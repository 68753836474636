import hash from './hash';
import { saveToLocalStorage } from './encryptedStorageDriver';
import { STORAGE_KEY_VERIFY_NAME } from './isStorageKeyValid';

const saveStorageKey = (sessionPassword: string) => {
  const encryptionKey = hash(sessionPassword);
  const encryptionKeyVerification = hash(encryptionKey);
  saveToLocalStorage(STORAGE_KEY_VERIFY_NAME, encryptionKeyVerification);

  return encryptionKey;
};

export default saveStorageKey;
