import * as React from 'react';
import { Formik, FormikValues, FormikFormProps } from 'formik';
import * as yup from 'yup';
import { Persist } from 'formik-persist';
import './styles/base-form.scss';
import { isRunningInJest } from '../../../utilities/helpers';
import Alert from './Alert';
import Button from './Button';

export interface BaseFormProps extends FormikFormProps {
  onSubmit(values: FormikValues): void;
  errorMessage?: string;
  isLoading?: boolean;
  validationSchema?: yup.ObjectSchema<{}>;
  initialValues: FormikValues;
  children(formikParams: any): void;
  submitButtonLabel?: string;
  persistName?: string;
  hasCancel?: boolean;
  cancelButtonLabel?: string;
  onCancel?(): void;
  onFormChanged?(values: FormikValues): void;
  hasSubmitButton?: boolean;
}

interface BaseFormState {
  shouldRenderForm: boolean;
  currentFormValues: FormikValues;
}

class BaseForm extends React.PureComponent<BaseFormProps, BaseFormState> {
  private formRef = React.createRef<Formik>();
  private mountTimer: number;

  state: Readonly<BaseFormState> = {
    shouldRenderForm: isRunningInJest(),
    currentFormValues: {},
  };

  static defaultProps = {
    isLoading: false,
    hasSubmitButton: true,
    errorMessage: '',
    submitButtonLabel: 'Submit',
    cancelButtonLabel: 'Cancel',
  };

  componentDidMount() {
    // This timer makes autofocus work even when forms are inside an
    // animated modal.
    this.mountTimer = window.setTimeout(() => {
      this.setState({
        shouldRenderForm: true,
      });
    }, 50);
  }

  componentWillUnmount() {
    clearTimeout(this.mountTimer);
  }

  onFormChanged = (values: FormikValues) => {
    const { onFormChanged } = this.props;
    const { currentFormValues } = this.state;

    if (!onFormChanged) {
      return;
    }

    // Only trigger form changed callback if the form has actually changed
    // to prevent cyclical renders due to updating props.
    if (values !== currentFormValues) {
      onFormChanged(values);
      this.setState({
        currentFormValues: values,
      });
    }
  }

  onFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const didPressCommandEnter = e.keyCode === 13 && e.metaKey;
    if (!didPressCommandEnter) return true;

    this.submitForm();
  }

  submitForm() {
    this.formRef.current.submitForm();
  }

  render() {
    const { shouldRenderForm } = this.state;
    const {
      persistName,
      validationSchema,
      onSubmit,
      initialValues,
      errorMessage,
      children,
      isLoading,
      submitButtonLabel,
      cancelButtonLabel,
      hasCancel,
      onCancel,
      hasSubmitButton,
    } = this.props;

    return (
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        initialValues={initialValues}
        ref={this.formRef}
      >
        {shouldRenderForm ? ({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={this.onFormKeyDown}
              className="base-form"
            >
              {this.onFormChanged(values)}
              {errorMessage ? <Alert variant="warning" className="mb-20">{errorMessage}</Alert> : null}
              {children({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                setFieldValue,
              })}
              {hasSubmitButton ? (
                <div className="base-form__footer">
                  {hasCancel ? (
                    <Button variant="danger" disabled={isLoading} className="base-form__btn mr-10" onClick={onCancel}>
                      {cancelButtonLabel}
                    </Button>
                  ) : null}
                  <Button variant="primary" type="submit" disabled={isLoading} className="base-form__btn">
                    {submitButtonLabel}
                  </Button>
                </div>
              ) : null}
              {isLoading ? (
                <div className="base-form__loading-overlay">
                  <span
                    className="spinner-border spinner-border"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {persistName ? (
                <Persist
                  name={`genie/forms/${persistName}`}
                  isSessionStorage
                />
              ) : null}
            </form>
          )
          : null}
      </Formik>
    );
  }
}

export default BaseForm;
