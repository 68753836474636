import * as React from 'react';
import { connect } from 'react-redux';
import { startCase } from 'lodash';
import * as queryString from 'query-string';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnalysisOverrides } from '../../../store/report/analysis/types';
import { PATHS } from '../../../store/router/types';
import { navigate } from '../../../store/router/actions';
import { RouteComponentProps, RouteProps } from 'react-router';
import DiseaseFieldForm from '../../../components/report/analysis/DiseaseFieldForm';
import { editDiseaseField } from '../../../store/report/analysis/actions';
import { getDiseaseFieldsMergedWithOverrides, getFieldValue, getDiseaseIdForGene } from '../../../store/report/selectors';
import Modal from '../../../components/widgets/Modal';

interface StateProps {
  fieldName: keyof AnalysisOverrides;
  fieldValue: string|string[];
  diseaseId: string;
}

interface DispatchProps {
  onClose(): void;
  onEdit(diseaseId: string, fieldName: string, value: string): void;
}

interface AllProps extends StateProps, DispatchProps, RouteComponentProps<{}> {

}

const AnalysisEdit = (props: AllProps) => {
  const { diseaseId, fieldName, fieldValue } = props;

  return (
    <Modal
      show
      onHide={props.onClose}
      title={startCase(fieldName)}
    >
      <DiseaseFieldForm
        onSubmit={({ value }) => {
          props.onEdit(diseaseId, fieldName, value);
          props.onClose();
        }}
        initialValues={{
          value: fieldValue,
        }}
        fieldName={fieldName}
      />
    </Modal>
  );
};

const mapStateToProps = ({ report }: ApplicationState, ownProps: RouteProps) => {
  const { fieldName, gene } = queryString.parse(ownProps.location.search) as { fieldName: keyof AnalysisOverrides; gene: string };
  const { analysis, clientDetails, carrierRates } = report;

  return {
    ...getDiseaseFieldsMergedWithOverrides(gene, report.analysis, report.clientDetails, carrierRates),
    fieldName,
    diseaseId: getDiseaseIdForGene(gene, clientDetails),
    fieldValue: getFieldValue(gene, fieldName, analysis, clientDetails, carrierRates),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onEdit: (diseaseId: string, fieldName: string, value: string) =>
    dispatch(editDiseaseField(diseaseId, fieldName, value)),
  onClose: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_ANALYSIS)),
});

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(AnalysisEdit);
