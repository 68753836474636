import { FieldType } from '../../FieldType';

export type ClientDetailsVariables = {
  whoTestedTitle: string;
  careTeamTitle: string;
  clientAName: string;
  clientADOB: string;
  clientASampleDate: string;
  clientAReportDate: string;
  hasSecondClient: boolean;
  clientBName?: string;
  clientBDOB?: string;
  clientBSampleDate?: string;
  clientBReportDate?: string;
  careTeamSubTitle: string;
  careTeam: string;
  referringDoctorTitle: string;
  referringDoctorClinic: string;
};

export const ClientDetailsVariableTypes: {[key in keyof ClientDetailsVariables]: FieldType} = {
  whoTestedTitle: 'text',
  careTeamTitle: 'text',
  clientAName: 'text',
  clientADOB: 'text',
  clientASampleDate: 'text',
  clientAReportDate: 'text',
  hasSecondClient: 'boolean',
  clientBName: 'text',
  clientBDOB: 'text',
  clientBSampleDate: 'text',
  clientBReportDate: 'text',
  careTeamSubTitle: 'text',
  careTeam: 'text',
  referringDoctorTitle: 'text',
  referringDoctorClinic: 'text',
};
