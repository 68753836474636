import { merge } from 'lodash';
import { appSyncClient } from '../../../utilities/appSync';
import gql from 'graphql-tag';
import { Request, QueryRequestArgs, Member, Mutation, MutationUpdateJourneyStateArgs, JourneyState, Journey, Product, Pregnancy, Counsellor, Report, MutationUpdateRequestArgs, RiskStatus, DoctorClinic } from '../../../graphql/genie-api-types';
import { defaultReportState, ReportState } from '.';
import { PATHS } from '../router/types';
import { navigate } from '../router/actions';
import { ThunkDispatch } from 'redux-thunk';
import { hideToast, showLoadingToast, showToast } from '../toast/actions';
import { ApplicationState } from '..';
import { mergeApplicationState } from '../storage/actions';
import createPrefilledReportForRequest from '../../graphql/requests/createPrefilledReportForRequest';
import { loadReportFromStorage } from '../../../utilities/report/localStorage';
import generateMemberReportEmailBody from '../../../utilities/report/generateMemberReportEmailBody';
import { DoctorClinicPartial, DOCTOR_CLINIC_FRAGMENT, formatDoctorName } from '../../../utilities/doctorClinic';
import { preferredDoctorClinicEmail } from '../../../utilities/helpers';

type RequestWithBaseJourney = Pick<Request, 'id' | 'product' | 'productVariant' | 'primaryCounsellor' | 'report' | 'pregnancy' | 'initiatingMember' | 'riskStatus'> & {
  referringDoctorClinic: DoctorClinicPartial;
  journeys: Pick<Journey, 'id' | 'state' | 'member' | 'history'>[];
}

export type RequestResult = {
  request: RequestWithBaseJourney;
};

export const replaceReportStateWithStorageState = (sessionName: string) =>
  async (dispatch: ThunkDispatch<{}, any, any>, getState: () => ApplicationState) => {
    const { storage } = getState();
    const retrievedState = loadReportFromStorage(sessionName, storage.encryptionKey);
    const storedState = merge({}, defaultReportState, retrievedState);

    dispatch(mergeApplicationState(storedState));
  };

export const createReportFromRequest = (requestId: string) =>
  async (dispatch: ThunkDispatch<{}, any, any>) => {
    const existingReportToastId = 'createReportFromRequest-checking';

    // Check existing requests.
    let request: RequestResult['request'];
    try {
      dispatch(showLoadingToast(existingReportToastId, 'Creating report', 'Checking for existing report'));
      const requestResult = await appSyncClient.query<RequestResult, QueryRequestArgs>({
        query: gql`
          query Request($id: ID!) {
            request(id: $id) {
              id
              report {
                id
              }
              journeys {
                id
              }
            }
          }
        `,
        variables: {
          id: requestId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });
      request = requestResult.data.request;
    } catch (e) {
      showToast('error', 'Creating report', e.message);
      return;
    } finally {
      dispatch(hideToast(existingReportToastId));
    }

    // If the request already has a report, view instead.
    if (request?.report?.id) {
      dispatch(viewRequestSession(request.id));
      return;
    }

    const creatingReportToastId = 'createReportFromRequest-creating';
    dispatch(showLoadingToast(creatingReportToastId, 'Creating report', 'Prefilling report from request details'));

    try {
      await createPrefilledReportForRequest(requestId);
      showToast('success', 'Creating report', 'Successfully created and prefilled report');
      dispatch(viewRequestSession(request.id));
    } catch (e) {
      showToast('error', 'Creating report', e.message);
    } finally {
      dispatch(hideToast(creatingReportToastId));
    }
  };

export const viewRequestSession = (requestId: Request['id']) =>
  async (dispatch: ThunkDispatch<{}, any, any>) => {
    dispatch(navigate.toReportPath(PATHS.REPORTS_CLIENT_DETAILS, 'remote', requestId));
  };

type MemberWithEmail = Pick<Member, 'nickname' | 'name' | 'email'>;

type JourneyWithMember = {
  member: MemberWithEmail;
};

interface RequestPartial {
  journeys: JourneyWithMember[];
  primaryCounsellor: Pick<Counsellor, 'nickname' | 'name'>;
  referringDoctorClinic: DoctorClinicPartial;
  pregnancy: Partial<Pregnancy>;
  product: Product;
  report: Pick<Report, 'id' | 'data'>;
}

type MemberReportEmailRequestResult = {
  request: RequestPartial;
};

export const createMemberReportEmail = (requestId: string) =>
  async (dispatch: ThunkDispatch<{}, any, any>) => {
    const toastId = 'createMemberReportEmail-loadingEmails';
    dispatch(showLoadingToast(toastId, 'Preparing email', 'Downloading email addresses...'));

    try {
      const requestResult = await appSyncClient.query<MemberReportEmailRequestResult, QueryRequestArgs>({
        query: gql`
          query Request($id: ID!) {
            request(id: $id) {
              id
              product
              riskStatus
              referringDoctorClinic {
                ...DoctorClinicFields
              }
              report {
                id
                data
              }
              primaryCounsellor {
                id
                nickname
                name
              }
              pregnancy {
                days
              }
              journeys {
                partnerCanReceiveResults
                member {
                  id
                  email
                  name
                  nickname
                }
              }
            }
          }
          ${DOCTOR_CLINIC_FRAGMENT}
        `,
        variables: {
          id: requestId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const emailAddresses = requestResult.data.request.journeys.filter((journey => journey.member )).map(journey => `${journey.member.name}<${journey.member.email}>`);

      const doctorClinic = requestResult.data.request.referringDoctorClinic;
      const doctorEmailAddress = doctorClinic ? `${formatDoctorName(doctorClinic)}<${preferredDoctorClinicEmail(doctorClinic as DoctorClinic)}>` : '';

      const report = requestResult.data.request.report;
      const reportData = report && report.data ? JSON.parse(report.data) as ReportState : null;
      const risk = reportData ? reportData.summary.reproductiveRisk : '';

      const body = generateMemberReportEmailBody(requestResult.data.request, risk);
      
      window.open(`mailto:${emailAddresses.join(',')}?subject=Your results from Eugene&bcc=${doctorEmailAddress}&body=${body.replace(/\n/g, "%0D%0A")}`);
    } catch (e) {
      showToast('error', 'Member report email', 'Could not retrieve email addresses');
    }

    dispatch(hideToast(toastId));
  };

export const sendForReview = (requestId: string) =>
  async (dispatch: ThunkDispatch<{}, any, any>) => {
    const toastId = 'sendForReview-changingStates';
    dispatch(showLoadingToast(toastId, 'Changing journey states', 'Moving journeys to Ready for Report Review.'));

    try {
      const requestResult = await appSyncClient.query<RequestResult, QueryRequestArgs>({
        query: gql`
          query Request($id: ID!) {
            request(id: $id) {
              id
              journeys {
                id
              }
            }
          }
        `,
        variables: {
          id: requestId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const journeyIds = requestResult.data.request.journeys
        .filter(journey => ![JourneyState.Trash, JourneyState.CloseLost].includes(journey.state))
        .map(journey => journey.id)
      await Promise.all(journeyIds.map(journeyId =>
        appSyncClient.mutate<Pick<Mutation, 'updateJourneyState'>, MutationUpdateJourneyStateArgs>({
          mutation: gql`
            mutation UpdateJourneyState($input: UpdateJourneyStateInput!) {
              updateJourneyState(input: $input) {
                journey {
                  id
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              journeyId,
              state: JourneyState.ReadyForReportReview,
            },
          },
        })
      ));
      dispatch(navigate.to(PATHS.TASKS_WRITE_REPORTS));
      showToast('success', 'Moved journeys', 'Moved journeys to Ready for Report Review.');
    } catch (e) {
      showToast('error', 'Journey state update failed', e.message);
    }

    dispatch(hideToast(toastId));
  };

export const markAsReviewed = (requestId: string) =>
  async (dispatch: ThunkDispatch<{}, any, any>) => {
    const toastId = 'sendForReview-changingStates';
    dispatch(showLoadingToast(toastId, 'Changing journey states', 'Moving journeys to Ready for Report Review.'));

    try {
      const requestResult = await appSyncClient.query<RequestResult, QueryRequestArgs>({
        query: gql`
          query Request($id: ID!) {
            request(id: $id) {
              id
              journeys {
                id
                state
              }
            }
          }
        `,
        variables: {
          id: requestId,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const journeyIds = requestResult.data.request.journeys
        .filter(journey => ![JourneyState.Trash, JourneyState.CloseLost].includes(journey.state))
        .map(journey => journey.id)
      await Promise.all(journeyIds.map(journeyId =>
        appSyncClient.mutate<Pick<Mutation, 'updateJourneyState'>, MutationUpdateJourneyStateArgs>({
          mutation: gql`
            mutation UpdateJourneyState($input: UpdateJourneyStateInput!) {
              updateJourneyState(input: $input) {
                journey {
                  id
                  state
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              journeyId,
              state: JourneyState.RequireResultBooking,
            },
          },
        })
      ));
      dispatch(navigate.to(PATHS.TASKS_REVIEW_REPORTS));
      showToast('success', 'Moved journeys', 'Moved journeys to Require result booking');
    } catch (e) {
      showToast('error', 'Journey state update failed', e.message);
    }

    dispatch(hideToast(toastId));
  }

  export const updateRiskStatus = (requestId: string, newStatus: string) => async (dispatch: ThunkDispatch<{}, any, any>) => {
    const toastId = 'updating-risk-status';
    dispatch(showLoadingToast(toastId, 'Updating risk status', 'Changing the risk status'));

    try {
      await appSyncClient.mutate<Pick<Mutation, 'updateRequest'>, MutationUpdateRequestArgs>({
        mutation: gql`
          mutation updateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                riskStatus
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            requestId,
            riskStatus: newStatus !== "" ? newStatus as RiskStatus : null,
          },
        },
      });
      showToast('success', 'Updated risk status', 'Changed risk status');

    } catch (e) {
      showToast('error', 'Risk status update failed', e.message);
    }

    dispatch(hideToast(toastId));
  }

  export const updatePrimaryCounsellor = (requestId: string, newCounsellorId: string) => async (dispatch: ThunkDispatch<{}, any, any>) => {
    const toastId = 'updating-primary-counsellor';
    dispatch(showLoadingToast(toastId, 'Updating primary counsellor', 'Changing the primary counsellor'));

    try {
      await appSyncClient.mutate<Pick<Mutation, 'updateRequest'>, MutationUpdateRequestArgs>({
        mutation: gql`
          mutation updateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                primaryCounsellor {
                  id
                  name
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            requestId,
            primaryCounsellorId: newCounsellorId ?? null,
          },
        },
      });
      showToast('success', 'Updated primary counsellor info', 'Changed primary counsellor info');

    } catch (e) {
      showToast('error', 'Primary counsellor info update failed', e.message);
    }

    dispatch(hideToast(toastId));
  }