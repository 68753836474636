import * as React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Member, QueryListJourneysArgs, QuerySearchMembersArgs, QueryMembersByDateOfBirthArgs } from '../../../graphql/genie-api-types';
import { uniqBy } from 'lodash';
import { useLazyQuery, useQuery } from 'react-apollo';
import { AWS_DATE_FORMAT } from '../../../utilities/helpers';

export type MemberSearchResult = Pick<Member, 'id' | 'name' | 'dateOfBirth'>;

export interface MemberSearchResultsProps {
  searchTerm: string;
  children(memberResults: MemberSearchResult[], isLoading: boolean): React.ReactElement<any>;
}

interface ListJourneysResult {
  listJourneys: {
    edges: {
      node: {
        member: MemberSearchResult;
      };
    }[];
  };
}

interface SearchMembersResult {
  searchMembers: MemberSearchResult[];
}

interface MembersByDateOfBirthResult {
  membersByDateOfBirth: MemberSearchResult[];
}

const MemberSearchResults = (props: MemberSearchResultsProps) => {
  const [useDobQueryResults, setUseDobQueryResults] = React.useState<boolean>(false);

  const labOrderNumberQuery = useQuery<ListJourneysResult, QueryListJourneysArgs>(gql`
    query ListJourneys($input: ListJourneysInput!) {
      listJourneys(input: $input) {
        edges {
          node {
            id
            member {
              id
              name
              dateOfBirth
              sex
            }
          }
        }
      }
    }
  `, {
    variables: {
      input: {
        labOrderNumber: props.searchTerm,
      },
    },
    fetchPolicy: props.searchTerm ? 'cache-and-network' : 'cache-only',
  });

  const searchMembersQuery = useQuery<SearchMembersResult, QuerySearchMembersArgs>(gql`
    query SearchMembers($term: String!) {
      searchMembers(term: $term) {
        id
        name
        dateOfBirth
        sex
      }
    }
  `, {
    variables: {
      term: props.searchTerm,
    },
    fetchPolicy: props.searchTerm ? 'cache-and-network' : 'cache-only',
  });
  
  const [fetchDateOfBirthMembers, dateOfBirthQuery] = useLazyQuery<MembersByDateOfBirthResult, QueryMembersByDateOfBirthArgs>(gql`
    query MembersByDateOfBirth($date: AWSDate!) {
      membersByDateOfBirth(date: $date) {
        id
        name
        dateOfBirth
        sex
      }
    }
  `);
  
  React.useEffect(() => {
    const dateOfBirthDate = moment(props.searchTerm);
    if (!dateOfBirthDate.isValid()) {
      setUseDobQueryResults(false);
      return;
    }
    
    setUseDobQueryResults(true);
    fetchDateOfBirthMembers({
      variables: {
        date: dateOfBirthDate.format(AWS_DATE_FORMAT),
      },
    });

  }, [props.searchTerm]);

  let searchMembersResult: MemberSearchResult[] = [];
  let listJourneysMembersResult: MemberSearchResult[] = [];
  let dateOfBirthMembersResult: MemberSearchResult[] = [];

  try {
    searchMembersResult = searchMembersQuery?.data?.searchMembers ?? [];
    listJourneysMembersResult = labOrderNumberQuery?.data?.listJourneys?.edges?.map(edge => edge.node)?.filter(journey => journey.member).map(journey => journey.member) ?? [];
    dateOfBirthMembersResult = useDobQueryResults ? (dateOfBirthQuery?.data?.membersByDateOfBirth ?? []) : [];
  } catch (e) {
    console.error(e);
  }

  const members = props.searchTerm ? uniqBy([...searchMembersResult, ...listJourneysMembersResult, ...dateOfBirthMembersResult], 'id') : [];

  return props.children(members ?? [], searchMembersQuery.loading || labOrderNumberQuery.loading || dateOfBirthQuery.loading);
};

export default MemberSearchResults;
