import { Product, ProductVariant } from '../../../../graphql/genie-api-types';

export enum TestInformationActionTypes {
  CHANGE_FIELDS = '@@report/test-information/CHANGE_FIELDS',
}

export interface TestInformationState {
  product: Product;
  productVariant?: ProductVariant;
  numberOfGenesTested: string;
  referringDoctorClinic: string;
  reviewedBy: string;
  eugeneCareTeam: string;
  numberOfXLinkedGenesTested: string;
}
