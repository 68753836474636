import * as React from 'react';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import RouteButton from '../../components/widgets/RouteButton';
import Screen from '../../components/widgets/Screen';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Query } from '../../../graphql/genie-api-types';
import Table from '../../components/widgets/Table';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';

const LIST_COUNSELLORS_QUERY = gql`
  query {
    listCounsellors {
      id
      name
      nickname
      admin {
        id
        email
      }
    }
  }
`;

const CounsellorIndex = () => {
  const dispatch = useDispatch();

  const counsellorsList = useQuery<Pick<Query, 'listCounsellors'>, {}>(LIST_COUNSELLORS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const counsellorsListOrdered = _.orderBy(counsellorsList.data?.listCounsellors, ['name'], ['asc', 'desc']);

  const onRowActionClick = (counsellorID: string) => {
    dispatch(navigate.toForm('CounsellorUpdate', { counsellorID: counsellorID }));
  }

  return (
    <Screen
      className="relative"
    >
      <ScreenTitle
        title="Counsellors"
        action={(
          <div>
            <RouteButton
              query={{ form: 'CounsellorCreate' }}
              size="sm"
            >
              + Create Counsellor
            </RouteButton>

          </div>
        )}
      />

      <Table
        header={['Name', 'Email']}
        onRowSelect={(counsellorID) => onRowActionClick(counsellorID)}
        rowGroups={counsellorsListOrdered?.map(counsellor => ({
          id: counsellor.id,
          rows: [{
            id: counsellor.id,
            cells: [
              `${counsellor.name}${counsellor.nickname ? ` (${counsellor.nickname})` : ''}`,
              `${counsellor.admin?.email || ''}`
            ],
          }]
        }))}
      />
    </Screen>
  );
};

export default CounsellorIndex;

