import * as React from 'react';
import { Journey, Kit, KitScope, ListKitsInput, Member, Pregnancy, Request } from '../../../graphql/genie-api-types';
import moment from 'moment';
import gql from 'graphql-tag';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';
import Screen from '../../components/widgets/Screen';
import OpsKitsTable from '../../graphql/containers/ops/OpsKitsTable';
import MemberFlowStatusBadge from '../../components/widgets/MemberFlowStatusBadge';

interface OpsAwaitingSamplesJourney extends Required<Pick<Journey, 'id' | 'state' | 'hasRecollection' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission'>> {
  member: Required<Pick<Member, 'id'|'name'|'sex'|'onlineAccessEnabled'|'dateDetailsConfirmedByMember'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'>>;
    })[];
  };
}

interface OpsAwaitingSamplesKit extends Pick<Kit, 'id'|'dateShippedToMember'|'registrationNumber'> {
  journey: OpsAwaitingSamplesJourney;
}

const OPS_AWAITING_SAMPLES_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        cursor
        node {
          id
          dateShippedToMember
          registrationNumber
          journey {
            id
            hasRecollection
            hasConsent
            hasRelevantFamilyHistoryGroupSubmission
            member {
              id
              dateOfBirth
              name
              email
              onlineAccessEnabled
              dateDetailsConfirmedByMember
            }
            request {
              id
              onHoldDate
              product
              productVariant
              initiatingMember {
                id
              }
              pregnancy {
                dueDate
                dueDateConfirmed
                days
              }
              journeys {
                id
                state
                member {
                  id
                  name
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalRows
    }
  }
`;

const queryInput: ListKitsInput = {
  scope: KitScope.WaitingForReturnShipping,
};

const markAsArrivedAction = 'markAsArrived';

const OpsAwaitingSamples = () => {
  const dispatch = useDispatch();

  const onActionClick = React.useCallback(async (kitIds: string[], actionName: string) => {
    if (actionName !== markAsArrivedAction) {
      return;
    }

    dispatch(navigate.toForm('KitsUpdateArrived', { kitIds }));
  }, [dispatch]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Awaiting samples',
      }}
    >
      <OpsKitsTable<OpsAwaitingSamplesKit>
        query={OPS_AWAITING_SAMPLES_QUERY}
        queryInput={queryInput}
        header={[
          'Shipped',
          'Member flow',
          'Kit number',
        ]}
        cellsForJourney={(journey, kit) => [
          moment(kit?.dateShippedToMember).fromNow(),
          <MemberFlowStatusBadge journey={journey} key="flow-status" />,
          kit?.registrationNumber ?? 'not registered',
        ]}
        actionsForJourney={() => [
          {
            label: 'Arrived',
            name: markAsArrivedAction,
            variant: 'success',
          },
        ]}
        onActionClick={(rowIds, actionName) => onActionClick(rowIds, actionName)}
      />
    </Screen>
  );
};

export default OpsAwaitingSamples;
