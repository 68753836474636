import * as React from 'react';

type UseToggleStateReturn = [boolean, () => void, (value: boolean) => void];

const useToggleState = (defaultValue: boolean): UseToggleStateReturn => {
  const [isToggled, setIsToggled] = React.useState(defaultValue);
  
  const onToggle = React.useCallback(() => {
    setIsToggled(!isToggled);
  }, [setIsToggled, isToggled]);
  
  return [isToggled, onToggle, setIsToggled];
};

export default useToggleState;
