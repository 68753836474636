import { Reducer } from 'redux';
import { AdditionalInformationState, AdditionalInformationActionTypes } from './types';

export const initialState: AdditionalInformationState = {
         content: '',
       };

const reducer: Reducer<AdditionalInformationState> = (state = initialState, action) => {
  switch (action.type) {
    case AdditionalInformationActionTypes.CHANGE_FIELDS:
      const fields: { fields: AdditionalInformationState } = action.payload;

      return {
        ...state,
        ...fields,
      };
    default:
      return state;
  }
};

export { reducer as AdditionalInformationReducer };
