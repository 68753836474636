export enum SummaryActionTypes {
  CHANGE_FIELD = '@@report/summary/CHANGE_FIELD',
  RESET_FIELD = '@@report/summary/RESET_FIELD',
  RECEIVE_PSEUDO_DISEASES_BEGIN = '@@report/summary/RECEIVE_PSEUDO_DISEASES_BEGIN',
  RECEIVE_PSEUDO_DISEASES_COMPLETE = '@@report/summary/RECEIVE_PSEUDO_DISEASES_COMPLETE',
  UPDATE_DEFAULT_QUICK_NOTE = '@@report/summary/UPDATE_DEFAULT_QUICK_NOTE',
  UPDATE_DEFAULT_WHAT_WE_FOUND = '@@report/summary/UPDATE_DEFAULT_WHAT_WE_FOUND',
}

type SummaryFieldOverrides =  { [key: string]: string };
export type SummaryStateFieldNames = 'reproductiveRisk' | 'whatWeKnow' | 'quickNote' | 'importantNote' | 'whatWeFound';

export interface SummaryState {
  reproductiveRisk: ''|'High'|'Low'|'Increased';
  whatWeKnow: string;
  whatWeFound: string;
  quickNote: string;
  importantNote: string;
  fieldOverrides: SummaryFieldOverrides;
  isLoadingPseudoDiseases: boolean;
}
