import gql from 'graphql-tag';
import { AppDispatch } from '..';
import { Counsellor, Query, RequestsConnection } from '../../../graphql/genie-api-types';
import { appSyncClient } from '../../../utilities/appSync';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { navigate } from '../router/actions';
import { hideToast, showLoadingToast, showToast } from '../toast/actions';
import { ResearchTypes, ResearchSession } from './types';

const TOTAL_REQUEST_COUNT_STATS_QUERY = gql`
  query ListRequests($input: ListRequestsInput!) {
    listRequests(input: $input) {
      edges {
        cursor # Required for stats query
        node {
          id
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const startResearchSession = (counsellorId?: Counsellor['id']) =>
  async (dispatch: AppDispatch) => {
    const loadingToastId = 'start-session';
    dispatch(showLoadingToast(loadingToastId, 'Loading session', '...'));

    const allRequests = await runStatsQuery<RequestsConnection>(TOTAL_REQUEST_COUNT_STATS_QUERY, {
      variables: {
        input: {
          primaryCounsellorId: counsellorId,
          hasReport: true,
          extractSubmitted: false,
        }
      },
    }, 'listRequests');
    
    const counsellorQuery = counsellorId ? await appSyncClient.query<Pick<Query, 'counsellor'>>({
      query: gql`
        query Counsellor($id: ID!) {
          counsellor(id: $id) {
            id
            nickname
          }
        }
      `,
      variables: {
        id: counsellorId,
      },
      errorPolicy: 'ignore',
    }) : null;
    
    const requestIds = allRequests.edges.map(edge => edge.node).map(node => node.id);
    
    if (requestIds.length === 0) {
      dispatch(hideToast(loadingToastId));
      dispatch(showToast('info', 'No pending requests to extract found', 'Either something went wrong or you\'ve finished!'));
      return;
    }

    dispatch({
      type: ResearchTypes.START_SESSION,
      payload: {
        requestIds,
        counsellor: counsellorQuery?.data?.counsellor,
      } as ResearchSession,
    });
    
    dispatch(navigate.toClinicalResearchExtractCreate(requestIds?.[0]));
    dispatch(hideToast(loadingToastId));
  };