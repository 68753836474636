import gql from 'graphql-tag';
import * as React from 'react';
import { Flag, Journey, JourneyState, Query, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import { formatDateOfBirth } from '../../../../utilities/helpers';
import { getProductLabel } from '../../../../utilities/journeys/products';
import useGenieQuery from '../../../../utilities/useGenieQuery';
import Button from '../../../components/widgets/Button';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { showToast } from '../../../store/toast/actions';
import updateFlagComplete from '../../requests/flags/updateFlagComplete';
import updateJourneyState from '../../requests/journey/updateJourneyState';
import addRequestNote from '../../requests/request/addRequestNote';

interface CancelTestProps {
  onComplete(): void;
  onCancel(): void;
  journeyId: Journey['id'];
  failedSampleFlagId?: Flag['id'];
}

const CancelTest = (props: CancelTestProps) => {
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const journeyQuery = useGenieQuery<Pick<Query, 'journey'>, QueryJourneyArgs>(gql`
    query Journey($id: ID!) {
      journey(id: $id) {
        id
        member {
          id
          name
          dateOfBirth
        }
        relatedJourneys {
          member {
            id
            name
          }
        }
        request {
          id
          product
          productVariant
        }
      }
    }
  `, {
    variables: {
      id: props.journeyId,
    },
  }, 'journey');

  const onCancelTest = React.useCallback(async () => {
    setIsCancelling(true);
    await updateJourneyState(props.journeyId, JourneyState.CloseLost);
    if (props.failedSampleFlagId) {
      await updateFlagComplete(props.failedSampleFlagId);
    }
    
    await addRequestNote(journeyQuery.data?.journey?.request?.id, `${journeyQuery.data.journey?.member?.name}'s test cancelled: ${message || 'no reason provided'}`);

    setIsCancelling(false);
    showToast('success', 'Cancel test', `Marked as Closed Lost with ${message ? `the message "${message}"` : 'no message'}.`);
    props.onComplete();
  }, [props.failedSampleFlagId, props.journeyId, journeyQuery.data, message]);
  
  const onMessageChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  }, [setMessage]);

  if (journeyQuery.loading || isCancelling) {
    return <LoadingOverlay />;
  }

  const journey = journeyQuery?.data?.journey;
  const member = journey?.member;
  const request = journey?.request;
  const relatedJourneys = journey?.relatedJourneys ?? [];

  return (
    <div>
      <p className="mb-10">Member: <strong>{member?.name}</strong> ({formatDateOfBirth(member?.dateOfBirth)})</p>
      <p>Product: <strong>{getProductLabel(request?.product, request?.productVariant)}</strong></p>
      {relatedJourneys.length > 0 && (
        <div className="py-10">
          Note: This will not affect {relatedJourneys.map(journey => journey.member.name).join(',')}&apos;s test.
        </div>
      )}
      <label className="block my-10">
        <span className="block font-bold mb-5">Reason</span>
        <textarea value={message} onChange={onMessageChange} className="w-full p-10" />
      </label>
      <Button onClick={onCancelTest}>Cancel this test</Button>
    </div>
  );
};

export default CancelTest;