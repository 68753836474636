import { combineReducers, Reducer } from 'redux';
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr';
import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { ReportState, reportReducer } from './report';
import { SearchState } from './search/types';
import { searchReducer } from './search/reducer';
import { storageReducer } from './storage/reducer';
import { StorageReducerState, StorageActionTypes } from './storage/types';
import { ThunkDispatch } from 'redux-thunk';
import { NavigationState } from './navigation/types';
import { navigationReducer } from './navigation/reducer';
import { ResearchState } from './research/types';
import { researchReducer } from './research/reducer';

export interface ApplicationState {
  report: ReportState;
  router: RouterState;
  toastr: ToastrState;
  search: SearchState;
  storage: StorageReducerState;
  navigation: NavigationState;
  research: ResearchState;
}

export type AppDispatch = ThunkDispatch<ApplicationState, void, any>;
export type GetStateFn = () => ApplicationState;

export const history = createBrowserHistory();

const appReducer = combineReducers<ApplicationState>({
  router: connectRouter(history),
  report: reportReducer,
  toastr: toastrReducer,
  search: searchReducer,
  storage: storageReducer,
  navigation: navigationReducer,
  research: researchReducer,
});

export const rootReducer: Reducer<ApplicationState> = (state: ApplicationState, action) => {
  if (action.type === StorageActionTypes.MERGE_APPLICATION_STATE) {
    /* tslint:disable-next-line:no-parameter-reassignment */
    state = {
      ...state,
      ...action.payload,
    };
  }

  return appReducer(state, action);
};
