import * as React from 'react';
import useDebounce from '../../../utilities/useDebounce';
import { FiSearch } from './Icon';
import Spinner from './Spinner';

type SearchResult = {
  id: string;
  renderMethod(): React.ReactNode;
};

export type SearchResultGroup = {
  title: string;
  results: SearchResult[];
};

export interface SearchFormProps {
  className?: string;
  resultGroups?: SearchResultGroup[];
  isLoading?: boolean;
  onSearchChange(searchTerm: string): void;
  defaultSearchTerm: string;
  autoFocus?: boolean;
  placeholder?: string;
  debounceTime?: number;
}

const SearchForm = (props: SearchFormProps) => {
  const { className, isLoading, defaultSearchTerm, onSearchChange, autoFocus, placeholder } = props;
  const [isDebouncing, setIsDebouncing] = React.useState<boolean>(false);
  
  const [searchTerm, setSearchTerm] = React.useState<string>(defaultSearchTerm);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsDebouncing(true);
    if (e.target.value === '') {
      onSearchChange(e.target.value); // Immediate change if it's clearing it out.
    }
  };
  
  const debouncedSearchTerm = useDebounce(searchTerm, props.debounceTime ?? 500);
  React.useEffect(() => {
    onSearchChange(debouncedSearchTerm);
    setIsDebouncing(false);
  }, [debouncedSearchTerm])

  return (
    <div className={`relative ${className}`}>
      <div className="absolute t-0 l-0 ml-10 mt-12 text-md">
        {isLoading || isDebouncing ? <Spinner size={16} className="ml-2 mt-2" color="purple" /> : <FiSearch className="text-grey" />}
      </div>
      <input
        type="text"
        autoComplete="false"
        className="focus:outline-none text-sm border border-transparent focus:border-purple-lighter rounded py-10 bg-purple-100 pl-30 w-full"
        placeholder={placeholder}
        onChange={onChange}
        value={searchTerm}
        autoFocus={autoFocus}
      />
    </div>
  );
};

export default SearchForm;
