import * as React from 'react';

import ShippingChecklist from '../../components/checklists/ShippingChecklist';
import ShippingList from '../../graphql/containers/kits/ShippingList';
import moment from 'moment';
import Button from '../../components/widgets/Button';
import { FiPrinter } from '../../components/widgets/Icon';
import Screen from '../../components/widgets/Screen';

const Manifest = () => (
  <Screen
    screenTitleProps={{
      title: `Manifest - ${moment().format('Y-MM-D')}`,
      action: (
        <Button
          onClick={window.print}
          className="print:hidden"
        >
          <FiPrinter /> Print
        </Button>
      )
    }}
  >
    <ShippingChecklist />
    <ShippingList />
  </Screen>
);

export default Manifest;