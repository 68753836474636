import { Reducer } from 'redux';
import { ClientDetailsState, ClientDetailsActionTypes, ClientFields, ClientId } from './types';
import { RequestDiseasesByGeneResult } from '../../../graphql/requests/requestDiseasesByGene';

export const defaultClientValues = {
  fullName: '',
  nickname: '',
  dob: '',
  affectedGenes: [] as string[],
  pseudogenes: [] as string[],
  ethnicity: '',
  memberId: '',
};

const initialState: ClientDetailsState = {
  clientA: defaultClientValues,
  clientB: defaultClientValues,
  diseasesByGene: {},
  carrierRatesByGene: {},
  pseudoDiseasesByGene: {},
  isLoadingDiseases: false,
  isPseudoDiseasesLoading: false,
};

const reducer: Reducer<ClientDetailsState> = (state = initialState, action) => {
  switch (action.type) {
    case ClientDetailsActionTypes.CHANGE_FIELDS:
      const { client, fields }: { client: ClientId; fields: ClientFields } = action.payload;

      const clientFieldName = client === 'A' ? 'clientA' : 'clientB';

      return {
        ...state,
        [clientFieldName]: {
          ...state[clientFieldName],
          ...fields,
        },
      };

    case ClientDetailsActionTypes.RECEIVE_DISEASES:
      return {
        ...state,
        diseasesByGene: action.payload as RequestDiseasesByGeneResult,
      };

    case ClientDetailsActionTypes.RECEIVE_DISEASES_BEGIN:
      return {
        ...state,
        isLoadingDiseases: true,
      };

    case ClientDetailsActionTypes.RECEIVE_DISEASES_COMPLETE:
      return {
        ...state,
        isLoadingDiseases: false,
      };

    case ClientDetailsActionTypes.RECEIVE_PSEUDO_DISEASES:
      return {
        ...state,
        pseudoDiseasesByGene: action.payload as RequestDiseasesByGeneResult,
      };

    case ClientDetailsActionTypes.RECEIVE_PSEUDO_DISEASES_BEGIN:
      return {
        ...state,
        isLoadingDiseases: true,
      };

    case ClientDetailsActionTypes.RECEIVE_PSEUDO_DISEASES_COMPLETE:
      return {
        ...state,
        isLoadingDiseases: false,
      };

    case ClientDetailsActionTypes.RECEIVE_CARRIER_RATES:
      return {
        ...state,
        carrierRatesByGene: {
          ...state.carrierRatesByGene,
          ...action.payload,
        },
      };
      
    case ClientDetailsActionTypes.SWAP_CLIENTS:
      return {
        ...state,
        clientA: state.clientB,
        clientB: state.clientA,
      };

    default:
      return state;
  }
};

export { reducer as clientDetailsReducer };
