import * as React from 'react';
import { Pregnancy } from '../../../graphql/genie-api-types';
import { convertDaysToFormattedWeeks } from '../../../utilities/helpers';
import Tooltip from './Tooltip';

interface PregnancyIconProps {
  pregnancy: Pick<Pregnancy, 'days'>;
  className?: string;
}

const PregnancyIcon = ({ pregnancy, className }: PregnancyIconProps) => (
  <Tooltip
    label={convertDaysToFormattedWeeks(pregnancy.days)}
  >
    <span className={`text-xl align-middle inline-block cursor-default ${className ? className : ''}`}>
      🤰
  </span>
  </Tooltip>
);

export default PregnancyIcon;
