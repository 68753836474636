import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { CreateReferralInput } from '../../../graphql/genie-api-types';
import FormGroup from '../widgets/form/FormGroup';
import DoctorClinicSearchAutocomplete from '../../graphql/containers/doctor-clinic/DoctorClinicSearchAutocomplete';
import MultiModelForm from '../../screens/MultiModelForm';
import FormInput from '../widgets/form/FormInput';
import FormCheck from '../widgets/form/FormCheck';
import { DoctorClinicPartial } from '../../../utilities/doctorClinic';

export type ReferralFormValues = {
  note?: CreateReferralInput['note'];
  doctorClinic?: DoctorClinicPartial;
  createFollowUp?: boolean;
}

interface ReferralFormProps extends Pick<BaseFormProps, 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: ReferralFormValues;
  hasFollowUpOption?: boolean;
}

const ReferralForm = (props: ReferralFormProps) => {
  const { onSubmit, hasFollowUpOption, ...restOfFormProps } = props;
  return (
    <MultiModelForm<'doctorClinic'>
      modelForms={{
        doctorClinic: 'DoctorClinicCreate',
      }}
      initialModelValues={{
        doctorClinic: props.initialValues.doctorClinic,
      }}
    >
      {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
        <BaseForm
          {...restOfFormProps}
          submitButtonLabel="Save"
          onSubmit={(values) => onSubmit({
            ...values,
            ...modelValues,
          })}
        >
          {({
            values,
            handleChange,
            setFieldValue,
          }) => (
              <div>
                <FormGroup
                  controlId="referringDoctorClinic"
                  label="Doctor-Clinic"
                  labelAction={{
                    label: '+ New',
                    onClick: () => onSwitchToModelForm('doctorClinic'),
                  }}
                >
                  <DoctorClinicSearchAutocomplete
                    defaultValue={modelValues['doctorClinic'] as DoctorClinicPartial}
                    onSelect={(doctor) => onChangeModelValue('doctorClinic', doctor)}
                  />
                </FormGroup>
                <FormInput
                  name="note"
                  label="Note"
                  as="textarea"
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values['note']}
                />
                {hasFollowUpOption ? (
                  <FormCheck
                    type="checkbox"
                    label="Create follow up?"
                    name="createFollowUp"
                    checked={values['createFollowUp']}
                    onChange={() => { setFieldValue('createFollowUp', !values['createFollowUp']) }}
                    className="mb-20"
                  />
                ) : null}
              </div>
            )
          }
        </BaseForm>
      )}
    </MultiModelForm>
  );
}

export default ReferralForm;
