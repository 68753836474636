import { JourneyState } from '../../graphql/genie-api-types';
import { FiCalendar, FiEye, FiEdit, FiInbox, FiClock, FiFileText, FiSend, FiArchive, FiThumbsUp, FiTrash } from '../../app/components/widgets/Icon';
import { StyledIcon } from '@styled-icons/styled-icon';

export const ORDERED_JOURNEY_STATES: JourneyState[] = [
  JourneyState.RequireDetails,
  JourneyState.RequirePretestBooking,
  JourneyState.WaitingForPretestConsult,
  JourneyState.WaitingForLabResults,
  JourneyState.WaitingForPartnerResults,
  JourneyState.ReadyForReport,
  JourneyState.ReadyForReportReview,
  JourneyState.RequireResultBooking,
  JourneyState.WaitingForResultConsult,
  JourneyState.ReadyToReleaseResults,
  JourneyState.ReadyToArchive,
  JourneyState.Complete,
  JourneyState.CloseLost,
  JourneyState.Trash,
];

export const JOURNEY_STATE_LABELS: { [key in JourneyState]: string } = {
  [JourneyState.RequireDetails]: 'Require more details',
  [JourneyState.RequirePretestBooking]: 'Require a pretest booking',
  [JourneyState.WaitingForPretestConsult]: 'Waiting for a pretest consult',
  [JourneyState.WaitingForLabResults]: 'Waiting for their results',
  [JourneyState.WaitingForPartnerResults]: 'Waiting for their partner\'s results',
  [JourneyState.ReadyForReport]: 'Ready to report',
  [JourneyState.ReadyForReportReview]: 'Ready to review',
  [JourneyState.RequireResultBooking]: 'Require a results booking',
  [JourneyState.WaitingForResultConsult]: 'Waiting for a results consult',
  [JourneyState.ReadyToReleaseResults]: 'Ready to release results',
  [JourneyState.ReadyToArchive]: 'Ready to archive',
  [JourneyState.Complete]: 'Complete',
  [JourneyState.CloseLost]: 'Close lost',
  [JourneyState.Trash]: 'Trash',
};

export const SELECTABLE_JOURNEY_STATES: JourneyState[] = [
  JourneyState.RequireDetails,
  JourneyState.RequirePretestBooking,
  JourneyState.WaitingForPretestConsult,
  JourneyState.WaitingForLabResults,
  JourneyState.ReadyForReport,
  JourneyState.ReadyForReportReview,
  JourneyState.RequireResultBooking,
  JourneyState.WaitingForResultConsult,
  JourneyState.ReadyToReleaseResults,
  JourneyState.ReadyToArchive,
  JourneyState.Complete,
  JourneyState.CloseLost,
  JourneyState.Trash,
];

export type JourneyStateAction = {
  label: string;
  type: 'moveToState' | 'createReport' | 'viewReport' | 'createMemberReportEmail' | 'markAsConsented' | 'markAsVerified' | 'addLabOrderNumber';
  value?: string;
  noBatch?: boolean;
};

export const JOURNEY_STATE_ACTIONS: { [key in JourneyState]: JourneyStateAction[] } = {
  [JourneyState.RequireDetails]: [
    {
      label: 'Enter details',
      type: 'moveToState',
      value: JourneyState.RequirePretestBooking,
    },
  ],
  [JourneyState.RequirePretestBooking]: [
    {
      label: 'Booked',
      type: 'moveToState',
      value: JourneyState.WaitingForPretestConsult,
    },
    {
      label: 'Consult complete',
      type: 'moveToState',
      value: JourneyState.WaitingForLabResults,
    },
  ],
  [JourneyState.WaitingForPretestConsult]: [
    {
      label: 'Consult complete',
      type: 'moveToState',
      value: JourneyState.WaitingForLabResults,
    },
    {
      label: 'No show',
      type: 'moveToState',
      value: JourneyState.RequirePretestBooking,
    },
    {
      label: 'Reschedule',
      type: 'moveToState',
      value: JourneyState.WaitingForPretestConsult,
    },
    {
      label: 'Verified',
      type: 'markAsVerified',
      noBatch: true,
    },
  ],
  [JourneyState.WaitingForLabResults]: [
    {
      label: 'Results received',
      type: 'moveToState',
      value: JourneyState.WaitingForPartnerResults,
    },
  ],
  [JourneyState.WaitingForPartnerResults]: [],
  [JourneyState.ReadyForReport]: [
    {
      label: 'Create/Edit report',
      type: 'createReport',
      noBatch: true,
    },
    {
      label: 'Send for review',
      type: 'moveToState',
      value: JourneyState.ReadyForReportReview,
    },
  ],
  [JourneyState.ReadyForReportReview]: [
    {
      label: 'Ready for consult',
      type: 'moveToState',
      value: JourneyState.RequireResultBooking,
    },
  ],
  [JourneyState.RequireResultBooking]: [
    {
      label: 'Booked',
      type: 'moveToState',
      value: JourneyState.WaitingForResultConsult,
    },
  ],
  [JourneyState.WaitingForResultConsult]: [
    {
      label: 'Consult complete',
      type: 'moveToState',
      value: JourneyState.ReadyToReleaseResults,
    },
  ],
  [JourneyState.ReadyToReleaseResults]: [
    {
      label: 'Send reports',
      type: 'createMemberReportEmail',
    },
    {
      label: 'Release results',
      type: 'moveToState',
      value: JourneyState.ReadyToArchive,
    },
  ],
  [JourneyState.ReadyToArchive]: [
    {
      label: 'Complete',
      type: 'moveToState',
      value: JourneyState.Complete,
    },
  ],
  [JourneyState.Complete]: null,
  [JourneyState.CloseLost]: null,
  [JourneyState.Trash]: null,
};

export const JOURNEY_STATE_ICONS: { [key: string]: StyledIcon } = {
  [JourneyState.RequireDetails]: FiEdit,
  [JourneyState.RequirePretestBooking]: FiClock,
  [JourneyState.WaitingForPretestConsult]: FiCalendar,
  [JourneyState.WaitingForLabResults]: FiInbox,
  [JourneyState.WaitingForPartnerResults]: FiInbox,
  [JourneyState.ReadyForReport]: FiFileText,
  [JourneyState.ReadyForReportReview]: FiEye,
  [JourneyState.RequireResultBooking]: FiClock,
  [JourneyState.WaitingForResultConsult]: FiCalendar,
  [JourneyState.ReadyToReleaseResults]: FiSend,
  [JourneyState.ReadyToArchive]: FiArchive,
  [JourneyState.Complete]: FiThumbsUp,
  [JourneyState.CloseLost]: FiTrash,
  [JourneyState.Trash]: FiTrash,
};
