import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import RequestForm, { RequestFormValues } from '../../../components/request/RequestForm';
import GenieQuery from '../../base/GenieQuery';
import { QueryResult } from 'react-apollo';
import Spinner from '../../../components/widgets/Spinner';
import { SearchAutocompleteOption } from '../../../components/widgets/SearchAutocomplete';
import { Member, Query } from '../../../../graphql/genie-api-types';
import JourneyCreate from '../journeys/JourneyCreate';
import { appSyncClient } from '../../../../utilities/appSync';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import { ProductSelectValue } from '../../../components/widgets/form/ProductSelect';
import { convertLocalToUTC, convertUTCToLocal } from '../../../../utilities/helpers';
import { DOCTOR_CLINIC_FRAGMENT } from '../../../../utilities/doctorClinic';

interface RequestUpdateProps {
  requestId: string;
  onComplete(): void;
}

interface RequestUpdateState {
  step: 'updateRequest' | 'createJourney';
  createdMember?: Pick<Member, 'name' | 'id'>;
}

class RequestUpdate extends React.PureComponent<RequestUpdateProps, RequestUpdateState> {
  state: Readonly<RequestUpdateState> = {
    step: 'updateRequest',
  };

  onSwitchToCreateJourney = () => {
    this.setState({ step: 'createJourney' });
  }

  onBackToRequestForm = () => {
    appSyncClient.queryManager.reFetchObservableQueries();
    this.setState({ step: 'updateRequest' });
  }

  renderRequestForm({ request }: Pick<Query, 'request'>) {
    const { onComplete } = this.props;
    const { createdMember } = this.state;
    const {
      id,
      initiatingMember,
      referringDoctorClinic,
      primaryCounsellor,
      product,
      productVariant,
      datePaid,
      pregnancy,
      journeys,
      source,
      reason,
      riskStatus,
      numGenesTestedRecessive,
      numGenesTestedXLinked
    } = request;

    const productAndVariant: ProductSelectValue = {
      product,
      productVariant,
    }

    return (
      <GenieMutation
        mutation={gql`
          mutation UpdateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                product
                productVariant
                pregnancy {
                  days
                  dueDate
                  dueDateConfirmed
                }
                datePaid
                initiatingMember {
                  id
                  name
                }
                referringDoctorClinic {
                  ...DoctorClinicFields
                }
                primaryCounsellor { # For updating JourneyTable
                  id
                  name
                }
                reason
                riskStatus
                source
                numGenesTestedRecessive
                numGenesTestedXLinked
              }
            }
          }
          ${DOCTOR_CLINIC_FRAGMENT}
        `}
      >
        {(mutate, result) => (
          <RequestForm
            initialValues={{
              productAndVariant,
              pregnancy: {
                dueDate: pregnancy?.dueDate,
                dueDateConfirmed: pregnancy?.dueDateConfirmed,
              },
              datePaid: convertUTCToLocal(datePaid),
              initiatingMember: createdMember as SearchAutocompleteOption || initiatingMember as SearchAutocompleteOption,
              referringDoctorClinic: referringDoctorClinic as SearchAutocompleteOption,
              primaryCounsellor: primaryCounsellor as SearchAutocompleteOption,
              reason,
              riskStatus,
              source,
              numGenesTestedRecessive,
              numGenesTestedXLinked
            }}
            onSubmit={async ({ initiatingMember, referringDoctorClinic, datePaid, primaryCounsellor, productAndVariant, riskStatus, ...restOfFormValues }: RequestFormValues) => {
              const { product, productVariant } = productAndVariant;              
              await mutate({
                variables: {
                  input: {
                    requestId: id,
                    datePaid: convertLocalToUTC(datePaid),
                    initiatingMemberId: initiatingMember?.id,
                    referringDoctorClinicId: referringDoctorClinic?.id ?? null,
                    primaryCounsellorId: primaryCounsellor?.id ?? null,
                    product,
                    productVariant,
                    riskStatus,
                    ...restOfFormValues,
                  },
                },
              });
              ReduxQueryListener.triggerEvents('All');
              showToast('success', 'Request updated', 'Saved');
              onComplete();
            }}
            isLoading={result.loading}
            errorMessage={result?.error?.message}
            onNewJourney={this.onSwitchToCreateJourney}
            attachedJourneys={journeys}
          />
        )}
      </GenieMutation>
    );
  }

  renderCreateJourneyForm() {
    const { requestId } = this.props;
    return (
      <JourneyCreate
        onCreate={this.onBackToRequestForm}
        onCancel={this.onBackToRequestForm}
        requestId={requestId}
      />
    );
  }

  renderForm(requestResult: Pick<Query, 'request'>) {
    const { step } = this.state;
    switch (step) {
      case 'updateRequest':
        return this.renderRequestForm(requestResult);

      case 'createJourney':
        return this.renderCreateJourneyForm();
    }
  }

  render() {
    const { requestId } = this.props;
    return (
      <GenieQuery
        query={gql`
          query Request($id: ID!) {
            request(id: $id) {
              id
              product
              productVariant
              initiatingMember {
                id
                name
              }
              datePaid
              source
              reason
              riskStatus
              primaryCounsellor {
                id
                name
              }
              referringDoctorClinic {
                ...DoctorClinicFields
              }
              pregnancy {
                dueDate
                dueDateConfirmed
              }
              journeys {
                id
                member {
                  id
                  name
                }
                startDate
                state
              }
              numGenesTestedRecessive
              numGenesTestedXLinked
            }
          }
          ${DOCTOR_CLINIC_FRAGMENT}
        `}
        errorPolicy="all"
        variables={{
          id: requestId,
        }}
      >
        {({ loading, data }: QueryResult<Pick<Query, 'request'>>) => loading ?
          (
            <Spinner label="Loading request..." />
          ) : this.renderForm(data)}
      </GenieQuery>
    );
  }
}

export default RequestUpdate;
