import { action } from 'typesafe-actions';
import { ExportReportActionTypes } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../..';
import { downloadReportPDF } from '../../../../utilities/report/html/generator';
import { showLoadingToast, hideToast, showToast } from '../../toast/actions';

export const completeExport = () =>
  action(ExportReportActionTypes.COMPLETE_EXPORT);

export const downloadPDF = () =>
  async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const loadingId = `download-${new Date().getTime()}`;
    const { report } = getState();
    dispatch(showLoadingToast(loadingId, 'Generating PDF', 'Should be downloaded in a moment...'));

    try {
      await downloadReportPDF(report);
      showToast('success', 'PDF Export Complete', 'Your file was downloaded.');
    } catch (e) {
      showToast('error', 'Error generating PDF', e.message);
    }

    dispatch(hideToast(loadingId));
  };
