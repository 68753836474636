import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { JourneysConnection, JourneyState, Product, QueryListJourneysArgs, ProductVariant, Journey, Pregnancy, Member } from '../../../graphql/genie-api-types';
import { getProductIcon } from '../../../utilities/journeys/products';
import useStatsQuery from '../../../utilities/useStatsQuery';
import Badge from '../../components/widgets/Badge';
import Table from '../../components/widgets/Table';
import { createReportFromRequest } from '../../store/report/actions';
import { navigate } from '../../store/router/actions';
import { PATHS } from '../../store/router/types';
import PregnancyIcon from '../../components/widgets/PregnancyIcon';
import RecollectionIcon from '../../components/widgets/RecollectionIcon';
import { flatten, sortBy } from 'lodash';
import PrivateContent from '../widgets/PrivateContent';
import DateBadge, { getWeeksLabel } from '../widgets/DateBadge';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';
import MemberlessPlaceholder from '../member/MemberlessPlaceholder';
import { formatMemberName } from '../../../utilities/helpers';

const READY_FOR_REPORT_JOURNEYS = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          hasRecollection
          request {
            id
            datePaid
            product
            productVariant
            initiatingMember {
              id
            }
            pregnancy {
              days
              dueDate
              dueDateConfirmed
            }
            report {
              id
              dateCreated
            }
          }
          kits {
            id
            dateShippedToLab
            recollectionRequestedDate
          }
          history {
            date
            fromState
            toState
          }
          member {
            id
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

interface RequestRow {
  isProbablyAmendedReport: boolean;
  requestId: string;
  primaryJourneyId: string;
  members: Member[];
  product: Product;
  productVariant: ProductVariant;
  reportCreated: string;
  datePaid: string;
  pregnancy?: Pregnancy;
  dateShippedToLab: string;
  hasRecollection: boolean;
  initiatingMember: Member;
}

type RowActions = 'viewReport' | 'createReport';

interface ReportsTaskTableProps {
  journeyState: JourneyState;
}

const ReportsTaskTable = (props: ReportsTaskTableProps) => {
  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  const readyForReportJourneys = useStatsQuery<JourneysConnection, QueryListJourneysArgs>(READY_FOR_REPORT_JOURNEYS, {
    variables: {
      input: {
        state: props.journeyState,
      },
    },
    fetchPolicy: 'network-only',
  }, 'listJourneys');

  const dispatch = useDispatch();

  const requestRowData: RequestRow[] = React.useMemo(() => {
    const journeysByRequestId: { [key: string]: Partial<Journey>[] } = {};

    const journeyNodes = readyForReportJourneys.data?.edges?.map(edge => edge.node) ?? [];
    journeyNodes.forEach(journeyNode => {
      if (!journeysByRequestId[journeyNode.request.id]) {
        journeysByRequestId[journeyNode.request.id] = [];
      }
      journeysByRequestId[journeyNode.request.id].push(journeyNode);
    });

    return sortBy<RequestRow>(Object.values(journeysByRequestId).map((journeyNodes) => {
      const request = journeyNodes[0].request;
      const hasRecollection = !!journeyNodes?.find(journey => journey.hasRecollection);
      const isProbablyAmendedReport = journeyNodes?.filter(journey => !!journey.history.find(historyItem => [JourneyState.Complete, JourneyState.ReadyToArchive].includes(historyItem.fromState))).length === journeyNodes.length;

      const latestLabShippingDate = sortBy(flatten(journeyNodes.map(journeyNode => journeyNode.kits))
        .filter(kit => !kit.recollectionRequestedDate)
        .map(kit => kit.dateShippedToLab)
        , (labShippingDate => moment(labShippingDate).unix())).reverse()[0];

      return {
        requestId: request.id,
        primaryJourneyId: journeyNodes[0].id,
        members: journeyNodes?.map(journey => journey.member),
        product: request.product,
        productVariant: request.productVariant,
        reportCreated: request.report?.dateCreated,
        datePaid: request.datePaid,
        pregnancy: request.pregnancy,
        hasRecollection,
        isProbablyAmendedReport,
        dateShippedToLab: latestLabShippingDate,
        initiatingMember: request.initiatingMember,
      };
    }), ((requestRow: RequestRow) => {
      return moment(requestRow.dateShippedToLab).unix();
    })) ?? [];
  }, [readyForReportJourneys.data]);

  const pregnantRequestRows = React.useMemo(() => {
    return requestRowData.filter(requestRow => !!requestRow.pregnancy && !requestRow.isProbablyAmendedReport);
  }, [requestRowData]);

  const nonPregnantRequestRows = React.useMemo(() => {
    return requestRowData.filter(requestRow => !requestRow.pregnancy  && !requestRow.isProbablyAmendedReport);
  }, [requestRowData]);

  const onActionClick = React.useCallback((rowIds: string[], actionId: RowActions) => {
    const requestId = rowIds[0];
    const requestRow = requestRowData.find(requestRow => requestRow.requestId === requestId);
    const journeyId = requestRow.primaryJourneyId;

    switch (actionId) {
      case 'createReport':
        dispatch(createReportFromRequest(requestId));
        onSelectJourney(journeyId);
        break;

      case 'viewReport':
        dispatch(navigate.toReportPath(PATHS.REPORTS_CLIENT_DETAILS, 'remote', requestId))
        onSelectJourney(journeyId);
        break;
    }
  }, [dispatch, createReportFromRequest, requestRowData]);

  const onRowSelect = React.useCallback((requestId: string) => {
    const requestRow = requestRowData.find(requestRow => requestRow.requestId === requestId);
    const journeyId = requestRow.primaryJourneyId;

    onSelectJourney(journeyId);
  }, [requestRowData, dispatch]);

  const titles = [
    'Pregnant',
    'Non-pregnant'
  ];

  const requestIdToFixedNumber = (requestId: string) => {
    const char = requestId.charAt(0);
    const decimal = parseInt(char, 16);
    return (decimal % 4) + 1;
  
  }
  return (
    <div>
      {[pregnantRequestRows, nonPregnantRequestRows].map((requestRows, index) => (
        <div className="mb-30" key={titles[index]}>
          <h2 className="text-lg font-bold mb-10">{titles[index]}</h2>
          <Table
            header={['Allotment', 'Names', 'Product', 'Purchased', 'Shipped to lab']}
            alwaysShowActions
            onActionClick={onActionClick}
            onRefresh={readyForReportJourneys.refetch}
            isLoading={readyForReportJourneys.isLoading}
            onRowSelect={onRowSelect}
            rowGroups={requestRows.map(requestRow => ({
              id: requestRow.requestId,
              rows: [
                {
                  id: requestRow.requestId,
                  cells: [
                    <span key="computed-id">{requestIdToFixedNumber(requestRow.requestId)}</span>,
                    <React.Fragment key="names">
                      {requestRow.members.map(member => member
                        ? <Badge className="mr-5" key={member.name}><PrivateContent>{formatMemberName(member, requestRow)}</PrivateContent></Badge>
                        : <MemberlessPlaceholder/>
                      )}
                      {requestRow.pregnancy && <PregnancyIcon pregnancy={requestRow.pregnancy} />}
                      {requestRow.hasRecollection && <RecollectionIcon />}
                    </React.Fragment>,
                    getProductIcon(requestRow.product, requestRow.productVariant),
                    getWeeksLabel(requestRow.datePaid),
                    <DateBadge dateString={requestRow.dateShippedToLab} orangeDays={21} redDays={28} key="date" />,
                  ],
                  actions: [
                    requestRow.reportCreated ? {
                      name: 'viewReport',
                      label: 'View report',
                      variant: 'success',
                    } :
                      {
                        name: 'createReport',
                        label: 'Create report',
                        variant: 'primary',
                      },
                  ],
                },
              ],
            }))}
          />
          <div className='flex border-t border-b p-10 border-grey-mid bg-grey-light font-bold text-sm font-lt text-grey-darkest justify-between'><span>Total rows:</span><span>{requestRows.length}</span></div>
        </div>
      ))
      }
    </div>
  );
};

export default ReportsTaskTable;
