import * as React from 'react';
import SwitchForm, { AnyModelResult, FormName } from '../graphql/containers/SwitchForm';

type ModelResults<FormParamNames extends string> = {[key in FormParamNames]?: AnyModelResult};

interface MultiModelFormProps<FormParamNames extends string> {
  modelForms: {
    [key in FormParamNames]: FormName;
  };
  initialModelValues?: {
    [key in FormParamNames]?: AnyModelResult;
  };
  children(onSwitchToModelForm: (modelFormParamName: FormParamNames) => void,
    onChangeModelValue: (modelFormParamName: FormParamNames, model: AnyModelResult) => void,
    modelValues: ModelResults<FormParamNames>): React.ReactNode;
}

export interface MultiModelFormState<FormParamNames extends string> {
  formParam: FormParamNames | null;
  modelValues: ModelResults<FormParamNames>;
}

export default class MultiModelForm<FormParamNames extends string> extends React.PureComponent<MultiModelFormProps<FormParamNames>, MultiModelFormState<FormParamNames>> {
  constructor(props: MultiModelFormProps<FormParamNames>) {
    super(props);
    
    this.state = {
      formParam: null,
      modelValues: props.initialModelValues || {},
    }
  }

  getFormNameForformParam() {
    const { formParam } = this.state;
    const { modelForms } = this.props;
    return modelForms[formParam];
  }

  getMainFormStyle() {
    const { formParam } = this.state;
    if (!formParam) {
      return null;
    }

    // Hide but don't remove the main form when other forms are active to retain
    // existing form values.
    return {
      display: 'none',
    };
  }
  
  onChangeformParam = (formParam: FormParamNames|null) => {
    this.setState({
      formParam,
    });
  }

  onModelFormComplete = (model: AnyModelResult) => {
    const { formParam } = this.state;
    
    this.onSetModelValue(formParam, model);
    this.onChangeformParam(null);
  }

  onModelFormCancel = () => {
    this.onChangeformParam(null);
  }
  
  onSetModelValue = (paramName: FormParamNames, model: AnyModelResult) => {
    this.setState({
      modelValues: {
        ...this.state.modelValues,
        [paramName]: model,
      },
    });
  }

  renderModelForm() {
    const formName = this.getFormNameForformParam();
    if (!formName) {
      return null;
    }

    return (
      <SwitchForm
        form={formName}
        onComplete={this.onModelFormComplete}
        onCancel={this.onModelFormCancel}
      />
    );

  }

  render() {
    const { children } = this.props;
    const { modelValues } = this.state;
    return (
      <div>
        {this.renderModelForm()}
        <div style={this.getMainFormStyle()}>
          {children(this.onChangeformParam, this.onSetModelValue, modelValues)}
        </div>
      </div>
    );
  }
}