import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { Request, Journey, JourneyState, QueryListRequestsArgs, RequestsConnection, Sex } from '../../../graphql/genie-api-types';
import { copyToClipboard, extractNameParts, formatMemberName, journeyToInvitaeBookmarkletData, trimLabOrderNumber } from '../../../utilities/helpers';
import { getProductIcon } from '../../../utilities/journeys/products';
import useStatsQuery from '../../../utilities/useStatsQuery';
import Badge from '../../components/widgets/Badge';
import Button from '../../components/widgets/Button';
import SingleFieldForm from '../../components/widgets/form/SngleFieldForm';
import PregnancyIcon from '../../components/widgets/PregnancyIcon';
import PrivateContent from '../../components/widgets/PrivateContent';
import Screen from '../../components/widgets/Screen';
import Table, { RowGroup } from '../../components/widgets/Table';
import ChangeLabOrderNumber from '../../graphql/containers/journeys/ChangeLabOrderNumber';
import { ORDERED_JOURNEY_STATES } from '../../../utilities/journeys/states';
import RequestNotes from '../../graphql/containers/requests/RequestNotes';
import Spinner from '../../components/widgets/Spinner';
import CreateRequestNote from '../../graphql/containers/requests/CreateRequestNote';
import NoteForm from '../../components/notes/NoteForm';
import Note from '../../components/widgets/Note';
import { flatten, sortBy } from 'lodash';
import DateBadge from '../../components/widgets/DateBadge';
import { FiChevronRight, FiCopy } from '../../components/widgets/Icon';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';
import MemberlessPlaceholder from '../../components/member/MemberlessPlaceholder';
import { labOrderNumberUrl } from '../../../utilities/journeys/selectors';
import { RequestPanelInformation } from '../../components/journey/show/RequestPanelInformation';

const LIST_ALL_REQUESTS = gql`
  query ListRequests($input: ListRequestsInput!) {
    listRequests(input: $input) {
      edges {
        cursor
        node {
          id
          product
          productVariant
          gcApprovalDate
          datePaid
          numGenesTestedRecessive
          numGenesTestedXLinked
          initiatingMember {
            id
          }
          order {
            data
          }
          pregnancy {
            days
            dueDate
            dueDateConfirmed
          }
          journeys {
            id
            state
            addressProvince
            addressCountryCode
            labOrderNumber
            member {
              id
              name
              sex
              email
              dateOfBirth
              familyHistoryGroup {
                id
                name
                value
              }
            }
            kits {
              id
              dateArrivedAtEugene
              recollectionRequestedDate
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

interface CopiableFieldProps {
  text: string;
  className?: string;
  wrapperClassName?: string;
}

const CopiableField = (props: CopiableFieldProps) => {
  const onFieldClick = React.useCallback(() => {
    copyToClipboard(props.text);
  }, [props.text, copyToClipboard]);
  return (
    <div className={`${props.wrapperClassName} w-full flex-1 group relative`}>
      <PrivateContent className={`w-full bg-white rounded border border-transparent hover:border-purple font-bold p-10 flex-1 cursor-pointer block ${props.className ?? ''}`}>
        <input
          readOnly
          type="text"
          className={`w-full bg-white rounded border border-transparent hover:border-purple font-bold p-10 flex-1 cursor-pointer ${props.className ?? ''}`}
          value={props.text}
          onClick={onFieldClick}
        />
      </PrivateContent>
      <div className="group-hover:block hidden absolute top-0 right-0 bottom-0 py-10 px-5 text-purple bg-white m-5 pointer-events-none"><FiCopy /></div>
    </div>
  );
};

const renderJourneyPanel = (request: Request, journey: Journey, partnerJourneys: Journey[] | null, onNavigateToJourney: (journeyId: string) => void) => {
  const names = extractNameParts(journey?.member?.name ?? '', true);
  const ethnicity = journey?.member?.familyHistory?.find(familyHistoryGroupItem => familyHistoryGroupItem.name === 'Ethnicity')?.value;
  const state = journey?.addressProvince ?? 'unknown';
  const country = journey?.addressCountryCode ?? 'unknown';
  const requestPanelInformation = request?.numGenesTestedRecessive ? RequestPanelInformation(request.numGenesTestedRecessive, request.numGenesTestedXLinked) : null;

  return (
    <div className="w-1/2 m-1 p-20 bg-grey-light">
      <div className="mb-20 font-bold flex justify-between items-center"><span>{journey?.member?.sex}</span>  <Button onClick={() => onNavigateToJourney(journey.id)} size="sm">View Journey</Button></div>
      <div className="flex items-center">
        <div className="mr-10 w-1/3">Name</div>
        <div className="w-2/3 flex">
          <CopiableField text={names?.firstName} wrapperClassName="w-1/3 mr-5" />
          {names?.middleInitial && <CopiableField text={names?.middleInitial} wrapperClassName="w-1/3 mr-5" />}
          <CopiableField text={names?.familyName} wrapperClassName="w-1/3" />
        </div>
      </div>
      <div className={"flex items-center mt-10"}>
        <div className="mr-10 w-1/3">Panel Selected</div>
        <div className="w-2/3 flex">
          {requestPanelInformation ? (
            <CopiableField text={`${requestPanelInformation.text}`} wrapperClassName={`w-1/3 ${requestPanelInformation.className}`} />
          ) : (
            <div className="p-4 text-grey">No panel information available</div>
          )}
        </div>
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">DOB (mm/dd/yyyy):</div>
        <CopiableField text={moment(journey?.member?.dateOfBirth).format('MM/DD/Y')} />
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">Ethnicity:</div>
        <CopiableField text={ethnicity ?? 'None in family history'} />
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">Location:</div>
        <CopiableField text={state + ', ' + country} />
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">Email:</div>
        <CopiableField text={journey?.member?.email} />
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">Lab order number:
          {journey.labOrderNumber && (
            <div className="mt-10">
              <a target="_blank" href={labOrderNumberUrl(journey)} rel="noopener noreferrer" className="text-purple bg-grey-light p-3 px-5 text-xs border rounded hover:text-white hover:bg-purple">
                Invitae <FiChevronRight />
              </a>
            </div>
          )}
        </div>
        <div className="bg-white font-bold mr-5 cursor-text">
          <ChangeLabOrderNumber>
            {(mutate, isLoading) => (
              <SingleFieldForm
                placeholder="Enter number"
                isLoading={isLoading}
                onSubmit={value => mutate(journey.id, value)}
                defaultValue={journey.labOrderNumber}
                inputClassName="bg-white font-bold p-10 flex-1"
                transformValue={trimLabOrderNumber}
              />
            )}
          </ChangeLabOrderNumber>
        </div>
      </div>
      <div className="flex items-center mt-10">
        <div className="mr-10 w-1/3">Bookmarklet Data:</div>
        <CopiableField text={JSON.stringify(journeyToInvitaeBookmarkletData(journey, partnerJourneys))} />
      </div>
    </div >
  );
};

const TasksLabOrders = () => {
  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  const requestsStatsQuery = useStatsQuery<RequestsConnection, QueryListRequestsArgs>(LIST_ALL_REQUESTS, {
    variables: {
      input: {
        isGcApproved: true,
        excludeOnHold: true,
        hasReport: false,
      }
    },
    errorPolicy: 'ignore',
  }, 'listRequests');

  const rowGroups: RowGroup[] = React.useMemo(() => {
    return sortBy(requestsStatsQuery?.data?.edges?.map(edge => edge.node)
      .filter((request) => {
        const activeJourneys = request?.journeys?.filter(journey => journey.state !== JourneyState.Trash) ?? [];
        const journeysWithLabOrderNumbers = activeJourneys.filter(journey => journey.state !== JourneyState.Trash && journey.labOrderNumber);
        const hasIncompleteJourney = activeJourneys.find(journey => ORDERED_JOURNEY_STATES.indexOf(journey.state) < ORDERED_JOURNEY_STATES.indexOf(JourneyState.ReadyForReport));
        return journeysWithLabOrderNumbers.length !== activeJourneys.length && !!hasIncompleteJourney;
      }), (request) => moment(request.datePaid).unix())
      .map((request) => {
        const kitArrivedDate = sortBy(flatten(request?.journeys?.map(journey => journey.kits)).filter(kit => !kit.recollectionRequestedDate), (kit) => {
          return -moment(kit.dateArrivedAtEugene).unix();
        })?.[0]?.dateArrivedAtEugene;
        const productIcon = getProductIcon(request.product, request.productVariant);

        return {
          id: `group-${request.id}`,
          key: request.id,
          rows: [
            {
              id: request.id,
              cells: [
                (
                  <PrivateContent key="name">
                    {request.journeys?.filter(journey => journey.state !== JourneyState.Trash).map(journey =>
                      journey.member
                        ? <Badge key={journey.member?.id} className="mr-5">{formatMemberName(journey.member, request)}</Badge>
                        : <MemberlessPlaceholder />
                    )}
                    {request?.pregnancy && <PregnancyIcon pregnancy={request?.pregnancy} />}
                  </PrivateContent>
                ),
                productIcon,
                <DateBadge orangeDays={21} redDays={28} dateString={request.datePaid} key="purchase-date" />,
                kitArrivedDate ? <DateBadge orangeDays={21} redDays={28} dateString={kitArrivedDate} key="arrival-date" /> : <Badge>No samples</Badge>,
                <Badge key="gc-approval-date">{moment(request.gcApprovalDate).fromNow()}</Badge>,
              ],
              renderExpansion: function renderExpansion() {
                const femaleJourneys = request?.journeys?.filter(journey => journey.member?.sex === Sex.Female && journey.state !== JourneyState.Trash);
                const maleJourneys = request?.journeys?.filter(journey => journey.member?.sex === Sex.Male && journey.state !== JourneyState.Trash);
                return (
                  <div className="bg-grey-light">
                    <div className="flex">
                      {femaleJourneys?.map((journey) => renderJourneyPanel(request, journey, maleJourneys, onSelectJourney))}
                      {maleJourneys?.map((journey) => renderJourneyPanel(request, journey, femaleJourneys, onSelectJourney))}
                    </div>
                    <div className="border-t pt-20">
                      <RequestNotes
                        requestId={request.id}
                      >
                        {(notes, isLoading) => (
                          <div className="px-20">
                            {isLoading ? <Spinner label="Loading notes..." className="mb-20" /> : null}
                            <CreateRequestNote
                              requestId={request.id}
                            >
                              {(mutation, isLoading) => (
                                <div>
                                  <NoteForm
                                    isLoading={isLoading}
                                    onSubmit={({ text, isImportant }) => mutation(text, isImportant)}
                                  />
                                </div>
                              )}
                            </CreateRequestNote>
                            {notes ? (
                              <div className="pb-10">
                                {notes.map(note => <Note note={note} key={note.date} />)}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </RequestNotes>
                    </div>
                  </div>
                );
              },
            }
          ],
        };
      }) ?? [];
  }, [requestsStatsQuery.data]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Approved requests ready for a lab order'
      }}
    >
      <Table
        header={['Name', 'Product', 'Purchased', 'Samples received', 'Approved']}
        rowGroups={rowGroups}
        onRefresh={requestsStatsQuery.refetch}
        isLoading={requestsStatsQuery.isLoading}
      />
    </Screen>
  );
};

export default TasksLabOrders;