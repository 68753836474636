import * as React from 'react';
import { FamilyHistoryClassification } from '../../../graphql/genie-api-types';
import { FiInfo, FiCheck, FiAlertTriangle } from '../widgets/Icon';

interface FamilyHistoryClassificationIconProps {
  classification: FamilyHistoryClassification;
}

const FamilyHistoryClassificationIcon = ({ classification }: FamilyHistoryClassificationIconProps) => {
  switch (classification) {
    case FamilyHistoryClassification.Alert:
      return <FiAlertTriangle />;

    case FamilyHistoryClassification.Extraneous:
    case FamilyHistoryClassification.Highlight:
      return <FiInfo />;

    case FamilyHistoryClassification.Info:
      return <FiCheck color="#1ea380" />;

    default:
      return <div>?</div>
  }
};

export default React.memo(FamilyHistoryClassificationIcon);
