import * as React from 'react';
import { startCase } from 'lodash';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { CreateFamilyHistoryGroupItemInput, FamilyHistoryCategory, FamilyHistoryClassification } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';

interface FamilyHistoryFormProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  initialValues: Partial<CreateFamilyHistoryGroupItemInput>;
}

interface FamilyHistoryFormInput extends FormInputProps {
  name: keyof CreateFamilyHistoryGroupItemInput;
}

const categoryOptions: FamilyHistoryCategory[] = [
  FamilyHistoryCategory.Me,
  FamilyHistoryCategory.Pregnancy,
  FamilyHistoryCategory.Children,
  FamilyHistoryCategory.Siblings,
  FamilyHistoryCategory.Parents,
  FamilyHistoryCategory.Niblings,
  FamilyHistoryCategory.Extended,
];

const classificationOptions: FamilyHistoryClassification[] = [
  FamilyHistoryClassification.Alert,
  FamilyHistoryClassification.Extraneous,
  FamilyHistoryClassification.Highlight,
  FamilyHistoryClassification.Info,
];

const formItems: Pick<FamilyHistoryFormInput, 'label' | 'name' | 'as' | 'options' | 'type' | 'min' | 'max'>[] = [
  {
    label: 'Category',
    name: 'category',
    as: 'select',
    options: [
      ...categoryOptions.map(classificationOption => ({
        value: classificationOption,
        label: startCase(classificationOption.toLowerCase()),
      })),
      {
        label: 'Other',
        value: '',
      },
    ],
  },
  {
    label: 'Classification',
    name: 'classification',
    as: 'select',
    options: [
      {
        label: 'Select an option',
        value: '',
      },
      ...classificationOptions.map(classificationOption => ({
        value: classificationOption,
        label: startCase(classificationOption.toLowerCase()),
      }))
    ],
  },
  {
    label: 'Name',
    name: 'name',

  },
  {
    label: 'Value',
    name: 'value',
    as: 'textarea',
  },
];

const FamilyHistoryForm = (props: FamilyHistoryFormProps) => {
  const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = props;

  return (
    <BaseForm
      initialValues={initialValues}
      submitButtonLabel="Save"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={(values) => onSubmit({ category: '', ...values })}
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
          <div>
            {
              formItems.map((formItem, index) => (
                <FormInput
                  {...formItem}
                  value={values[formItem.name]}
                  onChange={handleChange}
                  key={formItem.name}
                  autoFocus={index === 0}
                  setFieldValue={setFieldValue}
                />
              ))
            }
          </div>
        )}
    </BaseForm>
  );
}

export default FamilyHistoryForm;
