import * as React from 'react';
import { QueryResult } from 'react-apollo';
import SearchAutocomplete from '../../components/widgets/SearchAutocomplete';
import GenieQuery from '../base/GenieQuery';
import gql from 'graphql-tag';

type Ethnicity = {
  id: string;
  name: string;
};

interface EthnicitySearchAutocompleteProps {
  onSelect(ethnicity: Ethnicity): void;
  defaultValue?: string;
}

interface EthnicityQueryResult {
  searchEthnicity: Ethnicity[];
}

const EthnicitySearchAutocomplete = (props: EthnicitySearchAutocompleteProps) => (
  <GenieQuery
    query={gql`
      query SearchEthnicity($term: String!) {
        searchEthnicity(term: $term) {
          id
          name
        }
      }
    `}
    notifyOnNetworkStatusChange
    variables={{
      term: '',
    }}
  >
    {({ loading, data, fetchMore }: QueryResult<EthnicityQueryResult, {}>) => (
      <SearchAutocomplete
        isLoading={loading}
        options={data && data.searchEthnicity ? data.searchEthnicity.map(({ id, name }: Ethnicity) => ({
          id,
          name,
        })) : []}
        onSearch={(searchQuery: string) => {
          fetchMore({
            variables: {
              term: searchQuery,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
              ...previousResult,
              ...fetchMoreResult,
            }),
          });
        }}
        onSelect={props.onSelect}
        defaultSelected={props.defaultValue ? [{ name: props.defaultValue, id: props.defaultValue }] : undefined}
      />
    )}
  </GenieQuery>
);

export default EthnicitySearchAutocomplete;
