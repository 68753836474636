import * as React from 'react';
import DoctorList from '../../../graphql/containers/doctors/DoctorList';
import Table from '../../../components/widgets/Table';
import { AppDispatch } from '../../../store';
import { navigate } from '../../../store/router/actions';
import { connect } from 'react-redux';
import { ListDoctorsInput } from '../../../../graphql/genie-api-types';
import FilterFormProps, { FilterOption } from '../../../components/widgets/FilterForm';

interface DispatchProps {
  onGoToDoctor(doctorId: string): void;
}

const filterOptions: FilterOption[] = [
  {
    type: 'text',
    name: 'nameSearch' as keyof ListDoctorsInput,
    placeholder: 'Doctor name',
  },
  {
    type: 'text',
    name: 'specialitySearch' as keyof ListDoctorsInput,
    placeholder: 'Speciality',
  },
  {
    type: 'text',
    name: 'clinicNameSearch' as keyof ListDoctorsInput,
    placeholder: 'Clinic name',
  },
  {
    type: 'text',
    name: 'addressSearch' as keyof ListDoctorsInput,
    placeholder: 'Address or city',
  },
];

const DoctorListTable = (props: DispatchProps) => {
  const [searchParams, setSearchParams] = React.useState({} as ListDoctorsInput);

  return (
    <div>
      <FilterFormProps
        options={filterOptions}
        onPress={setSearchParams}
      />
      <DoctorList searchParams={searchParams}>
        {(doctors, isLoading, reloadData) => (
          <Table
            header={['Name', 'Speciality', 'Clinic Name', 'Clinic Suburb']}
            isLoading={isLoading}
            onRefresh={reloadData}
            onRowSelect={props.onGoToDoctor}
            rowGroups={doctors?.map(doctor => ({
              id: doctor.id,
              rows: [{
                id: doctor.id,
                cells: [
                  `${doctor.title ?? ''} ${doctor.nameFirst}${doctor.nameMiddle ? ` (${doctor.nameMiddle})` : ''}${doctor.nameLast ? ` (${doctor.nameLast})` : ''}${doctor.nickname ? ` (${doctor.nickname})` : ''}`,
                  doctor.specialties,
                  doctor.clinics?.map((clinic) => {
                    return `${clinic.name}`
                  }),
                  doctor.clinics?.map((clinic) => {
                    return clinic.addressSuburb ?? '';
                  }),
                ],
              }]
            })) ?? []}
          />
        )}
      </DoctorList>
    </div>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGoToDoctor: (doctorId: string) => dispatch(navigate.toDoctor(doctorId)),
});

export default connect<{}, DispatchProps, {}>(null, mapDispatchToProps)(DoctorListTable);
