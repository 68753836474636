import * as React from 'react';
import { connect } from 'react-redux';
import ClientDetailsForm from '../../../components/report/client-details/ClientDetailsForm';
import { ClientDetailsState, ClientFields, ClientId } from '../../../store/report/client-details/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { changeFields } from '../../../store/report/client-details/actions';
import { RouteComponentProps } from 'react-router';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';
import Modal from '../../../components/widgets/Modal';

interface MatchParams {
  clientId: ClientId;
}

interface ClientDetailEditProps extends ClientDetailsState, RouteComponentProps<MatchParams> {
  onSave(client: ClientId, fields: Omit<ClientFields, 'memberId'>): void;
  onClose(): void;
  product: string;
}

const ClientDetailsEdit = (props: ClientDetailEditProps) => {
  const clientId: ClientId = props.match.params.clientId;
  
  const clientValues = clientId === 'A' ? props.clientA : props.clientB;

  return (
    <Modal
      show
      onHide={props.onClose}
      title={`Client ${clientId}`}
    >
      <ClientDetailsForm
        onSubmit={values => props.onSave(clientId, values)}
        initialValues={clientValues}
        memberId={clientValues.memberId}
        product={props.product}
      />
    </Modal>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  product: report.request.request?.product,
  ...report.clientDetails,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onSave: (client: ClientId, fields: Omit<ClientFields, 'memberId'>) =>
    dispatch(changeFields(client, fields)),
  onClose: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CLIENT_DETAILS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetailsEdit);
