import gql from 'graphql-tag';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { appSyncClient } from '../../../utilities/appSync';
import { QueryRequestArgs, Sex, Journey, JourneyState, Member, Request, JourneyLog, Kit, Product, ProductVariant } from '../../../graphql/genie-api-types';
import { AWS_DATE_FORMAT } from '../../../utilities/helpers';
import { defaultReportState } from '../../store/report';
import saveReportForRequestId from './saveReportForRequestId';
import { DoctorClinicPartial, formatDoctorName } from '../../../utilities/doctorClinic';

interface JourneyData extends Pick<Journey, 'id' | 'state' | 'partnerCanReceiveResults'> {
  member: Pick<Member, 'id' | 'name' | 'nickname' | 'sex' | 'dateOfBirth'>;
  history: Pick<JourneyLog, 'date' | 'fromState' | 'toState'>[];
  kits: Pick<Kit, 'id' | 'dateArrivedAtEugene' | 'recollectionRequestedDate'>[];
}

export interface RequestDataForReport extends Pick<Request, 'id' | 'numGenesTestedRecessive' | 'numGenesTestedXLinked' | 'product' | 'productVariant' | 'primaryCounsellor'> {
  referringDoctorClinic: DoctorClinicPartial;
  journeys: JourneyData[];
}

interface RequestResult {
  request: RequestDataForReport;
}

export const queryRequestData = async (requestId: string): Promise<RequestDataForReport> => {
  const requestResult = await appSyncClient.query<RequestResult, QueryRequestArgs>({
    query: gql`
        query Request($id: ID!) {
          request(id: $id) {
            id
            product
            productVariant
            numGenesTestedRecessive
            numGenesTestedXLinked
            initiatingMember {
              id
            }
            journeys {
              id
              state
              partnerCanReceiveResults
              member {
                id
                name
                nickname
                sex
                dateOfBirth
              }
              history {
                date
                fromState
                toState
              }
              kits {
                id
                dateArrivedAtEugene
                recollectionRequestedDate
              }
            }
            primaryCounsellor {
              id
              name
            }
            referringDoctorClinic {
              id
              doctor {
                title
                nameFirst
                nameMiddle
                nameLast
              }
            }
          }
        }
      `,
    variables: {
      id: requestId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  return requestResult.data.request;
};

export const generateReportStateFromRequestData = (request: RequestDataForReport) => {
  // Ensure female journeys are placed first.
  const requestJourneys = request?.journeys?.filter(journey => journey.state !== JourneyState.Trash).sort((journeyA) => {
    if (journeyA?.member?.sex === Sex.Female) {
      return -1;
    }
    return 1;
  }) ?? [];

  const journeyA = requestJourneys?.[0];
  const journeyB = requestJourneys?.[1];

  // Step 2: Create new report session.
  const reportState = cloneDeep(defaultReportState).report;

  // Step 3: Populate client details.
  const clientJourneyMapping: { [key: string]: JourneyData } = {
    clientA: journeyA,
    clientB: journeyB,
  };
  Object.keys(clientJourneyMapping)
    .filter(clientKey => clientJourneyMapping[clientKey])
    .forEach((clientKey: 'clientA' | 'clientB') => {
      const journey = clientJourneyMapping[clientKey];
      const member = journey?.member;
      const reportProcessedHistoryItem = journey?.history?.find(historyItem =>
        historyItem.fromState === JourneyState.WaitingForLabResults &&
        (historyItem.toState === JourneyState.WaitingForPartnerResults || historyItem.toState === JourneyState.ReadyForReport));

      const latestKit = journey.kits.filter(kit => !kit.recollectionRequestedDate)?.[0];

      const reportProcessedDate = reportProcessedHistoryItem?.date ? moment(reportProcessedHistoryItem?.date).format(AWS_DATE_FORMAT) : null;
      const sampleCollectionDate = latestKit?.dateArrivedAtEugene ? moment(latestKit?.dateArrivedAtEugene).format(AWS_DATE_FORMAT) : null;
      const partnerCanReceiveResults = journey.partnerCanReceiveResults;

      const sexMapping: { [key: string]: 'Female' | 'Male' | '' } = {
        [Sex.Female]: 'Female',
        [Sex.Male]: 'Male',
      };
      const sex = member?.sex;

      reportState.clientDetails[clientKey] = {
        ...reportState.clientDetails[clientKey],
        reportProcessedDate,
        sampleCollectionDate,
        fullName: member?.name ?? '',
        nickname: member?.nickname || (member?.name?.split(' ')[0] ?? ''),
        sex: sexMapping?.[sex] ?? '',
        dob: member?.dateOfBirth ?? '',
        memberId: member?.id,
        partnerCanReceiveResults,
      };
    });

  // Step 4: Populate referring doctor and counsellor and reviewer
  if (request.product === Product.Cancer) {
    reportState.testInformation.eugeneCareTeam = request?.primaryCounsellor?.name ?? 'Zoë Milgrom';
    reportState.customisation.templateName = 'individualCancer';
  } else {
    reportState.testInformation.eugeneCareTeam = `${request?.primaryCounsellor?.name ?? 'Zoë Milgrom'} & Prof. David Amor`;
    reportState.customisation.templateName = request.productVariant === ProductVariant.Couple ? 'coupleCarrier' : 'individualCarrier';
  }
  reportState.testInformation.referringDoctorClinic = formatDoctorName(request?.referringDoctorClinic);
  reportState.testInformation.product = request.product ?? Product.Carrier;
  reportState.testInformation.productVariant = request.productVariant;

  if (request.numGenesTestedRecessive) {
    reportState.testInformation.numberOfGenesTested = request.numGenesTestedRecessive.toString();
  } else {
    reportState.testInformation.numberOfGenesTested = (request.product === Product.Cancer) ? '21' : '268';
  }

  if (request.numGenesTestedXLinked) {
    reportState.testInformation.numberOfXLinkedGenesTested = request.numGenesTestedXLinked.toString();
  } else {
    reportState.testInformation.numberOfXLinkedGenesTested = (request.product === Product.Cancer) ? '' : '21';
  }

  return reportState;
};

const createPrefilledReportForRequest = async (requestId: string) => {
  const request = await queryRequestData(requestId);

  const reportState = generateReportStateFromRequestData(request);

  const createReportResult = await saveReportForRequestId(requestId, reportState);
  if (!createReportResult.data.saveReport.report.id) {
    throw new Error('Report failed to create');
  }

  return createReportResult.data.saveReport.report.id;
};

export default createPrefilledReportForRequest;
