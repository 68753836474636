import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { CreateMemberInput, Mutation, CreateMemberResult } from '../../../../graphql/genie-api-types';
import MemberForm from '../../../components/member/MemberForm';
import { MutationFunction } from 'react-apollo';
import gql from 'graphql-tag';

interface MemberCreateProps {
  onCreate?(member: CreateMemberResult['member']): void;
  onCancel?(): void;
}

const MemberCreate = (props: MemberCreateProps) => (
  <GenieMutation
    mutation={gql`
      mutation CreateMember($input: CreateMemberInput!) {
        createMember(input: $input) {
          member {
            id,
            name,
            nickname,
            email,
            dateOfBirth,
            phoneNumber,
            sex,
          }
        }
      }
    `}
  >
    {(mutate: MutationFunction<Mutation>, result) => (
      <MemberForm
        initialValues={{}}
        onCancel={props.onCancel}
        isLoading={result.loading}
        errorMessage={result?.error?.message ? 'Email already exists' : ''}
        onSubmit={async (memberfields: CreateMemberInput) => {
          const mutationResult = await mutate({
            variables: {
              input: memberfields,
            },
          });

          if (mutationResult && props.onCreate) {
            props.onCreate(mutationResult.data.createMember.member);
          }
        }}
      />
    )
    }
  </GenieMutation>
);

export default MemberCreate;
