import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import { Collapsible } from '../../components/widgets/Collapsible';
import JourneysPostGroup from '../../graphql/containers/journeys/groups/JourneysPostGroup';
import { FiPlus } from '../../components/widgets/Icon';
import RouteButton from '../../components/widgets/RouteButton';
import Screen from '../../components/widgets/Screen';

const JourneysHome = () => (
  <Screen>
    <div className="flex justify-between w-full">
      <Heading level={1}>Journeys</Heading>
      <RouteButton variant="primary" size="sm" query={{ form: 'RequestCreate' }}>
        <span className="flex items-center">
          <FiPlus className="mb-2" />
          <span className="ml-2">New</span>
        </span>
      </RouteButton>
    </div>
    <div className="pl-30">
      <Collapsible
        title="Post"
        className="mt-80"
        iconClasses="-ml-30"
        contentClass="pt-10"
      >
        <JourneysPostGroup />
      </Collapsible>
    </div>
  </Screen>
);

export default JourneysHome;
