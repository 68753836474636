import * as React from 'react';
import { JourneyLog, Kit, JourneyState } from '../../../graphql/genie-api-types';
import { JOURNEY_STATE_ICONS } from '../../../utilities/journeys/states';
import moment from 'moment';
import { lowerCase, upperFirst } from 'lodash';
import { FaParachuteBox, FaPlaneDeparture, FiArrowRight, FiRefreshCcw, FiEdit3  } from '../widgets/Icon';

interface JourneyTimelineProps {
  journeyHistory: Pick<JourneyLog, 'toState' | 'date'>[];
  kits?: Pick<Kit, 'id' | 'dateArrivedAtEugene' | 'dateShippedToLab' | 'dateShippedToMember' | 'memberRegisteredKitOnDate' | 'recollectionRequestedDate' | 'registrationNumber'>[];
}

type DateKey = keyof Pick<Kit, 'dateArrivedAtEugene' | 'dateShippedToLab' | 'dateShippedToMember' | 'memberRegisteredKitOnDate' | 'recollectionRequestedDate'>;

const renderIconForJourneyState = (journeyState: JourneyState) => {
  const Icon = JOURNEY_STATE_ICONS[journeyState];

  if (Icon) {
    return <Icon className="w-5 h-5" />;
  }

  return <div />;
}

interface JourneyTimelineItemProps {
  id: string;
  label: string;
  date: moment.Moment;
  icon: JSX.Element;
  index?: number;
}

const JourneyTimelineItem = (props: JourneyTimelineItemProps) => {
  const { index, label, date, icon } = props;
  return (
    <div className={`inline-flex w-full ${index > 0 ? 'mt-30' : ''}`}>

      <div className="-ml-10 bg-white text-grey pt-10 -mt-10 w-8 h-10 rounded-full">
        {icon}
      </div>

      <div className="flex w-full">
        <div className="flex flex-column flex-wrap w-4/5">
          <span className="text-black ml-4 leading-normal align-text-top font-nm text-sm w-full">
            {label}
          </span>
          <span className="mt-3 ml-4 text-grey text-xs align-middle w-full">
            {date.format('LT [on] Do MMMM YYYY')}
          </span>
        </div>

        <div className="flex w-1/5">
          <span className="text-xs text-grey w-full text-right pr-8 pt-8">
            {`${moment().diff(date, 'days')}d`}
          </span>
        </div>
      </div>

    </div>
  );
};

const JourneyTimelineItemMemo = React.memo(JourneyTimelineItem);

const JourneyTimeline = (props: JourneyTimelineProps) => {
  const { journeyHistory, kits } = props;

  const historyItems: JourneyTimelineItemProps[] = journeyHistory.map((historyItem) => {
    return {
      id: `history-${historyItem.date}`,
      label: upperFirst(lowerCase(historyItem.toState)),
      date: moment(historyItem.date),
      icon: renderIconForJourneyState(historyItem.toState),
    };
  });

  const kitItems: JourneyTimelineItemProps[] = [];

  kits?.forEach((kit) => {
    const kitIdPrefix = `kit-${kit.id}-`;

    const dateKeys: { [key in DateKey]: Pick<JourneyTimelineItemProps, 'label' | 'icon'> } = {
      dateArrivedAtEugene: {
        label: 'Kit: Arrived at Eugene',
        icon: <FaParachuteBox />,
      },
      dateShippedToLab: {
        label: 'Kit: Shipped to lab',
        icon: <FaPlaneDeparture />,
      },
      dateShippedToMember: {
        label: 'Kit: Shipped to member',
        icon: <FiArrowRight />,
      },
      memberRegisteredKitOnDate: {
        label: `Kit: ${kit.registrationNumber} Registered`,
        icon: <FiEdit3 />,
      },
      recollectionRequestedDate: {
        label: 'Kit: Recollected',
        icon: <FiRefreshCcw />,
      },
    }

    Object.keys(dateKeys).forEach((dateKey: DateKey) => {
      if (kit[dateKey]) {
        kitItems.push({
          id: `${kitIdPrefix}-${dateKey}`,
          label: dateKeys[dateKey].label,
          icon: dateKeys[dateKey].icon,
          date: moment(kit[dateKey]),
        });
      }
    });
  });

  const timelineItems = [...historyItems, ...kitItems].sort((a: JourneyTimelineItemProps, b: JourneyTimelineItemProps) =>
    a.date.unix() - b.date.unix());

  return (
    <div className="timeline mt-15 ml-2">
      <div className="w-full ml-5 border-l-2 mt-3 border-purple-lighter">
        {timelineItems.map((timelineItem, index) => (
          <JourneyTimelineItemMemo {...timelineItem} key={timelineItem.id} index={index} />
        ))}
      </div>
    </div>
  );
};

export default JourneyTimeline;
