import * as React from 'react';
import { Product } from '../../../../graphql/genie-api-types';
import { formatPartnerConsent } from '../../../../utilities/helpers';
import { ClientFields } from '../../../store/report/client-details/types';
import Badge from '../../widgets/Badge';

interface FieldAttributes {
  label: string;
  display?: 'tag';
}

interface ClientFieldProps {
  value?: string;
  values?: string[];
  attributes: FieldAttributes;
}

interface ClientProps extends ClientFields {
  defaultHeaderText: string;
  product?: string;
}

const ClientField = (props: ClientFieldProps) => {
  let valueDisplay = null;

  switch (props.attributes.display) {
    case 'tag':
      valueDisplay = props.values
        ? props.values.map(gene => (
            <Badge color="grey-darkest" textColor="white" key={gene} className="mr-5">
              {gene}
            </Badge>
          ))
        : '';
      break;

    default:
      valueDisplay = props.value;
      break;
  }

  valueDisplay = valueDisplay || <span className="text-grey">Not specified</span>;

  return (
    <div className="my-10">
      <strong className="block mb-5">{props.attributes.label}:</strong> {valueDisplay}
    </div>
  );
};

const Client = (props: ClientProps) => {
  const fieldProps: { [index: string]: FieldAttributes } = {
    fullName: {
      label: 'Full name',
    },
    sex: {
      label: 'Sex assigned at birth',
    },
    dob: {
      label: 'Date of Birth',
    },
    reportProcessedDate: {
      label: 'Report processed date',
    },
    sampleCollectionDate: {
      label: 'Sample collection date',
    },
    affectedGenes: {
      label: 'Affected Genes',
      display: 'tag',
    },
    pseudogenes: {
      label: 'Pseudogenes',
      display: 'tag',
    },
    ethnicity: {
      label: 'Ethnicity',
    },
    partnerCanReceiveResults: {
      label: 'Agree to share result with partner',
    },
  };

  return (
    <div>
      <h3 className="text-2xl font-serif mb-20">{props.nickname || props.defaultHeaderText}</h3>
      <ClientField attributes={fieldProps['fullName']} value={props.fullName} />
      <ClientField attributes={fieldProps['sex']} value={props.sex} />
      <ClientField attributes={fieldProps['dob']} value={props.dob} />
      <ClientField attributes={fieldProps['reportProcessedDate']} value={props.reportProcessedDate} />
      <ClientField attributes={fieldProps['sampleCollectionDate']} value={props.sampleCollectionDate} />

      {props.product !== Product.Cancer && (
        <>
          <ClientField attributes={fieldProps['partnerCanReceiveResults']} value={props.partnerCanReceiveResults ? formatPartnerConsent(props.partnerCanReceiveResults) : 'Missing result consent'} />
          <ClientField attributes={fieldProps['affectedGenes']} values={props.affectedGenes} />
          <ClientField attributes={fieldProps['pseudogenes']} values={props.pseudogenes} />
          <ClientField attributes={fieldProps['ethnicity']} value={props.ethnicity} />
        </>
      )}
    </div>
  );
};

export default Client;
