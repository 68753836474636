import * as React from 'react';

export interface SelectOption<SelectOptionValues extends string> {
  value: SelectOptionValues;
  label: string;
}

interface SelectProps<SelectOptionValues extends string> {
  onChange(optionValue: SelectOptionValues): void;
  hasBlankValue?: boolean;
  options: SelectOption<SelectOptionValues>[];
  selectedValue: string;
  className?: string;
  disabled?: boolean;
  name?: string;
}

function Select<SelectOptionValues extends string = string>(props: SelectProps<SelectOptionValues>) {
  return (
    <select
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.onChange(e.target.value as string as SelectOptionValues)}
      className={`bg-grey-light max-w-full w-full ${props.className ? props.className : ''}`}
      value={props.selectedValue}
      disabled={props.disabled}
      name={props.name}
    >
      {props.hasBlankValue ? (
        <option value="">-</option>
      ) : null}
      {props.options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Select;
