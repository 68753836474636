import * as React from 'react';
import Select from 'react-select';

interface ReportMacroSelectorProps {
  onSelect(text: string): void;
}

interface Macro {
  label: string;
  value: string;
}

const macros: Macro[] = [
  {
    label: 'Does not test for FHx condition',
    value: '[NAME] has told us that a number of her relatives have been diagnosed with [DISEASE] and understands that Eugene\'s reproductive carrier screening is not a comprehensive screen for [DISEASE] genes.'
  },
];

const ReportMacroSelector = (props: ReportMacroSelectorProps) => {
  return (
    <Select
      options={macros}
      placeholder="Insert macro..."
      onChange={(option) => props.onSelect(option.value)}
      className="mb-10"
      value={null}
      styles={{
        control: (provided) => ({
          ...provided,
        }),
      }}
      formatOptionLabel={(option) => (
        <>
          <h3 className="font-bold mb-5">{option.label}</h3>
          <p className="text-grey-dark text-sm">{option.value}</p>
        </>
      )}
    />
  );
};

export default ReportMacroSelector;
