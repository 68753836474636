import * as React from 'react';
import './styles/screen-title.scss';
import Heading from './Heading';
import Spinner from './Spinner';

export interface ScreenTitleProps {
  title: string;
  action?: React.ReactNode;
  isLoading?: boolean;
}

const ScreenTitle = (props: ScreenTitleProps) => (
  <div className="justify-between items-center flex screen-title mb-20 text-blue-darkest">
    <Heading level={1}>{props.title}</Heading>
    {props.action ? props.action : null}
    {props.isLoading &&  <Spinner />}
  </div>
);

export default ScreenTitle;
