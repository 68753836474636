import * as React from 'react';
import { Product } from '../../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../../widgets/BaseForm';
import FormInput, { FormInputProps } from '../../widgets/form/FormInput';

interface TestInformationFormProps {
  initialValues: BaseFormProps['initialValues'];
  onSubmit: BaseFormProps['onSubmit'];
  product: string;
}

const formItems: Pick<FormInputProps, 'label'|'name'|'as'|'options'|'type'>[] = [
  {
    label: 'Product',
    name: 'productAndVariant',
    type: 'productAndVariantSelect',
  },
  {
    label: 'Number of Recessive Genes Tested',
    name: 'numberOfGenesTested',
  },
  {
    label: 'Number of X-Linked Genes Tested',
    name: 'numberOfXLinkedGenesTested',
  },
  {
    label: 'Referring Doctor',
    name: 'referringDoctorClinic',
    as: 'textarea',
  },
  {
    label: 'Eugene Care Team',
    name: 'eugeneCareTeam',
    as: 'textarea',
  },
];

const cancerFormItems: Pick<FormInputProps, 'label'|'name'|'as'|'options'|'type'>[] = [
  {
    label: 'Number of Genes Tested',
    name: 'numberOfGenesTested',
  },
  {
    label: 'Referring Doctor',
    name: 'referringDoctorClinic',
    as: 'textarea',
  },
  {
    label: 'Reviewed By',
    name: 'reviewedBy',
    as: 'textarea',
  },
  {
    label: 'Eugene Care Team',
    name: 'eugeneCareTeam',
    as: 'textarea',
  },
];

const TestInformationForm = (props: TestInformationFormProps) => (
  <BaseForm
    initialValues={props.initialValues}
    onSubmit={props.onSubmit}
    submitButtonLabel="Save"
  >
    {({
      handleChange,
      values,
      setFieldValue,
    }) => (
        <div>
          {(props.product === Product.Cancer ? cancerFormItems : formItems).map((formItem, index) => (
            <div key={formItem.name}>
              <FormInput
                {...formItem}
                value={values[formItem.name]}
                onChange={handleChange}
                key={formItem.name}
                autoFocus={index === 0}
                setFieldValue={setFieldValue}
              />
            </div>
          ))}
        </div>
      )}
  </BaseForm>
);

export default TestInformationForm;
