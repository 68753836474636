import * as React from 'react';
import { Mutation, Query, Member, QueryMemberArgs, MutationUpdateMemberArgs } from '../../../../graphql/genie-api-types';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '../../../components/widgets/Button';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';

interface MemberOnlineAccessProps {
  onUpdate?(): void;
  onCancel?(): void;
  memberId: Member['id'];
}

const MemberOnlineAccess = (props: MemberOnlineAccessProps) => {
  const memberQuery = useQuery<Pick<Query, 'member'>, QueryMemberArgs>(gql`
    query Member($id: ID!) {
        member(id: $id) {
          id
          onlineAccessEnabled
          email
          name
        }
    }
  `, {
    variables: {
      id: props.memberId,
    }
  });

  const [memberMutation] = useMutation<Pick<Mutation, 'updateMember'>, MutationUpdateMemberArgs>(gql`
    mutation UpdateMember($input: UpdateMemberInput!) {
      updateMember(input: $input) {
        member {
          id
          onlineAccessEnabled
        }
      }
    }
    `);

  const member = memberQuery?.data?.member;
  const isOnlineAccessEnabled = member?.onlineAccessEnabled;

  const onClick = async () => {
    await memberMutation({
      variables: {
        input: {
          memberId: props.memberId,
          onlineAccessEnabled: !isOnlineAccessEnabled,
        },
      }
    });
    props.onUpdate();
  };

  if (memberQuery.loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <div className="border-b font-bold p-10 mb-10">{member?.name} ({member?.email})</div>
      <Button variant={isOnlineAccessEnabled ? 'danger' : 'success'} onClick={onClick}>
        {isOnlineAccessEnabled ? 'Disable ' : 'Enable '}online access
      </Button>
    </>
  );
};

export default MemberOnlineAccess;
