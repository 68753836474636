import { action } from 'typesafe-actions';
import { SearchActionTypes, MemberSearchState, RequestSearchState, JourneySearchState, DoctorSearchState, SearchMode } from './types';

export const changeSearchMode = (searchMode: SearchMode) =>
  action(SearchActionTypes.CHANGE_SEARCH_MODE, searchMode);

export const changeMemberSearch = (state: MemberSearchState) =>
  action(SearchActionTypes.CHANGE_MEMBER_SEARCH, state);

export const changeRequestSearch = (state: RequestSearchState) =>
  action(SearchActionTypes.CHANGE_REQUEST_SEARCH, state);

export const changeJourneySearch = (state: JourneySearchState) =>
  action(SearchActionTypes.CHANGE_JOURNEY_SEARCH, state);

export const changeDoctorSearch = (state: DoctorSearchState) =>
  action(SearchActionTypes.CHANGE_DOCTOR_SEARCH, state);
