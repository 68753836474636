import * as React from 'react';
import KitTag from '../../kit/KitTag';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';

const JourneyInfoKits = () => {
  const { journey } = React.useContext(JourneyInfoContext);
  if (!journey) {
    return null;
  }

  const kits = journey?.kits ?? [];

  return (
    <div className="text-grey-darkest font-nm align-middle text-sm mt-15 flex items-start">
      <div className="mr-5 pt-3">
        Kits:
        </div>
      <div>
        {kits.map(kit => <KitTag kit={kit} key={kit.id} />)}
      </div>
    </div>
  );
}

export default JourneyInfoKits;
