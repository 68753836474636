import * as React from 'react';
import * as yup from 'yup';
import BaseForm, { BaseFormProps } from '../../widgets/BaseForm';
import FormInput from '../../widgets/form/FormInput';

interface DiseaseFieldFormProps {
  initialValues: BaseFormProps['initialValues'];
  onSubmit: BaseFormProps['onSubmit'];
  fieldName: string;
}

const schema = yup.object().shape({
  value: yup.string(),
});

const getFieldExtraProps = (fieldName: string): { [key: string]: any } => {
  switch (fieldName) {
    case 'carrierRisk':
      return {
        as: 'select',
        options: ['High Risk', 'Low Risk', 'Increased Risk', 'Result to note'].map(value => ({
          value,
          label: value,
        })),
      };

    default:
      return {
        type: 'text',
        as: 'textarea',
        rows: 10,
      };
  }
};

const DiseaseFieldForm = (props: DiseaseFieldFormProps) => (
  <BaseForm
    validationSchema={schema}
    initialValues={props.initialValues}
    onSubmit={props.onSubmit}
    submitButtonLabel="Save"
  >
    {({
      handleChange,
      values,
      setFieldValue,
    }) => (
        <div>
          <FormInput
            type="text"
            name="value"
            value={values.value}
            onChange={handleChange}
            autoFocus
            className="w-100"
            setFieldValue={setFieldValue}
            {...getFieldExtraProps(props.fieldName)}
          />
        </div>
      )
    }
  </BaseForm>
);

export default DiseaseFieldForm;
