import * as React from 'react';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import DoctorListTable from '../../containers/doctors/list/DoctorListTable';
import RouteButton from '../../components/widgets/RouteButton';
import Screen from '../../components/widgets/Screen';
import Button from '../../components/widgets/Button';
import requestHubspotDoctorData from '../../graphql/requests/requestHubspotDoctorData';
import { hideToast, showLoadingToast, showToast } from '../../store/toast/actions';
import { useDispatch } from 'react-redux';
import requestFullReferralList from '../../graphql/requests/requestFullReferralList';

const DoctorIndex = () => {
  const dispatch = useDispatch();

  const onExportHubspotData = React.useCallback(async () => {
    const toastId = 'hubspotData';
    dispatch(showLoadingToast(toastId, 'Querying doctors', 'Querying doctors and requests for hubspot'));
    await requestHubspotDoctorData();
    dispatch(hideToast(toastId));
    showToast('success', 'Hubspot export complete', 'CSV downloaded');
  }, [dispatch]);
  
  const onDownloadFullReferralList = React.useCallback(async () => {
    const toastId = 'fullReferral';
    dispatch(showLoadingToast(toastId, 'Querying doctors', 'Querying doctors and requests for referral list'));
    await requestFullReferralList();
    dispatch(hideToast(toastId));
    showToast('success', 'Data export complete', 'CSV downloaded');
  }, [dispatch]);


  return (
    <Screen
      className="relative"
    >
      <ScreenTitle
        title="Doctors"
        action={(
          <div>
            <Button
              onClick={onExportHubspotData}
              size="sm"
              className="mr-10"
            >
              Download hubspot data
            </Button>
            <Button
              onClick={onDownloadFullReferralList}
              size="sm"
              className="mr-10"
            >
              Download full referral list
            </Button>
            <RouteButton
              query={{ form: 'DoctorCreate' }}
              size="sm"
            >
              + Create Doctor
          </RouteButton>

          </div>
        )}
      />
      <DoctorListTable />
    </Screen>
  );
};

export default DoctorIndex;
