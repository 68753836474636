import gql from "graphql-tag";
import * as React from "react";
import { useQuery, useMutation } from "react-apollo";
import { Journey, MessageType, Notification, NotificationEntity, QueryJourneyArgs } from "../../../graphql/genie-api-types";

import NotificationNoteForm,  { NotificationFormPayload }  from "../notifications/NotificationNoteForm";
import NotificationNote from "../widgets/NotificationNote";

interface JourneyNotificationsProps {
  journeyId: string;
}

interface QueryResult {
  journey: Pick<Journey, "id"> & {
    notifications?: Notification[];
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      notifications {
        id
        method
        type
        destination
        dateSent
        content
      }
    }
  }
`;

const ADD_NOTIFICATION = gql`
  mutation createNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      notification {
        id
        destination
        type
        content
        method
      }
    }
  }
`;

const JourneyNotifications = (props: JourneyNotificationsProps) => {
  const { journeyId } = props;
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
      variables: {
      id: journeyId
    },
  });

  const [addNotification, {data} ] = useMutation(ADD_NOTIFICATION, {
    refetchQueries: () => [{
        query: QUERY,
        variables: { 
        id: journeyId,
      },
    }],
  });

  const notifications = journeyQuery?.data?.journey?.notifications;

  const submitAction = async(values: NotificationFormPayload) => {
    await addNotification({ 
      variables: { 
        input: {
          id: journeyId,
          destination: values.destination,
          type: MessageType.CustomExternalMessage,
          content: values.content,
          method: values.method,
          entity: NotificationEntity.Journey
        }
      } 
    });
  }

  if (!notifications) {
    return null;
  }

  return (
    <div>
      <div className="pb-10 my-5">
        <NotificationNoteForm onSubmit={submitAction} isLoading={false} />
      </div>


      {notifications.map((item, index) => (
        <NotificationNote key={`notification${index}`} notification={item} />
      ))}
    </div>
  );
};

export default JourneyNotifications;