import * as React from 'react';
import { groupBy } from 'lodash';
import PedigreeBox from '../widgets/PedigreeBox';
import { FamilyHistoryCategory, FamilyHistoryGroupItem as FamilyHistoryItemType, FamilyHistoryClassification, Member } from '../../../graphql/genie-api-types';
import FamilyHistoryCategoryBox from './FamilyHistoryCategoryBox';
import FamilyHistoryItem from './FamilyHistoryItem';

interface FamilyHistoryProps {
  familyHistoryGroup: Member['familyHistoryGroup'];
  memberId: string;
  onAddHistoryItem?(memberId: string, category: string): void;
  onEditHistoryItem?(itemId: string): void;
  shouldShowExtra: boolean;
  shouldShowAsStacked?: boolean;
}

const FamilyHistory = ({ familyHistoryGroup, memberId, onAddHistoryItem, onEditHistoryItem, shouldShowAsStacked, shouldShowExtra }: FamilyHistoryProps) => {

  const categoryHistoryItems = groupBy(familyHistoryGroup, 'category');

  const filterExtras = (familyHistoryGroupItem: FamilyHistoryItemType) =>
    shouldShowExtra ? true : familyHistoryGroupItem.classification !== FamilyHistoryClassification.Extraneous;

  if (shouldShowAsStacked) {
    // 'null' used for empty category because lodash groupBy keys null categories using the string 'null'.
    const categories: (FamilyHistoryCategory | 'null')[] = [
      FamilyHistoryCategory.Parents,
      FamilyHistoryCategory.Extended,
      FamilyHistoryCategory.Me,
      FamilyHistoryCategory.Siblings,
      FamilyHistoryCategory.Pregnancy,
      FamilyHistoryCategory.Children,
      FamilyHistoryCategory.Niblings,
      'null',
    ];

    return (
      <div className="px-5">
        {categories.map(category => (
          <div className="mt-20 mb-30" key={category}>
            <FamilyHistoryCategoryBox
              memberId={memberId}
              category={category === 'null' ? undefined : category}
              label={category === 'null' ? 'Other' : undefined}
              onAddHistoryItem={onAddHistoryItem}
            >
              {categoryHistoryItems[category]?.filter(filterExtras).map(familyHistoryGroupItem => (
                <FamilyHistoryItem
                  key={familyHistoryGroupItem.id}
                  familyHistoryGroupItem={familyHistoryGroupItem}
                  onEditHistoryItem={onEditHistoryItem}
                />
              ))}
            </FamilyHistoryCategoryBox>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-40">
      <div className="min-w-5xl -mx-20">
        <div className="flex">
          <PedigreeBox
            rightLine={{ style: 'dotted' }}
            bottomLine={{ connectRight: '-25%', style: 'solid' }}
            width="2/3"
          >
            <FamilyHistoryCategoryBox
              memberId={memberId}
              category={FamilyHistoryCategory.Parents}
              onAddHistoryItem={onAddHistoryItem}
            >
              {categoryHistoryItems[FamilyHistoryCategory.Parents]?.filter(filterExtras).map(familyHistoryGroupItem => (
                <FamilyHistoryItem
                  key={familyHistoryGroupItem.id}
                  familyHistoryGroupItem={familyHistoryGroupItem}
                  onEditHistoryItem={onEditHistoryItem}
                />
              ))}
            </FamilyHistoryCategoryBox>
          </PedigreeBox>
          <PedigreeBox leftLine={{ style: 'dotted' }}>
            <FamilyHistoryCategoryBox
              memberId={memberId}
              category={FamilyHistoryCategory.Extended}
              onAddHistoryItem={onAddHistoryItem}
            >
              {categoryHistoryItems[FamilyHistoryCategory.Extended]?.filter(filterExtras).map(familyHistoryGroupItem => (
                <FamilyHistoryItem
                  key={familyHistoryGroupItem.id}
                  familyHistoryGroupItem={familyHistoryGroupItem}
                  onEditHistoryItem={onEditHistoryItem}
                />
              ))}
            </FamilyHistoryCategoryBox>
          </PedigreeBox>
        </div>
      </div>
      <div className="flex -mx-20">
        <PedigreeBox
          className="border-yellow"
          topLine={{ style: 'solid' }}
          bottomLine={{ style: 'solid', connectRight: '25%', connectLeft: '25%' }}
          width="2/3"
          backgroundColorClass="orange-light"
        >
          <FamilyHistoryCategoryBox
            memberId={memberId}
            category={FamilyHistoryCategory.Me}
            onAddHistoryItem={onAddHistoryItem}
          >
            {categoryHistoryItems[FamilyHistoryCategory.Me]?.filter(filterExtras).map(familyHistoryGroupItem => (
              <FamilyHistoryItem
                key={familyHistoryGroupItem.id}
                familyHistoryGroupItem={familyHistoryGroupItem}
                onEditHistoryItem={onEditHistoryItem}
              />
            ))}
          </FamilyHistoryCategoryBox>
        </PedigreeBox>
        <PedigreeBox
          topLine={{ style: 'solid' }}
          bottomLine={{ style: 'solid' }}
        >
          <FamilyHistoryCategoryBox
            memberId={memberId}
            category={FamilyHistoryCategory.Siblings}
            onAddHistoryItem={onAddHistoryItem}
          >
            {categoryHistoryItems[FamilyHistoryCategory.Siblings]?.filter(filterExtras).map(familyHistoryGroupItem => (
              <FamilyHistoryItem
                key={familyHistoryGroupItem.id}
                familyHistoryGroupItem={familyHistoryGroupItem}
                onEditHistoryItem={onEditHistoryItem}
              />
            ))}
          </FamilyHistoryCategoryBox>
        </PedigreeBox>
      </div>
      <div className="flex -mx-20">
        <PedigreeBox
          topLine={{ style: 'dotted' }}
        >
          <FamilyHistoryCategoryBox
            memberId={memberId}
            category={FamilyHistoryCategory.Pregnancy}
            onAddHistoryItem={onAddHistoryItem}
          >
            {categoryHistoryItems[FamilyHistoryCategory.Pregnancy]?.filter(filterExtras).map(familyHistoryGroupItem => (
              <FamilyHistoryItem
                key={familyHistoryGroupItem.id}
                familyHistoryGroupItem={familyHistoryGroupItem}
                onEditHistoryItem={onEditHistoryItem}
              />
            ))}
          </FamilyHistoryCategoryBox>
        </PedigreeBox>
        <PedigreeBox
          topLine={{ style: 'solid' }}
        >
          <FamilyHistoryCategoryBox
            memberId={memberId}
            category={FamilyHistoryCategory.Children}
            onAddHistoryItem={onAddHistoryItem}
          >
            {categoryHistoryItems[FamilyHistoryCategory.Children]?.filter(filterExtras).map(familyHistoryGroupItem => (
              <FamilyHistoryItem
                key={familyHistoryGroupItem.id}
                familyHistoryGroupItem={familyHistoryGroupItem}
                onEditHistoryItem={onEditHistoryItem}
              />
            ))}
          </FamilyHistoryCategoryBox>
        </PedigreeBox>
        <PedigreeBox
          topLine={{ style: 'solid' }}
        >
          <FamilyHistoryCategoryBox
            memberId={memberId}
            category={FamilyHistoryCategory.Niblings}
            onAddHistoryItem={onAddHistoryItem}
          >
            {categoryHistoryItems[FamilyHistoryCategory.Niblings]?.filter(filterExtras).map(familyHistoryGroupItem => (
              <FamilyHistoryItem
                key={familyHistoryGroupItem.id}
                familyHistoryGroupItem={familyHistoryGroupItem}
                onEditHistoryItem={onEditHistoryItem}
              />
            ))}
          </FamilyHistoryCategoryBox>
        </PedigreeBox>
      </div>
      <PedigreeBox width="full" className="-mx-20">
        <FamilyHistoryCategoryBox
          memberId={memberId}
          label="Other"
          onAddHistoryItem={onAddHistoryItem}
        >
          {categoryHistoryItems['null']?.filter(filterExtras).map(familyHistoryGroupItem => (
            <FamilyHistoryItem
              key={familyHistoryGroupItem.id}
              familyHistoryGroupItem={familyHistoryGroupItem}
              onEditHistoryItem={onEditHistoryItem}
              alwaysShowTimestamps
            />
          ))}
        </FamilyHistoryCategoryBox>
      </PedigreeBox>
    </div>
  );
}

export default React.memo(FamilyHistory);