import * as React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Kit, Journey, Member, Request, Pregnancy, Counsellor, Flag, QueryListFlagsArgs, FlagCode } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import JourneyTable from '../../../components/widgets/JourneyTable';
import CounsellorIcon from '../../../components/widgets/CounsellorIcon';
import { Action } from '../../../components/widgets/DropdownButton';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import { getProductIcon } from '../../../../utilities/journeys/products';
import updateFlagComplete from '../../requests/flags/updateFlagComplete';

const JOURNEY_FRAGMENT = gql`
  fragment JourneyParts on Journey {
    id
    hasConsent
    labOrderNumber
    state
    member {
      id
      name
      sex
    }
    kits {
      id
      recollectionRequestedDate
    }
    request {
      id
      gcApprovalDate
      onHoldDate
      product
      productVariant
      initiatingMember {
        id
      }
      pregnancy {
        dueDate
        dueDateConfirmed
        days
      }
      journeys {
        id
        state
        member {
          id
          name
        }
      }
      primaryCounsellor {
        id
        name
        nickname
        photoURL
      }
      notes(importantOnly: true) {
        text
      }
    }
  }
`;

const LIST_FLAGS_QUERY = gql`
query ListFlags($input: ListFlagsInput!) {
  listFlags(input: $input) {
    edges {
      cursor
      node {
        id
        dateCreated
        linkData
        journey {
          ...JourneyParts
        }
      }
    }
  }
}
${JOURNEY_FRAGMENT}
`;

interface KitResultRequest extends Required<Pick<Request, 'id' | 'gcApprovalDate' | 'onHoldDate' | 'product' | 'productVariant'>> {
  pregnancy: Pick<Pregnancy, 'dueDate' | 'dueDateConfirmed' | 'days'>;
  primaryCounsellor: Pick<Counsellor, 'id' | 'name' | 'nickname' | 'photoURL'>;
  journeys: (Required<Pick<Journey, 'id'|'state'>> & {
    member: Required<Pick<Member, 'id'|'name'>>;
  })[];
}

interface KitResultJourney extends Pick<Journey, 'id' | 'hasConsent' | 'labOrderNumber'|'state'> {
  member: Required<Pick<Member, 'id' | 'name'|'sex'>>|null;
  request: KitResultRequest;
  kits?: Pick<Kit, 'id' | 'recollectionRequestedDate'>[];
}

interface FlagResult extends Pick<Flag, 'id' | 'dateCreated' | 'linkData'> {
  journey: KitResultJourney;
  kitId?: string;
}

interface ListFlagsResponse {
  listFlags: {
    edges: {
      node: FlagResult;
    }[];
  };
}

type RecollectKitAction = 'proceedWithRecollection' | 'cancelTest' | 'removeFlag';

const getRecollectActionsForJourney = (): Action<RecollectKitAction>[] => {
  const actions: Action<RecollectKitAction>[] = [];
  
  actions.push({
    label: 'Followed up',
    name: 'removeFlag',
    variant: 'primary',
    extraProps: {
      noBatch: true,
    },
  });

  return actions;
};
const FailedSamples = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [recollectionFlags, setRecollectionFlags] = React.useState<FlagResult[]>([]);

  const loadFailedSamples = async () => {
    setIsLoading(true);

    const recollectionFlagResponses = await appSyncClient.query<ListFlagsResponse, QueryListFlagsArgs>({
      query: LIST_FLAGS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          code: FlagCode.SampleFailed,
        },
      },
    });

    setRecollectionFlags(recollectionFlagResponses?.data?.listFlags?.edges?.map(edge => edge.node).filter(flag => flag.journey).map(node => ({
      ...node,
      kitId: JSON.parse(node.linkData)?.kitId,
    })) ?? []);

    setIsLoading(false);
  };

  const recollectJourneysByRequestId = React.useMemo(() => {
    const result: { [key: string]: KitResultJourney[] } = {};

    recollectionFlags.forEach(flag => {
      const journey = flag.journey;
      if (!result[journey.request.id]) {
        result[journey.request.id] = [];
      }
      result[journey.request.id].push(journey);
    });

    return result;
  }, [recollectionFlags]);

  const recollectOrderedRequestIds = React.useMemo(() => {
    return Object.keys(recollectJourneysByRequestId);
  }, [recollectJourneysByRequestId]);

  const failedSampleDatesByJourneyId = React.useMemo(() => {
    const result: { [key: string]: string } = {};

    recollectionFlags.forEach(flag => {
      result[flag.journey.id] = moment(flag.dateCreated).fromNow();
    });

    return result;
  }, [recollectionFlags]);

  const onRecollectActionClick = React.useCallback(async (rowIds: string[], actionName: RecollectKitAction) => {
    const journeyId = rowIds[0];
    const flag = recollectionFlags.find(flag => flag.journey.id === journeyId);
    switch (actionName) {
      case 'removeFlag':
        await updateFlagComplete(flag.id);
        await loadFailedSamples();
        break;
    }
  }, [recollectionFlags, loadFailedSamples]);

  React.useEffect(() => {
    loadFailedSamples();
    ReduxQueryListener.addCallbackListener(loadFailedSamples);

    return () => {
      ReduxQueryListener.removeCallbackListener(loadFailedSamples);
    };
  }, []);

  return (
    <JourneyTable<KitResultJourney>
      journeysByRequestId={recollectJourneysByRequestId}
      orderedRequestIds={recollectOrderedRequestIds}
      onActionComplete={loadFailedSamples}
      emptyText="No failed samples"
      header={[
        'Counsellor',
        'Product',
        'Sample failed',
      ]}
      cellsForJourney={(journey: KitResultJourney) => [
        <CounsellorIcon key="counsellor" {...journey.request.primaryCounsellor} />,
        getProductIcon(journey.request.product, journey.request.productVariant),
        failedSampleDatesByJourneyId[journey.id],
      ]}
      onRefresh={loadFailedSamples}
      actionsForJourney={getRecollectActionsForJourney}
      onActionClick={onRecollectActionClick}
      isLoading={isLoading}
    />
  );
};

export default FailedSamples;
