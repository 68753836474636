import * as React from 'react';
import { ColumnGroupProperty, Row, TableProps } from './Table';
import DropdownButton from './DropdownButton';
import { FiChevronDown, FiChevronUp } from './Icon';
import BatchCheckbox from './BatchCheckbox';

interface TableRowProps {
  row: Row;
  onRowSelect?(rowId: string): void;
  isDisabled?: boolean;
  hasBatchSelect?: boolean;
  showIndicator?: boolean;
  rowIndex?: number;
  alwaysShowActions?: boolean;
  disabledRowIds?: TableProps['disabledRowIds'];
  onActionClick?: TableProps['onActionClick'];
  onToggleRowChecked(): void;
  isLastRow?: boolean;
  renderExpansion?(row: Row): React.ReactNode;
  columnGroupProperties: ColumnGroupProperty[];
  isSelected: boolean;
  displayTotalRowSelect: boolean;
  totalRowCount: number;
  onTotalRowSelect(): void;
}

interface BaseTableRowProps extends TableRowProps {
  onExpandToggle?(): void;
  isExpanded?: boolean;
}

const BaseTableRow = ({ row, onTotalRowSelect, totalRowCount, columnGroupProperties, displayTotalRowSelect, onExpandToggle, onToggleRowChecked, isExpanded, isSelected, onRowSelect, isDisabled, showIndicator, hasBatchSelect, rowIndex, alwaysShowActions, disabledRowIds, onActionClick, isLastRow }: BaseTableRowProps) => {
  return (
    <tr
      className={`transition-all table-row-flash group ${onRowSelect ? 'cursor-pointer' : ''} ${isDisabled ? 'opacity-50 pointer-events-none' : ''} ${isSelected ? 'bg-orange-light scale-up' : 'hover:bg-grey-light'}`}
      onClick={onRowSelect ? () => onRowSelect(row.id) : undefined}
    >
      {hasBatchSelect && (!displayTotalRowSelect || rowIndex === 0) && (
        <td rowSpan={displayTotalRowSelect ? totalRowCount : 1}>
          <BatchCheckbox
            state={isSelected ? 'checked' : 'unchecked'}
            onToggle={displayTotalRowSelect ? onTotalRowSelect : onToggleRowChecked}
            className="pl-10 pt-10 pb-8"
          />
        </td>
      )}
      {row.cells.map((cell, cellIndex) => {
        const groupProperties = columnGroupProperties[hasBatchSelect ? cellIndex + 1 : cellIndex];

        return (
          <td
            className={`text-sm font-nm text-black whitespace-no-wrap px-10 ${hasBatchSelect && cellIndex === 0 ? 'pl-0' : ''} ${rowIndex === 0 ? 'pt-10' : 'pt-6 border-t border-dashed'} ${isLastRow ? 'pb-8' : 'pb-6'} ${groupProperties?.isStartOfGroup ? 'border-l border-l-solid' : ''} ${groupProperties?.isEndOfGroup ? 'border-r border-r-solid' : ''}`}
            key={cellIndex}
          >
            {onExpandToggle && cellIndex === 0 && (
              <button onClick={onExpandToggle} className="focus:outline-none hover:bg-purple hover:text-white rounded-full w-5 h-5 inline-flex items-center justify-center mr-5">
                {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
              </button>
            )}
            {showIndicator && cellIndex === 0 ? <span className="table__group-indicator"></span> : null}
            {cell}
          </td>
        );
      })}
      <td className={`text-sm text-black text-right py-1 p-8 ${rowIndex > 0 ? 'border-t border-dashed' : ''}`}>
        <div className={`flex justify-end items-center ${alwaysShowActions ? '' : 'invisible group-hover:visible'}`}>
          {!disabledRowIds || !disabledRowIds[row.id] ? (
            <DropdownButton
              actions={row.actions}
              onActionClick={onActionClick ? (actionName: string, actionValue: string) => onActionClick([row.id], actionName, actionValue) : undefined}
            />
          ) : null}
        </div>
      </td>
    </tr>
  );
}

const ExpandingTableRow = (props: TableRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onExpandToggle = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  return (
    <>
      <BaseTableRow {...props} onExpandToggle={onExpandToggle} isExpanded={isExpanded} />
      {isExpanded && (
        <tr>
          <td colSpan={props.row.cells.length + 1} className=" px-10py-8 text-sm font-nm text-black">
            {props.renderExpansion(props.row)}
          </td>
        </tr>
      )}
    </>
  );
};


const TableRow = (props: TableRowProps) => {
  if (props.renderExpansion) {
    return <ExpandingTableRow {...props} />
  }

  return <BaseTableRow {...props} />
};

export default TableRow;
