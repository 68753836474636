import * as React from 'react';
import moment from 'moment';
import { Flag } from '../../../graphql/genie-api-types';
import { FiStar } from '../widgets/Icon';

interface FlagTimeLabelProps {
  flagCreated: Flag['dateCreated'];
}

const today = moment();
const FlagTimeLabel = ({ flagCreated }: FlagTimeLabelProps) => {
  const date = moment(flagCreated);
  const daysAgo = today.diff(date, 'days');

  if (daysAgo === 0) {
    return (
      <span className="text-orange py-3 rounded font-bold text-xs"><FiStar className="align-top mr-3" />New</span>
    );
  }

  return <span className="bg-grey-light py-3 px-5 rounded text-xs">{`${daysAgo}d`}</span>
};

export default React.memo(FlagTimeLabel);
