import { kebabCase } from 'lodash';
import copy from 'copy-to-clipboard';
import { uniqBy } from 'lodash';
import { showToast } from '../app/store/toast/actions';
import moment from 'moment';
import { DoctorClinic, Journey, Member, ProductVariant, Request, Sex } from '../graphql/genie-api-types';

export const isDev = () =>
  process.env['NODE_ENV'] === 'development';

export const generateAndList = (items: string[]) => {
  let andList = '';

  items.forEach((item, index) => {
    andList += item;

    if (index < items.length - 2) {
      andList += ', ';
    } else if (index === items.length - 2) {
      andList += ' and ';
    }
  });

  return andList;
};

export const pluralise = (singular: string, plural: string, count: number) => {
  return count === 1 ? singular : plural;
};

export const addDiseaseRiskStyle = (text: string, risk: 'High Risk' | 'Low Risk' | 'Increased Risk' | 'Result to note') => {
  return `[${text}](#disease-label-${kebabCase(risk)})`;
};

export const isRunningInJest = () =>
  process.env.JEST_WORKER_ID === undefined;

export const convertDaysToFormattedWeeks = (days: number, shortHand?: boolean, excludeDays?: boolean) => {
  const leftoverDays = days % 7;
  const weeks = Math.trunc(days / 7);

  let formattedWeeks = '';

  if (weeks > 0) {
    formattedWeeks += `${weeks}${shortHand ? 'w' : pluralise(' week', ' weeks', weeks)}`;
  }

  if (leftoverDays && !shortHand && !excludeDays) {
    if (weeks > 0) {
      formattedWeeks += ', ';
    }
    formattedWeeks += `${leftoverDays} ${pluralise('day', 'days', leftoverDays)}`;
  }

  return formattedWeeks;
};

export const copyToClipboard = (content: string) => {
  copy(content);
  showToast('info', 'Copied to clipboard', content);
};

export const AWS_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm[:00.000Z]';
export const AWS_DATE_FORMAT = 'YYYY-MM-DD';

export const shorthandFromNow = (date: moment.Moment | string) => {
  const hours = moment().diff(moment(date), 'h');
  if (hours === 0) {
    return 'now';
  }
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = moment().diff(moment(date), 'd');
  return `${days}d`;
}

export const formatDateOfBirth = (dateOfBirth: string | moment.Moment | null) => dateOfBirth ?
  moment(dateOfBirth).format('DD/MM/YYYY') : '';

export const formatUsaDateOfBirth = (dateOfBirth: string | null) => dateOfBirth ?
  moment(dateOfBirth).format('MM/DD/YYYY') : '';

export const formatDateFull = (dateString: string, showTime?: boolean) => dateString ?
  moment(dateString).format(`Do MMM YYYY${showTime ? ', h:mma' : ''}`) : '';

export const formatSex = (sex: Sex) => {
  switch (sex) {
    case Sex.Male:
      return 'XY';
    case Sex.Female:
      return 'XX';
    default:
      return '';
  }
}

export function formatNotificationType(string: string): string {
  return string.toLowerCase().replace(
    /\w\S*/g,
    function (string) {
      return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }
  ).replace(/_/g, ' ');
}


export function formatNotificationMethod(string: string): string {
  switch (string) {
    case "SMS": return "💬";
    case "EMAIL": return "✉️";
    case "CALL": return "📞";
    default: return "";
  }
}

export const convertUTCToLocal = (value: string) => {
  return value ? moment(value).format('YYYY-MM-DDTHH:mm') : '';
}

export const convertLocalToUTC = (value: string) => {
  return value ? moment(value).toISOString() : null
}

export const getZendeskSearchUrl = (searchTerm: string) =>
  `https://eugenelabs.zendesk.com/users?utf8=%E2%9C%93&query=${searchTerm}&commit=Search`;

export const extractNameParts = (name: string, includeMiddleInitial?: boolean) => {
  const splitName = name.split(' ').filter(name => !!name);
  let middleInitial = '';

  if (includeMiddleInitial && splitName.length === 3) {
    const middleName = splitName.splice(1, 1)[0];
    middleInitial = middleName[0];
  }
  return {
    firstName: splitName.slice(0, splitName.length - 1).join(' '),
    familyName: splitName[splitName.length - 1],
    middleInitial,
  };
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let formattedNumber = phoneNumber;
  if (phoneNumber.includes('+614')) {
    formattedNumber = `0${phoneNumber.substr(3, 3)} ${phoneNumber.substr(6, 3)} ${phoneNumber.substr(9, 3)}`;
  }

  return formattedNumber;
}

export const extractStateFromAddress = (address: string) => {
  if (!address) {
    return 'N/A';
  }

  const lowerCaseAddress = address.toLowerCase();

  const lowercaseRules: { [key: string]: string } = {
    'victoria': 'VIC',
    'new south wales': 'NSW',
    'tasmania': 'TAS',
    'western australia': 'WA',
    'northern territory': 'NT',
    'queensland': 'QLD',
    'south australia': 'SA',
  };

  const uppercaseStates = [
    'VIC',
    'NSW',
    'TAS',
    'WA',
    'NT',
    'QLD',
    'SA',
  ];

  let state = uppercaseStates.find(state => address.includes(state));

  if (!state) {
    const ruleKey = Object.keys(lowercaseRules).find(rule => lowerCaseAddress.includes(rule));
    if (ruleKey) {
      state = lowercaseRules[ruleKey];
    }
  }

  return state ?? 'N/A';
}

export const getAcuitySearchUrl = (name: string) => {
  const nameParts = extractNameParts(name);
  return `https://secure.acuityscheduling.com/clients.php?action=detail&firstName=${nameParts.firstName}&lastName=${nameParts.familyName}`;
};

export const median = (values: number[]) => {
  if (values.length === 0) return 0;

  values.slice(0).sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
};

export const average = (values: number[]) =>
  values.reduce((a, b) => (a + b)) / values.length;

export const percentage = (number: number, decimals = 0) =>
  `${(number * 100).toFixed(decimals)}%`;

export const url = (path: string) => `https://genie.eugenelabs.com${path}`;

export const getGoogleDriveFolderName = (journeys: Journey[]) => {
  const names = journeys.filter(journey => journey.member).map(journey => {
    return extractNameParts(journey.member.name);
  });
  const areFamilyNamesSame = uniqBy(names, 'familyName').length === 1;

  if (areFamilyNamesSame) {
    return `${names[0].familyName.toUpperCase()} ${names.map(name => name.firstName).join(' and ')}`;
  }

  return names.map(name => `${name.familyName.toUpperCase()} ${name.firstName}`).join(' and ');
};

export const getGoogleDriveSearchLink = (folderName: string) => {
  const googleDriveSharedFolderDev = '0AAWWIHerrM2PUk9PVA';
  const googleDriveSharedFolderProd = '0AGdpfz5XW-UlUk9PVA';

  const googleDriveSharedFolder = isDev() ? googleDriveSharedFolderDev : googleDriveSharedFolderProd;

  return `https://drive.google.com/drive/search?q=${folderName}%20in:${googleDriveSharedFolder}`;
};

export function getGoogleDriveFolderNameInvitaeReportManager(request: Request) {
  let folderName: string = request.journeys.map((journey: Journey) => {
    return journey.member;
  }).sort(sortMembersBySexThenName).map((member: Member) => {
    return toTitleCase(member?.name ?? 'Memberless journey');
  }).join(" and ");

  return folderName += ' ' + request.id;
}

export function sortMembersBySexThenName(a: Member, b: Member): number {
  // Handle memberless journeys
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  // If sex is the same, we sort alphabetically
  if (a.sex === b.sex) {
    return a.name.localeCompare(b.name);
  }

  // If sex is different, females go first
  if (a.sex === 'FEMALE' && b.sex === 'MALE') { return -1; }
  if (a.sex === 'MALE' && b.sex === 'FEMALE') { return 1; }

  return 0;
}

export function toTitleCase(string: string): string {
  return string.trim().replace(
    /\w\S*/g,
    function (string) {
      return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }
  ).replace(/ /g, ' ');
}

export function trimLabOrderNumber(value: string) {
  return value.replace(/[^a-zA-Z0-9-]/g, '');
}

export function formatAddress(addressCity: string, addressProvince: string, addressCountryCode: string) {
  const checkAddressValue = (string: string) => string && string.trim() !== '';
  let address = "";
  if (checkAddressValue(addressCity)) { address += addressCity; }
  if (checkAddressValue(addressProvince)) { address += ', ' + addressProvince; }
  if (checkAddressValue(addressCountryCode)) { address += ', ' + addressCountryCode; }
  return address;
}

export function formatMemberName(member: Member, request: Pick<Request, 'initiatingMember' | 'productVariant'>) {
  const hasName = member?.name ?? 'Memberless Journey';
  const isDonor = request?.initiatingMember && member?.id !== request?.initiatingMember?.id && request?.productVariant === ProductVariant.Donor ? ' (Donor)' : '';
  return hasName + isDonor;
};

export function formatPartnerConsent(partnerConsent: boolean | null, yesAnswer: string = 'Yes', noAnswer: string = "No", unknownAnswer: string = "Missing result consent") {
  return partnerConsent !== null ? (partnerConsent.toString().toLowerCase() === 'true' ? yesAnswer : noAnswer) : unknownAnswer;
}

export function journeyToInvitaeBookmarkletData(journey: Journey, partnerJourneys: Journey[] | null = null) {
  const names = extractNameParts(journey?.member?.name ?? '', true);
  const state = journey?.addressProvince ?? '';
  const country = journey?.addressCountryCode ?? '';

  // Get first partnerJourney if one exists
  const partnerJourney = partnerJourneys?.length ? partnerJourneys[0] : null;

  // Get partnerJourney lab order number if it exists
  const partnerRequisitionId = partnerJourney?.labOrderNumber ?? '';

  // If the partner has a lab order number, we can also pass along their date of birth (if it exists)
  const partnerDateOfBirth = partnerRequisitionId && partnerJourney?.member?.dateOfBirth ? moment(partnerJourney?.member?.dateOfBirth).format('MM/DD/Y') : '';

  const data = {
    "firstName": names?.firstName,
    "lastName": names?.familyName,
    "dob": moment(journey?.member?.dateOfBirth).format('MM/DD/Y'),
    "sex": journey?.member?.sex?.toUpperCase(),
    "state": state + ', ' + country,
    "partnerDateOfBirth": partnerDateOfBirth,
    "partnerRequisitionId": partnerRequisitionId,
  };

  console.log(data);

  return data;
}

/**
 * Returns the preferred email for a DoctorClinic
 * Prefers email on the DoctorClinic, then the doctor, then the clinic
 * 
 * @param doctorClinic 
 * @returns string
 */
export function preferredDoctorClinicEmail(doctorClinic: DoctorClinic): string {
  if (doctorClinic.email) {
    return doctorClinic.email;
  }

  if (doctorClinic.doctor.personalEmail) {
    return doctorClinic.doctor.personalEmail;
  }

  if (doctorClinic.clinic.clinicEmail) {
    return doctorClinic.clinic.clinicEmail;
  }

  return '';
}