import * as React from 'react';
import { Mutation as ApolloMutation, MutationResult, MutationComponentOptions, MutationFunction } from 'react-apollo';
import { showToast } from '../../store/toast/actions';
import ErrorBoundary from '../../base/ErrorBoundary';

const renderQueryChildren = (data: MutationFunction, result: MutationResult, childrenCallback: MutationComponentOptions['children']) => {
  if (result.error) {
    showToast('error', result.error.name, result.error.message, {
      timeOut: 0,
      showCloseButton: true,
    });
  }
  return childrenCallback(data, result);
};

const GenieMutation = ({ children, ...props }: MutationComponentOptions) => (
  <ErrorBoundary>
    <ApolloMutation
      {...props}
    >
      {(data: MutationFunction, result: MutationResult) => renderQueryChildren(data, result, children)}
    </ApolloMutation>
  </ErrorBoundary>
);

export default GenieMutation;
