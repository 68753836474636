import gql from 'graphql-tag';
import * as React from 'react';
import { useLazyQuery } from 'react-apollo';
import { Counsellor, Journey, JourneyLog, Member, Pregnancy, QueryJourneyArgs, Request } from '../../graphql/genie-api-types';
import { DoctorClinicPartial, DOCTOR_CLINIC_FRAGMENT } from '../../utilities/doctorClinic';
import useToggleState from '../../utilities/useToggleState';

interface JourneyInfoContextWrapperProps {
  children: React.ReactNode;
}

interface JourneyInfoRequestResult extends Pick<Request, 'id'|'product'|'datePaid'|'dateCreated'|'productVariant'|'doctorConnectionType'|'doctorNotifyOnReport'> {
  journeys: Pick<Journey, 'id'|'member'>[];
  referringDoctorClinic: DoctorClinicPartial;
  pregnancy: Pick<Pregnancy, 'dueDate'|'days'>;
  initiatingMember?: Pick<Member, 'id'|'name'|'email'>;
  primaryCounsellor?: Pick<Counsellor, 'id'|'name'>;
}

export interface JourneyInfoResult extends Pick<Journey, 'id' | 'state' | 'startDate' | 'kits' | 'labOrderNumber' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission' | 'addressCity' | 'addressProvince' | 'addressCountryCode' | 'partnerCanReceiveResults'> {
  member: Pick<Member, 'id' | 'name' | 'nickname' | 'email' | 'dateOfBirth' | 'phoneNumber' | 'sex'>;
  history: Pick<JourneyLog, 'date'|'fromState'|'toState'>[];
  request: JourneyInfoRequestResult;
}

export interface QueryResult {
  journey: JourneyInfoResult;
}

export interface JourneyInfoContextValue {
  journeyId: string;
  isLoading: boolean;
  errorMessage?: string;
  journey?: JourneyInfoResult;
  isOpen: boolean;
  canGoBack: boolean;
  onSelectJourney(journeyId: Journey['id']): void;
  onGoBack(): void;
  onToggleOpen(): void;
}

const JourneyInfoContext = React.createContext<JourneyInfoContextValue>({
  isLoading: false,
  errorMessage: '',
  journeyId: null,
  isOpen: false,
  onSelectJourney: () => {},
  onGoBack: () => {},
  canGoBack: false,
  onToggleOpen: () => {},
});

const MASTER_QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      addressCity
      addressProvince
      addressCountryCode
      state
      startDate
      labOrderNumber
      hasConsent
      hasRelevantFamilyHistoryGroupSubmission
      partnerCanReceiveResults
      member {
        id
        name
        nickname
        email
        dateOfBirth
        phoneNumber
        sex
      }
      history {
        date
        fromState
        toState
      }
      kits {
        id
        dateArrivedAtEugene
        dateShippedToLab
        dateShippedToMember
        memberRegisteredKitOnDate
        recollectionRequestedDate
        registrationNumber
      }
      request {
        id
        product
        productVariant
        datePaid
        dateCreated
        referringDoctorClinic {
          ...DoctorClinicFields
        }
        doctorConnectionType
        doctorNotifyOnReport
        initiatingMember {
          id
          name
          email
        }
        pregnancy {
          dueDate
          dueDateConfirmed
          days
        }
        primaryCounsellor {
          id
          name
        }
        journeys {
          id
          member {
            id
            name
          }
        }
      }
    }
  }
  ${DOCTOR_CLINIC_FRAGMENT}
`;

export const JourneyInfoContextWrapper = (props: JourneyInfoContextWrapperProps) => {
  const [journeyIds, setJourneyIds] = React.useState<Journey['id'][]>([]);
  const [isOpen, onToggleOpen, setIsOpen] = useToggleState(false);
  
  const journeyId = journeyIds.length === 0 ? null : journeyIds[journeyIds.length - 1];

  // Master query is designed to precache all other graphql queries within
  // nested components.
  const [journeyQuery, journeyQueryResult] = useLazyQuery<QueryResult, QueryJourneyArgs>(MASTER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  
  React.useEffect(() => {
    journeyQuery({
      variables: {
        id: journeyId,
      },
    });
  }, [journeyId]);
  
  const onSelectJourney = (journeyId: Journey['id']) => {
    setJourneyIds([...journeyIds, journeyId]);
    setIsOpen(true);
  };
  
  const onGoBack = () => {
    const newJourneyIds = [...journeyIds];
    newJourneyIds.pop();
    setJourneyIds(newJourneyIds);
  };
  
  const value = {
    isLoading: journeyQueryResult.loading,
    errorMessage: '',
    journeyId,
    onSelectJourney,
    onGoBack,
    onToggleOpen,
    isOpen,
    canGoBack: journeyIds.length > 0,
    journey: journeyQueryResult.data?.journey,
  }

  return (
    <JourneyInfoContext.Provider value={value}>
      {props.children}
    </JourneyInfoContext.Provider>
  );
}

export default JourneyInfoContext;
