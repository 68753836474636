import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Journey, Kit, QueryJourneyArgs, Request } from '../../../../graphql/genie-api-types';
import { getKitScope, getKitScopeLabel } from '../../../../utilities/kits/scopes';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { FiPackage } from '../../widgets/Icon';

interface QueryResult {
  journey: Pick<Journey, 'id'|'hasConsent'|'labOrderNumber'> & {
    kits: Pick<Kit, 'id'|'dateArrivedAtEugene'|'dateShippedToMember'|'recollectionRequestedDate'|'dateShippedToLab'>[];
    request: Pick<Request, 'id'|'gcApprovalDate'|'datePaid'>;
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      hasConsent
      labOrderNumber
      kits {
        id
        dateArrivedAtEugene
        dateShippedToMember
        recollectionRequestedDate
        dateShippedToLab
      }
      request {
        id
        gcApprovalDate
        datePaid
      }
    }
  }
`;

const JourneyShowKitStage = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  
  const journey = journeyQuery?.data?.journey;
  
  if (!journey) {
    return null;
  }

  const kits = journey?.kits?.filter(kit => !kit.recollectionRequestedDate) ?? [];

  return (
    <>
      {kits.map(kit => {
        const kitScope = getKitScope(kit, journey, journey.request);

        return (
          <div
            className={`text-xs text-grey-dark flex justify-end mt-10`}
            key={kit.id}
          >
            (
            <FiPackage className="mr-4 text-grey" />
            <h2 className="w-auto">{getKitScopeLabel(kitScope)}</h2>
            )
          </div>
        );
      })}
    </>
  );
};

export default JourneyShowKitStage;
