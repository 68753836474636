import * as React from 'react';
import FormInput from '../widgets/form/FormInput';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';

interface RequestPaidFormProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
}
const RequestPaidForm = ({ initialValues, onSubmit, onCancel, isLoading, errorMessage }: RequestPaidFormProps) => {
  return (
    <BaseForm
      initialValues={initialValues}
      submitButtonLabel="Save"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
          <div>
            <FormInput
              name="datePaid"
              type="datetime-local"
              label="Date paid"
              setFieldValue={setFieldValue}
              value={values['datePaid']?.replace(/(.*):.*Z/, '$1')}
              onChange={handleChange}
            />
          </div>
        )}
    </BaseForm>
  );
};

export default RequestPaidForm;
