import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as queryString from 'query-string';
import { ApplicationState } from '../../../store';
import { RouteProps } from 'react-router';
import Modal from '../../../components/widgets/Modal';
import CarrierRatesForm from '../../../components/report/carrier-rates/CarrierRatesForm';
import { changeCarrierRateEthnicity } from '../../../store/report/carrier-rates/actions';
import { showToast } from '../../../store/toast/actions';
import { Dictionary } from 'lodash';
import { RequestCarrierStatisticsByGeneResult } from '../../../graphql/requests/requestCarrierStatisticsByGene';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';

interface CarrierRatesEditProps extends RouteProps {
  onEdit(ethnicityId: string): void;
}

const CarrierRatesEdit = (props: CarrierRatesEditProps) => {
  const { client } = queryString.parse(props.location.search) as { client: 'A' | 'B' };
  
  const affectedGenes = useSelector<ApplicationState, string[]>((state) => {
    const { clientDetails } = state.report;
    const clientFields = client === 'A' ? clientDetails.clientA : clientDetails.clientB;
    
    return clientFields.affectedGenes;
  });
  
  const memberId = useSelector<ApplicationState, string>((state) => {
    const { clientDetails } = state.report;
    const clientFields = client === 'A' ? clientDetails.clientA : clientDetails.clientB;
    
    return clientFields.memberId;
  });
  
  const selectedCarrierRatesByGene = useSelector<ApplicationState, Dictionary<string>>(state => {
    const carrierRatesByGeneForClient: Dictionary<string> = {};
    const { selectedCarrierRateEthnicitiesByGene } = state.report.carrierRates;
    
    Object.keys(selectedCarrierRateEthnicitiesByGene).map(gene => {
      carrierRatesByGeneForClient[gene] = selectedCarrierRateEthnicitiesByGene[gene][client];
    });
    
    return carrierRatesByGeneForClient;
  });
  
  const carrierRatesByGene = useSelector<ApplicationState, RequestCarrierStatisticsByGeneResult>(state => {
    return state.report.clientDetails.carrierRatesByGene;
  });
  
  const memberName = useSelector<ApplicationState, string>(state => {
    const { clientDetails } = state.report;
    const clientFields = client === 'A' ? clientDetails.clientA : clientDetails.clientB;
    
    return `${clientFields.fullName}, ${clientFields.ethnicity}`;
  });
  
  const diseaseNamesByGene = useSelector<ApplicationState, Dictionary<string>>(state => {
    const { diseasesByGene } = state.report.clientDetails;
    const result: Dictionary<string> = {};
    Object.keys(diseasesByGene).map(gene => {
      result[gene] = diseasesByGene[gene].name;
    })
    
    
    return result;
  });
  
  const dispatch = useDispatch();
  
  const onClose = React.useCallback(() => {
    dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CARRIER_RATES));
  }, [dispatch, navigate]);
  
  const onSubmit = React.useCallback(values => {
    const { selectedCarrierRatesByGene } = values;
    Object.keys(selectedCarrierRatesByGene).forEach(gene => {
      dispatch(changeCarrierRateEthnicity(gene, client, selectedCarrierRatesByGene[gene]));
    });
    showToast('success', 'Updated carrier rates', 'Updated');
    onClose();
  }, [client, dispatch, showToast, onClose]);

  return (
    <Modal
      show
      onHide={onClose}
      title={memberName}
    >
      <CarrierRatesForm
        affectedGenes={affectedGenes}
        selectedCarrierRatesByGene={selectedCarrierRatesByGene}
        carrierRatesByGene={carrierRatesByGene}
        diseaseNamesByGene={diseaseNamesByGene}
        memberId={memberId}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
export default CarrierRatesEdit;
