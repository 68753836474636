import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, CreateDoctorClinicResult } from '../../../../graphql/genie-api-types';
import DoctorClinicForm, { DoctorClinicFormValues } from '../../../components/doctor-clinic/DoctorClinicForm';
import { MutationFunction } from 'react-apollo';
import gql from 'graphql-tag';
import { showToast } from '../../../store/toast/actions';
import { SearchAutocompleteOption } from '../../../components/widgets/SearchAutocomplete';

interface DoctorClinicCreateProps {
  clinic: SearchAutocompleteOption;
  onCreate?(doctorClinic: CreateDoctorClinicResult['doctorClinic']): void;
  onCancel?(): void;
}

const DoctorClinicCreate = (props: DoctorClinicCreateProps) => (
  <GenieMutation
    refetchQueries={() => {
      return ['ListDoctorClinicPairs'];
    }}
    mutation={gql`
      mutation CreateDoctorClinic($input: CreateDoctorClinicInput!) {
        createDoctorClinic(input: $input) {
          doctorClinic {
            id,
            doctorId,
            clinicId
          }
        }
      }
    `}
  >
    {(mutate: MutationFunction<Mutation>, result) => (
      <DoctorClinicForm
        initialValues={{
          clinic: props.clinic
        }}
        onCancel={props.onCancel}
        isLoading={result.loading}
        errorMessage={result?.error?.message ?? ''}
        onSubmit={async (formValues: DoctorClinicFormValues) => {
          console.log(formValues)
          const mutationResult = await mutate({
            variables: {
              input: {
                clinicId: formValues.clinic.id,
                doctorId: formValues.doctor.id,
                phone: formValues.phone,
                email: formValues.email,
              },
            },
          });

          if (mutationResult && props.onCreate) {
            props.onCreate(mutationResult.data.createDoctorClinic.doctorClinic);
          }

          showToast('success', 'Created Doctor-Clinic pair', `${formValues.doctorId}`)
        }}
      />
    )
  }
  </GenieMutation>
);

export default DoctorClinicCreate;
