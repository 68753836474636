/* eslint-disable react/display-name */
import * as React from 'react';
import { FiUser, FiUsers } from '../../app/components/widgets/Icon';
import { Product, ProductVariant } from "../../graphql/genie-api-types";

interface ProductVariantStrategy {
  title: string;
  shortTitle: string;
  label: string;
  iconRenderer: () => React.ReactNode;
}

const carrierCoupleStrategy: ProductVariantStrategy = {
  title: 'Carrier Couple',
  shortTitle: 'Couple',
  label: 'Couples Carrier Test',
  iconRenderer: () => <><FiUser /><FiUser /></>
}

const carrierIndividualStrategy: ProductVariantStrategy = {
  title: 'Carrier Individual',
  shortTitle: 'Individual',
  label: 'Individual Carrier Test',
  iconRenderer: () => <FiUser />
}

const carrierDonorStrategy: ProductVariantStrategy = {
  title: 'Carrier Donor',
  shortTitle: 'Donor',
  label: 'Couples Carrier Test (Donor)',
  iconRenderer: () => <FiUsers />
}

const cancerStrategy: ProductVariantStrategy = {
  title: 'Cancer',
  shortTitle: 'Cancer',
  label: 'Cancer',
  iconRenderer: () => '🦀'
}

const unknownStrategy: ProductVariantStrategy = {
  title: 'Unknown',
  shortTitle: 'Unknown',
  label: 'Unknown Test',
  iconRenderer: () => '❓'
}

type ProductVariantTable = {
  [key in Product]: ProductWithVariant | ProductWithoutVariant;
}

type ProductWithVariant = {
  hasVariant: true;
  label: string;
  variants: {
    [key: string]: ProductVariantStrategy;
  };
}

type ProductWithoutVariant = {
  hasVariant: false;
  label: string;
  variants: { };
  strategy: ProductVariantStrategy;
}

// For every new product or variant, add a strategy object
// and reference from the productVariantTable object below

const productVariantTable: ProductVariantTable = {
  [Product.Carrier]: {
    hasVariant: true,
    label: 'Carrier',
    variants: {
      [ProductVariant.Couple]: carrierCoupleStrategy,
      [ProductVariant.Donor]: carrierDonorStrategy,
      [ProductVariant.Individual]: carrierIndividualStrategy,
    }
  },
  [Product.Cancer]: {
    hasVariant: false,
    label: 'Cancer',
    variants: { },
    strategy: cancerStrategy,
  }
}

const unknownProduct: ProductWithoutVariant = {
  hasVariant: false,
  label: 'Unknown',
  variants: { },
  strategy: unknownStrategy,
}

function getProductStrategy(product: Product): ProductWithVariant | ProductWithoutVariant {
  const strategy = productVariantTable[product];
  if (!strategy) {
    return unknownProduct;
  }
  return strategy;
}

function getProductVariantStrategy(product: Product, productVariant?: ProductVariant) {
  const productStrategy = productVariantTable[product];
  if (!productStrategy) {
    return unknownStrategy;
  }

  let strategy: ProductVariantStrategy = null;
  if (productStrategy.hasVariant === true && productVariant != null) {
    strategy = productStrategy.variants[productVariant];
  } else if (productStrategy.hasVariant === false && productVariant == null) {
    strategy = productStrategy.strategy;
  }

  if (!strategy) {
    return unknownStrategy;
  } 
  return strategy;
}

export { getProductStrategy, getProductVariantStrategy, productVariantTable };