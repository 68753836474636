import * as React from 'react';
import { Journey } from '../../../../graphql/genie-api-types';
import { FiX, FiCheck } from '../../widgets/Icon';

interface LabOrderNumberCheckProps {
  journey: Pick<Journey, 'labOrderNumber'>;
};

const LabOrderNumberCheck = ({ journey }: LabOrderNumberCheckProps) => 
  journey.labOrderNumber ? <span className="text-xs text-green"><FiCheck /></span> : <FiX color="grey" />

export default LabOrderNumberCheck;
