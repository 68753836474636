import * as React from 'react';
import { Switch, Redirect } from 'react-router';
import Route from '../base/Route';
import { PATHS } from '../store/router/types';
import Manifest from './ops/Manifest';
import LabShippingHistory from './ops/LabShippingHistory';
import OpsMemberShipping from './ops/OpsMemberShipping';
import OpsAwaitingSamples from './ops/OpsAwaitingSamples';
import OpsLabShipping from './ops/OpsLabShipping';
import ManifestInvitae from './ops/ManifestInvitae';

const Ops = () => {
  return (
    <Switch>
      <Redirect from={PATHS.OPS_REQUESTS} to={PATHS.TASKS_SALES} />
      <Route path={PATHS.OPS_MEMBER_SHIPPING} exact component={OpsMemberShipping} />
      <Route path={PATHS.OPS_AWAITING_SAMPLES} exact component={OpsAwaitingSamples} />
      <Route path={PATHS.OPS_LAB_SHIPPING} exact component={OpsLabShipping} />
      <Route path={PATHS.OPS_MANIFEST} exact component={Manifest} />
      <Route path={PATHS.OPS_MANIFEST_INVITAE} exact component={ManifestInvitae} />
      <Route path={PATHS.OPS_HISTORY} exact component={LabShippingHistory} />
      <Route>
        <Redirect to={PATHS.OPS_MEMBER_SHIPPING}/>
      </Route>
    </Switch>
  );
};

export default Ops;
