import { Reducer } from 'redux';
import { SearchActionTypes, SearchState, SearchMode, MemberSearchState, DoctorSearchState, JourneySearchState, RequestSearchState } from './types';

const initialState: SearchState = {
  searchMode: 'member',
  memberSearchState: {},
  requestSearchState: {},
  journeySearchState: {},
  doctorSearchState: {},
};

const reducer: Reducer<SearchState> = (state = initialState, action): SearchState => {
  switch (action.type) {
    case SearchActionTypes.CHANGE_SEARCH_MODE:
      return {
        ...state,
        searchMode: action.payload as SearchMode,
      };

    case SearchActionTypes.CHANGE_MEMBER_SEARCH:
      return {
        ...state,
        memberSearchState: action.payload as MemberSearchState,
      };

    case SearchActionTypes.CHANGE_REQUEST_SEARCH:
      return {
        ...state,
        requestSearchState: action.payload as RequestSearchState,
      };

    case SearchActionTypes.CHANGE_JOURNEY_SEARCH:
      return {
        ...state,
        journeySearchState: action.payload as JourneySearchState,
      };

    case SearchActionTypes.CHANGE_DOCTOR_SEARCH:
      return {
        ...state,
        doctorSearchState: action.payload as DoctorSearchState,
      };

    default:
      return state;
  }
};

export { reducer as searchReducer };
