import { emailTemplates, emailBaseTemplates } from './template/_email';
import { Medium, SectionTemplateName, TemplateBaseName } from '../templates';
import { pdfReportTemplates, pdfReportBaseTemplates, pdfCancerTemplates } from './template/_pdfReport';

export const sectionTemplates: { [key in Medium]: {[key in SectionTemplateName]?: any} } = {
  pdfReport: pdfReportTemplates,
  email: emailTemplates,
};

export const baseTemplates: { [key in Medium]: {[key in TemplateBaseName]: any} } = {
  pdfReport: pdfReportBaseTemplates,
  email: emailBaseTemplates,
};

export const cancerTemplates = pdfCancerTemplates;