import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import JourneysPreGroup from '../../graphql/containers/journeys/groups/JourneysPreGroup';
import Screen from '../../components/widgets/Screen';

const JourneysPre = () => (
  <Screen>
    <Heading level={1} className="mb-20">Journeys: Pre</Heading>
    <JourneysPreGroup />
  </Screen>
);

export default JourneysPre;
