import * as React from 'react';
import { CreateCounsellorInput, CreateCounsellorResult } from '../../../../graphql/genie-api-types';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { showToast } from '../../../store/toast/actions';
import CounsellorForm from '../../../components/counsellor/CounsellorForm';

interface CounsellorCreateProps {
  onCreate?(counsellor: CreateCounsellorResult['counsellor']): void;
  onCancel?(): void;
}

const LIST_COUNSELLORS = gql`
  query {
    listCounsellors {
      id
      name
      nickname
      admin {
        id
        email
      }
    }
  }
`;

const ADD_COUNSELLOR = gql`
  mutation CreateCounsellor($input: CreateCounsellorInput!) {
    createCounsellor(input: $input) {
      counsellor {
        id,
        name,
        nickname,
        admin {
          id
          email
        }
      }
    }
  }
`;


const CounsellorCreate = (props: CounsellorCreateProps) => {
  const [addCounsellor, { loading }] = useMutation(ADD_COUNSELLOR, {
    refetchQueries: () => [{
      query: LIST_COUNSELLORS
    }],
  });

  const onSubmit = async (values: CreateCounsellorInput) => {
    const res = await addCounsellor({
      variables: {
        input: values
      }
    });

    if (res.data?.createCounsellor?.counsellor && props.onCreate) {
      props.onCreate(res.data?.createCounsellor?.counsellor);
      showToast('success', 'Counsellor has been added', `${res.data?.createCounsellor?.counsellor.name}`)
    } else {
      showToast('error', 'Cannot add counsellor due to', `${res.errors?.toString}`)
    }

  }

  return (
    <CounsellorForm
      initialValues={{}}
      onCancel={props.onCancel}
      isLoading={loading}
      onSubmit={onSubmit}
    />
  )
};

export default CounsellorCreate;
