import * as React from 'react';
import ClientDetailsEdit from './ClientDetailsEdit';
import { PATHS } from '../../../store/router/types';
import ClientDetailsView from './ClientDetailsView';
import Route from '../../../base/Route';

const ClientDetails = () => (
  <div>
    <Route path={PATHS.REPORTS_CLIENT_DETAILS} component={ClientDetailsView} />
    <Route path={`${PATHS.REPORTS_CLIENT_DETAILS}/:clientId`} component={ClientDetailsEdit} />
  </div>
);

export default ClientDetails;
