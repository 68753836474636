import React from 'react';
import { useDispatch } from 'react-redux';
import GenieSearch from '../graphql/containers/GenieSearch';
import Heading from '../components/widgets/Heading';
import Flags from '../graphql/containers/flags/Flags';
import DropdownButton, { Action } from '../components/widgets/DropdownButton';
import { FormName } from '../graphql/containers/SwitchForm';
import { navigate } from '../store/router/actions';
import { FlagType } from '../../graphql/genie-api-types';
import OwnUrgentPriorityFlags from '../graphql/containers/flags/OwnUrgentPriorityFlags';
import Tabs from '../components/widgets/Tabs';
import { FLAG_TYPE_LABELS } from '../../utilities/flags/flagLabels';
import { Collapsible } from '../components/widgets/Collapsible';
import Screen from '../components/widgets/Screen';
import FeedbackFlags from '../graphql/containers/flags/FeedbackFlags';
import FailedSamples from '../graphql/containers/kits/FailedSamples';

interface WelcomeAction extends Action {
  name: FormName;
}

const actions: WelcomeAction[] = [
  {
    label: 'New Request',
    name: 'RequestCreate',
    variant: 'outline-primary',
  },
  {
    label: 'New Doctor',
    name: 'DoctorClinicCreate',
    variant: 'outline-primary',
  },
];

const Welcome = () => {
  const dispatch = useDispatch();

  const onNavigateToForm = React.useCallback((formName: FormName) => {
    dispatch(navigate.toForm(formName));
  }, [dispatch]);

  return (
    <Screen>
      <div className="flex justify-between items-end pb-40">
        <Heading level={1}>Today 🌞</Heading>
        <DropdownButton actions={actions} onActionClick={onNavigateToForm} />
      </div>
      <GenieSearch />

      <Collapsible title="My priority flags" className="mt-50" contentClass="mt-10">
        <OwnUrgentPriorityFlags />
      </Collapsible>

      <Collapsible title="Failed samples for followup" className="mt-50" contentClass="mt-10">
        <FailedSamples />
      </Collapsible>

      <Collapsible title="Other flags" className="mt-50">
        <Tabs
          tabs={[FlagType.OutOfOurControl, FlagType.NeedsAttention, FlagType.FollowUp, FlagType.AwaitingInput].map(flagType => ({
            name: flagType,
            label: FLAG_TYPE_LABELS[flagType],
          }))}
          defaultTab={FlagType.OutOfOurControl}
        >
          {(tabName: FlagType) => <Flags flagType={tabName} key={tabName} />}
        </Tabs>
      </Collapsible>
      <Collapsible title="Feedback" className="mt-50">
        <FeedbackFlags />
      </Collapsible>
    </Screen>
  );
};

export default Welcome;
