import { Journey } from '../../graphql/genie-api-types';

export const hasShippedKits = (journey: Pick<Journey, 'kits'>) => {
  if (journey?.kits) {
    const hasRegisteredKit = !!journey.kits.find((kit) => !kit.recollectionRequestedDate && kit.dateShippedToMember);

    return hasRegisteredKit;
  }

  return false;
};

export const labOrderNumberUrl = (journey: Pick<Journey, 'labOrderNumber'>) =>
  `https://www.invitae.com/hcp/#/order-status/${journey.labOrderNumber}`;