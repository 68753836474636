import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import Route from '../../../base/Route';
import CarrierRatesView from './CarrierRatesView';
import CarrierRatesEdit from './CarrierRatesEdit';

const CarrierRates = () => (
  <div>
    <Route path={PATHS.REPORTS_CARRIER_RATES} component={CarrierRatesView} />
    <Route path={PATHS.REPORTS_CARRIER_RATES_EDIT} component={CarrierRatesEdit} />
  </div>
);

export default CarrierRates;
