import { Reducer } from 'redux';
import { ResearchState, ResearchTypes } from './types';

const initialState: ResearchState = {
  session: null,
};

const reducer: Reducer<ResearchState> = (state = initialState, action): ResearchState => {
  switch (action.type) {
    case ResearchTypes.START_SESSION:
      return {
        ...state,
        session: action.payload,
      };
      
    default:
      return state;
  }
};

export { reducer as researchReducer };