export const barChartDataFormatter = (
  howManyWeeksToProject: number,
  cancerPretestWeeklyMinutes: any[],
  cancerFhxWeeklyMinutes: any[],
  cancerPosttestWeeklyMinutes: any[],
  carrierPretestWeeklyMinutes: any[],
  carrierFhxWeeklyMinutes: any[],
  carrierLowriskWeeklyMinutes: any[],
  carrierIncreasedriskWeeklyMinutes: any[],
  carrierHighriskWeeklyMinutes: any[]
) => {
  const result = [];

  for (let weekNum = 1; weekNum <= howManyWeeksToProject; weekNum++) {
    let nameVal;

    if (weekNum === 1) {
      nameVal = "This week";
    } else {
      nameVal = "week " + weekNum;
    }

    result.push({
      name: nameVal,
      cancerpre: cancerPretestWeeklyMinutes[weekNum]
        ? cancerPretestWeeklyMinutes[weekNum]
        : 0,
      cancerfhx: cancerFhxWeeklyMinutes[weekNum]
        ? cancerFhxWeeklyMinutes[weekNum]
        : 0,
      cancerpost: cancerPosttestWeeklyMinutes[weekNum]
        ? cancerPosttestWeeklyMinutes[weekNum]
        : 0,
      carrierpre: carrierPretestWeeklyMinutes[weekNum]
        ? carrierPretestWeeklyMinutes[weekNum]
        : 0,
      carrierfhx: carrierFhxWeeklyMinutes[weekNum]
        ? carrierFhxWeeklyMinutes[weekNum]
        : 0,
      carrierlow: carrierLowriskWeeklyMinutes[weekNum]
        ? carrierLowriskWeeklyMinutes[weekNum]
        : 0,
      carrierinc: carrierIncreasedriskWeeklyMinutes[weekNum]
        ? carrierIncreasedriskWeeklyMinutes[weekNum]
        : 0,
      carrierhigh: carrierHighriskWeeklyMinutes[weekNum]
        ? carrierHighriskWeeklyMinutes[weekNum]
        : 0,
    });
  }

  result.push({
    name: "Extra",
    cancerpre: cancerPretestWeeklyMinutes[0]
      ? cancerPretestWeeklyMinutes[0]
      : 0,
    cancerfhx: cancerFhxWeeklyMinutes[0] ? cancerFhxWeeklyMinutes[0] : 0,
    cancerpost: cancerPosttestWeeklyMinutes[0]
      ? cancerPosttestWeeklyMinutes[0]
      : 0,
    carrierpre: carrierPretestWeeklyMinutes[0]
      ? carrierPretestWeeklyMinutes[0]
      : 0,
    carrierfhx: carrierFhxWeeklyMinutes[0] ? carrierFhxWeeklyMinutes[0] : 0,
    carrierlow: carrierLowriskWeeklyMinutes[0]
      ? carrierLowriskWeeklyMinutes[0]
      : 0,
    carrierinc: carrierIncreasedriskWeeklyMinutes[0]
      ? carrierIncreasedriskWeeklyMinutes[0]
      : 0,
    carrierhigh: carrierHighriskWeeklyMinutes[0]
      ? carrierHighriskWeeklyMinutes[0]
      : 0,
  });

  return result;
};

export const tableHeaderFormatter = (howManyWeeksToProject: number) => {
  const headers = ["Appointment Type"];

  for (let weekNum = 1; weekNum <= howManyWeeksToProject; weekNum++) {
    if (weekNum === 1) {
      headers.push("This week");
    } else {
      headers.push("week " + weekNum);
    }
  }

  headers.push("Extra");

  return headers;
};

const tableRowFormatter = (
  howManyWeeksToProject: number,
  dataArray: any[],
  rowTitle: string
) => {
  const dataResult = [rowTitle];

  for (let weekNum = 1; weekNum <= howManyWeeksToProject; weekNum++) {
    dataResult.push(dataArray[weekNum] ? dataArray[weekNum] : 0);
  }

  dataResult.push(dataArray[0] ? dataArray[0] : 0);

  return dataResult;
};

const tableTotalRowFormatter = (
  howManyWeeksToProject: number,
  cancerPretestWeeklyMinutes: any[],
  cancerFhxWeeklyMinutes: any[],
  cancerPosttestWeeklyMinutes: any[],
  carrierPretestWeeklyMinutes: any[],
  carrierFhxWeeklyMinutes: any[],
  carrierLowriskWeeklyMinutes: any[],
  carrierIncreasedriskWeeklyMinutes: any[],
  carrierHighriskWeeklyMinutes: any[],
  rowTitle: string
) => {
  const dataResult = [rowTitle];

  for (let weekNum = 1; weekNum <= howManyWeeksToProject; weekNum++) {
    dataResult.push(
      (cancerPretestWeeklyMinutes[weekNum]
        ? cancerPretestWeeklyMinutes[weekNum]
        : 0) +
        (cancerFhxWeeklyMinutes[weekNum]
          ? cancerFhxWeeklyMinutes[weekNum]
          : 0) +
        (cancerPosttestWeeklyMinutes[weekNum]
          ? cancerPosttestWeeklyMinutes[weekNum]
          : 0) +
        (carrierPretestWeeklyMinutes[weekNum]
          ? carrierPretestWeeklyMinutes[weekNum]
          : 0) +
        (carrierFhxWeeklyMinutes[weekNum]
          ? carrierFhxWeeklyMinutes[weekNum]
          : 0) +
        (carrierLowriskWeeklyMinutes[weekNum]
          ? carrierLowriskWeeklyMinutes[weekNum]
          : 0) +
        (carrierIncreasedriskWeeklyMinutes[weekNum]
          ? carrierIncreasedriskWeeklyMinutes[weekNum]
          : 0) +
        (carrierHighriskWeeklyMinutes[weekNum]
          ? carrierHighriskWeeklyMinutes[weekNum]
          : 0)
    );
  }

  dataResult.push(
    (cancerPretestWeeklyMinutes[0] ? cancerPretestWeeklyMinutes[0] : 0) +
      (cancerFhxWeeklyMinutes[0] ? cancerFhxWeeklyMinutes[0] : 0) +
      (cancerPosttestWeeklyMinutes[0] ? cancerPosttestWeeklyMinutes[0] : 0) +
      (carrierPretestWeeklyMinutes[0] ? carrierPretestWeeklyMinutes[0] : 0) +
      (carrierFhxWeeklyMinutes[0] ? carrierFhxWeeklyMinutes[0] : 0) +
      (carrierLowriskWeeklyMinutes[0] ? carrierLowriskWeeklyMinutes[0] : 0) +
      (carrierIncreasedriskWeeklyMinutes[0]
        ? carrierIncreasedriskWeeklyMinutes[0]
        : 0) +
      (carrierHighriskWeeklyMinutes[0] ? carrierHighriskWeeklyMinutes[0] : 0)
  );

  return dataResult;
};

export const tableDataFormatter = (
  howManyWeeksToProject: number,
  cancerPretestWeeklyMinutes: any[],
  cancerFhxWeeklyMinutes: any[],
  cancerPosttestWeeklyMinutes: any[],
  carrierPretestWeeklyMinutes: any[],
  carrierFhxWeeklyMinutes: any[],
  carrierLowriskWeeklyMinutes: any[],
  carrierIncreasedriskWeeklyMinutes: any[],
  carrierHighriskWeeklyMinutes: any[]
) => {
  const result = [];

  result.push({
    id: 1,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      cancerPretestWeeklyMinutes,
      "Cancer - Pretest"
    ),
  });

  result.push({
    id: 2,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      cancerFhxWeeklyMinutes,
      "Cancer - Family History"
    ),
  });

  result.push({
    id: 3,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      cancerPosttestWeeklyMinutes,
      "Cancer - Posttest"
    ),
  });

  result.push({
    id: 4,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      carrierPretestWeeklyMinutes,
      "Carrier - Pretest"
    ),
  });

  result.push({
    id: 5,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      carrierFhxWeeklyMinutes,
      "Carrier - Family History"
    ),
  });

  result.push({
    id: 6,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      carrierLowriskWeeklyMinutes,
      "Carrier - Low risk"
    ),
  });

  result.push({
    id: 7,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      carrierIncreasedriskWeeklyMinutes,
      "Carrier - Increased risk"
    ),
  });

  result.push({
    id: 8,
    cells: tableRowFormatter(
      howManyWeeksToProject,
      carrierHighriskWeeklyMinutes,
      "Carrier - High risk"
    ),
  });

  result.push({
    id: 9,
    cells: tableTotalRowFormatter(
      howManyWeeksToProject,
      cancerPretestWeeklyMinutes,
      cancerFhxWeeklyMinutes,
      cancerPosttestWeeklyMinutes,
      carrierPretestWeeklyMinutes,
      carrierFhxWeeklyMinutes,
      carrierLowriskWeeklyMinutes,
      carrierIncreasedriskWeeklyMinutes,
      carrierHighriskWeeklyMinutes,
      "Total hours"
    ),
  });

  return result;
};
