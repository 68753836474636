import Amplify from '@aws-amplify/core';
import { default as AmplifyAuth } from '@aws-amplify/auth';
import config from '../config';
import * as queryString from 'query-string';

const oauth = {
  domain: `${config('COGNITO_DOMAIN_PREFIX')}.auth.ap-southeast-2.amazoncognito.com`,
  scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config('COGNITO_CALLBACK_URL'),
  redirectSignOut: config('COGNITO_SIGN_OUT_URL'),
  responseType: 'code',
};

Amplify.configure({
  Auth: {
    oauth,
    userPoolWebClientId: config('COGNITO_CLIENT_ID'),
    userPoolId: config('COGNITO_USER_POOL_ID'),
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 1,
      secure: false,
    },
  },
});

const getSignInUrl = (): string => {
  const query = {
    response_type: 'code',
    client_id: config('COGNITO_CLIENT_ID'),
    redirect_uri: encodeURI(config('COGNITO_CALLBACK_URL')),
    state: `redirecturl%3Dhttps%3A%2F%2F${window.location.hostname}`, // For linc oauth redirector
  }
  
  return `https://${config('COGNITO_DOMAIN_PREFIX')}.auth.ap-southeast-2.amazoncognito.com/login?${queryString.stringify(query)}`;
}

const getSignOutUrl = (): string => {
  const query = {
    response_type: 'code',
    client_id: config('COGNITO_CLIENT_ID'),
    redirect_uri: encodeURI(config('COGNITO_SIGN_OUT_URL')),
    state: `redirecturl%3Dhttps%3A%2F%2F${window.location.hostname}`, // For linc oauth redirector
  };

  return `https://${config('COGNITO_DOMAIN_PREFIX')}.auth.ap-southeast-2.amazoncognito.com/logout?${queryString.stringify(query)}`;
};
export { AmplifyAuth, getSignInUrl, getSignOutUrl };
