import { Reducer } from 'redux';
import { NavigationState, NavigationAction } from './types';

const initialState: NavigationState = {
  history: [],
  historyIndex: -1,
};

type LocationChangeAction = {
  type: '@@router/LOCATION_CHANGE';
  payload: {
    action: 'POP' | 'PUSH';
    location: {
      pathname: string;
      key: string;
    };
  };
}

const reducer: Reducer<NavigationState> = (state = initialState, action: NavigationAction | LocationChangeAction) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      const history = [...state.history];
      let historyIndex = state.historyIndex;

      if (action.payload.action === 'POP') {
        const existingHistoryIndex = history.findIndex(item => item === action.payload.location.key);
        if (existingHistoryIndex > -1) {
          historyIndex = existingHistoryIndex;
        }
        else {
          historyIndex = historyIndex === -1 ? historyIndex : historyIndex - 1;
        }
      }
      else if (action.payload.action === 'PUSH') {
        history.push(action.payload.location.key);
        historyIndex = history.length - 1;
      }

      return {
        ...state,
        history,
        historyIndex,
      }

    default:
      return state;
  }
};

export { reducer as navigationReducer };
