import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import moment from 'moment';
import { Mutation, UpdateFamilyHistoryGroupItemResult, MutationUpdateFamilyHistoryItemArgs, UpdateFamilyHistoryGroupItemInput, Query, QueryFamilyHistoryItemArgs } from '../../../../graphql/genie-api-types';
import { MutationFunction, QueryResult } from 'react-apollo';
import gql from 'graphql-tag';
import FamilyHistoryForm from '../../../components/family-history/FamilyHistoryForm';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface FamilyHistoryUpdateProps {
  onUpdate?(familyHistoryGroupItem: UpdateFamilyHistoryGroupItemResult['familyHistoryGroupItem']): void;
  onCancel?(): void;
  familyHistoryGroupItemId: string;
}

const FamilyHistoryUpdate = ({ onUpdate, onCancel, familyHistoryGroupItemId }: FamilyHistoryUpdateProps) => (
  <GenieQuery<Pick<Query, 'familyHistoryGroupItem'>, QueryFamilyHistoryItemArgs>
    query={gql`
      query FamilyHistoryItem($id: ID!) {
        familyHistoryGroupItem(id: $id) {
          id
          category
          classification
          name
          value
          dateCreated
          dateUpdated
          username
        }
      }
    `}
    errorPolicy="all"
    variables={{
      id: familyHistoryGroupItemId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'familyHistoryGroupItem'>>) => loading ?
      (
        <Spinner label="Loading family history..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateFamilyHistoryItem($input: UpdateFamilyHistoryGroupItemInput!) {
            updateFamilyHistoryGroupItem(input: $input) {
              familyHistoryGroupItem {
                id
              }
            }
          }
        `}
        refetchQueries={() => {
          return ['QueryMember'];
        }}
        >
          {(mutate: MutationFunction<Pick<Mutation, 'updateFamilyHistoryGroupItem'>, MutationUpdateFamilyHistoryItemArgs>, result) => (
            <div>
              <div className="border p-10 bg-white mb-20">
                Created {moment(data.familyHistoryGroupItem.dateCreated).format('Do MMM, YYYY')} {data.familyHistoryGroupItem.username ? `by ${data.familyHistoryGroupItem.username}` : ''}
                {data.familyHistoryGroupItem.dateUpdated ? ` (Modified: ${moment(data.familyHistoryGroupItem.dateUpdated).format('Do MMM, YYYY')})` : ''}
              </div>
              <FamilyHistoryForm
                initialValues={{ ...data.familyHistoryGroupItem }}
                isLoading={result.loading}
                errorMessage={result?.error?.message}
                onCancel={onCancel}
                onSubmit={async ({ id, category, classification, name, value }: UpdateFamilyHistoryGroupItemInput) => {
                  const mutationResult = await mutate({
                    variables: {
                      input: { id, category, classification, name, value },
                    },
                  });
                  if (mutationResult) {
                    onUpdate(mutationResult.data.updateFamilyHistoryGroupItem.familyHistoryGroupItem);
                  }

                  ReduxQueryListener.triggerEvents('UpdateFamilyHistory');
                }}
              />
            </div>
          )}
        </GenieMutation>
      )}
  </GenieQuery>

);

export default FamilyHistoryUpdate;
