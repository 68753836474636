import * as React from 'react';
import BaseForm, { BaseFormProps } from '../../widgets/BaseForm';
import GeneAutocompleteTag from '../../../graphql/containers/GeneAutocompleteTag';
import FormInput, { FormInputProps } from '../../widgets/form/FormInput';
import EthnicitySearchAutocomplete from '../../../graphql/containers/EthnicitySearchAutocomplete';
import FormGroup from '../../widgets/form/FormGroup';
import { Member, Product } from '../../../../graphql/genie-api-types';
import FamilyHistoryExtract from '../../../graphql/containers/family-history/FamilyHistoryExtract';

export interface ClientDetailsFormValues {
  fullName?: string;
  nickname?: string;
  dob?: string;
  sex?: 'Male' | 'Female';
  sampleCollectionDate?: string;
  reportProcessedDate?: string;
  affectedGenes?: string[];
}

interface ClientDetailsFormProps extends ClientDetailsFormValues {
  initialValues: BaseFormProps['initialValues'];
  onSubmit: BaseFormProps['onSubmit'];
  memberId?: Member['id'];
  product: string;
}

const formItems: Pick<FormInputProps, 'label' | 'name' | 'as' | 'options' | 'type' | 'min' | 'max'>[] = [
  {
    label: 'Full name',
    name: 'fullName',
  },
  {
    label: 'Nickname',
    name: 'nickname',
  },
  {
    label: 'Sex assigned at birth',
    name: 'sex',
    as: 'select',
    options: [
      {
        value: '',
        label: '-',
      },
      {
        value: 'Male',
        label: 'Male',
      },
      {
        value: 'Female',
        label: 'Female',
      },
    ],
  },
  {
    label: 'DOB',
    name: 'dob',
    type: 'date',
    min: '1940-01-01',
    max: '2010-01-01',
  },
  {
    label: 'Report processed date',
    name: 'reportProcessedDate',
    type: 'date',
    min: '2019-01-01',
    max: '3022-01-01',
  },
  {
    label: 'Sample collection date',
    name: 'sampleCollectionDate',
    type: 'date',
    min: '2019-01-01',
    max: '3022-01-01',
  },
  {
    label: 'Agree to share result with partner',
    name: 'partnerCanReceiveResults',
    as: 'select',
    options: [
      {
        value: '',
        label: 'Missing result consent',
      },
      {
        value: 'false',
        label: 'No',
      },
      {
        value: 'true',
        label: 'Yes',
      },
    ],
  },
];

interface ClientDetailsFormState {
  isNicknameEdited: boolean;
}

class ClientDetailsForm extends React.PureComponent<ClientDetailsFormProps, ClientDetailsFormState> {
  state: Readonly<ClientDetailsFormState> = {
    isNicknameEdited: false,
  };

  static getDerivedStateFromProps(props: ClientDetailsFormProps, state: ClientDetailsFormState) {
    if (props.initialValues['nickname']) {
      return {
        ...state,
        isNicknameEdited: true,
      };
    }
    return state;
  }

  onInput(event: React.FormEvent<any>, setFieldValue: (field: string, value: any) => void) {
    const { isNicknameEdited } = this.state;
    if (event.type !== 'input' || isNicknameEdited) {
      return;
    }

    const formInput: HTMLInputElement = event.target as HTMLInputElement;
    if (formInput.name === 'fullName' && formInput.value.length > 0) {
      const firstName = formInput.value.split(' ')[0];
      setFieldValue('nickname', firstName);
    } else if (formInput.name === 'nickname' && formInput.value.length > 0) {
      this.setState({
        isNicknameEdited: true,
      });
    }
  }

  render() {
    return (
      <>
        <BaseForm
          {...this.props}
          submitButtonLabel="Save"
        >
          {({
            handleChange,
            values,
            setFieldValue,
          }) => (
            <div>
              {formItems.map((formItem, index) => (
                <FormInput
                  {...formItem}
                  value={values[formItem.name]}
                  onChange={handleChange}
                  key={formItem.name}
                  autoFocus={index === 0}
                  onInput={event => this.onInput(event, setFieldValue)}
                  setFieldValue={setFieldValue}
                />
              ))}
              {this.props.product !== Product.Cancer &&
                <>
                  <FormGroup controlId="affectedGenes" label="Affected Genes">
                    <GeneAutocompleteTag
                      onSelectGenes={selectedGenes => setFieldValue('affectedGenes', selectedGenes)}
                      defaultSelectedGenes={values.affectedGenes}
                    />
                  </FormGroup>
                  <FormGroup controlId="pseudogenes" label="Pseudogenes">
                    <GeneAutocompleteTag
                      onSelectGenes={selectedGenes => setFieldValue('pseudogenes', selectedGenes)}
                      defaultSelectedGenes={values.pseudogenes}
                    />
                  </FormGroup>
                  <FormGroup controlId="ethnicity" label="Ethnicity">
                    <>
                      <EthnicitySearchAutocomplete
                        onSelect={ethnicity => setFieldValue('ethnicity', ethnicity?.id ?? null)}
                        defaultValue={values.ethnicity}
                      />
                    </>
                  </FormGroup>
                </>
              }
            </div>
          )}
        </BaseForm>
        {this.props.memberId && this.props.product !== Product.Cancer && (
          <FamilyHistoryExtract
            memberId={this.props.memberId}
            familyHistoryQuestionKeys={["Ethnicity"]}
          />
        )}
      </>
    );
  }
}

export default ClientDetailsForm;
