import * as React from 'react';
import RequestCreate from './requests/RequestCreate';
import RequestUpdate from './requests/RequestUpdate';
import MemberUpdate from './members/MemberUpdate';
import DoctorUpdate from './doctors/DoctorUpdate';
import DoctorCreate from './doctors/DoctorCreate';
import ClinicUpdate from './clinics/ClinicUpdate';
import ClinicCreate from './clinics/ClinicCreate';
import ErrorBoundary from '../../base/ErrorBoundary';
import MoveJourneysToStateForm from './journeys/MoveJourneysToStateForm';
import RecollectKit from './kits/RecollectKit';
import RequestPaid from './requests/RequestPaid';
import RequestApproval from './requests/RequestApproval';
import JourneyConsent from './journeys/JourneyConsent';
import JourneyLabOrderNumber from './journeys/JourneyLabOrderNumber';
import RequestOnHoldUpdate from './requests/RequestOnHoldUpdate';
import ReferralCreate from './referrals/ReferralCreate';
import {
  CreateDoctorResult,
  CreateMemberResult,
  CreateRequestResult,
  CreateJourneyResult,
  CreateFamilyHistoryGroupItemResult,
  Journey,
  CreateCounsellorResult,
  CreateDoctorClinicResult, CreateClinicResult
} from '../../../graphql/genie-api-types';
import ReferralUpdate from './referrals/ReferralUpdate';
import FollowUpCreate from './follow-ups/FollowUpCreate';
import FlagCreate from './flags/FlagCreate';
import MemberCreate from './members/MemberCreate';
import JourneyCreate from './journeys/JourneyCreate';
import JourneyUpdate from './journeys/JourneyUpdate';
import RecommendationUpdate from './recommendations/RecommendationUpdate';
import FamilyHistoryCreate from './family-history/FamilyHistoryCreate';
import FamilyHistoryUpdate from './family-history/FamilyHistoryUpdate';
import FamilyHistoryReassign from './family-history/FamilyHistoryReassign';
import KitsAssignNumber from './kits/KitsAssignNumber';
import KitsUpdate from './kits/KitsUpdate';
import ConsentReassign from '../containers/consent/ConsentReassign';
import WithdrawConsent from '../containers/consent/WithdrawConsent';
import RequestSyncWithAcuity from './requests/RequestSyncWithAcuity';
import CancelTest from './journeys/CancelTest';
import MemberOnlineAccess from './requests/MemberOnlineAccess';
import LeadsClose from './leads/LeadsClose';
import LeadInfoAndClose from './leads/LeadInfoAndClose';
import CounsellorCreate from './counsellors/CounsellorCreate';
import CounsellorUpdate from './counsellors/CounsellorUpdate';

export type FormName = 'RequestCreate' |
  'MemberUpdate' |
  'MemberCreate' |
  'MemberOnlineAccess' |
  'RequestUpdate' |
  'JourneysMove' |
  'RecollectKit' |
  'RequestPaid' |
  'RequestApproval' |
  'JourneyCreate' |
  'JourneyUpdate' |
  'JourneyConsent' |
  'JourneyLabOrderNumber' |
  'ClinicUpdate' |
  'ClinicCreate' |
  'DoctorUpdate' |
  'DoctorCreate' |
  'DoctorClinicUpdate' |
  'DoctorClinicCreate' |
  'CounsellorCreate' |
  'CounsellorUpdate' |
  'RequestHoldStatusUpdate' |
  'ReferralCreate' |
  'ReferralUpdate' |
  'FollowUpCreate' |
  'RecommendationUpdate' |
  'FamilyHistoryCreate' |
  'FamilyHistoryUpdate' |
  'FlagCreate' |
  'ReassignFamilyHistorySubmission' |
  'ReassignConsent' |
  'WithdrawConsent' |
  'KitsAssignNumber' |
  'KitsUpdateArrived' |
  'KitsUpdate' |
  'AcuityPreTestMatch' |
  'CancelTest' |
  'LeadsClose' |
  'LeadInfoAndClose' ;

export type ClinicModelResult = CreateClinicResult['clinic'];
export type DoctorModelResult = CreateDoctorResult['doctor'];
export type DoctorClinicModelResult = CreateDoctorClinicResult['doctorClinic'];
export type CounsellorModelResult = CreateCounsellorResult['counsellor'];
export type MemberModelResult = CreateMemberResult['member'];
export type RequestModelResult = CreateRequestResult['request'];
export type JourneyModelResult = CreateJourneyResult['journey'];
export type FamilyHistoryItemModelResult = CreateFamilyHistoryGroupItemResult['familyHistoryGroupItem'];

export type AnyModelResult = ClinicModelResult | DoctorModelResult | DoctorClinicModelResult | MemberModelResult | RequestModelResult | JourneyModelResult | FamilyHistoryItemModelResult | CounsellorModelResult;

interface SwitchFormProps<ModelResult> {
  form?: FormName;
  formParams?: any;
  onComplete(result?: ModelResult): void;
  onCancel(): void;
  onRedirectToForm?(formName: FormName, formParams?: any): void;
}

class SwitchForm<ModelResult extends AnyModelResult = null> extends React.PureComponent<SwitchFormProps<ModelResult>> {
  getTitle() {
    const { form } = this.props;

    switch (form) {
      case 'RequestCreate':
        return 'Create Request';

      case 'RequestUpdate':
        return 'Edit Request';

      case 'MemberCreate':
        return 'Create Member';

      case 'MemberUpdate':
        return 'Edit Member';
        
      case 'MemberOnlineAccess':
        return 'Toggle online access';

      case 'JourneysMove':
        return 'Move Journey';

      case 'RecollectKit':
        return 'Recollect a kit';

      case 'RequestPaid':
        return 'Mark request as paid';

      case 'RequestApproval':
        return 'Approve Request';

      case 'JourneyCreate':
        return 'Create Journey';

      case 'JourneyUpdate':
        return 'Update Journey';

      case 'JourneyConsent':
        return 'Manage Consent';

      case 'JourneyLabOrderNumber':
        return 'Change Lab Order Number';

      case 'DoctorUpdate':
        return 'Edit Doctor';

      case 'DoctorCreate':
        return 'Create Doctor';

      case 'DoctorClinicUpdate':
        return 'Edit Doctor-Clinic Pair';

      case 'ClinicCreate':
        return 'Create Clinic';

      case 'ClinicUpdate':
        return 'Edit Clinic';

      case 'DoctorClinicCreate':
        return 'Create Doctor-Clinic Pair';

      case 'CounsellorCreate':
        return 'Create Counsellor';

      case 'CounsellorUpdate':
        return 'Update Counsellor';

      case 'RequestHoldStatusUpdate':
        return 'Change on-hold status';

      case 'ReferralCreate':
        return 'Create Referral';

      case 'ReferralUpdate':
        return 'Update Referral';

      case 'FollowUpCreate':
        return 'Create Follow up';

      case 'RecommendationUpdate':
        return 'Verify Recommendation';

      case 'FamilyHistoryCreate':
        return 'Add Family History';

      case 'FamilyHistoryUpdate':
        return 'Update Family History';

      case 'FlagCreate':
        return 'Add Flag';

      case 'ReassignFamilyHistorySubmission':
        return 'Reassign Family History Submission';

      case 'KitsAssignNumber':
        return 'Assign kit number';

      case 'KitsUpdateArrived':
        return 'Mark kits as arrived';

      case 'KitsUpdate':
        return 'Update kits';

      case 'ReassignConsent':
        return 'Reassign consent';

      case 'WithdrawConsent':
        return 'Withdraw consent';

      case 'AcuityPreTestMatch':
        return 'Sync with Acuity';

      case 'CancelTest':
        return 'Cancel test';

      case 'LeadsClose':
        return 'Close leads';
        
      case 'LeadInfoAndClose':
        return 'Lead info';

      default:
        return '';
    }
  }

  onComplete = (model?: AnyModelResult) => {
    const { onComplete } = this.props;

    onComplete(model as ModelResult);
  }

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  renderForm() {
    const { form, formParams, onRedirectToForm } = this.props;


    switch (form) {
      case 'RequestCreate':
        return <RequestCreate onComplete={this.onComplete} />;

      case 'MemberUpdate':
        return <MemberUpdate memberId={formParams.memberId} onCancel={this.onCancel} onUpdate={this.onComplete} />;

      case 'MemberCreate':
        return <MemberCreate onCancel={this.onCancel} onCreate={this.onComplete} />;

      case 'MemberOnlineAccess':
        return <MemberOnlineAccess memberId={formParams.memberId} onUpdate={this.onComplete} />;

      case 'RequestUpdate':
        return <RequestUpdate requestId={formParams.requestId} onComplete={this.onComplete} />;

      case 'JourneysMove':
        return <MoveJourneysToStateForm journeyIds={formParams.journeyIds as Journey['id'][]} onComplete={this.onComplete} />;

      case 'JourneyCreate':
        return <JourneyCreate requestId={formParams.requestId} onCreate={this.onComplete} onCancel={this.onCancel} />;

      case 'JourneyUpdate':
        return <JourneyUpdate journeyId={formParams.journeyId} onUpdate={this.onComplete} onCancel={this.onCancel} />;

      case 'RecollectKit':
        return <RecollectKit kitId={formParams.kitId} onComplete={this.onComplete} />;

      case 'RequestPaid':
        return <RequestPaid requestId={formParams.requestId} onUpdate={this.onComplete} />;

      case 'RequestApproval':
        return <RequestApproval requestId={formParams.requestId} onUpdate={this.onComplete} />;

      case 'JourneyConsent':
        return <JourneyConsent journeyId={formParams.journeyId} onUpdate={this.onComplete} />;

      case 'JourneyLabOrderNumber':
        return <JourneyLabOrderNumber journeyId={formParams.journeyId} onUpdate={this.onComplete} />;

      case 'DoctorUpdate':
        return <DoctorUpdate doctorId={formParams.doctorId} onUpdate={this.onComplete} />;

      case 'DoctorCreate':
        return <DoctorCreate onCreate={this.onComplete} onCancel={this.onCancel} />;

      case 'ClinicUpdate':
        return <ClinicUpdate clinicId={formParams.clinicId} onComplete={this.onComplete} />;

      case 'ClinicCreate':
        return <ClinicCreate onComplete={this.onComplete} />;

      case 'CounsellorCreate':
        return <CounsellorCreate onCreate={this.onComplete} onCancel={this.onCancel} />;

      case 'CounsellorUpdate':
        return <CounsellorUpdate counsellorId={formParams.counsellorID} onUpdate={this.onComplete} onCancel={this.onCancel} />;

      case 'RequestHoldStatusUpdate':
        return <RequestOnHoldUpdate requestId={formParams.requestId} onUpdate={this.onComplete} onCancel={this.onCancel} />;

      case 'ReferralCreate':
        return <ReferralCreate journeyId={formParams.journeyId} onComplete={(shouldRedirectToFollowUp, doctorId) => {
          this.onComplete();
          if (shouldRedirectToFollowUp && onRedirectToForm) {
            onRedirectToForm('FollowUpCreate', { doctorId, journeyId: formParams.journeyId });
          }
        }} />;

      case 'ReferralUpdate':
        return <ReferralUpdate referralId={formParams.referralId} onComplete={this.onComplete} />;

      case 'FollowUpCreate':
        return <FollowUpCreate journeyId={formParams.journeyId} doctorId={formParams.doctorId} onComplete={this.onComplete} />;

      case 'RecommendationUpdate':
        return <RecommendationUpdate recommendationId={formParams.recommendationId} answer={formParams.answer} onComplete={this.onComplete} />;

      case 'FamilyHistoryCreate':
        return <FamilyHistoryCreate memberId={formParams.memberId} familyHistoryCategory={formParams.category} onCreate={this.onComplete} onCancel={this.onCancel} />;

      case 'FamilyHistoryUpdate':
        return <FamilyHistoryUpdate familyHistoryGroupItemId={formParams.familyHistoryGroupItemId} onUpdate={this.onComplete} onCancel={this.onCancel} />;

      case 'FlagCreate':
        return <FlagCreate journeyId={formParams.journeyId} onComplete={this.onComplete} />

      case 'ReassignFamilyHistorySubmission':
        return <FamilyHistoryReassign submissionId={formParams.submissionId} onUpdate={this.onComplete} />

      case 'KitsAssignNumber':
        return <KitsAssignNumber markAsArrived={false} kitIds={formParams.kitIds} onComplete={this.onComplete} />;

      case 'KitsUpdateArrived':
        return <KitsAssignNumber markAsArrived kitIds={formParams.kitIds} onComplete={this.onComplete} />;

      case 'KitsUpdate':
        return <KitsUpdate kitIds={formParams.kitIds} onComplete={this.onComplete} />;

      case 'ReassignConsent':
        return <ConsentReassign consentId={formParams.consentId} onUpdate={this.onComplete} />;

      case 'WithdrawConsent':
        return <WithdrawConsent consentId={formParams.consentId} journeyId={formParams.journeyId} onUpdate={this.onComplete} />;

      case 'AcuityPreTestMatch':
        return <RequestSyncWithAcuity acuityAppointmentId={formParams.acuityAppointmentId} />;

      case 'CancelTest':
        return <CancelTest onCancel={this.onCancel} onComplete={this.onComplete} journeyId={formParams.journeyId} failedSampleFlagId={formParams.failedSampleFlagId} />;

      case 'LeadsClose':
        return <LeadsClose onComplete={this.onComplete} leadIds={formParams.leadIds} />;

      case 'LeadInfoAndClose':
        return <LeadInfoAndClose onComplete={this.onComplete} leadId={formParams.leadId} />;

      default:
        return null;
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <h2 className="text-2xl font-bold pb-15">{this.getTitle()}...</h2>
        {this.renderForm()}
      </ErrorBoundary>
    );
  }
}

export default SwitchForm;
