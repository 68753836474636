import React, { useState } from 'react';
import { Query, QueryListFlagsArgs, FlagType, ListFlagsInput, FlagCode } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import FlagJourneyTable from '../../../components/widgets/FlagJourneyTable';
import GeniePagedQuery from '../../base/GeniePagedQuery';
import { FlagCodeType, FLAG_CODE_LABELS } from '../../../../utilities/flags/flagLabels';
import Select from 'react-select'

export type journeySortBy = 'dateASC' | 'dateDESC' | 'name';
interface OwnProps {
  flagType?: FlagType;
  priority?: ListFlagsInput['priority'];
  assignedTo?: ListFlagsInput['assignedTo'];
  code?: ListFlagsInput['code'];
  sortBy?: journeySortBy;
}

type AllProps = OwnProps;

const LIST_FLAGS_QUERY = gql`
  query ListFlags($input: ListFlagsInput!) {
    listFlags(input: $input) {
      edges {
        cursor
        node {
          id
          flagType
          priority
          code
          assignedTo {
            id
            counsellorDetails {
              id
              name
              nickname
            }
          }
          dateCreated
          journey {
            id
            state
            kits {
              id
              dateShippedToLab 
            }
            member {
              id
              name
            }
            request {
              id
              product
              productVariant
              datePaid
              initiatingMember {
                id
              }
              primaryCounsellor {
                id
                photoURL
                name
              }
              pregnancy {
                dueDate
              }
              journeys {
                id
                state
                member {
                  id
                  name
                  sex
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const Flags = ({ flagType, priority, assignedTo, code, sortBy }: AllProps) => {
  const [filterVal, setFilterVal] = useState<FlagCode | "">(code ?? "");
  const [sortVal, setSortVal] = useState<journeySortBy>(sortBy ?? "dateASC");

  const queryVariables = {
    input: {
      flagType,
      priority,
      assignedTo,
      code: filterVal !== "" ? filterVal : null,
    }
  };

  const flagCodeLabelsKeyVals: (keyof FlagCodeType)[] = Object.keys(FLAG_CODE_LABELS) as (keyof FlagCodeType)[];

  const selectOptions: any[] = [{ value: "", label: "View All" }];

  flagCodeLabelsKeyVals.forEach(item => selectOptions.push({ value: item, label: FLAG_CODE_LABELS[item] }));

  const sortOptions: any[] = [{ value: "name", label: "Name" }, { value: "dateASC", label: "Date (oldest first)" }, { value: "dateDESC", label: "Date latest first" }];

  const onChange = (option: any | null) => {
    setFilterVal(option.value);
  }
  const onSortChange = (option: any | null) => {
    setSortVal(option.value);
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 40,
      minHeight: 40,
      width: 200,
      borderColor: "lightgrey",
      boxShadow: "none",
      borderRadius: 0,
      "&:hover": {
        borderColor: "lightgrey"
      }
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 0,
    }),
    container: (styles: any) => ({
      ...styles,
      zIndex: 60
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      height: 40
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "#56566B",
      fontSize: "0.85rem",
      fontWeight: 600
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      padding: 10,
      background: state.isFocused ? "#F7F8FF" : "white",
      color: "#56566B",
      fontSize: "0.85rem",
      fontWeight: 600
    }),
  }

  const defaultSortVal = sortOptions.find(option => option.value === sortVal);

  return (
    <div>
      <div className='flex justify-between items-start'>
        <div className='w-1/2 my-10 flex justify-start items-center'>
          <label className='mr-10 font-bold text-sm font-lt text-grey-darkest'>Sort by:</label>
          <Select options={sortOptions} defaultValue={defaultSortVal} styles={customStyles} isSearchable={false} onChange={onSortChange} />
        </div>
        <div className='w-1/2 my-10 flex justify-end items-center'>
          <label className='mr-10 font-bold text-sm font-lt text-grey-darkest'>Filter by reason:</label>
          <Select options={selectOptions} defaultValue={selectOptions[0]} styles={customStyles} isSearchable={false} onChange={onChange} />
        </div>
      </div>
      <GeniePagedQuery<Pick<Query, 'listFlags'>, QueryListFlagsArgs>
        query={LIST_FLAGS_QUERY}
        itemsPerPage={100}
        variables={queryVariables}
        pageInfoSelector="listFlags.pageInfo"
        edgeSelector="listFlags.edges"
      >
        {(data, isLoading, reloadData) => (
            <FlagJourneyTable
            flags={data?.listFlags?.edges?.map(edge => edge.node) ?? []}
            isLoading={isLoading}
            onReload={reloadData}
            sortBy={sortVal}
          />
        )}
      </GeniePagedQuery>
    </div>
  );
}

export default Flags;
