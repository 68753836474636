import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Counsellor, Doctor, DoctorClinic, Journey, Kit, Member, Note, Pregnancy, Query, QueryJourneyArgs, Referral, Report, Request } from '../../graphql/genie-api-types';
import { DOCTOR_CLINIC_FRAGMENT } from '../../utilities/doctorClinic';
import LoadingOverlay from '../components/widgets/LoadingOverlay';

interface JourneyShowContextWrapperProps {
  journeyId: Journey['id'];
  children: React.ReactNode;
}

interface JourneyShowJourneyMember extends Pick<Member, 'id' | 'name' | 'email' | 'nickname' | 'dateOfBirth' | 'sex' | 'onlineAccessEnabled' | 'hasFamilyHistoryGroup'> {

}

interface JourneyShowJourneyRequest extends Pick<Request, 'id' | 'gcApprovalDate' | 'datePaid' | 'doctorConnectionType' | 'doctorNotifyOnReport'> {
  notes: Pick<Note, 'date' | 'text'>[];
  pregnancy?: Pick<Pregnancy, 'dueDate' | 'days'>;
  referringDoctorClinic: Pick<DoctorClinic, 'id'> & {
    doctor: Pick<Doctor, 'nameFirst' | 'nameLast'>;
  };
  primaryCounsellor?: Pick<Counsellor, 'name'>;
  report?: Pick<Report, 'id'>;
}

interface JourneyShowRelatedJourney extends Pick<Journey, 'id' | 'state'> {
  member?: Pick<Member, 'id' | 'name'>;
}

export interface JourneyShowJourney extends Pick<Journey, 'id' | 'labOrderNumber' | 'hasRecollection' | 'startDate' | 'hasConsent'> {
  member?: JourneyShowJourneyMember;
  request?: JourneyShowJourneyRequest;
  relatedJourneys?: JourneyShowRelatedJourney[];
  referrals?: Pick<Referral, 'id' | 'dateCreated' | 'note'> & {
    doctorClinic: Pick<DoctorClinic, 'id'> & {
      doctor: Pick<Doctor, 'nameFirst' | 'nameLast'>;
    };
  }[];
  kits?: Pick<Kit, 'recollectionRequestedDate' | 'id'>[];
}

interface JourneyShowContextValue {
  isLoading: boolean;
  errorMessage: string;
  journey?: JourneyShowJourney;
  journeyId: Journey['id'];
}

const JourneyShowContext = React.createContext<JourneyShowContextValue>({ isLoading: false, errorMessage: '', journeyId: null });

const MASTER_QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      hasConsent
      labOrderNumber
      state
      hasRecollection
      history {
        toState
        date
      }
      member {
        id
        nickname
        name
        email
        phoneNumber
        dateOfBirth
        sex
        hasFamilyHistoryGroup
        onlineAccessEnabled
      }
      referrals {
        id
        dateCreated
        note
        doctorClinic {
          id
          doctor {
            nameFirst
            nameLast
          }
        }
      }
      request {
        id
        doctorConnectionType
        doctorNotifyOnReport
        gcApprovalDate
        dateCreated
        datePaid
        referringDoctorClinic {
          ...DoctorClinicFields
        }
        primaryCounsellor {
          id
          name
        }
        pregnancy {
          dueDate
          days
        }
        report {
          id
        }
        notes {
          text
          date
          username
          isImportant
        }
      }
      relatedJourneys {
        id
        state
        hasRecollection
        request {
          id
          pregnancy {
            dueDate
            days
          }
        }
        member {
          id
          name
        }
        history {
          date
        }
      }
      kits {
        id
        dateArrivedAtEugene
        dateShippedToLab
        dateShippedToMember
        memberRegisteredKitOnDate
        recollectionRequestedDate
        registrationNumber
      }
    }
  }
  ${DOCTOR_CLINIC_FRAGMENT}
`;

export const JourneyShowContextWrapper = (props: JourneyShowContextWrapperProps) => {
  // Master query is designed to precache all other graphql queries within
  // nested components.
  const journeyQuery = useQuery<Pick<Query, 'journey'>, QueryJourneyArgs>(MASTER_QUERY, {
    variables: {
      id: props.journeyId,
    },
    fetchPolicy: 'network-only',
  });
  
  const value = {
    isLoading: journeyQuery.loading,
    errorMessage: '',
    journeyId: props.journeyId,
  }

  if (journeyQuery.loading) {
    return <LoadingOverlay />
  }

  return (
    <JourneyShowContext.Provider value={value}>
      {props.children}
    </JourneyShowContext.Provider>
  );
}


export default JourneyShowContext;