import * as React from 'react';
import TabsNav from './TabsNav';

export interface TabOption<T> {
  name: T;
  label: string;
  count?: number;
}

interface TabsProps<T> {
  children(tabName: T): React.ReactNode;
  tabs: TabOption<T>[];
  defaultTab: T;
  className?: string;
}

interface TabsState<T> {
  selectedTab: T;
}

export default class Tabs<T extends string> extends React.PureComponent<TabsProps<T>, TabsState<T>> {
  constructor(props: TabsProps<T>) {
    super(props);

    this.state = {
      selectedTab: props.defaultTab,
    };
  }

  onSelectTab = (tabName: T) => {
    this.setState({
      selectedTab: tabName,
    });
  }
  
  render() {
    const { tabs, children, className } = this.props;
    const { selectedTab } = this.state;
    

    return (
      <div>
        <TabsNav
          tabs={tabs}
          activeTab={selectedTab}
          onSelectTab={this.onSelectTab}
          className={className}
        />
        <div>
          {children(selectedTab)}
        </div>
      </div>
    );
  }
}
