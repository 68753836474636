import * as React from 'react';
import { UpdateJourneyParamsInput, UpdateJourneyParamsResult } from '../../../../graphql/genie-api-types';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import JourneyUpdateForm from '../../../components/journey/JourneyUpdateForm';
import Spinner from '../../../components/widgets/Spinner';

interface JourneyUpdateProps {
  onUpdate?(journey: UpdateJourneyParamsResult['journey']): void;
  onCancel?(): void;
  journeyId: string;
}

const GET_JOURNEY = gql`
  query journey( $id: ID!) {
    journey (id:$id) {
      id
      addressString
      addressAddress1
      addressAddress2
      addressCity
      addressProvince
      addressCountryCode
      addressZip
      partnerCanReceiveResults
    }
  }
`;

const UPDATE_JOURNEY = gql`
  mutation UpdateJourney($input: UpdateJourneyParamsInput!) {
    updateJourneyParams(input: $input) {
      journey {
        id
        addressAddress1
        addressAddress2
        addressCity
        addressCountryCode
        addressProvince
        addressZip
        partnerCanReceiveResults
        member {
          id
          name
        }
        startDate
      }
    }
  }
`;

const JourneyUpdate = ({ onUpdate, onCancel, journeyId }: JourneyUpdateProps) => {

  const { loading, data } = useQuery(GET_JOURNEY, {
    variables: {
      id: journeyId
    }
  });

  const [updateJourney, { error }] = useMutation(UPDATE_JOURNEY, {
    refetchQueries: () => [{
      query: GET_JOURNEY,
      variables: {
        input: {
          id: journeyId,
        },
      },
    }],
  });

  const submitAction = async (values: UpdateJourneyParamsInput) => {
    const fullAddress = `${values.addressAddress1}${values.addressAddress2 ? ', ' + values.addressAddress2 : ""}, ${values.addressCity}, ${values.addressProvince}, ${values.addressCountryCode} ${values.addressZip ?? ''}`;
    const canPartnerStatus = values?.partnerCanReceiveResults ? (values?.partnerCanReceiveResults.toString() === 'true' ? true : false) : null;

    const result = await updateJourney({
      variables: {
        input: {
          journeyId,
          addressString: fullAddress,
          addressAddress1: values?.addressAddress1 ?? null,
          addressAddress2: values?.addressAddress2 ?? null,
          addressCity: values?.addressCity ?? null,
          addressCountryCode: values?.addressCountryCode ?? null,
          addressProvince: values?.addressProvince ?? null,
          addressZip: values?.addressZip ?? null,
          partnerCanReceiveResults: canPartnerStatus,
        },
      },
    });

    if (onUpdate) {
      onUpdate(result.data.updateJourneyParams.journey);
    }
  }

  if (loading) {
    return <Spinner label="Loading address..." />;
  }

  return (
    <JourneyUpdateForm
      isLoading={loading}
      initialValues={data?.journey}
      errorMessage={error?.message}
      onCancel={onCancel}
      onSubmit={submitAction}
    />
  )
};

export default JourneyUpdate;

