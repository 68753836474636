import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { ClientDetailsState } from '../../../store/report/client-details/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import Client from '../../../components/report/client-details/Client';
import { toggleTask } from '../../../store/report/task-list/actions';
import { PATHS } from '../../../store/router/types';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import EditArea from '../../../components/widgets/EditArea';
import { defaultClientValues } from '../../../store/report/client-details/reducer';
import Row from '../../../components/layout/Row';
import Col from '../../../components/layout/Col';
import { navigate } from '../../../store/router/actions';
import { FiRepeat } from '../../../components/widgets/Icon';
import { swapClients } from '../../../store/report/client-details/actions';
import { Product } from '../../../../graphql/genie-api-types';

interface ClientDetailsViewProps extends ClientDetailsState, TaskListComponentProps {
  onEditClientA(): void;
  onEditClientB(): void;
  onSwapClients(): void;
  product: string;
}

const ClientDetailsView = (props: ClientDetailsViewProps) => {
  const showClientB = props.product !== Product.Cancer;

  return (
    <div>
      <TaskListScreenTitle
        title="Client details"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      <Row>
        <Col width={showClientB ? "1/2" : "full"}>
          <EditArea
            onClick={props.onEditClientA}
            isOverridden={!isEqual(props.clientA, defaultClientValues)}
          >
            <Client
              defaultHeaderText="Client A"
              product={props.product}
              {...props.clientA}
            />
          </EditArea>
        </Col>
        { showClientB &&
          <>
            <span className="bg-primary rounded-full flex items-center">
              <button type="button" onClick={props.onSwapClients}>
                <FiRepeat />
              </button>
            </span>
            <Col width="1/2">
              <EditArea
                onClick={props.onEditClientB}
                isOverridden={!isEqual(props.clientB, defaultClientValues)}
              >
                <Client
                  defaultHeaderText="Client B"
                  {...props.clientB}
                />
              </EditArea>
            </Col>
          </>
        }
      </Row>
    </div>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  product: report.request.request?.product,
  ...report.clientDetails,
  isComplete: report.taskList.completedTasks.clientDetails,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onEditClientA: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CLIENT_DETAILS_A)),
  onEditClientB: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CLIENT_DETAILS_B)),
  onToggleComplete: () => dispatch(toggleTask('clientDetails')),
  onSwapClients: () => dispatch(swapClients()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetailsView);
