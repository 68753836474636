import { ClientDetailsState, ClientId } from '../client-details/types';
import { CarrierRatesState } from './types';
import { Ethnicity, CarrierStatistics } from '../../../../graphql/genie-api-types';

const getEthnicityLabel = (ethnicity: Ethnicity) => {
  if (ethnicity.id === 'pan-ethnic') {
    return 'people';
  }

  return `people of ${ethnicity.name} ancestry`;
};

export const getCarrierRateDescForGene = (gene: string, clientDetailsState: ClientDetailsState, carrierRatesState: CarrierRatesState): string => {
  const defaultValue = 'CARRIER_RATE';

  let value = defaultValue;

  const { clientA, clientB } = clientDetailsState;

  const carrierRateEthnicities = carrierRatesState.selectedCarrierRateEthnicitiesByGene[gene] ? carrierRatesState.selectedCarrierRateEthnicitiesByGene[gene] : {};
  const carrierRates: CarrierStatistics[] = clientDetailsState?.carrierRatesByGene?.[gene] ?? [];

  // Note: This operates under the assumption of a single carrier which is not correct for high risk couples so manual editing must happen in those cases.
  const isClientACarrier = clientA.affectedGenes.includes(gene);
  const isClientBCarrier = clientB.affectedGenes.includes(gene);

  if (isClientACarrier && isClientBCarrier) {
    const ethnicityAID = carrierRateEthnicities.A;
    const ethnicityBID = carrierRateEthnicities.B;

    if (ethnicityAID === ethnicityBID) {
      // Same ethnicity: "Like person name& partner name, 1 in X people of Y ancestry carry a variation in this gene."
      const rate = carrierRates.find(carrierRateOption => carrierRateOption.ethnicity.id === ethnicityAID);
      const ratio = rate?.carrierRateDesc ?? defaultValue;
      const ethnicity = rate?.ethnicity ?? {} as Ethnicity;
      value = `Like ${clientA.nickname} & ${clientB.nickname}, ${ratio} ${getEthnicityLabel(ethnicity)} carry a variation in this gene.`;
    } else {
      // Different ethnicity: "Like person name & partner name , 1 in X people of Y  ancestry and 1 in X people of Y ancestry carry a variation in this gene."
      const rateA = carrierRates.find(carrierRateOption => carrierRateOption.ethnicity.id === ethnicityAID);
      const rateB = carrierRates.find(carrierRateOption => carrierRateOption.ethnicity.id === ethnicityBID);
      const ratioA = rateA?.carrierRateDesc ?? defaultValue;
      const ratioB = rateB?.carrierRateDesc ?? defaultValue;
      const ethnicityA = rateA?.ethnicity ?? {} as Ethnicity;
      const ethnicityB = rateB?.ethnicity ?? {} as Ethnicity;
      value = `Like ${clientA.nickname} & ${clientB.nickname}, ${ratioA} ${getEthnicityLabel(ethnicityA)} and ${ratioB} ${getEthnicityLabel(ethnicityB)} carry a variation in this gene.`;
    }
  } else {
    const carrierClientId = isClientACarrier ? 'A' : 'B';
    const clientFields = isClientACarrier ? clientA : clientB;
    const rate = carrierRates.find(carrierRateOption => carrierRateOption.ethnicity.id === carrierRateEthnicities[carrierClientId]);
    const ratio = rate?.carrierRateDesc ?? defaultValue;
    const ethnicity = rate?.ethnicity ?? {} as Ethnicity;
    value = `Like ${clientFields.nickname}, ${ratio} ${getEthnicityLabel(ethnicity)} carry a variation in this gene.`;
  }

  return value;
};

export const getResidualRiskDescForGene = (gene: string, clientDetailsState: ClientDetailsState, carrierRatesState: CarrierRatesState): string => {
  let value = 'RESIDUAL_RISK';

  const carrierRateEthnicities = carrierRatesState.selectedCarrierRateEthnicitiesByGene[gene] ? carrierRatesState.selectedCarrierRateEthnicitiesByGene[gene] : {};
  const carrierRates: CarrierStatistics[] = clientDetailsState?.carrierRatesByGene?.[gene] ?? [];

  const nonCarrierClientId: ClientId = clientDetailsState.clientA.affectedGenes.includes(gene) ? 'B' : 'A';

  if (carrierRateEthnicities[nonCarrierClientId]) {
    const rate = carrierRates.find(carrierRateOption => carrierRateOption.ethnicity.id === carrierRateEthnicities[nonCarrierClientId]);
    value = rate?.residualRiskDesc ?? value;
  }

  return value;
};
