import * as React from 'react';
import { connect } from 'react-redux';
import { withApollo, WithApolloClient } from 'react-apollo';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../../store';
import { toggleTask } from '../../../store/report/task-list/actions';
import {  downloadReportAsZip } from '../../../../utilities/report/html/generator';
import { ReportState } from '../../../store/report';
import './export-screen.scss';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { completeExport, downloadPDF } from '../../../store/report/exportReport/actions';
import { getPageCount } from '../../../store/report/selectors';
import Button from '../../../components/widgets/Button';
import ButtonGroup from '../../../components/widgets/ButtonGroup';
import ReportIframe from '../../../components/report/export/ReportIframe';

interface ExportProps extends TaskListComponentProps {
  reportState: ReportState;
  onExport(): void;
  onDownloadPDF(): void;
  hasExported: boolean;
  totalPages: number;
}

interface ExportState {
  page: number;
}

type AllProps = WithApolloClient<ExportProps>;

class Export extends React.PureComponent<AllProps, ExportState> {
  state: Readonly<ExportState> = {
    page: 0,
  };

  getPagerItems() {
    const pagerItems = [];
    const { totalPages } = this.props;
    const { page } = this.state;

    pagerItems.push((
      <Button
        key="prev"
        onClick={() => this.setPage(page - 1)}
        disabled={page === 0}
      >
        Previous
      </Button>
    ));

    for (let i = 0; i < totalPages; i += 1) {
      pagerItems.push((
        <Button
          key={i}
          isActive={i === page}
          onClick={() => this.setPage(i)}
        >
          {i + 1}
        </Button>
      ));
    }

    pagerItems.push((
      <Button
        key="next"
        onClick={() => this.setPage(page + 1)}
        disabled={page === totalPages - 1}
      >
        Next
      </Button>
    ));

    return (
      <ButtonGroup className="my-20">
        {pagerItems}
      </ButtonGroup>
    );
  }

  setPage(page: number) {
    this.setState({ page });
  }

  onClickExportSource = () => {
    const { reportState, onExport, hasExported } = this.props;
    downloadReportAsZip(reportState);

    if (!hasExported) {
      onExport();
    }
  }

  render() {
    const { isComplete, onToggleComplete, onDownloadPDF, reportState } = this.props;

    return (
      <div>
        <TaskListScreenTitle
          title="Export"
          isSelected={isComplete}
          onToggle={onToggleComplete}
        />
        <div className="export-screen__preview">
          <div className="flex items-center mb-20">
            <Button
              variant="primary"
              onClick={onDownloadPDF}
              className="mr-10"
            >
              Download PDF File
            </Button>
            <Button
              variant="link"
              onClick={this.onClickExportSource}
            >
              Download report source
            </Button>
          </div>
          <ReportIframe
            reportState={reportState}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ report }: ApplicationState) => ({
  reportState: report,
  isComplete: report.taskList.completedTasks.export,
  hasExported: report.exportReport.hasExported,
  totalPages: getPageCount(report.clientDetails),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onExport: () => dispatch(completeExport()),
  onDownloadPDF: () => dispatch(downloadPDF()),
  onToggleComplete: () => dispatch(toggleTask('export')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Export));
