import * as React from 'react';
import moment from 'moment';
import Badge from '../../widgets/Badge';
import { FiActivity, FiClock } from '../../widgets/Icon';
import { Counsellor, Journey, JourneyState, Member, Pregnancy, Report, Request } from '../../../../graphql/genie-api-types';
import CounsellorIcon from '../../widgets/CounsellorIcon';
import JourneyRows from './JourneyRows';
import gql from 'graphql-tag';

const getDaysWaitingFormattedDate = (journey: Partial<Journey>) => {
  return journey.lastStateChangeDate ? <div className="text-xs">{moment(journey.lastStateChangeDate).fromNow(true)}</div> : '';
};

const getActivityLabel = (journey: Partial<Journey>) => {
  if (journey.request.report) {
    return <Badge className="text-xs" color="green-lightest">Report started</Badge>;
  }
  if (journey.state === JourneyState.WaitingForPartnerResults) {
    return <Badge className="text-xs">Awaiting partner&apos;s results</Badge>;
  }

  return <Badge color="orange-light" className="text-xs">Ready to start</Badge>;
};

interface CreateReportsJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember'|'riskStatus'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    primaryCounsellor?: Pick<Counsellor, 'id'|'name'|'photoURL'>;
    report?: Pick<Report, 'id'>;

    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            initiatingMember {
              id
            }
            onHoldDate
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            primaryCounsellor {
              id
              name
              photoURL
            }
            report {
              id
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const CreateReportsJourneys = () => (
  <JourneyRows<CreateReportsJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.WaitingForPartnerResults, JourneyState.ReadyForReport]}
    header={[<span key="gc">GC</span>, <span title="Days since we received samples" key="clock"><FiClock /></span>, <span title="Current Status" key="activity"><FiActivity /></span>]}
    emptyText="No members awaiting reports"
    cellsForJourney={journey => [
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor-icon" />,
      getDaysWaitingFormattedDate(journey),
      getActivityLabel(journey),
    ]}
    actionsForJourney={journey => journey.request.report ? [{
      label: 'View report',
      name: 'viewReport',
      variant: 'success',
      noBatch: true,
    }] : [{
      label: 'Create report',
      name: 'createReport',
      variant: 'success',
      noBatch: true,
    }]
    }
  />
);

export default CreateReportsJourneys;
