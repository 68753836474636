import * as CryptoJS from 'crypto-js';
import { isString } from 'lodash';

const LOCAL_STORAGE_PREFIX = 'genie/';

export const saveToLocalStorage = (name: string, payload: object | string, key: string = '') => {
  let data = payload;

  if (!isString(data)) {
    data = JSON.stringify(payload);
  }

  if (key) {
    data = encrypt(key, data);
  }

  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${name}`, data);

  return true;
};

export const retrieveFromLocalStorage = (name: string, key: string = '', shouldParseJSON = true): string | string[] | {} => {
  let localStorageData = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${name}`);
  if (!localStorageData) {
    return null;
  }

  if (key) {
    localStorageData = decrypt(key, localStorageData);
  }

  if (shouldParseJSON) {
    try {
      localStorageData = JSON.parse(localStorageData);
    } catch (e) {
      localStorageData = null;
    }
  }

  return localStorageData;
};

export const deleteFromLocalStorage = (name: string) => {
  localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${name}`);
};

const decrypt = (encryptionKey: string, encryptedString: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedString, encryptionKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const encrypt = (encryptionKey: string, payload: string) => {
  const encrypted = CryptoJS.AES.encrypt(payload, encryptionKey);

  return encrypted.toString();
};
