import * as React from 'react';
import Spinner from './Spinner';
import GeneticJokes from './GeneticJokes';

interface ProgressBarProps {
  progress: number;
  message?: string;
  showJoke?: boolean;
  className?: string;
}

const progressBarStyle: React.CSSProperties ={
  height: '5px',
};

const ProgressBar = (props: ProgressBarProps) => {
  const progressBarInnerStyle: React.CSSProperties = {
    width: `${props.progress * 100}%`,
  }
  return (
    <div className={props.className}>
      <div style={progressBarStyle} className="bg-grey rounded mb-20">
        <div style={progressBarInnerStyle} className="bg-purple h-full rounded transition-all" />
      </div>
      {props.message && (
        <div className="mb-20 text-grey text-purple font-bold flex items-center justify-center"><Spinner className="mr-5" /> <span>{props.message}</span></div>
      )}
      {props.showJoke && <GeneticJokes />}
    </div>
  );
};

export default ProgressBar;
