import { Reducer } from 'redux';
import { StorageReducerState, StorageActionTypes } from './types';

const initialState: StorageReducerState = {
  encryptionKey: '',
};

const reducer: Reducer<StorageReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case StorageActionTypes.CHANGE_STORAGE_KEY:
      return {
        ...state,
        encryptionKey: action.payload,
      };

    default:
      return state;
  }
};

export { reducer as storageReducer };
