import * as React from 'react';
import { FLAG_CODE_LABELS, FLAG_CODE_ICONS } from '../../../utilities/flags/flagLabels';
import { FlagCode, FlagType } from '../../../graphql/genie-api-types';

interface FlagCodeLabelProps {
  flagCode: FlagCode;
  flagType: FlagType;
}

const typeColors: { [key in FlagType]: string } = {
  APRIL_ERROR: 'orange-light',
  AWAITING_INPUT: 'green-lightest',
  FOLLOW_UP: 'green-lightest',
  NEEDS_ATTENTION: 'orange-light',
  OUT_OF_OUR_CONTROL: 'grey-light',
}

const FlagCodeLabel = ({ flagCode, flagType }: FlagCodeLabelProps) => {
  const IconComponent = FLAG_CODE_ICONS[flagCode];
  const colorClass = typeColors[flagType];
  return (
    <div className={`inline-block text-xs bg-${colorClass} py-3 px-5 rounded`}>
      <IconComponent style={{ verticalAlign: 'top' }} /> <span className="ml-1">{FLAG_CODE_LABELS[flagCode] ?? flagCode}</span>
    </div>
  );
};

export default React.memo(FlagCodeLabel);
