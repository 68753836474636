import { FormikValues } from 'formik';
import * as React from 'react';
import { Lead, LeadStatus } from '../../../graphql/genie-api-types';
import { getProductLabel } from '../../../utilities/journeys/products';
import { LEAD_STATUS_LABELS } from '../../../utilities/leads/labels';
import Badge from '../widgets/Badge';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormInput from '../widgets/form/FormInput';

export interface LeadUpdateData {
  status: LeadStatus;
  reason: string;
  reasonOther?: string;
  doNotContact: boolean;
}

type FormValues = { leads: { [key: string]: LeadUpdateData } };
type LeadDetails = Pick<Lead, 'id' | 'name' | 'product'>;

interface LeadsUpdateFormProps extends Pick<BaseFormProps, 'onCancel' | 'isLoading' | 'errorMessage'> {
  leadIds: Lead['id'][];
  showLeadDetails?: boolean;
  leadDetails?: LeadDetails[];
  onSubmit(leadUpdates: FormValues): void;
}

const closedLostReasons = [
  '',
  'Too expensive',
  'Wouldn\'t change anything',
  'Not sure if test is right',
  'Too late in pregnancy',
  'Don\'t think the test is useful',
  'Already have a healthy child',
  'Don’t have any relevant family history',
  'Too many conditions tested',
  'Didn\'t answer calls / messages',
  'Other',
];

interface LeadDetailsProps {
  lead: LeadDetails;
}

const LeadDetails = (props: LeadDetailsProps) => {
  const { lead } = props;
  return (
    <div className="pb-10">
      <span className="font-bold">{lead.name}</span>
      <Badge className="ml-10">{getProductLabel(lead.product)}</Badge>
    </div>
  );
};

const LeadsUpdateForm = (props: LeadsUpdateFormProps) => {
  const { leadIds, onCancel, errorMessage, isLoading, onSubmit, leadDetails, showLeadDetails } = props;

  const onFormSubmit = React.useCallback((values: FormikValues) => onSubmit(values as FormValues), [onSubmit]);

  const initialValues: FormValues = React.useMemo(() => {
    const values: FormValues = {
      leads: {},
    };

    leadIds.forEach(leadId => {
      values.leads[leadId] = {
        status: LeadStatus.ClosedSaleExpected,
        reason: '',
        reasonOther: '',
        doNotContact: false,
      };
    });

    return values;
  }, [leadIds]);

  return (
    <BaseForm
      initialValues={initialValues}
      submitButtonLabel="Close lead(s)"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={onFormSubmit}
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
        <div>
          {leadIds.map(leadId => (
            <div className="py-10 mb-10 border-b" key={leadId}>
              {showLeadDetails && (
                <LeadDetails
                  lead={leadDetails.find(lead => lead.id === leadId)}
                />
              )}
              <div className="w-full">
                <FormInput
                  name={`leads[${leadId}][status]`}
                  type="text"
                  as="select"
                  label=""
                  setFieldValue={setFieldValue}
                  value={values?.leads?.[leadId]?.status ?? ''}
                  onChange={handleChange}
                  clearBottomMargin={values?.leads?.[leadId]?.status === LeadStatus.ClosedSaleExpected}
                  options={[
                    {
                      label: LEAD_STATUS_LABELS[LeadStatus.ClosedSaleExpected],
                      value: LeadStatus.ClosedSaleExpected,
                    },
                    {
                      label: LEAD_STATUS_LABELS[LeadStatus.ClosedLost],
                      value: LeadStatus.ClosedLost,
                    },
                  ]}
                />
                {values?.leads?.[leadId]?.status === LeadStatus.ClosedLost && (
                  <>
                    <FormInput
                      name={`leads[${leadId}][reason]`}
                      type="text"
                      as="select"
                      label="Reason"
                      options={closedLostReasons.map(reason => ({
                        label: reason,
                        value: reason,
                      }))}
                      setFieldValue={setFieldValue}
                      value={values?.leads?.[leadId]?.reason ?? ''}
                      onChange={handleChange}
                    />
                    {values?.leads?.[leadId]?.reason === 'Other' && (
                      <FormInput
                        name={`leads[${leadId}][reasonOther]`}
                        type="text"
                        as="textarea"
                        label=""
                        placeholder="Other reason..."
                        setFieldValue={setFieldValue}
                        value={values?.leads?.[leadId]?.reasonOther ?? ''}
                        onChange={handleChange}
                      />
                    )}
                    <FormInput
                      name={`leads[${leadId}][doNotContact]`}
                      type="checkbox"
                      label="Do not contact in future"
                      setFieldValue={setFieldValue}
                      value={values?.leads?.[leadId]?.doNotContact ?? ''}
                      onChange={handleChange}
                      clearBottomMargin={true}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )
      }
    </BaseForm>
  );
};

export default LeadsUpdateForm;