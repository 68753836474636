import * as React from 'react';
import FormInput from '../widgets/form/FormInput';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';

interface RequestOnHoldFormProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
}
const RequestOnHoldForm = ({ initialValues, onSubmit, onCancel, isLoading, errorMessage }: RequestOnHoldFormProps) => (
  <BaseForm
    initialValues={initialValues}
    submitButtonLabel="Save"
    hasCancel={!!onCancel}
    onCancel={onCancel}
    errorMessage={errorMessage}
    isLoading={isLoading}
    onSubmit={onSubmit}
  >
    {({
      handleChange,
      values,
      setFieldValue,
    }) => (
        <div>
          <FormInput
            name="onHold"
            type="checkbox"
            label="On hold"
            setFieldValue={setFieldValue}
            value={values['onHold']}
            onChange={handleChange}
          />
        </div>
      )}
  </BaseForm>
);

export default RequestOnHoldForm;
