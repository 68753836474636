import * as React from 'react';
import { upperFirst, lowerCase } from 'lodash';
import { FiEdit2 } from '../../widgets/Icon';
import { useDispatch } from 'react-redux';
import PregnancyIcon from '../../widgets/PregnancyIcon';
import { Link } from 'react-router-dom';
import RouteButton from '../../widgets/RouteButton';
import { navigate } from '../../../store/router/actions';
import { JourneyState } from '../../../../graphql/genie-api-types';
import { PATHS } from '../../../store/router/types';
import JourneyInfoKits from './JourneyInfoKits';
import JourneyInfoLabOrderNumber from './JourneyInfoLabOrderNumber';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';
import PrivateContent from '../../widgets/PrivateContent';
import { formatPartnerConsent } from '../../../../utilities/helpers';
import { formatDoctorClinicName } from '../../../../utilities/doctorClinic';

const JourneyInfoTimeline = () => {
  const [shouldShowStageEdit, setShouldShowStageEdit] = React.useState(false);

  const onStageHover = () => {
    setShouldShowStageEdit(true);
  }

  const onStageLeave = () => {
    setShouldShowStageEdit(false);
  }

  const getPathForJourneyState = (journeyState: JourneyState) => {
    switch (journeyState) {
      case JourneyState.RequireDetails:
      case JourneyState.RequirePretestBooking:
      case JourneyState.WaitingForPretestConsult:
      case JourneyState.WaitingForPretestConsult:
        return PATHS.CLINICAL_PRE;

      case JourneyState.WaitingForLabResults:
        return PATHS.CLINICAL_DURING;

      default:
        return PATHS.CLINICAL_POST;
    }
  }

  const { journey, onSelectJourney } = React.useContext(JourneyInfoContext);
  const dispatch = useDispatch();

  const onAddConsent = () => dispatch(navigate.toForm('JourneyConsent', { journeyId: journey.id }));
  const onAddPartnerShareConsent = () => dispatch(navigate.toForm('JourneyUpdate', { journeyId: journey.id }));
  const onGotoFamilyHistory = () => dispatch(navigate.toMemberFamilyHistory(journey.member?.id));
  if (!journey) {
    return null;
  }

  const { request } = journey;
  const partnerJourneys = request?.journeys?.filter(requestJourney => requestJourney.id !== journey.id);

  const doctorClinic = request?.referringDoctorClinic;
  const counsellor = request?.primaryCounsellor;

  return (
    <div className="text-grey-dark align-middle text-sm font-md san-serif px-20">
      {partnerJourneys.map(partnerJourney => (
        <p className="text-grey-darkest font-nm align-middle text-sm mt-15" key={partnerJourney.id}>
          Partner: <button className="bg-purple-100 ml-5 text-black font-md px-7 py-3 rounded" onClick={() => onSelectJourney(partnerJourney.id)}><PrivateContent>{partnerJourney.member?.name ?? 'No member'}</PrivateContent></button>
          {request?.pregnancy?.dueDate ? <PregnancyIcon pregnancy={request.pregnancy} /> : null}
        </p>
      ))}
      {doctorClinic ? (
        <div className="mt-10 flex flex-row flex-wrap items-baseline">
          <span className="flex mr-6">
            Nominated Type: <span className='font-bold ml-3'>{journey?.request?.doctorConnectionType === 'REFERRED_BY_DOCTOR' ? "Referring doctor" : "Non-referring HCP"}</span>
          </span>
          <span className="flex mr-6">
            Share report: <span className='font-bold ml-3'>{request.doctorNotifyOnReport ? "Yes" : "No"}</span>
          </span>
          <span className="flex mr-6">
            Details: <span className='font-bold'>{formatDoctorClinicName(journey?.request?.referringDoctorClinic)}
            </span>
          </span>
        </div>
      ) : 'No Doctor or Non-referring HCP provided'}
      {counsellor ? (
        <p className="text-grey-darkest font-nm align-middle text-sm mt-15">Primary counsellor:
          <span className="text-black ml-10 font-md">{counsellor.name}</span>
        </p>
      ) : null}
      <div className="text-grey-darkest font-nm align-middle text-sm mt-15" onMouseEnter={onStageHover} onMouseLeave={onStageLeave}>
        <div>Stage: {' '}
          <Link to={getPathForJourneyState(journey.state)} className={`${journey.state === JourneyState.Trash ? 'text-white p-5 rounded bg-red' : 'border-b border-purple-light text-black'} pt-5 font-md inline-block`}>
            {upperFirst(lowerCase(journey.state))}
          </Link>
          <RouteButton
            query={{ form: 'JourneysMove', formParams: JSON.stringify({ journeyIds: [journey.id] }) }}
            isUnstyled
            className="pl-10 relative"
            style={{ top: '2px' }}
          >
            {shouldShowStageEdit ? <FiEdit2 /> : ''}
          </RouteButton>
        </div>
      </div>
      <div className="text-grey-darkest font-nm align-middle text-sm mt-15" onMouseEnter={onStageHover} onMouseLeave={onStageLeave}>
        <div>Consent: {' '}         
          {["DONOR", "COUPLE"].includes(request?.productVariant) &&
            <>
            <button onClick={onAddPartnerShareConsent} className={`focus:outline-none ${formatPartnerConsent(journey.partnerCanReceiveResults, 'text-green', 'text-red', 'text-orange')} `}>
              {formatPartnerConsent(journey.partnerCanReceiveResults, 'Can share result', 'Can\'t share result')}
            </button>
            <p className='inline-block mx-4'>/</p>
            </>
          }
           <button onClick={onAddConsent} className={`focus:outline-none ${journey.hasConsent ? 'text-green' : 'text-orange'}`}>
            {journey.hasConsent ? 'Consented' : 'No Consent'}
          </button>
        </div>

      </div>
      {journey.member && (
        <div className="text-grey-darkest font-nm align-middle text-sm mt-15" onMouseEnter={onStageHover} onMouseLeave={onStageLeave}>
          <div>Family history: {' '}
            <button onClick={onGotoFamilyHistory} className={`focus:outline-none ${journey.hasRelevantFamilyHistoryGroupSubmission ? 'text-green' : 'text-orange'}`}>
              {journey.hasRelevantFamilyHistoryGroupSubmission ? 'Family History' : 'No Family History'}
            </button>
          </div>
        </div>
      )}
      <JourneyInfoKits />
      <JourneyInfoLabOrderNumber />
    </div>
  );
}

export default JourneyInfoTimeline;
