import * as React from 'react';
import GenieQuery from '../../base/GenieQuery';
import gql from 'graphql-tag';
import { Request, QueryRequestArgs, Query, Note } from '../../../../graphql/genie-api-types';

export interface RequestNotesProps {
  requestId: Request['id'];
  children(notes: Note[], isLoading: boolean): React.ReactElement<any>;
}

const RequestNotes = (props: RequestNotesProps) => (
  <GenieQuery<Pick<Query, 'request'>, QueryRequestArgs>
    query={gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          notes {
            text
            date
            username
            isImportant
          }
        }
      }
    `}
    notifyOnNetworkStatusChange
    errorPolicy="all"
    variables={{
      id: props.requestId,
    }}
  >
    {result => props.children(result?.data?.request?.notes ?? [], result.loading)}
  </GenieQuery>
);

export default RequestNotes;
