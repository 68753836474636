import * as React from 'react';

interface DripIconProps {
  className?: string;
}

const DripIcon = ({ className }: DripIconProps) => (
  <svg
    fill="#AAAAB9"
    className={`w-5 h-5 ${className}`}
    viewBox="0 0 30 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" fillRule="nonzero">
          <path d="M4.5,24.1666667 L4.5,24.6666667 C4.5,31.3333333 10,35.3333333 15,35.3333333 C19.9180328,35.3333333 25.3198065,31.4634059 25.4955921,24.9923797 L25.5,24.6666667 L25.5,24.1666667 L30,24.1666667 L30,24.6666667 C30,33.8333333 22.5,39.8333333 15,39.8333333 C7.59493671,39.8333333 0.189873418,33.9842707 0.0035899797,25.01324 L0,24.6666667 L0,24.1666667 L4.5,24.1666667 Z M6,14.1666667 C7.65685425,14.1666667 9,15.5098124 9,17.1666667 C9,18.8235209 7.65685425,20.1666667 6,20.1666667 C4.34314575,20.1666667 3,18.8235209 3,17.1666667 C3,15.5098124 4.34314575,14.1666667 6,14.1666667 Z M15,14.1666667 C16.6568542,14.1666667 18,15.5098124 18,17.1666667 C18,18.8235209 16.6568542,20.1666667 15,20.1666667 C13.3431458,20.1666667 12,18.8235209 12,17.1666667 C12,15.5098124 13.3431458,14.1666667 15,14.1666667 Z M24,14.1666667 C25.6568542,14.1666667 27,15.5098124 27,17.1666667 C27,18.8235209 25.6568542,20.1666667 24,20.1666667 C22.3431458,20.1666667 21,18.8235209 21,17.1666667 C21,15.5098124 22.3431458,14.1666667 24,14.1666667 Z M15,0 C18.1111111,2.8 20.7866667,6.03555556 23.297679,9.30014815 L23.8333333,10 L18.1666667,10 L15,5.66666667 L11.8333333,10 L6.16666667,10 C8.83333333,6.5 11.6666667,3 15,0 Z"></path>
      </g>
  </svg>
);

export default DripIcon;
