import * as React from 'react';
import { generateReportHtml } from '../../../../utilities/report/html/generator';
import { ReportState } from '../../../store/report';

interface ReportIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  reportState: ReportState;
}

const ReportIframe = (props: ReportIframeProps) => {
  const { reportState, ...restOfProps } = props;
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [currentReportHtml, setCurrentReportHtml] = React.useState('');
  
  React.useEffect(() => {
    const iframe = iframeRef;
    const document = iframe.current.contentWindow.document;
    const html = generateReportHtml(reportState);
    if (html !== currentReportHtml) {
      document.open();
      document.write(html);
      document.close();
      setCurrentReportHtml(html);
    }
  }, [reportState]);

  return (
    <iframe ref={iframeRef} scrolling="yes" {...restOfProps} />
  );
};

export default ReportIframe;
