import * as React from 'react';
import { Route as ReactRouterRoute, RouteProps as ReactRouterRouteProps } from 'react-router';
import ErrorBoundary from './ErrorBoundary';

const Route = (props: ReactRouterRouteProps) => (
  <ErrorBoundary>
    <ReactRouterRoute {...props} />
  </ErrorBoundary>
);

export default Route;
