import React from 'react';
import { TemplateSection, ReportTemplate } from '../../../../utilities/report/templates';
import { ReportVariables } from '../../../../utilities/report/templates/extractReportTemplateVariables';
import { renderSectionHtml } from '../../../../utilities/report/html/htmlTemplateGenerator';
import { baseTemplates } from '../../../../utilities/report/html';

interface CustomisationViewSectionProps {
  defaultValues: { [key: string]: any };
  section: TemplateSection;
  templateVariables: ReportVariables;
  isSectionHidden: boolean;
  template: ReportTemplate;
}

interface CustomisationViewSectionState {
  renderedSectionHtml: string;
}

export default class CustomisationViewSection extends React.PureComponent<CustomisationViewSectionProps, CustomisationViewSectionState> {
  private iframeRef = React.createRef<HTMLIFrameElement>();

  state: Readonly<CustomisationViewSectionState> = {
    renderedSectionHtml: '',
  };

  componentDidMount() {
    this.onRefreshIframe();
  }

  componentDidUpdate() {
    this.onRefreshIframe();
  }

  onRefreshIframe() {
    const { templateVariables, section, template } = this.props;
    const { renderedSectionHtml } = this.state;
    const sectionHtml = renderSectionHtml(section, templateVariables, template);
    
    const { Top, Bottom } = baseTemplates[template.medium];

    if (renderedSectionHtml === sectionHtml) {
      return;
    }

    const iframe = this.iframeRef.current;
    const document = iframe.contentDocument;

    document.open();
    document.write(Top());

    document.write(sectionHtml);
    document.write(Bottom());

    document.close();

    iframe.onload = () => {
      const firstElement = iframe.contentWindow.document.body.children[0] as HTMLDivElement;
      iframe.style.height = `${firstElement.offsetHeight + firstElement.offsetTop + 20}px`;
    };

    this.setState({
      renderedSectionHtml: sectionHtml,
    });
  }

  render() {
    const { isSectionHidden } = this.props;

    const classes = ['w-full'];
    if (isSectionHidden) {
      classes.push('opacity-25');
    }

    return (
      <iframe ref={this.iframeRef} style={{ pointerEvents: 'none' }} className={classes.join(' ')} />
    );
  }
}
