import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Journey, MutationCreateReferralArgs } from '../../../../graphql/genie-api-types';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReferralForm, { ReferralFormValues } from '../../../components/referral/ReferralForm';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface ReferralCreateProps {
  onComplete(shouldRedirectToFollowUp?: boolean, doctorId?: string): void;
  journeyId: Journey['id'];
}

class ReferralCreate extends React.PureComponent<ReferralCreateProps> {
  onSaveReferral = async ({ doctorClinic, createFollowUp, ...restOfFormValues }: ReferralFormValues, mutate: MutationFunction<MutationCreateReferralArgs>) => {
    const { onComplete, journeyId } = this.props;

    await mutate({
      variables: {
        input: {
          journeyId,
          doctorClinicId: doctorClinic.id,
          ...restOfFormValues,
        },
      },
    });

    showToast('success', 'Created request', 'Successfully created referral');

    if (createFollowUp) {
      onComplete(true, doctorClinic.id);
    }
    else {
      onComplete();
    }

    ReduxQueryListener.triggerEvents('All');
  }

  render() {
    return (
      <GenieMutation
        mutation={gql`
          mutation createReferral($input: CreateReferralInput!) {
            createReferral(input: $input) {
              referral {
                id,
              }
            }
          }
        `}
      >
        {(mutate, result) => (
          <ReferralForm
            initialValues={{
              doctorClinic: null,
              note: '',
            }}
            onSubmit={(values: ReferralFormValues) => this.onSaveReferral(values, mutate)}
            isLoading={result.loading}
            errorMessage={result?.error?.message}
            hasFollowUpOption={true}
          />
        )}
      </GenieMutation>
    );
  }
}

export default ReferralCreate;
