import moment from 'moment';
import * as React from 'react';
import { ResearchExtract } from '../../../graphql/genie-api-types';
import Badge from '../widgets/Badge';

interface ResearchStatusBadgeProps {
  researchExtract?: Pick<ResearchExtract, 'dateSubmitted' | 'id'>;
}

const ResearchStatusBadge = (props: ResearchStatusBadgeProps) => {
  if (props?.researchExtract?.dateSubmitted) {
    return <Badge color="green-light">Submitted {moment(props?.researchExtract?.dateSubmitted).fromNow()}</Badge>;
  }

  if (props?.researchExtract?.id) {
    return <Badge color="orange-light">In progress</Badge>;
  }

  return <Badge color="pink-light">Not started</Badge>;
};

export default ResearchStatusBadge;
