import * as React from 'react';
import { FiRepeat } from './Icon';

const RecollectionIcon = () => (
  <span className="ml-2" title="Lab test re-sent">
    <FiRepeat className="text-grey-dark" />
  </span>
);

export default RecollectionIcon;
