import * as React from 'react';
import RequestNotes from '../../../graphql/containers/requests/RequestNotes';
import CreateRequestNote from '../../../graphql/containers/requests/CreateRequestNote';
import NoteForm from '../../notes/NoteForm';
import Spinner from '../../widgets/Spinner';
import Note from '../../widgets/Note';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';

const JourneyInfoNotes = () => {
  const { journey } = React.useContext(JourneyInfoContext);
  if (!journey) {
    return null;
  }

  return (
    <RequestNotes
      requestId={journey.request.id}
    >
      {(notes, isLoading) => (
        <div className="px-20 mt-30">
          {isLoading ? <Spinner label="Loading notes..." className="mb-20" /> : null}
          <CreateRequestNote
            requestId={journey.request.id}
          >
            {(mutation, isLoading) => (
              <div>
                <NoteForm
                  isLoading={isLoading}
                  onSubmit={({ text, isImportant }) => mutation(text, isImportant)}
                />
              </div>
            )}
          </CreateRequestNote>
          {notes ? (
            <div className="pb-10">
              {notes.map(note => <Note note={note} key={note.date} />)}
            </div>
          ) : null}
        </div>
      )}
    </RequestNotes>
  );
}

export default JourneyInfoNotes;
