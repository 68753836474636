import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import CustomisationView from './CustomisationView';
import Route from '../../../base/Route';
import CustomisationEdit from './CustomisationEdit';

const Customisation = () => (
  <div>
    <Route path={PATHS.REPORTS_CUSTOMISATION} component={CustomisationView} />
    <Route path={PATHS.REPORTS_CUSTOMISATION_EDIT} component={CustomisationEdit} />
  </div>
);

export default Customisation;
