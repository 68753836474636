import * as React from 'react';
import ListRequestsPagedQuery from '../../../graphql/containers/ListRequestsPagedQuery';
import { QueryListRequestsArgs, Query, Journey } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import Spinner from '../../widgets/Spinner';
import convertRequestResultsToJourneyTableData from '../../../graphql/processors/convertRequestResultsToJourneyTableData';
import { FiClock } from '../../widgets/Icon';
import { JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';
import moment from 'moment';
import JourneyTable from '../../widgets/JourneyTable';

interface DoctorShowJourneys {
  referringDoctorId: string;
}

const DoctorShowJourneys = (props: DoctorShowJourneys) => {
  return (
    <ListRequestsPagedQuery<Pick<Query, 'listRequests'>, QueryListRequestsArgs>
      query={gql`
        query ListRequests($input: ListRequestsInput!) {
          listRequests(input: $input) {
            edges {
              cursor
              node {
                id
                product
                datePaid
                report {
                  id
                }
                pregnancy {
                  dueDate
                  days
                }
                primaryCounsellor {
                  id
                  name
                  photoURL
                }
                journeys {
                  id
                  state
                  member {
                    id
                    name
                    sex
                  }
                  history {
                    date
                    fromState
                    toState
                  }
                  request {
                    id
                    datePaid
                  }
                }
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
            }
          }
        }
      `}
      input={{
        referringDoctorId: props.referringDoctorId,
      }}
    >
      {(requests, isLoading) => {
        if (isLoading) {
          return <Spinner label="Loading doctor journeys" />;
        }
        const journeyTableData = convertRequestResultsToJourneyTableData(requests);

        return (
          <JourneyTable
            {...journeyTableData}
            alwaysShowActions
            isLoading={false}
            header={['State', 'Paid', <FiClock key="clock" />]}
            emptyText="No journeys found"
            cellsForJourney={(journey: Journey) => [
              JOURNEY_STATE_LABELS[journey.state],
              journey.request.datePaid ? moment(journey.request.datePaid).format('YYYY-MM-DD') : 'not-paid',
              moment(journey.history[0].date).fromNow(true),
            ]}
          />
        );
      }}
    </ListRequestsPagedQuery>
  );
}

export default DoctorShowJourneys;
