import * as React from 'react';
import { Member } from '../../../graphql/genie-api-types';
import moment from 'moment';
import PrivateContent from '../widgets/PrivateContent';

interface MemberDetailsFieldProps {
  label: string;
  value: string;
}

const MemberDetailsField = (props: MemberDetailsFieldProps) => {
  const { value, label } = props;

  return (
    <div className="flex p-10 w-full">
      <div className="font-bold pr-10 w-1/3">{label}</div>
      <div className="w-2/3">
        <PrivateContent>
          {value}
        </PrivateContent>
      </div>
    </div>
  );
};

interface MemberDetailsProps {
  member: Pick<Member, 'id' | 'name' | 'nickname' | 'dateOfBirth' | 'sex' | 'email' | 'phoneNumber' | 'dateDetailsConfirmedByMember'>;
}

const MemberDetails = (props: MemberDetailsProps) => {
  const { member } = props;

  return (
    <div>
      <MemberDetailsField
        label="Name"
        value={member.name}
      />
      <MemberDetailsField
        label="Preferred name"
        value={member.nickname}
      />
      <MemberDetailsField
        label="Sex assigned at birth"
        value={member.sex}
      />
      <MemberDetailsField
        label="Date of birth"
        value={member.dateOfBirth}
      />
      <MemberDetailsField
        label="Email"
        value={member.email}
      />
      <MemberDetailsField
        label="Phone"
        value={member.phoneNumber}
      />
      <MemberDetailsField
        label="Confirmed in member flow"
        value={moment(member.dateDetailsConfirmedByMember).fromNow()}
      />
    </div>
  );
};

export default MemberDetails;
