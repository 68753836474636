import * as React from 'react';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import { startCase } from 'lodash';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { changeField } from '../../../store/report/summary/actions';
import { RouteComponentProps, RouteProps } from 'react-router';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';
import { SummaryState, SummaryStateFieldNames } from '../../../store/report/summary/types';
import SummaryForm from '../../../components/report/summary/SummaryForm';
import { getFieldValue } from '../../../store/report/summary/selectors';
import Modal from '../../../components/widgets/Modal';
import { showToast } from '../../../store/toast/actions';
import { ClientFields, ClientDetailsState } from '../../../store/report/client-details/types';
import MemberFamilyHistoryQuery from '../../../graphql/containers/members/MemberFamilyHistoryQuery';
import FamilyHistoryListView from '../../../components/family-history/FamilyHistoryListView';
import CopyText from '../../../components/widgets/CopyText';

interface SummaryEditProps extends RouteComponentProps {
  onSave(fields: SummaryState): void;
  fieldName: SummaryStateFieldNames;
  fieldValue: string;
  onClose(): void;
  clientDetails: ClientDetailsState;
}

interface SummaryEditProps extends RouteComponentProps<{}> {
  onClose(): void;
  onEdit(fieldName: string, value: string): void;
}

interface ClientFamilyHistoryHelperProps {
  clientFields: ClientFields;
}

const ClientFamilyHistoryHelper = (props: ClientFamilyHistoryHelperProps) => {
  const { clientFields } = props;
  return (
    <>
      <h3 className="p-10 font-bold bg-grey-light border-b">
        <CopyText text={clientFields.nickname} />
      </h3>
      <MemberFamilyHistoryQuery
        memberId={clientFields.memberId}
      >
        {(result) => (
          <div className="p-20 overflow-auto relative">
            {result?.familyHistoryGroup && (
              <FamilyHistoryListView familyHistoryGroup={result.familyHistoryGroup} />
          )}
          </div>
        )}
      </MemberFamilyHistoryQuery>
    </>
  );
};

const SummaryEdit = (props: SummaryEditProps) => {
  const { fieldName, clientDetails, fieldValue } = props;
  return (
    <Modal
      show
      onHide={props.onClose}
      title={startCase(fieldName)}
    >
      <SummaryForm
        onSubmit={({ value }) => {
          props.onEdit(fieldName, value);
          props.onClose();
        }}
        fieldName={fieldName}
        initialValues={{
          value: fieldValue,
        }}
      />
      {(clientDetails.clientA.memberId || clientDetails.clientB.memberId) && (
        <div className="mt-20">
          <h2 className="font-bold border-t mt-10 pt-10">FHx quick reference</h2>
          <div className="flex w-full text-sm mt-10">
            <div className="w-1/2 bg-white border-l border">
              <ClientFamilyHistoryHelper
                clientFields={clientDetails.clientA}
              />
            </div>
            {clientDetails.clientB.memberId && (
              <div className="w-1/2 bg-white border-r border-b border-t">
                <ClientFamilyHistoryHelper
                  clientFields={clientDetails.clientB}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-20">
        <h2 className="font-bold border-t mt-10 pt-10">Disease label colors</h2>
        <ul className="mt-10 text-sm">
          <li className="py-5"><a href="#disease-label-low-risk">[Cystic Fibrosis](#disease-label-low-risk)</a></li>
          <li className="py-5"><a href="#disease-label-high-risk">[Cystic Fibrosis](#disease-label-high-risk)</a></li>
          <li className="py-5"><a href="#disease-label-increased-risk">[Cystic Fibrosis](#disease-label-increased-risk)</a></li>
          <li className="py-5"><a href="#disease-label-result-to-note">[Cystic Fibrosis](#disease-label-result-to-note)</a></li>
        </ul>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ report }: ApplicationState, ownProps: RouteProps) => {
  const { fieldName } = queryString.parse(ownProps.location.search) as { fieldName: SummaryStateFieldNames };
  return {
    fieldName,
    fieldValue: getFieldValue(fieldName, report.summary),
    clientDetails: report.clientDetails,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onEdit: (fieldName: SummaryStateFieldNames, value: string) => {
    dispatch(changeField(fieldName, value));
    dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_SUMMARY));
    showToast('success', `Updated ${startCase(fieldName)}`, 'Updated');
  },
  onClose: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_SUMMARY)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryEdit);
