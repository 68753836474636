import * as React from 'react';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { CreateDoctorClinicInput, DoctorClinic } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import DoctorSearchAutocomplete from "../../graphql/containers/doctors/DoctorSearchAutocomplete";
import ClinicSearchAutocomplete from "../../graphql/containers/clinics/ClinicSearchAutocomplete";
import { AnyModelResult, ClinicModelResult, DoctorModelResult } from "../../graphql/containers/SwitchForm";
import FormGroup from "../widgets/form/FormGroup";
import MultiModelForm from "../../screens/MultiModelForm";
import { SearchAutocompleteOption } from "../widgets/SearchAutocomplete";

interface DoctorClinicFromProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  initialValues?: Partial<DoctorClinicFormValues>;
  isCreate?: boolean;
  onFormChanged?(values: DoctorClinicFormValues): void;
  onNewDoctorClinic?(): void;
}

interface DoctorClinicFormInput extends FormInputProps {
  name: keyof DoctorClinic;
}

const formItems: Pick<DoctorClinicFormInput, 'label' | 'name' | 'type' | 'as'>[] = [
  {
    label: 'Phone',
    name: 'phone',
    type: 'tel',
  },
  {
    label: 'Email',
    name: 'email',
  },
];

export interface DoctorClinicFormValues extends CreateDoctorClinicInput {
  doctor: SearchAutocompleteOption;
  clinic: SearchAutocompleteOption;
  phone?: string;
  email?: string;
}

const DoctorClinicForm = (props: DoctorClinicFromProps) => {
  const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = props;
  const { clinic, doctor } = initialValues;

  return (
    <MultiModelForm<'clinic' | 'doctor'>
      modelForms={{
        doctor: 'DoctorCreate',
        clinic: 'ClinicCreate'
      }}
      initialModelValues={{
        doctor: doctor as AnyModelResult,
        clinic: clinic as AnyModelResult,
      }}
    >
      {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
        <BaseForm
          initialValues={initialValues}
          submitButtonLabel="Save"
          hasCancel={!!onCancel}
          onCancel={onCancel}
          errorMessage={errorMessage}
          isLoading={isLoading}
          onSubmit={values => {
            // if (!modelValues.initiatingMember) {
            //   showToast('warning', 'Initiating member value missing', 'This field is required');
            //   return;
            // }
            onSubmit({
              ...values,
              ...modelValues,
            });
          }}
        >
          {({
            handleChange,
            values,
            setFieldValue,
          }) => (
            <div>
              <FormGroup
                controlId="doctor"
                label="Doctor"
                labelAction={{
                  label: '+ New',
                  onClick: () => onSwitchToModelForm('doctor'),
                }}
              >
                <DoctorSearchAutocomplete onSelect={doctor => onChangeModelValue('doctor', doctor)} defaultValue={modelValues.doctor as DoctorModelResult} />
              </FormGroup>
              <FormGroup
                controlId="clinic"
                label="Clinic"
                // labelAction={{
                //   label: '+ New',
                //   onClick: () => onSwitchToModelForm('clinic'),
                // }}
              >
                <ClinicSearchAutocomplete onSelect={clinic => onChangeModelValue('clinic', clinic)} defaultValue={modelValues.clinic as ClinicModelResult} />
              </FormGroup>
              {
                formItems.map((formItem, index) =>
                  <React.Fragment key={formItem.name}>
                    <FormInput
                      {...formItem}
                      value={values[formItem.name]}
                      onChange={handleChange}
                      key={formItem.name}
                      autoFocus={index === 0}
                      setFieldValue={setFieldValue}
                    />
                  </React.Fragment>
                )
              }
            </div>
          )}
        </BaseForm>
      )}
    </MultiModelForm>
  );
}

export default DoctorClinicForm;
