import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { Counsellor, Journey, QueryJourneyArgs, Referral, Request } from '../../../../graphql/genie-api-types';
import { DoctorClinicPartial, DOCTOR_CLINIC_FRAGMENT } from '../../../../utilities/doctorClinic';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { navigate } from '../../../store/router/actions';
import ActionLink from '../../widgets/ActionLink';
import { FaArrowRight } from '../../widgets/Icon';
import RouteButton from '../../widgets/RouteButton';

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    referrals?: (Pick<Referral, 'id'|'dateCreated'|'note'> & {
      doctorClinic: DoctorClinicPartial;
    })[];
    request?: Pick<Request, 'id'> & {
      referringDoctorClinic: DoctorClinicPartial;
      primaryCounsellor: Pick<Counsellor, 'id' | 'name' >;
    };
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      referrals {
        id
        dateCreated
        note
        doctorClinic {
          ...DoctorClinicFields
        }
      }
      request {
        id
        referringDoctorClinic {
          ...DoctorClinicFields
        }
        primaryCounsellor {
          id
          name
        }
      }
    }
  }
  ${DOCTOR_CLINIC_FRAGMENT}
`;

const JourneyShowCareTeam = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const dispatch = useDispatch();

  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  
  const journey = journeyQuery?.data?.journey;

  const { name: doctorName, id: doctorId } = journey?.request?.referringDoctorClinic ?? {};
  const counsellorName = journey?.request?.primaryCounsellor?.name ?? '';
  const referrals = journey?.referrals ?? [];

  const careTeamItems = [];

  if (counsellorName) {
    careTeamItems.push(<span className="bg-grey-light px-6 py-4 rounded text-sm leading-tight">{counsellorName}</span>);
  }

  if (doctorName) {
    careTeamItems.push((
      <RouteButton isUnstyled path={`/dir/doctors/${doctorId}`}>
        <span className="bg-grey-light px-6 py-4 rounded text-sm leading-tight">{doctorName}</span>
      </RouteButton>
    ));
  }

  if (referrals.length > 0) {
    referrals.forEach(referral => {
      careTeamItems.push((
        <ActionLink onClick={() => dispatch(navigate.toForm('ReferralUpdate', { referralId: referral.id }))} title={`Date: ${referral.dateCreated}, Note: ${referral.note}`}>
          <span className="bg-grey-light px-6 py-4 rounded text-sm leading-tight"><FaArrowRight size={12} className="mb-3" /> {referral.doctorClinic?.doctor.nameFirst} {referral.doctorClinic?.doctor.nameMiddle} {referral.doctorClinic?.doctor.nameLast}</span>
        </ActionLink>
      ));
    });
  }
  
  if (careTeamItems.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap items-baseline">
      <span className="flex mr-6">Care team </span>
      {careTeamItems.map((item, index) => (
        <React.Fragment key={`CareTeam${index}`}>
          {index === careTeamItems.length - 1 && careTeamItems.length !== 1 ? <span className="mx-5">&amp;</span> : <span className="mr-5">{index > 0 ? ', ' : ''}</span>}
          {item}
        </React.Fragment>
      ))}
    </div>
  );
};

export default JourneyShowCareTeam;
