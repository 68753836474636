import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant: 'warning' | 'danger' | 'success' | 'info';
}

const Alert = ({ className, variant, ...props }: Props) => {
  let classes = 'relative px-3 py-3 mb-4 border rounded';

  switch (variant) {
    case 'warning':
      classes += ' text-white bg-yellow';
      break;
    case 'danger':
      classes += ' text-white bg-red';
      break;
    case 'success':
      classes += ' text-white bg-green';
      break;
    default:
      break;
  }

  if (className) {
    classes += ` ${className}`;
  }

  return (
    <div className={classes} {...props} >
      {props.children}
    </div>
  );
};

Alert.defaultProps = {
  variant: 'warning',
};

export default Alert;
