import * as React from 'react';
import moment from 'moment';
import { Member } from '../../../../graphql/genie-api-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Dispatch } from 'redux';
import { MemberSearchState } from '../../../store/search/types';
import MemberSearch from '../../../containers/search/MemberSearch';
import { navigate } from '../../../store/router/actions';
import { changeMemberSearch } from '../../../store/search/actions';
import { formatSex } from '../../../../utilities/helpers';
import MemberSearchResults from '../MemberSearchResults';

export type MemberSearchQueryResult = Pick<Member, 'id' | 'name' | 'dateOfBirth'>;

interface DispatchProps {
  onNavigateToMember(member: Member['id']): void;
}

interface OwnProps {
  emptySearchContent?: React.ReactNode;
}

class MemberSearchQuery extends React.PureComponent<MemberSearchState & OwnProps & DispatchProps> {
  renderNoResults() {
    return (
      <div>No results found</div>
    );
  }

  renderSearchResult = (member: Member) => {
    const { onNavigateToMember } = this.props;
    const dateOfBirth = moment(member.dateOfBirth);

    return (
      <button
        key={member.id}
        type="button"
        className="px-10 border-b py-15 w-full block flex justify-between items-center focus:outline-none hover:bg-purple-100"
        onClick={() => onNavigateToMember(member.id)}
      >
        <div className="text-purple font-bold">{member.name} {member.dateOfBirth ? (
          <span className="text-sm">
            {dateOfBirth.format('D/M/YYYY')}
          </span>
        ) : ''}</div>
        <div className="flex text-purple text-sm">
          {member.dateOfBirth ? (
            <div className="text-purple-light">{dateOfBirth.fromNow(true)} old</div>
          ) : null}
          {member.sex ? (
            <div className="text-purple-light ml-10">{formatSex(member.sex)} (at birth)</div>
          ) : null}
        </div>
      </button>
    );
  }

  renderSearchResults = (results: Member[], isLoading: boolean) => {
    const { searchTerm } = this.props;

    if (!results && !isLoading && searchTerm.length > 0) {
      return this.renderNoResults();
    }

    if (!results || !searchTerm) {
      return <div />;
    }

    return (
      <div className="absolute t-full bg-white w-full z-100 shadow max-h-1/2 overflow-y-scroll">
        {results.map(this.renderSearchResult)}
      </div>
    );
  }

  render() {
    const { searchTerm } = this.props;

    return (
      <div className="max-w-3xl relative">
        <MemberSearchResults searchTerm={searchTerm}>
          {(members, loading) => {
            return (
              <div>
                <MemberSearch isLoading={loading} />
                {this.renderSearchResults(members, loading || loading)}
              </div>
            );
          }}
        </MemberSearchResults>
      </div>
    );
  }
}

const mapStateToProps = ({ search }: ApplicationState) => ({
  ...search.memberSearchState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onNavigateToMember: (memberId: Member['id']) => {
    dispatch(navigate.toMemberLegacy(memberId));
    dispatch(changeMemberSearch({ searchTerm: '' }));
  },
});

export default connect<MemberSearchState, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(MemberSearchQuery);
