import { appSyncClient } from '../../../utilities/appSync';
import { Request, Report } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ReportState } from '../../store/report';
import { ReportRequest } from '../../store/report/request/types';

interface RequestResult extends ReportRequest {
  report: Request['reports'][0];
}

interface RequestReportByRequestId {
  request: RequestResult;
}

interface ReportResult {
  label: string;
  report: Pick<Report, 'id' | 'dateCreated' | 'username'>;
  data: ReportState;
  request: ReportRequest;
}

const requestReportByRequestId = async (requestId: string): Promise<ReportResult> => {
  const requestResult = await appSyncClient.query<RequestReportByRequestId>({
    query: gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          riskStatus
          product
          productVariant
          numGenesTestedRecessive
          numGenesTestedXLinked
          referringDoctorClinic {
            email
            doctor {
              nameFirst
              nameMiddle
              nameLast
              personalEmail
            }
            clinic {
              name
              clinicEmail
              addressStreet
            }
          }
          initiatingMember {
            id
          }
          primaryCounsellor {
            id
            name
          }
          report {
            id
            data
            dateCreated
            username
          }
          order {
            data
          }
          journeys {
            id
            state
            labOrderNumber
            member {
              id
              name
            }
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      id: requestId,
    },
  });

  const result: RequestResult = requestResult.data.request;
  const { report, ...request } = result;
  const label = result.journeys?.map(journey => journey.member?.name ?? 'Memberless journey').join(' & ') || '';

  return {
    label,
    report,
    request,
    data: JSON.parse(report.data),
  };
};

export default requestReportByRequestId;
