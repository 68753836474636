import * as React from 'react';
import { Journey, JourneyState } from '../../../../graphql/genie-api-types';
import JourneyMoveForm from '../../../components/journey/JourneyMoveForm';
import MoveJourneyToState, { MoveJourneyToStateMutationFunction } from '../MoveJourneyToState';

export interface MoveJourneysToStateFormProps {
  journeyIds: Journey['id'][];
  onComplete(): void;
}

const MoveJourneysToStateForm = (props: MoveJourneysToStateFormProps) => (
  <MoveJourneyToState>
    {(moveToState: MoveJourneyToStateMutationFunction, isLoading: boolean) => (
      <JourneyMoveForm
        isLoading={isLoading}
        initialValues={{}}
        onSubmit={async (values) => {
          await props.journeyIds.map(journeyId =>
            moveToState(journeyId, values.journeyState as JourneyState));
          props.onComplete();
        }}
      />
    )}
  </MoveJourneyToState>
);

export default MoveJourneysToStateForm;
