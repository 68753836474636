import * as React from 'react';
import { get, startCase } from 'lodash';
import moment from 'moment';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { CreateRequestInput, Journey, RiskStatus, UpdateRequestInput } from '../../../graphql/genie-api-types';
import FormGroup from '../widgets/form/FormGroup';
import MemberSearchAutocomplete from '../../graphql/containers/members/MemberSearchAutocomplete';
import DoctorClinicSearchAutocomplete from '../../graphql/containers/doctor-clinic/DoctorClinicSearchAutocomplete';
import { SearchAutocompleteOption } from '../widgets/SearchAutocomplete';
import Badge from '../widgets/Badge';
import CounsellorSearchAutocomplete from '../../graphql/containers/counsellors/CounsellorSearchAutocomplete';
import MultiModelForm from '../../screens/MultiModelForm';
import { AnyModelResult } from '../../graphql/containers/SwitchForm';
import { ProductSelectValue } from '../widgets/form/ProductSelect';
import MemberOrPlaceholder from '../member/MemberOrPlaceholder';
import * as yup from 'yup';
import { showToast } from '../../store/toast/actions';
import { getProductStrategy } from '../../../utilities/journeys/productVariantTable';
import { DoctorClinicPartial } from '../../../utilities/doctorClinic';

interface RequestFormProps {
  initialValues?: Partial<RequestFormValues>;
  onSubmit: BaseFormProps['onSubmit'];
  isLoading: BaseFormProps['isLoading'];
  isCreate?: boolean;
  errorMessage?: BaseFormProps['errorMessage'];
  onFormChanged?(values: RequestFormValues): void;
  onNewJourney?(): void;
  attachedJourneys?: Pick<Journey, 'member' | 'id' | 'startDate' | 'state'>[];
}

interface RequestFormInput extends Pick<FormInputProps, 'label' | 'helpText' | 'name' | 'as' | 'options' | 'type' | 'min' | 'max' | 'halfWidth'> {
  name:
    | keyof Omit<CreateRequestInput, 'product'>
    | keyof Omit<UpdateRequestInput, 'product'>
    | 'pregnancy.weeks'
    | 'pregnancy.dueDate'
    | 'pregnancy.dueDateConfirmed'
    | 'productAndVariant'
    | 'riskStatus';
  inCreateForm: boolean;
  inUpdateForm: boolean;
}

const riskStatusOptions = [{ value: '', label: 'Not set' }];

(Object.keys(RiskStatus) as Array<keyof typeof RiskStatus>).map(key => {
  riskStatusOptions.push({
    value: RiskStatus[key],
    label: key,
  });
});

const formItems: RequestFormInput[] = [
  {
    label: 'Product and variant',
    name: 'productAndVariant',
    type: 'productAndVariantSelect',
    inCreateForm: true,
    inUpdateForm: true,
  },
  {
    label: 'Paid',
    name: 'datePaid',
    type: 'datetime-local',
    inCreateForm: true,
    inUpdateForm: true,
  },
  {
    label: 'Reason',
    name: 'reason',
    as: 'textarea',
    inCreateForm: true,
    inUpdateForm: true,
  },
  {
    label: 'Source',
    name: 'source',
    helpText: "Shared with Drip: Don't enter any personally identifying information or names.",
    inCreateForm: true,
    inUpdateForm: true,
  },
  {
    label: 'Weeks pregnant',
    name: 'pregnancy.weeks',
    type: 'number',
    min: '0',
    max: '52',
    inCreateForm: true,
    inUpdateForm: false,
  },
  {
    label: 'Due date',
    name: 'pregnancy.dueDate',
    type: 'date',
    inCreateForm: false,
    inUpdateForm: true,
    halfWidth: true,
  },
  {
    label: 'Due date confirmed',
    name: 'pregnancy.dueDateConfirmed',
    type: 'checkbox',
    inCreateForm: false,
    inUpdateForm: true,
    halfWidth: true,
  },
  {
    label: 'Report risk status',
    name: 'riskStatus',
    as: 'select',
    options: riskStatusOptions,
    inCreateForm: false,
    inUpdateForm: true,
  },
  {
    label: 'The Number of Recessive genes screened for on the panel',
    name: 'numGenesTestedRecessive',
    type: 'number',
    min: '0',
    max: '1000',
    inCreateForm: true,
    inUpdateForm: true,
  },
  {
    label: 'The Number of X Linked genes screened for on the panel',
    name: 'numGenesTestedXLinked',
    type: 'number',
    min: '0',
    max: '1000',
    inCreateForm: true,
    inUpdateForm: true,
  },
];

export interface RequestFormValues extends Omit<CreateRequestInput, 'product'> {
  productAndVariant: ProductSelectValue;
  referringDoctorClinic: SearchAutocompleteOption;
  initiatingMember: SearchAutocompleteOption;
  partner: SearchAutocompleteOption;
  primaryCounsellor: SearchAutocompleteOption;
  riskStatus: RiskStatus;
}

const validationSchema = yup.object().shape({
  datePaid: yup.date().required(),
  productAndVariant: yup.object().shape({
    product: yup.string().required(),
    productVariant: yup.string().when('product', {
      is: (product) => getProductStrategy(product).hasVariant,
      then: yup.string().required(),
    }).when('product', {
      is: (product) => !getProductStrategy(product).hasVariant,
      then: yup.string().nullable(true)
    })
  }).nullable(true),
})

class RequestForm extends React.PureComponent<RequestFormProps> {
  renderJourneyTable() {
    const { attachedJourneys, onNewJourney } = this.props;

    return (
      <FormGroup
        controlId="journeys"
        label="Journeys"
        labelAction={{
          label: '+ New',
          onClick: onNewJourney,
        }}
      >
        <table className="w-full mb-10 text-xs">
          <tbody>
            {attachedJourneys.map(journey => (
              <tr key={journey.id} className="border-t">
                <td className="py-10">
                  <MemberOrPlaceholder member={journey.member}>
                    {(member) => (
                      <Badge color={'white'}>{member.name}</Badge>
                    )}
                  </MemberOrPlaceholder>
                </td>
                <td>
                  {startCase(journey.state.toLowerCase())}
                </td>
                <td className="text-right">
                  Started {moment(journey.startDate).format('Do MMM YYYY')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </FormGroup>
    );
  }

  render() {
    const { isCreate, attachedJourneys, onSubmit, ...props } = this.props;
    const { initiatingMember, partner, referringDoctorClinic, ...restOfInitialValues } = this.props.initialValues;

    return (
      <MultiModelForm<'initiatingMember' | 'partner' | 'referringDoctorClinic'>
        modelForms={{
          initiatingMember: 'MemberCreate',
          partner: 'MemberCreate',
          referringDoctorClinic: 'DoctorClinicCreate',
        }}
        initialModelValues={{
          initiatingMember: initiatingMember as AnyModelResult,
          partner: partner as AnyModelResult,
          referringDoctorClinic: referringDoctorClinic as AnyModelResult,
        }}
      >
        {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
          <BaseForm
            {...props}
            validationSchema={validationSchema}
            initialValues={restOfInitialValues}
            onSubmit={values => {
              if (!modelValues.initiatingMember) {
                showToast('warning', 'Initiating member value missing', 'This field is required');
                return;
              }
              onSubmit({
                ...values,
                ...modelValues,
              });
            }}
            submitButtonLabel="Save"
          >
            {({ handleChange, values, errors, setFieldValue }) => (
              <div className="flex flex-wrap items-end">
                <FormGroup
                  controlId="initiatingMember"
                  helpText={JSON.stringify(errors['initiatingMember'])}
                  label="Initiating member"
                  labelAction={{
                    label: '+ New',
                    onClick: () => onSwitchToModelForm('initiatingMember'),
                  }}
                >
                  <MemberSearchAutocomplete onSelect={member => onChangeModelValue('initiatingMember', member)} defaultValue={modelValues.initiatingMember} />
                </FormGroup>
                {isCreate ? (
                  <FormGroup
                    controlId="partnerId"
                    label="Partner"
                    labelAction={{
                      label: '+ New',
                      onClick: () => onSwitchToModelForm('partner'),
                    }}
                  >
                    <MemberSearchAutocomplete onSelect={partner => onChangeModelValue('partner', partner)} defaultValue={modelValues.partner} />
                  </FormGroup>
                ) : null}
                <FormGroup
                  controlId="doctorClinic"
                  label="Doctor/Clinic"
                  // labelAction={{
                  //   label: '+ New',
                  //   onClick: () => onSwitchToModelForm('doctorClinic'),
                  // }}
                >
                  <DoctorClinicSearchAutocomplete onSelect={doctorClinic => onChangeModelValue('referringDoctorClinic', doctorClinic)} defaultValue={modelValues.referringDoctorClinic as DoctorClinicPartial} />
                </FormGroup>
                <FormGroup controlId="primaryCounsellorId" label="Primary counsellor">
                  <CounsellorSearchAutocomplete onSelect={primaryCounsellor => setFieldValue('primaryCounsellor', primaryCounsellor)} defaultValue={values.primaryCounsellor} />
                </FormGroup>
                {formItems
                  .filter(item => (isCreate ? item.inCreateForm : item.inUpdateForm))
                  .map(({ inUpdateForm, inCreateForm, ...formItem }) => (
                    <FormInput
                      {...formItem}
                      value={get(values, formItem.name)}
                      onChange={handleChange}
                      helpText={typeof errors[formItem.name] !== 'string' && errors[formItem.name] ? `${formItem.name} is a required field` : errors[formItem.name]}
                      key={formItem.name}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                {attachedJourneys ? this.renderJourneyTable() : null}
              </div>
            )}
          </BaseForm>
        )}
      </MultiModelForm>
    );
  }
}

export default RequestForm;
