import * as React from 'react';

interface TrafficLightsBarProps {
  greenCount: number;
  greyCount: number;
  redCount: number;
}

const colors: { colorClass: string; key: keyof TrafficLightsBarProps }[] = [
  {
    colorClass: 'green',
    key: 'greenCount',
  },
  {
    colorClass: 'purple',
    key: 'greyCount',
  },
  {
    colorClass: 'red',
    key: 'redCount',
  },
]

const TrafficLightsBar = (props: TrafficLightsBarProps) => {
  const totalCount = props.greenCount + props.greyCount + props.redCount;

  return (
    <div>
      <div className="flex w-full" style={{ minWidth: '200px' }}>
        {colors.map(color => (
          <div
            className={`bg-${color.colorClass}`}
            key={color.key}
            style={{
              width: `${(props[color.key] / totalCount) * 100}%`,
              height: '10px',
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default TrafficLightsBar;