import * as React from 'react';
import './styles/button-group.scss';

type ButtonGroupProps = {
  className?: string;
  children: React.ReactNode;
};

const ButtonGroup = (props: ButtonGroupProps) => (
  <div className={`button-group ${props.className || ''}`}>
    {props.children}
  </div>
);

export default ButtonGroup;
