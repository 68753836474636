import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  type: 'checkbox'|'radio';
  name: string;
  value?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
}

const FormCheck = ({ className, label: labelText, ...props }: Props) => {
  return (
    <label className={`flex items-center ${className}`}>
      <input {...props} className="mr-10" />
      <span>{labelText}</span>
    </label>
  );
};

export default FormCheck;
