import * as React from 'react';
import { Filters } from '../../screens/Stats';
import moment from 'moment';

interface StatsFormProps {
  onChangeFilters(filters: Filters): void;
  defaultFilters: Filters;
}

const StatsForm = (props: StatsFormProps) => {
  const [filters, setFilters] = React.useState<Filters>({
    fromDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const onChangeFromDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      fromDate: e.target.value,
    });
  }, [filters, setFilters]);

  const onChangeToDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      toDate: e.target.value,
    });
  }, [filters, setFilters]);
  
  React.useEffect(() => {
    setFilters(props.defaultFilters);
  }, [props.defaultFilters]);

  return (
    <div className="bg-grey-light px-10 rounded border">
      <label className="font-bold mr-20">
        From
        <input type="date" className="bg-white px-10 ml-10" onChange={onChangeFromDate} value={filters.fromDate} />
      </label>
      <label className="font-bold">
        To
        <input type="date" className="bg-white px-10 ml-10" onChange={onChangeToDate} value={filters.toDate} />
      </label>
      <button
        type="button"
        onClick={() => props.onChangeFilters(filters)}
        className="bg-purple text-white ml-10 px-10 py-10 rounded-md hover:bg-blue"
        >
        Filter
      </button>
    </div>
  );
};

export default StatsForm;