import * as React from 'react';
import Table from '../../widgets/Table';
import { navigate } from '../../../store/router/actions';
import { useDispatch } from 'react-redux';
import ClinicShowContext from '../../../contexts/ClinicShowContext';

export const generateDoctorFullName = (nameFirst: string | null, nameMiddle: string | null, nameLast: string | null): string => {
  let name = '';

  if (nameFirst) {
    name += nameFirst;
  }

  if (nameMiddle) {
    name += ` ${nameMiddle}`;
  }

  if (nameLast) {
    name += ` ${nameLast}`;
  }

  return name.trim();
};

const ClinicShowDoctors = () => {
  const { clinic } = React.useContext(ClinicShowContext);
  const dispatch = useDispatch();

  if (!clinic) {
    return null;
  }

  const onGoToDoctor = (doctorId: string) => dispatch(navigate.toDoctor(doctorId));

  return <Table
    header={['Doctor Name']}
    onRowSelect={onGoToDoctor}
    rowGroups={clinic.doctors.map(doctor => ({
      id: doctor.id,
      rows: [{
        id: doctor.id,
        cells: [
          generateDoctorFullName(doctor.nameFirst, doctor.nameMiddle, doctor.nameLast)
        ],
      }]
    })) ?? []}
  />
};

export default ClinicShowDoctors;