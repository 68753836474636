import * as React from 'react';
import Button, { ButtonProps } from './Button';
import { FiChevronDown } from './Icon';

type ActionType = string;

export type Action<ActionNames = ActionType> = {
  label: React.ReactNode | string;
  name: ActionNames;
  value?: string;
  variant: ButtonProps['variant'];
  extraProps?: any;
};

interface DropdownButtonProps<ActionNames = ActionType> extends React.HTMLAttributes<HTMLButtonElement> {
  actions: Action<ActionNames>[];
  onActionClick?(actionName: ActionNames, actionValue: string): void;
}

interface DropdownButtonState {
  isOpen: boolean;
}

class DropdownButton<ActionNames = ActionType> extends React.PureComponent<DropdownButtonProps<ActionNames>> {
  state: Readonly<DropdownButtonState> = {
    isOpen: false,
  };

  onToggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ isOpen: !this.state.isOpen });
    e.stopPropagation();
  }

  onActionClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, actionName: ActionNames, actionValue: string) {
    const { onActionClick } = this.props;

    if (onActionClick) {
      onActionClick(actionName, actionValue);
    }

    this.setState({ isOpen: false });

    event.stopPropagation();
  }

  render() {
    const { isOpen } = this.state;
    const { className, actions } = this.props;

    if (!actions || actions.length === 0) {
      return null;
    }

    const classes = className ? className.split(' ') : [];
    classes.push('relative');
    classes.push('whitespace-no-wrap');

    const firstAction = actions[0];

    return (
      <div className={classes.join(' ')}>
        <Button
          variant={firstAction.variant}
          key={`${firstAction.name}:${firstAction.value}`}
          size="sm"
          onClick={e => this.onActionClick(e, firstAction.name, firstAction.value)}
          className="flex items-center p-0"
          isActive={isOpen}
        >
          <span className={`pl-10 ${actions.length > 1 ? 'pr-5' : 'pr-10'} py-4`}>{firstAction.label}</span>
          {actions.length > 1 ? (
            <div
              className="hover:bg-trans-black flex items-center px-5 py-4 rounded-sm text-inherit focus:outline-none"
              onClick={this.onToggleDropdown}
            >
              <FiChevronDown className="w-4 h-4" />
            </div>
          ) : null}
        </Button>
        {isOpen ? (
          <div className="block relative">
            <div className="absolute z-10 top-0 bg-white shadow-md border right-0 mt-2 min-w-full">
              {actions.slice(1).map(action => (
                <Button
                  variant={action.variant}
                  key={`${action.name}:${action.value}`}
                  size="sm"
                  className="rounded-none w-full border-none block"
                  textAlign="left"
                  onClick={e => this.onActionClick(e, action.name, action.value)}
                >
                  {action.label}
                </Button>
              ))}
            </div>
            <div className="fixed top-0 bottom-0 left-0 right-0 z-0" onClick={this.onToggleDropdown}></div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DropdownButton;
