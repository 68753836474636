import React from 'react';
import Badge, { BadgeProps } from './Badge';

interface BadgeListProps {
  commaSeparator?: string;
  ampersandSeparator?: string;
  items: string[];
  badgeProps?: BadgeProps;
}

const BadgeList = (props: BadgeListProps) => {
  const { items, ampersandSeparator, commaSeparator } = props;
  const badgeItems: React.ReactElement<any>[] = [];

  items.forEach((item, index) => {
    badgeItems.push(<Badge key={item}>{item}</Badge>);

    if (index === items.length - 1) {
      return;
    }

    if (index === items.length - 2) {
      badgeItems.push(<span key="ampersand">{ampersandSeparator}</span>);
    } else {
      badgeItems.push(<span key={`comma${index}`}>{commaSeparator}</span>);
    }
  });

  return (
    <div>
      {badgeItems}
    </div>
  );
};

BadgeList.defaultProps = {
  commaSeparator: ', ',
  ampersandSeparator: ' & ',
};

export default BadgeList;
