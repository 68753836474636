import { Reducer } from 'redux';
import { CarrierRatesState, CarrierRatesActionTypes } from './types';
import { ClientId, ClientDetailsActionTypes } from '../client-details/types';

const initialState: CarrierRatesState = {
  selectedCarrierRateEthnicitiesByGene: {},
};

const reducer: Reducer<CarrierRatesState> = (state = initialState, action) => {
  switch (action.type) {
    case CarrierRatesActionTypes.CHANGE_CARRIER_RATE_ETHNICITY:
      const { gene, clientId, ethnicityId }: { gene: string; clientId: ClientId; ethnicityId: string } = action.payload;
      const selectedEthnicitiesForGene = state.selectedCarrierRateEthnicitiesByGene[gene];
      return {
        ...state,
        selectedCarrierRateEthnicitiesByGene: {
          ...state.selectedCarrierRateEthnicitiesByGene,
          [gene]: {
            ...selectedEthnicitiesForGene,
            [clientId]: ethnicityId,
          },
        },
      };

    case ClientDetailsActionTypes.SWAP_CLIENTS:
      const selectedCarrierRateEthnicitiesByGene = { ...state.selectedCarrierRateEthnicitiesByGene };
      Object.keys(selectedCarrierRateEthnicitiesByGene).forEach(gene => {
        selectedCarrierRateEthnicitiesByGene[gene] = {
          A: state.selectedCarrierRateEthnicitiesByGene[gene].B,
          B: state.selectedCarrierRateEthnicitiesByGene[gene].A,
        };
      });
      return {
        selectedCarrierRateEthnicitiesByGene,
      };

    default:
      return state;
  }
};

export { reducer as carrierRatesReducer };
