import * as React from 'react';
import { Mutation } from 'react-apollo';
import { UpdateJourneyParamsResult, MutationUpdateJourneyParamsArgs, Journey } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

export type ChangeLabOrderNumberMutationFn = (journeyId: Journey['id'], labOrderNumber: Journey['labOrderNumber']) => Promise<void>;

interface ChangeLabOrderNumberProps {
  children(moveToState: ChangeLabOrderNumberMutationFn, isLoading: boolean): React.ReactElement<any>;
}

const ChangeLabOrderNumber = (props: ChangeLabOrderNumberProps) => (
  <Mutation<UpdateJourneyParamsResult, MutationUpdateJourneyParamsArgs>
    mutation={gql`
      mutation UpdateJourneyParams($input: UpdateJourneyParamsInput!) {
        updateJourneyParams(input: $input) {
          journey {
            id
            labOrderNumber
          }
        }
      }
    `}
    refetchQueries={() => {
      return ['Journey', 'ListKits'];
    }}
  >
    {(mutate, result) => props.children(async (journeyId, labOrderNumber) => {
      await mutate({
        variables: {
          input: {
            journeyId,
            labOrderNumber,
          },
        },
      });
      ReduxQueryListener.triggerEvents('All');
    }, result.loading)}
  </Mutation>
);

export default ChangeLabOrderNumber;
