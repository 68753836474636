import { appSyncClient } from '../../../../utilities/appSync';
import { Mutation, Flag, MutationUpdateFlagCompleteArgs } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';

type UpdateFlagCompleteResult = {
  updateFlagComplete: {
    flag: Flag['id'];
  };
}

const updateFlagComplete = async (flagId: Flag['id']): Promise<ApolloQueryResult<UpdateFlagCompleteResult>> =>
  appSyncClient.mutate<Pick<Mutation, 'updateFlagComplete'>, MutationUpdateFlagCompleteArgs>({
    mutation: gql`
      mutation UpdateFlagComplete($input: UpdateFlagCompleteInput!) {
        updateFlagComplete(input: $input) {
          flag {
            id
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    refetchQueries: () => {
      return ['ListFlags'];
    },
    variables: {
      input: {
        id: flagId,
      },
    },
  });

export default updateFlagComplete;
