import * as React from 'react';
import moment from 'moment';
import { FiArrowLeft, FiSearch, FiX } from '../../../components/widgets/Icon';
import MemberJourneys from '../../../graphql/containers/MemberJourneys';
import Spinner from '../../../components/widgets/Spinner';
import MemberSearchResults from '../../../graphql/containers/MemberSearchResults';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';

const JourneyInfoSearch = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedSearchResultMemberId, setSelectedSearchResultMemberId] = React.useState<string>(null);
  const journeyInfoContext = React.useContext(JourneyInfoContext);

  const onClearSearch = () => {
    setSearchTerm('');
    setSelectedSearchResultMemberId(null);
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectedSearchResultMemberId(null);
  }

  const onSelectMember = (memberId: string, memberName: string) => {
    setSelectedSearchResultMemberId(memberId);
    setSearchTerm(memberName);
  }

  const onSelectJourney = (journeyId: string) => {
    onClearSearch();

    journeyInfoContext.onSelectJourney(journeyId);
  }

  const renderSearch = () => {
    let searchContent = null;
    const searchResultClass = 'block font-bold hover:bg-grey-light w-full py-10 px-20 text-left focus:outline-none';
    const spinnerClass = 'm-20';

    if (selectedSearchResultMemberId) {
      searchContent = (
        <MemberJourneys memberId={selectedSearchResultMemberId}>
          {(journeys, isLoading) => (
            <div>
              {isLoading ? <Spinner className={spinnerClass} label="Getting journeys" /> : null}
              {journeys.map(journey => (
                <button onClick={() => onSelectJourney(journey.id)} key={journey.id} className={searchResultClass}>
                  Journey started on {moment(journey.startDate).format('Do MMMM YYYY')}
                </button>
              ))}
            </div>
          )}
        </MemberJourneys>
      );
    } else {
      searchContent = (
        <MemberSearchResults searchTerm={searchTerm}>
          {(members, isLoading) => (
            <div>
              {isLoading ? <Spinner className={spinnerClass} label="Finding members" /> : null}
              {members.length ? members.map(searchMember => (
                <button onClick={() => onSelectMember(searchMember.id, searchMember.name)} key={searchMember.id} className={searchResultClass}>
                  {searchMember.name} ({searchMember.dateOfBirth})
                </button>
              )) : (!isLoading ? (
                <div className={searchResultClass}>No results found</div>
              ) : null)}
            </div>
          )}
        </MemberSearchResults>
      );
    }

    return (
      <div className="absolute right-0 left-0 shadow-md bg-white mx-20 z-50" style={{ top: '100%' }}>
        {searchContent}
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="mx-20 mt-30 relative">
        <button onClick={journeyInfoContext.onGoBack} disabled={!journeyInfoContext.canGoBack} className="absolute left-0 top-0 bottom-0 flex items-center text-grey focus:outline-none">
          {journeyInfoContext.canGoBack ? <FiArrowLeft /> : <FiSearch />}
        </button>
        <input
          type="text"
          className="border-b w-full text-sm text-grey-dark focus:outline-none focus:border-purple focus:text-black pl-20 py-5"
          placeholder="Search by name, dob (yyyy-mm-dd), rq# ..."
          value={searchTerm}
          onChange={onSearchChange}
        />
        {searchTerm.length > 0 ? <button onClick={onClearSearch} className="absolute top-0 right-0 p-5"><FiX /></button> : null}
      </div>
      {searchTerm || selectedSearchResultMemberId ? renderSearch() : null}
    </div>
  );
}

export default JourneyInfoSearch;
