import { action } from 'typesafe-actions';
import { CustomisationActionTypes } from './types';

export const changeSectionVariables = (templateName: string, pageId: string, sectionId: string, variables: object) =>
  action(CustomisationActionTypes.CHANGE_TEMPLATE_SECTION_VARIABLES, {
    templateName,
    pageId,
    sectionId,
    variables,
  });

export const toggleSectionVisibility = (templateName: string, pageId: string, sectionId: string) =>
  action(CustomisationActionTypes.TOGGLE_VISIBILITY, {
    templateName,
    pageId,
    sectionId,
  });

export const changeTemplateName = (templateName: string) =>
  action(CustomisationActionTypes.CHANGE_TEMPLATE_NAME, templateName);

export const resetSection = (templateName: string, pageId: string, sectionId: string) =>
  action(CustomisationActionTypes.RESET_SECTION, { templateName, pageId, sectionId });
