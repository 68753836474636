import * as React from 'react';

import { Switch } from 'react-router';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';
import JourneyShow from './journeys/JourneyShow';

const Journeys = () => {
  return (
    <Switch>
      <Route path={PATHS.JOURNEY_SHOW} component={JourneyShow} />
    </Switch>
  );
};

export default Journeys;
