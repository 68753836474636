import * as React from 'react';
import moment from 'moment';
import { mean, max, min } from 'lodash';
import { JourneyState } from '../../../../../graphql/genie-api-types';
import Select from '../../../widgets/Select';
import { JOURNEY_STATE_LABELS } from '../../../../../utilities/journeys/states';
import { FiArrowRight, FiArrowDown, FiArrowUp } from '../../../widgets/Icon';
import { RequestAllJourneysWithHistoryJourney } from '../JourneyStats';

const journeyStateOptions = Object.keys(JOURNEY_STATE_LABELS).map((journeyState: JourneyState) => ({
  label: JOURNEY_STATE_LABELS[journeyState],
  value: journeyState
}));

interface MonthNumberComparisonProps {
  allJourneysWithHistory: RequestAllJourneysWithHistoryJourney[];
}

const MonthNumberComparison = (props: MonthNumberComparisonProps) => {
  const [fromState, setFromState] = React.useState<JourneyState>(JourneyState.RequirePretestBooking);
  const [toState, setToState] = React.useState<JourneyState>(JourneyState.ReadyToReleaseResults);
  const { allJourneysWithHistory } = props;
  
  const { thisMonth, lastMonth } = React.useMemo(() => {
    const currentDate = moment().subtract(2, 'month')
    const lastMonth = moment().subtract(3, 'month');

    const thisMonthDurations: number[] = [];
    const lastMonthDurations: number[] = [];

    allJourneysWithHistory.forEach(journey => {
      const fromLog = journey.history.slice().reverse().find(historyItem => historyItem.toState === fromState);
      const toLog = journey.history.slice().reverse().find(historyItem => historyItem.toState === toState);

      if (fromLog && toLog) {
        const fromDate = moment(fromLog.date);
        const toDate = moment(toLog.date)

        const timeBetweenStates = toDate.diff(fromDate, 'd');

        if (toDate.isSame(currentDate, 'month')) {
          thisMonthDurations.push(timeBetweenStates);
        }
        else if (toDate.isSame(lastMonth, 'month')) {
          lastMonthDurations.push(timeBetweenStates);
        }
      }
    });

    const data = {
      thisMonth: {
        daysAverage: Math.round(mean(thisMonthDurations)) ?? 0,
        quantity: thisMonthDurations.length,
        max: max(thisMonthDurations),
        min: min(thisMonthDurations),
      },
      lastMonth: {
        daysAverage: Math.round(mean(lastMonthDurations)) ?? 0,
        quantity: lastMonthDurations.length,
        max: max(lastMonthDurations),
        min: min(lastMonthDurations),
      },
    };

    return data;
  }, [fromState, toState, allJourneysWithHistory]);
  
  const direction = thisMonth.daysAverage > lastMonth.daysAverage ? 'up' : 'down';

  return (
    <div className="p-20">
      <div className="flex mb-20 items-center">
        <Select<JourneyState>
          options={journeyStateOptions}
          onChange={setFromState}
          selectedValue={fromState}
        />
        <FiArrowRight size={30} className="mx-20" />
        <Select<JourneyState>
          options={journeyStateOptions}
          onChange={setToState}
          selectedValue={toState}
        />
      </div>
      <div className="font-bold">
        <div className="text-2xl">{thisMonth.daysAverage}</div>
        <div className="my-10">average days</div>
        <div className={`text-sm ${direction === 'up' ? 'text-red' : 'text-green-dark'}`}>
          {direction === 'up' ? <FiArrowUp /> : <FiArrowDown />}
          {Math.abs(thisMonth.daysAverage - lastMonth.daysAverage)} days <span className="text-grey-dark">(from {lastMonth.daysAverage} last month)</span>
        </div>
      </div>
    </div>
  );
}

export default MonthNumberComparison;
