import * as React from 'react';
import Heading from '../../../../components/widgets/Heading';
import PreTestCounsellingJourneys from '../../../../components/journey/journey-rows/PreTestCounsellingJourneys';

const JourneysPreGroup = () => (
  <div>
    <Heading level={3} className="mb-10">
      Pre-test counselling <span className="ml-5 text-sm align-middle">📞 </span>
    </Heading>
    <PreTestCounsellingJourneys />
  </div>
);

export default JourneysPreGroup;
