import { action } from 'typesafe-actions';
import { ClientDetailsActionTypes, TaskTypes } from './types';

export const completeTask = (taskName: TaskTypes) =>
  action(ClientDetailsActionTypes.COMPLETE_TASK, taskName);

export const unCompleteTask = (taskName: TaskTypes) =>
  action(ClientDetailsActionTypes.UNCOMPLETE_TASK, taskName);

export const toggleTask = (taskName: TaskTypes) =>
  action(ClientDetailsActionTypes.TOGGLE_TASK, taskName);
