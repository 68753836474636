import * as React from 'react';
import { FiChevronLeft, FiChevronRight } from '../../widgets/Icon';
import { PATHS } from '../../../store/router/types';
import Spinner from '../../widgets/Spinner';
import Tabs, { TabOption } from '../../widgets/Tabs';
import JourneyInfoActions from './JourneyInfoActions';
import JourneyInfoHeader from './JourneyInfoHeader';
import JourneyInfoTimeline from './JourneyInfoTimeline';
import JourneyInfoNotes from './JourneyInfoNotes';
import FamilyHistoryListView from '../../family-history/FamilyHistoryListView';
import MemberFamilyHistoryQuery from '../../../graphql/containers/members/MemberFamilyHistoryQuery';
import LoadingOverlay from '../../widgets/LoadingOverlay';
import RouteButton from '../../widgets/RouteButton';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';
import JourneyInfoSearch from './JourneyInfoSearch';
import JourneyInfoNotifications from './JourneyInfoNotifications';


type JourneyInfoTabNames = 'timeline' | 'notes' | 'family-history' | 'notifications';

const JourneyInfo = () => {
  const { journey, isLoading, isOpen, onToggleOpen } = React.useContext(JourneyInfoContext);

  const tabs: TabOption<JourneyInfoTabNames>[] = [
    {
      name: 'notes',
      label: 'Notes',
    },
    {
      name: 'timeline',
      label: 'Timeline',
    },
    {
      name: 'notifications',
      label: 'Notifications',
    }
  ];

  if (journey?.member) {
    tabs.push({
      name: 'family-history',
      label: 'FHx',
    });
  }
  
  const renderJourneyTab = (tabName: JourneyInfoTabNames) => {
    switch (tabName) {
      case 'timeline':
        return <JourneyInfoTimeline />;

      case 'notes':
        return <JourneyInfoNotes />;

      case 'family-history':
        return (
          <MemberFamilyHistoryQuery
            memberId={journey.member.id}
          >
            {(result, submissions, isLoading) => (
              <div className="p-20 overflow-auto relative">
                {isLoading && <LoadingOverlay />}
                {result?.familyHistoryGroup && (
                  <FamilyHistoryListView familyHistoryGroup={result.familyHistoryGroup} />
                )}
                <RouteButton
                  path={PATHS.MEMBER_FAMILY_HISTORY.replace(':memberId', journey.member.id)}
                  size="sm"
                  className="mr-0 ml-auto mb-10"
                >
                  View full family history
                </RouteButton>
              </div>
            )}
          </MemberFamilyHistoryQuery>
        );
                  
      case 'notifications':
        return <JourneyInfoNotifications />;
      }
  }

  return (
    <div className={`top-0 fixed right-0 z-50 bg-white shadow-2xl h-screen no-scrollbars ${isOpen ? 'w-infopanel' : 'w-px'}`}>
      <div className="pb-30 top-0 h-full scrolling-touch overflow-y-auto border-l border-purple-lighter no-scrollbars w-full">
        <JourneyInfoSearch />
        <div className="relative pt-10">
          <div>
            {!journey && isLoading && (
              <div className="mx-20 mt-20">
                <Spinner label="Loading journey..." />
              </div>
            )}
            {journey && (
              <div>
                <JourneyInfoHeader />
                <JourneyInfoActions />
                <Tabs<JourneyInfoTabNames>
                  tabs={tabs}
                  defaultTab="notes"
                  className="pl-15"
                >
                  {tabName => renderJourneyTab(tabName)}
                </Tabs>
              </div >
            )}
          </div>
        </div>
      </div>
      <button className="print:hidden absolute top-0 items-center focus:outline-none p-10 bg-white shadow mt-10" onClick={onToggleOpen} style={{ right: '100%' }}>
        {isOpen ? <FiChevronRight /> : <FiChevronLeft />}
      </button>
    </div>
  );
};

export default JourneyInfo;
