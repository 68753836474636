import { RecommendationAction, RecommendationResult } from '../../graphql/genie-api-types';

const preTestQuestion = 'Was a pre-test consult for this member required?';

interface RecommendationItemOption {
  question: string;
  answers: {
    label: string;
    value: RecommendationResult;
  }[];
}

const options: { [key in RecommendationAction]: RecommendationItemOption } = {
  [RecommendationAction.PreTestConsultNotRequired]: {
    question: preTestQuestion,
    answers: [
      { label: 'Yes', value: RecommendationResult.NeedsWork },
      { label: 'No', value: RecommendationResult.Ok },
    ],
  },
  [RecommendationAction.PreTestConsultRequired]: {
    question: preTestQuestion,
    answers: [
      { label: 'Yes', value: RecommendationResult.Ok },
      { label: 'No', value: RecommendationResult.NeedsWork },
    ],
  },
}

export const getRecommendationOptionsForAction = (action: RecommendationAction) =>
  options[action];