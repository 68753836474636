import * as React from 'react';
import { Journey, Mutation, Kit, MutationUpdateKitArgs, Member, QueryKitArgs } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import { appSyncClient } from '../../../../utilities/appSync';
import BaseForm from '../../../components/widgets/BaseForm';
import FormInput from '../../../components/widgets/form/FormInput';
import { formatDateOfBirth } from '../../../../utilities/helpers';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import MemberOrPlaceholder from '../../../components/member/MemberOrPlaceholder';

export interface KitsAssignNumberProps {
  kitIds: Kit['id'][];
  markAsArrived: boolean;
  onComplete(): void;
}

const UPDATE_KIT_MUTATION = gql`
  mutation UpdateKit($input: UpdateKitInput!) {
    updateKit(input: $input) {
      kit {
        id
      }
    }
  }
`

const KITS_QUERY = gql`
  query Kit($id: ID!) {
    kit(id: $id) {
      id
      registrationNumber
      journey {
        id
        member {
          id
          dateOfBirth
          name
        }
      }
    }
  }
`;

interface KitResult extends Pick<Kit, 'id' | 'registrationNumber'> {
  journey: {
    id: Journey['id'];
    member: Pick<Member, 'id' | 'dateOfBirth' | 'name'>;
  };
}

interface KitResponse {
  kit: KitResult;
}

interface KitFormResult {
  kits: {
    [key: string]: {
      registrationNumber: string;
    };
  };
}

const KitsAssignNumber = (props: KitsAssignNumberProps) => {
  const { kitIds } = props;
  const [isLoadingKits, setIsLoadingKits] = React.useState(true);
  const [kits, setKits] = React.useState([] as KitResult[]);

  const [mutate, mutationRequest] = useMutation<Pick<Mutation, 'updateKit'>, MutationUpdateKitArgs>(UPDATE_KIT_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['ListKits'];
    },
  });

  const loadKits = async () => {
    setIsLoadingKits(true);

    const kitResults = await Promise.all(kitIds.map(kitId =>
      appSyncClient.query<KitResponse, QueryKitArgs>({
        query: KITS_QUERY,
        fetchPolicy: 'network-only',
        variables: { id: kitId },
      })));

    setKits(kitResults.filter(response => response.data?.kit).map(response => response.data.kit));

    setIsLoadingKits(false);
  };

  React.useEffect(() => {
    loadKits();
  }, [kitIds]);

  const initialValues = {
    kits: {} as { [key: string]: { registrationNumber: string } },
  };

  kits?.forEach(kit => {
    initialValues.kits[kit.id] = { registrationNumber: kit.registrationNumber };
  });

  if (isLoadingKits) {
    return <LoadingOverlay />;
  }

  return (
    <BaseForm
      submitButtonLabel="Save"
      initialValues={initialValues}
      isLoading={isLoadingKits}
      errorMessage={mutationRequest?.error?.message}
      onSubmit={async (kitFields: KitFormResult) => {
        await Promise.all(kits.map(kit => {
          const variables: MutationUpdateKitArgs = {
            input: {
              kitId: kit.id,
              registrationNumber: kitFields?.kits?.[kit.id].registrationNumber,
            },
          };

          if (props.markAsArrived) {
            variables.input.arrivedAtEugene = true;
          }
          return mutate({
            variables,
          });
        }));

        const registrationNumbers = Object.values(kitFields.kits).map(kitValues => kitValues.registrationNumber);

        showToast('success', 'Kits entered', `Kits ${registrationNumbers.join(',')} added to Genie.`);

        props.onComplete();
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
      }) => (
        <div>
          {kits.map(kit => {
            return (
              <div key={kit.id} className="flex items-center">
                <div className="font-bold mb-20 w-1/3 pr-10">
                  <MemberOrPlaceholder member={kit.journey?.member}>
                    {(member) => (
                      <>
                        {member.name}
                        <span className="font-normal block">
                          {formatDateOfBirth(member.dateOfBirth) || 'no dob'}
                        </span>
                      </>
                    )}
                  </MemberOrPlaceholder>
                </div>
                <FormInput
                  name={`kits[${kit.id}][registrationNumber]`}
                  value={values?.kits?.[kit.id]?.registrationNumber}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  placeholder="e.g. IB1234567"
                  className="flex-1"
                />
              </div>
            )
          })}

        </div>
      )
      }
    </BaseForm>
  );
}

export default KitsAssignNumber;
