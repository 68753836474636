import hash from './hash';
import { retrieveFromLocalStorage } from './encryptedStorageDriver';

export const STORAGE_KEY_VERIFY_NAME = 'storage_key_verify';

const isStorageKeyValid = (storageKey: string) => {
  // Double hash for local storage check.
  const doubleHash = hash(hash(storageKey));

  try {
    const verificationKey = retrieveFromLocalStorage(STORAGE_KEY_VERIFY_NAME, '', false);
    // No verification key is in storage yet, so this is the first time a user has used the session system.
    if (!verificationKey) {
      return true;
    }

    if (verificationKey !== doubleHash) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};

export default isStorageKeyValid;
