import { Counsellor } from '../../../graphql/genie-api-types';

export interface ResearchSession {
  requestIds: string[];
  counsellor: Pick<Counsellor, 'id'|'name'>;
}

export interface ResearchState {
  session: ResearchSession|null;
}

export enum ResearchTypes {
  START_SESSION = '@@research/START_SESSION',
}
