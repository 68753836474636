import * as React from 'react';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import ClinicListTable from '../../containers/clinics/list/ClinicListTable';
import RouteButton from '../../components/widgets/RouteButton';
import Screen from '../../components/widgets/Screen';

const ClinicIndex = () => {
  return (
    <Screen
      className="relative"
    >
      <ScreenTitle
        title="Clinics"
        action={(
          <div>
            <RouteButton
              query={{ form: 'ClinicCreate' }}
              size="sm"
            >
              + Create Clinic
          </RouteButton>

          </div>
        )}
      />
      <ClinicListTable />
    </Screen>
  );
};

export default ClinicIndex;
