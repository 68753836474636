import { readFileSync } from 'fs';
import Handlebars from '../../../../report/Handlebars';

const Top = readFileSync(__dirname + '/_base/top.handlebars', 'utf8');
const Bottom = readFileSync(__dirname + '/_base/bottom.handlebars', 'utf8');

const Section = readFileSync(__dirname + '/_components/section.handlebars', 'utf8');
const TwoColSection = readFileSync(__dirname + '/_components/two-col-section.handlebars', 'utf8');
const DiseaseTable = readFileSync(__dirname + '/_components/disease-table.handlebars', 'utf8');
const DiseaseHeader = readFileSync(__dirname + '/_components/disease-header.handlebars', 'utf8');
const Intro = readFileSync(__dirname + '/_components/intro.handlebars', 'utf8');
const ClientDetails = readFileSync(__dirname + '/_components/client-details.handlebars', 'utf8');
const PageTitle = readFileSync(__dirname + '/_components/page-title.handlebars', 'utf8');

const CancerNAD = readFileSync(__dirname + '/_cancer/cancer-nad.handlebars', 'utf8');

export class pdfReportTemplates {
  static get Intro() {
    return Handlebars.compile(Intro);
  }
  static get Section() {
    return Handlebars.compile(Section);
  }
  
  static get TwoColSection() {
    return Handlebars.compile(TwoColSection);
  }
  
  static get DiseaseTable() {
    return Handlebars.compile(DiseaseTable);
  }
  
  static get DiseaseHeader() {
    return Handlebars.compile(DiseaseHeader);
  }
  
  static get ClientDetails() {
    return Handlebars.compile(ClientDetails);
  }
  
  static get PageTitle() {
    return Handlebars.compile(PageTitle);
  }
}

export class pdfReportBaseTemplates {
  static get Top() {
    return Handlebars.compile(Top);
  }
  static get Bottom() {
    return Handlebars.compile(Bottom);
  }
}

export class pdfCancerTemplates {
  static get CancerNAD() {
    return Handlebars.compile(CancerNAD);
  }
}