import * as React from 'react';
import JourneyTimeline from '../JourneyTimeline';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';


const JourneyInfoTimeline = () => {
  const { journey } = React.useContext(JourneyInfoContext);

  return (
    <div className="px-20 mt-30">
      <JourneyTimeline
        journeyHistory={journey.history}
        kits={journey.kits}
      />
    </div>
  );
}

export default JourneyInfoTimeline;
