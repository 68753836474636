import * as React from 'react';
import { MemberSearchResult } from '../MemberSearchResults';
import MemberSearchAutocomplete from '../members/MemberSearchAutocomplete';
import GenieQuery from '../../base/GenieQuery';
import gql from 'graphql-tag';
import { Journey, Product, Member, QueryListJourneysArgs, ProductVariant } from '../../../../graphql/genie-api-types';
import Spinner from '../../../components/widgets/Spinner';
import { getProductLabel } from '../../../../utilities/journeys/products';
import { JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';

interface JourneySearchFieldProps {
  showUnpaid?: boolean;
  onSelectJourney(journeyId: string): void;
}

interface JourneyResult extends Pick<Journey, 'id' | 'state'> {
  request: {
    product: Product;
    productVariant: ProductVariant;
    journeys: {
      id: string;
      member: Pick<Member, 'id' | 'name' | 'dateOfBirth' | 'sex'>;
    }[];
  };
}

interface QueryResult {
  listJourneys: {
    edges: {
      node: JourneyResult;
    }[];
  };
}

const JourneySearchField = (props: JourneySearchFieldProps) => {
  const { onSelectJourney } = props;
  const [memberResult, setMemberResult] = React.useState(null as MemberSearchResult);
  const [selectedJourneyId, setSelectedJourneyId] = React.useState(null as string);
  
  React.useEffect(() => {
    onSelectJourney(selectedJourneyId);
  }, [selectedJourneyId]);

  return (
    <div>
      <MemberSearchAutocomplete
        onSelect={setMemberResult}
        defaultValue={memberResult}
        placeholder="Search for a member..."
      />
      {memberResult && (
        <div className="mt-20">
          <GenieQuery<QueryResult, QueryListJourneysArgs>
            query={gql`
              query ListJourneys($input: ListJourneysInput!) {
                listJourneys(input: $input) {
                  edges {
                    node {
                      id
                      state
                      request {
                        id
                        product
                        productVariant
                        journeys {
                          id
                          member {
                            id
                            name
                            dateOfBirth
                            sex
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            variables={{
              input: {
                memberId: memberResult.id,
              },
            }}
          >
            {({ data, loading }) => loading ? <Spinner label="Loading journeys" /> : (
              <div>
                {data.listJourneys.edges.map(edge => edge.node).map((journey: JourneyResult) => (
                  <button
                    key={journey.id}
                    onClick={() => setSelectedJourneyId(journey.id)}
                    className={`text-left p-10 mb-5 rounded w-full border ${journey.id === selectedJourneyId ? 'bg-purple text-white' : 'bg-white'}`}
                    type="button"
                  >
                    <span className="font-bold">{getProductLabel(journey?.request?.product, journey?.request?.productVariant)}</span> ({JOURNEY_STATE_LABELS[journey.state]}) - {journey.request.journeys.map(journey => journey.member.name).join(', ')}
                  </button>
                ))}
              </div>
            )}
          </GenieQuery>
        </div>
      )}
    </div>
  );
};

export default JourneySearchField;
