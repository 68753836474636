import * as React from 'react';
import Badge from '../../widgets/Badge';
import { FiClock, FiSearch } from '../../widgets/Icon';
import { JourneyState, Request, Journey, Member, Pregnancy, Counsellor } from '../../../../graphql/genie-api-types';
import CounsellorIcon from '../../widgets/CounsellorIcon';
import JourneyRows from './JourneyRows';
import { getAcuitySearchUrl } from '../../../../utilities/helpers';
import gql from 'graphql-tag';

interface ReportCounsellingJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  request: Required<Pick<Request, 'id'|'product'|'productVariant'|'onHoldDate'>> & {
    initiatingMember?: Pick<Member, 'id'|'name'>;
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    primaryCounsellor?: Pick<Counsellor, 'id'|'name'|'photoURL'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

const getDateBadge = (journey: ReportCounsellingJourney, request: ReportCounsellingJourney['request']) => {
  if (journey.state === JourneyState.WaitingForResultConsult) {
    const initiatingMember = request?.initiatingMember;

    const acuityLink = getAcuitySearchUrl(initiatingMember?.name ?? '');

    return <a href={acuityLink} target="blank"><Badge color="green-lightest" className="text-xs hover:text-green-dark flex items-center"><FiSearch className="mr-5 mb-1" /> <span>Search Acuity</span></Badge></a>;
  }

  return <Badge color="orange-light" className="text-xs">Not booked</Badge>;
};


export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            onHoldDate
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            primaryCounsellor {
              id
              name
              photoURL
            }
            initiatingMember {
              id
              name
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const ReportCounsellingJourneys = () => (
  <JourneyRows<ReportCounsellingJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.RequireResultBooking, JourneyState.WaitingForResultConsult, JourneyState.ReadyToReleaseResults]}
    header={['GC', <span title="Booking Time" key="time"><FiClock /></span>]}
    emptyText="No members awaiting counselling"
    cellsForJourney={journey => [
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor" />,
      getDateBadge(journey, journey.request),
    ]}
  />
);

export default ReportCounsellingJourneys;