import * as React from 'react';
import { Mutation as ApolloMutation } from 'react-apollo';
import { MutationUpdateJourneyStateArgs, Journey, JourneyState, Mutation } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { showToast } from '../../store/toast/actions';
import { JOURNEY_STATE_LABELS } from '../../../utilities/journeys/states';

export type MoveJourneyToStateMutationFunction = (journeyId: Journey['id'], state: JourneyState) => Promise<void>;

interface MoveJourneyToStateProps {
  children(moveToState: MoveJourneyToStateMutationFunction, isLoading: boolean): React.ReactElement<any>;
}

const MoveJourneyToState = (props: MoveJourneyToStateProps) => (
  <ApolloMutation<Pick<Mutation, 'updateJourneyState'>, MutationUpdateJourneyStateArgs>
    mutation={gql`
      mutation UpdateJourneyState($input: UpdateJourneyStateInput!) {
        updateJourneyState(input: $input) {
          journey {
            id
            state
          }
        }
      }
    `}
    refetchQueries={() => {
      return ['ListJourneys'];
    }}
    onError={(error) => {
      showToast('error', 'Uh oh!', error.graphQLErrors[0].message);
    }}
  >
    {(mutate, result) => props.children(async (journeyId, state) => {
      const updateJourneyResult = await mutate({
        variables: {
          input: {
            journeyId,
            state,
          },
        },
        refetchQueries:() => {
          return ['ListJourneys'];
        },
      });

      if (!updateJourneyResult) {
        return;
      }

      const resultJourneyState = updateJourneyResult?.data?.updateJourneyState?.journey?.state;
      if (resultJourneyState === null) {
        return;
      }

      if (resultJourneyState !== state) {
        showToast('error', 'Couldn\'t move journey', `Journey stayed in ${JOURNEY_STATE_LABELS[state]}`);
      }
    }, result.loading)}
  </ApolloMutation>
);

export default MoveJourneyToState;
