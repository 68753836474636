import * as React from 'react';
import { getAcuityAppointment, AppointmentResult } from '../../../../utilities/acuity';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import Button from '../../../components/widgets/Button';
import Select from '../../../components/widgets/Select';
import RequestSearchField from './RequestSearchField';
import gql from 'graphql-tag';
import { Request, Mutation, MutationUpdateMemberArgs, UpdateMemberInput, Member, MutationUpdateRequestArgs, Query, QueryRequestArgs, UpdateRequestInput, Sex, JourneyState, QuerySearchMembersArgs } from '../../../../graphql/genie-api-types';
import moment from 'moment';
import { formatDateOfBirth } from '../../../../utilities/helpers';
import { FaArrowRight, FiRepeat, FiCheckSquare, FiSquare, FiCheck } from '../../../components/widgets/Icon';
import CounsellorSearchAutocomplete, { CounsellorPartial } from '../counsellors/CounsellorSearchAutocomplete';
import { appSyncClient } from '../../../../utilities/appSync';
import { showToast, showLoadingToast, hideToast } from '../../../store/toast/actions';
import { findRequestByEmail } from '../../../../utilities/request/findRequestByEmail';
import DoctorClinicSearchAutocomplete from '../doctor-clinic/DoctorClinicSearchAutocomplete';
import { ORDERED_JOURNEY_STATES } from '../../../../utilities/journeys/states';
import FormCheck from '../../../components/widgets/form/FormCheck';
import updateJourneyState from '../../requests/journey/updateJourneyState';
import PrivateContent from '../../../components/widgets/PrivateContent';
import { SEARCH_MEMBER_QUERY } from '../members/MemberAcuitySyncBadge';
import { DoctorClinicPartial } from '../../../../utilities/doctorClinic';

interface RequestSyncWithAcuityProps {
  acuityAppointmentId: number;
}

const preTestStateIndex = ORDERED_JOURNEY_STATES.indexOf(JourneyState.RequirePretestBooking);

type MemberData = Pick<Member, 'name' | 'email' | 'dateOfBirth' | 'phoneNumber' | 'sex'>;

interface GenieMemberData extends MemberData {
  memberId: string;
  sex: Sex;
}

interface MemberSyncPair {
  genieVersion?: GenieMemberData;
  acuityVersion: MemberData;
}

interface Field {
  name: keyof MemberData;
  label: string;
}
const fields: Field[] = [
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'email',
    label: 'Email'
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth'
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number'
  },
  {
    name: 'sex',
    label: 'Sex assigned at birth',
  },
];

const requestQuery = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      product
      primaryCounsellor {
        id
        name
      }
      referringDoctorClinic {
        id
        doctor {
          title
          nameFirst
          nameMiddle
          nameLast
        }
        clinic {
          name
        }
      }
      notes {
        date
        username
        text
      }
      journeys {
        id
        state
        member {
          id
          name
          phoneNumber
          email
          dateOfBirth
          sex
        }
      }
    }
  }
`;

type SyncOptions = [{ [key: string]: boolean }, { [key: string]: boolean }];
type SyncOverrides = [{ [key: string]: string }, { [key: string]: string }];

const RequestSyncWithAcuity = (props: RequestSyncWithAcuityProps) => {
  const [acuityAppointment, setAcuityAppointment] = React.useState<AppointmentResult>(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [request, setRequest] = React.useState<Request>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [shouldFlipMembers, setShouldFlipMembers] = React.useState(false);
  const [syncOptions, setSyncOptions] = React.useState<SyncOptions>([{}, {}]);
  const [syncOverrides, setSyncOverrides] = React.useState<SyncOverrides>([{}, {}]);
  const [bookForPretest, setBookForPretest] = React.useState(false);

  const [primaryCounsellor, setPrimaryCounsellor] = React.useState<CounsellorPartial>(null);
  const [referringDoctorClinic, setReferringDoctorClinic] = React.useState<DoctorClinicPartial>(null);

  const syncPairs = React.useMemo(() => {
    let acuityMemberData: MemberData[] = [];
    const genieMemberData: GenieMemberData[] = [];

    request?.journeys?.filter(journey => journey.member).forEach(journey => {
      genieMemberData.push({
        memberId: journey.member.id,
        name: journey.member.name,
        dateOfBirth: formatDateOfBirth(journey.member.dateOfBirth),
        phoneNumber: journey.member.phoneNumber,
        email: journey.member.email,
        sex: journey.member.sex,
      });
    });

    if (acuityAppointment) {
      acuityMemberData.push({
        name: acuityAppointment.name,
        dateOfBirth: formatDateOfBirth(moment(acuityAppointment.dob, 'DD/MM/YYYY')),
        phoneNumber: acuityAppointment.phone,
        email: acuityAppointment.email,
      });
    }

    if (acuityAppointment && (acuityAppointment.partnerEmail || acuityAppointment.partnerName)) {
      acuityMemberData.push({
        name: acuityAppointment.partnerName,
        dateOfBirth: formatDateOfBirth(moment(acuityAppointment.partnerDob, 'DD/MM/YYYY')),
        phoneNumber: acuityAppointment.partnerPhone,
        email: acuityAppointment.partnerEmail,
      });
    }

    if (shouldFlipMembers) {
      acuityMemberData = acuityMemberData.reverse();
    }

    return acuityMemberData?.map((acuityMember, index) => ({
      acuityVersion: acuityMember,
      genieVersion: genieMemberData?.[index],
    })) as MemberSyncPair[];
  }, [shouldFlipMembers, request]);

  const onSetRequest = React.useCallback((request: Request) => {
    setRequest(request);
    setReferringDoctorClinic(request?.referringDoctorClinic as DoctorClinicPartial);
    setPrimaryCounsellor(request?.primaryCounsellor);
  }, [setReferringDoctorClinic, setPrimaryCounsellor, setRequest]);

  const loadRequest = async (requestId: string) => {
    setIsLoading(true);
    const requestRequest = await appSyncClient.query<Pick<Query, 'request'>, QueryRequestArgs>({
      query: requestQuery,
      variables: {
        id: requestId,
      },
      fetchPolicy: 'network-only',
    });
    const request = requestRequest?.data?.request;
    onSetRequest(request);
    setIsLoading(false);
  }

  const init = React.useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const appointment = await getAcuityAppointment(props.acuityAppointmentId);
      setAcuityAppointment(appointment);
      const defaultGenieRequest = await findRequestByEmail(appointment.email, appointment.partnerEmail);
      if (defaultGenieRequest.matchType !== 'none' && defaultGenieRequest.match?.id) {
        await loadRequest(defaultGenieRequest.match.id);
      }
    } catch (e) {
      setErrorMessage(e.message);
    } finally {
      setIsLoading(false)
    }
  }, []);

  React.useEffect(() => {
    init();
  }, [props.acuityAppointmentId]);

  const toggleSyncOption = React.useCallback((syncPairIndex, fieldName) => {
    const options = syncOptions.slice(0);
    options[syncPairIndex][fieldName] = !options[syncPairIndex][fieldName];
    setSyncOptions(options as SyncOptions);
  }, [setSyncOptions, syncOptions]);

  const onExecuteSync = React.useCallback(async () => {
    setIsLoading(true);
    showLoadingToast('acuity-sync', 'Syncing', 'Syncing members and requests from data');
    try {
      await syncPairs?.map(async (syncPair, index) => {
        if (!syncPair.genieVersion?.memberId) {
          return;
        }

        const syncFields = Object.keys(syncOptions[index]).filter((syncKey) =>
          !!syncOptions[index][syncKey]);

        if (syncFields.length === 0) {
          return;
        }

        const updateMemberInput: UpdateMemberInput = {
          memberId: syncPair.genieVersion?.memberId,
        };

        syncFields.forEach((fieldName: keyof MemberData) => {
          let newVersion = syncOverrides[index]?.[fieldName] ?? syncPair.acuityVersion[fieldName];
          if (fieldName === 'dateOfBirth') {
            newVersion = moment(newVersion, 'D/MM/YYYY').format('YYYY-MM-DD');
          }
          if (fieldName === 'sex') {
            updateMemberInput[fieldName] = newVersion as Sex;
          }
          else {
            updateMemberInput[fieldName] = newVersion;
          }
        });

        try {
          await appSyncClient.mutate<Mutation['updateMember'], MutationUpdateMemberArgs>({
            mutation: gql`
              mutation UpdateMember($input: UpdateMemberInput!) {
                updateMember(input: $input) {
                  member {
                    id,
                    name,
                    nickname,
                    email,
                    dateOfBirth,
                    phoneNumber,
                    sex,
                  }
                }
                }
            `,
            update: (store, queryResult) => {
              const memberResult = queryResult.data.updateMember.member as Pick<Member, 'id'|'email'>;
              // Read the data from our cache for this query.
              const variables = {
                term: memberResult.email,
              };
              const data = store.readQuery<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>({
                query: SEARCH_MEMBER_QUERY,
                variables,
              });
              // Add our comment from the mutation to the end.
              data.searchMembers = [memberResult, ...data.searchMembers];
              // Write our data back to the cache.
              store.writeQuery({ query: SEARCH_MEMBER_QUERY, data, variables });
            },
            variables: {
              input: updateMemberInput,
            }
          });
          showToast('success', `Synced ${syncPair.acuityVersion.name}`, Object.values(updateMemberInput).join(', '));
        } catch (e) {
          showToast('error', 'Error saving member', e.message, {
            timeOut: 0,
            showCloseButton: true,
          });
        }
      });

      const updateRequestPayload: Omit<UpdateRequestInput, 'requestId'> = {};

      if (primaryCounsellor) {
        updateRequestPayload.primaryCounsellorId = primaryCounsellor.id;
      }
      if (referringDoctorClinic) {
        updateRequestPayload.referringDoctorClinicId = referringDoctorClinic.id;
      }

      if (Object.keys(updateRequestPayload).length > 0) {
        await appSyncClient.mutate<Mutation['updateRequest'], MutationUpdateRequestArgs>({
          mutation: gql`
            mutation UpdateRequest($input: UpdateRequestInput!) {
              updateRequest(input: $input) {
                request {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              requestId: request.id,
              ...updateRequestPayload,
            },
          },
        });
      }
    } catch (e) {
      showToast('error', 'Error updating request', e.message);
    }

    if (bookForPretest) {
      try {
        await request.journeys?.map(journey => {
          if (ORDERED_JOURNEY_STATES.indexOf(journey.state) <= preTestStateIndex) {
            return updateJourneyState(journey.id, JourneyState.WaitingForPretestConsult);
          }
        });
      } catch (e) {
        showToast('error', 'Couldn\'t book journeys for pre-test', e.message);
      }
    }

    loadRequest(request.id);
    setIsLoading(false);
    hideToast('acuity-sync');
    showToast('success', 'Update complete', 'Finished update members and requests');
  }, [request, shouldFlipMembers, syncPairs, syncOptions, primaryCounsellor, setIsLoading, syncOverrides, referringDoctorClinic, bookForPretest]);

  const onAcuityVersionChange = React.useCallback((index, fieldName, fieldValue: string) => {
    const currentOverrides = syncOverrides.slice(0) as SyncOverrides;
    currentOverrides[index] = { ...currentOverrides[index] };
    currentOverrides[index][fieldName] = fieldValue;
    setSyncOverrides(currentOverrides);
  }, [syncOverrides, setSyncOverrides]);

  const isBookedForPreTest = React.useMemo(() => {
    return request?.journeys
      .filter(journey => ORDERED_JOURNEY_STATES.indexOf(journey.state) > preTestStateIndex)
      .length === request?.journeys.length;
  }, [request]);

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      {errorMessage && <div className="text-red font-bold">{errorMessage}</div>}
      {acuityAppointment && (
        <div>
          <div>
            <h2 className="font-bold mb-10 text-lg">Acuity appointment: {props.acuityAppointmentId}</h2>
            <div className="mb-10 font-bold">{acuityAppointment.type}</div>
            <div className="mb-10">{acuityAppointment.counsellor}, {acuityAppointment.date.calendar()}</div>
          </div>
          <RequestSearchField
            onSelectRequest={onSetRequest}
            requestQuery={requestQuery}
            defaultRequest={request}
          />
          {request && (
            <div className="my-20">
              <h3 className="font-bold mb-5">Notes</h3>
              {request?.notes?.length > 0 ? (
                <ul className="list-disc pl-20 mb-20">
                  {request?.notes?.map(note => (
                    <li key={note.date} className="py-10">
                      <PrivateContent>{note.text}</PrivateContent>
                    </li>
                  ))}
                </ul>
              ) : <div className="mb-20 italic">No notes found.</div>}
              <h3 className="font-bold mb-5">Counsellor</h3>
              <CounsellorSearchAutocomplete
                onSelect={(counsellorPartial) => {
                  setPrimaryCounsellor(counsellorPartial);
                }}
                defaultValue={primaryCounsellor}
              />
              <h3 className="font-bold mt-20 mb-5">Doctor</h3>
              <DoctorClinicSearchAutocomplete
                onSelect={(doctorPartial) => {
                  setReferringDoctorClinic(doctorPartial);
                }}
                defaultValue={referringDoctorClinic}
              />
            </div>
          )}
          {syncPairs?.map((syncPair, index) => (
            <div className="mt-20" key={index}>
              {index === 1 && <Button onClick={() => setShouldFlipMembers(!shouldFlipMembers)} size="sm" variant="link" className="mb-20"><FiRepeat /> Flip members</Button>}
              <h3 className="font-bold">Member {index + 1} details</h3>
              <div className="mt-10 bg-white px-10 text-sm">
                <div className="flex w-full py-10 border-b">
                  <div className="flex-1 font-bold">
                    Genie version
                  </div>
                  <div className="text-white">
                    <FaArrowRight className="mx-10" />
                  </div>
                  <div className="flex-1 font-bold">
                    Acuity version
                    </div>
                </div>
                {fields?.map(field => (
                  <div key={field.name} className={`flex w-full py-10 border-b items-center ${!syncOptions?.[index]?.[field.name] ? 'opacity-25' : ''}`}>
                    <div className="flex-1 w-1/3 break-words">
                      <PrivateContent>{syncPair.genieVersion?.[field.name] || '<<Nothing in Genie>>'}</PrivateContent>
                    </div>
                    <div className="text-grey-dark">
                      <FaArrowRight className="mx-10" />
                    </div>
                    <div className="flex-1 w-1/3">
                      {field.name === 'sex' ? (
                        <Select options={[
                          {
                            value: Sex.Male,
                            label: Sex.Male,
                          },
                          {
                            value: Sex.Female,
                            label: Sex.Female,
                          },
                        ]}
                          hasBlankValue
                          onChange={(value: string) => onAcuityVersionChange(index, field.name, value)}
                          selectedValue={syncOverrides[index]?.[field.name] ?? syncPair.genieVersion?.[field.name]}
                        />
                      ) : (
                          <PrivateContent>
                            <input
                              type="text"
                              value={syncOverrides[index]?.[field.name] ?? syncPair.acuityVersion[field.name]}
                              onChange={(e) => onAcuityVersionChange(index, field.name, e.target.value)}
                              placeholder="<<Nothing in Acuity>>"
                            />
                          </PrivateContent>
                        )}
                    </div>
                    <button onClick={() => toggleSyncOption(index, field.name)}>
                      {syncOptions?.[index]?.[field.name] ? (
                        <FiCheckSquare />
                      ) : (
                          <FiSquare />
                        )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {isBookedForPreTest ? (
        <div className="mt-20 flex items-center text-green-dark font-bold">
          <FiCheck /> <span className="ml-5">Booked for pre-test</span>
        </div>
      ) : (
          <FormCheck
            type="checkbox"
            label="Book for pre-test"
            name="booked-for-pretest"
            className="mt-20"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookForPretest(e.target.checked)}
          />
        )}

      <div className="mt-20">
        <Button onClick={onExecuteSync}>Sync</Button>
      </div>
    </div>
  );
};

export default RequestSyncWithAcuity;