import * as React from 'react';
import BaseForm, { BaseFormProps } from '../../widgets/BaseForm';
import FormInput from '../../widgets/form/FormInput';

interface SummaryFormProps {
  initialValues: BaseFormProps['initialValues'];
  onSubmit: BaseFormProps['onSubmit'];
  fieldName: string;
}

const getFieldExtraProps = (fieldName: string): { [key: string]: any } => {
  switch (fieldName) {
    case 'reproductiveRisk':
      return {
        as: 'select',
        options: ['-', 'High', 'Low', 'Increased'].map(value => ({
          value,
          label: value,
        })),
      };

    default:
      return {
        type: 'text',
        as: 'textarea',
        rows: 10,
      };
  }
};

const SummaryForm = (props: SummaryFormProps) => (
  <BaseForm
    initialValues={props.initialValues}
    onSubmit={props.onSubmit}
    submitButtonLabel="Save"
  >
    {({
      handleChange,
      values,
      setFieldValue,
    }) => (
      <div>
        <FormInput
          type="text"
          name="value"
          value={values.value}
          onChange={handleChange}
          autoFocus
          className="w-100"
          setFieldValue={setFieldValue}
          useMacroSelector={props.fieldName !== 'reproductiveRisk'}
          {...getFieldExtraProps(props.fieldName)}
        />
      </div>
      )}
  </BaseForm>
);

export default SummaryForm;
