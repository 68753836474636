import * as React from 'react';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { Counsellor, ListAdminsInput, Query } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import * as yup from 'yup';

interface CounsellorFromProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  isNicknameEdited?: boolean;
}

interface CounsellorFormInput extends FormInputProps {
  name: keyof Counsellor | 'adminId';
}

const LIST_ADMINS_QUERY = gql`
  query listAdmins($input: ListAdminsInput!) {
    listAdmins(input: $input) {
      id
      email
      counsellorDetails {
        id
        name
      }
    }
  }
`;

const CounsellorForm = (props: CounsellorFromProps) => {
  const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = props;

  const adminList = useQuery<Pick<Query, 'listAdmins'>, { input: ListAdminsInput }>(LIST_ADMINS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        hasPublicDetails: false,
        currentAdminId: initialValues.adminId || null
      }
    },
  });
  const adminArray = adminList.data?.listAdmins || [];

  const onInput = (event: React.FormEvent<any>, setFieldValue: (field: string, value: any) => void) => {
    if (event.type !== 'input') {
      return;
    }

    const formInput: HTMLInputElement = event.target as HTMLInputElement;
    if (formInput.name === 'name' && formInput.value.length > 0) {
      const firstName = formInput.value.split(' ')[0];
      setFieldValue('nickname', firstName);
    }
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    nickname: yup.string().required(),
  });

  const formItems: Pick<CounsellorFormInput, 'label' | 'name' | 'type' | 'as' | 'options'>[] = [
    {
      label: 'Full name',
      name: 'name',
    },
    {
      label: 'Preferred name',
      name: 'nickname',
    },
    {
      label: 'Admin email',
      name: 'adminId',
      as: 'select',
      options: [
        {
          label: 'Select an option',
          value: '',
        },
        ...adminArray.map(admin => ({
          value: admin.id,
          label: admin.email,
        }))
      ],
    },
  ];

  return (
    <BaseForm
      initialValues={initialValues}
      submitButtonLabel="Save"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        values,
        setFieldValue,
        errors
      }) => (
        <div>
          {
            formItems.map((formItem, index) =>
              <FormInput
                {...formItem}
                value={values[formItem.name]}
                onChange={handleChange}
                key={formItem.name}
                autoFocus={index === 0}
                onInput={event => onInput(event, setFieldValue)}
                setFieldValue={setFieldValue}
                helpText={errors[formItem.name]}
              />
            )
          }
        </div>
      )}
    </BaseForm>
  );
}
export default CounsellorForm;

