import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import Route from '../../../base/Route';
import SummaryView from './SummaryView';
import SummaryEdit from './SummaryEdit';

const Summary = () => (
  <div>
    <Route path={PATHS.REPORTS_SUMMAY} component={SummaryView} />
    <Route path={PATHS.REPORTS_SUMMARY_EDIT} component={SummaryEdit} />
  </div>
);

export default Summary;
