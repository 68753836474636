import * as React from 'react';
import moment from 'moment';
import JourneyTimeline from '../JourneyTimeline';
import gql from 'graphql-tag';
import { Journey, JourneyLog, Kit, QueryJourneyArgs, Request } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { useQuery } from 'react-apollo';

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    history?: Pick<JourneyLog, 'toState'|'date'>[];
    request?: Pick<Request, 'id' | 'dateCreated'>;
    kits?: Pick<Kit, 'id' | 'dateArrivedAtEugene' | 'dateShippedToLab' | 'dateShippedToMember' | 'memberRegisteredKitOnDate' | 'recollectionRequestedDate' | 'registrationNumber'>[];
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      request {
        id
        dateCreated
      }
      history {
        toState
        date
      }
      kits {
        id
        dateArrivedAtEugene
        dateShippedToLab
        dateShippedToMember
        memberRegisteredKitOnDate
        recollectionRequestedDate
        registrationNumber
      }
    }
  }
`;

const MemberShowTimeline = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  
  const journey = journeyQuery?.data?.journey;
  
  if (!journey) {
    return null;
  }

  const startDate = journey.request.dateCreated;

  return (
    <div>
      <div className="flex flex-row flex-nowrap justify-between">
        <div className="text-sm text-grey font-nm mb-8 flex mt-8">Timeline</div>

        <span className="text-sm pt-8 pb-6 px-8 bg-grey-light flex rounded">
          {`${moment().diff(startDate, 'weeks')}w total`}
        </span>
      </div>
      <JourneyTimeline journeyHistory={journey.history} kits={journey.kits} /> 
    </div>
  );
};

export default MemberShowTimeline;