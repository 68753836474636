import * as React from 'react';
import moment from 'moment';
import StatsForm from '../../components/stats/StatsForm';
import Tabs from '../../components/widgets/Tabs';
import KitsStats from './KitsStats';
import OpsEfficiency from './OpsEfficiency';
import PreTestStats from './PreTestStats';
import PurchaseStats from './PurchaseStats';
import RecollectionStats from './RecollectionStats';
import ReportStats from './ReportStats';
import RequestStats from './RequestStats';
import SLAStats from './SLAStats';
import Screen from '../../components/widgets/Screen';

export interface Filters {
  fromDate: string;
  toDate: string;
}

export interface StatsProps {
  fromDate: moment.Moment;
  toDate: moment.Moment;
}

type StatsMode = '' | 'Kits' | 'Reports' | 'Requests' | 'Purchases' | 'Ops efficiency' | 'SLA' | 'Recollections' | 'Pre-test';

const Stats = () => {
  const [filters, setFilters] = React.useState<Filters>({
    fromDate: moment()
      .subtract(7, 'd')
      .format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const fromDate = React.useMemo(() => {
    return moment(filters.fromDate);
  }, [filters.fromDate]);

  const toDate = React.useMemo(() => {
    return moment(filters.toDate).endOf('day');
  }, [filters.toDate]);

  const statProps: StatsProps = {
    fromDate,
    toDate,
  };

  return (
    <Screen
      screenTitleProps={{
        title: 'Stats',
      }}
    >
      <div>
        <StatsForm onChangeFilters={setFilters} defaultFilters={filters} />
      </div>
      <Tabs
        tabs={
          [
            {
              name: '',
              label: 'Home',
            },
            {
              name: 'Kits',
              label: 'Kits',
            },
            {
              name: 'Reports',
              label: 'Reports',
            },
            {
              name: 'Requests',
              label: 'Requests',
            },
            {
              name: 'Purchases',
              label: 'Purchases',
            },
            {
              name: 'Recollections',
              label: 'Recollections',
            },
            {
              name: 'Ops efficiency',
              label: 'Ops efficiency',
            },
            {
              name: 'SLA',
              label: 'SLAs',
            },
            {
              name: 'Pre-test',
              label: 'Pre-test',
            },
          ] as { name: StatsMode; label: string }[]
        }
        defaultTab=""
      >
        {(tabName: StatsMode) => {
          let content = null;
          switch (tabName) {
            case '':
              content = <div>Pick your dates and select your stats using the tab bar above</div>;
              break;

            case 'Kits':
              content = <KitsStats {...statProps} />;
              break;

            case 'Reports':
              content = <ReportStats {...statProps} />;
              break;

            case 'Requests':
              content = <RequestStats {...statProps} />;
              break;

            case 'Purchases':
              content = <PurchaseStats {...statProps} />;
              break;

            case 'Ops efficiency':
              content = <OpsEfficiency {...statProps} />;
              break;

            case 'SLA':
              content = <SLAStats {...statProps} />;
              break;

            case 'Recollections':
              content = <RecollectionStats {...statProps} />;
              break;

            case 'Pre-test':
              content = <PreTestStats {...statProps} />;
          }

          return <div className="py-20">{content}</div>;
        }}
      </Tabs>
    </Screen>
  );
};

export default Stats;
