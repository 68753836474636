import { appSyncClient } from '../../../../utilities/appSync';
import { Mutation, JourneyState, MutationUpdateJourneyStateArgs, Journey } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';

type UpdateJourneyStateResult = {
  updateJourneyState: {
    journey: Pick<Journey, 'id' | 'state'>;
  };
}

const updateJourneyState = async (journeyId: string, state: JourneyState): Promise<ApolloQueryResult<UpdateJourneyStateResult>> => {
  const response = await appSyncClient.mutate<Pick<Mutation, 'updateJourneyState'>, MutationUpdateJourneyStateArgs>({
    mutation: gql`
      mutation UpdateJourneyState($input: UpdateJourneyStateInput!) {
        updateJourneyState(input: $input) {
          journey {
            id
            state
          }
        }
      }
    `,
    variables: {
      input: {
        journeyId,
        state,
      },
    },
    refetchQueries:() => {
      return ['ListJourneys'];
    },
  });

  return response;
}

export default updateJourneyState;
