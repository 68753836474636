import * as React from 'react';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { Doctor, Sex } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';

interface DoctorFromProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
}

interface DoctorFormInput extends FormInputProps {
  name: keyof Doctor;
}

const formItems: Pick<DoctorFormInput, 'label' | 'name' | 'type' | 'as' | 'options'>[] = [
  {
    label: 'First name',
    name: 'nameFirst',
  },
  {
    label: 'Middle name',
    name: 'nameMiddle',
  },
  {
    label: 'Last name',
    name: 'nameLast',
  },
  {
    label: 'Preferred name',
    name: 'nickname',
  },
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Personal email',
    name: 'personalEmail',
  },
  {
    label: 'Personal phone',
    name: 'personalPhone',
    type: 'tel',
  },
  {
    label: 'Mobile phone',
    name: 'personalMobile',
    type: 'tel',
  },
  {
    label: 'Practitioner ID',
    name: 'practitionerId',
  },
  {
    label: 'Registration number',
    name: 'registrationNumber',
  },
  {
    label: 'Sex',
    name: 'gender',
    as: 'select',
    options: [
      {
        value: '',
        label: '-',
      },
      {
        value: Sex.Male,
        label: 'Male',
      },
      {
        value: Sex.Female,
        label: 'Female',
      },
    ],
  },
];

interface DoctorFormState {
  isNicknameEdited: boolean;
}

class DoctorFrom extends React.PureComponent<DoctorFromProps, DoctorFormState> {
  state: Readonly<DoctorFormState> = {
    isNicknameEdited: false,
  };

  static getDerivedStateFromProps(props: DoctorFromProps, state: DoctorFormState) {
    if (props.initialValues['nickname']) {
      return {
        ...state,
        isNicknameEdited: true,
      };
    }
    return state;
  }

  onInput(event: React.FormEvent<any>, setFieldValue: (field: string, value: any) => void) {
    const { isNicknameEdited } = this.state;
    if (event.type !== 'input' || isNicknameEdited) {
      return;
    }

    const formInput: HTMLInputElement = event.target as HTMLInputElement;
    if (formInput.name === 'name' && formInput.value.length > 0) {
      const firstName = formInput.value.split(' ')[0];
      setFieldValue('nickname', firstName);
    } else if (formInput.name === 'nickname' && formInput.value.length > 0) {
      this.setState({
        isNicknameEdited: true,
      });
    }
  }

  render() {
    const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = this.props;

    return (
      <BaseForm
        initialValues={initialValues}
        submitButtonLabel="Save"
        hasCancel={!!onCancel}
        onCancel={onCancel}
        errorMessage={errorMessage}
        isLoading={isLoading}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          values,
          setFieldValue,
        }) => (
          <div>
            {
              formItems.map((formItem, index) =>
                <React.Fragment key={formItem.name}>
                  <FormInput
                    {...formItem}
                    value={values[formItem.name]}
                    onChange={handleChange}
                    key={formItem.name}
                    autoFocus={index === 0}
                    onInput={event => this.onInput(event, setFieldValue)}
                    setFieldValue={setFieldValue}
                  />
                </React.Fragment>
              )
            }
          </div>
        )}
      </BaseForm>
    );
  }
}

export default DoctorFrom;
