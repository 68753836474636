import { Extract } from '@eugenelabs/types--research-extract';
import { AmplifyAuth } from './amplify/auth';
import config from './config';

type APIName = 'shopify'|'acuity'|'research-db';

type ShopifyPayload = {
  apiPath: string;
}

type AcuityPayload = {
  apiPath: string;
}

type ResearchDBPayload = {
  report: Partial<Extract>;
};

type APIPayload = ShopifyPayload | AcuityPayload | ResearchDBPayload;

export const fetchFromApiBridge = async <T>(apiName: APIName, payload: APIPayload): Promise<T> => {
  const amplifySession = await AmplifyAuth.currentSession();
  const cognitoToken = amplifySession.getIdToken().getJwtToken();
  
  return await fetch(`${config('BRIDGE_API')}${apiName}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Authorization: config('BRIDGE_API_TOKEN_OVERRIDE') ?? cognitoToken,
    },
  }).then(response => response.json());
}