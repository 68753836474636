import * as React from 'react';
import { Journey } from '../../../graphql/genie-api-types';
import Badge from '../widgets/Badge';

interface MemberlessPlaceholderProps {
  className?: string;
  journeyId?: Journey['id'];
}

const MemberlessPlaceholder = (props: MemberlessPlaceholderProps) => {
  return (
    <Badge color="orange-light" className={props.className}>
      Memberless journey
    </Badge>
  );
};

export default MemberlessPlaceholder;
