import * as React from 'react';
import { Journey } from '../../../../graphql/genie-api-types';
import { FiX, FiCheck } from '../../widgets/Icon';
import { hasShippedKits } from '../../../../utilities/journeys/selectors';

interface ShippedKitsCheckProps {
  journey: Pick<Journey, 'kits'>;
};

const ShippedKitsCheck = ({ journey }: ShippedKitsCheckProps) =>
  hasShippedKits(journey) ? <span className="text-xs text-green"><FiCheck /></span> : <FiX color="grey" />

export default ShippedKitsCheck;
