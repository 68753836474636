import * as React from 'react';
import { Request } from '../../../graphql/genie-api-types';
import RequestNotes from '../../graphql/containers/requests/RequestNotes';
import CreateRequestNote from '../../graphql/containers/requests/CreateRequestNote';
import NoteForm from '../../components/notes/NoteForm';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import NoteComponent from '../../components/widgets/Note';

interface MemberShowRequestNotesProps {
  requestId: Request['id'];
};

const MemberShowRequestNotes = (props: MemberShowRequestNotesProps) => {
  const { requestId } = props;

  return (
    <div className="relative">
      <div className="text-sm text-grey font-nm mb-8 mt-8">Notes</div>
      <CreateRequestNote
        requestId={requestId}
      >
        {(mutation, isLoading) => (
          <div className="border-b py-10 my-5">
            <NoteForm
              isLoading={isLoading}
              onSubmit={({ text, isImportant }) => mutation(text, isImportant)}
            />
          </div>
        )}
      </CreateRequestNote>
      <RequestNotes requestId={requestId}>
        {(notes, isLoading: boolean) => (
          <div className="relative">
            {isLoading ? <LoadingOverlay label="Refreshing notes..." /> : null}
            {notes.map((note => <NoteComponent note={note} key={note.date} />))}
          </div>
        )}
      </RequestNotes>
    </div>
  );
};

export default MemberShowRequestNotes;
