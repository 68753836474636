import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import FilterForm from '../../components/widgets/FilterForm';
import SessionSelector from '../../containers/report/SessionSelector';
import { navigate } from '../../store/router/actions';
import { PATHS, ReportMode } from '../../store/router/types';
import { defaultReportState } from '../../store/report';
import EncryptedLocalStorage from '../../containers/storage/EncryptedLocalStorage';
import ReportsList from '../../graphql/containers/reports/ReportsList';
import Table from '../../components/widgets/Table';
import moment from 'moment';
import PregnancyIcon from '../../components/widgets/PregnancyIcon';
import BadgeList from '../../components/widgets/BadgeList';
import Screen from '../../components/widgets/Screen';
import Button from '../../components/widgets/Button';
import checkReportMismatches from '../../../utilities/report/checkReportMismatches';
import { isDev, formatMemberName } from '../../../utilities/helpers';

interface ChooseReportProps {
  onChooseReport: (mode: ReportMode, id: string) => void;
}

interface ChooseReportState {
  mode: ReportMode;
}

class ChooseReport extends React.PureComponent<ChooseReportProps, ChooseReportState> {
  state: Readonly<ChooseReportState> = {
    mode: 'remote',
  };

  onSelectRemote = () => {
    this.setState({ mode: 'remote' });
  }

  onSelectLocalStorage = () => {
    this.setState({ mode: 'localStorage' });
  }

  onChooseReport = (sessionId: string) => {
    const { mode } = this.state;
    const { onChooseReport } = this.props;
    onChooseReport(mode, sessionId);
  }

  renderSession() {
    return (
      <EncryptedLocalStorage>
        <div>
          <SessionSelector
            defaultState={defaultReportState}
            onSelectSession={this.onChooseReport}
          />
        </div>
      </EncryptedLocalStorage>
    );
  }

  renderRemote() {
    return (
      <div>
        <ReportsList>
          {(reports, setFilters, isLoading, reloadData) => (
            <div>
              <FilterForm
                options={[
                  {
                    type: 'gene',
                    name: 'gene',
                    placeholder: 'Gene',
                  },
                  {
                    type: 'counsellorId',
                    name: 'primaryCounsellorId',
                    placeholder: 'Counsellor',
                  },
                  {
                    type: 'boolean',
                    name: 'hasAffectedGenes',
                    placeholder: 'Affected genes',
                  },
                  {
                    type: 'boolean',
                    name: 'hasPsuedoGenes',
                    placeholder: 'Pseudo genes',
                  },
                  {
                    type: 'boolean',
                    name: 'isHighRisk',
                    placeholder: 'High risk',
                  },
                ]}
                onPress={setFilters}
              />
              <Table
                header={['Members', 'Author', 'Created', 'Updated']}
                isLoading={isLoading}
                onRowSelect={(requestId: string) => this.onChooseReport(requestId)}
                onRefresh={reloadData}
                rowGroups={reports.map(report => {
                  const memberNames = report.members.map(member => {
                    return formatMemberName(member, report.request);
                  });
                  return ({
                  id: report.requestId,
                  rows: [{
                    id: report.requestId,
                    cells: [
                      <div className="flex" key="members"><BadgeList items={memberNames} /> {report.pregnancy ? <PregnancyIcon pregnancy={report.pregnancy} className="ml-5" /> : null}</div>,
                      <div key="username">{report.username}</div>,
                      <div key="created">{moment(report.dateCreated).format('DD/MM/YYYY')}</div>,
                      <div key="updated">{moment(report.dateUpdated).format('DD/MM/YYYY')}</div>,
                    ],
                  }],
                  })
                })}
              />
            </div>
          )}
        </ReportsList>
      </div>
    );
  }

  renderToolbar() {
    const { mode } = this.state;

    return (
      <div className="flex mb-20">
        <button onClick={this.onSelectRemote} type="button" className={`mr-10 border-b-2 pb-5 focus:outline-none ${mode === 'remote' ? 'text-purple border-purple font-bold' : 'text-purple-light'}`}>
          On the cloud
        </button>
        <button onClick={this.onSelectLocalStorage} type="button" className={`border-b-2 pb-5 focus:outline-none ${mode === 'localStorage' ? 'text-purple border-purple font-bold' : 'text-purple-light'}`}>
          On this computer
        </button>
      </div>
    );
  }

  render() {
    const { mode } = this.state;
    return (
      <Screen
        screenTitleProps={{
          title: 'Reports',
        }}
      >
        {mode === 'remote' ? this.renderRemote() : this.renderSession()}
        {isDev() && (
          <div className="mt-10">
            <Button size="sm" onClick={checkReportMismatches}>Check for mismatches</Button>
          </div>
        )}
      </Screen>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onChooseReport: (mode: ReportMode, id: string) => dispatch(navigate.toReportPath(PATHS.REPORTS_CLIENT_DETAILS, mode, id)),
});

export default connect(null, mapDispatchToProps)(ChooseReport);
