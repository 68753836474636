import * as React from 'react';
import { Member } from '../../../graphql/genie-api-types';
import MemberlessPlaceholder from './MemberlessPlaceholder';

interface MemberOrPlaceholderProps<MemberPartial = Partial<Member>> {
  children: (member: MemberPartial) => React.ReactNode;
  member?: MemberPartial;
}

function MemberOrPlaceholder<MemberPartial>(props: MemberOrPlaceholderProps<MemberPartial>) {
  if (!props.member) {
    return <MemberlessPlaceholder />;
  }
  return (
    <>
      {props.children(props.member)}
    </>
  );
}

export default MemberOrPlaceholder;
