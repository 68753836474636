import { kebabCase } from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import fetch from 'cross-fetch';
import juice from 'juice';

import { generateHtml } from './htmlTemplateGenerator';
import { ReportState } from '../../../app/store/report';
import { AmplifyAuth } from '../../amplify/auth';
import config from '../../config';
import templates from '../templates';
import { Product } from '../../../graphql/genie-api-types';

export type ReportPage = 'intro' | 'disease-description';

export const generateReportHtml = (reportState: ReportState) => {
  const template = templates[reportState.customisation.templateName];
  return generateHtml(reportState, template);
};

export const getReportZip = async (reportState: ReportState, type: 'base64' | 'blob') => {
  const zip = new JSZip();

  const namePrefix = kebabCase(`${reportState.clientDetails.clientA.fullName}-${reportState.clientDetails.clientB.fullName}`);
  const reportHtmlString = generateReportHtml(reportState);
  zip.file('report.html', reportHtmlString);
  
  const reportStyles = await fetch(`/assets/reports/css/report.css`).then(response => response.text());
  
  if (reportState.clientDetails.clientA.affectedGenes.length > 0) {
    const familyAHtmlString = generateHtml(reportState, templates.familyReportA);
    zip.file('family/a.html', juice(familyAHtmlString, {
      extraCss: reportStyles,
    }));
  }
  if (reportState.clientDetails.clientB.affectedGenes.length > 0) {
    const familyBHtmlString = generateHtml(reportState, templates.familyReportB);
    zip.file('family/b.html', juice(familyBHtmlString, {
      extraCss: reportStyles,
    }));
  }

  zip.file(`${namePrefix}-report-state.json`, JSON.stringify(reportState));
  return zip.generateAsync({ type });
};

export const downloadReportAsZip = async (reportState: ReportState) => {
  const zipFileBlob = await getReportZip(reportState, 'blob');
  const namePrefix = kebabCase(`${reportState.clientDetails.clientA.fullName}-${reportState.clientDetails.clientB.fullName}`);

  saveAs(zipFileBlob, `${namePrefix}.zip`);
};

export const downloadReportPDF = async (reportState: ReportState) => {
  const htmlString = generateReportHtml(reportState);
  const fileName = kebabCase(`${reportState.clientDetails.clientA.fullName}-${reportState.clientDetails.clientB.fullName}`);
  
  if (reportState.testInformation.product === Product.Cancer) {
    return generatePDF(htmlString, fileName, { top: "10mm" });
  } else {
    return generatePDF(htmlString, fileName);
  }
};

type Margin = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const generatePDF = async (htmlString: string, fileName: string, margin?: Margin) => {
  const buffer = new Buffer(htmlString);
  const html = buffer.toString('base64');

  const amplifySession = await AmplifyAuth.currentSession();
  const token = await amplifySession.getIdToken().getJwtToken();

  const blob = await fetch(config('PDF_API'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      Accept: 'application/pdf',
    },
    body: JSON.stringify({
      encodedHtml: `base64,/${html}`,
      headerUrl: `${config('ASSET_HOST')}/assets/reports/header.html`,
      footerUrl: `${config('ASSET_HOST')}/assets/reports/footer.html`,
      ...(margin && { margin })
    }),
  })
    .then(resp => resp.blob());

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${fileName}.pdf`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}