import * as React from 'react';
import './styles/screen-title.scss';
import { flatten, difference, zipObject, intersectionWith, isEqual } from 'lodash';
import TableRow from './TableRow';
import Spinner from './Spinner';
import { FiInbox, FiRefreshCw } from './Icon';
import './styles/table.scss';
import DropdownButton, { Action } from './DropdownButton';
import BatchCheckbox, { BatchCheckboxProps } from './BatchCheckbox';

export interface Row {
  cells: (React.ReactNode | string)[];
  memberName?: string;
  actions?: Action[];
  id: string;
  renderExpansion?(row: Row): React.ReactNode;
}

export interface RowGroup {
  isSelectable?: boolean;
  rows: Row[];
  showGroupIndicator?: boolean;
  id: string;
}

export interface HeaderGroup {
  colSpan: number;
  content?: React.ReactNode | string;
}

export interface TableProps {
  header: (React.ReactNode | string)[];
  columnGroups?: HeaderGroup[];
  rowGroups: RowGroup[];
  onRowSelect?(rowId: string): void;
  onActionClick?(rowIds: string[], actionId: string, actionValue: string): void;
  isLoading?: boolean;
  emptyText?: string;
  hasBatchSelect?: boolean;
  disabledRowIds?: { [key: string]: boolean };
  columnClasses?: string[];
  alwaysShowActions?: boolean;
  onRefresh?(): void;
  shouldPersistRefreshButton?: boolean;
  stickyHeaderOffset?: number;
}

export type ColumnGroupProperty = { isStartOfGroup: boolean; isInGroup: boolean; isEndOfGroup: boolean };

interface TableState {
  selectedRowIds: { [key: string]: boolean };
}

class Table extends React.PureComponent<TableProps> {
  static defaultProps = {
    emptyText: 'No results found',
  };

  state: Readonly<TableState> = {
    selectedRowIds: {},
  };

  getSelectableRowIds = () => {
    const { rowGroups } = this.props;
    return flatten(rowGroups.map(rowGroup => rowGroup.rows)).filter(row => row.actions && row.actions.length > 0).map(row => row.id);
  }

  getAreAllRowsSelected = () => {
    const { selectedRowIds } = this.state;
    const allRowIds = this.getSelectableRowIds();
    const rowDiff = difference(allRowIds, Object.keys(selectedRowIds));

    return rowDiff.length === 0;
  }

  onToggleRowIds = (rowIds: string[]) => {
    const selectedRowIds = { ...this.state.selectedRowIds };
    rowIds.forEach(rowId => {
      if (selectedRowIds[rowId]) {
        delete selectedRowIds[rowId];
      } else {
        selectedRowIds[rowId] = true;
      }
    });

    this.setState({
      selectedRowIds,
    });
  }

  onToggleBatchAll = () => {
    const allRowIds = this.getSelectableRowIds();
    const areAllRowSelected = this.getAreAllRowsSelected();

    if (areAllRowSelected) {
      this.setState({
        selectedRowIds: {},
      });
    } else {
      this.setState({
        selectedRowIds: zipObject(allRowIds, allRowIds.map(() => true)),
      });
    }
  }

  onActionClick = (rowIds: string[], actionId: string, actionValue: string) => {
    const { onActionClick } = this.props;
    onActionClick(rowIds, actionId, actionValue);
    this.setState({
      selectedRowIds: {},
    });
  }

  getBatchAllCheckboxState = (): BatchCheckboxProps['state'] => {
    const { selectedRowIds } = this.state;

    if (this.getAreAllRowsSelected()) {
      return 'checked';
    }

    if (Object.keys(selectedRowIds).length) {
      return 'partially-checked';
    }

    return 'unchecked';
  }

  renderBatchActions() {
    const { selectedRowIds } = this.state;
    const { rowGroups, onActionClick } = this.props;

    const actionGroups: Action[][] = flatten(rowGroups.map(rowGroup => rowGroup.rows))
      .filter(row => selectedRowIds[row.id])
      .map(row => row.actions);

    const commonActions: Action[] = flatten<Action>(intersectionWith<Action>(...actionGroups as any, isEqual as any))
      .filter(action => !action?.extraProps?.noBatch);

    const style: React.CSSProperties = {
      top: '60px',
    };

    return (
      <div className="flex items-center animate__animated animate__zoomIn" style={style}>
        <div className="font-bold mr-10 text-grey-darkest text-sm whitespace-no-wrap">Selected ({Object.keys(selectedRowIds).length})</div>
        <DropdownButton
          actions={commonActions}
          onActionClick={onActionClick ? (actionName: string, actionValue: string) => this.onActionClick(Object.keys(selectedRowIds), actionName, actionValue) : undefined}
          className="-my-5"
        />
      </div>
    );
  }

  getColumnGroupProperties() {
    const { columnGroups } = this.props;

    const columnGroupProperties: ColumnGroupProperty[] = [];

    columnGroups?.forEach((columnGroup) => {
      const hasContent = !!columnGroup.content;
      for (let i = 0; i < columnGroup.colSpan; i++) {
        columnGroupProperties.push({
          isStartOfGroup: hasContent && i === 0,
          isInGroup: hasContent,
          isEndOfGroup: hasContent && i === columnGroup.colSpan - 1,
        });
      }
    });

    return columnGroupProperties;
  }

  render() {
    const { header, rowGroups, columnGroups, onRowSelect, stickyHeaderOffset, isLoading, emptyText, hasBatchSelect, disabledRowIds, columnClasses, alwaysShowActions, onRefresh, shouldPersistRefreshButton } = this.props;
    const { selectedRowIds } = this.state;

    const hasSelectedItems = Object.values(selectedRowIds).length > 0;

    const columnGroupProperties = this.getColumnGroupProperties();

    const stickyHeaderStyle: React.CSSProperties = {
      top: `${stickyHeaderOffset ?? '60'}px`,
    };

    return (
      <div className="table__wrapper">
        <table className="w-full text-left table p-10">
          {header ? (
            <thead>
              {columnGroups && (
                <tr>
                  {columnGroups.map((headerGroup, index) => (
                    <th colSpan={headerGroup.colSpan} className={`text-center ${headerGroup.content ? 'bg-grey-light border-l border-t border-r py-10' : ''}`} key={index}>
                      {headerGroup.content}
                    </th>
                  ))}
                </tr>
              )}
              <tr className="group">
                {hasBatchSelect && (
                  <th
                    className="bg-grey-light font-bold text-sm font-lt text-grey-darkest border-b border-grey-mid sticky top-0 pl-10 z-50 py-12 font-normal"
                    style={stickyHeaderStyle}
                  >
                    <BatchCheckbox
                      state={this.getBatchAllCheckboxState()}
                      onToggle={this.onToggleBatchAll}
                    />
                  </th>
                )}
                {header.map((headerItem, index) => {
                  const groupProperties = columnGroupProperties[hasBatchSelect ? index + 1 : index];
                  return (
                    <th
                      className={`bg-grey-light font-bold text-sm font-lt w-80 text-grey-darkest border-b border-grey-mid sticky top-0 px-10 z-50 py-12 font-normal ${hasBatchSelect && index === 0 ? 'pl-0' : ''} ${columnClasses?.[index] ?? ''} ${groupProperties?.isInGroup ? 'bg-grey-light' : ''} ${groupProperties?.isStartOfGroup ? 'border-l' : ''} ${groupProperties?.isEndOfGroup ? 'border-r' : ''} print:text-lg print:text-black`}
                      key={index}
                      style={{
                        ...stickyHeaderStyle,
                        ... (groupProperties?.isInGroup ? {
                          borderLeftColor: '#EFF0FD',
                          borderRightColor: '#EFF0FD',
                        } : undefined)
                      }}
                    >
                      <span className="flex">
                        {hasSelectedItems && index === 0 ? this.renderBatchActions() : headerItem}
                      </span>
                    </th>
                  );
                })}
                <th
                  className="bg-grey-light text-sm text-grey border-b border-grey-mid text-right sticky z-50 top-0 pr-10"
                  style={stickyHeaderStyle}
                >
                  <FiRefreshCw className={`text-purple ${isLoading ? 'icon-spin visible' : (shouldPersistRefreshButton ? '' : 'invisible')} ${onRefresh ? 'cursor-pointer group-hover:visible' : ''}`} onClick={onRefresh} />
                </th>
              </tr>
            </thead>
          ) : null}
          {rowGroups.map((rowGroup, rowGroupIndex) => {
            const shouldDisplayTotalRowSelect = !rowGroup.rows.find(row => selectedRowIds[row.id]);
            const onTotalRowSelect = () => {
              this.onToggleRowIds(rowGroup.rows.map(row => row.id));
            };
            return (
              <tbody
                className={`${rowGroupIndex < rowGroups.length - 1 ? 'border-b border-grey-mid' : ''} ${rowGroup.isSelectable ? 'cursor-pointer' : ''}`}
                key={rowGroup.id}
              >
                {rowGroup.rows.map((row, rowIndex) => (
                  <TableRow
                    key={row.id}
                    row={row}
                    onRowSelect={onRowSelect}
                    isDisabled={disabledRowIds && disabledRowIds[row.id]}
                    hasBatchSelect={hasBatchSelect}
                    showIndicator={rowGroup.showGroupIndicator}
                    rowIndex={rowIndex}
                    alwaysShowActions={alwaysShowActions}
                    disabledRowIds={disabledRowIds}
                    onActionClick={this.onActionClick}
                    isLastRow={rowIndex === rowGroup.rows.length - 1}
                    renderExpansion={row.renderExpansion}
                    columnGroupProperties={columnGroupProperties}
                    isSelected={!!selectedRowIds[row.id]}
                    totalRowCount={rowGroup.rows.length}
                    onToggleRowChecked={() => this.onToggleRowIds([row.id])}
                    displayTotalRowSelect={shouldDisplayTotalRowSelect}
                    onTotalRowSelect={onTotalRowSelect}
                  />
                ))}
              </tbody>
            );
          })}
          <tbody>
            {isLoading && rowGroups.length === 0 ? (
              <tr>
                <td colSpan={header.length + (hasBatchSelect ? 1 : 0)}>
                  <div className="h-12 px-10 flex items-center w-full">
                    <Spinner label="Loading table data..." />
                  </div>
                </td>
              </tr>
            ) : null}
            {!isLoading && rowGroups.length === 0 ? (
              <tr>
                <td colSpan={header.length + (hasBatchSelect ? 1 : 0)}>
                  <div className="flex text-grey-dark h-12 items-center">
                    <FiInbox className="mr-5" /> <span>{emptyText}</span>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
