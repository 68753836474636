import * as React from 'react';
import { uniq } from 'lodash';
import { ReportRequestState } from '../../../store/report/request/types';
import { JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';
import { getProductLabel } from '../../../../utilities/journeys/products';
import { ApplicationState, AppDispatch } from '../../../store';
import { connect } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';
import Button from '../../../components/widgets/Button';
import { sendForReview, markAsReviewed, updateRiskStatus, updatePrimaryCounsellor } from '../../../store/report/actions';
import { Counsellor, JourneyState, RiskStatus } from '../../../../graphql/genie-api-types';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';
import { getGoogleDriveFolderName, getGoogleDriveSearchLink, formatMemberName } from '../../../../utilities/helpers';
import Select from '../../../components/widgets/Select';
import CounsellorSearchAutocomplete from '../../../graphql/containers/counsellors/CounsellorSearchAutocomplete';
import { RequestPanelInformation } from '../../../components/journey/show/RequestPanelInformation';

interface DispatchProps {
  onNavigateToJourneys(): void;
  onSendForReview(requestId: string): void;
  onMarkAsReviewed(requestId: string): void;
  onRiskStatusUpdate(requestId: string, newStatus: string): void;
  onUpdatePrimaryCounsellor(requestId: string, newCounsellorId: string): void;
}

const RemoteReportHeader = (props: ReportRequestState & DispatchProps) => {
  const { request } = props;

  const [riskStatus, setRiskStatus] = React.useState(request?.riskStatus ?? "");
  const [primaryCounsellor, setPrimaryCounsellor] = React.useState(request?.primaryCounsellor ?? undefined);

  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  if (!request?.journeys) {
    return null;
  }

  const { journeys } = request;

  const journeyStates = uniq(journeys.map(journey => journey.state));

  const isInReviewState = journeyStates.length === 1 && journeyStates[0] === JourneyState.ReadyForReportReview;

  const riskStatusOptions = [
    { value: "", label: 'Not set' },
  ];

  (Object.keys(RiskStatus) as Array<keyof typeof RiskStatus>).map((key) => {
    riskStatusOptions.push({
      value: RiskStatus[key],
      label: key
    })
  });

  const updateRiskStatus = async (newStatus: string) => {
    await props.onRiskStatusUpdate(request.id, newStatus);
    setRiskStatus(newStatus);
  }

  const updatePrimaryCounsellor = async (newCounsellor: Counsellor) => {
    await props.onUpdatePrimaryCounsellor(request.id, newCounsellor ? newCounsellor.id : undefined);
    setPrimaryCounsellor(newCounsellor);
  }

  const requestPanelInformation = request?.numGenesTestedRecessive ? RequestPanelInformation(request.numGenesTestedRecessive, request.numGenesTestedXLinked) : null;

  return <>
    <div className="border-b pb-8 flex justify-between items-start">
      <div>
        <div className="text-xs mb-10">
          {journeyStates.map((journeyState, index) => (
            <React.Fragment key={journeyState}>
              <button
                className="text-blue hover:underline"
                onClick={props.onNavigateToJourneys}>
                {JOURNEY_STATE_LABELS[journeyState]}
              </button>
              {journeyStates.length > 1 && index !== journeyStates.length - 1 ? ' / ' : ''}
            </React.Fragment>
          ))}
        </div>
        <div>
          {journeys.map((journey, index) => (
            <React.Fragment key={journey.id}>
              <button
                className="hover:underline font-bold"
                onClick={() => onSelectJourney(journey.id)}>
                {formatMemberName(journey.member, request)}
              </button>
              {journeys.length > 1 && index !== journeys.length - 1 ? ' & ' : ''}
            </React.Fragment>
          ))}
          <span className='text-xs text-grey-dark'>&nbsp;-&nbsp;{getProductLabel(request.product, request.productVariant)}</span>
          {requestPanelInformation && (
            <span className={`ml-10 whitespace-no-wrap text-xs font-md ${requestPanelInformation.className}`}>{requestPanelInformation.text}</span>
          )}
        </div>
      </div>
      <div className='flex flex-col items-end'>
        <div className='flex justify-end'>
          <Button size="sm" onClick={() => {
            const folderName = getGoogleDriveFolderName(journeys);
            window.open(getGoogleDriveSearchLink(folderName));
          }} variant="success" className="mr-10">
            Google Drive link (old)
          </Button>
          <Button size="sm" onClick={() => {
            const folderName = request.id;
            window.open(getGoogleDriveSearchLink(folderName));
          }} variant="success" className="mr-10">
            Google Drive link (new)
          </Button>
          {isInReviewState ? (
            <Button size="sm" onClick={() => props.onMarkAsReviewed(request.id)}>
              Mark as reviewed
            </Button>
          ) : (
            <Button size="sm" onClick={() => props.onSendForReview(request.id)}>
              Send for Review
            </Button>
          )}
        </div>
      </div>
    </div>
    <div className="flex items-center justify-end mt-10 border-b pb-8 mb-20">
      <label className="flex w-80 items-center justify-start text-sm mr-20">
        <h2 className="mr-8 font-semibold whitespace-no-wrap">Primary Counsellor</h2>
        <div className='flex-1'><CounsellorSearchAutocomplete autocompleteSize="default" onSelect={updatePrimaryCounsellor} defaultValue={primaryCounsellor} /></div>
      </label>

      <label className="flex items-center text-sm">
        <h2 className="mr-8 font-semibold whitespace-no-wrap">Risk status</h2>
        <Select
          className="h-8 p-4"
          selectedValue={riskStatus}
          onChange={updateRiskStatus}
          options={riskStatusOptions}
        />
      </label>


    </div>
  </>
}

const mapStateToProps = (state: ApplicationState) => state.report.request;
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onNavigateToJourneys: () => dispatch(navigate.to(PATHS.CLINICAL_POST)),
  onSendForReview: (requestId: string) => dispatch(sendForReview(requestId)),
  onMarkAsReviewed: (requestId: string) => dispatch(markAsReviewed(requestId)),
  onRiskStatusUpdate: (requestId: string, newStatus: string) => dispatch(updateRiskStatus(requestId, newStatus)),
  onUpdatePrimaryCounsellor: (requestId: string, newStatus: string) => dispatch(updatePrimaryCounsellor(requestId, newStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteReportHeader);
