/* eslint-disable @typescript-eslint/camelcase */

import * as React from 'react';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { ReportState } from '../../../store/report';
import { preferredDoctorClinicEmail } from '../../../../utilities/helpers';
import { DoctorClinic } from '../../../../graphql/genie-api-types';

interface FamilyReportViewProps extends TaskListComponentProps {
  reportState: ReportState;
}

// See https://script.google.com/u/1/home/projects/1ZcGXWaF_fn9Jk0OWHgtd8bj06UHfHcv1bclFovbotp59bSyS6FWef7B6/edit
const TEMPLATE_MANAGER_SCRIPT_URL = "https://script.google.com/a/macros/eugenelabs.com/s/AKfycbywJd-tZWuzuNioEEHOnY2mAjjWyYgzHQdIfjnrUJUo25NbEwwqmkKiDFMNtA62hqE/exec";

class LetterToDoctorView extends React.PureComponent<FamilyReportViewProps> {
  render() {
    const { isComplete, onToggleComplete, reportState } = this.props;
    return (
      <div>
        <TaskListScreenTitle
          title="Letter to Doctor"
          isSelected={isComplete}
          onToggle={onToggleComplete}
        />
        <div>
          {url(reportState) !== '' ? <a className="underline" target="_blank" rel="noopener noreferrer" href={url(reportState)}> Autofill Google Doc Letter to Doctor</a> : <span>Template not setup</span>}
        </div>
      </div>
    );
  }
}

const url = (reportState: ReportState) => {
  const params = parameters(reportState);
  if (!params) {
    return '';
  }

  const url = new URL(TEMPLATE_MANAGER_SCRIPT_URL);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  return url.toString();
}

const parameters = (reportState: ReportState): Record<string, string> | boolean => {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const today = new Date();
  const date = today.toLocaleDateString("en-AU", options);

  if (reportState.request.request.product !== 'CARRIER' || reportState.request.request.riskStatus === 'LOW') {
    return false;
  }

  let doctorNameAndTitle = '';

  if (reportState.request.request.referringDoctorClinic) {
    doctorNameAndTitle += reportState.request.request.referringDoctorClinic?.doctor.nameFirst;
    doctorNameAndTitle += reportState.request.request.referringDoctorClinic?.doctor.nameMiddle ? ' ' + reportState.request.request.referringDoctorClinic?.doctor.nameMiddle : '';
    doctorNameAndTitle += reportState.request.request.referringDoctorClinic?.doctor.nameLast ? ' ' + reportState.request.request.referringDoctorClinic?.doctor.nameLast : '';
    doctorNameAndTitle = doctorNameAndTitle.trim();
  }

  const doctorEmail = preferredDoctorClinicEmail(reportState.request.request.referringDoctorClinic as DoctorClinic);

  return {
    request_id: reportState.request.request.id,
    rq_number: reportState.request.request.journeys.map((journey) => journey.labOrderNumber).join(', '),
    current_date: date,
    doctor_name_and_title: doctorNameAndTitle,
    doctor_email: doctorEmail,
    practice_name: reportState.request.request.referringDoctorClinic?.clinic.name,
    patient_1_name_full: reportState.clientDetails.clientA.fullName,
    patient_1_name_first: reportState.clientDetails.clientA.nickname,
    patient_1_dob: reportState.clientDetails.clientA.dob,
    patient_2_name_full: reportState.clientDetails.clientB.fullName,
    patient_2_name_first: reportState.clientDetails.clientB.nickname,
    patient_2_dob: reportState.clientDetails.clientB.dob,
    couple_name: reportState.clientDetails.clientA.nickname + ' and ' + reportState.clientDetails.clientB.nickname,
    gc_name: reportState.request.request.primaryCounsellor ? reportState.request.request.primaryCounsellor.name : '',
    number_of_recessive_genes_tested: reportState.testInformation.numberOfGenesTested,
    number_of_x_linked_genes_tested: reportState.testInformation.numberOfXLinkedGenesTested,
  }
}

const mapStateToProps = ({ report }: ApplicationState) => ({
  reportState: report,
});

export default connect(mapStateToProps)(LetterToDoctorView);