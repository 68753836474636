import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import {
  DoctorClinic,
  Journey,
  LeadsConnection,
  Member,
  QueryListLeadsArgs,
  QueryListRequestsArgs,
  Request,
  RequestsConnection
} from '../../../../graphql/genie-api-types';
import { pluralise } from '../../../../utilities/helpers';
import useStatsQuery from '../../../../utilities/useStatsQuery';
import { FiChevronLeft, FiChevronRight } from '../../../components/widgets/Icon';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';

const LIST_ALL_REQUESTS = gql`
  query ListRequests($input: ListRequestsInput!) {
    listRequests(input: $input) {
      edges {
        cursor
        node {
          id
          datePaid
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const LIST_ALL_LEADS_QUERY = gql`
  query ListLeads($input: ListLeadsInput!) {
    listLeads(input: $input) {
      edges {
        cursor
        node {
          id
          dateCreated
          additionalData
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

interface RequestPartial extends Pick<Request, 'id' | 'dateCreated' | 'datePaid'> {
  journeys: Pick<Journey, 'id' | 'state'>[];
  referringDoctorClinic: Pick<DoctorClinic, 'id'>;
  initiatingMember: Pick<Member, 'id'>;
}

interface StatsResult {
  thisMonth: number;
  lastMonth: number;
  change: number;
}

const SalesStats = () => {
  const [monthMoment, setMonthMoment] = React.useState(moment());
  
  
  const leadStats = useStatsQuery<LeadsConnection, QueryListLeadsArgs>(LIST_ALL_LEADS_QUERY, {
    variables: {
      input: {},
    },
  }, 'listLeads');
  
  const requestStats = useStatsQuery<RequestsConnection, QueryListRequestsArgs>(LIST_ALL_REQUESTS, {
    variables: {
      input: {},
    },
  }, 'listRequests');

  const onNextMonth = React.useCallback(() => {
    setMonthMoment(monthMoment.clone().add(1, 'month'));
  }, [monthMoment, setMonthMoment]);

  const onPreviousMonth = React.useCallback(() => {
    setMonthMoment(monthMoment.clone().subtract(1, 'month'));
  }, [monthMoment, setMonthMoment]);
  
  const isCurrentMonth = React.useMemo(() => {
    return monthMoment.diff(moment(), 'days') === 0;
  }, [monthMoment]);

  const leadCount: StatsResult = React.useMemo(() => {
    const result: StatsResult = {
      thisMonth: 0,
      lastMonth: 0,
      change: 0,
    };
    
    const lastMonth = monthMoment.clone().subtract(1, 'month');

    leadStats.data?.edges?.map(edge => edge.node).forEach(lead => {
      const created = moment(lead.dateCreated);
      if (
        created.month() === monthMoment.month() &&
        created.year() === monthMoment.year() && 
        (isCurrentMonth ? (created.date() <= monthMoment.date()) : true)
      ) {
        result.thisMonth ++;
      }
      else if (
        created.month() === lastMonth.month() &&
        created.year() === lastMonth.year() && 
        (isCurrentMonth ? (created.date() <= monthMoment.date()) : true)
      ) {
        result.lastMonth ++;
      }
    });

    result.change = result.thisMonth - result.lastMonth;
    
    return result;
  }, [leadStats.data, monthMoment, isCurrentMonth]);
  
  const purchaseCount: StatsResult = React.useMemo(() => {
    const result: StatsResult = {
      thisMonth: 0,
      lastMonth: 0,
      change: 0,
    };
    
    const lastMonth = monthMoment.clone().subtract(1, 'month');

    requestStats.data?.edges?.map(edge => edge.node).forEach(request => {
      const purchased = moment(request.datePaid);
      if (
        purchased.month() === monthMoment.month() &&
        purchased.year() === monthMoment.year() && 
        (isCurrentMonth ? (purchased.date() <= monthMoment.date()) : true)
      ) {
        result.thisMonth ++;
      }
      else if (
        purchased.month() === lastMonth.month() &&
        purchased.year() === lastMonth.year() && 
        (isCurrentMonth ? (purchased.date() <= monthMoment.date()) : true)
      ) {
        result.lastMonth ++;
      }
    });
    
    result.change = result.thisMonth - result.lastMonth;
    
    return result;
  }, [requestStats.data, monthMoment, isCurrentMonth]);
  
  const referralCount: StatsResult = React.useMemo(() => {
    const result: StatsResult = {
      thisMonth: 0,
      lastMonth: 0,
      change: 0,
    };
    
    const lastMonth = monthMoment.clone().subtract(1, 'month');

    leadStats.data?.edges?.map(edge => edge.node).filter(lead => {
      if (!lead.additionalData) {
        return false;
      }
      
      const data = JSON.parse(lead.additionalData);
      if (data?.doctors_name) { // eslint-disable-line @typescript-eslint/camelcase
        return true;
      }

      return false;
    }).forEach(lead => {
      const created = moment(lead.dateCreated);
      if (
        created.month() === monthMoment.month() &&
        created.year() === monthMoment.year() && 
        (isCurrentMonth ? (created.date() <= monthMoment.date()) : true)
      ) {
        result.thisMonth ++;
      }
      else if (
        created.month() === lastMonth.month() &&
        created.year() === lastMonth.year() && 
        (isCurrentMonth ? (created.date() <= monthMoment.date()) : true)
      ) {
        result.lastMonth ++;
      }
    });

    result.change = result.thisMonth - result.lastMonth;
    
    return result;
  }, [leadStats.data, monthMoment, isCurrentMonth]);;

  return (
    <div className="bg-purple-lighter p-20 rounded flex items-center relative mb-20">
      {(requestStats.isLoading || leadStats.isLoading) && <LoadingOverlay />}
      <button onClick={onPreviousMonth} className="pr-10"><FiChevronLeft /></button>
      <div className="flex-1">
        {!isCurrentMonth && <h2 className="font-bold text-center text-purple-light absolute right-0 left-0 top-0 p-10">({monthMoment.format('MMMM YYYY')})</h2>}
        <div className="flex mt-10">
          <div className="w-1/3 border bg-purple-100 p-20 rounded text-center font-bold">
            <div className="text-2xl mb-10">{leadCount.thisMonth} {pluralise('lead', 'leads', leadCount.thisMonth)} <span className={`${leadCount.change > 0 ? 'text-green-dark' : 'text-orange'}`}>{leadCount.change >= 0 ? '+' : ''}{leadCount.change}</span></div>
            <div className="text-sm text-grey-dark">{leadCount.lastMonth} {isCurrentMonth ? 'this time last month' : 'previous month'}</div>
          </div>
          <div className="w-1/3 border bg-purple-100 p-20 rounded text-center font-bold">
            <div className="text-2xl mb-10">{purchaseCount.thisMonth} {pluralise('sale', 'sales', purchaseCount.thisMonth)} <span className={`${purchaseCount.change > 0 ? 'text-green-dark' : 'text-orange'}`}>{purchaseCount.change >= 0 ? '+' : ''}{purchaseCount.change}</span></div>
            <div className="text-sm text-grey-dark">{purchaseCount.lastMonth} {isCurrentMonth ? 'this time last month' : 'previous month'}</div>
          </div>
          <div className="w-1/3 border bg-purple-100 p-20 rounded text-center font-bold">
            <div className="text-2xl mb-10">{referralCount.thisMonth} {pluralise('referral', 'referrals', referralCount.thisMonth)} <span className={`${referralCount.change > 0 ? 'text-green-dark' : 'text-orange'}`}>{referralCount.change >= 0 ? '+' : ''}{referralCount.change}</span></div>
            <div className="text-sm text-grey-dark">{referralCount.lastMonth} {isCurrentMonth ? 'this time last month' : 'previous month'}</div>
          </div>
        </div>
      </div>
      <button onClick={onNextMonth} className={`pl-10 ${isCurrentMonth ? 'invisible' : ''}`}><FiChevronRight /></button>
    </div>
  );
}

export default SalesStats;
