import * as React from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import Button from '../../widgets/Button';
import ClinicShowContext from "../../../contexts/ClinicShowContext";

const renderFieldOrPlaceholder = (isValid: boolean, fieldLabel: string, fallbackPlaceholderLabel: string, isGreen: boolean, onEditClinic: () => void) => {
  if (isValid) { return <div className={isGreen ? 'text-green' : ''}>{fieldLabel}</div> }

  return <button className="text-orange text-left focus:outline-none hover:underline" onClick={onEditClinic}>{fallbackPlaceholderLabel}</button>;
}

const ClinicShowHeader = () => {
  const { clinic, clinicId } = React.useContext(ClinicShowContext);
  const dispatch = useDispatch();
  if (!clinic) {
    return null;
  }

  const onEditClinic = () => dispatch(navigate.toForm('ClinicUpdate', {
    clinicId: clinicId,
  }));

  return (
    <div>
      <div className="w-full flex justify-between items-start">
        <h3 className="text-black font-bold text-2xl">
          <span>{clinic.name} </span>
        </h3>
        <Button onClick={onEditClinic} size="sm" className="ml-10">Edit</Button>
      </div>
      <div className="flex flex-wrap">
        <div className="sm:w-full w-1/2 leading-relaxed pr-20 flex flex-col flex-wrap">
          <div className="mt-12 flex flex-col">
            {renderFieldOrPlaceholder(!!clinic.addressStreet, clinic.addressStreet, 'Add clinic address', true, onEditClinic)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicShowHeader;
