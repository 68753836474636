

import * as React from 'react';

interface ZendeskIconProps {
  className?: string;
}

const ZendeskIcon = ({ className }: ZendeskIconProps) => (
  <svg
    viewBox="0 0 26 26"
    id="zd-svg-icon-26-zendesk"
    className={`${className} w-5 h-5`}
  >
    <path
      fill="currentColor"
      d="M12 8.2v14.5H0zM12 3c0 3.3-2.7 6-6 6S0 6.3 0 3h12zm2 19.7c0-3.3 2.7-6 6-6s6 2.7 6 6H14zm0-5.2V3h12z"
    />
  </svg>
);

export default ZendeskIcon;
