import { SummaryState } from '../../app/store/report/summary/types';
import { Counsellor, Member, Pregnancy, Product } from '../../graphql/genie-api-types';
import { DoctorClinicPartial, formatDoctorName } from '../doctorClinic';
import { generateAndList } from '../helpers';

type MemberWithEmail = Pick<Member, 'nickname'|'name'>;

type JourneyWithMember = {
  member: MemberWithEmail;
};

interface RequestPartial {
  journeys: JourneyWithMember[];
  primaryCounsellor: Pick<Counsellor, 'nickname'|'name'>;
  referringDoctorClinic: DoctorClinicPartial;
  pregnancy: Partial<Pregnancy>;
  product: Product;
}

const getEmailContentForProduct = (product: Product, risk: SummaryState['reproductiveRisk'], memberNames: string[], doctorClinic: DoctorClinicPartial, hasPregnancy: boolean) => {
  switch (product) {
    case Product.Carrier:
      return `It was a pleasure to chat to you over Zoom today and give you the ${risk === 'Low' ? 'good ' : ''}news about your ${risk === 'Low' ? 'low risk ' : ''}genetic carrier screening results.

Please find attached to this email the following files:
1. Your Eugene ${memberNames.length > 1 ? 'couples ' : ''}summary report
${memberNames.map((name, index) => `${index + 2}. ${memberNames.length === 1 ? 'Your' : `${name}'s`} Comprehensive Carrier Screening results
`).join('')}    
Please let me know if you have any questions. I have sent your results to ${doctorClinic ? formatDoctorName(doctorClinic) : 'your doctor'} for their records.

Wishing you ${memberNames.length === 2 ? 'both ' : ''}all the best for the remainder of your ${hasPregnancy ? 'pregnancy' : 'reproductive journey'}.`;
    case Product.Cancer:
      return `It was a pleasure to chat to you over Zoom today and give you the news about your proactive cancer risk test results.
  
Please find attached to this email the following files:
1. Your Eugene summary report
${memberNames.map((name, index) => `${index + 2}. ${memberNames.length === 1 ? 'Your' : `${name}'s`} Invitae comprehensive proactive risk test screening results
`).join('')}    
Please let me know if you have any questions. I have sent your results to ${doctorClinic ? formatDoctorName(doctorClinic) : 'your doctor'} for their records.`;
  }
}

const generateMemberReportEmailBody = (request: RequestPartial, risk: SummaryState['reproductiveRisk']) => {
  const memberNames = request.journeys.map(journey => journey.member ? (journey.member.nickname ? journey.member.nickname : journey.member.name) : 'Unknown');
  const counsellorNickname = request.primaryCounsellor?.nickname ?? '';
  
  const salutation = `Dear ${generateAndList(memberNames)},`;

  const body = getEmailContentForProduct(request.product, risk, memberNames, request.referringDoctorClinic, !!request.pregnancy);

  const counsellorSignature = `Warmest regards,
${counsellorNickname}`;
  
  return `${salutation}

${body}

${counsellorSignature}`;
}

export default generateMemberReportEmailBody;