import * as React from 'react';
import { colors } from '../../../types/tailwind';
import Spinner from '../Spinner';
import Button from '../Button';

interface SingleFieldFormProps {
  onSubmit(value: string): void;
  placeholder: string;
  isLoading: boolean;
  color?: colors;
  textColor?: colors;
  defaultValue?: string;
  inputClassName?: string;
  transformValue?(value: string): string;
}

const submitForm = (form: HTMLFormElement, onSubmit: SingleFieldFormProps['onSubmit']) => {
  const fieldValue = (form.elements[0] as HTMLInputElement).value;
  onSubmit(fieldValue);
};

interface SingleFieldFormState {
  fieldValue: string;
}

class SingleFieldForm extends React.PureComponent<SingleFieldFormProps, SingleFieldFormState> {
  static defaultProps = {
    textColor: 'black',
    color: 'purple-100',
  };

  constructor(props: SingleFieldFormProps) {
    super(props);

    this.state = {
      fieldValue: props.defaultValue,
    };
  }

  onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      fieldValue: this.props.transformValue ? this.props.transformValue(e.target.value) : e.target.value
    });
  }

  render() {
    const { color, textColor, placeholder, onSubmit, isLoading, defaultValue, inputClassName } = this.props;
    const { fieldValue } = this.state;
    const isEdited = (fieldValue ?? '') !== (defaultValue ?? '');

    return (
      <form onSubmit={(e) => {
        submitForm(e.nativeEvent.target as HTMLFormElement, onSubmit);
        e.preventDefault();
      }}
        className="relative flex items-center"
      >
        <input
          type="text"
          placeholder={placeholder}
          className={`inline-block ${inputClassName ?? `px-7 py-3 rounded text-${textColor} bg-${color} focus:bg-white`}`}
          autoComplete="off"
          value={fieldValue || ''}
          onChange={this.onFieldChange}
          style={{ height: 'auto' }}
        />
        {isEdited ? (
          <Button size="sm" disabled={isLoading} type="submit">
            {isLoading ? <Spinner /> : 'Save'}
          </Button>
        ) : null}
      </form>
    );
  }
}

export default SingleFieldForm;
