import gql from 'graphql-tag';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import {
  DoctorClinic,
  Journey,
  JourneysConnection,
  JourneyState,
  Member,
  Note,
  Pregnancy,
  QueryListJourneysArgs,
  Request
} from '../../../graphql/genie-api-types';
import { getProductIcon } from '../../../utilities/journeys/products';
import runStatsQuery from '../../../utilities/runStatsQuery';
import Badge, { BadgeProps } from '../../components/widgets/Badge';
import GeneticJokes from '../../components/widgets/GeneticJokes';
import PregnancyIcon from '../../components/widgets/PregnancyIcon';
import PrivateContent from '../../components/widgets/PrivateContent';
import Screen from '../../components/widgets/Screen';
import Table from '../../components/widgets/Table';
import Tooltip from '../../components/widgets/Tooltip';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber } from '../../../utilities/helpers';
import { Action } from '../../components/widgets/DropdownButton';
import updateJourneyState from '../../graphql/requests/journey/updateJourneyState';
import { hideToast, showLoadingToast } from '../../store/toast/actions';
import { JOURNEY_STATE_LABELS } from '../../../utilities/journeys/states';
import { FiPauseCircle } from '../../components/widgets/Icon';
import { navigate } from '../../store/router/actions';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';

const UNPAID_JOURNEY_FRAGMENT = gql`
  fragment UnpaidJourneyParts on Journey {
    id
    request {
      id
      dateCreated
      product
      productVariant
      onHoldDate
      pregnancy {
        days
        dueDate
        dueDateConfirmed
      }
      initiatingMember {
        id
      }
      notes {
        date
        text
        username
      }
      referringDoctorClinic {
        id
        doctor {
          title
          nameFirst
          nameMiddle
          nameLast
        }
        clinic {
          addressStreet
          addressSuburb
          addressPostcode
          addressState
          addressCountry
        }
      }
      journeys {
        id
        member {
          id
          name
          email
          phoneNumber
        }
      }
    }
  } 
`;

interface UnpaidRequest {
  id: Request['id'];
  dateCreated?: Request['dateCreated'];
  product?: Request['product'];
  productVariant?: Request['productVariant'];
  pregnancy?: Pregnancy;
  notes?: Pick<Note, 'date' | 'text' | 'username'>[];
  referringDoctorClinic?: Pick<DoctorClinic, 'id' | 'doctor' | 'clinic'>;
  onHoldDate?: Request['onHoldDate'];
  journeys?: {
    id: Journey['id'];
    member?: Pick<Member, 'id' | 'name' | 'email' | 'phoneNumber'>;
  }[];
}

const LIST_UNPAID_ACTIVE_JOURNEYS = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          ...UnpaidJourneyParts
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${UNPAID_JOURNEY_FRAGMENT}
`;

type ActionName = 'close-lost' | 'on-hold' | 'trash';

const queryVars: QueryListJourneysArgs = {
  input: {
    excludeCompleted: true,
    paid: false,
  },
};

const actions: Action<ActionName>[] = [
  {
    label: 'Close lost',
    name: 'close-lost',
    variant: 'warning',
  },
  {
    label: 'Trash',
    name: 'trash',
    variant: 'danger',
  },
  {
    label: 'Change on-hold',
    name: 'on-hold',
    variant: 'primary',
  }
];

const TasksSales = () => {
  const dispatch = useDispatch();
  const journeyInfoContext = React.useContext(JourneyInfoContext);
  // Do a genie stats query on all unpaid and paid requests with minimal content to do stats on.

  // Do a genie stats query on ONLY paid requests to list the sales leads.
  const [unpaidRequests, setUnpaidRequests] = React.useState<UnpaidRequest[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const loadUnpaidRequests = React.useCallback(async () => {
    setIsLoading(true);
    const journeys = await runStatsQuery<JourneysConnection, QueryListJourneysArgs>(LIST_UNPAID_ACTIVE_JOURNEYS, {
      variables: queryVars,
      fetchPolicy: 'network-only',
    }, 'listJourneys');

    const requests = journeys?.edges
      ?.map(edge => edge.node)
      .filter(node => node.request)
      .map(node => node.request) ?? [];

    const sortedRequests = sortBy(uniqBy(requests, 'id'), (request) => {
      const lastNote = request?.notes?.slice(0).filter(note => note.username)?.[0] ?? null;

      if (!lastNote) {
        return Number.MAX_SAFE_INTEGER;
      }

      return -moment(lastNote.date).valueOf();
    }).reverse();

    const deduplicatedRequests = uniqBy(sortedRequests, (request) => {
      return request?.initiatingMember?.id;
    });

    setUnpaidRequests(deduplicatedRequests as UnpaidRequest[]);
    setIsLoading(false);
  }, [setUnpaidRequests, setIsLoading]);

  React.useEffect(() => {
    loadUnpaidRequests();
  }, [loadUnpaidRequests]);

  const onActionClick = React.useCallback(async (requestIds: string[], actionName: ActionName) => {
    let toState: JourneyState;

    if (actionName === 'trash') {
      toState = JourneyState.Trash;
    }
    else if (actionName === 'close-lost') {
      toState = JourneyState.CloseLost;
    }
    else if (actionName === 'on-hold') {
      dispatch(navigate.toForm('RequestHoldStatusUpdate', { requestId: requestIds[0] }));
    }

    if (!toState) {
      return;
    }

    const loadingId = 'updatingJourneyState';

    dispatch(showLoadingToast(loadingId, `Marking journeys ${JOURNEY_STATE_LABELS[toState]}`, '...'));

    await Promise.all(requestIds.map(requestId => {
      const request = unpaidRequests.find(request => request.id === requestId);
      return Promise.all(request?.journeys?.map(journey => updateJourneyState(journey.id, toState)));
    }));

    dispatch(hideToast(loadingId));

    const newUnpaidRequestsList = unpaidRequests.filter(request => !requestIds.includes(request.id));
    setUnpaidRequests(newUnpaidRequestsList);

  }, [dispatch, unpaidRequests, setUnpaidRequests]);

  const rowGroups = React.useMemo(() => {
    return unpaidRequests.map(request => {
      const lastNote = request?.notes?.slice(0).filter(note => note.username)?.[0] ?? null;
      const lastNoteDate = lastNote ? moment(lastNote.date) : null;

      let badgeColor: BadgeProps['color'] = 'orange-light';
      if (lastNoteDate) {
        if (moment().diff(lastNoteDate) < 2) {
          badgeColor = 'green-light';
        }
      }

      const phoneNumber = formatPhoneNumber(request?.journeys?.find(journey => journey?.member?.phoneNumber)?.member.phoneNumber ?? '');

      return {
        id: `group-${request.id}`,
        rows: [
          {
            id: request.id,
            cells: [
              (
                <span key="member-badge-cell">
                  {request?.journeys?.map(journey => (
                    <Badge key={journey.id} className="mr-5"><PrivateContent>{journey.member?.name ?? ''}</PrivateContent></Badge>
                  ))}
                  {request?.pregnancy?.dueDate ? <PregnancyIcon pregnancy={request?.pregnancy} /> : null}
                  {request?.onHoldDate && <FiPauseCircle title={`On hold since ${moment(request.onHoldDate).format('Do MMM YYYY')}`} />}
                </span>
              ),
              getProductIcon(request.product, request.productVariant),
              request.referringDoctorClinic ? `${request.referringDoctorClinic?.doctor?.nameFirst} ${request.referringDoctorClinic?.doctor?.nameLast} (${request.referringDoctorClinic?.clinic?.addressState ?? ''})` : '',
              <PrivateContent key="phone">{formatPhoneNumber(phoneNumber)}</PrivateContent>,
              request.dateCreated ? moment(request.dateCreated).fromNow() : '',
              lastNote ? <Tooltip key="note" label={`${lastNote.text} - ${lastNote.username}`}>
                <Badge color={badgeColor}>{moment(lastNoteDate).fromNow()}</Badge>
              </Tooltip > : <Badge color="orange" textColor="white">None</Badge>,
            ],
            actions,
          }
        ],
      };
    });
  }, [unpaidRequests]);

  const onSelectJourney = React.useCallback((requestId: Request['id']) => {
    const request = unpaidRequests.find(request => request.id === requestId);
    journeyInfoContext.onSelectJourney(request?.journeys?.[0]?.id);
  }, [journeyInfoContext.onSelectJourney, unpaidRequests]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Unpaid requests',
        action: <GeneticJokes />,
      }}
    >
      <div>
        <Table
          header={['People', 'Product', 'Doctor (state)', 'Phone', 'Requested', 'Last note']}
          rowGroups={rowGroups}
          onRefresh={loadUnpaidRequests}
          isLoading={isLoading}
          onRowSelect={onSelectJourney}
          hasBatchSelect
          onActionClick={onActionClick}
        />
      </div>
    </Screen>
  );
}

export default TasksSales;