import * as React from 'react';
import ActionLink from '../ActionLink';

type LabelAction = {
  label: string;
  onClick(): void;
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  controlId: string;
  label?: string;
  labelAction?: LabelAction;
  children: React.ReactElement<any>;
  helpText?: string;
  halfWidth?: boolean;
  clearBottomMargin?: boolean;
}

const FormGroup = ({ className, clearBottomMargin, halfWidth, label: labelText, labelAction, helpText, controlId, children, ...props }: Props) => {
  return (
    <div className={`${clearBottomMargin ? '' : 'mb-20'} ${className || ''} ${halfWidth ? 'w-1/2' : 'w-full'}`} {...props} >
      {labelText ? (
        <label htmlFor={controlId} className="block mb-10 flex justify-between items-center">
          <span>{labelText}</span>
          <span>{labelAction ? <ActionLink className="text-xs inline-block font-bold ml-5 text-blue-dark" onClick={labelAction.onClick}>{labelAction.label}</ActionLink> : null}</span>
        </label>
      ) : null}
      <div className="block">
        {React.cloneElement(children, { id: controlId })}
      </div>
      {helpText ? <div className="text-grey-dark mt-5 text-sm">{helpText}</div> : null}
    </div>
  );
};

export default FormGroup;
