import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import ArchivedJourneys from '../../components/journey/journey-rows/ArchivedJourneys';
import ReadyToArchiveJourneys from '../../components/journey/journey-rows/ReadyToArchiveJourneys';
import Screen from '../../components/widgets/Screen';

const JourneysArchived = () => (
  <Screen>
    <Heading level={1} className="mb-20">Journeys: Archival</Heading>
    <Heading level={3} className="mb-10">
      Ready to archive <span className="ml-5 text-sm align-middle">🗃 </span>
    </Heading>
    <ReadyToArchiveJourneys />
    <Heading level={3} className="mt-30 mb-10">
      Archived <span className="ml-5 text-sm align-middle">🗂 </span>
    </Heading>
    <ArchivedJourneys />
  </Screen>
);

export default JourneysArchived;
