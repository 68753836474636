import { FieldType } from '../../FieldType';

export type DiseaseHeaderVariables = {
  diseaseClass: string;
  diseaseClassColor: string;
  resultTitle: string;
  clientAName: string;
  clientBName: string;
  carrierRisk: string;
  diseaseName: string;
  relatedGenes: string;
  clientACarrierStatus: string;
  clientBCarrierStatus: string;
  relatedGenesPrefix: string;
  hasSecondClientColumn: boolean;
};

export const DiseaseHeaderVariableTypes: {[key in keyof DiseaseHeaderVariables]: FieldType} = {
  diseaseClass: ['increased-risk', 'high-risk', 'low-risk-bb', 'result-to-note'],
  diseaseClassColor: ['pink', 'orange', 'dk-green', 'grey'],
  resultTitle: 'text',
  clientAName: 'text',
  clientACarrierStatus: 'text',
  carrierRisk: 'text',
  diseaseName: 'boolean',
  relatedGenesPrefix: 'text',
  relatedGenes: 'text',
  hasSecondClientColumn: 'boolean',
  clientBName: 'text',
  clientBCarrierStatus: 'text',
};
