import * as React from 'react';
import { QueryResult, QueryComponentOptions, Query } from 'react-apollo';
import ErrorBoundary from '../../base/ErrorBoundary';

export type GenieQueryProps<Data, Vars> = QueryComponentOptions<Data, Vars>;

const renderQueryChildren = (data: QueryResult, childrenCallback: QueryComponentOptions['children']) => {
  return childrenCallback(data);
};

function GenieQuery<Data, Vars>(props: GenieQueryProps<Data, Vars>) {
  const { children, ...restOfProps } = props;

  return (
    <ErrorBoundary>
      <Query
        fetchPolicy="cache-and-network"
        {...restOfProps}
      >
        {(result: QueryResult) => renderQueryChildren(result, children)}
      </Query>
    </ErrorBoundary>
  );
}

export default GenieQuery;
