import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { navigate } from '../../store/router/actions';
import { QueryFamilyHistorySubmissionArgs, Query, QueryFamilyHistoryGroupSubmissionArgs } from '../../../graphql/genie-api-types';
import FamilyHistory from '../../components/family-history/FamilyHistory';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { appSyncClient } from '../../../utilities/appSync';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { useDispatch } from 'react-redux';
import Button from '../../components/widgets/Button';
import Screen from '../../components/widgets/Screen';

interface MatchParams {
  submissionId: QueryFamilyHistorySubmissionArgs['submissionId'];
}

type OwnProps = RouteComponentProps<MatchParams>;

const FAMILY_HISTORY_SUBMISSION_QUERY = gql`
  query FamilyHistorySubmission($submissionId: ID!) {
    familyHistorySubmission(submissionId: $submissionId) {
      id
      category
      classification
      dateCreated
      dateUpdated
      name
      source
      username
      value
    }
  }
`;

const FamilyHistorySubmission = (props: OwnProps) => {
  const { submissionId } = props.match.params;
  const dispatch = useDispatch();
  const { loading, data } = useQuery<Pick<Query, 'familyHistoryGroupSubmission'>, QueryFamilyHistoryGroupSubmissionArgs>(FAMILY_HISTORY_SUBMISSION_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: {
      submissionId,
    },
  });

  const onNavigateToAssignFamilyHistory = React.useCallback(() => {
    dispatch(navigate.toForm('ReassignFamilyHistorySubmission', { submissionId }));
  }, [dispatch, submissionId]);

  return (
    <Screen
      className="relative"
      screenTitleProps={{
        'title': `Family History Submission`,
        action: (
          <Button onClick={onNavigateToAssignFamilyHistory}>
            Assign to member
          </Button>
        ),
      }}
    >
      {loading && <LoadingOverlay />}
      <FamilyHistory
        familyHistoryGroup={data?.familyHistoryGroupSubmission ?? []}
        memberId="unknown"
        shouldShowExtra={true}
      />
    </Screen>
  );
};

export default FamilyHistorySubmission;
