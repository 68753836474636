import config from "./config";

export const generatePDF = async (jsonString: string) => {
  const response = await fetch(config('REPORT_GENERATOR_API'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
    },
    body: jsonString,
  });
  return response.text();
}

export const downloadPDF = async (jsonString: string, fileName: string) => {
  const base64Blob = await generatePDF(jsonString);
  const buf = Buffer.from(base64Blob, 'base64');
  const blob = new Blob([buf]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${fileName}.pdf`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}