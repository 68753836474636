import * as React from 'react';

interface PrivacyContextWrapperProps {
  children: React.ReactNode;
}

export interface PrivacyContextValue {
  isPrivateModeActive: boolean;
  onPrivateModeToggle?(): void;
}

const PrivacyContext = React.createContext<PrivacyContextValue>({ isPrivateModeActive: false });

export const PrivacyContextWrapper = (props: PrivacyContextWrapperProps) => {
  const [isPrivateModeActive, setIsPrivateModeActive] = React.useState(false);
  
  const onPrivateModeToggle = React.useCallback(() => {
    setIsPrivateModeActive(!isPrivateModeActive);
  }, [isPrivateModeActive, setIsPrivateModeActive]);

  return (
    <PrivacyContext.Provider value={{
      isPrivateModeActive,
      onPrivateModeToggle,
    }}
    >
      {props.children}
    </PrivacyContext.Provider>
  );
};

export default PrivacyContext;
