import * as React from 'react';
import './styles/bug-button.scss';

export type HeadingProps = {
  level: 1 | 2 | 3;
  children: React.ReactNode;
  className?: string;
};

const Heading = (props: HeadingProps) => {
  switch (props.level) {
    case 1:
      return <h1 className={`text-black leading-none text-2xl font-bold ${props.className || ''}`}>{props.children}</h1>;

    case 2:
      return <h2 className={`text-black font-bold text-2xl ${props.className || ''}`}>{props.children}</h2>;

    case 3:
      return <h3 className={`text-black font-bold text-base ${props.className || ''}`}>{props.children}</h3>;

  }
};

export default Heading;
