import * as React from 'react';
import gql from 'graphql-tag';
import { Query, Report, Pregnancy, QueryListReportsArgs, ListReportsInput, Member } from '../../../../graphql/genie-api-types';
import GeniePagedQuery from '../../base/GeniePagedQuery';

export interface ReportOption extends Pick<Report, 'dateUpdated' | 'dateCreated' | 'username' | 'request'> {
  members: Member[];
  pregnancy?: Pregnancy;
  requestId: string;
}

export type ReportFilters = Pick<ListReportsInput, 'gene' | 'hasAffectedGenes' | 'hasPsuedoGenes' | 'isHighRisk' | 'primaryCounsellorId'>

export interface ReportsListProps {
  children(reports: ReportOption[], setFilters: (filters: ReportFilters) => void, isLoading: boolean, reloadData: () => void): React.ReactNode;
}

const processReportEdges = (queryResult: Pick<Query, 'listReports'>): ReportOption[] => {
  return queryResult?.listReports?.edges?.map(edge => edge.node).map(node => ({
    requestId: node.request.id,
    dateUpdated: node.dateUpdated,
    dateCreated: node.dateCreated,
    members: node.members,
    username: node.username,
    pregnancy: node.request.pregnancy,
    request: node.request,
  })) ?? [];
};

const ReportsList = (props: ReportsListProps) => {
  const [filters, setFilters] = React.useState({} as ReportFilters);

  return (
    <GeniePagedQuery<Pick<Query, 'listReports'>, QueryListReportsArgs>
      query={gql`
       query ListReports($input: ListReportsInput!) {
        listReports(input: $input) {
          edges {
            cursor
            node {
              id
              dateCreated
              dateUpdated
              username
              members {
                id
                name
              }
              request {
                id
                initiatingMember {
                  id
                }
                productVariant
                pregnancy {
                  days
                  dueDate
                  dueDateConfirmed
                }
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
       }
    `}
      variables={{ input: filters }}
      notifyOnNetworkStatusChange
      errorPolicy="all"
      itemsPerPage={20}
      pageInfoSelector="listReports.pageInfo"
      edgeSelector="listReports.edges"
    >
      {(data, isLoading, reloadData) => {
        return props.children(processReportEdges(data), setFilters, isLoading, reloadData);
      }}
    </GeniePagedQuery>
  );
};

export default ReportsList;
