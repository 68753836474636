import * as React from 'react';
import { Member } from '../../../graphql/genie-api-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { SearchState } from '../../store/search/types';
import SearchModeSelector from '../../containers/search/SearchModeSelector';
import MemberSearchQuery from './search/MemberSearchQuery';
import DoctorSearchQuery from './search/DoctorSearchQuery';
import ErrorBoundary from '../../base/ErrorBoundary';

export type GenieSearchResult = Pick<Member, 'id' | 'name' | 'dateOfBirth'>;

class GenieSearch extends React.PureComponent<SearchState> {
  renderSearchMode() {
    const { searchMode } = this.props;
    switch (searchMode) {
      case 'member':
        return (
          <MemberSearchQuery />
        );

      case 'doctor':
        return (
          <DoctorSearchQuery />
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        <ErrorBoundary>
          <SearchModeSelector />
          {this.renderSearchMode()}
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = ({ search }: ApplicationState) => ({
  ...search,
});

export default connect<SearchState>(mapStateToProps)(GenieSearch);
