import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormGroup from '../widgets/form/FormGroup';
import MemberSearchAutocomplete from '../../graphql/containers/members/MemberSearchAutocomplete';
import MultiModelForm from '../../screens/MultiModelForm';
import { AnyModelResult } from '../../graphql/containers/SwitchForm';
import { Member } from '../../../graphql/genie-api-types';

interface FamilyHistoryReassignFormProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  initialValues?: {
    member: Member;
  };
}

const FamilyHistoryReassignForm = (props: FamilyHistoryReassignFormProps) => {
  const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = props;

  return (
    <MultiModelForm<'member'>
      modelForms={{
        member: 'MemberCreate',
      }}
      initialModelValues={{
        member: initialValues?.member as AnyModelResult,
      }}
    >
      {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
        <BaseForm
          initialValues={{}}
          submitButtonLabel="Save"
          hasCancel={!!onCancel}
          onCancel={onCancel}
          errorMessage={errorMessage}
          isLoading={isLoading}
          onSubmit={(values) => onSubmit({
            ...values,
            ...modelValues,
          })}
        >
          {() => (
              <div>
                <FormGroup
                  controlId="member"
                  label="Member to assign to"
                  labelAction={{
                    label: '+ New',
                    onClick: () => onSwitchToModelForm('member'),
                  }}
                >
                  <MemberSearchAutocomplete
                    onSelect={member => onChangeModelValue('member', member)}
                    defaultValue={modelValues.member}
                  />
                </FormGroup>
              </div>
            )}
        </BaseForm>
      )}
    </MultiModelForm>
  );
}

export default FamilyHistoryReassignForm;
