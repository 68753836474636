import { Extract, PregnancyMethod, ReportType, RiskStatus, Member as ExtractMember, Gender, Test, TestType, Condition } from '@eugenelabs/types--research-extract';
import gql from 'graphql-tag';
import wordsToNumbers from 'words-to-numbers';
import { ReportState } from '../../app/store/report';
import { SummaryState } from '../../app/store/report/summary/types';
import { ClientFields } from '../../app/store/report/client-details/types';
import {
  DoctorClinic,
  FamilyHistoryCategory,
  FamilyHistoryItem,
  Journey,
  JourneyState,
  Member,
  Pregnancy,
  ProductVariant,
  QueryRequestArgs,
  Report,
  Request,
  Sex
} from '../../graphql/genie-api-types';
import { appSyncClient } from '../appSync';
import moment from 'moment';
import { RelationshipStatus } from '@eugenelabs/types--research-extract/RelationshipStatus';
import { flatten, uniq } from 'lodash';
import { PregnancyChallenges } from '@eugenelabs/types--research-extract/PregnancyChallenges';
import { AffectedGenesCondition } from '@eugenelabs/types--research-extract/AffectedGenesCondition';
import { getDiseaseIdForGene, getFieldOverride } from '../../app/store/report/selectors';
import { AnalysisOverrides } from '../../app/store/report/analysis/types';

const REQUEST_QUERY = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      product # report_type, test_type
      productVariant # report_type, test_type
      initiatingMember {
        id
      }
      report {
        id
        data # results_date, risk_status, ethnicity, test.test_breadth, test.affected_genes, test.pseudo_genes
      }
      pregnancy {
        dueDate # due date
      }
      referringDoctorClinic {
        id
      }
      journeys {
        id
        state
        labOrderNumber # test.request_id
        partnerCanReceiveResults
        member {
          id # members[].id
          sex # members[].gender
          dateOfBirth # members[].test.age_at_test
          familyHistoryGroup { 
            id
            name # children, pregnancy_challenges, method
            value # children, pregnancy_challenges, method
            category # children
          }
        }
      }
    }
  }
`;

interface MemberResult extends Pick<Member, 'id' | 'sex' | 'dateOfBirth'> {
  familyHistoryGroup: Pick<FamilyHistoryItem, 'id' | 'name' | 'value' | 'category'>[];
}

interface JourneyResult extends Pick<Journey, 'id' | 'labOrderNumber' | 'state' | 'partnerCanReceiveResults'> {
  member: MemberResult;
}

export interface RequestResult extends Pick<Request, 'id' | 'product' | 'productVariant' | 'initiatingMember' | 'primaryCounsellor' | 'riskStatus'> {
  report: Pick<Report, 'id' | 'data'>;
  pregnancy?: Pick<Pregnancy, 'dueDate'>;
  referringDoctorClinic?: Pick<DoctorClinic, 'id'>;
  journeys: JourneyResult[];
}

interface QueryResult {
  request: RequestResult;
}

interface JourneyWithReportClientFields {
  journey: JourneyResult;
  details?: ClientFields;
}

const getReportId = (requestResult: RequestResult) => requestResult.id;

const getReportType = (report: ReportState): ReportType => {
  const hasSecondClient = !!report?.clientDetails?.clientB?.fullName;
  return hasSecondClient ? 'couple' : 'individual';
};

const getResultsDate = (report: ReportState): string =>
  report?.clientDetails?.clientA?.reportProcessedDate ?? '';

const getRiskStatus = (report: ReportState): RiskStatus => {
  const riskStatusMap: { [key in SummaryState['reproductiveRisk']]: RiskStatus } = {
    '': null,
    'High': 'high',
    'Increased': 'increased',
    'Low': 'low',
  };
  return riskStatusMap?.[report?.summary?.fieldOverrides?.reproductiveRisk as SummaryState['reproductiveRisk']] ?? null;
}

const getDueDate = (requestResult: RequestResult) =>
  requestResult.pregnancy?.dueDate;

const getChildren = (requestResult: RequestResult) => {
  const familyHistoryGroupItem = requestResult.journeys?.[0]?.member?.familyHistoryGroup?.filter(familyHistoryGroupItem => familyHistoryGroupItem.category === FamilyHistoryCategory.Children)
    .find(FamilyHistoryItem => FamilyHistoryItem.name === 'Description');
  if (!familyHistoryGroupItem) {
    return 0;
  }

  return parseInt(wordsToNumbers(familyHistoryGroupItem?.value, { fuzzy: true }) as string);
};

const getPregnancyChallenges = (requestResult: RequestResult): PregnancyChallenges => {
  const femaleMember = requestResult.journeys?.map(journey => journey.member)
    .find(member => member?.sex === Sex.Female);

  const pregnancyChallengesFamilyHistoryItem = femaleMember?.familyHistoryGroup?.filter(familyHistoryGroupItem => familyHistoryGroupItem.category === FamilyHistoryCategory.Pregnancy)
    .find(item => item.name === 'Challenges');
  if (pregnancyChallengesFamilyHistoryItem && !['I\'d rather not say', 'I haven\'t'].includes(pregnancyChallengesFamilyHistoryItem.value)) {
    return 'previous affected pregnancy';
  }

  return undefined;
}

const getPregnancyMethods = (requestResult: RequestResult): PregnancyMethod[] => {
  const familyHistories = flatten(requestResult.journeys?.map(journey => journey?.member.familyHistoryGroup) ?? []);
  const familyHistoryGroupItems = familyHistories.filter(familyHistoryGroupItem => familyHistoryGroupItem.category === FamilyHistoryCategory.Pregnancy)
    .filter(item => item.name === 'Method');
  if (familyHistoryGroupItems.length === 0) {
    return [];
  }

  const items = uniq(flatten(familyHistoryGroupItems.map(familyHistoryGroupItem => familyHistoryGroupItem.value.replace('[', '').replace(']', '').split(', '))));
  const pregnancyMethodMap: { [key: string]: PregnancyMethod } = {
    'The old fashioned way': 'naturally',
    'Donor': 'donor',
    'Assisted fertility (eg: OI/IVF)': 'ivf',
    'Surrogate': 'surrogate',
    'Something else': 'other',
  };

  return items.map(item => pregnancyMethodMap?.[item] ?? 'other');
}

const getGender = (journeyClientFieldCombo: JourneyWithReportClientFields) => {
  const memberSex = journeyClientFieldCombo?.journey?.member?.sex;
  const sexGenderMap: { [key in Sex]: Gender } = {
    [Sex.Female]: 'female',
    [Sex.Male]: 'male',
  };

  return sexGenderMap[memberSex];
}

const getReferringDoctorClinicId = (request: RequestResult) =>
  request.referringDoctorClinic?.id;

const getClientInitiated = (request: RequestResult) =>
  !getReferringDoctorClinicId(request);

const getEthnicity = (journeyClientFieldCombo: JourneyWithReportClientFields) => {
  const familyHistoryGroupItem = journeyClientFieldCombo.journey?.member?.familyHistoryGroup?.filter(familyHistoryGroupItem => familyHistoryGroupItem.category === FamilyHistoryCategory.Me)
    .find(item => item.name === 'Ethnicity');
  if (!familyHistoryGroupItem) {
    return null;
  }

  const items = familyHistoryGroupItem.value.replace('[', '').replace(']', '').split(',');

  return items.join(', ');
}

const getAgeAtTest = (journeyClientFieldCombo: JourneyWithReportClientFields) => {
  const testDate = moment(journeyClientFieldCombo.details?.reportProcessedDate ?? '');
  const dateOfBirth = moment(journeyClientFieldCombo.journey.member.dateOfBirth);

  return testDate.diff(dateOfBirth, 'years');
}

const getDateTested = (journeyClientFieldCombo: JourneyWithReportClientFields) =>
  journeyClientFieldCombo.details?.reportProcessedDate;

const getTestType = (requestResult: RequestResult) => {
  let productWithVariantCode: string = requestResult.product;

  switch (requestResult.productVariant) {
    case ProductVariant.Individual:
      productWithVariantCode += '_IN';
      break;

    case ProductVariant.Couple:
      productWithVariantCode += '_CP';
      break;

    case ProductVariant.Donor:
      productWithVariantCode += '_DONOR';
      break;
  }

  return productWithVariantCode as TestType;
};

const getTestBreadth = (report: ReportState) =>
  report?.testInformation?.numberOfGenesTested ?? null;

const getAffectedGenes = (journeyClientFieldCombo: JourneyWithReportClientFields, report: ReportState) => {
  const genes = journeyClientFieldCombo.details?.affectedGenes ?? [];
  const conditions: Partial<AffectedGenesCondition>[] = genes.map(gene => {
    const condition: Partial<AffectedGenesCondition> = {
      gene,
      disease_id: report?.clientDetails?.diseasesByGene?.[gene]?.id,
      disease_name: report?.clientDetails?.diseasesByGene?.[gene]?.name,
    };

    const isClientA = journeyClientFieldCombo.details === report.clientDetails.clientA;
    const isClientB = journeyClientFieldCombo.details === report.clientDetails.clientB;

    if (isClientA || isClientB) {
      const diseaseId = getDiseaseIdForGene(gene, report.clientDetails);
      let fieldName: keyof AnalysisOverrides;
      if (isClientA) {
        fieldName = 'clientACarrierStatus';
      }
      else if (isClientB) {
        fieldName = 'clientBCarrierStatus';
      }

      const hasCarrierOverride = !!getFieldOverride(diseaseId, fieldName, report.analysis);
      if (!hasCarrierOverride) {
        // If the  carrier hasn't been overriden, assume it's classic variant.
        condition.variants = ['classic'];
      }
    }

    return condition;
  });

  return conditions;
}

const getPseudoGenes = (journeyClientFieldCombo: JourneyWithReportClientFields, report: ReportState) => {
  const genes = journeyClientFieldCombo.details?.pseudogenes ?? [];
  const conditions: Condition[] = genes.map(gene => ({
    gene,
    disease_id: report?.clientDetails?.pseudoDiseasesByGene?.[gene]?.id,
    disease_name: report?.clientDetails?.pseudoDiseasesByGene?.[gene]?.name,
  }));

  return conditions;
}

const getRelationshipStatus = (report: ReportState): RelationshipStatus => {
  const { clientA, clientB } = report?.clientDetails;

  if (!clientB.fullName) {
    return 'single';
  }

  return clientA.sex === clientB.sex ? 'same-sex' : 'hetero';
}

const getMemberTest = (requestResult: RequestResult, journeyClientFieldCombo: JourneyWithReportClientFields, report: ReportState): Test => {
  return {
    request_id: journeyClientFieldCombo.journey.labOrderNumber,
    location: null, // Can't be automatically filled.
    age_at_test: getAgeAtTest(journeyClientFieldCombo),
    date_tested: getDateTested(journeyClientFieldCombo),
    test_type: getTestType(requestResult),
    test_breadth: getTestBreadth(report),
    affected_genes: getAffectedGenes(journeyClientFieldCombo, report) as AffectedGenesCondition[],
    pseudo_genes: getPseudoGenes(journeyClientFieldCombo, report),
    relationship_status: getRelationshipStatus(report),
  }
}

const getMembers = (requestResult: RequestResult, report: ReportState): ExtractMember[] => {
  const journeys = requestResult?.journeys?.filter(journey => journey.state !== JourneyState.Trash);
  const { clientA, clientB } = report?.clientDetails;
  const clients = [clientA, clientB];

  const journeysWithReportClientFields: JourneyWithReportClientFields[] = journeys.map(journey => {
    return {
      journey,
      details: clients.find(client => journey.member.dateOfBirth === client.dob),
    };
  }).sort(item => clients.indexOf(item.details) === 0 ? -1 : 1); // Sort by client order.

  return journeysWithReportClientFields.map(journeyClientFieldCombo => ({
    member_id: journeyClientFieldCombo?.journey?.member?.id,
    gender: getGender(journeyClientFieldCombo),
    self_reported_ethnicity: getEthnicity(journeyClientFieldCombo),
    english_speaking: true,
    referring_doctor_id: getReferringDoctorClinicId(requestResult),
    client_initiated: getClientInitiated(requestResult),
    referral_source: null,
    test: getMemberTest(requestResult, journeyClientFieldCombo, report),
    member_history: [],
  }));
}

// Exported for testing.
export const generatedExtractDataFromRequestResult = (request: RequestResult): Partial<Extract> => {
  const report = JSON.parse(request.report.data ?? '{}') as ReportState;

  return {
    report_id: getReportId(request),
    report_type: getReportType(report),
    results_date: getResultsDate(report),
    risk_status: getRiskStatus(report),
    due_date: getDueDate(request),
    children: getChildren(request),
    pregnancy_challenges: getPregnancyChallenges(request),
    pregnancy_method: getPregnancyMethods(request),
    members: getMembers(request, report),
  };
}

const generatedExtractFromRequest = async (requestId: Request['id']): Promise<Partial<Extract>> => {
  const requestResult = await appSyncClient.query<QueryResult, QueryRequestArgs>({
    query: REQUEST_QUERY,
    variables: {
      id: requestId,
    },
    fetchPolicy: 'network-only',
  });

  const { request } = requestResult.data;

  return generatedExtractDataFromRequestResult(request);
};

export default generatedExtractFromRequest;