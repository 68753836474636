import * as React from 'react';
import { JourneyState } from '../../../graphql/genie-api-types';
import Screen from '../../components/widgets/Screen';
import ReportsTaskTable from '../../components/report/ReportsTaskTable';

const TasksReviewReports = () => {
  return (
    <Screen
      screenTitleProps={{
        title: 'Review reports',
      }}
    >
      <ReportsTaskTable journeyState={JourneyState.ReadyForReportReview} />
    </Screen>
  );
};

export default TasksReviewReports;
