import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import RequestForm, { RequestFormValues } from '../../../components/request/RequestForm';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import { convertLocalToUTC } from '../../../../utilities/helpers';

export interface RequestCreateProps {
  onComplete(): void;
}

class RequestCreate extends React.PureComponent<RequestCreateProps> {
  onSaveRequest = async ({ referringDoctorClinic, primaryCounsellor, initiatingMember, partner, datePaid, productAndVariant, ...restOfFormValues }: RequestFormValues, mutate: MutationFunction) => {
    const { onComplete } = this.props;
    
    const { product, productVariant } = productAndVariant;

    await mutate({
      variables: {
        input: {
          datePaid: convertLocalToUTC(datePaid),
          initiatingMemberId: initiatingMember?.id,
          partnerId: partner?.id,
          referringDoctorClinicId: referringDoctorClinic?.id,
          primaryCounsellorId: primaryCounsellor?.id,
          product,
          productVariant,
          ...restOfFormValues,
        },
      },
    });

    ReduxQueryListener.triggerEvents('All');
    showToast('success', 'Created request', 'Successfully saved request');

    onComplete();
  }

  render() {
    return (
      <GenieMutation
        mutation={gql`
          mutation CreateRequest($input: CreateRequestInput!) {
            createRequest(input: $input) {
              request {
                id,
              }
            }
          }
        `}
      >
        {(mutate, result) => (
          <RequestForm
            initialValues={{}}
            onSubmit={(values: RequestFormValues) => this.onSaveRequest(values, mutate)}
            isLoading={result.loading}
            isCreate
            errorMessage={result?.error?.message}
          />
        )}
      </GenieMutation>
    );
  }
}

export default RequestCreate;
