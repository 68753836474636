import React from 'react';
import { connect } from 'react-redux';
import { DoctorSearchState } from '../../store/search/types';
import { changeDoctorSearch } from '../../store/search/actions';
import { ApplicationState } from '../../store';
import SearchForm from '../../components/widgets/SearchForm';
import { Dispatch } from 'redux';

interface OwnProps {
  isLoading?: boolean;
}

interface DispatchProps {
  onChangeSearchTerm(searchTerm: string): void;
}

type AllProps = OwnProps & DispatchProps &DoctorSearchState;

const DoctorSearch = (props: AllProps) => (
  <div className="max-w-3xl">
    <SearchForm
      onSearchChange={props.onChangeSearchTerm}
      isLoading={props.isLoading}
      defaultSearchTerm={props.searchTerm || ''}
      placeholder="Search by name, or clinic ..."
      autoFocus
    />
  </div>
);

const mapStateToProps = ({ search }: ApplicationState) => ({
  ...search.doctorSearchState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchTerm: (searchTerm: string) => dispatch(changeDoctorSearch({ searchTerm })),
});

export default connect<DoctorSearchState, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(DoctorSearch);
