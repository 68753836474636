import * as React from 'react';
import { connect } from 'react-redux';
import { ClientDetailsState, ClientFields, ClientId } from '../../../store/report/client-details/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { CarrierRatesState, RateType, SelectedClientEthnicities } from '../../../store/report/carrier-rates/types';
import EditArea from '../../../components/widgets/EditArea';
import { PATHS } from '../../../store/router/types';
import Spinner from '../../../components/widgets/Spinner';
import { navigate } from '../../../store/router/actions';

interface StateProps extends CarrierRatesState, ClientDetailsState {
  diseasesByGene: ClientDetailsState['diseasesByGene'];
  isComplete: boolean;
}

interface DispatchProps {
  onCarrierRateEdit(gene: string, client: ClientId): void;
  onToggleComplete(): void;
}

interface CarrierRatesViewProps extends StateProps, DispatchProps {

}

const getTitleOverride = (clientFields: ClientFields, defaultName: string) => {
  const name = clientFields.nickname || defaultName;
  const ethnicity = clientFields.ethnicity ? clientFields.ethnicity : 'No ethnicity';

  return `${name} (${ethnicity})`;
};

const renderCarrierRateField = (client: ClientId, gene: string, props: CarrierRatesViewProps) => {
  const clientFields = client === 'A' ? props.clientA : props.clientB;
  const type: RateType = clientFields.affectedGenes.includes(gene) ? 'carrier_rate' : 'residual_risk';

  const selectedStatistics: SelectedClientEthnicities = props.selectedCarrierRateEthnicitiesByGene[gene] || {};

  const selectedEthnicity = selectedStatistics[client];

  const selectedRate = props.carrierRatesByGene[gene] ? props.carrierRatesByGene[gene].find(option => option.ethnicity && option.ethnicity.id === selectedEthnicity) : null;
  const rateDescription = selectedRate ? (type === 'carrier_rate' ? selectedRate.carrierRateDesc : selectedRate.residualRiskDesc) : '';

  return (
    <EditArea onClick={() => props.onCarrierRateEdit(gene, client)}>
      <h3>{type === 'carrier_rate' ? 'Carrier rate' : 'Residual risk'}</h3>
      <p>
        {rateDescription} ({selectedRate ? selectedRate.ethnicity.name : 'Not set'})
      </p>
    </EditArea>
  );
};

const CarrierRatesView = (props: CarrierRatesViewProps) => {
  const { diseasesByGene, isLoadingDiseases } = props;

  return (
    <div>
      <TaskListScreenTitle
        title="Carrier Rates"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-10 border-b-2 text-left">
              Disease
            </th>
            <th className="p-10 border-b-2 text-left">
              {getTitleOverride(props.clientA, 'Client A')}
            </th>
            <th className="p-10 border-b-2 text-left">
              {getTitleOverride(props.clientB, 'Client B')}
            </th>
          </tr>
        </thead>
        {isLoadingDiseases ? (
          <tbody>
            <tr>
              <td colSpan={3}>
                <Spinner label="Loading disease data" />
              </td>
            </tr>
          </tbody>
        ) : (
            <tbody>
              {Object.keys(diseasesByGene).map(gene => (
                <tr key={gene}>
                  <td className="px-10 py-20 font-bold w-1/3">
                    {diseasesByGene[gene].name}
                  </td>
                  <td key="clientA" className="px-10 py-20 w-1/3">
                    {renderCarrierRateField('A', gene, props)}
                  </td>
                    <td key="clientB" className="px-10 py-20 w-1/3">
                    {renderCarrierRateField('B', gene, props)}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
      </table>
      <p className="mt-100">Note: You can alter carrier rates within the descriptions for each disease in the Analysis section.</p>
    </div>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  ...report.carrierRates,
  ...report.clientDetails,
  isComplete: report.taskList.completedTasks.carrierRates,
  diseasesByGene: report.clientDetails.diseasesByGene,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onToggleComplete: () => dispatch(toggleTask('carrierRates')),
  onCarrierRateEdit: (gene: string, client: ClientId) => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CARRIER_RATES_EDIT, `gene=${gene}&client=${client}`)),
});

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(CarrierRatesView);
