import * as React from 'react';
import { startCase } from 'lodash';
import ReactMarkdown from 'react-markdown';
import EditArea from '../../../components/widgets/EditArea';
import Heading from '../../widgets/Heading';
import ActionLink from '../../widgets/ActionLink';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../store';
import { changeModification } from '../../../store/report/analysis/actions';
import { Modifications } from '../../../store/report/analysis/types';

interface DiseaseRiskExpansionItemProps {
  onResetClick(): void;
  onEditClick(): void;
  isOverridden?: boolean;
  fieldName: string;
  fieldValue: string | string[];
  titleOverride?: string;
  diseaseId: string;
}

interface Accessory {
  modificationName: keyof Modifications;
  value: any;
  label: string;
  color: 'red' | 'green';
}

const DiseaseRiskExpansionItem = (props: DiseaseRiskExpansionItemProps) => {
  const addPathogenicMutationsToCarrierInfo = useSelector<ApplicationState>(state => state.report?.analysis.modifications?.[props.diseaseId]?.addPathogenicMutationsToCarrierInfo ?? false);
  const addPathogenicMutationsToNextSteps = useSelector<ApplicationState>(state => state.report?.analysis.modifications?.[props.diseaseId]?.addPathogenicMutationsToNextSteps ?? false);
  const dispatch = useDispatch();

  const accessories = React.useMemo(() => {
    if (props.isOverridden) {
      return [];
    }

    const accessories: Accessory[] = [];

    switch (props.fieldName) {
      case 'carrierInfo':
        accessories.push({
          modificationName: 'addPathogenicMutationsToCarrierInfo',
          value: !addPathogenicMutationsToCarrierInfo,
          color: addPathogenicMutationsToCarrierInfo ? 'red' : 'green',
          label: `${addPathogenicMutationsToCarrierInfo ? 'Remove' : 'Add'} likely pathogenic mutations`,
        });
        break;
        
      case 'individualCarrierNextSteps':
      case 'affectedCoupleOptions':
        accessories.push({
          modificationName: 'addPathogenicMutationsToNextSteps',
          value: !addPathogenicMutationsToNextSteps,
          color: addPathogenicMutationsToNextSteps ? 'red' : 'green',
          label: `${addPathogenicMutationsToNextSteps ? 'Remove' : 'Prepend'} likely pathogenic mutations`,
        });
        break;
    }

    return accessories.map(accessory => (
      <ActionLink
        key={accessory.label}
        className={`mr-10 text-sm text-${accessory.color}`}
        onClick={() => {
          dispatch(changeModification(props.diseaseId, accessory.modificationName, accessory.value));
        }}
      >
        {accessory.label}
      </ActionLink>
    ));
  }, [props.fieldName, props.isOverridden, addPathogenicMutationsToCarrierInfo, addPathogenicMutationsToNextSteps, dispatch]);

  return (
    <div className="disease-risk-expansion__field" key={props.fieldName}>
      <div className="flex justify-between">
        <Heading level={3} className="mb-10">{props.titleOverride || startCase(props.fieldName)}</Heading>
        <div>
          {accessories}
          {props.isOverridden ? <ActionLink onClick={() => props.onResetClick()} className="text-sm">Reset</ActionLink> : ''}
        </div>
      </div>
      <EditArea
        onClick={props.onEditClick}
        isOverridden={props.isOverridden}
      >
        {Array.isArray(props.fieldValue) ? props.fieldValue.join(',') : <ReactMarkdown source={props.fieldValue as string} />}
      </EditArea>
    </div>
  );
}

export default DiseaseRiskExpansionItem;
