import { Kit, Journey, Request, KitScope } from '../../graphql/genie-api-types';
import { upperFirst } from 'lodash';

type KitPartial = Pick<Kit, 'dateArrivedAtEugene'|'dateShippedToMember'|'recollectionRequestedDate'|'dateShippedToLab'>;
type JourneyPartial = Pick<Journey, 'hasConsent'|'labOrderNumber'>; 
type RequestPartial = Pick<Request, 'gcApprovalDate'|'datePaid'>;

type KitScopeWithComplete = KitScope|'COMPLETE'|'AWAITING_PAYMENT';

export const getKitScope = (kit: KitPartial, journey: JourneyPartial, request: RequestPartial): KitScopeWithComplete|null => {
  if (kit.recollectionRequestedDate) {
    return KitScope.Recollected;
  }
  
  if (kit.dateShippedToLab) {
    return 'COMPLETE';
  }
  
  if (!request.datePaid) {
    return 'AWAITING_PAYMENT';
  }

  if (!kit.dateShippedToMember) {
    return KitScope.ReadyForMemberShipping;
  }
  
  if (!kit.dateArrivedAtEugene) {
    return KitScope.WaitingForReturnShipping;
  }
  
  if (!journey.hasConsent) {
    return KitScope.RequireConsent;
  }
  if (!journey.labOrderNumber) {
    return KitScope.RequireLabOrder;
  }
  if (!request.gcApprovalDate) {
    return KitScope.RequireApproval;
  }
  
  return KitScope.ReadyForLabShipping;
};

export const getKitScopeLabel = (kitScopeWithComplete: KitScopeWithComplete) => {
  return upperFirst(kitScopeWithComplete.toLowerCase().replace(/_/g, ' '));
}