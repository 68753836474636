import * as React from "react";
import Button from "../../../widgets/Button";
import FormCheck from "../../../widgets/form/FormCheck";
import FormControl from "../../../widgets/form/FormControl";
import LoadingOverlay from "../../../widgets/LoadingOverlay";

export type FormPayload = {
  displayAdvForm: boolean;
  avgPreTat: number;
  avgPostTat: number;
  cancerPretestApptTime: number;
  cancerPosttestApptTime: number;
  cancerFhxreviewApptTime: number;
  carrierPretestApptTime: number;
  carrierFhxreviewApptTime: number;
  carrierLowriskApptTime: number;
  carrierIncreasedriskApptTime: number;
  carrierHighriskApptTime: number;
  cancerPretestRatio: number;
  cancerPosttestRatio: number;
  cancerFhxreviewRatio: number;
  carrierPretestRatio: number;
  carrierFhxreviewRatio: number;
  carrierLowriskRatio: number;
  carrierIncreasedriskRatio: number;
  carrierHighriskRatio: number;
  howManyWeeksToProject: number;
};
interface SettingsFormProps {
  onSubmit(values: FormPayload): Promise<void>;
  isLoading: boolean;
  initialValues: FormPayload;
}

interface SettingsControlProps {
  label: string;
  formPayload: FormPayload;
  settingsName: keyof FormPayload;
  updateState(settingsName: keyof FormPayload, e: React.ChangeEvent<HTMLTextAreaElement>): void;
}

const SettingsControl = (props: SettingsControlProps) => {
  const { label, formPayload, settingsName, updateState } = props;
  return (
    <>
      <p className="mb-4 text-xs">{label}</p>
      <FormControl
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { updateState(settingsName, e) }}
        name="text"
        type="number"
        value={formPayload[settingsName]}
        min="0"
        autoFocus={true}
      />
    </>
  );
}

class SettingsForm extends React.PureComponent<SettingsFormProps, FormPayload> {
  state: Readonly<FormPayload> = { ...this.props.initialValues };

  onSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();

    const { onSubmit } = this.props;

    try {
      await onSubmit(this.state);
    } catch (e) {
      console.log(e);
    }
  };

  toggleDisplayAdvForm = () => {
    this.setState({
      displayAdvForm: !this.state.displayAdvForm,
    });
  };

  onFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const didPressCommandEnter = e.keyCode === 13 && e.metaKey;
    if (!didPressCommandEnter) return true;

    this.onSubmit();
  };

  onValChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) {
      return parseInt(e.target.value);
    } else {
      return 0;
    }
  }

  updateState = (stateKey: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = this.onValChange(e);
    this.setState(JSON.parse('{"' + stateKey + '":' + newVal + '}'));
  }

  render() {
    const { isLoading } = this.props;
    const { displayAdvForm } = this.state;

    return (
      <form onSubmit={this.onSubmit} onKeyDown={this.onFormKeyDown}>
        {isLoading ? <LoadingOverlay label="Recalculating..." /> : null}

        <div className="flex flex-row items-end justify-between">
          <div className="flex flex-col flex-1 pr-12">
            <SettingsControl label="Average Pretest TAT (days)" formPayload={this.state} settingsName="avgPreTat" updateState={this.updateState.bind(this)} />
          </div>
          <div className="flex flex-col flex-1 pr-12">
            <SettingsControl label="Average Posttest TAT (days)" formPayload={this.state} settingsName="avgPostTat" updateState={this.updateState.bind(this)} />
          </div>
          <div className="flex flex-col flex-1 pr-12">
            <SettingsControl label="Weeks to project (weeks)" formPayload={this.state} settingsName="howManyWeeksToProject" updateState={this.updateState.bind(this)} />
          </div>
          <div className="flex flex-col flex-1 justify-end items-end">
            <FormCheck
              type="checkbox"
              label="Show more configs"
              name="advFilter"
              checked={displayAdvForm}
              className="mb-20"
              onChange={this.toggleDisplayAdvForm}
            />
            <Button
              type="submit"
              disabled={isLoading}
              variant="primary"
              size="md"
              className="w-full py-14">
              {isLoading ? "Calculating..." : "Calculate"}
            </Button>
          </div>
        </div>

        <div className={`pt-20 ${displayAdvForm ? "" : "hidden"}`}>
          <hr className="mb-30"></hr>
          <h4 className="mb-14 font-bold text-lg">Cancer</h4>
          <div className="flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Pretest Appointment (minutes)" formPayload={this.state} settingsName="cancerPretestApptTime" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="FHx Appointment (minutes)" formPayload={this.state} settingsName="cancerFhxreviewApptTime" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1">
              <SettingsControl label="Posttest Appointment (minutes)" formPayload={this.state} settingsName="cancerPosttestApptTime" updateState={this.updateState.bind(this)} />
            </div>
          </div>
          <div className="mt-14 flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Pretest Ratio (%)" formPayload={this.state} settingsName="cancerPretestRatio" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="FHx Ratio (%)" formPayload={this.state} settingsName="cancerFhxreviewRatio" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1">
              <SettingsControl label="Posttest Ratio (%)" formPayload={this.state} settingsName="cancerPosttestRatio" updateState={this.updateState.bind(this)} />
            </div>
          </div>
          <hr className="my-30"></hr>

          <h4 className="mt-20 mb-14 font-bold text-lg">Carrier</h4>
          <div className="flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Pretest Appointment (minutes)" formPayload={this.state} settingsName="carrierPretestApptTime" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1">
              <SettingsControl label="FHx Appointment (minutes)" formPayload={this.state} settingsName="carrierFhxreviewApptTime" updateState={this.updateState.bind(this)} />
            </div>
          </div>
          <div className="mt-14 flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Pretest Ratio (%)" formPayload={this.state} settingsName="carrierPretestRatio" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1">
              <SettingsControl label="FHx Ratio (%)" formPayload={this.state} settingsName="carrierFhxreviewRatio" updateState={this.updateState.bind(this)} />
            </div>
          </div>
          <div className="mt-14 flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Low Risk Appointment (minutes)" formPayload={this.state} settingsName="carrierLowriskApptTime" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Increased Risk Appointment (minutes)" formPayload={this.state} settingsName="carrierIncreasedriskApptTime" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="High Risk Appointment (minutes)"
                formPayload={this.state} settingsName="carrierHighriskApptTime" updateState={this.updateState.bind(this)} />
            </div>
          </div>
          <div className="mt-14 mb-50 flex flex-row items-end justify-between">
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Low Risk Ratio (%)"
                formPayload={this.state} settingsName="carrierLowriskRatio" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="Increased Risk Ratio (%)"
                formPayload={this.state} settingsName="carrierIncreasedriskRatio" updateState={this.updateState.bind(this)} />
            </div>
            <div className="flex flex-col flex-1 pr-12">
              <SettingsControl label="High Risk Ratio (%)"
                formPayload={this.state} settingsName="carrierHighriskRatio" updateState={this.updateState.bind(this)} />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default SettingsForm;
