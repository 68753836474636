import { ReportState } from '../../../app/store/report';
import { AnalysisOverrides, CarrierRisk } from '../../../app/store/report/analysis/types';
import { ClientDetailsState, ClientFields } from '../../../app/store/report/client-details/types';
import { TestInformationState } from '../../../app/store/report/test-information/types';
import { getSummaryStateMergedWithOverrides } from '../../../app/store/report/summary/selectors';
import { getAnalysisStateMergedWithOverrides } from '../../../app/store/report/selectors';
import { intersection, difference, sortBy, keyBy, mapValues, map } from 'lodash';
import { areBothClientsCarriers } from '../../analysis/analysisDefaults';
import { CustomisationState } from '../../../app/store/report/customisation/types';
import config from '../../config';
import { AdditionalInformationState } from '../../../app/store/report/additional-information/types';

interface DiseasePageVariables extends AnalysisOverrides {
  diseaseClass: 'increased-risk' | 'high-risk' | 'low-risk-bb' | 'result-to-note';
  diseaseClassColor: 'pink' | 'orange' | 'dk-green' | 'grey';
  areBothClientsCarriers: boolean;
  sectionNumber: number;
}

export interface ReportVariables {
  sharedAffectedDiseases: string[];
  clientAExclusiveAffectedDiseases: string[];
  clientBExclusiveAffectedDiseases: string[];
  increasedRiskDiseases: AnalysisOverrides[];
  highRiskDiseases: AnalysisOverrides[];
  lowRiskDiseases: AnalysisOverrides[];
  resultToNoteDiseases: AnalysisOverrides[];
  clientDetails: ClientDetailsState;
  summary: {
    reproductiveRisk: string;
    whatWeKnow: string;
    quickNote: string;
    importantNote: string;
    whatWeFound: string;
  };
  testInformation: TestInformationState;
  customisation: CustomisationState;
  totalExclusiveAffectedGenes: number;
  isHighRisk: boolean;
  hasNoDiseases: boolean;
  diseaseNamesByRiskLevel: {
    lowRisk: string[];
    highRisk: string[];
    increasedRisk: string[];
    resultToNote: string[];
  };
  orderedDiseases: DiseasePageVariables[];
  assetHost: string;
  sectionNumbersByDiseaseId: { [key: string]: number };
  diseaseCount: number;
  xxMember?: ClientFields;
  additionalInformation: AdditionalInformationState;
}

const getDiseaseClassForCarrierRisk = (carrierRisk: CarrierRisk) => {
  switch (carrierRisk) {
    case 'Increased Risk':
      return 'increased-risk';

    case 'High Risk':
      return 'high-risk';

    case 'Low Risk':
      return 'low-risk-bb';

    case 'Result to note':
      return 'result-to-note';
  }
};

const getDiseaseClassColorForCarrierRisk = (carrierRisk: CarrierRisk) => {
  switch (carrierRisk) {
    case 'Increased Risk':
      return 'pink';

    case 'High Risk':
      return 'orange';

    case 'Low Risk':
      return 'dk-green';

    case 'Result to note':
      return 'grey';
  }
};

export const extractReportTemplateVariables = (reportState: ReportState): ReportVariables => {
  const { clientDetails, testInformation, customisation, additionalInformation } = reportState;
  const { clientA, clientB } = clientDetails;

  const summary = getSummaryStateMergedWithOverrides(reportState.summary);

  // Get diseases merged with overrides.
  const { analysisStateByGene } = getAnalysisStateMergedWithOverrides(reportState);

  const sharedAffectedDiseases = intersection(clientA.affectedGenes, clientB.affectedGenes).map((gene: string) =>
    analysisStateByGene[gene].name);

  const clientAExclusiveAffectedDiseases = difference(clientA.affectedGenes, clientB.affectedGenes).map((gene: string) =>
    analysisStateByGene[gene].name);
  const clientBExclusiveAffectedDiseases = difference(clientB.affectedGenes, clientA.affectedGenes).map((gene: string) =>
    analysisStateByGene[gene].name);

  const diseaseSort = (analysisState: AnalysisOverrides) => {
    // Use multiplier to sink client B results to end for grouping of low risk results
    const clientWeightMultiplier = analysisState.clientBCarrierStatus !== 'Not a carrier' ? 2 : 1;
    const diseaseName = analysisState.name.toLowerCase();

    switch (analysisState.carrierRisk) {
      case 'High Risk':
        return `00_${diseaseName}`;

      case 'Increased Risk':
        return `01_${diseaseName}`;

      case 'Result to note':
        return `0${2 * clientWeightMultiplier}_${diseaseName}`;

      case 'Low Risk':
        return `${10 * clientWeightMultiplier}_${diseaseName}`;
    }
  };

  const orderedDiseaseAnalysisStates = sortBy(analysisStateByGene, diseaseSort);

  // This is wrong - only accounts for single gene.
  const orderedAffectedGenes = orderedDiseaseAnalysisStates.map((analysisOverride: AnalysisOverrides) =>
    analysisOverride.genes[0]);

  const increasedRiskDiseases = orderedDiseaseAnalysisStates
    .filter((analysisState: AnalysisOverrides) => analysisState.carrierRisk === 'Increased Risk');

  const highRiskDiseases = orderedDiseaseAnalysisStates
    .filter((analysisState: AnalysisOverrides) => analysisState.carrierRisk === 'High Risk');

  const lowRiskDiseases = orderedDiseaseAnalysisStates
    .filter((analysisState: AnalysisOverrides) => analysisState.carrierRisk === 'Low Risk');

  const resultToNoteDiseases = orderedDiseaseAnalysisStates
    .filter((analysisState: AnalysisOverrides) => analysisState.carrierRisk === 'Result to note');

  const sectionNumbersByDiseaseId = mapValues(keyBy(
    orderedDiseaseAnalysisStates.map((value, index) => ({ pageNumber: index + 1, id: value.id }))
    , 'id'), 'pageNumber');

  const xxMember = [clientDetails.clientA, clientDetails.clientB].find((clientDetails) => clientDetails.sex === 'Female') ?? {};

  return {
    sharedAffectedDiseases,
    clientAExclusiveAffectedDiseases,
    clientBExclusiveAffectedDiseases,
    increasedRiskDiseases,
    highRiskDiseases,
    lowRiskDiseases,
    diseaseCount: orderedDiseaseAnalysisStates.length,
    resultToNoteDiseases,
    sectionNumbersByDiseaseId,
    clientDetails,
    summary,
    customisation,
    testInformation,
    totalExclusiveAffectedGenes: clientAExclusiveAffectedDiseases.length + clientBExclusiveAffectedDiseases.length,
    isHighRisk: ['High', 'Increased'].includes(summary.reproductiveRisk),
    hasNoDiseases: highRiskDiseases.length + lowRiskDiseases.length + resultToNoteDiseases.length === 0,
    orderedDiseases: orderedDiseaseAnalysisStates.map((analysisOverrides, diseaseIndex) => ({
      ...analysisOverrides,
      sectionNumber: diseaseIndex + 1,
      diseaseClass: getDiseaseClassForCarrierRisk(analysisOverrides.carrierRisk),
      diseaseClassColor: getDiseaseClassColorForCarrierRisk(analysisOverrides.carrierRisk),
      areBothClientsCarriers: areBothClientsCarriers(orderedAffectedGenes[diseaseIndex], clientA.affectedGenes, clientB.affectedGenes),
    })),
    diseaseNamesByRiskLevel: {
      lowRisk: map(lowRiskDiseases, 'name'),
      highRisk: map(highRiskDiseases, 'name'),
      increasedRisk: map(increasedRiskDiseases, 'name'),
      resultToNote: map(resultToNoteDiseases, 'name'),
    },
    assetHost: config('ASSET_HOST'),
    xxMember,
    additionalInformation,
  };
};
