import * as React from 'react';

import { Switch } from 'react-router';
import ChooseReport from './report/ChooseReport';
import ReportSession from './report/ReportSession';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';

const Report = () => {
  return (
    <Switch>
      <Route path={PATHS.REPORTS} exact component={ChooseReport} />
      <Route path={PATHS.REPORTS} component={ReportSession} />
    </Switch>
  );
};

export default Report;
