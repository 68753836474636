import { ReportTemplate } from "..";
import { TwoColSectionVariables } from "../sections/TwoColSection";
import { ClientDetailsVariables } from "../sections/ClientDetails";
import { DiseaseHeaderVariables } from "../sections/DiseaseHeader";
import { DiseaseTableVariables } from "../sections/DiseaseTable";
import { SectionVariables } from "../sections/Section";
import { IntroVariables } from "../sections/Intro";
import { PageTitleVariables } from "../sections/PageTitle";

export const individualCarrier: ReportTemplate = {
  label: "Carrier: Individual or Donor",
  name: "individualCarrier",
  medium: "pdfReport",
  pages: [
    {
      sections: [
        {
          id: "introHighRisk",
          dependencies: ["isHighRisk"],
          template: "Intro",
          variables: {
            title:
              "Hi {{clientDetails.clientA.nickname}}, your carrier screening results are in and",
            riskLevelColor: "orange",
            riskLevelPrefix: "we've identified a",
            riskLevel: "{{summary.reproductiveRisk}}",
            riskLevelSuffix: "reproductive risk.",
            optionsImage: true,
          } as IntroVariables,
        },
        {
          id: "intro",
          dependencies: ["!isHighRisk"],
          template: "Intro",
          variables: {
            title:
              "Hi {{clientDetails.clientA.nickname}}, your carrier screening results are in and",
            riskLevelColor:
              '{{#ifEquals summary.reproductiveRisk "Low"}}green{{else}}pink{{/ifEquals}}',
            riskLevelPrefix: `we've identified`,
            riskLevel: "",
            riskLevelSuffix:
              "{{#unless diseaseCount}}no{{/unless}} variations in {{#if diseaseCount}}{{diseaseCount}}{{else}}the screened{{/if}} genes.",
            optionsImage: false,
          },
        },
        {
          id: "whoTested",
          template: "ClientDetails",
          variables: {
            whoTestedTitle: "Who was tested",
            careTeamTitle: "Your care team",
            clientAName: "{{clientDetails.clientA.fullName}}",
            clientADOB: '{{formatDate clientDetails.clientA.dob "full"}}',
            clientASex: "{{clientDetails.clientA.sex}}",
            clientASampleDate:
              'Sample collected on {{formatDate clientDetails.clientA.sampleCollectionDate "short"}}',
            clientAReportDate:
              'Report processed on {{formatDate clientDetails.clientA.reportProcessedDate "short"}}',
            hasSecondClient: false,
            careTeamSubTitle: "at Eugene",
            careTeam: "{{testInformation.eugeneCareTeam}}",
            referringDoctorTitle: "Referred by",
            referringDoctorClinic: "{{testInformation.referringDoctorClinic}}",
          } as ClientDetailsVariables,
        },
        {
          id: "whatWeKnow",
          template: "Section",
          dependencies: ["summary.whatWeKnow"],
          variables: {
            title: "What we know",
            content: "{{ summary.whatWeKnow }}",
          } as SectionVariables,
        },
        {
          id: "whatWeTested",
          template: "Section",
          variables: {
            title: "What we tested",
            content:
              "Eugene’s carrier test used the Comprehensive Carrier Screen to help you predict your risk of having a child with an inherited genetic condition. You were screened for a total of {{ testInformation.numberOfGenesTested }} {{#if testInformation.numberOfXLinkedGenesTested}}recessive {{/if}}{{#if testInformation.numberOfXLinkedGenesTested}} and {{testInformation.numberOfXLinkedGenesTested}} X-linked {{/if}} genetic conditions.",
          } as SectionVariables,
        },
        {
          id: "whatWeFound",
          template: "Section",
          variables: {
            title: "What we found",
            content: "{{ summary.whatWeFound }}",
          } as SectionVariables,
        },
        {
          id: "resultsTable",
          template: "DiseaseTable",
          variables: {
            clientAName: "{{clientDetails.clientA.fullName}}",
            highRiskInformation:
              "**1 in 4 (25%)** chance of having a child affected by this condition.",
            highRiskMore: "",
            increasedRiskInformation:
              "**1 in 4 (25%)** chance of having a child affected by this condition.",
            increasedRiskMore:
              "More about {{disease.name}} later in this report",
            lowRiskMore: "",
            resultToNoteMore: "",
            otherConditionsTested:
              "All {{#unless hasNoDiseases}}other{{/unless}} conditions tested",
            otherConditionsClientAStatus: "Not a carrier",
            otherConditionsClientBStatus: "",
            hasSecondClientColumn: true,
            clientBName: "Reproductive partner",
          } as DiseaseTableVariables,
        },
        {
          id: "lowRiskWhatThisMeans",
          template: "Section",
          dependencies: ["!isHighRisk"],
          variables: {
            title: "What this means",
            content: `
The chance of you having a child with one of these conditions depends on the carrier status of your reproductive partner.

It’s also important to remember that, genetics is only one part of the pregnancy
puzzle. This test does not cover all risks associated with pregnancy, so always stick to best practices and
guidelines regardless of the result and regularly consult with your obstetrician or GP during your
pregnancy.
            `,
          } as SectionVariables,
        },
        {
          id: "lowRiskImportantNote",
          template: "Section",
          dependencies: ["!isHighRisk", "summary.importantNote"],
          variables: {
            title: "An important note",
            content: "{{ summary.importantNote }}",
          } as SectionVariables,
        },
        {
          id: "highRiskWhatYouCanDo",
          template: "TwoColSection",
          dependencies: ["isHighRisk"],
          variables: {
            col1Title: "What you can do about it",
            col1TickBullets: true,
            col1Width: "6/12",
            col1Content: `
* You can get pregnant naturally & do prenatal tests to identify if the pregnancy is affected. This could
influence your pregnancy choices.
* You can do in-vitro fertilization (IVF) & pre-implantation genetic diagnosis (PGD) to avoid passing on
the condition.
* You can get pregnant and leave it to chance.
* You can conceive through donor egg, sperm or embryo.
            `,
            col1TitleOptions: true,
            col2Title: "How you can go about it",
            col2TickBullets: true,
            col2Width: "6/12",
            col2Content: `
* Speak to your counsellor about your reproductive options to avoid passing on this condition.
* Share these results with your doctor or request a referral to one.
* Consider sharing your results with your family members as they have an increased chance of being a
carrier.
`,
          } as TwoColSectionVariables,
        },
        {
          id: "highRiskImportantNote",
          template: "Section",
          dependencies: ["isHighRisk", "summary.importantNote"],
          variables: {
            title: "An important note",
            content: "{{ summary.importantNote }}",
          } as SectionVariables,
        },
        {
          id: "highRiskQuickNote",
          template: "Section",
          dependencies: ["isHighRisk", "summary.quickNote"],
          variables: {
            title: "Quick note",
            content: "{{ summary.quickNote }}",
          } as SectionVariables,
        },
        {
          id: "lowRiskQuickNoteNextSteps",
          template: "TwoColSection",
          dependencies: ["!isHighRisk", "summary.quickNote"],
          variables: {
            col1Title: "Next steps",
            col1TickBullets: true,
            col1TitleOptions: false,
            col1Content: `
* If there’s anything in this report that you don’t understand, please feel free to reach out to your genetic counsellor.
* Share these results with your doctor or obstetrician or request a referral to one.
            `,
            col1Width: "7/12",

            col2Title: "Quick note",
            col2Content: "{{summary.quickNote}}",
            col2Width: "5/12",
          } as TwoColSectionVariables,
        },
        {
          id: "lowRiskNextSteps",
          template: "Section",
          dependencies: ["!isHighRisk", "!summary.quickNote"],
          variables: {
            title: "Next steps",
            tickBullets: true,
            titleOptions: true,
            content: `
* If there’s anything in this report that you don’t understand, please feel free to reach out to your genetic counsellor.
* Share these results with your doctor or obstetrician or request a referral to one.
            `,
          } as SectionVariables,
        },
      ],
    },
    {
      repeat: {
        collection: "orderedDiseases",
        as: "disease",
        repeaterId: "disease.id",
      },
      sections: [
        {
          id: "pageTitle",
          template: "PageTitle",
          variables: {
            title: "Section {{lookup sectionNumbersByDiseaseId disease.id}} —",
          } as PageTitleVariables,
        },
        {
          id: "diseasePageHeader",
          template: "DiseaseHeader",
          variables: {
            diseaseClass: "{{disease.diseaseClass}}",
            diseaseClassColor: "{{disease.diseaseClassColor}}",
            resultTitle: "Your result",
            carrierRisk: "{{disease.carrierRisk}}",
            diseaseName: "{{disease.name}}",
            relatedGenesPrefix: "Related gene — ",
            relatedGenes: "{{{andList disease.genes}}}",
            clientAName: "{{clientDetails.clientA.fullName}}",
            clientACarrierStatus: "{{disease.clientACarrierStatus}}",
            hasSecondClientColumn: true,
            clientBName: "Reproductive partner",
            clientBCarrierStatus: "{{disease.clientBCarrierStatus}}",
          } as DiseaseHeaderVariables,
        },
        {
          id: "diseasePageOverview",
          template: "Section",
          variables: {
            title: "What is {{disease.name}}",
            content: "{{ disease.description }}",
          } as SectionVariables,
        },
        {
          id: "diseasePageIndividualCarrier",
          template: "Section",
          variables: {
            title: "So I'm a carrier, what does that mean?",
            content: "{{ disease.carrierInfo }}",
          } as SectionVariables,
        },
        {
          id: "diseasePageIndividualCarrierMeaning",
          template: "Section",
          variables: {
            title: "What does this mean for having children?",
            content:
              "The chance of having a child affected by these conditions will depend on the carrier status of your reproductive partner.",
          } as SectionVariables,
        },
        {
          id: "diseasePageIndividualCarrierNextStepsWithResources",
          template: "TwoColSection",
          dependencies: ["disease.resources"],
          variables: {
            col1Title: "Next steps",
            col1Width: "8/12",
            col1TickBullets: true,
            col1Content: "{{ disease.individualCarrierNextSteps }}",
            col2Title: "Resources",
            col2Width: "4/12",
            col2Content: "{{ disease.resources }}",
          } as TwoColSectionVariables,
        },
        {
          id: "diseasePageIndividualCarrierNextStepsWithoutResources",
          template: "Section",
          dependencies: ["!disease.resources"],
          variables: {
            title: "Next steps",
            tickBullets: true,
            content: "{{ disease.individualCarrierNextSteps }}",
          } as SectionVariables,
        },
      ],
    },
  ],
};
