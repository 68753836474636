import * as React from 'react';
import { QueryResult } from 'react-apollo';
import SearchAutocomplete from '../../../components/widgets/SearchAutocomplete';
import GenieQuery from '../../base/GenieQuery';
import { Doctor } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';

export type DoctorPartial = Pick<Doctor, 'id' | 'nameFirst'>;

interface DoctorSearchAutocompleteProps {
  onSelect(option: DoctorPartial): void;
  defaultValue?: DoctorPartial;
}

type DoctorQueryResult = {
  searchDoctors: DoctorPartial[];
};

const DoctorSearchAutocomplete: React.FC<DoctorSearchAutocompleteProps> = (props: DoctorSearchAutocompleteProps) => {
  return (
    <GenieQuery
      query={gql`
        query SearchDoctors($term: String!) {
          searchDoctors(term: $term) {
            id
            nameFirst
          }
        }
      `}
      notifyOnNetworkStatusChange
      variables={{
        term: '',
      }}
    >
      {({ loading, data, fetchMore }: QueryResult<DoctorQueryResult>) => (
        <SearchAutocomplete
          isLoading={loading}
          options={data && data.searchDoctors ? data.searchDoctors.map(({ id, nameFirst }) => ({
            id,
            name: `${nameFirst}`,
          })) : []}
          onSearch={(searchQuery: string) => {
            fetchMore({
              variables: {
                term: searchQuery,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => ({
                ...previousResult,
                ...fetchMoreResult,
              }),
            });
          }}
          onSelect={option => props.onSelect({
            nameFirst: option.name,
            id: option.id as string
          })}
          defaultSelected={props.defaultValue ? [{
            name: props.defaultValue.nameFirst,
            id: props.defaultValue.id,
          }] : undefined}
        />
      )}
    </GenieQuery>
  );
};

export default DoctorSearchAutocomplete;
