import * as React from 'react';
import moment from 'moment';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, Query, Request, MutationUpdateRequestArgs, QueryRequestArgs } from '../../../../graphql/genie-api-types';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import Button from '../../../components/widgets/Button';
import Table from '../../../components/widgets/Table';
import { FiCheck, FiX } from '../../../components/widgets/Icon';
import PregnancyIcon from '../../../components/widgets/PregnancyIcon';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { getProductLabel } from '../../../../utilities/journeys/products';
import MemberOrPlaceholder from '../../../components/member/MemberOrPlaceholder';

interface RequestApprovalProps {
  onUpdate?(): void;
  onCancel?(): void;
  requestId: Request['id'];
}

const RequestApproval = (props: RequestApprovalProps) => (
  <GenieQuery<Pick<Query, 'request'>, QueryRequestArgs>
    query={gql`
      query Request($id: ID!) {
          request(id: $id) {
            id
            datePaid
            product
            productVariant
            gcApprovalDate
            pregnancy {
              days
            }
            journeys {
              id
              state
              hasConsent
              member {
                id
                name
              }
            }
          }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.requestId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'request'>>) => loading ?
      (
        <Spinner label="Loading request..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                gcApprovalDate
              }
            }
          }
          `}
          refetchQueries={() => {
            return ['ListKits'];
          }}
        >
          {(mutate: MutationFunction<Mutation, MutationUpdateRequestArgs>, result) => {
            const pregnancy = data?.request?.pregnancy;
            const datePaid = data?.request?.datePaid;
            const approvalDate = data?.request?.gcApprovalDate;
            const productLabel = getProductLabel(data.request.product, data.request.productVariant);
            const isLoading = result.loading;

            return (
              <div className="relative">
                <h2 className="text-md">{productLabel}</h2>
                <Table
                  header={['Member', 'Paid', 'Consent', 'Approval']}
                  rowGroups={[
                    {
                      id:  data?.request.id,
                      rows: data?.request?.journeys?.map(journey => ({
                        id: journey.id,
                        cells: [
                          <>
                            <MemberOrPlaceholder member={journey?.member}>
                              {(member) => member.name}
                            </MemberOrPlaceholder> {pregnancy ? <PregnancyIcon pregnancy={pregnancy} /> : null}</>,
                          datePaid ? <FiCheck /> : <FiX />,
                          journey.hasConsent ? <FiCheck /> : <FiX />,
                          approvalDate ? <FiCheck /> : <FiX />,
                        ],
                      })) ?? [],
                    },
                  ]}
                />
                {approvalDate ? (
                  <div className="mt-20 font-bold">
                    Approved {moment(approvalDate).fromNow()}.
                  </div>
                ) : (
                    <Button
                      className="mt-20"
                      onClick={async () => {
                        const mutationResult = await mutate({
                          variables: {
                            input: {
                              requestId: props.requestId,
                              approvedByGC: true,
                            },
                          },
                        });

                        if (mutationResult && props.onUpdate) {
                          props.onUpdate();
                        }
                      }}
                    >
                      Mark as Approved
              </Button>
                  )}
                {isLoading ? <LoadingOverlay /> : null}
              </div>
            );
          }}
        </GenieMutation>
      )}
  </GenieQuery>
);

export default RequestApproval;
