import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import Route from '../../../base/Route';
import AdditionalInformationView from './AdditionalInformationView';

const AdditionalInformation = () => (
    <div>
        <Route path={PATHS.REPORT_ADDITIONAL_INFO} component={AdditionalInformationView} />
    </div>
);

export default AdditionalInformation;
