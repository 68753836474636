import * as React from 'react';
import * as yup from 'yup';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormGroup from '../widgets/form/FormGroup';
import FormControl from '../widgets/form/FormControl';
import FormCheck from '../widgets/form/FormCheck';

export interface SessionUnlockFormValues {
  sessionPassword?: string;
  shouldResetStorageKey?: boolean;
}

interface SessionUnlockFormProps extends SessionUnlockFormValues {
  onSubmit: BaseFormProps['onSubmit'];
  hasKeyFailed: boolean;
}

const schema = yup.object().shape({
  sessionPassword: yup.string().min(5).required('Password is required'),
});

const SessionUnlockForm = (props: SessionUnlockFormProps) => (
  <BaseForm
    validationSchema={schema}
    onSubmit={props.onSubmit}
    errorMessage={props.hasKeyFailed ? 'The password you entered doesn\'t match the one you previously used.' : ''}
    initialValues={{
      sessionPassword: props.sessionPassword,
    }}
  >
    {({
      handleChange,
      values,
      errors,
      setFieldValue,
    }) => (
        <div>
          <FormGroup controlId="formGroupPassword" className="w-100" label="Session password">
            <FormControl
              type="password"
              name="sessionPassword"
              value={values.sessionPassword}
              onChange={handleChange}
              isInvalid={!!errors.sessionPassword}
              setFieldValue={setFieldValue}
            />
          </FormGroup>
          {props.hasKeyFailed ? (
            <FormGroup controlId="formGroupReset" helpText="Checking this box will clear out any sessions that were running with your previous password.">
              <FormCheck name="shouldResetStorageKey" type="checkbox" label="Reset password" onChange={handleChange} />
            </FormGroup>
          ) : null}
        </div>
      )
    }
  </BaseForm>
);

SessionUnlockForm.defaultProps = {
  sessionPassword: '',
};

export default SessionUnlockForm;
