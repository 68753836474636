import { Reducer } from 'redux';
import { CustomisationState, CustomisationActionTypes, SectionOverride } from './types';

export const initialState: CustomisationState = {
  sectionVariableOverridesByTemplate: {},
  templateName: 'coupleCarrier',
};

const reducer: Reducer<CustomisationState> = (state = initialState, action) => {
  switch (action.type) {
    case CustomisationActionTypes.CHANGE_TEMPLATE_SECTION_VARIABLES: {
      const { templateName, pageId, sectionId, variables } = action.payload;
      const templateSectionOverrides = state.sectionVariableOverridesByTemplate[templateName] || {};

      if (!templateSectionOverrides[pageId]) {
        templateSectionOverrides[pageId] = {};
      }
      templateSectionOverrides[pageId][sectionId] = variables;

      return {
        ...state,
        sectionVariableOverridesByTemplate: {
          ...state.sectionVariableOverridesByTemplate,
          [templateName]: { ...templateSectionOverrides },
        },
      };
    }

    case CustomisationActionTypes.TOGGLE_VISIBILITY: {
      const { templateName, pageId, sectionId } = action.payload;
      const templateSectionOverrides = state.sectionVariableOverridesByTemplate[templateName] || {};

      const existingSectionVariables = templateSectionOverrides?.[pageId]?.[sectionId] ?? {};
      const isCurrentlyHidden = existingSectionVariables._hidden;

      if (!templateSectionOverrides[pageId]) {
        templateSectionOverrides[pageId] = {};
      }
      (templateSectionOverrides[pageId][sectionId] as SectionOverride) = {
        ...existingSectionVariables,
        _hidden: !isCurrentlyHidden,
      };

      return {
        ...state,
        sectionVariableOverridesByTemplate: {
          ...state.sectionVariableOverridesByTemplate,
          [templateName]: { ...templateSectionOverrides },
        },
      };
    }

    case CustomisationActionTypes.CHANGE_TEMPLATE_NAME:
      return {
        ...state,
        templateName: action.payload,
      };

    case CustomisationActionTypes.RESET_SECTION: {
      const { templateName, pageId, sectionId } = action.payload;
      const templateSectionOverrides = state.sectionVariableOverridesByTemplate[templateName] || {};

      if (!templateSectionOverrides[pageId]) {
        templateSectionOverrides[pageId] = {};
      }
      delete templateSectionOverrides[pageId][sectionId];

      return {
        ...state,
        sectionVariableOverridesByTemplate: {
          ...state.sectionVariableOverridesByTemplate,
          [templateName]: { ...templateSectionOverrides },
        },
      };
    }

    default:
      return state;
  }
};

export { reducer as customisationReducer };
