import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Journey, JourneyState, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import { JOURNEY_STATE_ICONS, JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';
import { getKitScope } from '../../../../utilities/kits/scopes';
import JourneyShowContext from '../../../contexts/JourneyShowContext';

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      state
      kits {
        id
        dateArrivedAtEugene
        dateShippedToMember
        recollectionRequestedDate
        dateShippedToLab
      }
      request {
        id
        gcApprovalDate
        datePaid
      }
    }
  }
`;

interface QueryResult {
  journey: Journey;
}

const JourneyShowStage = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const journey = journeyQuery?.data?.journey;
  
  if (!journey) {
    return null;
  }

  const JourneyStateIcon = JOURNEY_STATE_ICONS[journey.state];
  const journeyStateTitle = JOURNEY_STATE_LABELS[journey.state];
  const isTrashed = journey.state === JourneyState.Trash;

  const kitsByDate = _.orderBy(journey?.kits.filter(kit => kit.dateShippedToLab !== null), ['dateShippedToLab'], ['desc']);

  const TATFastest = kitsByDate[0] ? moment(kitsByDate[0].dateShippedToLab).add(4, 'weeks').format("DD MMM YY") : "unknown";
  const TATSlowest = kitsByDate[0] ? moment(kitsByDate[0].dateShippedToLab).add(6, 'weeks').format("DD MMM YY") : "unknown";
  const kitScope = kitsByDate[0] ? getKitScope(kitsByDate[0], journey, journey.request) : null;

  return (
    <div className={`flex flex-col justify-start`}>
      <div className={`flex justify-end ${isTrashed ? 'bg-red text-white rounded p-10 font-bold' : ''} ${journey.state === JourneyState.WaitingForLabResults && kitScope === "COMPLETE" ? 'pb-0' : ''}`}>
        <JourneyStateIcon className={`mr-8 mt-2 ${isTrashed ? 'text-white' : ''}`} />
        <h2 className="text-lg w-auto">{journeyStateTitle}</h2>
      </div>
      {journey.state === JourneyState.WaitingForLabResults && kitScope === "COMPLETE" &&
        <p className='flex justify-end text-xs py-10'>TAT: {TATFastest} - {TATSlowest}</p>
      }
    </div>
  );
};

export default JourneyShowStage;
