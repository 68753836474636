import * as React from 'react';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import Button from '../../components/widgets/Button';
import { downloadPDF } from '../../../utilities/reportGenerator';

const ReportGenerator = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [jsonString, setJsonString] = React.useState(JSON.stringify(sampleJson, null, 2));

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJsonString(e.target.value);
  }

  const onSubmit = async () => {
    setIsLoading(true);
    await downloadPDF(jsonString, 'eugene-pdf');
    setIsLoading(false);
  }

  return (
    <div>
      <ScreenTitle
        title="Generate a PDF from an intermediate report"
      />
      <textarea
        onChange={onChangeTextArea}
        value={jsonString}
        className="border border-grey-dark p-10 w-full"
        placeholder="Paste the intermediate report here..."
        style={{ height: '300px' }}
      />
      
      <Button
        onClick={onSubmit}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Download PDF'}
      </Button>
    </div>
  );
}

export default ReportGenerator;

const sampleJson = {
  "header": {
    "summary": "This is a report summarising the expanded carrier screening findings for Joe and Jane.",
    "memberA": {
      "name": "Joe Bloggs",
      "dob": "10/03/1988",
      "sex": "Male",
      "reportDate": "01/07/2022"
    },
    "memberB": {
      "name": "Jane Citizen",
      "dob": "01/06/1990",
      "sex": "Female",
      "reportDate": "06/07/2022"
    },
    "careTeam": [
      "Stephanie Groube, Assoc. GC",
      "David Amor, Geneticist"
    ]
  },
  "elements": [
    {
      "block": "content",
      "content": "*Based on your reported family history and expanded carrier screening result:*"
    },
    {
      "block": "section",
      "header": "Combined reproductive implications",
      "elements": [
        {
          "block": "condition",
          "condition": "Hypophosphatasia",
          "gene": "ALPL",
          "description": "Joe and Jane were both found to carry a variant in the ALPL gene. This means they have a 1 in 4 (25%) chance of having a child affected by hypophosphatasia.\n\nHypophosphatasia is a variable condition that results in soft and weak bones because the body can’t absorb minerals that help bones develop. The individual symptoms and age of onset depend on the combination of variants that are inherited. The variant combination for Joe and Jane is not expected to result in the most severe prenatal form of hypophosphatasia. However, it may result in any of the other types, from infantile to mild adult onset.",
          "recommendations": "- Speak or request a referral to a fertility specialist, clinical genetics service and/or obstetrician to discuss your reproductive options, which include:\n    - Natural pregnancy & prenatal tests to identify if the pregnancy is affected.\n    - In-vitro fertilization (IVF) & pre-implantation genetic testing (PGT) to avoid passing on the condition.\n    - Conceive naturally pregnant and leave to chance\n    - Conceive through donor egg, sperm or embryo"
        },
        {
          "block": "condition",
          "condition": "Fragile X syndrome",
          "gene": "FMR1",
          "description": "Jane was found to carry a variant known as a pre-mutation in the FMR1 gene. This is an X-linked gene, which means it affects males and females differently. There is a 1 in 2 (50%) chance Jane’s children will be affected by Fragile X syndrome.\n\nPeople with fragile X syndrome experience developmental delay, learning difficulties, anxiety and epilepsy, and are sometimes diagnosed as having features consistent with autism spectrum disorder. Boys tend to be affected more severely than girls, because they only have one X-chromosome: the one with the FMR1 variant on it.\n\nCarriers of a fragile X pre-mutation, like Jane, can sometimes experience premature ovarian failure, anxiety or fragile X associated tremor/ataxia syndrome (FXTAS).",
          "recommendations": "- Speak or request a referral to a fertility specialist, clinical genetics service and/or obstetrician to discuss your reproductive options, which include:\n    - Natural pregnancy & prenatal tests to identify if the pregnancy is affected.\n    - In-vitro fertilization (IVF) & pre-implantation genetic testing (PGT) to avoid passing on the condition.\n    - Conceive naturally pregnant and leave to chance\n    - Conceive through donor egg, sperm or embryo\n- Speak to your GP about further investigation for the health implications for fragile X pre-mutation carriers"
        }
      ]
    },
    {
      "block": "section",
      "header": "Individual health implications",
      "elements": [
        {
          "block": "condition",
          "condition": "Hypohidrotic ectodermal dysplasia",
          "carrier": "Joe",
          "gene": "WNT10A",
          "description": "Hypohidrotic ectodermal dysplasia (HED) affects the development of the skin, hair, nails, teeth and sweat glands. Some carriers of HED may experience mild symptoms, such as absent adult teeth.",
          "recommendations": "- Speak to your doctor or dentist if you are concerned about symptoms of HED"
        },
        {
          "block": "condition",
          "condition": "Familial hypercholesterolaemia",
          "carrier": "Jane",
          "gene": "LDLR",
          "description": "Familial hypercholesterolaemia causes high levels of the LDL-C type cholesterol to build up in the body which can cause coronary heart disease. FH is an autosomal dominant condition, which means a variant in only one copy of the gene is enough to cause symptoms.",
          "recommendations": "- Speak to your GP about monitoring cholesterol levels and implementing a management plan\n- Testing is not recommended in pregnancy, however your children have a 50%  chance of inheriting this variant. Gene testing is recommended around age 10 to implement an early management plan"
        }
      ]
    },
    {
      "block": "section",
      "header": "Other findings",
      "elements": [
        {
          "block": "content",
          "content": "For all other findings, no further action is needed. Further details about other findings are outlined on the Invitae laboratory report."
        },
        {
          "block": "condition",
          "condition": "Cystic fibrosis",
          "carrier": "Joe",
          "gene": "CFTR"
        },
        {
          "block": "condition",
          "condition": "Spinal muscular atrophy",
          "carrier": "Jane",
          "gene": "SMN1"
        },
        {
          "block": "content",
          "content": "What this means:\n- Carriers are healthy and do not show signs of the condition\n- There is a low likelihood of having a child with these conditions\n- For more information about these conditions please refer to the laboratory report"
        },
        {
          "block": "content",
          "content": "Important to remember:\n- This test complements other obstetric care\n- Genetic knowledge is constantly changing. You may possibly be contacted in future if new information comes to light that affects your result. Please let us know if you do not want to be contacted.\n- This report is personal to the couple tested. If your reproductive partner changes, these results may change.\n- We share our genes with our family - talk to your siblings, parents, children (when adults) and other family as they may be interested to understand if they are also carriers."
        }
      ]
    }
  ]
}