import * as React from 'react';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import {Clinic, CreateClinicInput, Doctor} from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import Badge from "../widgets/Badge";
import FormGroup from "../widgets/form/FormGroup";
import MultiModelForm from "../../screens/MultiModelForm";
import {get} from "lodash";

interface ClinicFormProps {
  initialValues?: Partial<CreateClinicInput>;
  onSubmit: BaseFormProps['onSubmit'];
  isLoading: BaseFormProps['isLoading'];
  isCreate?: boolean;
  errorMessage?: BaseFormProps['errorMessage'];
  onFormChanged?(values: ClinicFormInput): void;
  onNewDoctorClinic?(): void;
  attachedDoctors?: Pick<Doctor, 'id' | 'nameFirst' | 'nameMiddle' | 'nameLast' | 'title' | 'nickname'>[];
}

interface ClinicFormInput extends FormInputProps {
  name: keyof Clinic;
}

const formItems: Pick<ClinicFormInput, 'label' | 'name' | 'type' | 'as'>[] = [
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Location ID',
    name: 'locationId',
  },
  {
    label: 'Address Street',
    name: 'addressStreet',
  },
  {
    label: 'Address Suburb',
    name: 'addressSuburb',
  },
  {
    label: 'Address Postcode',
    name: 'addressPostcode',
  },
  {
    label: 'Address State',
    name: 'addressState',
  },
  {
    label: 'Address Country',
    name: 'addressCountry',
  },
  {
    label: 'Clinic Phone',
    name: 'clinicPhone',
  },
  {
    label: 'Clinic Email',
    name: 'clinicEmail',
  },
  {
    label: 'Latitude',
    name: 'latitude',
  },
  {
    label: 'Longitude',
    name: 'longitude',
  }
];

class ClinicForm extends React.PureComponent<ClinicFormProps> {
  renderDoctorTable() {
    const { attachedDoctors, onNewDoctorClinic } = this.props;

    return (
        <FormGroup
            controlId="clinicDoctors"
            label="Doctors"
            labelAction={{
              label: '+ New',
              onClick: onNewDoctorClinic,
            }}
        >
          <table className="w-full mb-10 text-xs">
            <tbody>
            {attachedDoctors.map(doctor => (
                <tr key={doctor.id} className="border-t">
                  <td className="py-10">
                    <Badge color={'white'}>{doctor.title ?? ''} {doctor.nameFirst}{doctor.nameMiddle ? ` (${doctor.nameMiddle})` : ''}{doctor.nameLast ? ` (${doctor.nameLast})` : ''}{doctor.nickname ? ` (${doctor.nickname})` : ''}</Badge>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </FormGroup>
    );
  }

  render() {
    const { isCreate, attachedDoctors, onSubmit, ...props } = this.props;
    const { ...restOfInitialValues } = this.props.initialValues;

    return (
        <MultiModelForm<'doctorClinic'>
            modelForms={{
              doctorClinic: 'DoctorClinicCreate',
            }}
        >
          {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
              <BaseForm
                  {...props}
                  initialValues={restOfInitialValues}
                  onSubmit={values => {
                    onSubmit({
                      ...values,
                      ...modelValues,
                    });
                  }}
                  submitButtonLabel="Save"
              >
                {({ handleChange, values, errors, setFieldValue }) => (
                    <div className="flex flex-wrap items-end">
                      {formItems
                          .map(({ ...formItem }) => (
                              <FormInput
                                  {...formItem}
                                  value={get(values, formItem.name)}
                                  onChange={handleChange}
                                  helpText={typeof errors[formItem.name] !== 'string' && errors[formItem.name] ? `${formItem.name} is a required field` : errors[formItem.name]}
                                  key={formItem.name}
                                  setFieldValue={setFieldValue}
                              />
                          ))}
                      {attachedDoctors ? this.renderDoctorTable() : null}
                    </div>
                )}
              </BaseForm>
          )}
        </MultiModelForm>
    );
  }
}

export default ClinicForm;
