import * as React from 'react';
import { Product, ProductVariant } from '../../../../graphql/genie-api-types';
import { getProductLabel, PRODUCT_LIST } from '../../../../utilities/journeys/products';
import { productVariantTable } from '../../../../utilities/journeys/productVariantTable';
import Select, { SelectOption } from '../Select';

export type ProductSelectValue = {
  product: Product;
  productVariant: ProductVariant;
}

interface ProductSelectProps {
  value?: ProductSelectValue;
  onChange(value: ProductSelectValue): void;
  selectClass?: string;
}

const productOptions: SelectOption<Product>[] = PRODUCT_LIST.map(product => ({
  value: product as Product,
  label: getProductLabel(product)
}));

const ProductSelect = (props: ProductSelectProps) => {
  const onChangeProduct = React.useCallback((productSelection: string) => {
    props.onChange({
      ...props.value,
      product: productSelection as Product,
    });
  }, [props.value, props.onChange]);

  const onChangeProductVariant = React.useCallback((productVariantSelection: string) => {
    props.onChange({
      ...props.value,
      productVariant: productVariantSelection === '' ? null : productVariantSelection as ProductVariant,
    });
  }, [props.value, props.onChange]);

  const variantOptions: SelectOption<ProductVariant>[] = React.useMemo(() => {
    if (!props.value.product) {
      return [];
    }

    return Object.entries(productVariantTable[props.value.product].variants)
      .map(([variant, variantStrategy]) => ({
        value: variant as ProductVariant,
        label: variantStrategy.shortTitle
      }));
  }, [props.value.product]);

  React.useEffect(() => {
    // Reset product variant if it doesn't exist for this product type.
    if (!variantOptions.find(variantOption => variantOption.value === props.value?.productVariant)) {
      props.onChange({
        ...props.value,
        productVariant: null,
      });
    }
  }, [variantOptions]);

  return (
    <div className="flex">
      <Select
        options={productOptions}
        onChange={onChangeProduct}
        selectedValue={props.value.product}
        hasBlankValue={true}
        className={props.selectClass}
        name="product"
      />
      {variantOptions.length > 0 && (
        <Select
          options={variantOptions}
          onChange={onChangeProductVariant}
          selectedValue={props.value.productVariant}
          hasBlankValue={true}
          className={props.selectClass}
          name="productVariant"
        />
      )}
    </div>
  );
};

ProductSelect.defaultProps = {
  value: {
    product: undefined,
    productVariant: undefined,
  },
};

export default ProductSelect;