import * as React from 'react';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { TestInformationState } from '../../../store/report/test-information/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import { connect } from 'react-redux';
import { PATHS } from '../../../store/router/types';
import EditArea from '../../../components/widgets/EditArea';
import templates, { ReportTemplate, templateLabels, SectionTemplateName } from '../../../../utilities/report/templates';
import sections from '../../../../utilities/report/templates/sections';
import { getCompiledTemplateVariablesWithSectionOverrides, ProcessedTemplatePage } from '../../../../utilities/report/templates/getCompiledTemplateVariablesWithSectionOverrides';
import { Collapsible } from '../../../components/widgets/Collapsible';
import { PageOverrides } from '../../../store/report/customisation/types';
import ActionLink from '../../../components/widgets/ActionLink';
import { resetSection, toggleSectionVisibility, changeTemplateName } from '../../../store/report/customisation/actions';
import { extractReportTemplateVariables, ReportVariables } from '../../../../utilities/report/templates/extractReportTemplateVariables';
import CustomisationViewSection from '../../../components/report/customisation/CustomisationViewSection';
import { navigate } from '../../../store/router/actions';
import Select from '../../../components/widgets/Select';

interface CustomisationViewProps extends TaskListComponentProps {
  onEdit(pageId: string, sectionId: string): void;
  fields: TestInformationState;
  compiledPageVariables: ProcessedTemplatePage[];
  pageOverrides: PageOverrides;
  templateName: string;
  templateVariables: ReportVariables;
  onReset(templateName: string, pageId: string, sectionId: string): void;
  onToggleVisibility(templateName: string, pageId: string, sectionId: string): void;
  onChangeTemplateName(templateName: string): void;
  template: ReportTemplate;
}

const CustomisationView = (props: CustomisationViewProps) => {
  const { onToggleVisibility, onReset, templateName, onChangeTemplateName } = props;
  return (
    <div>
      <TaskListScreenTitle
        title="Customisation"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      <div className="p-10 mb-20 bg-grey-light rounded border">
        <label className="font-bold block pb-10 rounded">Template</label>
        <Select
          onChange={onChangeTemplateName}
          options={['coupleCarrier', 'individualCarrier', 'individualCancer'].map((templateName: 'coupleCarrier' | 'individualCarrier' | 'individualCancer') => ({
            label: templateLabels[templateName],
            value: templateName,
          }))}
          selectedValue={templateName}
          className="border rounded bg-white px-10"
        />
      </div>
      {props.compiledPageVariables.map((templatePage, index) => (
        <Collapsible title={`Page ${index + 1}`} defaultExpandedState={false} className="mb-20" key={templatePage.id}>
          {templatePage.sections.map((section) => {
            const isOverridden = !!props.pageOverrides?.[templatePage.id]?.[section.id] ?? false;
            const isSectionHidden = !!props.pageOverrides?.[templatePage.id]?.[section.id]?._hidden ?? false;

            return (
              <div className="mt-30 mb-20" key={section.id}>
                <div className="flex justify-end mb-5 flex-1">
                  {isOverridden ? <ActionLink className="text-sm mr-10" onClick={() => onReset(props.templateName, templatePage.id, section.id)}>Reset</ActionLink> : ''}
                  {isSectionHidden ? (
                    <ActionLink className="text-sm text-blue" onClick={() => onToggleVisibility(props.templateName, templatePage.id, section.id)}>Show</ActionLink>
                  ) :
                    <ActionLink className="text-sm text-red" onClick={() => onToggleVisibility(props.templateName, templatePage.id, section.id)}>Hide</ActionLink>
                  }
                </div>
                <EditArea
                  onClick={() => props.onEdit(templatePage.id, section.id)}
                  isOverridden={!!props.pageOverrides?.[templatePage.id]?.[section.id] ?? false}
                  padding={2}
                >
                  <CustomisationViewSection
                    defaultValues={sections.variableTypes[section.template as Exclude<SectionTemplateName, 'Divider'>]}
                    section={section}
                    templateVariables={props.templateVariables}
                    isSectionHidden={isSectionHidden}
                    template={props.template}
                  />
                </EditArea>
              </div>
            );
          })}
        </Collapsible>
      ))}
    </div>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  templateName: report.customisation.templateName,
  template: templates[report.customisation.templateName],
  templateVariables: extractReportTemplateVariables(report),
  compiledPageVariables: getCompiledTemplateVariablesWithSectionOverrides(report.customisation.templateName, report),
  sectionOverrides: report.customisation.sectionVariableOverridesByTemplate[report.customisation.templateName],
  pageOverrides: report.customisation.sectionVariableOverridesByTemplate?.[report.customisation.templateName] ?? {},
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onEdit: (pageId: string, sectionId: string) => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CUSTOMISATION_EDIT, `pageId=${pageId}&sectionId=${sectionId}`)),
  onToggleComplete: () => dispatch(toggleTask('customisation')),
  onReset: (templateName: string, pageId: string, sectionId: string) =>
    dispatch(resetSection(templateName, pageId, sectionId)),
  onToggleVisibility: (templateName: string, pageId: string, sectionId: string) =>
    dispatch(toggleSectionVisibility(templateName, pageId, sectionId)),
  onChangeTemplateName: (templateName: string) =>
    dispatch(changeTemplateName(templateName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomisationView);
