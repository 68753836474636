import { groupBy, uniq } from 'lodash';
import { Sex } from '../../../graphql/genie-api-types';
import { JourneyRowPartial, JourneyTableProps } from '../../components/widgets/JourneyTable';

export type JourneyTableData<JourneyRow extends JourneyRowPartial> = Pick<JourneyTableProps<JourneyRow>, 'journeysByRequestId' | 'orderedRequestIds'>;

export default function convertJourneyResultsToJourneyTableData<JourneyRow extends JourneyRowPartial>(unsortedJourneys: JourneyRow[], shouldSort = true): JourneyTableData<JourneyRow> {
  // Prevent unecessary re-orders and reflows in table.
  const orderedRequestIds = uniq(unsortedJourneys.map(journey => journey.request ? journey.request.id : ''));

  // Ensure females appear at top of couple lists.
  const journeys = shouldSort ? unsortedJourneys.slice(0).sort(journey => (journey?.member?.sex ?? Sex.Female) === Sex.Female ? -1 : 1) : unsortedJourneys;
  const journeysByRequestId = groupBy(journeys, (journey => journey.request ? journey.request.id : 'no-request'));

  return {
    journeysByRequestId,
    orderedRequestIds,
  };
};
