import * as React from 'react';

import { Switch } from 'react-router';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';
import DoctorShow from './directory/DoctorShow';
import DoctorIndex from './directory/DoctorIndex';

const Doctors = () => {
  return (
    <Switch>
      <Route path={PATHS.DOCTORS} exact component={DoctorIndex} />
      <Route path={PATHS.DOCTOR_SHOW} exact component={DoctorShow} />
    </Switch>
  );
};

export default Doctors;
