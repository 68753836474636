import * as React from 'react';
import ReactTooltip from 'react-tooltip';

interface TooltipProps {
  children: React.ReactNode;
  label?: string | React.ReactNode;
  placement?: 'left' | 'top' | 'bottom' | 'right';
  appearance?: 'light' | 'dark';
}

const Tooltip = (props: TooltipProps) => {
  // Fix Jest issues.
  if (!ReactTooltip) {
    return null;
  }

  return (
    <>
      <span
        data-tip={props.label}
        data-place={props.placement}
        data-background-color={props.appearance === 'light' ? '#FAFAFF' : undefined}
        data-border-color={props.appearance === 'light' ? '#FAFAFF' : undefined}
        data-text-color={props.appearance === 'light' ? '#56566B' : undefined}
      >
        {props.children}
      </span>
      <ReactTooltip
        className="whitespace-pre-line max-w-md"
      />
    </>
  );
};

Tooltip.defaultProps = {
  appearance: 'dark',
};

export default Tooltip;
