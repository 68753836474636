import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import JourneysPostGroup from '../../graphql/containers/journeys/groups/JourneysPostGroup';
import Screen from '../../components/widgets/Screen';

const JourneysPost = () => (
  <Screen>
    <Heading level={1} className="mb-20">Journeys: Post</Heading>
    <JourneysPostGroup />
  </Screen>
);

export default JourneysPost;
