import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { SearchMode } from '../../store/search/types';
import { Dispatch } from 'redux';
import { changeSearchMode } from '../../store/search/actions';

interface StateProps {
  searchMode: SearchMode;
}

interface DispatchProps {
  onChangeSearchMode(searchMode: SearchMode): void;
}

interface AllProps extends StateProps, DispatchProps {

}

type SearchModeOption = {
  label: string;
  id: SearchMode;
};

const searchModes: SearchModeOption[] = [
  {
    label: 'Members',
    id: 'member',
  },
  {
    label: 'Doctors',
    id: 'doctor',
  },
];

const SearchModeSelector = (props: AllProps) => (
  <div className="mx-10">
    {searchModes.map(searchMode => (
      <button
        type="button"
        onClick={() => props.onChangeSearchMode(searchMode.id)}
        className={`border-b-2 py-5 mr-10 text-xs font-bold focus:outline-none ${searchMode.id === props.searchMode ? 'border-blue text-purple' : 'text-grey border-transparent'}`}
        key={searchMode.id}
      >
        {searchMode.label}
      </button>
    ))}
  </div>
);

const mapStateToProps = ({ search }: ApplicationState) => ({
  searchMode: search.searchMode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchMode: (searchMode: SearchMode) => dispatch(changeSearchMode(searchMode)),
});

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(SearchModeSelector);
