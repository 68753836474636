import { Reducer } from 'redux';
import { TaskListState, ClientDetailsActionTypes, TaskTypes } from './types';

const initialState: TaskListState = {
  completedTasks: {
    clientDetails: false,
    analysis: false,
    carrierRates: false,
    testInformation: false,
    summary: false,
    customisation: false,
    export: false,
    familyReport: false,
    additionalInformation: false,
  },
};

const reducer: Reducer<TaskListState> = (state = initialState, action) => {
  switch (action.type) {
    case ClientDetailsActionTypes.COMPLETE_TASK:
      const taskToComplete: TaskTypes = action.payload;

      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          [taskToComplete]: true,
        },
      };

    case ClientDetailsActionTypes.UNCOMPLETE_TASK:
      const taskToUncompleted: TaskTypes = action.payload;

      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          [taskToUncompleted]: false,
        },
      };

    case ClientDetailsActionTypes.TOGGLE_TASK:
      const taskToToggle: TaskTypes = action.payload;

      return {
        ...state,
        completedTasks: {
          ...state.completedTasks,
          [taskToToggle]: !state.completedTasks[taskToToggle],
        },
      };

    default:
      return state;
  }
};

export { reducer as taskListReducer };
