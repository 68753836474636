import * as React from 'react';
import Button from './Button';
import Spinner from './Spinner';

interface LazyLoaderProps {
  isLoading: boolean;
  nextPageRowCount: number;
  loadMore(): void;
  children: React.ReactNode;
}

const LazyLoader = (props: LazyLoaderProps) => {
  const { isLoading, nextPageRowCount, loadMore, children } = props;

  const handleLoadMore = () => {
    if (!isLoading && nextPageRowCount > 0) loadMore();
  }

  const handleScroll = () => {
    const { offsetHeight, scrollTop, scrollHeight } = document.documentElement;
    if ((scrollTop + offsetHeight) === scrollHeight) {
      handleLoadMore();
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  const showPageLoader = !(Number.isNaN(nextPageRowCount) || nextPageRowCount === 0);

  return (
    <div>
      {children}
      {showPageLoader && (
        <div className="h-16 flex justify-center items-center w-full">
          {isLoading ?
            <Spinner label="Loading records..." />
            : nextPageRowCount > 0 &&
              <Button onClick={handleLoadMore}>Load {nextPageRowCount} more</Button>
          }
        </div>
      )}
    </div>
  );
};

export default LazyLoader;
