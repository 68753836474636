import { FieldType } from '../../FieldType';

type ColWidth = 'hidden' | '1/12' | '2/12' | '3/12' | '4/12' | '5/12' | '6/12' | '7/12' | '8/12' | '9/12' | '10/12' | '11/12' | 'full';

export type TwoColSectionVariables = {
  col1Title?: string;
  col1TitleOptions?: boolean;
  col1Width: ColWidth;
  col1TickBullets?: boolean;
  col1Content: string;
  col2Title?: string;
  col2Width: ColWidth;
  col2Content: string;
  col2TickBullets?: boolean;
};

export const TwoColSectionVariableTypes: { [key in keyof TwoColSectionVariables]: FieldType } = {
  col1Title: 'text',
  col1TitleOptions: 'boolean',
  col1Width: ['hidden', '1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', 'full'],
  col1TickBullets: 'boolean',
  col1Content: 'text',
  col2Title: 'text',
  col2Width: ['hidden', '1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', 'full'],
  col2Content: 'text',
  col2TickBullets: 'boolean',
};
