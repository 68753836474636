import * as React from 'react';
import { FaBug } from './Icon';
import './styles/bug-button.scss';
import { useMe } from '../../graphql/requests/requestMe';
import { Admin } from '../../../graphql/genie-api-types';

const loadBugherd = async (user: Admin) => {
  (window as any).BugHerdConfig = {
    metadata: {
      username: user?.counsellorDetails?.name ?? user.cognitoId,
    },
    reporter: {
      email: user.email,
      required: "true"
    },
    feedback: {
      hide: true,
    }
  };

  await new Promise(function (resolve) {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.addEventListener('load', function () {
      resolve(script)
    })
    script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=l6pgbbtrakfkkbsffoykmq';
    head.appendChild(script)
  });
};

const triggerBugherd = () => {
  (window as any)._bugHerd.win.bugherd.applicationView.anonymousbar.toggleOptions()
}

const BugButton = () => {
  const me = useMe();

  React.useEffect(() => {
    if (me) {
      loadBugherd(me);
    }
  }, [me]);

  return (
    <button
      className="bug-button print:hidden"
      type="button"
      onClick={triggerBugherd}
      title="Report a bug"
    >
      <FaBug />
    </button>
  );
};

export default BugButton;
