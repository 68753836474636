import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, Query, Request, CreateRequestResult, UpdateRequestInput } from '../../../../graphql/genie-api-types';
import RequestOnHoldForm from '../../../components/request/RequestOnHoldForm';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface RequestOnHoldUpdateProps {
  onUpdate?(request: CreateRequestResult['request']): void;
  onCancel?(): void;
  requestId: Request['id'];
}

const RequestOnHoldUpdate = (props: RequestOnHoldUpdateProps) => (
  <GenieQuery
    query={gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          onHoldDate
        }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.requestId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'request'>>) => loading ?
      (
        <Spinner label="Loading request..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                onHoldDate
              }
            }
          }
          `}
        >
          {(mutate: MutationFunction<Mutation>, result) => (
            <RequestOnHoldForm
              initialValues={{
                onHold: data.request.onHoldDate,
              }}
              onCancel={props.onCancel}
              isLoading={result.loading}
              errorMessage={result?.error?.message}
              onSubmit={async (requestFields: Pick<UpdateRequestInput, 'onHold'>) => {
                const mutationResult = await mutate({
                  variables: {
                    input: {
                      requestId: props.requestId,
                      onHold: !!requestFields.onHold,
                    },
                  },
                });
                ReduxQueryListener.triggerEvents('All');

                if (mutationResult && props.onUpdate) {
                  props.onUpdate(mutationResult.data.updateRequest.request);
                }
              }}
            />
          )
          }
        </GenieMutation>
      )}
  </GenieQuery>
);

export default RequestOnHoldUpdate;
