import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { Journey, QueryJourneyArgs, Report, Request } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      request {
        id
        report {
          id
        }
      }
    }
  }
`;

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    request?: Pick<Request, 'id'> & {
      report?: Pick<Report, 'id'>;
    };
  };
}

const JourneyShowReportLink = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const dispatch = useDispatch();
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const journey = journeyQuery?.data?.journey;
  
  if (!journey?.request?.report) {
    return null;
  }
  
  const onNavigateToReport = () => dispatch(navigate.toReportPath(PATHS.REPORTS_EXPORT, 'remote', journey.request.id));

  return (
    <div className="text-xs text-grey font-nm mt-8 text-right">
      Report: <button onClick={onNavigateToReport} className="text-green">View report</button>
    </div>
  );
};

export default JourneyShowReportLink;
