import * as React from 'react';
import { startCase } from 'lodash';
import BaseForm, { BaseFormProps } from '../../widgets/BaseForm';
import FormInput from '../../widgets/form/FormInput';
import { TemplateSection, SectionTemplateName } from '../../../../utilities/report/templates';
import sections from '../../../../utilities/report/templates/sections';
import { FieldType } from '../../../../utilities/report/FieldType';
import MarkdownHelper from '../../widgets/MarkdownHelper';

interface CustomisationFormProps {
  section: TemplateSection;
  onSubmit: BaseFormProps['onSubmit'];
}

const getFormInputType = (fieldTypes: { [key: string]: FieldType }, fieldName: string) => {
  const fieldType = fieldTypes[fieldName];

  if (fieldType === 'boolean') {
    return 'checkbox';
  }

  return 'text';
};

export const getFormAs = (fieldTypes: { [key: string]: FieldType }, fieldName: string) => {
  const fieldType = fieldTypes[fieldName];

  if (Array.isArray(fieldType)) {
    return 'select';
  }

  return 'textarea';
};

export const getFormOptions = (fieldTypes: { [key: string]: FieldType }, fieldName: string) => {
  const fieldType = fieldTypes[fieldName];

  if (Array.isArray(fieldType)) {
    return fieldType.map(option => ({
      label: option,
      value: option,
      key: option,
    }));
  }

  return undefined;
};

const CustomisationForm = (props: CustomisationFormProps) => {
  const { section } = props;
  const defaultValues = sections.variableTypes[section.template as Exclude<SectionTemplateName, 'Divider'>];
  const fieldNames = Object.keys(defaultValues);

  return (
    <BaseForm
      initialValues={props.section.variables}
      onSubmit={props.onSubmit}
      submitButtonLabel="Save"
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
          <div>
            {fieldNames.map((fieldName, index) => (
              <FormInput
                type={getFormInputType(defaultValues, fieldName)}
                name={fieldName}
                as={getFormAs(defaultValues, fieldName)}
                options={getFormOptions(defaultValues, fieldName)}
                label={startCase(fieldName)}
                value={values[fieldName]}
                onChange={handleChange}
                key={fieldName}
                autoFocus={index === 0}
                setFieldValue={setFieldValue}
              />
            ))}
            <MarkdownHelper />
          </div>
        )}
    </BaseForm>
  );
};

export default CustomisationForm;
