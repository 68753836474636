import * as React from 'react';
import LoadingOverlay from '../widgets/LoadingOverlay';
import './styles/cognito-federated-sign-in.scss';

interface CognitoFederatedSignInProps {
  isLoading: boolean;
  isAuthenticated: boolean;
  signInUrl: string;
}

const CognitoFederatedSignIn = (props: CognitoFederatedSignInProps) => (
  <div className="cognito-federated-sign-in">
    {props.isAuthenticated ? (
      <div>Authenticated</div>
    ) : (
      <a href={props.signInUrl} className="inline-block px-20 py-10 text-blue-dark border border-blue-dark rounded hover:bg-blue-dark hover:text-white">
        Sign in
      </a>
    )}
    {props.isLoading ? <LoadingOverlay /> : null}
  </div>
);

export default CognitoFederatedSignIn;
