import * as React from 'react';
import { Journey, Mutation, Kit, MutationUpdateKitArgs, Member, QueryKitArgs, UpdateKitInput } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import { appSyncClient } from '../../../../utilities/appSync';
import BaseForm from '../../../components/widgets/BaseForm';
import FormInput from '../../../components/widgets/form/FormInput';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import RouteButton from '../../../components/widgets/RouteButton';
import MemberOrPlaceholder from '../../../components/member/MemberOrPlaceholder';

export interface KitsUpdateProps {
  kitIds: Kit['id'][];
  onComplete(): void;
}

const UPDATE_KIT_MUTATION = gql`
  mutation UpdateKit($input: UpdateKitInput!) {
    updateKit(input: $input) {
      kit {
        id
        dateArrivedAtEugene
        dateCreated
        dateShippedToLab
        dateShippedToMember
        registrationNumber
        recollectionRequestedDate
      }
    }
  }
`

const KITS_QUERY = gql`
  query Kit($id: ID!) {
    kit(id: $id) {
      id
      dateArrivedAtEugene
      dateCreated
      dateShippedToLab
      dateShippedToMember
      registrationNumber
      recollectionRequestedDate
      journey {
        id
        member {
          id
          name
        }
      }
    }
  }
`;

interface KitResult extends Pick<Kit, 'id' | 'dateArrivedAtEugene' | 'dateCreated' | 'dateShippedToLab' | 'dateShippedToMember' | 'registrationNumber' | 'recollectionRequestedDate'> {
  journey: {
    id: Journey['id'];
    member: Pick<Member, 'id' | 'name'>;
  };
}

interface KitResponse {
  kit: KitResult;
}

interface KitFormResult {
  kits: {
    [key: string]: Omit<UpdateKitInput, 'kitId'>;
  };
}

interface KitUpdateItem {
  label: string;
  name: keyof Omit<UpdateKitInput, 'kitId'>;
}

const KitsUpdate = (props: KitsUpdateProps) => {
  const { kitIds } = props;
  const [isLoadingKits, setIsLoadingKits] = React.useState(true);
  const [kits, setKits] = React.useState([] as KitResult[]);

  const [mutate, mutationRequest] = useMutation<Pick<Mutation, 'updateKit'>, MutationUpdateKitArgs>(UPDATE_KIT_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['ListKits'];
    },
  });

  const loadKits = async () => {
    setIsLoadingKits(true);

    const kitResults = await Promise.all(kitIds.map(kitId =>
      appSyncClient.query<KitResponse, QueryKitArgs>({
        query: KITS_QUERY,
        fetchPolicy: 'network-only',
        variables: { id: kitId },
      })));

    setKits(kitResults.filter(response => response.data?.kit).map(response => response.data.kit));

    setIsLoadingKits(false);
  };

  React.useEffect(() => {
    loadKits();
  }, [kitIds]);

  const initialValues: KitFormResult = {
    kits: {},
  };

  kits?.forEach(kit => {
    initialValues.kits[kit.id] = {
      registrationNumber: kit.registrationNumber,
      shippedToMember: !!kit.dateShippedToMember,
      arrivedAtEugene: !!kit.dateArrivedAtEugene,
      shippedToLab: !!kit.dateShippedToLab,
    };
  });

  if (isLoadingKits) {
    return <LoadingOverlay />;
  }

  const kitItems: KitUpdateItem[] = [
    {
      label: 'Shipped to member',
      name: 'shippedToMember',
    },
    {
      label: 'Arrived at Eugene',
      name: 'arrivedAtEugene',
    },
    {
      label: 'Shipped to lab',
      name: 'shippedToLab',
    },
  ];

  return (
    <BaseForm
      submitButtonLabel="Update Kit(s)"
      initialValues={initialValues}
      isLoading={isLoadingKits}
      errorMessage={mutationRequest?.error?.message}
      onSubmit={async (kitFields: KitFormResult) => {
        await Promise.all(kits.map(kit => {
          if (kit.recollectionRequestedDate) {
            showToast('warning', 'Kit did not update', 'Cannot update a recollected kit.');
            throw new Error('Kit update incomplete');
          }

          const variables: MutationUpdateKitArgs = {
            input: {
              kitId: kit.id,
              ...kitFields?.kits?.[kit.id],
            },
          };

          return mutate({
            variables,
          });
        }));

        const registrationNumbers = Object.values(kitFields.kits).map(kitValues => kitValues.registrationNumber);

        showToast('success', 'Kits entered', `Kits ${registrationNumbers.join(',')} added to Genie.`);

        props.onComplete();
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
      }) => (
          <div>
            {kits.map(kit => {
              return (
                <div key={kit.id} className="border-b">
                  <MemberOrPlaceholder member={kit?.journey?.member}>
                    {(member) => (
                      <h3 className="text-lg font-bold mb-20">{member.name}&apos;s kit</h3>
                    )}
                  </MemberOrPlaceholder>
                  {kitItems.map(kitItem => (
                    <FormInput
                      name={`kits[${kit.id}][${kitItem.name}]`}
                      key={kitItem.name}
                      type="checkbox"
                      label={kitItem.label}
                      value={values?.kits?.[kit.id]?.[kitItem.name]}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      labelClassName={`${(initialValues?.kits?.[kit.id]?.[kitItem.name]) !== values?.kits?.[kit.id]?.[kitItem.name] ? 'bg-orange-light' : 'bg-white'} border`}
                      disabled={!!kit.recollectionRequestedDate}
                    />
                  ))}
                  <div>
                    <FormInput
                      name={`kits[${kit.id}][registrationNumber]`}
                      label="Registration number"
                      value={values?.kits?.[kit.id]?.registrationNumber}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      placeholder="e.g. IB1234567"
                      className="flex-1"
                      disabled={!!kit.recollectionRequestedDate}
                    />
                  </div>
                  {kit.recollectionRequestedDate ? (
                    <div className="mb-10">
                      Recollected: {kit.recollectionRequestedDate}
                    </div>
                  ): (
                    <RouteButton
                      variant="danger"
                      query={{ form: 'RecollectKit', formParams: JSON.stringify({ kitId: kit.id }) }}
                      className="mb-20"
                    >
                      Recollect this kit
                    </RouteButton>
                  )}
                </div>
              )
            })}
          </div>
        )
      }
    </BaseForm>
  );
}

export default KitsUpdate;
