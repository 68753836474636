import runStatsQuery from '../../../utilities/runStatsQuery';
import gql from 'graphql-tag';
import { Request } from '../../../graphql/genie-api-types';
import moment from 'moment';
import { QueryListDoctorClinicPairsArgs } from '../../../graphql/genie-api-types';
import { DoctorClinicPairsConnection } from '../../../graphql/genie-api-types';
import { JourneyState } from '../../../graphql/genie-api-types';
import { sortBy } from 'lodash';
import { extractStateFromAddress, preferredDoctorClinicEmail } from '../../../utilities/helpers';

const DOCTOR_CLINICS_QUERY = gql`
  query ListDoctorClinics($input: ListDoctorClinicPairsInput!) {
    listDoctorClinicPairs(input: $input) {
      edges {
        cursor
        node {
          email
          doctor {
            id 
            nameFirst
            nameMiddle
            nameLast
            gender
            nickname
            personalMobile
            personalPhone
            personalEmail
            registrationNumber
            specialties
          }
          clinic {
            name
            addressSuburb
            addressState
            addressPostcode
            addressCountry
            addressStreet
            clinicEmail
            clinicPhone
            locationId
            latitude
            longitude
          }
          requests {
            id
            datePaid
            dateCreated
            product
            journeys {
              id
              state
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const extractRollingDayRequests = (requests: Request[], lastDays: number) => {
  if (!requests) {
    return [];
  }

  const momentBeginning = moment().startOf('day').subtract(lastDays, 'days');

  return requests.filter(request => {
    const paidMoment = moment(request.dateCreated);

    return paidMoment.isSameOrAfter(momentBeginning);
  });
};

const extractRollingDayConversions = (requests: Request[], lastDays: number) => {
  if (!requests) {
    return [];
  }

  const momentBeginning = moment().startOf('day').subtract(lastDays, 'days');

  return requests.filter(request => request.datePaid).filter(request => {
    const paidMoment = moment(request.datePaid);

    return paidMoment.isSameOrAfter(momentBeginning);
  });
}

type FieldName =
  'Clinic Name' |
  'First Name' |
  'Last Name' |
  'Email Address' |
  'Phone' |
  'State' |
  'First Referral' |
  'Last Referral' |
  '7 day carrier referrals' |
  '7 day carrier conversions' |
  '15 day carrier referrals' |
  '15 day carrier conversions' |
  '30 day carrier referrals' |
  '30 day carrier conversions' |
  '60 day carrier referrals' |
  '60 day carrier conversions' |
  '120 day carrier referrals' |
  '120 day carrier conversions' |
  '365 day carrier referrals' |
  '365 day carrier conversions' |
  'Lifetime carrier referrals' |
  'Lifetime carrier conversions' |
  '7 day cancer referrals' |
  '7 day cancer conversions' |
  '15 day cancer referrals' |
  '15 day cancer conversions' |
  '30 day cancer referrals' |
  '30 day cancer conversions' |
  '60 day cancer referrals' |
  '60 day cancer conversions' |
  '120 day cancer referrals' |
  '120 day cancer conversions' |
  '365 day cancer referrals' |
  '365 day cancer conversions' |
  'Lifetime cancer referrals' |
  'Lifetime cancer conversions' |
  'Genie url' |
  'Company domain name' |
  'Specialty';

type DoctorRow = { [key in FieldName]: string | number };

const requestHubspotDoctorData = async () => {
  const doctorClinicResults = await runStatsQuery<DoctorClinicPairsConnection, QueryListDoctorClinicPairsArgs>(DOCTOR_CLINICS_QUERY, {}, 'listDoctorClinicPairs', 50);

  const doctorClinics = doctorClinicResults.edges.map(edge => edge.node).filter(doctorClinic => preferredDoctorClinicEmail(doctorClinic) !== '');

  doctorClinics.forEach(doctorClinic => {
    doctorClinic
    doctorClinic.requests = doctorClinic.requests.filter(request => {
      return request.datePaid && !request.journeys.find(journey => journey.state === JourneyState.Trash);
    });
  });

  const doctorClinicRows: DoctorRow[] = doctorClinics.map(doctorClinic => {
    const email = preferredDoctorClinicEmail(doctorClinic);

    const requestsByDate = sortBy(doctorClinic.requests, 'datePaid');

    const firstRequestDate = requestsByDate.length > 0 ? moment(requestsByDate[0].datePaid).format('MM/DD/YYYY') : '';
    const lastRequestDate = requestsByDate.length > 0 ? moment(requestsByDate[requestsByDate.length - 1].datePaid).format('MM/DD/YYYY') : '';

    const carrierRequests = doctorClinic.requests?.filter(request => request.product.includes('CARRIER'));
    const cancerRequests = doctorClinic.requests?.filter(request => request.product.includes('CANCER'));

    let nameFirst = doctorClinic.doctor.nameFirst;
    let nameLast = '';

    if (doctorClinic.doctor.nameMiddle) {
      nameLast = doctorClinic.doctor.nameMiddle;
    }

    if (doctorClinic.doctor.nameLast) {
      nameLast = nameLast + ' ' + doctorClinic.doctor.nameLast;
    }

    nameLast = nameLast.trim();

    if (!nameLast) {
      const splitNames = doctorClinic.doctor.nameFirst.split(' ');
      nameFirst = splitNames[0];
      nameLast = splitNames.slice(1).join(' ');
    }

    // If the state field is set, use that. Otherwise, try to extract it from the address.
    const addressState = extractStateFromAddress(doctorClinic.clinic?.addressState ?? doctorClinic.clinic?.addressStreet);

    return {
      'Clinic Name': doctorClinic.clinic?.name,
      'First Name': nameFirst,
      'Last Name': nameLast,
      'Specialty': doctorClinic.doctor.specialties,
      'Email Address': email,
      'Phone': doctorClinic.doctor.personalPhone,
      'State': addressState,
      'First Referral': firstRequestDate,
      'Last Referral': lastRequestDate,
      '7 day carrier referrals': extractRollingDayRequests(carrierRequests, 7).length,
      '7 day carrier conversions': extractRollingDayConversions(carrierRequests, 7).length,
      '15 day carrier referrals': extractRollingDayRequests(carrierRequests, 15).length,
      '15 day carrier conversions': extractRollingDayConversions(carrierRequests, 15).length,
      '30 day carrier referrals': extractRollingDayRequests(carrierRequests, 30).length,
      '30 day carrier conversions': extractRollingDayConversions(carrierRequests, 30).length,
      '60 day carrier referrals': extractRollingDayRequests(carrierRequests, 60).length,
      '60 day carrier conversions': extractRollingDayConversions(carrierRequests, 60).length,
      '120 day carrier referrals': extractRollingDayRequests(carrierRequests, 120).length,
      '120 day carrier conversions': extractRollingDayConversions(carrierRequests, 120).length,
      '365 day carrier referrals': extractRollingDayRequests(carrierRequests, 365).length,
      '365 day carrier conversions': extractRollingDayConversions(carrierRequests, 365).length,
      'Lifetime carrier referrals': extractRollingDayRequests(carrierRequests, 365 * 10).length, // 10 years
      'Lifetime carrier conversions': extractRollingDayConversions(carrierRequests, 365 * 10).length, // 10 years
      '7 day cancer referrals': extractRollingDayRequests(cancerRequests, 7).length,
      '7 day cancer conversions': extractRollingDayConversions(cancerRequests, 7).length,
      '15 day cancer referrals': extractRollingDayRequests(cancerRequests, 15).length,
      '15 day cancer conversions': extractRollingDayConversions(cancerRequests, 15).length,
      '30 day cancer referrals': extractRollingDayRequests(cancerRequests, 30).length,
      '30 day cancer conversions': extractRollingDayConversions(cancerRequests, 30).length,
      '60 day cancer referrals': extractRollingDayRequests(cancerRequests, 60).length,
      '60 day cancer conversions': extractRollingDayConversions(cancerRequests, 60).length,
      '120 day cancer referrals': extractRollingDayRequests(cancerRequests, 120).length,
      '120 day cancer conversions': extractRollingDayConversions(cancerRequests, 120).length,
      '365 day cancer referrals': extractRollingDayRequests(cancerRequests, 365).length,
      '365 day cancer conversions': extractRollingDayConversions(cancerRequests, 365).length,
      'Lifetime cancer referrals': extractRollingDayRequests(cancerRequests, 365 * 10).length, // 10 years
      'Lifetime cancer conversions': extractRollingDayConversions(cancerRequests, 365 * 10).length, // 10 years

      'Genie url': `https://genie.eugenelabs.com/doctors/${doctorClinic.doctor.id}`,
      'Company domain name': email ? email.split('@')[1] : '',
    };
  });

  const header: FieldName[] = [
    'Clinic Name',
    'First Name',
    'Last Name',
    'Specialty',
    'Email Address',
    'Phone',
    'State',
    'First Referral',
    'Last Referral',
    '7 day carrier referrals',
    '7 day carrier conversions',
    '15 day carrier referrals',
    '15 day carrier conversions',
    '30 day carrier referrals',
    '30 day carrier conversions',
    '60 day carrier referrals',
    '60 day carrier conversions',
    '120 day carrier referrals',
    '120 day carrier conversions',
    '365 day carrier referrals',
    '365 day carrier conversions',
    'Lifetime carrier referrals',
    'Lifetime carrier conversions',
    '7 day cancer referrals',
    '7 day cancer conversions',
    '15 day cancer referrals',
    '15 day cancer conversions',
    '30 day cancer referrals',
    '30 day cancer conversions',
    '60 day cancer referrals',
    '60 day cancer conversions',
    '120 day cancer referrals',
    '120 day cancer conversions',
    '365 day cancer referrals',
    '365 day cancer conversions',
    'Lifetime cancer referrals',
    'Lifetime cancer conversions',
    'Genie url',
    'Company domain name',
  ];

  let csvContent = "data:text/csv;charset=utf-8,"
    + header.join(',') + "\n";

  doctorClinicRows.forEach(line => {
    const csvLine = header.map(headerLabel => {
      return `"${line[headerLabel] ?? ''}"`;
    }).join(',');

    csvContent += `${csvLine} \n`;
  });

  window.open(encodeURI(csvContent));
};

export default requestHubspotDoctorData;
