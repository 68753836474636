import gql from "graphql-tag";
import { Clinic, Doctor, DoctorClinic } from "../graphql/genie-api-types";

export const DOCTOR_CLINIC_FRAGMENT = gql`
  fragment DoctorClinicFields on DoctorClinic {
    id
    email
    doctor {
      id
      nameFirst
      nameLast
      personalEmail
    }
    clinic {
      name
      addressSuburb
      clinicEmail
    }
  }
`;

export type DoctorClinicPartial = Pick<DoctorClinic, 'id' | 'email'> & {
  doctor: Pick<Doctor, 'id' | 'personalEmail' | 'title' | 'nameFirst' | 'nameMiddle' | 'nameLast'>;
  clinic: Pick<Clinic, 'name' | 'clinicEmail' | 'addressSuburb'>;
};

export function formatDoctorClinicName(doctorClinic: DoctorClinicPartial): string {
  if (!doctorClinic || !doctorClinic.doctor) {
    return '';
  }

  const doctorName = formatDoctorName(doctorClinic);
  const clinicName = formatClinicName(doctorClinic);
  let doctorClinicName = `${doctorName}, ${clinicName}`;

  return doctorClinicName.replace(/\s+/g, ' ').replace(/(,|\s)+$/, '');
}

export function formatDoctorName(doctorClinic: DoctorClinicPartial): string {
  if (!doctorClinic || !doctorClinic.doctor) {
    return '';
  }

  const { doctor } = doctorClinic;
  const nameParts = [doctor.nameFirst, doctor.nameMiddle, doctor.nameLast].filter(Boolean);

  return nameParts.join(' ');
}

export function formatClinicName(doctorClinic: DoctorClinicPartial): string {
  if (!doctorClinic || !doctorClinic.clinic) {
    return '';
  }

  const { clinic } = doctorClinic;
  const clinicName = clinic.name || 'Unknown Clinic';
  const suburbString = clinic.addressSuburb ? `(${clinic.addressSuburb.trim()})` : '';

  return `${clinicName} ${suburbString}`.replace(/\s+/g, ' ').replace(/(,|\s)+$/, '');
}
