import { Store, AnyAction } from 'redux';
import { ApplicationState } from '.';
import { ThunkAction } from 'redux-thunk';

type ApplicationStore = Store<ApplicationState, AnyAction>;
export type ListenerEvent = 'UpdateClinic' | 'UpdateDoctor' | 'UpdateNotes' | 'UpdateFamilyHistory' | 'UpdateFlag' | 'UpdateConsent' | 'All';

type ListenerAction = (listenerEvent?: ListenerEvent, ...args: any) => AnyAction | ThunkAction<any, any, any, any>;
type ListenerCallback = (listenerEvent?: ListenerEvent) => void;

class ReduxQueryListener {
  static store: ApplicationStore = null;

  static actions: ListenerAction[] = [];
  static callbacks: ListenerCallback[] = [];

  static initWithStore(store: ApplicationStore) {
    this.store = store;
  }

  static addActionListener(action: ListenerAction) {
    this.actions.push(action);
  }
  
  static addCallbackListener(callback: ListenerCallback) {
    this.callbacks.push(callback);
  }
  
  static removeCallbackListener(callback: ListenerCallback) {
    const callbackIndex = this.callbacks.indexOf(callback);
    if (callbackIndex > -1) {
      this.callbacks.splice(callbackIndex, 1);
    }
  }

  static triggerEvents(event: ListenerEvent, ...args: any[]) {
    this.actions.forEach(action => {
      const actionPayload = action(event, ...args) as AnyAction;
      if (actionPayload) {
        this.store.dispatch(actionPayload);
      }
    });
    
    this.callbacks.forEach(callback => {
      if (callback) {
        callback(event);
      }
    });
  }
}
export default ReduxQueryListener;
