import * as React from 'react';
import { QueryResult } from 'react-apollo';
import SearchAutocomplete, { SearchAutocompleteOption } from '../../../components/widgets/SearchAutocomplete';
import GenieQuery from '../../base/GenieQuery';
import gql from 'graphql-tag';
import { DoctorClinicPartial, formatDoctorClinicName } from '../../../../utilities/doctorClinic';



interface DoctorClinicSearchAutocompleteProps {
  onSelect(option: DoctorClinicPartial): void;
  defaultValue?: DoctorClinicPartial;
}

type DoctorClinicQueryResult = {
  searchDoctorClinicPairs: DoctorClinicPartial[];
};

function toOption(doctorClinic: DoctorClinicPartial): SearchAutocompleteOption {
  return {
    ...doctorClinic,
    name: formatDoctorClinicName(doctorClinic),
  }
}



const DoctorClinicSearchAutocomplete: React.FC<DoctorClinicSearchAutocompleteProps> = (props: DoctorClinicSearchAutocompleteProps) => (
  <GenieQuery
    query={gql`
      query SearchDoctorClinicPairs($term: String!) {
        searchDoctorClinicPairs(term: $term) {
          id
          doctor {
            id
            nameFirst
            nameLast
          }
          clinic {
            name
          }
        }
      }
    `}
    notifyOnNetworkStatusChange
    variables={{
      term: '',
    }}
  >
    {({ loading, data, fetchMore }: QueryResult<DoctorClinicQueryResult>) => (
      <SearchAutocomplete
        isLoading={loading}
        options={data && data.searchDoctorClinicPairs ? data.searchDoctorClinicPairs.map(toOption) : []}
        onSearch={(searchQuery: string) => {
          fetchMore({
            variables: {
              term: searchQuery,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
              ...previousResult,
              ...fetchMoreResult,
            }),
          });
        }}
        onSelect={option => props.onSelect(option as unknown as DoctorClinicPartial)}
        defaultSelected={props.defaultValue ? [toOption(props.defaultValue)] : undefined}
      />
    )}
  </GenieQuery>
);

export default DoctorClinicSearchAutocomplete;
