import { CarrierRisk } from '../../app/store/report/analysis/types';
import { intersection, difference } from 'lodash';
import { ClientFields } from '../../app/store/report/client-details/types';
import { pluralise, generateAndList, addDiseaseRiskStyle } from '../helpers';
import { Disease } from '../../graphql/genie-api-types';

/*
 * Note: This class should only be used to provide rules for prefilled default values. Any actual report analysis should
 * be done by a genetic counsellor.
 */

export const getDefaultCarrierLabel = (gene: string, clientAffectedGenes: string[]): string => {
  if (clientAffectedGenes.includes(gene)) {
    return 'Carrier';
  }

  return 'Not a carrier';
};

export const getDefaultCarrierRisk = (gene: string, clientAAffectedGenes: string[], clientBAffectedGenes: string[]): CarrierRisk => {
  if (areBothClientsCarriers(gene, clientAAffectedGenes, clientBAffectedGenes)) {
    return 'High Risk';
  }

  return 'Low Risk';
};

export const areBothClientsCarriers = (gene: string, clientAAffectedGenes: string[], clientBAffectedGenes: string[]) =>
  clientAAffectedGenes.includes(gene) && clientBAffectedGenes.includes(gene);

const quickNoteSingleCarrierSentence = (carrierName: string, diseaseNames: string[]) => {
  return `${carrierName} was found to have a pseudodeficiency allele in the gene${diseaseNames.length > 1 ? 's' : ''} related to ${generateAndList(diseaseNames)}.`;
};

const quickNoteMultiCarrierSentence = (carrierAName: string, carrierBName: string, diseaseNames: string[]) => {
  return `${carrierAName} & ${carrierBName} were found to have a pseudodeficiency allele in the gene${diseaseNames.length > 1 ? 's' : ''} related to ${generateAndList(diseaseNames)}.`;
};

export const getQuickNoteForPseudoDiseases = (pseudoDiseases: { [key: string]: Disease }, clientA: ClientFields, clientB: ClientFields): string => {
  if (Object.values(pseudoDiseases).length === 0) {
    return '';
  }

  const clientAPseudoGenes = clientA.pseudogenes;
  const clientBPseudoGenes = clientB.pseudogenes;

  const quickNoteSentences: string[] = [];

  const sharedAffectedDiseases = intersection(clientAPseudoGenes, clientBPseudoGenes).map((gene: string) =>
    pseudoDiseases[gene].name);

  const clientAExclusivePseudoDiseases = difference(clientAPseudoGenes, clientBPseudoGenes).map((gene: string) =>
    pseudoDiseases[gene].name);
  const clientBExclusivePseudoDiseases = difference(clientBPseudoGenes, clientAPseudoGenes).map((gene: string) =>
    pseudoDiseases[gene].name);

  if (clientAExclusivePseudoDiseases.length > 0) {
    quickNoteSentences.push(quickNoteSingleCarrierSentence(clientA.nickname, clientAExclusivePseudoDiseases));
  }

  if (clientBExclusivePseudoDiseases.length > 0) {
    quickNoteSentences.push(quickNoteSingleCarrierSentence(clientB.nickname, clientBExclusivePseudoDiseases));
  }

  if (sharedAffectedDiseases.length > 0) {
    quickNoteSentences.push(quickNoteMultiCarrierSentence(clientA.nickname, clientB.nickname, sharedAffectedDiseases));
  }

  quickNoteSentences.push('This is not the same as being a carrier for the condition. This pseudodeficiency allele is not associated with the condition but can sometimes show false positives in the results of blood tests, including newborn screenings.');

  return quickNoteSentences.join(' ');
};

export const getWhatWeFound = (diseases: { [key: string]: Disease }, clientA: ClientFields, clientB: ClientFields): string => {
  const clientAAffectedGenes = clientA.affectedGenes;
  const clientBAffectedGenes = clientB.affectedGenes;
  const hasSecondClient = !!clientB.fullName;

  const whatWeFoundParagraphs: string[] = [];
  let sentences: string[] = [];

  const sharedAffectedDiseases = intersection(clientAAffectedGenes, clientBAffectedGenes).map((gene: string) =>
    diseases[gene].name).map(diseaseName => addDiseaseRiskStyle(diseaseName, 'High Risk'));

  const clientAExclusiveDiseases = difference(clientAAffectedGenes, clientBAffectedGenes).map((gene: string) =>
    diseases[gene].name).map(diseaseName => addDiseaseRiskStyle(diseaseName, 'Low Risk'));
  const clientBExclusiveDiseases = difference(clientBAffectedGenes, clientAAffectedGenes).map((gene: string) =>
    diseases[gene].name).map(diseaseName => addDiseaseRiskStyle(diseaseName, 'Low Risk'));

  const sharedDiseaseCount = sharedAffectedDiseases.length;
  if (sharedDiseaseCount > 0) {
    sentences = [];
    sentences.push(`The results show that both of you carry ${pluralise('a gene variation', 'gene variations', sharedDiseaseCount)} for ${generateAndList(sharedAffectedDiseases)}.`);
    sentences.push('It\'s important to know that carriers are completely healthy.');
    sentences.push(`However, as you both carry ${pluralise('a variation in this gene', 'variations in these genes', sharedDiseaseCount)}, with each pregnancy you have there's a 25% chance that your child will be affected by ${pluralise('the condition', 'one or more of the conditions', sharedDiseaseCount)}.`);
    sentences.push(`There's also a 75% chance that they will be healthy and not affected by the ${pluralise('condition', 'conditions', sharedDiseaseCount)}.`);
    sentences.push(`The options mentioned below can also help you avoid passing on the ${pluralise('condition', 'conditions', sharedDiseaseCount)} altogether.`);
    whatWeFoundParagraphs.push(sentences.join(' '));
  }

  const bothHaveExclusiveDiseases = clientAExclusiveDiseases.length && clientBExclusiveDiseases.length;
  if (bothHaveExclusiveDiseases) {
    sentences = [];
    sentences.push(`We ${sharedDiseaseCount ? 'also ' : ''}found that ${clientA.nickname} is a carrier of ${generateAndList(clientAExclusiveDiseases)} and ${clientB.nickname} is a carrier of ${generateAndList(clientBExclusiveDiseases)}.`);
    sentences.push(
      `Don’t stress though, this is unlikely to have implications for you — since carriers are healthy and neither ${clientA.nickname} or ${clientB.nickname} share these variations.`
    );
    sentences.push('Consider sharing this information with family members as they are also more likely to be carriers.');

    whatWeFoundParagraphs.push(sentences.join(' '));
  } else if (clientAExclusiveDiseases.length || clientBExclusiveDiseases.length) {
    sentences = [];

    const carrierClient = clientAExclusiveDiseases.length ? clientA : clientB;
    const nonCarrierClient = clientAExclusiveDiseases.length ? clientB : clientA;
    const diseaseList = clientAExclusiveDiseases.length ? clientAExclusiveDiseases : clientBExclusiveDiseases;

    sentences.push(`We ${whatWeFoundParagraphs.length > 0 ? 'also ' : ''}found that ${carrierClient.nickname} is a carrier of ${generateAndList(diseaseList)}. Don’t stress though, this is unlikely to have implications for you — since carriers are healthy${hasSecondClient ? ` and ${nonCarrierClient.nickname} does not share ${pluralise('this variation', 'these variations', diseaseList.length)}` : ''}.`);
    if (!hasSecondClient) {
      sentences.push(`The chance of having a child affected by these conditions will depend on the carrier status of your reproductive partner.`)
    }
    sentences.push(`Consider sharing this information with family members as they are also more likely to be carriers.`);

    whatWeFoundParagraphs.push(sentences.join(' '));

    if (sharedDiseaseCount === 0 && hasSecondClient) {
      whatWeFoundParagraphs.push(`${nonCarrierClient.nickname} was not found to be a carrier of any of the conditions tested.`);
    }
  }
  
  if (hasSecondClient && clientAAffectedGenes.length === 0 && clientBAffectedGenes.length === 0) {
    whatWeFoundParagraphs.push(`The results show that ${clientA.nickname} and ${clientB.nickname} were not carriers of any of the conditions that were screened.`);
  }
  else if (!hasSecondClient && clientAAffectedGenes.length === 0) {
    whatWeFoundParagraphs.push(`The results show that ${clientA.nickname} is not a carrier of any of the conditions that were screened.`);
  }

  return whatWeFoundParagraphs.join('\n\n');
};
