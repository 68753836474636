import * as React from 'react';
import { QueryResult } from 'react-apollo';
import SearchAutocomplete, { SearchAutocompleteProps } from '../../../components/widgets/SearchAutocomplete';
import GenieQuery from '../../base/GenieQuery';
import { Counsellor } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';

export type CounsellorPartial = Pick<Counsellor, 'id' | 'name'>;

interface CounsellorSearchAutocompleteProps {
  onSelect(option: CounsellorPartial): void;
  defaultValue?: CounsellorPartial;
  autocompleteSize?: SearchAutocompleteProps['size'];
}

type CounsellorQueryResult = {
  searchCounsellors: CounsellorPartial[];
};

const CounsellorSearchAutocomplete = (props: CounsellorSearchAutocompleteProps) => (
  <GenieQuery
    query={gql`
      query SearchCounsellors($term: String!) {
        searchCounsellors(term: $term) {
          id
          name
        }
      }
    `}
    notifyOnNetworkStatusChange
    variables={{
      term: '',
    }}
  >
    {({ loading, data, fetchMore }: QueryResult<CounsellorQueryResult>) => (
      <SearchAutocomplete
        isLoading={loading}
        size={props.autocompleteSize}
        options={data && data.searchCounsellors ? data.searchCounsellors.map(({ id, name }) => ({
          id,
          name,
        })) : []}
        placeholder="Search counsellors..."
        onSearch={(searchQuery: string) => {
          fetchMore({
            variables: {
              term: searchQuery,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
              ...previousResult,
              ...fetchMoreResult,
            }),
          });
        }}
        onSelect={option => props.onSelect(option as CounsellorPartial)}
        defaultSelected={props.defaultValue ? [{
          name: props.defaultValue.name,
          id: props.defaultValue.id,
        }] : undefined}
      />
    )}
  </GenieQuery>
);

export default CounsellorSearchAutocomplete;
