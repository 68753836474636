import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { pick } from 'lodash';
import { Mutation, Member, UpdateMemberInput, CreateMemberResult, Query } from '../../../../graphql/genie-api-types';
import MemberForm from '../../../components/member/MemberForm';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface MemberUpdateProps {
  onUpdate?(member: CreateMemberResult['member']): void;
  onCancel?(): void;
  memberId: Member['id'];
}

const MemberUpdate = (props: MemberUpdateProps) => (
  <GenieQuery
    query={gql`
      query Member($id: ID!) {
        member(id: $id) {
          id
          dateOfBirth
          email
          name
          nickname
          phoneNumber
          sex
          onlineAccessEnabled
        }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.memberId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'member'>>) => loading ?
      (
        <Spinner label="Loading request..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateMember($input: UpdateMemberInput!) {
            updateMember(input: $input) {
                member {
                  id
                  name
                  nickname
                  email
                  dateOfBirth
                  phoneNumber
                  sex
                  onlineAccessEnabled
                }
              }
            }
          `}
          refetchQueries={() => {
            return ['Journey'];
          }}
        >
          {(mutate: MutationFunction<Mutation>, result) => (
            <MemberForm
              initialValues={{
                ...data.member,
              }}
              onCancel={props.onCancel}
              isLoading={result.loading}
              errorMessage={result?.error?.message}
              onSubmit={async (memberFields: UpdateMemberInput) => {
                if (memberFields.email === '') {
                  memberFields.email = null;
                }
                const mutationResult = await mutate({
                  variables: {
                    input: {
                      memberId: props.memberId,
                      ...pick<UpdateMemberInput>(memberFields, [
                        'dateOfBirth',
                        'email',
                        'name',
                        'nickname',
                        'phoneNumber',
                        'sex',
                        'onlineAccessEnabled',
                      ]),
                    },
                  },
                });

                ReduxQueryListener.triggerEvents('All');

                if (mutationResult && props.onUpdate) {
                  props.onUpdate(mutationResult.data.updateMember.member);
                }
              }}
            />
          )
          }
        </GenieMutation>
      )}
  </GenieQuery>
);

export default MemberUpdate;
