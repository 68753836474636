import { ApolloQueryResult } from 'apollo-client';
import { appSyncClient } from '../../../utilities/appSync';
import { Disease } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';

export type DiseaseByGeneResult = Pick<Disease,
  'id'|
  'name'|
  'description'|
  'resources'|
  'carrierInfo'|
  'individualCarrierInfoForCouple'|
  'individualCarrierNextSteps'|
  'affectedCoupleInfo'|
  'affectedCoupleOptions'|
  'affectedCoupleActions'|
  'genes'|
  'cms'
>;

interface RequestDiseasesByGeneQueryResult {
  diseaseByGene: DiseaseByGeneResult;
}

interface GeneQueryResult {
  result: ApolloQueryResult<RequestDiseasesByGeneQueryResult>;
  gene: string;
}

export type RequestDiseasesByGeneResult = { [key: string]: DiseaseByGeneResult };

const requestDiseasesByGene = async (genes: string[]): Promise<RequestDiseasesByGeneResult> => {
  const diseaseQueries: Promise<GeneQueryResult>[] = genes.map(gene => appSyncClient.query<RequestDiseasesByGeneQueryResult>({
    query: gql`
      query DiseaseByGene($gene: String!) {
        diseaseByGene(gene: $gene) {
          id
          name
          genes
          description
          resources
          carrierInfo
          individualCarrierInfoForCouple
          individualCarrierNextSteps
          affectedCoupleInfo
          affectedCoupleOptions
          affectedCoupleActions
          cms {
            entryId
            spaceId
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      gene,
    },
  }).then(({ errors, ...result }) => ({
    gene,
    result: {
      errors: errors ? errors : null, // Fixes typescript compilation issue with incompatible graphql return signature.
      ...result,
    },
  })));

  const geneQueryResults: GeneQueryResult[] = await Promise.all(diseaseQueries);

  const diseasesByGene: RequestDiseasesByGeneResult = {};

  geneQueryResults.forEach((geneQueryResult: GeneQueryResult) => {
    diseasesByGene[geneQueryResult.gene] = geneQueryResult.result.data.diseaseByGene;
  });

  return diseasesByGene;
};

export default requestDiseasesByGene;
