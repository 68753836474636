import * as React from 'react';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: 'link' | 'primary' | 'secondary' | 'warning' | 'danger' | 'success' | 'outline-primary';
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  textAlign?: 'center' | 'right' | 'left';
  disabled?: boolean;
  isActive?: boolean;
}

const Button = ({ className, variant, size, textAlign, isActive, ...props }: ButtonProps) => {
  const classes = ['btn', `text-${textAlign}`, `btn--${size}`, 'apperance-none'];

  let buttonColor = 'purple';

  switch (variant) {
    case 'primary':
    case 'outline-primary':
      buttonColor = 'purple';
      break;
    case 'secondary':
      buttonColor = 'grey';
      break;
    case 'warning':
      buttonColor = 'orange-dark';
      break;
    case 'danger':
      buttonColor = 'red';
      break;
    case 'success':
      buttonColor = 'green-dark';
      break;
    default:
      break;
  }

  if (isActive && variant !== 'link') {
    classes.push('text-white');
    classes.push(`border-${buttonColor}`);
    classes.push(`bg-${buttonColor}`);
  } else {
    classes.push(`text-${buttonColor}`);
    if (variant !== 'link') {
      classes.push(`border-${buttonColor}`);
      classes.push(`hover:bg-${buttonColor}`);
      classes.push('bg-white');
      classes.push('hover:text-white');
    }
  }

  if (className) {
    classes.push(className);
  }

  if (props.disabled) {
    classes.push('opacity-50');
  }

  return (
    <button
      className={classes.join(' ')}
      {...props}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
  type: 'button',
  textAlign: 'center',
};

export default Button;
