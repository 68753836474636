import { appSyncClient } from '../../../../utilities/appSync';
import { Mutation, MutationCreateRequestNoteArgs, Request } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

type RequestNoteResult = {
  createRequestNote: {
    request: Pick<Request, 'notes'>;
  };
}

const addRequestNote = async (requestId: string, text: string): Promise<ApolloQueryResult<RequestNoteResult>> => {
  const result = await appSyncClient.mutate<Pick<Mutation, 'createRequestNote'>, MutationCreateRequestNoteArgs>({
    mutation: gql`
      mutation CreateRequestNote($input: CreateRequestNoteInput!) {
        createRequestNote(input: $input) {
          request {
            id
            notes {
              isImportant
              date 
              text
              username
            }
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        requestId,
        text,
      },
    },
  }) as ApolloQueryResult<RequestNoteResult>;

  ReduxQueryListener.triggerEvents('All');

  return result;
};
 

export default addRequestNote;
