import * as React from 'react';
import SearchAutocomplete from '../../../components/widgets/SearchAutocomplete';
import MemberSearchResults, { MemberSearchResult } from '../MemberSearchResults';

interface MemberSearchAutocompleteProps {
  onSelect(member: MemberSearchResult): void;
  defaultValue?: MemberSearchResult;
  placeholder?: string;
}

type MemberSearchAutocompleteState = {
  searchTerm: string;
};

class MemberSearchAutocomplete extends React.PureComponent<MemberSearchAutocompleteProps, MemberSearchAutocompleteState> {
  state: Readonly<MemberSearchAutocompleteState> = {
    searchTerm: '',
  };

  onSearch = (searchTerm: string) => {
    this.setState({
      searchTerm,
    });
  }
  render() {
    const { onSelect, defaultValue, placeholder } = this.props;
    const { searchTerm } = this.state;

    return (
      <MemberSearchResults
        searchTerm={searchTerm}
      >
        {(results, isLoading) => (
          <SearchAutocomplete
            isLoading={isLoading}
            onSearch={this.onSearch}
            placeholder={placeholder}
            onSelect={member => onSelect(member as MemberSearchResult)}
            options={results.map(({ id, name, dateOfBirth }) => ({
              id,
              name: `${name} ${dateOfBirth ? `- ${dateOfBirth}` : '' }`,
            }))}
            defaultSelected={defaultValue ? [{
              name: defaultValue.name,
              id: defaultValue.id,
            }] : undefined}
          />
        )}
      </MemberSearchResults>
    );
  }
}

export default MemberSearchAutocomplete;
