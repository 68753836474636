import * as React from 'react';

interface LoomVideoProps {
  videoId: string;
}

const videoWrapperStyle: React.CSSProperties = {
  position: 'relative',
  paddingBottom: '56.25%',
  height: '0',
};

const videoStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

const LoomVideo = (props: LoomVideoProps) => {
  return (
    <div style={videoWrapperStyle}>
      <iframe src={`https://www.loom.com/embed/${props.videoId}`} frameBorder="0" allowFullScreen style={videoStyle}></iframe>
    </div>
  );
};

export default LoomVideo;