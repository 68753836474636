import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { changeFields } from '../../../store/report/test-information/actions';
import { RouteComponentProps } from 'react-router';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';
import { TestInformationState } from '../../../store/report/test-information/types';
import TestInformationForm from '../../../components/report/test-information/TestInformationForm';
import Modal from '../../../components/widgets/Modal';
import { showToast } from '../../../store/toast/actions';
import { ProductSelectValue } from '../../../components/widgets/form/ProductSelect';

interface TestInformationFormValues extends Omit<TestInformationState, 'product'|'productVariant'> {
  productAndVariant: ProductSelectValue;
}

interface TestInformationEditProps extends RouteComponentProps {
  onSave(fields: TestInformationFormValues): void;
  fields: TestInformationState;
  onClose(): void;
  product: string;
}

const TestInformationEdit = (props: TestInformationEditProps) => {
  const { product, productVariant, ...values } = props.fields;
  return (
    <Modal
      show
      onHide={props.onClose}
      title="Edit Test Information"
    >
      <TestInformationForm
        product={props.product}
        onSubmit={values => props.onSave(values as TestInformationFormValues)}
        initialValues={{
          ...values,
          productAndVariant: {
            product,
            productVariant,
          },
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  product: report.request.request?.product,
  fields: report.testInformation,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onSave: ({ productAndVariant, ...fields }: TestInformationFormValues) => {
    const { product, productVariant } = productAndVariant;
    dispatch(changeFields({ ...fields, product, productVariant }));
    dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_TEST_INFORMATION));
    showToast('success', 'Updated test information', 'Updated');
  },
  onClose: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_TEST_INFORMATION)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestInformationEdit);
