import * as React from 'react';
import { Switch } from 'react-router';
import Route from '../base/Route';
import { PATHS } from '../store/router/types';
import TasksPostPurchase from './tasks/TasksPostPurchase';
import TasksPreTest from './tasks/TasksPreTest';
import TasksDownloadReports from './tasks/TasksDownloadReports';
import TasksSales from './tasks/TasksSales';
import TasksApproveOrders from './tasks/approve-orders';
import TasksApproveOrder from './tasks/approve-orders/TaskApproveOrder';
import TasksSalesLeads from './tasks/TasksSalesLeads';
import TasksMatchDoctors from './tasks/TasksMatchDoctors';
import TasksLabOrders from './tasks/TasksLabOrders';
import TasksWriteReports from './tasks/TasksWriteReports';
import TasksReviewReports from './tasks/TasksReviewReports';
import TasksAmendedReports from './tasks/TasksAmendedReports';

const Tasks = () => {
  return (
    <Switch>
      <Route path={PATHS.TASKS_POST_PURCHASE} component={TasksPostPurchase} />
      <Route path={PATHS.TASKS_PRE_TEST} component={TasksPreTest} />
      <Route path={PATHS.TASKS_DOWNLOAD_REPORTS} component={TasksDownloadReports} />
      <Route path={PATHS.TASKS_WRITE_REPORTS} component={TasksWriteReports} />
      <Route path={PATHS.TASKS_REVIEW_REPORTS} component={TasksReviewReports} />
      <Route path={PATHS.TASKS_AMENDED_REPORTS} component={TasksAmendedReports} />
      <Route path={PATHS.TASKS_SALES} component={TasksSales} />
      <Route path={PATHS.TASKS_APPROVE_ORDERS_ORDER} component={TasksApproveOrder} />
      <Route path={PATHS.TASKS_APPROVE_ORDERS} component={TasksApproveOrders} />
      <Route path={PATHS.TASKS_SALES_LEADS} component={TasksSalesLeads} />
      <Route path={PATHS.TASKS_MATCH_DOCTORS} component={TasksMatchDoctors} />
      <Route path={PATHS.TASKS_LAB_ORDERS} component={TasksLabOrders} />
      {/* <Route path={PATHS.TASKS} component={TasksHome} /> */}
    </Switch>
  );
};

export default Tasks;
