const ENV_VARIABLES: {[key: string]: string|boolean} = {
  COGNITO_REGION: process.env.COGNITO_REGION,
  COGNITO_CALLBACK_URL: process.env.COGNITO_CALLBACK_URL,
  COGNITO_SIGN_OUT_URL: process.env.COGNITO_SIGN_OUT_URL,
  DOMAIN: process.env.DOMAIN,
  APP_SYNC_REGION: process.env.APP_SYNC_REGION,
  COGNITO_USER_POOL_ID: process.env.COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID: process.env.COGNITO_CLIENT_ID,
  APP_SYNC_APP_ID: process.env.APP_SYNC_APP_ID,
  APP_SYNC_API_URL: process.env.APP_SYNC_API_URL,
  COGNITO_DOMAIN_PREFIX: process.env.COGNITO_DOMAIN_PREFIX,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  SENTRY_ORG: process.env.SENTRY_ORG,
  ASSET_HOST: process.env.ASSET_HOST,
  PDF_API: process.env.PDF_API,
  BRIDGE_API: process.env.BRIDGE_API,
  BRIDGE_API_TOKEN_OVERRIDE: process.env.BRIDGE_API_TOKEN_OVERRIDE, // For development with production cognito token
  REPORT_GENERATOR_API: process.env.REPORT_GENERATOR_API,
  GREEN_APP_SYNC_APP_ID: process.env.GREEN_APP_SYNC_APP_ID,
  GREEN_APP_SYNC_API_URL: process.env.GREEN_APP_SYNC_API_URL,
  BLUE_APP_SYNC_APP_ID: process.env.BLUE_APP_SYNC_APP_ID,
  BLUE_APP_SYNC_API_URL: process.env.BLUE_APP_SYNC_API_URL,
};

// Prepend the preview environment flag (BLUE or GREEN) to the API endpoint variables
const prependPreviewEnvFlag = (previewFlag: 'GREEN' | 'BLUE') => {
  ENV_VARIABLES['APP_SYNC_APP_ID'] = ENV_VARIABLES[previewFlag + '_APP_SYNC_APP_ID'];
  ENV_VARIABLES['APP_SYNC_API_URL'] = ENV_VARIABLES[previewFlag + '_APP_SYNC_API_URL'];
}

const previewBranchName = process.env.CF_PAGES_BRANCH;
if (previewBranchName) {
  if (previewBranchName.toLowerCase().startsWith('green/')) {
    prependPreviewEnvFlag('GREEN');
  } else if (previewBranchName.toLowerCase().startsWith('blue/')) {
    prependPreviewEnvFlag('BLUE');
  }
}

const config = (name: string): any => {
  return ENV_VARIABLES[name];
};

export default config;
