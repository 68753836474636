import * as React from 'react';
import Button from '../widgets/Button';

interface CognitoFederatedSignOutProps {
  signOutUrl: string;
}

const CognitoFederatedSignOut = (props: CognitoFederatedSignOutProps) => {
  const logOut = () => {
    document.cookie.split(';').forEach(function(c) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); // localhost support
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;domain=.' + window.location.host + ';expires=' + new Date().toUTCString() + ';path=/');
    });
    window.location.href = props.signOutUrl;
  };

  return (
    <div className="cognito-federated-sign-out">
      <Button onClick={logOut} size="sm" variant="warning" className="mx-14 mt-10">
        Log out
      </Button>
    </div>
  );
};

export default CognitoFederatedSignOut;
