import * as React from 'react';
import { toastr, actions as toastrActions, LightToastrOptions } from 'react-redux-toastr';
import Spinner from '../../components/widgets/Spinner';

export const showLoadingToast = (toastId: string, title: string, message: string) =>
  toastrActions.add({
    title,
    message,
    id: toastId,
    type: 'light',
    options: {
      timeOut: 0,
      showCloseButton: true,
      icon: <div className="flex items-center justify-center h-full w-full"><Spinner size={30} /></div>,
    },
  });

export const showToast = (type: LightToastrOptions['status'], title: string, message: string, options: LightToastrOptions = {}) =>
  toastr.light(title, message, {
    icon: type,
    status: type,
    timeOut: type === 'error' ? 0 : undefined, // Persist errors on screen until closed.
    showCloseButton: type === 'error' ? true : false,
    ...options,
  });

export const showMessageToast = (title: string, message: string) =>
  toastrActions.add({
    title,
    message,
    type: 'confirm',
    position: 'top-center',
    options: {
      timeOut: 0,
      showCloseButton: true,
    },
  });

export const hideToast = (toastId: string) =>
  toastrActions.remove(toastId);
