import { AvailableTemplates } from '../../../../utilities/report/templates/getCompiledTemplateVariablesWithSectionOverrides';

export enum CustomisationActionTypes {
  CHANGE_TEMPLATE_SECTION_VARIABLES = '@@report/customisation/CHANGE_TEMPLATE_SECTION_VARIABLES',
  CHANGE_TEMPLATE_NAME = '@@report/customisation/CHANGE_TEMPLATE_NAME',
  RESET_SECTION = '@@report/customisation/RESET_SECTION',
  TOGGLE_VISIBILITY = '@@report/customisation/TOGGLE_VISIBILITY',
}

export type SectionOverride = {
  _hidden: boolean;
  [key: string]: any;
};

export type SectionOverrides = {[sectionId: string]: SectionOverride};
export type PageRepeaterOverrides = {[repeaterId: string]: SectionOverrides};

export type PageOverrides = {[pageId: string]: PageRepeaterOverrides};
export type TemplateOverrides = {[templateName: string]: PageOverrides};

export interface CustomisationState {
  templateName: AvailableTemplates;
  sectionVariableOverridesByTemplate: {[key: string]: PageOverrides};
}
