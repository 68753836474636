import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import LetterToDoctorView from './LetterToDoctorView';
import Route from '../../../base/Route';

const LetterToDoctor = () => (
  <div>
    <Route path={PATHS.REPORTS_LETTER_TO_DOCTOR} component={LetterToDoctorView} />
  </div>
)

export default LetterToDoctor;