import * as React from 'react';
import ChangeLabOrderNumber from '../../../graphql/containers/journeys/ChangeLabOrderNumber';
import SingleFieldForm from '../../widgets/form/SngleFieldForm';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';
import { trimLabOrderNumber } from '../../../../utilities/helpers';
import { labOrderNumberUrl } from '../../../../utilities/journeys/selectors';
import { FiChevronRight } from '../../widgets/Icon';

const JourneyInfoLabOrderNumber = () => {
  const { journey } = React.useContext(JourneyInfoContext);
  if (!journey) {
    return null;
  }

  return (
    <ChangeLabOrderNumber>
      {(mutate, isLoading) => (
        <div className="text-grey-darkest font-nm align-middle text-sm mt-15 flex items-start">
          <div className="mr-5 pt-3">
            Lab order number:
            {journey.labOrderNumber && (
              <div className="mt-10">
                <a target="_blank" href={labOrderNumberUrl(journey)} rel="noopener noreferrer" className="text-purple bg-grey-light p-3 px-5 text-xs border rounded hover:text-white hover:bg-purple">
                  Invitae <FiChevronRight />
                </a>
              </div>
            )}
          </div>
          <div>
            <SingleFieldForm
              placeholder="Enter number"
              isLoading={isLoading}
              onSubmit={value => mutate(journey.id, value)}
              defaultValue={journey.labOrderNumber}
              transformValue={trimLabOrderNumber}
              key={journey.id}
            />
          </div>

        </div>
      )}
    </ChangeLabOrderNumber>
  );
}

export default JourneyInfoLabOrderNumber;
