import { FieldType } from '../../../FieldType';

export type IntroVariables = {
  title?: string;
  optionsImage?: boolean;
  riskLevelColor: 'pink' | 'orange' | 'dk-green' | 'grey';
  riskLevelPrefix: string;
  riskLevel: string;
  riskLevelSuffix: string;
};

export const IntroVariableTypes: {[key in keyof IntroVariables]: FieldType} = {
  title: 'text',
  optionsImage: 'boolean',
  riskLevelColor: ['pink', 'orange', 'dk-green', 'grey'],
  riskLevelPrefix: 'text',
  riskLevel: 'text',
  riskLevelSuffix: 'text',
};
