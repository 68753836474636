import React from 'react';
import { Query, QueryListFlagsArgs, Mutation, MutationUpdateFlagCompleteArgs, FlagCode } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { appSyncClient } from '../../../../utilities/appSync';
import { useDispatch } from 'react-redux';
import Button from '../../../components/widgets/Button';

const LIST_FLAGS_QUERY = gql`
  query ListFlags($input: ListFlagsInput!) {
    listFlags(input: $input) {
      edges {
        cursor
        node {
          id
          flagType
          code
          priority
          linkData
          assignedTo {
            id
            counsellorDetails {
              id
              name
              nickname
            }
          }
          dateCreated
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const UPDATE_FLAG_COMPLETE_MUTATION = gql`
  mutation UpdateFlagComplete($input: UpdateFlagCompleteInput!) {
    updateFlagComplete(input: $input) {
      flag {
        id
      }
    }
  }
`;

interface FeedbackLinkData {
  email: string;
  text: string;
  rating: number;
  platform: string;
  data: {
    question: string;
    scale: 'yes/no';
    userAgent: string;
  };
}

const queryVariables: QueryListFlagsArgs = {
  input: {
    code: FlagCode.FeedbackReceived,
  }
};

interface FlagItem {
  id: string;
  text: string;
  rating: number;
  submitted: string;
  platform: string;
  email: string;
}

const FeedbackFlags = () => {
  const [items, setItems] = React.useState<FlagItem[]>([]);

  const { loading, data } = useQuery<Pick<Query, 'listFlags'>, QueryListFlagsArgs>(LIST_FLAGS_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  React.useEffect(() => {
    const flagItems = data?.listFlags?.edges?.map(edge => edge.node)
      .map(node => {
        const linkData: FeedbackLinkData = node.linkData ? JSON.parse(node.linkData) : null;

        return {
          id: node.id,
          text: linkData.text ?? '-',
          rating: linkData.rating ?? 0,
          submitted: node.dateCreated,
          platform: linkData.platform,
          email: linkData.email,
        };
      }) ?? [];
      setItems(flagItems);
  }, [data?.listFlags, setItems]);

  const dispatch = useDispatch();

  const [completeFlagsMutation] = useMutation<Pick<Mutation, 'updateFlagComplete'>, MutationUpdateFlagCompleteArgs>(UPDATE_FLAG_COMPLETE_MUTATION, {
    client: appSyncClient,
  });

  const onComplete = React.useCallback(async (flagId: string) => {
    const newItems = items.filter(item => item.id !== flagId);
    setItems(newItems);
    await completeFlagsMutation({
      variables: {
        input: {
          id: flagId,
        }
      }
    });
  }, [dispatch, setItems, items]);

  if (loading) {
    return null;
  }

  return (
    <div className="flex flex-wrap mt-20">
      {items.map(item => (
        <div key={item.id} className="p-5 w-1/3 relative">
          <div className="border p-10 rounded flex items-start">
            <div>
              {item.rating ? <span>👍</span> : <span>👎</span>}
            </div>
            <div className="flex-1 pl-10 text-sm">
              <p>{item.text || 'No feedback provided'}</p>
              <div className="text-xs text-grey-dark mt-5">{item.email ?? 'No email'} - {item.platform}</div>
            </div>
            <Button
              onClick={() => onComplete(item.id)}
              size="sm"
              variant="success"
              type="button"
            >
              Seen
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeedbackFlags;
