import * as React from 'react';
import moment from 'moment';
import { JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';
import JourneyTable from '../../../components/widgets/JourneyTable';
import { FiClock } from '../../../components/widgets/Icon';
import { Journey, JourneyLog, Member, QueryJourneyArgs, Request } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const JOURNEY_FRAGMENT = gql`
  fragment JourneyRowParts on Journey {
    id
    state
    hasRecollection
    request {
      id
      pregnancy {
        dueDate
        days
      }
    }
    member {
      id
      name
    }
    history {
      date
    }
  }
`;

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      ...JourneyRowParts
      relatedJourneys {
        ...JourneyRowParts
      }
    }
  }
  ${JOURNEY_FRAGMENT}
`;

interface JourneyRowData extends Pick<Journey, 'id'|'state'|'hasRecollection'> {
  member: Pick<Member, 'id'|'name'>;
  history?: Pick<JourneyLog, 'date'>[];
  request?: Pick<Request, 'id'|'pregnancy'>;
}

interface QueryResult {
  journey: JourneyRowData & {
    relatedJourneys: JourneyRowData[];
  };
}

const JourneyShowJourneyRows = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const journey = journeyQuery?.data?.journey;
  if (!journey) {
    return null;
  }

  const sortedJourneys = [journey, ...journey.relatedJourneys]
    .sort((lhs, rhs) => {
      if (lhs.id === journey.id) {
        return Number.MIN_SAFE_INTEGER;
      }

      if (rhs.id === journey.id) {
        return Number.MAX_SAFE_INTEGER;
      }

      return lhs.member?.name.localeCompare(rhs.member?.name);
    });
  
  const orderedRequestIds = [journey.request.id];

  return (
    <JourneyTable
      journeysByRequestId={{
        [journey.request.id]: sortedJourneys,
      }}
      alwaysShowActions
      orderedRequestIds={orderedRequestIds}
      isLoading={false}
      header={['State', <FiClock key="clock" />]}
      emptyText="No journeys found"
      cellsForJourney={(journey: JourneyRowData) => [
        JOURNEY_STATE_LABELS[journey.state],
        moment(journey.history?.[0]?.date).fromNow(true),
      ]}
    />
  );
};

export default JourneyShowJourneyRows;
