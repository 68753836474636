/* tslint:disable */

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Admin = {
  __typename?: 'Admin';
  cognitoId?: Maybe<Scalars['ID']>;
  counsellorDetails?: Maybe<Counsellor>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type AppointmentPrediction = {
  __typename?: 'AppointmentPrediction';
  datePredicted?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type AppointmentPredictionResult = {
  __typename?: 'AppointmentPredictionResult';
  postTestAppointments: Array<AppointmentPrediction>;
  preTestAppointments: Array<AppointmentPrediction>;
};

export type Cms = {
  __typename?: 'CMS';
  entryId?: Maybe<Scalars['String']>;
  spaceId?: Maybe<Scalars['String']>;
};

export type CarrierStatistics = {
  __typename?: 'CarrierStatistics';
  carrierRateDesc?: Maybe<Scalars['String']>;
  carrierRateRaw?: Maybe<Scalars['Float']>;
  ethnicity?: Maybe<Ethnicity>;
  gene?: Maybe<Scalars['String']>;
  residualRiskDesc?: Maybe<Scalars['String']>;
  residualRiskRaw?: Maybe<Scalars['Float']>;
};

export type Clinic = {
  __typename?: 'Clinic';
  addressCountry?: Maybe<Scalars['String']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressSuburb?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicPhone?: Maybe<Scalars['String']>;
  doctors?: Maybe<Array<Maybe<Doctor>>>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicEdge = {
  __typename?: 'ClinicEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Clinic>;
};

export type ClinicsConnection = {
  __typename?: 'ClinicsConnection';
  edges?: Maybe<Array<Maybe<ClinicEdge>>>;
  pageInfo: PageInfo;
};

export type CloseConsultInput = {
  consultId: Scalars['ID'];
  result?: Maybe<ConsultState>;
};

export type CompleteFamilyHistoryResult = {
  __typename?: 'CompleteFamilyHistoryResult';
  familyHistoryGroup?: Maybe<FamilyHistoryGroup>;
};

export type Consent = {
  __typename?: 'Consent';
  createdBy?: Maybe<Admin>;
  data?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateWithdrawn?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journey: Journey;
  name?: Maybe<Scalars['String']>;
  product: Product;
  source: ConsentSource;
  withdrawalNotes?: Maybe<Scalars['String']>;
  withdrawnBy?: Maybe<Admin>;
};

export enum ConsentSource {
  MemberFlow = 'MEMBER_FLOW',
  Pdf = 'PDF',
  Web = 'WEB'
}

export type ConsentSubmissionInput = {
  data?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product: Product;
};

export type ConsentSubmissionResult = {
  __typename?: 'ConsentSubmissionResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type Consult = {
  __typename?: 'Consult';
  counsellors?: Maybe<Array<Maybe<Counsellor>>>;
  id: Scalars['ID'];
  journeys?: Maybe<Array<Maybe<Journey>>>;
  members?: Maybe<Array<Maybe<Member>>>;
  scheduledDateTime?: Maybe<Scalars['String']>;
  state?: Maybe<ConsultState>;
  timeEnded?: Maybe<Scalars['String']>;
  timeStarted?: Maybe<Scalars['String']>;
};

export enum ConsultState {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Future = 'FUTURE',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED'
}

export type Counsellor = {
  __typename?: 'Counsellor';
  admin?: Maybe<Admin>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type CreateClinicInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressSuburb?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicPhone?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateClinicResult = {
  __typename?: 'CreateClinicResult';
  clinic?: Maybe<Clinic>;
};

export type CreateConsentInput = {
  journeyId: Scalars['ID'];
};

export type CreateConsentResult = {
  __typename?: 'CreateConsentResult';
  consent?: Maybe<Consent>;
};

export type CreateCounsellorInput = {
  adminId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type CreateCounsellorResult = {
  __typename?: 'CreateCounsellorResult';
  counsellor?: Maybe<Counsellor>;
};

export type CreateDoctorClinicInput = {
  clinicId: Scalars['ID'];
  doctorId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateDoctorClinicResult = {
  __typename?: 'CreateDoctorClinicResult';
  doctorClinic?: Maybe<DoctorClinic>;
};

export type CreateDoctorInput = {
  gender?: Maybe<Sex>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  nameMiddle?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  personalMobile?: Maybe<Scalars['String']>;
  personalPhone?: Maybe<Scalars['String']>;
  practitionerId?: Maybe<Scalars['String']>;
  providerNumber?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  specialties?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CreateDoctorResult = {
  __typename?: 'CreateDoctorResult';
  doctor?: Maybe<Doctor>;
};

export type CreateFamilyHistoryGroupItemInput = {
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  groupId?: Maybe<Scalars['ID']>;
  memberId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  value?: Maybe<Scalars['String']>;
};

export type CreateFamilyHistoryGroupItemResult = {
  __typename?: 'CreateFamilyHistoryGroupItemResult';
  familyHistoryGroupItem?: Maybe<FamilyHistoryGroupItem>;
};

export type CreateFamilyHistoryItemInput = {
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  memberId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  value?: Maybe<Scalars['String']>;
};

export type CreateFamilyHistoryItemResult = {
  __typename?: 'CreateFamilyHistoryItemResult';
  familyHistoryItem?: Maybe<FamilyHistoryItem>;
};

export type CreateFileMetaInput = {
  base64Md5Hash?: Maybe<Scalars['String']>;
  category: FileCategory;
  description?: Maybe<Scalars['String']>;
  journeyId?: Maybe<Scalars['ID']>;
  requestId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type CreateFileMetaResult = {
  __typename?: 'CreateFileMetaResult';
  uploadURL?: Maybe<Scalars['String']>;
};

export type CreateFlagInput = {
  assignedTo?: Maybe<Scalars['ID']>;
  code: FlagCode;
  flagType: FlagType;
  journeyId?: Maybe<Scalars['ID']>;
  linkData?: Maybe<Scalars['String']>;
  priority: FlagPriority;
};

export type CreateFlagResult = {
  __typename?: 'CreateFlagResult';
  flag?: Maybe<Flag>;
};

export type CreateFollowUpInput = {
  dateFollowUp?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['ID']>;
  journeyId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type CreateFollowUpResult = {
  __typename?: 'CreateFollowUpResult';
  followUp?: Maybe<FollowUp>;
};

export type CreateFormSubmissionInput = {
  data?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
};

export type CreateFormSubmissionResult = {
  __typename?: 'CreateFormSubmissionResult';
  formSubmission: FormSubmission;
};

export type CreateJourneyInput = {
  memberId?: Maybe<Scalars['ID']>;
  requestId: Scalars['ID'];
  state?: Maybe<JourneyState>;
};

export type CreateJourneyResult = {
  __typename?: 'CreateJourneyResult';
  journey?: Maybe<Journey>;
};

export type CreateKitInput = {
  journeyId: Scalars['ID'];
  registrationNumber?: Maybe<Scalars['String']>;
};

export type CreateKitResult = {
  __typename?: 'CreateKitResult';
  kit?: Maybe<Kit>;
};

export type CreateLeadInput = {
  additionalData?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  product: Product;
  source?: Maybe<Scalars['String']>;
};

export type CreateLeadNoteInput = {
  isImportant?: Maybe<Scalars['Boolean']>;
  leadId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type CreateLeadNoteResult = {
  __typename?: 'CreateLeadNoteResult';
  note?: Maybe<Note>;
};

export type CreateLeadResult = {
  __typename?: 'CreateLeadResult';
  lead: Lead;
};

export type CreateMemberInput = {
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  onlineAccessEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
};

export type CreateMemberResult = {
  __typename?: 'CreateMemberResult';
  member?: Maybe<Member>;
};

export type CreateNotificationInput = {
  content?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  entity: NotificationEntity;
  id: Scalars['ID'];
  method: MessageMethod;
  subject?: Maybe<Scalars['String']>;
  type: MessageType;
};

export type CreateNotificationResult = {
  __typename?: 'CreateNotificationResult';
  notification?: Maybe<Notification>;
};

export type CreateReferralInput = {
  doctorId: Scalars['ID'];
  journeyId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type CreateReferralResult = {
  __typename?: 'CreateReferralResult';
  referral?: Maybe<Referral>;
};

export type CreateReportInput = {
  data?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type CreateReportResult = {
  __typename?: 'CreateReportResult';
  report?: Maybe<Report>;
};

export type CreateRequestInput = {
  datePaid?: Maybe<Scalars['String']>;
  drNameOnRequestForm?: Maybe<Scalars['String']>;
  initiatingMemberId: Scalars['ID'];
  numGenesTestedRecessive?: Maybe<Scalars['Int']>;
  numGenesTestedXLinked?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderInput>;
  partnerId?: Maybe<Scalars['ID']>;
  pregnancy?: Maybe<PregnancyInput>;
  primaryCounsellorId?: Maybe<Scalars['ID']>;
  product: Product;
  productVariant?: Maybe<ProductVariant>;
  reason?: Maybe<Scalars['String']>;
  referringDoctorClinicId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

export type CreateRequestNoteInput = {
  isImportant?: Maybe<Scalars['Boolean']>;
  requestId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type CreateRequestNoteResult = {
  __typename?: 'CreateRequestNoteResult';
  request?: Maybe<Request>;
};

export type CreateRequestResult = {
  __typename?: 'CreateRequestResult';
  request?: Maybe<Request>;
};

export type DateRange = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type DeleteFile = {
  id: Scalars['ID'];
};

export type Disease = {
  __typename?: 'Disease';
  affectedCoupleActions?: Maybe<Scalars['String']>;
  affectedCoupleInfo?: Maybe<Scalars['String']>;
  affectedCoupleOptions?: Maybe<Scalars['String']>;
  carrierInfo?: Maybe<Scalars['String']>;
  cms?: Maybe<Cms>;
  description?: Maybe<Scalars['String']>;
  genes: Array<Maybe<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  individualCarrierInfoForCouple?: Maybe<Scalars['String']>;
  individualCarrierNextSteps?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resources?: Maybe<Scalars['String']>;
};

export type Doctor = {
  __typename?: 'Doctor';
  clinics?: Maybe<Array<Maybe<Clinic>>>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  nameMiddle?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  personalMobile?: Maybe<Scalars['String']>;
  personalPhone?: Maybe<Scalars['String']>;
  practitionerId?: Maybe<Scalars['String']>;
  providerNumber?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  specialties?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DoctorClinic = {
  __typename?: 'DoctorClinic';
  clinic?: Maybe<Clinic>;
  doctor?: Maybe<Doctor>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  requests?: Maybe<Array<Maybe<Request>>>;
  stats?: Maybe<Array<Maybe<DoctorStats>>>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type DoctorClinicEdge = {
  __typename?: 'DoctorClinicEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<DoctorClinic>;
};

export type DoctorClinicPairsConnection = {
  __typename?: 'DoctorClinicPairsConnection';
  edges?: Maybe<Array<Maybe<DoctorClinicEdge>>>;
  pageInfo: PageInfo;
};

export enum DoctorConnectionType {
  OtherHealthcareProvider = 'OTHER_HEALTHCARE_PROVIDER',
  ReferredByDoctor = 'REFERRED_BY_DOCTOR'
}

export type DoctorEdge = {
  __typename?: 'DoctorEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Doctor>;
};

export type DoctorStats = {
  __typename?: 'DoctorStats';
  dateFirstConversion?: Maybe<Scalars['String']>;
  dateFirstReport?: Maybe<Scalars['String']>;
  dateLastConversion?: Maybe<Scalars['String']>;
  n15DayConversions?: Maybe<Scalars['Int']>;
  n30DayConversions?: Maybe<Scalars['Int']>;
  n60DayConversions?: Maybe<Scalars['Int']>;
  n120DayConversions?: Maybe<Scalars['Int']>;
  n365DayConversions?: Maybe<Scalars['Int']>;
  nLifetimeConversions?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
};

export type DoctorsConnection = {
  __typename?: 'DoctorsConnection';
  edges?: Maybe<Array<Maybe<DoctorEdge>>>;
  pageInfo: PageInfo;
};

export type Ethnicity = {
  __typename?: 'Ethnicity';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum FamilyHistoryCategory {
  Children = 'CHILDREN',
  Extended = 'EXTENDED',
  Me = 'ME',
  Niblings = 'NIBLINGS',
  Parents = 'PARENTS',
  Pregnancy = 'PREGNANCY',
  Siblings = 'SIBLINGS'
}

export enum FamilyHistoryClassification {
  Alert = 'ALERT',
  Extraneous = 'EXTRANEOUS',
  Highlight = 'HIGHLIGHT',
  Info = 'INFO'
}

export type FamilyHistoryGroup = {
  __typename?: 'FamilyHistoryGroup';
  dateCompleted?: Maybe<Scalars['String']>;
  dateStarted?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  product: Product;
};

export type FamilyHistoryGroupItem = {
  __typename?: 'FamilyHistoryGroupItem';
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  source: FamilyHistorySource;
  submissionData?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FamilyHistoryItem = {
  __typename?: 'FamilyHistoryItem';
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  source: FamilyHistorySource;
  submissionData?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum FamilyHistoryKeyField {
  Badger = 'BADGER',
  Hippo = 'HIPPO',
  Hog = 'HOG',
  Jackal = 'JACKAL',
  Koala = 'KOALA',
  Leopard = 'LEOPARD'
}

export enum FamilyHistorySource {
  April = 'APRIL',
  Genie = 'GENIE',
  MemberFlow = 'MEMBER_FLOW'
}

export type FamilyHistorySubmissionInput = {
  email?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<FamilyHistorySubmissionRow>>>;
};

export type FamilyHistorySubmissionResult = {
  __typename?: 'FamilyHistorySubmissionResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type FamilyHistorySubmissionRow = {
  category?: Maybe<FamilyHistoryCategory>;
  data?: Maybe<Scalars['String']>;
  keyField?: Maybe<FamilyHistoryKeyField>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  value?: Maybe<Scalars['String']>;
};

export enum FileCategory {
  EugeneReport = 'EUGENE_REPORT',
  LabReport = 'LAB_REPORT',
  Other = 'OTHER'
}

export type FileMeta = {
  __typename?: 'FileMeta';
  URL?: Maybe<Scalars['String']>;
  base64Md5Hash?: Maybe<Scalars['String']>;
  category: FileCategory;
  createdBy?: Maybe<Admin>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  filePermission: FilePermission;
  id: Scalars['ID'];
  status: FileStatus;
  title?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Admin>;
};

export enum FilePermission {
  Internal = 'INTERNAL',
  Journey = 'JOURNEY',
  Request = 'REQUEST'
}

export enum FileStatus {
  AwaitingUpload = 'AWAITING_UPLOAD',
  Complete = 'COMPLETE'
}

export type Flag = {
  __typename?: 'Flag';
  assignedTo?: Maybe<Admin>;
  code: FlagCode;
  completedBy?: Maybe<Admin>;
  dateCompleted?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateSnoozeUntil?: Maybe<Scalars['String']>;
  flagType: FlagType;
  id: Scalars['ID'];
  journey?: Maybe<Journey>;
  linkData?: Maybe<Scalars['String']>;
  priority: FlagPriority;
  snoozedBy?: Maybe<Admin>;
};

export enum FlagCode {
  FeedbackReceived = 'FEEDBACK_RECEIVED',
  LateResults_1 = 'LATE_RESULTS_1',
  LateResults_2 = 'LATE_RESULTS_2',
  NotReturnedToEugene = 'NOT_RETURNED_TO_EUGENE',
  NotSentToMember = 'NOT_SENT_TO_MEMBER',
  NotShippedToLab = 'NOT_SHIPPED_TO_LAB',
  ReadyForReport = 'READY_FOR_REPORT',
  ReadyForReportReview = 'READY_FOR_REPORT_REVIEW',
  ReadyToReleaseResults = 'READY_TO_RELEASE_RESULTS',
  RequireDetails = 'REQUIRE_DETAILS',
  RequirePretestBooking = 'REQUIRE_PRETEST_BOOKING',
  RequireResultBooking = 'REQUIRE_RESULT_BOOKING',
  SampleFailed = 'SAMPLE_FAILED',
  UnmatchedConsent = 'UNMATCHED_CONSENT',
  UnmatchedFamilyHistory = 'UNMATCHED_FAMILY_HISTORY'
}

export type FlagEdge = {
  __typename?: 'FlagEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Flag>;
};

export enum FlagPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Urgent = 'URGENT'
}

export enum FlagType {
  AprilError = 'APRIL_ERROR',
  AwaitingInput = 'AWAITING_INPUT',
  FollowUp = 'FOLLOW_UP',
  NeedsAttention = 'NEEDS_ATTENTION',
  OutOfOurControl = 'OUT_OF_OUR_CONTROL'
}

export type FlagsConnection = {
  __typename?: 'FlagsConnection';
  edges?: Maybe<Array<Maybe<FlagEdge>>>;
  pageInfo: PageInfo;
};

export type FollowUp = {
  __typename?: 'FollowUp';
  completedBy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  dateCompleted?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateFollowUp?: Maybe<Scalars['String']>;
  doctorClinic?: Maybe<DoctorClinic>;
  id: Scalars['ID'];
  journey: Journey;
  outcome?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type FormSubmission = {
  __typename?: 'FormSubmission';
  data?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  processed: FormSubmissionProcessed;
};

export type FormSubmissionEdge = {
  __typename?: 'FormSubmissionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FormSubmission>;
};

export enum FormSubmissionProcessed {
  Done = 'DONE',
  Error = 'ERROR',
  Waiting = 'WAITING'
}

export type FormSubmissionsConnection = {
  __typename?: 'FormSubmissionsConnection';
  edges?: Maybe<Array<Maybe<FormSubmissionEdge>>>;
  pageInfo: PageInfo;
};

export type GeneList = {
  __typename?: 'GeneList';
  genes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum IncludeRelatedKitsFlag {
  All = 'ALL',
  InScope = 'IN_SCOPE',
  None = 'NONE'
}

export type InvitePartnerInput = {
  memberId: Scalars['ID'];
  requestId: Scalars['ID'];
};

export type InvitePartnerResult = {
  __typename?: 'InvitePartnerResult';
  journey?: Maybe<Journey>;
};

export type Journey = {
  __typename?: 'Journey';
  addressAddress1?: Maybe<Scalars['String']>;
  addressAddress2?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountryCode?: Maybe<Scalars['String']>;
  addressProvince?: Maybe<Scalars['String']>;
  addressString?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['String']>;
  consents?: Maybe<Array<Maybe<Consent>>>;
  consults?: Maybe<Array<Maybe<Consult>>>;
  dateAsknicelyEnrolled?: Maybe<Scalars['String']>;
  dateReviewed?: Maybe<Scalars['String']>;
  followUps?: Maybe<Array<Maybe<FollowUp>>>;
  hasConsent?: Maybe<Scalars['Boolean']>;
  hasRecollection?: Maybe<Scalars['Boolean']>;
  hasRelevantFamilyHistoryGroupSubmission?: Maybe<Scalars['Boolean']>;
  hasRelevantFamilyHistorySubmission?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<JourneyLog>>>;
  id: Scalars['ID'];
  kits?: Maybe<Array<Maybe<Kit>>>;
  labOrderNumber?: Maybe<Scalars['String']>;
  lastStateChangeDate?: Maybe<Scalars['String']>;
  latestPreTestSelfAssessmentDate?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  partnerCanReceiveResults?: Maybe<Scalars['Boolean']>;
  preTestSelfAssessment?: Maybe<PreTestSelfAssessment>;
  recommendations?: Maybe<Array<Maybe<Recommendation>>>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  relatedJourneys?: Maybe<Array<Maybe<Journey>>>;
  request?: Maybe<Request>;
  startDate?: Maybe<Scalars['String']>;
  state: JourneyState;
};

export type JourneyEdge = {
  __typename?: 'JourneyEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Journey;
};

export enum JourneyListOrderBy {
  PreTestSelfAssessmentDate = 'PRE_TEST_SELF_ASSESSMENT_DATE',
  ReviewDateAsc = 'REVIEW_DATE_ASC',
  Standard = 'STANDARD'
}

export type JourneyLog = {
  __typename?: 'JourneyLog';
  actioningMember?: Maybe<Member>;
  data?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  fromState?: Maybe<JourneyState>;
  notes?: Maybe<Scalars['String']>;
  toState?: Maybe<JourneyState>;
  username?: Maybe<Scalars['String']>;
};

export enum JourneyState {
  CloseLost = 'CLOSE_LOST',
  Complete = 'COMPLETE',
  ReadyForReport = 'READY_FOR_REPORT',
  ReadyForReportReview = 'READY_FOR_REPORT_REVIEW',
  ReadyToArchive = 'READY_TO_ARCHIVE',
  ReadyToReleaseResults = 'READY_TO_RELEASE_RESULTS',
  RequireDetails = 'REQUIRE_DETAILS',
  RequirePretestBooking = 'REQUIRE_PRETEST_BOOKING',
  RequireResultBooking = 'REQUIRE_RESULT_BOOKING',
  Trash = 'TRASH',
  WaitingForLabResults = 'WAITING_FOR_LAB_RESULTS',
  WaitingForPartnerResults = 'WAITING_FOR_PARTNER_RESULTS',
  WaitingForPretestConsult = 'WAITING_FOR_PRETEST_CONSULT',
  WaitingForResultConsult = 'WAITING_FOR_RESULT_CONSULT'
}

export type JourneysConnection = {
  __typename?: 'JourneysConnection';
  edges?: Maybe<Array<Maybe<JourneyEdge>>>;
  pageInfo: PageInfo;
};

export type Kit = {
  __typename?: 'Kit';
  dateArrivedAtEugene?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateShippedToLab?: Maybe<Scalars['String']>;
  dateShippedToMember?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journey?: Maybe<Journey>;
  memberRegisteredKitOnDate?: Maybe<Scalars['String']>;
  recollectionRequestedDate?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
};

export type KitEdge = {
  __typename?: 'KitEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Kit;
};

export type KitRecollectionInput = {
  kitId: Scalars['ID'];
};

export type KitRecollectionResult = {
  __typename?: 'KitRecollectionResult';
  kit?: Maybe<Kit>;
};

export type KitRegistrationInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
};

export type KitRegistrationResult = {
  __typename?: 'KitRegistrationResult';
  error?: Maybe<Scalars['String']>;
  kit?: Maybe<Kit>;
};

export enum KitScope {
  ReadyForLabShipping = 'READY_FOR_LAB_SHIPPING',
  ReadyForMemberShipping = 'READY_FOR_MEMBER_SHIPPING',
  Recollected = 'RECOLLECTED',
  RequireApproval = 'REQUIRE_APPROVAL',
  RequireConsent = 'REQUIRE_CONSENT',
  RequireLabOrder = 'REQUIRE_LAB_ORDER',
  ShippedToLab = 'SHIPPED_TO_LAB',
  WaitingForReturnShipping = 'WAITING_FOR_RETURN_SHIPPING'
}

export type KitsConnection = {
  __typename?: 'KitsConnection';
  edges?: Maybe<Array<Maybe<KitEdge>>>;
  pageInfo: PageInfo;
  totalRows: Scalars['Int'];
};

export type Lead = {
  __typename?: 'Lead';
  additionalData?: Maybe<Scalars['String']>;
  closedReason?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateLastContact?: Maybe<Scalars['String']>;
  doNotContact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  phone?: Maybe<Scalars['String']>;
  product: Product;
  source?: Maybe<Scalars['String']>;
  status: LeadStatus;
};


export type LeadNotesArgs = {
  importantOnly?: Maybe<Scalars['Boolean']>;
};

export type LeadEdge = {
  __typename?: 'LeadEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Lead>;
};

export enum LeadStatus {
  ClosedLost = 'CLOSED_LOST',
  ClosedSaleExpected = 'CLOSED_SALE_EXPECTED',
  ClosedWon = 'CLOSED_WON',
  FollowUp = 'FOLLOW_UP'
}

export type LeadsConnection = {
  __typename?: 'LeadsConnection';
  edges?: Maybe<Array<Maybe<LeadEdge>>>;
  pageInfo: PageInfo;
};

export type ListAdminsInput = {
  currentAdminId?: Maybe<Scalars['ID']>;
  hasPublicDetails?: Maybe<Scalars['Boolean']>;
};

export type ListClinicsInput = {
  addressSearch?: Maybe<Scalars['String']>;
  clinicNameSearch?: Maybe<Scalars['String']>;
  emailSearch?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  phoneNumberSearch?: Maybe<Scalars['String']>;
};

export type ListDoctorClinicPairsInput = {
  addressSearch?: Maybe<Scalars['String']>;
  clinicNameSearch?: Maybe<Scalars['String']>;
  emailSearch?: Maybe<Scalars['String']>;
  nameSearch?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  phoneNumberSearch?: Maybe<Scalars['String']>;
  providerNumber?: Maybe<Scalars['String']>;
  specialitySearch?: Maybe<Scalars['String']>;
};

export type ListDoctorsInput = {
  addressSearch?: Maybe<Scalars['String']>;
  clinicNameSearch?: Maybe<Scalars['String']>;
  emailSearch?: Maybe<Scalars['String']>;
  nameSearch?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  phoneNumberSearch?: Maybe<Scalars['String']>;
  providerNumber?: Maybe<Scalars['String']>;
  specialitySearch?: Maybe<Scalars['String']>;
};

export type ListFlagsInput = {
  assignedTo?: Maybe<Scalars['ID']>;
  code?: Maybe<FlagCode>;
  flagType?: Maybe<FlagType>;
  includeCompleted?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<Pagination>;
  priority?: Maybe<FlagPriority>;
};

export type ListFormSubmissionsInput = {
  formId?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  processed?: Maybe<FormSubmissionProcessed>;
};

export type ListJourneysInput = {
  dateReviewedOlderThanMinutes?: Maybe<Scalars['Int']>;
  excludeCompleted?: Maybe<Scalars['Boolean']>;
  excludeOnHold?: Maybe<Scalars['Boolean']>;
  hasPreTestSelfAssessment?: Maybe<Scalars['Boolean']>;
  hasRecollection?: Maybe<Scalars['Boolean']>;
  labOrderNumber?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<JourneyListOrderBy>;
  pagination?: Maybe<Pagination>;
  paid?: Maybe<Scalars['Boolean']>;
  preTestSelfAssessment?: Maybe<PreTestSelfAssessment>;
  preTestSelfAssessmentDateRange?: Maybe<DateRange>;
  preTestSelfAssessmentForReview?: Maybe<Scalars['Boolean']>;
  state?: Maybe<JourneyState>;
};

export type ListKitsInput = {
  includeRelatedKits?: Maybe<IncludeRelatedKitsFlag>;
  isOnRecollectedJourney?: Maybe<Scalars['Boolean']>;
  memberSearch?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  product?: Maybe<Product>;
  productVariant?: Maybe<ProductVariant>;
  registrationNumberSearch?: Maybe<Scalars['String']>;
  scope?: Maybe<KitScope>;
};

export type ListLeadsInput = {
  dateCreatedRange?: Maybe<DateRange>;
  dateLastContactRange?: Maybe<DateRange>;
  pagination?: Maybe<Pagination>;
  product?: Maybe<Product>;
  status?: Maybe<LeadStatus>;
};

export type ListRecommendationsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  fromDate?: Maybe<Scalars['String']>;
  pagination?: Maybe<Pagination>;
  result?: Maybe<RecommendationResult>;
};

export type ListReportsInput = {
  gene?: Maybe<Scalars['String']>;
  hasAffectedGenes?: Maybe<Scalars['Boolean']>;
  hasPsuedoGenes?: Maybe<Scalars['Boolean']>;
  isHighRisk?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<Pagination>;
  primaryCounsellorId?: Maybe<Scalars['ID']>;
};

export type ListRequestsInput = {
  excludeOnHold?: Maybe<Scalars['Boolean']>;
  extractSubmitted?: Maybe<Scalars['Boolean']>;
  hasReport?: Maybe<Scalars['Boolean']>;
  isGcApproved?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<Pagination>;
  paid?: Maybe<Scalars['Boolean']>;
  pregnant?: Maybe<Scalars['Boolean']>;
  primaryCounsellorId?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
  referringClinicId?: Maybe<Scalars['ID']>;
  referringDoctorClinicId?: Maybe<Scalars['ID']>;
  referringDoctorId?: Maybe<Scalars['ID']>;
  riskStatus?: Maybe<RiskStatus>;
};

export type Member = {
  __typename?: 'Member';
  dateDetailsConfirmedByMember?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyHistory?: Maybe<Array<Maybe<FamilyHistoryItem>>>;
  familyHistoryGroup?: Maybe<Array<Maybe<FamilyHistoryGroupItem>>>;
  hasFamilyHistory?: Maybe<Scalars['Boolean']>;
  hasFamilyHistoryGroup?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  journeys?: Maybe<Array<Maybe<Journey>>>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  okToSendNewsletter?: Maybe<Scalars['Boolean']>;
  onlineAccessEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  webSession?: Maybe<Scalars['String']>;
};

export enum MessageMethod {
  Call = 'CALL',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum MessageType {
  Consent = 'CONSENT',
  CustomExternalMessage = 'CUSTOM_EXTERNAL_MESSAGE',
  FamilyHistory_1 = 'FAMILY_HISTORY_1',
  FamilyHistory_2 = 'FAMILY_HISTORY_2',
  KitSentToLab = 'KIT_SENT_TO_LAB',
  LabOrderCreated = 'LAB_ORDER_CREATED',
  MemberFlowIncompleteReminder_1 = 'MEMBER_FLOW_INCOMPLETE_REMINDER_1',
  MemberFlowIncompleteReminder_2 = 'MEMBER_FLOW_INCOMPLETE_REMINDER_2',
  PartnerInvite_1 = 'PARTNER_INVITE_1',
  ReportReadyForMember = 'REPORT_READY_FOR_MEMBER'
}

export type Mutation = {
  __typename?: 'Mutation';
  closeConsult?: Maybe<StartConultResult>;
  completeFamilyHistory?: Maybe<CompleteFamilyHistoryResult>;
  consentSubmission?: Maybe<ConsentSubmissionResult>;
  createClinic?: Maybe<CreateClinicResult>;
  createConsent?: Maybe<CreateConsentResult>;
  createCounsellor?: Maybe<CreateCounsellorResult>;
  createDoctor?: Maybe<CreateDoctorResult>;
  createDoctorClinic?: Maybe<CreateDoctorClinicResult>;
  createFamilyHistoryGroupItem?: Maybe<CreateFamilyHistoryGroupItemResult>;
  createFamilyHistoryItem?: Maybe<CreateFamilyHistoryItemResult>;
  createFileMeta?: Maybe<CreateFileMetaResult>;
  createFlag?: Maybe<CreateFlagResult>;
  createFollowUp?: Maybe<CreateFollowUpResult>;
  createFormSubmission?: Maybe<CreateFormSubmissionResult>;
  createJourney?: Maybe<CreateJourneyResult>;
  createKit?: Maybe<CreateKitResult>;
  createLead?: Maybe<CreateLeadResult>;
  createLeadNote?: Maybe<CreateLeadNoteResult>;
  createMember?: Maybe<CreateMemberResult>;
  createNotification?: Maybe<CreateNotificationResult>;
  createReferral?: Maybe<CreateReferralResult>;
  createReport?: Maybe<CreateReportResult>;
  createRequest?: Maybe<CreateRequestResult>;
  createRequestNote?: Maybe<CreateRequestNoteResult>;
  familyHistorySubmission?: Maybe<FamilyHistorySubmissionResult>;
  invitePartner?: Maybe<InvitePartnerResult>;
  kitRecollection?: Maybe<KitRecollectionResult>;
  kitRegistration?: Maybe<KitRegistrationResult>;
  reassignConsent?: Maybe<ReassignConsentResult>;
  reassignFamilyHistorySubmission?: Maybe<Member>;
  /** @deprecated Please use create/update report instead */
  saveReport?: Maybe<SaveReportResult>;
  setResearchExtractSubmitted?: Maybe<SetResearchExtractSubmittedResult>;
  startConsult?: Maybe<StartCallResult>;
  updateClinic?: Maybe<UpdateClinicResult>;
  updateCounsellor?: Maybe<UpdateCounsellorResult>;
  updateDoctor?: Maybe<UpdateDoctorResult>;
  updateDoctorClinic?: Maybe<UpdateDoctorClinicResult>;
  updateFamilyHistoryGroupItem?: Maybe<UpdateFamilyHistoryGroupItemResult>;
  updateFamilyHistoryItem?: Maybe<UpdateFamilyHistoryItemResult>;
  updateFlagComplete?: Maybe<UpdateFlagCompleteResult>;
  updateFlagSnooze?: Maybe<UpdateFlagSnoozeResult>;
  updateFollowUp?: Maybe<UpdateFollowUpResult>;
  updateFollowUpComplete?: Maybe<UpdateFollowUpCompleteResult>;
  updateFormSubmissionProcessed?: Maybe<UpdateFormSubmissionProcessedResult>;
  updateJourneyParams?: Maybe<UpdateJourneyParamsResult>;
  updateJourneyReviewDate?: Maybe<Journey>;
  updateJourneyState?: Maybe<UpdateJourneyStateResult>;
  updateKit?: Maybe<UpdateKitResult>;
  updateLead?: Maybe<UpdateLeadResult>;
  updateMember?: Maybe<UpdateMemberResult>;
  updateRecommendation?: Maybe<UpdateRecommendationResult>;
  updateReferral?: Maybe<UpdateReferralResult>;
  updateReport?: Maybe<UpdateReportResult>;
  updateRequest?: Maybe<UpdateRequestResult>;
  upsertMember?: Maybe<UpsertMemberResult>;
  upsertResearchExtract?: Maybe<UpsertResearchExtractResult>;
  withdrawConsent?: Maybe<WithdrawConsentResult>;
};


export type MutationCloseConsultArgs = {
  input: CloseConsultInput;
};


export type MutationCompleteFamilyHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationConsentSubmissionArgs = {
  input: ConsentSubmissionInput;
};


export type MutationCreateClinicArgs = {
  input: CreateClinicInput;
};


export type MutationCreateConsentArgs = {
  input: CreateConsentInput;
};


export type MutationCreateCounsellorArgs = {
  input: CreateCounsellorInput;
};


export type MutationCreateDoctorArgs = {
  input: CreateDoctorInput;
};


export type MutationCreateDoctorClinicArgs = {
  input: CreateDoctorClinicInput;
};


export type MutationCreateFamilyHistoryGroupItemArgs = {
  input: CreateFamilyHistoryGroupItemInput;
};


export type MutationCreateFamilyHistoryItemArgs = {
  input: CreateFamilyHistoryItemInput;
};


export type MutationCreateFileMetaArgs = {
  input: CreateFileMetaInput;
};


export type MutationCreateFlagArgs = {
  input: CreateFlagInput;
};


export type MutationCreateFollowUpArgs = {
  input: CreateFollowUpInput;
};


export type MutationCreateFormSubmissionArgs = {
  input: CreateFormSubmissionInput;
};


export type MutationCreateJourneyArgs = {
  input: CreateJourneyInput;
};


export type MutationCreateKitArgs = {
  input: CreateKitInput;
};


export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};


export type MutationCreateLeadNoteArgs = {
  input: CreateLeadNoteInput;
};


export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationCreateRequestNoteArgs = {
  input: CreateRequestNoteInput;
};


export type MutationFamilyHistorySubmissionArgs = {
  input: FamilyHistorySubmissionInput;
};


export type MutationInvitePartnerArgs = {
  input: InvitePartnerInput;
};


export type MutationKitRecollectionArgs = {
  input: KitRecollectionInput;
};


export type MutationKitRegistrationArgs = {
  input: KitRegistrationInput;
};


export type MutationReassignConsentArgs = {
  input: ReassignConsentInput;
};


export type MutationReassignFamilyHistorySubmissionArgs = {
  memberId: Scalars['ID'];
  submissionId: Scalars['ID'];
};


export type MutationSaveReportArgs = {
  input: SaveReportInput;
};


export type MutationSetResearchExtractSubmittedArgs = {
  input: SetResearchExtractSubmittedInput;
};


export type MutationStartConsultArgs = {
  input: StartConsultInput;
};


export type MutationUpdateClinicArgs = {
  input: UpdateClinicInput;
};


export type MutationUpdateCounsellorArgs = {
  input: UpdateCounsellorInput;
};


export type MutationUpdateDoctorArgs = {
  input: UpdateDoctorInput;
};


export type MutationUpdateDoctorClinicArgs = {
  input: UpdateDoctorClinicInput;
};


export type MutationUpdateFamilyHistoryGroupItemArgs = {
  input: UpdateFamilyHistoryGroupItemInput;
};


export type MutationUpdateFamilyHistoryItemArgs = {
  input: UpdateFamilyHistoryItemInput;
};


export type MutationUpdateFlagCompleteArgs = {
  input: UpdateFlagCompleteInput;
};


export type MutationUpdateFlagSnoozeArgs = {
  input: UpdateFlagSnoozeInput;
};


export type MutationUpdateFollowUpArgs = {
  input: UpdateFollowUpInput;
};


export type MutationUpdateFollowUpCompleteArgs = {
  input: UpdateFollowUpCompleteInput;
};


export type MutationUpdateFormSubmissionProcessedArgs = {
  input: UpdateFormSubmissionProcessedInput;
};


export type MutationUpdateJourneyParamsArgs = {
  input: UpdateJourneyParamsInput;
};


export type MutationUpdateJourneyReviewDateArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateJourneyStateArgs = {
  input: UpdateJourneyStateInput;
};


export type MutationUpdateKitArgs = {
  input: UpdateKitInput;
};


export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateRecommendationArgs = {
  input: UpdateRecommendationInput;
};


export type MutationUpdateReferralArgs = {
  input: UpdateReferralInput;
};


export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationUpsertMemberArgs = {
  input: UpsertMemberInput;
};


export type MutationUpsertResearchExtractArgs = {
  input: UpsertResearchExtractInput;
};


export type MutationWithdrawConsentArgs = {
  input: WithdrawConsentInput;
};

export type Note = {
  __typename?: 'Note';
  createdBy?: Maybe<Admin>;
  date?: Maybe<Scalars['String']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  /** @deprecated not all notes have requests */
  request?: Maybe<Request>;
  text?: Maybe<Scalars['String']>;
  /** @deprecated please use createdBy instead */
  username?: Maybe<Scalars['String']>;
};

export type NoteEdge = {
  __typename?: 'NoteEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Note>;
};

export type NotesConnection = {
  __typename?: 'NotesConnection';
  edges?: Maybe<Array<Maybe<NoteEdge>>>;
  pageInfo: PageInfo;
};

export type Notification = {
  __typename?: 'Notification';
  content?: Maybe<Scalars['String']>;
  dateSent?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  method: MessageMethod;
  subject?: Maybe<Scalars['String']>;
  type: MessageType;
};

export enum NotificationEntity {
  Consent = 'CONSENT',
  Journey = 'JOURNEY',
  Lead = 'LEAD'
}

export type NotificationSentForJourneyInput = {
  journeyId: Scalars['ID'];
  type: MessageType;
};

export type Order = {
  __typename?: 'Order';
  data?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  lineItemId?: Maybe<Scalars['String']>;
  source?: Maybe<OrderSource>;
};

export type OrderInput = {
  data?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  lineItemId?: Maybe<Scalars['String']>;
  source?: Maybe<OrderSource>;
};

export enum OrderSource {
  Invoice = 'INVOICE',
  Shopify = 'SHOPIFY'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type Pagination = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum PreTestSelfAssessment {
  AskQuestion = 'ASK_QUESTION',
  BookConsult = 'BOOK_CONSULT',
  Ok = 'OK'
}

export type Pregnancy = {
  __typename?: 'Pregnancy';
  days: Scalars['Int'];
  dueDate?: Maybe<Scalars['String']>;
  dueDateConfirmed?: Maybe<Scalars['Boolean']>;
};

export type PregnancyInput = {
  dueDate?: Maybe<Scalars['String']>;
  dueDateConfirmed?: Maybe<Scalars['Boolean']>;
  weeks?: Maybe<Scalars['Int']>;
};

export enum Product {
  Cancer = 'CANCER',
  Carrier = 'CARRIER'
}

export enum ProductVariant {
  Couple = 'COUPLE',
  Donor = 'DONOR',
  Individual = 'INDIVIDUAL'
}

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  adminByEmail?: Maybe<Admin>;
  appointmentPrediction?: Maybe<AppointmentPredictionResult>;
  carrierRates?: Maybe<Array<Maybe<CarrierStatistics>>>;
  clinic?: Maybe<Clinic>;
  consent?: Maybe<Consent>;
  counsellor?: Maybe<Counsellor>;
  diseaseByGene?: Maybe<Disease>;
  doctor?: Maybe<Doctor>;
  doctorClinic?: Maybe<DoctorClinic>;
  familyHistoryGroupItem?: Maybe<FamilyHistoryGroupItem>;
  familyHistoryGroupItemAuditTrail?: Maybe<Array<Maybe<FamilyHistoryGroupItem>>>;
  familyHistoryGroupSubmission?: Maybe<Array<Maybe<FamilyHistoryGroupItem>>>;
  familyHistoryItem?: Maybe<FamilyHistoryItem>;
  familyHistoryItemAuditTrail?: Maybe<Array<Maybe<FamilyHistoryItem>>>;
  familyHistorySubmission?: Maybe<Array<Maybe<FamilyHistoryItem>>>;
  flag?: Maybe<Flag>;
  followUp?: Maybe<FollowUp>;
  genes?: Maybe<GeneList>;
  getDisease?: Maybe<Disease>;
  journey?: Maybe<Journey>;
  kit?: Maybe<Kit>;
  lead?: Maybe<Lead>;
  listAdmins?: Maybe<Array<Maybe<Admin>>>;
  listClinics?: Maybe<ClinicsConnection>;
  listCounsellors?: Maybe<Array<Maybe<Counsellor>>>;
  listDoctorClinicPairs?: Maybe<DoctorClinicPairsConnection>;
  listDoctors?: Maybe<DoctorsConnection>;
  listFlags?: Maybe<FlagsConnection>;
  listFormSubmissions?: Maybe<FormSubmissionsConnection>;
  listJourneys?: Maybe<JourneysConnection>;
  listKits?: Maybe<KitsConnection>;
  listLeads?: Maybe<LeadsConnection>;
  listRecommendations?: Maybe<RecommendationsConnection>;
  listReferrals?: Maybe<Array<Maybe<Referral>>>;
  listReports?: Maybe<ReportsConnection>;
  listRequests?: Maybe<RequestsConnection>;
  me?: Maybe<Admin>;
  member?: Maybe<Member>;
  memberByEmail?: Maybe<Member>;
  membersByDateOfBirth?: Maybe<Array<Maybe<Member>>>;
  notificationSentForJourney?: Maybe<Scalars['Boolean']>;
  recommendation?: Maybe<Recommendation>;
  referral?: Maybe<Referral>;
  request?: Maybe<Request>;
  researchExtract?: Maybe<ResearchExtract>;
  searchClinics?: Maybe<Array<Maybe<Clinic>>>;
  searchCounsellors?: Maybe<Array<Maybe<Counsellor>>>;
  searchDiseases?: Maybe<Array<Maybe<Disease>>>;
  searchDoctorClinicPairs?: Maybe<Array<Maybe<DoctorClinic>>>;
  searchDoctors?: Maybe<Array<Maybe<Doctor>>>;
  searchEthnicity?: Maybe<Array<Maybe<Ethnicity>>>;
  searchMembers?: Maybe<Array<Maybe<Member>>>;
  searchRequestNotes?: Maybe<NotesConnection>;
};


export type QueryAdminArgs = {
  id: Scalars['ID'];
};


export type QueryAdminByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryAppointmentPredictionArgs = {
  postTestTurnAroundTime?: Maybe<Scalars['Int']>;
  preTestTurnAroundTime?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
};


export type QueryCarrierRatesArgs = {
  gene?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryConsentArgs = {
  id: Scalars['ID'];
};


export type QueryCounsellorArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryDiseaseByGeneArgs = {
  gene?: Maybe<Scalars['String']>;
};


export type QueryDoctorArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorClinicArgs = {
  id: Scalars['ID'];
};


export type QueryFamilyHistoryGroupItemArgs = {
  id: Scalars['ID'];
};


export type QueryFamilyHistoryGroupItemAuditTrailArgs = {
  id: Scalars['ID'];
};


export type QueryFamilyHistoryGroupSubmissionArgs = {
  submissionId: Scalars['ID'];
};


export type QueryFamilyHistoryItemArgs = {
  id: Scalars['ID'];
};


export type QueryFamilyHistoryItemAuditTrailArgs = {
  id: Scalars['ID'];
};


export type QueryFamilyHistorySubmissionArgs = {
  submissionId: Scalars['ID'];
};


export type QueryFlagArgs = {
  id: Scalars['ID'];
};


export type QueryFollowUpArgs = {
  id: Scalars['ID'];
};


export type QueryGenesArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QueryGetDiseaseArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryJourneyArgs = {
  id: Scalars['ID'];
};


export type QueryKitArgs = {
  id: Scalars['ID'];
};


export type QueryLeadArgs = {
  id: Scalars['ID'];
};


export type QueryListAdminsArgs = {
  input?: Maybe<ListAdminsInput>;
};


export type QueryListClinicsArgs = {
  input: ListClinicsInput;
};


export type QueryListDoctorClinicPairsArgs = {
  input: ListDoctorClinicPairsInput;
};


export type QueryListDoctorsArgs = {
  input: ListDoctorsInput;
};


export type QueryListFlagsArgs = {
  input: ListFlagsInput;
};


export type QueryListFormSubmissionsArgs = {
  input: ListFormSubmissionsInput;
};


export type QueryListJourneysArgs = {
  input: ListJourneysInput;
};


export type QueryListKitsArgs = {
  input: ListKitsInput;
};


export type QueryListLeadsArgs = {
  input: ListLeadsInput;
};


export type QueryListRecommendationsArgs = {
  input: ListRecommendationsInput;
};


export type QueryListReferralsArgs = {
  memberId?: Maybe<Scalars['ID']>;
};


export type QueryListReportsArgs = {
  input: ListReportsInput;
};


export type QueryListRequestsArgs = {
  input: ListRequestsInput;
};


export type QueryMemberArgs = {
  id: Scalars['ID'];
};


export type QueryMemberByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryMembersByDateOfBirthArgs = {
  date?: Maybe<Scalars['String']>;
};


export type QueryNotificationSentForJourneyArgs = {
  input: NotificationSentForJourneyInput;
};


export type QueryRecommendationArgs = {
  id: Scalars['ID'];
};


export type QueryReferralArgs = {
  id: Scalars['ID'];
};


export type QueryRequestArgs = {
  id: Scalars['ID'];
};


export type QueryResearchExtractArgs = {
  requestId: Scalars['ID'];
};


export type QuerySearchClinicsArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchCounsellorsArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchDiseasesArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchDoctorClinicPairsArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchDoctorsArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchEthnicityArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchMembersArgs = {
  term?: Maybe<Scalars['String']>;
};


export type QuerySearchRequestNotesArgs = {
  input: SearchRequestNotesInput;
};

export type ReassignConsentInput = {
  consentId: Scalars['ID'];
  journeyId: Scalars['ID'];
};

export type ReassignConsentResult = {
  __typename?: 'ReassignConsentResult';
  journey?: Maybe<Journey>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  action: RecommendationAction;
  dateCompleted?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journey: Journey;
  reason?: Maybe<Scalars['String']>;
  result?: Maybe<RecommendationResult>;
};

export enum RecommendationAction {
  PreTestConsultNotRequired = 'PRE_TEST_CONSULT_NOT_REQUIRED',
  PreTestConsultRequired = 'PRE_TEST_CONSULT_REQUIRED'
}

export type RecommendationEdge = {
  __typename?: 'RecommendationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Recommendation>;
};

export enum RecommendationResult {
  NeedsWork = 'NEEDS_WORK',
  Ok = 'OK'
}

export type RecommendationsConnection = {
  __typename?: 'RecommendationsConnection';
  edges?: Maybe<Array<Maybe<RecommendationEdge>>>;
  pageInfo: PageInfo;
};

export type Referral = {
  __typename?: 'Referral';
  dateCreated?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  doctorClinic: DoctorClinic;
  id: Scalars['ID'];
  journey: Journey;
  member: Member;
  note?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  createdBy?: Maybe<Admin>;
  data?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  members?: Maybe<Array<Maybe<Member>>>;
  request?: Maybe<Request>;
  title?: Maybe<Scalars['String']>;
  /** @deprecated Please use createdBy instead */
  username?: Maybe<Scalars['String']>;
  versionId?: Maybe<Scalars['String']>;
  versions?: Maybe<Array<Maybe<Report>>>;
};

export type ReportEdge = {
  __typename?: 'ReportEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Report>;
};

export type ReportsConnection = {
  __typename?: 'ReportsConnection';
  edges?: Maybe<Array<Maybe<ReportEdge>>>;
  pageInfo: PageInfo;
  totalRows: Scalars['Int'];
};

export type Request = {
  __typename?: 'Request';
  dateCreated?: Maybe<Scalars['String']>;
  dateDetailsConfirmedByMember?: Maybe<Scalars['String']>;
  datePaid?: Maybe<Scalars['String']>;
  doctorConnectionType?: Maybe<Scalars['String']>;
  doctorNotifyOnReport?: Maybe<Scalars['Boolean']>;
  drNameOnRequestForm?: Maybe<Scalars['String']>;
  extractSubmitted?: Maybe<Scalars['Boolean']>;
  gcApprovalDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initiatingMember?: Maybe<Member>;
  journeys?: Maybe<Array<Maybe<Journey>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  numGenesTestedRecessive?: Maybe<Scalars['Int']>;
  numGenesTestedXLinked?: Maybe<Scalars['Int']>;
  onHoldDate?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  pregnancy?: Maybe<Pregnancy>;
  primaryCounsellor?: Maybe<Counsellor>;
  product?: Maybe<Product>;
  productVariant?: Maybe<ProductVariant>;
  reason?: Maybe<Scalars['String']>;
  referringDoctorClinic?: Maybe<DoctorClinic>;
  /** @deprecated Please use reports instead */
  report?: Maybe<Report>;
  reports?: Maybe<Array<Maybe<Report>>>;
  researchExtract?: Maybe<ResearchExtract>;
  riskStatus?: Maybe<RiskStatus>;
  source?: Maybe<Scalars['String']>;
};


export type RequestNotesArgs = {
  importantOnly?: Maybe<Scalars['Boolean']>;
};

export type RequestEdge = {
  __typename?: 'RequestEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Request>;
};

export type RequestsConnection = {
  __typename?: 'RequestsConnection';
  edges?: Maybe<Array<Maybe<RequestEdge>>>;
  pageInfo: PageInfo;
};

export type ResearchExtract = {
  __typename?: 'ResearchExtract';
  data?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['String']>;
  dateSubmitted?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  submittedBy?: Maybe<Admin>;
};

export enum RiskStatus {
  Curly = 'CURLY',
  High = 'HIGH',
  Low = 'LOW'
}

export type SaveReportInput = {
  data?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
};

export type SaveReportResult = {
  __typename?: 'SaveReportResult';
  report?: Maybe<Report>;
};

export type SearchRequestNotesInput = {
  pagination?: Maybe<Pagination>;
  term?: Maybe<Scalars['String']>;
};

export type SetResearchExtractSubmittedInput = {
  requestId: Scalars['ID'];
};

export type SetResearchExtractSubmittedResult = {
  __typename?: 'SetResearchExtractSubmittedResult';
  researchExtract?: Maybe<ResearchExtract>;
};

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type StartCallResource = {
  __typename?: 'StartCallResource';
  roomId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type StartCallResult = {
  __typename?: 'StartCallResult';
  consult?: Maybe<Consult>;
  resource?: Maybe<StartCallResource>;
};

export type StartConsultInput = {
  consultId: Scalars['ID'];
};

export type StartConultResult = {
  __typename?: 'StartConultResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateClinicInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressPostcode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressSuburb?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicPhone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateClinicResult = {
  __typename?: 'UpdateClinicResult';
  clinic?: Maybe<Clinic>;
};

export type UpdateCounsellorInput = {
  adminId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type UpdateCounsellorResult = {
  __typename?: 'UpdateCounsellorResult';
  counsellor?: Maybe<Counsellor>;
};

export type UpdateDoctorClinicInput = {
  clinicId: Scalars['ID'];
  doctorId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
};

export type UpdateDoctorClinicResult = {
  __typename?: 'UpdateDoctorClinicResult';
  doctorClinic?: Maybe<DoctorClinic>;
};

export type UpdateDoctorInput = {
  id: Scalars['ID'];
  gender?: Maybe<Sex>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLast?: Maybe<Scalars['String']>;
  nameMiddle?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  personalMobile?: Maybe<Scalars['String']>;
  personalPhone?: Maybe<Scalars['String']>;
  practitionerId?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  specialties?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  providerNumber?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type UpdateDoctorResult = {
  __typename?: 'UpdateDoctorResult';
  doctor?: Maybe<Doctor>;
};

export type UpdateFamilyHistoryGroupItemInput = {
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateFamilyHistoryGroupItemResult = {
  __typename?: 'UpdateFamilyHistoryGroupItemResult';
  familyHistoryGroupItem?: Maybe<FamilyHistoryGroupItem>;
};

export type UpdateFamilyHistoryItemInput = {
  category?: Maybe<FamilyHistoryCategory>;
  classification: FamilyHistoryClassification;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateFamilyHistoryItemResult = {
  __typename?: 'UpdateFamilyHistoryItemResult';
  familyHistoryItem?: Maybe<FamilyHistoryItem>;
};

export type UpdateFileMetaInput = {
  base64Md5Hash?: Maybe<Scalars['String']>;
  category?: Maybe<FileCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journeyId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateFileMetaResult = {
  __typename?: 'UpdateFileMetaResult';
  uploadURL?: Maybe<Scalars['String']>;
};

export type UpdateFilePermissions = {
  filePermission: FilePermission;
  id: Scalars['ID'];
};

export type UpdateFlagCompleteInput = {
  id: Scalars['ID'];
};

export type UpdateFlagCompleteResult = {
  __typename?: 'UpdateFlagCompleteResult';
  flag?: Maybe<Flag>;
};

export type UpdateFlagSnoozeInput = {
  dateSnoozeUntil?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateFlagSnoozeResult = {
  __typename?: 'UpdateFlagSnoozeResult';
  flag?: Maybe<Flag>;
};

export type UpdateFollowUpCompleteInput = {
  id: Scalars['ID'];
  outcome?: Maybe<Scalars['String']>;
};

export type UpdateFollowUpCompleteResult = {
  __typename?: 'UpdateFollowUpCompleteResult';
  followUp?: Maybe<FollowUp>;
};

export type UpdateFollowUpInput = {
  dateFollowUp?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type UpdateFollowUpResult = {
  __typename?: 'UpdateFollowUpResult';
  followUp?: Maybe<FollowUp>;
};

export type UpdateFormSubmissionProcessedInput = {
  id: Scalars['ID'];
  processed: FormSubmissionProcessed;
};

export type UpdateFormSubmissionProcessedResult = {
  __typename?: 'UpdateFormSubmissionProcessedResult';
  formSubmission: FormSubmission;
};

export type UpdateJourneyParamsInput = {
  addressAddress1?: Maybe<Scalars['String']>;
  addressAddress2?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountryCode?: Maybe<Scalars['String']>;
  addressProvince?: Maybe<Scalars['String']>;
  addressString?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  dateAsknicelyEnrolled?: Maybe<Scalars['String']>;
  hasRecollection?: Maybe<Scalars['Boolean']>;
  journeyId: Scalars['ID'];
  labOrderNumber?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  partnerCanReceiveResults?: Maybe<Scalars['Boolean']>;
};

export type UpdateJourneyParamsResult = {
  __typename?: 'UpdateJourneyParamsResult';
  journey?: Maybe<Journey>;
};

export type UpdateJourneyStateInput = {
  journeyId: Scalars['ID'];
  state: JourneyState;
};

export type UpdateJourneyStateResult = {
  __typename?: 'UpdateJourneyStateResult';
  journey?: Maybe<Journey>;
};

export type UpdateKitInput = {
  arrivedAtEugene?: Maybe<Scalars['Boolean']>;
  kitId: Scalars['ID'];
  registrationNumber?: Maybe<Scalars['String']>;
  shippedToLab?: Maybe<Scalars['Boolean']>;
  shippedToMember?: Maybe<Scalars['Boolean']>;
};

export type UpdateKitResult = {
  __typename?: 'UpdateKitResult';
  kit?: Maybe<Kit>;
};

export type UpdateLeadInput = {
  additionalData?: Maybe<Scalars['String']>;
  closedReason?: Maybe<Scalars['String']>;
  dateLastContact?: Maybe<Scalars['String']>;
  doNotContact?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<LeadStatus>;
};

export type UpdateLeadResult = {
  __typename?: 'UpdateLeadResult';
  lead: Lead;
};

export type UpdateMemberInput = {
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  onlineAccessEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
};

export type UpdateMemberResult = {
  __typename?: 'UpdateMemberResult';
  member?: Maybe<Member>;
};

export type UpdateRecommendationInput = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  result: RecommendationResult;
};

export type UpdateRecommendationResult = {
  __typename?: 'UpdateRecommendationResult';
  recommendation?: Maybe<Recommendation>;
};

export type UpdateReferralInput = {
  doctorId: Scalars['ID'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type UpdateReferralResult = {
  __typename?: 'UpdateReferralResult';
  referral?: Maybe<Referral>;
};

export type UpdateReportInput = {
  data?: Maybe<Scalars['String']>;
  reportId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UpdateReportResult = {
  __typename?: 'UpdateReportResult';
  report?: Maybe<Report>;
};

export type UpdateRequestInput = {
  approvedByGC?: Maybe<Scalars['Boolean']>;
  datePaid?: Maybe<Scalars['String']>;
  initiatingMemberId?: Maybe<Scalars['ID']>;
  numGenesTestedRecessive?: Maybe<Scalars['Int']>;
  numGenesTestedXLinked?: Maybe<Scalars['Int']>;
  onHold?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderInput>;
  pregnancy?: Maybe<PregnancyInput>;
  primaryCounsellorId?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
  productVariant?: Maybe<ProductVariant>;
  reason?: Maybe<Scalars['String']>;
  referringDoctorClinicId?: Maybe<Scalars['ID']>;
  requestId: Scalars['ID'];
  riskStatus?: Maybe<RiskStatus>;
  source?: Maybe<Scalars['String']>;
};

export type UpdateRequestResult = {
  __typename?: 'UpdateRequestResult';
  request?: Maybe<Request>;
};

export type UpsertMemberInput = {
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  onlineAccessEnabled?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
};

export type UpsertMemberResult = {
  __typename?: 'UpsertMemberResult';
  member?: Maybe<Member>;
};

export type UpsertResearchExtractInput = {
  data?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
};

export type UpsertResearchExtractResult = {
  __typename?: 'UpsertResearchExtractResult';
  researchExtract?: Maybe<ResearchExtract>;
};

export type WithdrawConsentInput = {
  consentId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type WithdrawConsentResult = {
  __typename?: 'WithdrawConsentResult';
  consent?: Maybe<Consent>;
};
