import { AnalysisState, AnalysisOverrides, DiseaseOverrides, CarrierRiskOverrides, CarrierRisk } from './analysis/types';
import { ClientDetailsState } from './client-details/types';
import { getDefaultCarrierRisk, getDefaultCarrierLabel, areBothClientsCarriers } from '../../../utilities/analysis/analysisDefaults';
import { replaceContentfulTemplateVariables } from '../../../utilities/report/contentfulReplacement';
import { ReportState } from '.';
import { CarrierRatesState } from './carrier-rates/types';
import { getCarrierRateDescForGene, getResidualRiskDescForGene } from './carrier-rates/selectors';
import { Disease, CarrierStatistics, ProductVariant } from '../../../graphql/genie-api-types';
import { DiseaseByGeneResult } from '../../graphql/requests/requestDiseasesByGene';

export const getDiseaseFieldValue = (gene: string, fieldName: keyof AnalysisOverrides, analysisState: AnalysisState, clientDetailState: ClientDetailsState): string => {
  const disease = clientDetailState.diseasesByGene[gene];
  const defaultValue = disease[fieldName as keyof DiseaseByGeneResult] as string;

  return getFieldOverride(disease.id, fieldName, analysisState, defaultValue);
};

export const getCarrierFieldValue = (gene: string, fieldName: keyof AnalysisOverrides, analysisState: AnalysisState, clientDetailsState: ClientDetailsState): string => {
  let defaultValue = '';
  const disease = clientDetailsState.diseasesByGene[gene];

  switch (fieldName) {
    case 'carrierRisk':
      defaultValue = getDefaultCarrierRisk(gene, clientDetailsState.clientA.affectedGenes, clientDetailsState.clientB.affectedGenes);
      break;

    case 'clientACarrierStatus':
      defaultValue = getDefaultCarrierLabel(gene, clientDetailsState.clientA.affectedGenes);
      break;

    case 'clientBCarrierStatus':
      defaultValue = getDefaultCarrierLabel(gene, clientDetailsState.clientB.affectedGenes);
      break;
  }

  return getFieldOverride(disease.id, fieldName, analysisState, defaultValue);
};

export const getFieldValue = (gene: string, fieldName: keyof AnalysisOverrides, analysisState: AnalysisState, clientDetailsState: ClientDetailsState, carrierRatesState: CarrierRatesState) => {
  let fieldValue;
  if (['carrierRisk', 'clientACarrierStatus', 'clientBCarrierStatus'].includes(fieldName)) {
    fieldValue = getCarrierFieldValue(gene, fieldName, analysisState, clientDetailsState);
  } else {
    fieldValue = getDiseaseFieldValue(gene, fieldName, analysisState, clientDetailsState);
  }

  let nonCarrierName = '';
  let carrierName = '';
  if (!areBothClientsCarriers(gene, clientDetailsState.clientA.affectedGenes, clientDetailsState.clientB.affectedGenes)) {
    if (clientDetailsState.clientA.affectedGenes.includes(gene)) {
      carrierName = clientDetailsState.clientA.nickname;
      nonCarrierName = clientDetailsState.clientB.nickname;
    } else {
      carrierName = clientDetailsState.clientB.nickname;
      nonCarrierName = clientDetailsState.clientA.nickname;
    }
  }

  // Skip contentful replace on genes.
  if (Array.isArray(fieldValue)) {
    return fieldValue;
  }

  const disease = clientDetailsState.diseasesByGene[gene];
  
  const modifications = analysisState.modifications?.[disease.id] ?? {};
  const { fieldOverrides } = analysisState;
  const isFieldOverriden = fieldOverrides?.[disease.id]?.[fieldName];
  
  if (fieldName === 'carrierInfo' && modifications.addPathogenicMutationsToCarrierInfo && !isFieldOverriden) {
    fieldValue += "\n\n*It's useful to know that the significance of the specific variant found in {CARRIER.NAME} is unclear (the technical term is likely pathogenic). What this means is that the current research suggests that someone with this variant is a carrier of this condition. In the future, that may get re-confirmed, or it may get re-classified as being not a carrier. Either way, this is unlikely to have implications for you or your children — since carriers are healthy and {NON_CARRIER.NAME} was not found to carry a variation in this gene.";
  }
  if ((fieldName === 'affectedCoupleActions' || fieldName === 'individualCarrierNextSteps') && modifications.addPathogenicMutationsToNextSteps && !isFieldOverriden) {
    fieldValue = `- It's important for {CARRIER.NAME} to review their results in the future to see if the general understanding of this variant has changed. \n ${fieldValue}`;
  }

  return replaceContentfulTemplateVariables(fieldValue, {
    disease,
    nonCarrierName,
    carrierName,
    carrierRate: getCarrierRateDescForGene(gene, clientDetailsState, carrierRatesState),
    residualRisk: getResidualRiskDescForGene(gene, clientDetailsState, carrierRatesState),
  });
};

export const getDiseaseFieldsMergedWithOverrides = (gene: string, analysisState: AnalysisState, clientDetailsState: ClientDetailsState, carrierRatesState: CarrierRatesState): DiseaseOverrides => {
  if (!clientDetailsState.diseasesByGene[gene]) {
    return {};
  }

  const disease = clientDetailsState.diseasesByGene[gene];
  const overriddenDisease: { [key: string]: string | string[] } = {};

  Object.keys(disease).filter(key => key !== 'cms').forEach((fieldName: keyof Disease) => {
    overriddenDisease[fieldName] = getFieldValue(gene, fieldName as keyof DiseaseOverrides, analysisState, clientDetailsState, carrierRatesState);
  });

  return overriddenDisease as DiseaseOverrides;
};

export const getCarrierFieldsMergedWithOverrides = (gene: string, analysisState: AnalysisState, clientDetailsState: ClientDetailsState, carrierRatesState: CarrierRatesState, productVariant: ProductVariant): CarrierRiskOverrides => {
  if (!clientDetailsState.diseasesByGene[gene]) {
    return {};
  }

  const disease = clientDetailsState.diseasesByGene[gene];
  
  const carrierValues: CarrierRiskOverrides = {
    carrierRisk: getDefaultCarrierRisk(gene, clientDetailsState.clientA.affectedGenes, clientDetailsState.clientB.affectedGenes),
    clientACarrierStatus: getDefaultCarrierLabel(gene, clientDetailsState.clientA.affectedGenes),
    clientBCarrierStatus: getDefaultCarrierLabel(gene, clientDetailsState.clientB.affectedGenes),
  };
  
  if (productVariant && productVariant !== ProductVariant.Couple) {
    const carrierBEthnicity = carrierRatesState.selectedCarrierRateEthnicitiesByGene?.[gene]?.B;
    const carrierRates: CarrierStatistics[] = clientDetailsState?.carrierRatesByGene?.[gene] ?? [];
    const carrierRate = carrierRates.find(carrierRate => carrierRate.ethnicity.id === carrierBEthnicity);
    
    carrierValues.carrierRisk = 'Result to note';
    carrierValues.clientBCarrierStatus = carrierRate ? carrierRate.carrierRateDesc : '';
  }

  Object.keys(carrierValues).forEach((fieldName: keyof CarrierRiskOverrides) => {
    carrierValues[fieldName] = getFieldOverride(disease.id, fieldName, analysisState, carrierValues[fieldName]) as CarrierRisk;
  });

  return carrierValues;
};

export const getAnalysisStateMergedWithOverrides = (reportState: ReportState) => {
  const { analysis, clientDetails, carrierRates, testInformation } = reportState;
  const { diseasesByGene } = clientDetails;

  const analysisStateByGene: { [key: string]: AnalysisOverrides } = {};

  Object.keys(diseasesByGene).forEach((gene: string) => {
    const carrierFields = getCarrierFieldsMergedWithOverrides(gene, analysis, clientDetails, carrierRates, testInformation.productVariant ?? ProductVariant.Couple);
    const diseaseFields = getDiseaseFieldsMergedWithOverrides(gene, analysis, clientDetails, carrierRates);

    analysisStateByGene[gene] = {
      ...carrierFields,
      ...diseaseFields,
    };
  });

  return { analysisStateByGene };
};

export const getFieldOverride = (diseaseId: string, fieldName: keyof AnalysisOverrides, analysisState: AnalysisState, defaultValue: string | string[] = ''): string =>
  analysisState?.fieldOverrides?.[diseaseId]?.[fieldName] as string ?? defaultValue as string;

export const getDiseaseIdForGene = (gene: string, clientDetailsState: ClientDetailsState) =>
  clientDetailsState.diseasesByGene[gene].id;

export const getPageCount = (clientDetailsState: ClientDetailsState) => {
  const pageTotal = Object.values(clientDetailsState.diseasesByGene).length;

  return pageTotal;
};

export const getCMSUrlForDisease = (gene: string, clientDetailsState: ClientDetailsState): string => {
  if (!clientDetailsState.diseasesByGene[gene]) {
    return '';
  }

  const disease = clientDetailsState.diseasesByGene[gene];

  if (!disease.cms) {
    return '';
  }

  return `https://app.contentful.com/spaces/${disease.cms.spaceId}/entries/${disease.cms.entryId}`;
};
