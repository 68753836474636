import { appSyncClient } from '../../../utilities/appSync';
import { MutationSaveReportArgs, Mutation } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ReportState } from '../../store/report';
import { ApolloQueryResult } from 'apollo-client';

type SaveReportResult = Pick<Mutation, 'saveReport'>;

const saveReportForRequestId = async (requestId: string, reportState: ReportState): Promise<ApolloQueryResult<SaveReportResult>> => {
  if (reportState.request?.request?.id && reportState?.request?.request?.id !== requestId) {
    throw new Error('Prevented saving of incorrect report for request Id');
  }
  return appSyncClient.mutate<Pick<Mutation, 'saveReport'>, MutationSaveReportArgs>({
    mutation: gql`
      mutation SaveReport($input: SaveReportInput!) {
        saveReport(input: $input) {
          report {
            id
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        requestId,
        data: JSON.stringify(reportState),
      },
    },
  });
};

export default saveReportForRequestId;
