import * as React from 'react';
import GraphiQL from 'graphiql';

import Screen from '../../components/widgets/Screen';
import config from '../../../utilities/config';
import { AmplifyAuth } from '../../../utilities/amplify/auth';

const fetcher = async (graphQLParams: any) => {
  const APPSYNC_API_URL = config('APP_SYNC_API_URL');
  const amplifySession = await AmplifyAuth.currentSession();
  const token = await amplifySession.getIdToken().getJwtToken();
  const credentialsAppSync = {
    "authorization": token,
  }
  return fetch(
    APPSYNC_API_URL,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...credentialsAppSync
      },
      body: JSON.stringify(graphQLParams),
      credentials: "omit",
    }
  ).then(function (response) {
    return response.json().catch(function () {
      return response.text();
    });
  });
}

const GraphqlIDE = () => {
  return (
    <Screen
      fullWidth
      screenTitleProps={{
        title: 'Graphql Editor',
      }}
    >
      <GraphiQL fetcher={fetcher} editorTheme={'dracula'} />
    </Screen>
  );
}

export default GraphqlIDE;
