import _ from "lodash";
import moment from "moment";
import { AppointmentPrediction } from "../../../../../graphql/genie-api-types";

// sort array result based on date, and produce a number array with the "special" null date value put at index 0
const dailyTotal = (daysToPredict: number, dataArray: any[]) => {
  const result: number[] = []
  /* 
       check if the first and last date exists in array, so the missing days can be added starting from the correct start date
   */
  const lastDate = moment().add(daysToPredict, "days").format("YYYY-MM-DD");
  const today = moment().add(1, "days").format("YYYY-MM-DD"); // exclude today


  if (!dataArray.find((data) => data.datePredicted === lastDate)) {
    dataArray.push({ datePredicted: lastDate, total: 0 });
  }
  if (!dataArray.find((data) => data.datePredicted === today)) {
    dataArray.push({ datePredicted: today, total: 0 });
  }

  const tempArr = _.sortBy(
    dataArray,
    [(obj) => new Date(obj.datePredicted)],
    ["asc"]
  );

  /* 
      find all missing dates
      this is done so we'll have a full week block for the weekly chunk logic 
  */
  const missingDates = tempArr.reduce(
    (function (hash) {
      return function (p: AppointmentPrediction[], c: AppointmentPrediction) {
        const missingDaysNo =
          (Date.parse(c.datePredicted) - hash.prev) / (1000 * 3600 * 24);
        if (hash.prev && missingDaysNo > 1) {
          for (let i = 1; i < missingDaysNo; i++) {
            p.push({
              datePredicted: moment(
                new Date(hash.prev + i * (1000 * 3600 * 24))
              ).format("YYYY-MM-DD"),
              total: 0,
            });
          }
        }
        hash.prev = Date.parse(c.datePredicted);
        return p;
      };
    })(Object.create(null)),
    []
  );


  /* Add the missing dates to tempArray */
  for (let j = 0; j < missingDates.length; j++) {
    tempArr.push(missingDates[j]);
  }
  /* sort it again */
  const finalArray = _.sortBy(
    tempArr,
    [(obj) => new Date(obj.datePredicted)],
    ["asc"]
  );


  finalArray.forEach((element) => {
    result.push(element.total);
  });

  return result;
};

// get the total number of appointments per week
const weeklyTotal = (myArray: number[], nth: number) => {
  // remove first array element because it's an extra param for older possible bookings
  const thisArray: number[] = [...myArray];
  thisArray.shift();

  // divide into nth days chunks
  const chunks = _.chunk(thisArray, nth);
  const results: number[] = [];

  _.forEach(chunks, (chunk) => {
    results.push(
      _.reduce(
        chunk,
        function (sum, n) {
          return sum + n;
        },
        0
      )
    );
  });

  return results;
};

// process minute into hour in increments of 0.25
const roundTheHour = (minuteVal: number) => {
  const hourVal = minuteVal / 60;

  // round it to the closest 0.25
  return Math.ceil(hourVal / 0.25) * 0.25;
};

export const getTestWeeklyTotal = (
  daysToPredict: number,
  dataArray: AppointmentPrediction[],
  ratio: number,
  apptLength: number
) => {
  const getDailyTotal = dailyTotal(daysToPredict, dataArray);
  const weeklyOrderTotal = weeklyTotal(getDailyTotal, 7);

  const lastMonthLeftover = roundTheHour(
    getDailyTotal[0] * (ratio / 100) * apptLength
  );

  if (weeklyOrderTotal.length === 0) {
    return [lastMonthLeftover];
  }

  const newArray: any[] = [lastMonthLeftover];
  weeklyOrderTotal.map((totalOrder: number) => {
    const minuteVal = totalOrder * (ratio / 100) * apptLength;
    newArray.push(roundTheHour(minuteVal));
  });

  return newArray;
};
