import { ReportTemplate } from '..';
import { SectionVariables } from '../sections/Section';
import { ReportState } from '../../../../app/store/report';
import { generateAndList } from '../../../helpers';
import { Disease } from '../../../../graphql/genie-api-types';

type ClientKey = 'clientA' | 'clientB';

export const getFamilyReportTemplate = (clientKey: ClientKey): ReportTemplate => ({
  label: 'Family report',
  name: clientKey === 'clientA' ? 'familyReportA' : 'familyReportB',
  medium: 'email',
  pages: [
    {
      sections: [
        {
          id: 'intro',
          template: 'Section',
          variables: {
            title: 'Hi {{memberName}}, hope you\'re well!',
            content: 'Genetics is a **[family affair](https://eugenelabs.com/carrier/genetics-101/?utm_medium=email&utm_source=family_report)**, so we\'re excited to launch a version of Eugene reports that are designed to be shared with your family. It\'s a brief and helpful summary of your results that you can forward on to them so they can make empowered health choices just like you did 🙌.',
          } as SectionVariables,
        },
        {
          id: 'divider',
          template: 'Divider',
        },
        {
          id: 'intro',
          template: 'Section',
          variables: {
            title: 'To share with your family',
            content: `Hey fam,

I just wanted to let you know that I recently had genetic carrier screening with **[Eugene](http://eugenelabs.com/carrier?utm_medium=email&utm_source=family_report)**. The test found changes in my genes which can increase the chances of having a child with a serious genetic condition. It's ok though, as a carrier I'm not affected by the condition, and the chances of passing any of them on are completely manageable if you know about them in time.
            
Before we get into it, **[here's a link](https://eugenelabs.com/carrier/genetics-101/?utm_medium=email&utm_source=family_report)** to some more information on carrier screening.`,
          } as SectionVariables,
        },
        {
          id: 'diseaseTable',
          template: 'DiseaseTable',
          variables: {
            title: 'We found that I\'m a carrier of:',
          },
        },
        {
          id: 'related',
          template: 'Section',
          variables: {
            content: `Since you're related to me, you're at a higher chance of being a carrier. So if you're planning a pregnancy, you should really consider getting screened with your partner. Knowing this info before you have a family of your own can help open up your reproductive choices and even avoid passing on the condition.

Not to mention, doctors are now recommending this test as a routine part of pre and early pregnancy care. 
            
If you're interested in getting screened, you can learn more at **[eugenelabs.com/carrier](http://eugenelabs.com/carrier)** or feel free to reach out with any questions to **[hello@eugenelabs.com](mailto:hello@eugenelabs.com)**
            
Finally, Eugene does offer a reduced rate for family members of carriers so you can use the code **FAM50** when you're checking out to get $50 off the test price.`,
          } as SectionVariables,
        },
      ],
    },
  ],
});

export interface FamilyReportVariables {
  linkedDiseaseNameList: string;
  diseases: Disease[]
  memberName: string;
};

export const getFamilyReportTemplateVariables = (reportState: ReportState, client: ClientKey): FamilyReportVariables => {
  const memberDetails = reportState.clientDetails[client];
  const genes = memberDetails.affectedGenes;
  const { diseasesByGene } = reportState.clientDetails;
  
  const memberName = memberDetails.nickname;

  const diseases = genes.map(gene => diseasesByGene[gene]);
  const linkedDiseaseNameList = diseases.map(disease =>
    `[${disease.name}](https://eugenelabs.com/carrier/diseases/${disease.id}?utm_medium=email&utm_source=family_report#disease-label-low-risk)`);

  return {
    linkedDiseaseNameList: generateAndList(linkedDiseaseNameList),
    diseases,
    memberName,
  };
};
