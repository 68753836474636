import { ApolloQueryResult } from 'apollo-client';
import { appSyncClient } from '../../../utilities/appSync';
import { CarrierStatistics, Ethnicity, QueryCarrierRatesArgs } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';

export interface CarrierRate extends Pick<CarrierStatistics, 'gene'|'carrierRateDesc'|'residualRiskDesc'|'carrierRateRaw'|'residualRiskRaw'> {
  ethnicity: Pick<Ethnicity, 'id'|'name'>;
}

interface CarrierRatesResult {
  carrierRates: CarrierRate[];
}

interface CarrierRatesQueryResponse {
  result: ApolloQueryResult<CarrierRatesResult>;
  gene: string;
}

export type RequestCarrierStatisticsByGeneResult = { [key: string]: CarrierRate[] };

const requestCarrierStatisticsByGene = async (genes: string[]): Promise<RequestCarrierStatisticsByGeneResult> => {
  const diseaseQueries: Promise<CarrierRatesQueryResponse>[] = genes.map(gene => appSyncClient.query<CarrierRatesResult, QueryCarrierRatesArgs>({
    query: gql`
      query CarrierRates($gene: String!, $locale: String) {
        carrierRates(gene: $gene, locale: $locale) {
          gene
          ethnicity {
            id
            name
          }
          carrierRateDesc
          residualRiskDesc
          carrierRateRaw
          residualRiskRaw
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      gene,
    },
  }).then(({ errors, ...result }) => ({
    gene,
    result: {
      errors: errors ? errors : null, // Fixes typescript compilation issue with incompatible graphql return signature.
      ...result,
    },
  })));

  const geneQueryResults: CarrierRatesQueryResponse[] = await Promise.all(diseaseQueries);

  const carrierRatesByGene: RequestCarrierStatisticsByGeneResult = {};

  geneQueryResults.forEach((geneQueryResult: CarrierRatesQueryResponse) => {
    carrierRatesByGene[geneQueryResult.gene] = geneQueryResult.result.data.carrierRates;
  });

  return carrierRatesByGene;
};

export default requestCarrierStatisticsByGene;
