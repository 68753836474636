import { retrieveFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../storage/encryptedStorageDriver';
import { showToast } from '../../app/store/toast/actions';
import { ReportState } from '../../app/store/report';
import { ApplicationState } from '../../app/store';

export const STORAGE_BUCKET_NAME = 'report';
export const STORAGE_LIST_NAME = `sessions/${STORAGE_BUCKET_NAME}`;

export const getReportSessions = (encryptionKey: string) => {
  return retrieveFromLocalStorage(STORAGE_LIST_NAME, encryptionKey, true) as string[] || [];
};

export const createReportSession = (sessionName: string, encryptionKey: string) => {
  const sessionNames = getReportSessions(encryptionKey);

  if (sessionNames.indexOf(sessionName) !== -1) {
    showToast('error', 'Did not create session', `Session by the name "${sessionName}" already exists`);
  }

  const newSessionNames = [
    ...sessionNames,
    sessionName,
  ];

  saveToLocalStorage(STORAGE_LIST_NAME, newSessionNames, encryptionKey);
};

export const deleteReportSession = (sessionName: string, encryptionKey: string) => {
  const sessionNames = getReportSessions(encryptionKey);
  const newSessionNames = [...sessionNames];
  const sessionIndex = sessionNames.indexOf(sessionName);
  newSessionNames.splice(sessionIndex, 1);

  saveToLocalStorage(STORAGE_LIST_NAME, newSessionNames, encryptionKey);

  const localStorageNameForSession = getLocalStorageNameForSessionStorage(sessionName);
  deleteFromLocalStorage(localStorageNameForSession);

  showToast('info', 'Session deleted', `Session "${sessionName}" was deleted`);

  return newSessionNames;
};

export const getReportSessionStorageKey = (sessionName: string) =>
  `sessions/report/${sessionName}`;

export const getLocalStorageNameForSessionStorage = (sessionName: string) =>
  `${STORAGE_LIST_NAME}/${sessionName}`;

export const saveReportToLocalStorage = (sessionName: string, report: ReportState, encryptionKey: string) => {
  const storageName = getReportSessionStorageKey(sessionName);
  saveToLocalStorage(storageName, { report }, encryptionKey);
};

export const loadReportFromStorage = (sessionName: string, encryptionKey: string) =>
  retrieveFromLocalStorage(getReportSessionStorageKey(sessionName), encryptionKey) as Partial<ApplicationState>;
