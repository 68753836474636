import * as React from 'react';
import { Notification } from '../../../graphql/genie-api-types';
import { formatNotificationMethod, formatNotificationType, shorthandFromNow } from '../../../utilities/helpers';
import PrivateContent from './PrivateContent';

interface NotificationNoteProps {
  notification: Notification;
}

const NotificationNote = ({ notification }: NotificationNoteProps) => (
  <div className={`flex items-start py-10 my-5 border-b text-sm last:mb-20 px-5 ${ notification.type !== "CUSTOM_EXTERNAL_MESSAGE" ? "bg-grey-light " : "" }`}>
    <div className="flex-none">
        <span className="pr-10">{formatNotificationMethod(notification.method)}</span>
    </div>
    <div className="flex-1">
      <p>
        <PrivateContent>
          {formatNotificationType(notification.type)} (via {notification.method.toLowerCase()})
        </PrivateContent>
      </p>
      {notification.destination && (
        <div className="text-grey text-xs mt-2">Sent to: {notification.destination}</div>
      )}
      { notification.type == "CUSTOM_EXTERNAL_MESSAGE" && (
        <div className={`text-grey text-xs mt-2`}>{notification.content}</div>
      )}
    </div>
    <div className="pl-10 pt-5 text-xs text-right text-grey">{shorthandFromNow(notification.dateSent)}</div>
  </div>
);

export default NotificationNote;
