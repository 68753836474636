import { FieldType } from '../../FieldType';

export type DiseaseTableVariables = {
  clientAName: string;
  highRiskInformation: string;
  highRiskMore: string;
  increasedRiskInformation: string;
  increasedRiskMore: string;
  lowRiskMore: string;
  resultToNoteMore: string;
  otherConditionsTested: string;
  otherConditionsClientAStatus: string;
  otherConditionsClientBStatus: string;
  hasSecondClientColumn: boolean;
  clientBName: string;
};

export const DiseaseTableVariableTypes: {[key in keyof DiseaseTableVariables]: FieldType} = {
  clientAName: 'text',
  highRiskInformation: 'text',
  highRiskMore: 'text',
  increasedRiskInformation: 'text',
  increasedRiskMore: 'text',
  lowRiskMore: 'text',
  resultToNoteMore: 'text',
  otherConditionsTested: 'text',
  otherConditionsClientAStatus: 'text',
  otherConditionsClientBStatus: 'text',
  hasSecondClientColumn: 'boolean',
  clientBName: 'text',
};
