import runStatsQuery from '../../../utilities/runStatsQuery';
import gql from 'graphql-tag';
import { DoctorClinicPairsConnection, QueryListDoctorClinicPairsArgs } from '../../../graphql/genie-api-types';
import { orderBy } from 'lodash';
import moment from 'moment';
import { extractStateFromAddress, preferredDoctorClinicEmail } from '../../../utilities/helpers';

const DOCTOR_CLINIC_PAIRS_QUERY = gql`
  query ListDoctorClinicPairs($input: ListDoctorClinicPairsInput!) {
    listDoctorClinicPairs(input: $input){
      edges {
        cursor
        node {
          email
          doctor {
            nameFirst
            nameMiddle
            nameLast
            nickname
            title
            personalEmail
          }
          clinic {
            name
            addressStreet
            addressState
            clinicEmail
          }
          requests {
            id
            datePaid
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const requestFullReferralList = async () => {
  const doctorClinicResults = await runStatsQuery<DoctorClinicPairsConnection, QueryListDoctorClinicPairsArgs>(DOCTOR_CLINIC_PAIRS_QUERY, {
  }, 'listDoctorClinicPairs', 15);

  const doctorClinics = doctorClinicResults.edges.map(edge => edge.node).filter(doctorClinic => preferredDoctorClinicEmail(doctorClinic) !== '');
  doctorClinics.forEach(doctorClinic => {
    doctorClinic.requests = doctorClinic.requests.filter(request => {
      return request.datePaid;
    });
  });

  const doctorRows: string[][] = doctorClinics.map(doctorClinic => {
    const email = preferredDoctorClinicEmail(doctorClinic);
    let doctorName = doctorClinic.doctor.nameFirst;
    if (doctorClinic.doctor.nameMiddle) {
      doctorName += ' ' + doctorClinic.doctor.nameMiddle;
    }

    if (doctorClinic.doctor.nameLast) {
      doctorName += ' ' + doctorClinic.doctor.nameLast;
    }

    doctorName = doctorName.replace(/\s+/g, ' ').trim();

    const state = doctorClinic.clinic.addressState ?? extractStateFromAddress(doctorClinic.clinic.addressStreet);

    return [
      doctorName,
      email,
      doctorClinic.clinic.name,
      state,
      ...(orderBy(doctorClinic.requests?.map(request => moment(request.datePaid).format('YYYY-MM-DD')), (date) => moment(date).unix())),
    ];
  });

  const header: string[] = [
    'Dr Name',
    'Email',
    'Clinic name',
    'State',
    'Referrals',
  ];

  let csvContent = "data:text/csv;charset=utf-8,"
    + header.join(',') + "\n";

  doctorRows.forEach(line => {
    csvContent += `${line.map(col => `"${col}"`).join(',')} \n`;
  });

  window.open(encodeURI(csvContent));
};

export default requestFullReferralList;
