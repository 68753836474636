import * as React from 'react';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import { connect } from 'react-redux';
import templates from '../../../../utilities/report/templates';
import { generateJuicedHtml } from '../../../../utilities/report/html/htmlTemplateGenerator';
import { ReportState } from '../../../store/report';
import Button from '../../../components/widgets/Button';
import { toastr } from 'react-redux-toastr';

interface FamilyReportViewProps extends TaskListComponentProps {
  reportState: ReportState;
}

interface FamilyReportViewState {
  familyReportA: string;
  familyReportB: string;
}

class FamilyReportView extends React.PureComponent<FamilyReportViewProps, FamilyReportViewState> {
  private familyReportAIframeRef = React.createRef<HTMLIFrameElement>();
  private familyReportBIframeRef = React.createRef<HTMLIFrameElement>();

  state: Readonly<FamilyReportViewState> = {
    familyReportA: '',
    familyReportB: '',
  };

  componentDidMount() {
    this.onRefreshIframe();
  }

  componentDidUpdate() {
    this.onRefreshIframe();
  }

  onRefreshIframe() {
    this.updateIframe('familyReportA');
    this.updateIframe('familyReportB');
  }

  async updateIframe(reportName: 'familyReportA' | 'familyReportB') {
    const { reportState } = this.props;
    const styledReportHtml = await generateJuicedHtml(reportState, templates[reportName]);

    const iframe = this[`${reportName}IframeRef` as 'familyReportAIframeRef' | 'familyReportBIframeRef'].current;
    const document = iframe.contentDocument;

    iframe.onload = () => {
      const firstElement = iframe.contentWindow.document.body.children[0] as HTMLDivElement;
      iframe.style.height = `${firstElement.offsetHeight + firstElement.offsetTop + 20}px`;
    };

    document.open();
    document.write(styledReportHtml);
    document.close();
  }

  renderHeader(name: string, iframeRef: React.RefObject<HTMLIFrameElement>) {
    return (
      <>
        <h3 className="mb-5 font-bold text-sm">To</h3>
        <p className="mb-20">{name}</p>
        <h3 className="mb-5 font-bold text-sm">Subject line</h3>
        <p className="mb-20">Your Eugene family report</p>
        <Button onClick={() => {
          const frameContent = iframeRef.current.contentDocument;
          frameContent.execCommand('selectAll');
          frameContent.execCommand('copy');
          frameContent.execCommand('');
          frameContent.getSelection().removeAllRanges();
          toastr.info('Email copied', `Email to ${name} copied to clipboard.`);
        }}>
          Copy Email HTML
        </Button>
      </>
    );
  }

  render() {
    const { isComplete, onToggleComplete, reportState } = this.props;
    return (
      <div>
        <TaskListScreenTitle
          title="Family Report"
          isSelected={isComplete}
          onToggle={onToggleComplete}
        />
        <div className="border p-20 mb-40">
          {this.renderHeader(reportState.clientDetails.clientA.fullName, this.familyReportAIframeRef)}
          <hr />
          <iframe ref={this.familyReportAIframeRef} className="w-full" />
        </div>
        <div className="border p-20 mb-40">
          {this.renderHeader(reportState.clientDetails.clientB.fullName, this.familyReportBIframeRef)}
          <hr />
          <iframe ref={this.familyReportBIframeRef} className="w-full" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ report }: ApplicationState) => ({
  reportState: report,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onToggleComplete: () => dispatch(toggleTask('familyReport')),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyReportView);
