import * as React from 'react';
import { QueryResult } from 'react-apollo';
import SearchAutocomplete from '../../components/widgets/SearchAutocomplete';
import GenieQuery from '../base/GenieQuery';
import { Disease } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { DiseaseOption } from '../../components/research-extract/DiseaseDetailsSearch';

interface DiseaseSearchAutocompleteProps {
  onSelect(disease: DiseaseOption): void;
}

type DiseaseSearchResult = {
  searchDiseases: Pick<Disease, 'id'|'name'|'genes'>[];
};

const DiseaseSearchAutocomplete = (props: DiseaseSearchAutocompleteProps) => (
  <GenieQuery
    query={gql`
      query SearchDiseases($term: String!) {
        searchDiseases(term: $term) {
          id
          genes
          name
        }
      }
    `}
    notifyOnNetworkStatusChange
    variables={{
      term: '',
    }}
  >
    {({ loading, data, fetchMore }: QueryResult<DiseaseSearchResult>) => (
      <SearchAutocomplete
        isLoading={loading}
        options={data && data.searchDiseases ? data.searchDiseases.map(({ id, name, genes }) => ({
          id,
          name,
          genes,
        })) : []}
        onSearch={(searchQuery: string) => {
          fetchMore({
            variables: {
              term: searchQuery,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
              ...previousResult,
              ...fetchMoreResult,
            }),
          });
        }}
        onSelect={props.onSelect}
      />
    )}
  </GenieQuery>
);

export default DiseaseSearchAutocomplete;
