import * as React from 'react';

import { Hub } from '@aws-amplify/core';
import CognitoFederatedSignIn from '../components/auth/CognitoFederatedSignIn';
import { getSignInUrl, AmplifyAuth } from '../../utilities/amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  errorMessage: string;
  cognitoUser: CognitoUser;
}

class AuthenticationWrapper extends React.Component<{}, AuthState> {
  state: Readonly<AuthState> = {
    isAuthenticated: false,
    isLoading: false,
    errorMessage: '',
    cognitoUser: null,
  };

  constructor(props: {}) {
    super(props);
    Hub.listen('auth', this);
  }

  componentDidMount() {
    this.onLoadUser();
  }

  onHubCapsule = (capsule: any) => {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event === 'signIn') {
      this.onLoadUser();
    }
  }

  onLoadUser = async () => {
    this.setState({ isLoading: true });

    try {
      const cognitoUser = await AmplifyAuth.currentAuthenticatedUser();
      this.setState({ cognitoUser, isAuthenticated: true });
    } catch (e) {
      console.warn(e);
      this.setState({ errorMessage: e.message });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, isAuthenticated } = this.state;

    if (isAuthenticated) {
      return this.props.children;
    }

    return (
      <div className="flex items-center justify-center flex-row h-full">
        <div className="text-center">
          <img src="/assets/images/eugene-logo.png" alt="Eugene logo" className="mb-20" />
          <CognitoFederatedSignIn
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
            signInUrl={getSignInUrl()}
          />
        </div>
      </div>
    );
  }
}

export default AuthenticationWrapper;
