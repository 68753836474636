import * as React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { AWS_DATE_FORMAT } from '../../../../utilities/helpers';
import ProductSelect from './ProductSelect';

export interface FormControlProps extends React.HTMLAttributes<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> {
  isInvalid?: boolean;
  type?: 'text' | 'number' | 'email' | 'tel' | 'date' | 'password' | 'checkbox' | 'datetime-local' | 'productAndVariantSelect';
  name: string;
  as?: 'select' | 'textarea' | 'radios';
  value?: string|any;
  min?: string;
  max?: string;
  setFieldValue?(fieldName: string, fieldValue: any): void;
  autoFocus?: boolean;
  disabled?: boolean;
}

const FormControl = ({ isInvalid, className, setFieldValue, as: asModifier, ...props }: FormControlProps) => {
  const inputClass = `block border p-10 bg-white rounded block w-full focus:border-grey focus:text-blue-darkest outline-none ${isInvalid ? 'text-red' : ''} ${className || ''}`;

  if (asModifier) {
    switch (asModifier) {
      case 'select':
        return (
          <select
            {...props}
            className={inputClass}
            onChange={e => setFieldValue(props.name, e.target.value || null)}
          />
        );
      case 'textarea':
        return <textarea {...props} className={inputClass} />;
    }
  }

  switch (props.type) {
    case 'productAndVariantSelect':
      return (
        <ProductSelect
          {...props}
          onChange={(value) => setFieldValue(props.name, value)}
          selectClass={inputClass}
        />
      );

    case 'date':
      return (
        <DatePicker
          onChange={date => setFieldValue(props.name, date ? moment(date, 'DD/MM/YYYY').format(AWS_DATE_FORMAT) : null)}
          selected={props.value ? new Date(props.value) : null}
          className={inputClass}
          placeholderText="dd/mm/yyyy"
          minDate={props.min ? new Date(props.min) : null}
          maxDate={props.max ? new Date(props.max) : null}
          isClearable
          todayButton="Today"
          dateFormat="dd/MM/yyyy"
        />
      );
    case 'datetime-local':
      return (
        <input {...props} className={inputClass} format-value="yyyy-MM-ddTHH:mm" />
      );

    default:
      return (
        <input {...props} className={inputClass} autoComplete="off" />
      );
  }
};

FormControl.defaultProps = {
  type: 'text',
};

export default FormControl;
