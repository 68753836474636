import * as React from 'react';
import { has } from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { getCarrierFieldsMergedWithOverrides, getDiseaseIdForGene } from '../../../store/report/selectors';
import { CarrierRiskOverrides, AnalysisOverrides } from '../../../store/report/analysis/types';
import DiseaseRiskExpansionItem from '../../../components/report/analysis/DiseaseRiskExpansionItem';
import Row from '../../../components/layout/Row';
import Col from '../../../components/layout/Col';

interface DiseaseRiskExpansionCarrierFieldsProps {
  onResetClick(fieldName: string): void;
  onEditClick(fieldName: string): void;
  gene: string;
}

interface StateProps extends CarrierRiskOverrides {
  clientAName: string;
  clientBName: string;
  fieldOverrides: AnalysisOverrides;
  diseaseId: string;
}

interface AllProps extends StateProps, DiseaseRiskExpansionCarrierFieldsProps { }

const fields = ['carrierRisk', 'clientACarrierStatus', 'clientBCarrierStatus'];

const getTitleOverride = (fieldName: string, props: StateProps) => {
  switch (fieldName) {
    case 'clientACarrierStatus':
      return props.clientAName;

    case 'clientBCarrierStatus':
      return props.clientBName;

    default:
      return '';
  }
};

class DiseaseRiskExpansionCarrierFields extends React.PureComponent<AllProps> {
  render() {
    const props = this.props;

    return (
      <Row>
        {fields.map(fieldName => (
          <Col width="1/3" key={fieldName}>
            <DiseaseRiskExpansionItem
              diseaseId={props.diseaseId}
              onResetClick={() => props.onResetClick(fieldName)}
              onEditClick={() => props.onEditClick(fieldName)}
              isOverridden={has(props.fieldOverrides, fieldName)}
              titleOverride={getTitleOverride(fieldName, props)}
              fieldName={fieldName}
              fieldValue={props[fieldName as keyof CarrierRiskOverrides]}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: DiseaseRiskExpansionCarrierFieldsProps) => {
  const { gene } = ownProps;
  const { clientDetails, analysis, testInformation, carrierRates } = state.report;
  const diseaseId = getDiseaseIdForGene(ownProps.gene, clientDetails);

  return {
    diseaseId,
    clientAName: clientDetails.clientA.nickname,
    clientBName: clientDetails.clientB.nickname,
    ...getCarrierFieldsMergedWithOverrides(gene, analysis, clientDetails, carrierRates, testInformation.productVariant),
    fieldOverrides: analysis.fieldOverrides[diseaseId],
  };
};

export default connect<StateProps, {}, DiseaseRiskExpansionCarrierFieldsProps>(mapStateToProps)(DiseaseRiskExpansionCarrierFields);
