import { appSyncClient } from '../../../../utilities/appSync';
import { Query, Journey, QueryJourneyArgs, Request } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';

const requestJourneyRequestId = async (id: Journey['id']): Promise<Request['id']> => {
  const journeyResult = await appSyncClient.query<Pick<Query, 'journey'>, QueryJourneyArgs>({
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    query: gql`
      query Journey($id: ID!) {
        journey(id: $id) {
          id
          request {
            id
          }
        }
      }
    `,
    variables: {
      id,
    },
  });

  return journeyResult?.data?.journey?.request?.id;
}

export default requestJourneyRequestId;
