import * as React from 'react';
import Heading from '../widgets/Heading';
import ChecklistItem from '../widgets/ChecklistItem';

interface ShippingChecklistProps {

}

const ShippingChecklist = () => (
  <div className="flex items-start w-full">
    <div className="my-20 flex-1">
      <Heading level={3}>Before you begin <span className="text-grey-dark italic">- in order</span></Heading>
      <div className="mt-10">
        <ChecklistItem>Courier bag</ChecklistItem>
        <ChecklistItem>Invitae boxes</ChecklistItem>
        <ChecklistItem>Surface empty and clean</ChecklistItem>
        <ChecklistItem>Surface wiped down</ChecklistItem>
        <ChecklistItem>Hands sanitised</ChecklistItem>
        <ChecklistItem>Gloves on</ChecklistItem>
      </div>
    </div>
    <div className="my-20 ml-20 flex-1">
      <Heading level={3}>After you finish <span className="text-grey-dark italic">- in order</span></Heading>
      <div className="mt-10">
        <ChecklistItem>Surface wiped down</ChecklistItem>
        <ChecklistItem>Gloves disposed</ChecklistItem>
        <ChecklistItem>Hands sanitised</ChecklistItem>
      </div>
    </div>
  </div>
);

export default ShippingChecklist;
