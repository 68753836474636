import { appSyncClient } from '../../../../utilities/appSync';
import moment from 'moment';
import { JourneyState, Mutation, MutationUpdateRequestArgs, Query, QueryRequestArgs, Request } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { AWS_DATE_TIME_FORMAT } from '../../../../utilities/helpers';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import deduplicateMemberRequests from '../journey/deduplicateMemberJourneys';
import updateJourneyState from '../journey/updateJourneyState';

type MarkRequestAsPaidResult = {
  updateRequest: {
    request: Pick<Request, 'id'|'datePaid'>;
  };
}

const markRequestAsPaid = async (requestId: string, deduplicate?: boolean): Promise<ApolloQueryResult<MarkRequestAsPaidResult>> => {
  const requestQuery = await appSyncClient.query<Pick<Query, 'request'>, QueryRequestArgs>({
    query: gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          journeys {
            id
            state
          }
        }
      }
    `,
    variables: {
      id: requestId,
    }
  });
  
  const journeys = requestQuery?.data?.request?.journeys ?? [];
  await Promise.all(journeys.map(async (journey) => {
    if (journey.state === JourneyState.CloseLost) {
      await updateJourneyState(journey.id, JourneyState.RequirePretestBooking);
    }
  }));

  const result = await appSyncClient.mutate<Pick<Mutation, 'updateRequest'>, MutationUpdateRequestArgs>({
    mutation: gql`
      mutation UpdateRequest($input: UpdateRequestInput!) {
        updateRequest(input: $input) {
          request {
            id
            datePaid
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        requestId,
        datePaid: moment().format(AWS_DATE_TIME_FORMAT),
      },
    },
  }) as ApolloQueryResult<MarkRequestAsPaidResult>;
  if (deduplicate) {
    // Merge unpaid duplicates belonging to this requests primary member.
    await deduplicateMemberRequests(requestId);
  }
  
  ReduxQueryListener.triggerEvents('All');
  
  return result;
};
 

export default markRequestAsPaid;
