import * as React from 'react';
import { ORDERED_JOURNEY_STATES, SELECTABLE_JOURNEY_STATES, JOURNEY_STATE_LABELS } from '../../../../utilities/journeys/states';
import { JourneyState } from '../../../../graphql/genie-api-types';

interface FormJourneyStatesRadiosProps {
  onSelect(state: JourneyState): void;
  selectedJourneyState?: JourneyState;
}

const GROUP_INITIAL_STATES: { [key: string]: string } = {
  [JourneyState.RequireDetails]: 'Pre-Test',
  [JourneyState.WaitingForLabResults]: 'During',
  [JourneyState.ReadyForReport]: 'Post',
  [JourneyState.ReadyToArchive]: 'Archival',
};

const FormJourneyStatesRadios = ({ selectedJourneyState, onSelect }: FormJourneyStatesRadiosProps) => (
  <div>
    {ORDERED_JOURNEY_STATES.filter(state => SELECTABLE_JOURNEY_STATES.includes(state)).map(journeyState => (
      <React.Fragment key={journeyState}>
        {GROUP_INITIAL_STATES[journeyState] ? (
          <div className="text-grey mt-20 mb-5">{GROUP_INITIAL_STATES[journeyState]}</div>
        ) : null}
        <label className={`py-6 block flex w-full mb-5 ${selectedJourneyState === journeyState ? 'bg-purple-lighter -mx-8 px-8' : ''}`}>
          <input
            type="radio"
            name="journeyState"
            value={journeyState}
            className="mr-10"
            onChange={() => onSelect(journeyState)}
          />
          {JOURNEY_STATE_LABELS[journeyState]}
        </label>
      </React.Fragment>
    ))}
  </div>
);

export default FormJourneyStatesRadios;
