import * as React from 'react';
import { AreaChart, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Area } from 'recharts';
import moment from 'moment';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { Kit, KitsConnection, QueryListKitsArgs } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { FiInfo } from '../../components/widgets/Icon';
import { StatsProps } from './Stats';

const formatMonthString = (moment: moment.Moment) =>
  `${moment.year()}.${(moment.month() + 1).toString().padStart(2, '0')}`

export type TooltipFormatter = (value: number, name: string) => string
interface ChartData {
  dataKeys: string[];
  items: { [key: string]: number | string }[];
  tooltipFormatter?: TooltipFormatter;
}

const RecollectionStats = (props: StatsProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [allKits, setAllKits] = React.useState<Kit[]>([]);
  const { fromDate, toDate } = props;

  const getAllKits = async () => {
    const results = await runStatsQuery<KitsConnection, QueryListKitsArgs>(gql`
      query ListKits($input: ListKitsInput!) {
        listKits(input: $input) {
          edges {
            cursor
            node {
              id
              dateShippedToLab
              dateShippedToMember
              recollectionRequestedDate
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
      `, {
      variables: {
        input: {},
      },
    }, 'listKits');
    const kits = results.edges.map(edge => edge.node);
    setAllKits(kits);
    setIsLoading(false);
  };

  React.useEffect(() => { getAllKits(); }, []);

  const kitsAndRecollectsDataByMonth = React.useMemo(() => {
    const startMonth = moment(fromDate).clone().startOf('month');
    const endMonth = moment(toDate).clone().add(1, 'month').startOf('month');

    const months = endMonth.diff(startMonth, 'months');

    const monthData: { [key: string]: { totalShipped: number; totalRecollected: number; totalMemberShipped: number; totalFailedAtEugene: number; totalFailedAtLab: number } } = {}

    for (let i = 0; i < months; i++) {
      const date = startMonth.clone().add(i, 'months');
      const monthString = formatMonthString(date);
      monthData[monthString] = { totalShipped: 0, totalRecollected: 0, totalMemberShipped: 0, totalFailedAtEugene: 0, totalFailedAtLab: 0 };
    }

    allKits.filter(kit => kit.dateShippedToMember).forEach(kit => {
      const shippedToMemberDate = moment(kit.dateShippedToMember);

      const memberShippedDateString = formatMonthString(shippedToMemberDate);
      if (monthData[memberShippedDateString]) {
        monthData[memberShippedDateString].totalShipped++;

        if (kit.recollectionRequestedDate) {
          monthData[memberShippedDateString].totalRecollected++;

          if (kit.dateShippedToLab) {
            monthData[memberShippedDateString].totalFailedAtLab++;
          }
          else {
            monthData[memberShippedDateString].totalFailedAtEugene++;
          }
        }
      }
    });

    return Object.keys(monthData).sort().map((monthString) => {
      return {
        month: moment(monthString, 'YYYY-MM').format('MMM'),
        '% of samples failed at Eugene': (((monthData[monthString].totalFailedAtEugene / monthData[monthString].totalShipped) || 0) * 100).toFixed(1),
        '% of samples failed at Lab': (((monthData[monthString].totalFailedAtLab / monthData[monthString].totalShipped) || 0) * 100).toFixed(1),
      };
    });
  }, [fromDate, toDate, allKits]);

  const colors = ['#6269EE', '#FFB258', '#7F7F9E', '#FD5A5A'];

  const data: ChartData = React.useMemo(() => {
    return {
      dataKeys: ['% of samples failed at Eugene', '% of samples failed at Lab'],
      items: kitsAndRecollectsDataByMonth,
      tooltipFormatter: (value) => {
        return `${value}%`;
      },
    };
  }, [kitsAndRecollectsDataByMonth]);

  const kitShippedCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.dateShippedToMember)
      .filter(kit => {
        const shipDate = moment(kit.dateShippedToMember).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const kitShippedToLabCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.dateShippedToLab)
      .filter(kit => {
        const shipDate = moment(kit.dateShippedToLab).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const eugeneKitRecollectCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.recollectionRequestedDate && kit.dateShippedToLab)
      .filter(kit => {
        const recollectDate = moment(kit.dateShippedToMember).startOf('day');
        return recollectDate.isSameOrAfter(fromDate) && recollectDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const labKitRecollectCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.recollectionRequestedDate && kit.dateShippedToLab)
      .filter(kit => {
        const recollectDate = moment(kit.dateShippedToMember).startOf('day');
        return recollectDate.isSameOrAfter(fromDate) && recollectDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const overallKitRecollectCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.recollectionRequestedDate)
      .filter(kit => {
        const recollectDate = moment(kit.dateShippedToMember).startOf('day');
        return recollectDate.isSameOrAfter(fromDate) && recollectDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const textStats = React.useMemo(() => {
    const rows = [
      ['Samples shipped to member', kitShippedCount],
      ['Samples failed at Eugene', `${((eugeneKitRecollectCount / kitShippedCount) * 100).toFixed(1)}%`],
      ['Samples failed at lab', `${((labKitRecollectCount / kitShippedCount) * 100).toFixed(1)}%`],
      ['Overall sample failure rate', `${((overallKitRecollectCount / kitShippedCount) * 100).toFixed(1)}%`],
    ]
    return (
      <table className="w-full">
        <tbody>
          {rows.map((row, index) => (
            <tr key={`row-${index}`}>
              <td>{row[0]}</td>
              <td className="text-right font-bold py-10">{row[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [kitShippedCount, kitShippedToLabCount, overallKitRecollectCount, labKitRecollectCount, eugeneKitRecollectCount]);

  return (
    <div>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <div className="text-grey-dark border p-10 rounded text-xs flex items-start">
            <FiInfo className="mr-5" />
            <span>Date range specified filters for the date a kit was shipped to a member, so more recent kits won&apos;t have had time to be recollected yet.</span>
          </div>
          {textStats}
          <h2 className="text-lg my-10 font-bold text-center border-t pt-20 mt-20">Recollection rates (% monthly)</h2>
          <AreaChart width={730} height={500} data={data.items}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis interval={0} allowDecimals={false} />
            <Legend />
            <Tooltip formatter={data.tooltipFormatter} />
            {data.dataKeys.map((dataKey, index) => (
              <Area type="monotone" dataKey={dataKey} fill={colors[index]} stroke={colors[index]} key={dataKey} stackId="1" />
            ))}
          </AreaChart>
        </>
      )}
    </div>
  );
}

export default RecollectionStats;