import * as React from 'react';
import { Journey, Member } from '../../../graphql/genie-api-types';
import Badge, { BadgeProps } from './Badge';

interface MemberFlowStatusBadgeProps {
  journey: Pick<Journey, 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission'> & {
    member?: Required<Pick<Member, 'onlineAccessEnabled'|'dateDetailsConfirmedByMember'>>;
  };
}

type State = 'Not started'|'Incomplete'|'Completed';

const stateColors: {[key in State]: BadgeProps['color']} = {
  'Not started': 'red-light',
  'Completed': 'green-light',
  'Incomplete': 'orange-light',
};


const MemberFlowStatusBadge = (props: MemberFlowStatusBadgeProps) => {
  let state: State = 'Not started';
  
  if (props.journey.hasConsent && props.journey?.hasRelevantFamilyHistoryGroupSubmission) {
    state = 'Completed';
  }
  else if (props.journey?.member?.dateDetailsConfirmedByMember) {
    state = 'Incomplete';
  }
  
  return (
    <Badge
      color={stateColors[state]}
    >
      {state}
    </Badge>
  );
};

export default MemberFlowStatusBadge;
