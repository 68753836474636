import * as React from 'react';
import moment from 'moment';
import GenieQuery from '../../base/GenieQuery';
import gql from 'graphql-tag';
import { Member, QueryMemberArgs, Query } from '../../../../graphql/genie-api-types';
import { groupBy } from 'lodash';

type MemberFamilyHistoryQueryResult = Pick<Member, 'id' | 'name' | 'familyHistoryGroup' | 'dateOfBirth' | 'sex' | 'journeys'>;

type Submission = {
  label: string;
  id: string;
}

export interface MemberFamilyHistoryQueryProps {
  memberId: string;
  children(member: MemberFamilyHistoryQueryResult, submissions: Submission[], isLoading: boolean): React.ReactElement<any>;
}

const MemberFamilyHistoryQuery = (props: MemberFamilyHistoryQueryProps) => (
  <GenieQuery<Pick<Query, 'member'>, QueryMemberArgs>
    query={gql`
      query QueryMember($id: ID!) {
        member(id: $id) {
          id
          name
          sex
          dateOfBirth
          journeys {
            id
          }
          familyHistoryGroup {
            id
            category
            classification
            dateCreated
            dateUpdated
            name
            source
            username
            value
            submissionId
          }
        }
      }
    `}
    notifyOnNetworkStatusChange
    errorPolicy="all"
    variables={{
      id: props.memberId,
    }}
  >
    {(response) => {
      const member = response?.data?.member;
      const submissions = Object.values(groupBy(member?.familyHistoryGroup ?? [], 'submissionId')).map(submission => ({
        label: moment(submission[0].dateCreated).format('Do MMM, YYYY'),
        id: submission[0].submissionId,
      }));
      
      return props.children(member, submissions, response.loading);
    }}
  </GenieQuery>
);

export default MemberFamilyHistoryQuery;
