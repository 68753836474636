import * as React from 'react';
import { curry } from 'lodash';
import { navigate } from '../../../store/router/actions';
import { FiMail, FiPhoneCall } from '../../widgets/Icon';
import { copyToClipboard } from '../../../../utilities/helpers';
import DoctorShowContext from '../../../contexts/DoctorShowContext';
import { useDispatch } from 'react-redux';

const onShowContact = (value: string, e: MouseEvent) => {
  e.preventDefault();
  copyToClipboard(value);
}

const DoctorShowContact = () => {
  const { doctor, doctorId } = React.useContext(DoctorShowContext);
  const dispatch = useDispatch();
  if (!doctor) {
    return null;
  }

  const onEditDoctor = () => dispatch(navigate.toForm('DoctorUpdate', {
    doctorId: doctorId,
  }));

  return (
    <div className="flex">
      <div className="w-1/2 leading-relaxed mt-12 pr-20 mt-40">
        <div className="bg-grey-light px-14 pt-8 pb-10 rounded">
          <div className="text-grey">Contact {doctor.nickname}</div>
          <span className="flex flex-row flex-nowrap py-3">
            <FiMail className="w-5 h-5 align-text-bottom mr-12 mt-2 text-grey" />
            {doctor.personalEmail ?
              <button className="active:outline-none hover:underline" onClick={curry(onShowContact)(doctor.personalEmail)}>
                {doctor.personalEmail }
              </button>
              : <button className="text-orange active:outline-none hover:underline" onClick={onEditDoctor}>Add personal email</button>
            }
          </span>
          <span className="flex flex-row flex-nowrap py-3">
            <FiPhoneCall className="w-5 h-5 align-text-bottom mr-12 mt-2 text-grey" />
            {doctor.personalPhone ?
              <button className="active:outline-none hover:underline" onClick={curry(onShowContact)(doctor.personalPhone)}>
                {doctor.personalPhone}
              </button>
              : <button className="text-orange active:outline-none hover:underline" onClick={onEditDoctor}>Add personal phone number</button>
            }
          </span>
        </div>
      </div>

    </div>
  );
}

export default DoctorShowContact;
