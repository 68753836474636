import * as React from 'react';
import { KitScope, Mutation, MutationUpdateKitArgs, ListKitsInput, Kit, Journey, Member, Request, Pregnancy, Note } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { getProductIcon } from '../../../utilities/journeys/products';
import { useMutation } from 'react-apollo';
import { appSyncClient } from '../../../utilities/appSync';
import Screen from '../../components/widgets/Screen';
import OpsKitsTable from '../../graphql/containers/ops/OpsKitsTable';
import moment from 'moment';
import MemberFlowStatusBadge from '../../components/widgets/MemberFlowStatusBadge';

interface OpsLabShippingJourney extends Required<Pick<Journey, 'id' | 'state' | 'hasRecollection' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission'>> {
  member: Required<Pick<Member, 'id'|'name'|'sex'|'onlineAccessEnabled'|'dateDetailsConfirmedByMember'>>|null;
  request: Required<Pick<Request, 'id' | 'datePaid' | 'onHoldDate' | 'product' | 'productVariant' | 'gcApprovalDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'>>;
    })[];
    notes: Pick<Note, 'text'>[];
  };
}

interface OpsLabShippingKit extends Pick<Kit, 'id'|'dateArrivedAtEugene'|'registrationNumber'> {
  journey: OpsLabShippingJourney;
}

const OPS_MEMBER_SHIPPING_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        cursor
        node {
          id
          registrationNumber
          journey {
            id
            state
            hasRecollection
            hasConsent
            hasRelevantFamilyHistoryGroupSubmission
            member {
              id
              name
              sex
              onlineAccessEnabled
              dateDetailsConfirmedByMember
            }
            request {
              id
              datePaid
              onHoldDate
              product
              productVariant
              initiatingMember {
                id
              }
              pregnancy {
                dueDate
                dueDateConfirmed
                days
              }
              journeys {
                id
                state
                member {
                  id
                  name
                }
              }
              notes(importantOnly: true) {
                text
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalRows
    }
  }
`;

const OPS_MEMBER_SHIPPING_SHIPPED_MUTATION = gql`
  mutation UpdateKit($input: UpdateKitInput!) {
    updateKit(input: $input) {
      kit {
        id
      }
    }
  }
`;

const queryInput: ListKitsInput = {
  scope: KitScope.ReadyForMemberShipping,
};

const markAsShippedAction = 'markAsShipped';

const OpsMemberShipping = () => {
  const [mutate] = useMutation<Pick<Mutation, 'updateKit'>, MutationUpdateKitArgs>(OPS_MEMBER_SHIPPING_SHIPPED_MUTATION, {
    client: appSyncClient,
  });

  const onActionClick = React.useCallback(async (kitIds: string[], actionName: string) => {
    switch (actionName) {
      case markAsShippedAction: {
        await Promise.all(kitIds.map(kitId => mutate({
          variables: {
            input: {
              kitId,
              shippedToMember: true,
            },
          },
        })));
      }
        break;
    }
  }, [mutate]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Member shipping',
      }}
    >
      <OpsKitsTable<OpsLabShippingKit>
        query={OPS_MEMBER_SHIPPING_QUERY}
        queryInput={queryInput}
        header={[
          'Purchased',
          'Product',
          'Member flow'
        ]}
        cellsForJourney={journey => [
          moment(journey.request.datePaid).format('D MMM YYYY'),
          getProductIcon(journey.request.product, journey.request.productVariant),
          <MemberFlowStatusBadge journey={journey} key="flow-status" />
        ]}
        actionsForJourney={() => [
          {
            label: 'Shipped',
            name: markAsShippedAction,
            variant: 'success',
          },
        ]}
        onActionClick={(rowIds, actionName) => onActionClick(rowIds, actionName)}
      />
    </Screen>
  );
};

export default OpsMemberShipping;
