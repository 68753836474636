import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { startCase, isEqual } from 'lodash';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { TestInformationState } from '../../../store/report/test-information/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import { connect } from 'react-redux';
import { PATHS } from '../../../store/router/types';
import EditArea from '../../../components/widgets/EditArea';
import { initialState } from '../../../store/report/test-information/reducer';
import { navigate } from '../../../store/router/actions';
import { getProductLabel, getVariantLabel } from '../../../../utilities/journeys/products';
import { Product, ProductVariant } from '../../../../graphql/genie-api-types';

interface TestInformationViewProps extends TaskListComponentProps {
  onEdit(): void;
  fields: TestInformationState;
  product: string;
}

const TestInformationView = (props: TestInformationViewProps) => {
  const fields = Object.keys(props.fields)
    .filter(fieldName => !(props.product === Product.Cancer && fieldName === 'numberOfXLinkedGenesTested'))
    .map((fieldName: string) => {
    let fieldContent: React.ReactNode;
    switch (fieldName) {
      case 'product':
        fieldContent = (
          <div className="mb-10">{getProductLabel(props.fields[fieldName])}</div>
        );
        break;

      case 'productVariant':
        fieldContent = (
          <div className="mb-10">{getVariantLabel(props.fields[fieldName] as ProductVariant)}</div>
        );
        break;

      default:
        fieldContent = (
          <ReactMarkdown
            source={props.fields[fieldName as keyof TestInformationState] || '-'}
          />
        );
    }
    return (
      <div key={fieldName}>
        <h4 className="font-bold">{startCase(fieldName)}</h4>
        {fieldContent}
      </div>
    );
  });

  return (
    <div>
      <TaskListScreenTitle
        title="Test Information"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      <EditArea
        onClick={props.onEdit}
        isOverridden={!isEqual(props.fields, initialState)}
      >
        {fields}
      </EditArea>
    </div>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => ({
  product: report.request.request?.product,
  fields: report.testInformation,
  isComplete: report.taskList.completedTasks.testInformation,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onEdit: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_TEST_INFORMATION_EDIT)),
  onToggleComplete: () => dispatch(toggleTask('testInformation')),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestInformationView);
