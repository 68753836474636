import gql from 'graphql-tag';
import * as React from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-apollo';
import { SALES_LEAD_FRAGMENT } from '../../../../graphql/fragments/leads';
import { Lead, MessageMethod, MessageType, Mutation, MutationCreateLeadNoteArgs, MutationCreateNotificationArgs, Note as NoteType, NotificationEntity, QueryLeadArgs } from '../../../../graphql/genie-api-types';
import { Collapsible } from '../../../components/widgets/Collapsible';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import Note from '../../../components/widgets/Note';
import LeadsClose from './LeadsClose';
import NoteForm from '../../../components/notes/NoteForm';
import { getProductLabel } from '../../../../utilities/journeys/products';
import NotificationForm, { NotificationFormPayload } from '../../../components/notifications/NotificationForm';
import NotificationRow from '../../../components/notifications/NotificationRow';
import EditableField from '../../../components/widgets/EditableField';
import { appSyncClient } from '../../../../utilities/appSync';

interface LeadInfoAndCloseProps {
  leadId: Lead['id'];
  onComplete(): void;
}

const LEAD_QUERY = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      ...LeadParts
      source
      additionalData
      notes(importantOnly: false) {
        createdBy {
          id
          email
          counsellorDetails {
            id
            nickname
          }
        }
        date
        isImportant
        text
      }
      notifications {
        id
        content
        dateSent
        destination
        method
        subject
        type
      }
    }
  }
  ${SALES_LEAD_FRAGMENT}
`;

const LEAD_FIELD_UPDATE_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      lead {
        id
        ...LeadParts
      }
    }
  }
  ${SALES_LEAD_FRAGMENT}
`;

type LeadPartial = Lead;

type LeadResult = { lead: LeadPartial };

interface LeadFieldRowProps {
  label: string;
  value?: string;
  children?: React.ReactNode;
  onSaveValue?(fieldName: string, fieldValue: string): Promise<void>;
  saveFieldName?: string;
  placeholder?: string;
}

const LeadFieldRow = (props: LeadFieldRowProps) => {
  const { onSaveValue, placeholder, saveFieldName} = props;
  
  const onSave = React.useCallback(async(value: string) => {
    await onSaveValue(saveFieldName, value);
  }, [onSaveValue, saveFieldName])

  return (
    <div className="mb-20 flex-1 break-all">
      <div className="font-bold text-xs text-grey-dark uppercase mb-5">{props.label}</div>
      <div className="bg-white border-b p-10">
        {onSaveValue ? (
          <EditableField
            initialValue={props.value}
            onSubmit={onSave}
            type="text"
            placeholder={placeholder}
          />
        ) : (props.value || props.placeholder)}
        {props.children}
      </div>
    </div>
  );
}

const ColumnSpacer = () => <span style={{ width: '20px' }} />;

const LeadInfoAndClose = (props: LeadInfoAndCloseProps) => {
  const { leadId, onComplete } = props;

  const leadQuery = useQuery<LeadResult, QueryLeadArgs>(LEAD_QUERY, {
    variables: {
      id: leadId,
    },
  });

  const [createNoteMutate, createNoteMutateQuery] = useMutation<Pick<Mutation, 'createLeadNote'>, MutationCreateLeadNoteArgs>(gql`
     mutation CreateLeadNote($input: CreateLeadNoteInput!) {
        createLeadNote(input: $input) {
          note {
            text
          }
        }
      }
  `);

  const [createNotificationMutate, createNotificationMutateQuery] = useMutation<Pick<Mutation, 'createNotification'>, MutationCreateNotificationArgs>(gql`
     mutation CreateNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
          notification {
            id
          }
        }
      }
  `);

  const lead = React.useMemo(() => {
    return leadQuery.data?.lead;
  }, [leadQuery.data]);
  
  const onLeadReload = React.useCallback(async () => {
    await leadQuery.refetch({ id: leadId });
  }, [leadId, leadQuery.refetch]);

  const onNoteFormSubmit = React.useCallback(async (noteValues: Pick<NoteType, 'text' | 'isImportant'>) => {
    await createNoteMutate({
      variables: {
        input: {
          ...noteValues,
          leadId,
        },
      },
    });
    await onLeadReload();
  }, [createNoteMutate, leadId, onLeadReload]);

  const onNotificationFormSubmit = React.useCallback(async (notificationValues: NotificationFormPayload) => {
    const destination = notificationValues.method === MessageMethod.Email ? lead.email : lead.phone;
    await createNotificationMutate({
      variables: {
        input: {
          ...notificationValues,
          id: leadId,
          entity: NotificationEntity.Lead,
          destination: destination,
          type: MessageType.CustomExternalMessage,
        },
      },
    });
    await onLeadReload();
  }, [createNotificationMutate, leadId, lead, onLeadReload]);
  
  const onUpdateLeadFieldValue = React.useCallback(async (fieldName: string, fieldValue: string) => {
    await appSyncClient.mutate<Pick<Mutation, 'updateLead'>>({
      mutation: LEAD_FIELD_UPDATE_MUTATION,
      variables: {
        input: {
          [fieldName]: fieldValue,
          id: leadId,
        },
      },
    });
  }, [leadId, onLeadReload]);

  if (leadQuery.loading) {
    return <LoadingOverlay />;
  }

  if (!lead) {
    return <div>No lead found</div>;
  }

  return (
    <div>
      <div className="pb-10">
        <LeadFieldRow
          label="Name"
          value={lead.name}
          saveFieldName="name"
          onSaveValue={onUpdateLeadFieldValue}
        />
        <div className="flex max-w-full">
          <LeadFieldRow
            label="Email"
            value={lead.email}
            saveFieldName="email"
            onSaveValue={onUpdateLeadFieldValue}
            placeholder="No email"
          />
          <ColumnSpacer />
          <LeadFieldRow
            label="Phone"
            value={lead.phone}
            saveFieldName="phone"
            onSaveValue={onUpdateLeadFieldValue}
            placeholder="No phone"
          />
        </div>
        <div className="flex max-w-full">
          <LeadFieldRow label="Product" value={getProductLabel(lead.product)} />
          <ColumnSpacer />
          <LeadFieldRow label="Source" value={lead.source} placeholder="No source" />
        </div>
        <div className="flex max-w-full">
          <LeadFieldRow label="Entered" value={moment(lead.dateCreated).fromNow()} />
          <ColumnSpacer />
          <LeadFieldRow label="Last contacted" value={moment(lead.dateLastContact).fromNow()} />
        </div>
        <LeadFieldRow label="Follow-ups">
          <NotificationForm
            isLoading={createNotificationMutateQuery.loading}
            onSubmit={onNotificationFormSubmit}
          />
          {lead.notifications?.map((notification) => (
            <NotificationRow
              key={notification.id}
              notification={notification}
              className="mt-20"
            />
          ))}
        </LeadFieldRow>
        <LeadFieldRow label="Notes">
          <NoteForm
            isLoading={createNoteMutateQuery.loading}
            onSubmit={onNoteFormSubmit}
          />
          {lead.notes?.map(note => (
            <Note
              note={note}
            />
          ))}
        </LeadFieldRow>
      </div>
      <Collapsible
        defaultExpandedState={false}
        title="Close lead"
        headingLevel={3}
        className="bg-white p-10"
      >
        <LeadsClose
          leadIds={[leadId]}
          showLeadDetails={false}
          onComplete={onComplete}
        />
      </Collapsible>
    </div>
  );
};

export default LeadInfoAndClose;
