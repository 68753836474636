import * as React from 'react';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { RouteProps } from 'react-router';
import { navigate } from '../../../store/router/actions';
import { PATHS } from '../../../store/router/types';
import Modal from '../../../components/widgets/Modal';
import { getCompiledTemplateVariablesWithSectionOverrides } from '../../../../utilities/report/templates/getCompiledTemplateVariablesWithSectionOverrides';
import { TemplateSection } from '../../../../utilities/report/templates';
import CustomisationForm from '../../../components/report/customisation/CustomisationForm';
import { FormikValues } from 'formik';
import { changeSectionVariables } from '../../../store/report/customisation/actions';

interface StateProps {
  section: TemplateSection;
  pageId: string;
  templateName: string;
}

interface DispatchProps {
  onClose(): void;
  dispatch: ThunkDispatch<{}, {}, any>;
}

interface CustomisationEditProps extends StateProps, DispatchProps, RouteProps {
  onEdit(sectionVariables: FormikValues): void;
}

const CustomisationEdit = (props: CustomisationEditProps) => (
  <Modal
    show
    onHide={props.onClose}
    title="Customisation"
  >
    <CustomisationForm
      section={props.section}
      onSubmit={props.onEdit}
    />
  </Modal>
);

const mapStateToProps = ({ report }: ApplicationState, ownProps: RouteProps) => {
  const { pageId, sectionId } = queryString.parse(ownProps.location.search) as { pageId: string; sectionId: string };

  const compiledPageVariables = getCompiledTemplateVariablesWithSectionOverrides(report.customisation.templateName, report);
  const page = compiledPageVariables.find(page => page.id === pageId);

  const section = page.sections.find(section => section.id === sectionId);
  return {
    section,
    pageId,
    templateName: report.customisation.templateName,
    sectionOverrides: report.customisation.sectionVariableOverridesByTemplate[report.customisation.templateName],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  dispatch,
  onClose: () => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CUSTOMISATION)),
});

const mergeProps = (stateProps: StateProps, { dispatch, ...dispatchProps }: DispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onEdit: (sectionVariables: FormikValues) => {
    dispatch(changeSectionVariables(stateProps.templateName, stateProps.pageId, stateProps.section.id, sectionVariables));
    dispatchProps.onClose();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomisationEdit);
