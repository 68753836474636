import * as React from 'react';
import { useQuery } from 'react-apollo';
import { diff } from 'deep-object-diff';

import { getReportPathParams } from '../../../store/router/actions';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import gql from 'graphql-tag';
import { QueryRequestArgs, Query, Report } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { ReportState } from '../../../store/report';
import { showLoadingToast, hideToast } from '../../../store/toast/actions';
import saveReportForRequestId from '../../../graphql/requests/saveReportForRequestId';
import Button from '../../../components/widgets/Button';
import { useDispatch } from 'react-redux';

const REQUEST_REPORT_VERSIONS_QUERY = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      report {
        id
        data
        username
        dateCreated
        versions {
          data
          username
          dateCreated
        }
      }
    }
  }
`;

interface VersionData extends Pick<Report, 'id' | 'dateCreated' | 'username'> {
  data: ReportState;
}

const UpdatesView = () => {
  const { requestId } = getReportPathParams();
  const variables: QueryRequestArgs = {
    id: requestId,
  };
  
  const dispatch = useDispatch();

  const { loading, data, refetch } = useQuery<Pick<Query, 'request'>, QueryRequestArgs>(REQUEST_REPORT_VERSIONS_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables,
  });

  const versions: VersionData[] = React.useMemo(() => {
    const currentVersionData: VersionData = {
      ...data?.request?.report,
      data: JSON.parse(data?.request?.report?.data ?? '{}') as ReportState,
    };

    const previousVersionData: VersionData[] = data?.request?.report?.versions?.map(version => ({
      ...version,
      data: JSON.parse(version?.data ?? '{}') as ReportState,
    })) ?? [];

    return [
      currentVersionData,
      ...previousVersionData,
    ];
  }, [data]);
  
  const restoreVersion = React.useCallback(async (requestId: string, reportState: ReportState) => {
    const toastId = 'restoringVersion';
    dispatch(showLoadingToast(toastId, 'Restoring report version', 'Saving to server...'));
    
    await saveReportForRequestId(requestId, reportState);
    await refetch(variables);
    window.location.reload();

    dispatch(hideToast(toastId));
  }, [refetch, dispatch]);

  return (
    <div>
      <TaskListScreenTitle
        title="Update history"
        isSelected={true}
      />
      {loading ? (
        <LoadingOverlay />
      ) : (
          <div>
            <div className="flex p-10 font-bold">
              <div className="w-full">Diff (latest to oldest)</div>
            </div>
            {versions.map((version, index) => (
              <div className=" bg-grey-light mb-2 p-10 flex text-sm group" key={`${version.id}-${index}`}>
                <div className="font-mono" style={{ maxWidth: `500px` }}>
                  {JSON.stringify(diff((versions?.[index + 1]?.data ?? {}), version.data))}
                </div>
                <div className="hidden group-hover:block ml-10 flex-1 text-right">
                  <Button
                    onClick={() => restoreVersion(requestId, version.data)}
                    size="sm"
                  >
                    Restore
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default UpdatesView;