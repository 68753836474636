import * as React from 'react';
import { Admin, Counsellor, Note as NoteType } from '../../../graphql/genie-api-types';
import { shorthandFromNow } from '../../../utilities/helpers';
import PrivateContent from './PrivateContent';

interface NoteProps {
  note: Pick<NoteType, 'username' | 'isImportant' | 'text' | 'date'> & {
    createdBy?: Pick<Admin, 'id' | 'email'> & {
      counsellorDetails?: Pick<Counsellor, 'nickname'>;
    };
  };
}

const Note = ({ note }: NoteProps) => (
  <div className={`flex items-start py-10 my-5 border-b text-sm last:mb-20`}>
    <div className="flex-1">
      <p className={note.isImportant ? 'bg-orange-light px-4 py-3 rounded-sm mb-5' : ''}>
        <PrivateContent>
          {note.text}
        </PrivateContent>
      </p>
      {note.username && (
        <div className="text-grey text-xs mt-2">{note.username}</div>
      )}
      {note.createdBy && (
        <div className="text-grey text-xs mt-2">{note.createdBy?.counsellorDetails?.nickname ?? note?.createdBy?.email}</div>
      )}
    </div>
    <div className={`pl-10 pt-5 text-xs text-right text-grey ${note.isImportant ? 'font-bold text-orange' : ''}`}>{shorthandFromNow(note.date)}</div>
  </div>
);

export default Note;
