import * as React from 'react';
import useToggleState from '../../../utilities/useToggleState';
import { FiCheck, FiChevronDown, FiChevronRight } from './Icon';

interface ChecklistCollapsibleProps {
  title: string;
  defaultChecked?: boolean;
  defaultOpen?: boolean;
  children: React.ReactNode;
  className?: string;
  checkLabel?: string;
}

const HEADER_STYLE: React.CSSProperties = {
  top: '20px',
};

const ChecklistCollapsible = (props: ChecklistCollapsibleProps) => {
  const [isChecked, toggleIsChecked] = useToggleState(props.defaultChecked ?? false);
  const [isOpen, toggleIsOpen, setIsOpen] = useToggleState(props.defaultOpen ?? true);

  const onCheck = React.useCallback(() => {
    if (!isChecked) {
      setIsOpen(false);
    }

    toggleIsChecked();
  }, [toggleIsChecked, isChecked, setIsOpen]);

  const IconComponent = isOpen ? FiChevronDown : FiChevronRight;

  return (
    <div className={`${props.className} border ${isChecked ? '' : ''}`}>
      <div className={`flex justify-between text-lg bg-grey-light border-b sticky z-100 transition-all ${isChecked ? 'bg-green-lightest text-green-dark' : 'text-grey-dark'}`} style={HEADER_STYLE}>
        <button className="flex focus:outline-none flex-1 p-20" onClick={toggleIsOpen}>
          <IconComponent className={`w-4 h-4 align-middle text-5xl mr-10`} />
          <h2 className="font-bold">{props.title}</h2>
        </button>
        <button className={`ml-auto mr-0 focus:outline-none flex items-center p-20 ${isChecked ? '' : 'hover:text-green-dark'}`} onClick={onCheck}>
          <span className="mr-10">{props.checkLabel}</span> <FiCheck />
        </button>
      </div>
      {isOpen && (
        <div className="p-20">
          {props.children}
        </div>
      )}
    </div>
  );
};

export default ChecklistCollapsible;
