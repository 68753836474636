import * as React from 'react';
import PrivacyContext, { PrivacyContextValue } from '../../base/PrivacyContext';

interface PrivateContentProps {
  children: React.ReactNode;
  className?: string;
}

const PrivateContent = (props: PrivateContentProps) => {
  const privacyContext = React.useContext<PrivacyContextValue>(PrivacyContext);
  if (privacyContext.isPrivateModeActive) {
    return (
      <span className={props.className}>{Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}</span>
    );
  }
  return <>{props.children}</>
};

export default React.memo(PrivateContent);
