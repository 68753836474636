import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Journey, Kit, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import KitTag from '../../kit/KitTag';

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      kits {
        id
        recollectionRequestedDate
        registrationNumber
      }
    }
  }
`;

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    kits: Pick<Kit, 'id'|'recollectionRequestedDate'|'registrationNumber'>[];
  };
}

const JourneyShowKitList = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const journey = journeyQuery?.data?.journey;
  
  if (!journey) {
    return null;
  }

  const kits = journey?.kits ?? [];

  if (kits.length > 0) {
    return (
      <>
        {kits.map((kit, index) => (
          <div key={index} className="text-xs text-grey font-nm mt-8 text-right">Kit #: <KitTag kit={kit} /></div>
        ))}
      </>
    );
  }

  return null;
};

export default JourneyShowKitList;
