import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import AppointmentProjection from '../../components/journey/journey-rows/AppointmentProjection';
import Screen from '../../components/widgets/Screen';

const AppointmentsProjection = () => {
 
  return (
    <Screen>
      <Heading level={1} className="mb-20">GC Appointments: Weekly projection</Heading>        
      <AppointmentProjection />
    </Screen>
  )
};

export default AppointmentsProjection;
