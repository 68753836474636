import * as React from 'react';
import Table from '../../../components/widgets/Table';
import { AppDispatch } from '../../../store';
import { navigate } from '../../../store/router/actions';
import { connect } from 'react-redux';
import { ListClinicsInput } from '../../../../graphql/genie-api-types';
import FilterFormProps, { FilterOption } from '../../../components/widgets/FilterForm';
import ClinicList from "../../../graphql/containers/clinics/ClinicList";

interface DispatchProps {
  onGoToClinic(clinicId: string): void;
}

const filterOptions: FilterOption[] = [
  {
    type: 'text',
    name: 'clinicNameSearch' as keyof ListClinicsInput,
    placeholder: 'Clinic name',
  },
  {
    type: 'text',
    name: 'addressSearch' as keyof ListClinicsInput,
    placeholder: 'Address',
  },
];

const ClinicListTable = (props: DispatchProps) => {
  const [searchParams, setSearchParams] = React.useState({} as ListClinicsInput);
  
  return (
    <div>
      <FilterFormProps
        options={filterOptions}
        onPress={setSearchParams}
      />
      <ClinicList searchParams={searchParams}>
        {(clinics, isLoading, reloadData) => (
          <Table
            header={['Name', 'Address']}
            isLoading={isLoading}
            onRefresh={reloadData}
            onRowSelect={props.onGoToClinic}
            rowGroups={clinics?.map(clinic => ({
              id: clinic.id,
              rows: [{
                id: clinic.id,
                cells: [
                  clinic.name,
                  `${clinic.addressStreet ?? ''}${clinic.addressSuburb ? ` (${clinic.addressSuburb})` : ''}${clinic.addressState ? ` (${clinic.addressState})` : ''}${clinic.addressPostcode ? ` (${clinic.addressPostcode})` : ''}`
                ],
              }]
            })) ?? []}
          />
        )}
      </ClinicList>
    </div>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onGoToClinic: (clinicId: string) => dispatch(navigate.toClinic(clinicId)),
});

export default connect<{}, DispatchProps, {}>(null, mapDispatchToProps)(ClinicListTable);
