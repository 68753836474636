import { action } from 'typesafe-actions';

import { StorageActionTypes } from './types';
import { ApplicationState } from '..';

export const changeStorageKey = (encryptionKey: string) =>
  action(StorageActionTypes.CHANGE_STORAGE_KEY, encryptionKey);

export const mergeApplicationState = (store: Partial<ApplicationState>) =>
  action(StorageActionTypes.MERGE_APPLICATION_STATE, store);
