import * as React from 'react';
import { merge, get } from 'lodash';
import { Pagination, PageInfo } from '../../../graphql/genie-api-types';
import Maybe from 'graphql/tsutils/Maybe';
import { appSyncClient } from '../../../utilities/appSync';
import { ApolloQueryResult, QueryOptions } from 'apollo-client';
import Pager from '../../components/widgets/Pager';
import ReduxQueryListener, { ListenerEvent } from '../../store/ReduxQueryListener';
import { QueryFunctionOptions } from 'react-apollo';

interface PagedQueryVariables {
  input: {
    pagination?: Maybe<Pagination>;
  };
}

interface GeniePagedQueryProps<Data, Vars> extends QueryFunctionOptions<Data, Vars> {
  query: any;
  itemsPerPage: number;
  children: (data: Data, isLoading: boolean, reloadData: () => Promise<void>) => React.ReactNode;
  variables: Vars & PagedQueryVariables;
  pageInfoSelector: string;
  edgeSelector: string;
  refetchListenerEvents?: ListenerEvent[];
}

interface GeniePagedQueryState<Data> {
  currentCursor: string;
  direction: 'after' | 'before';
  isLoading: boolean;
  result?: ApolloQueryResult<Data>;
  pageInfo?: PageInfo;
}

interface Edge {
  cursor: string;
  node?: any;
}

class GeniePagedQuery<Data, Vars> extends React.PureComponent<GeniePagedQueryProps<Data, Vars>, GeniePagedQueryState<Data>> {
  private wrapperRef = React.createRef<HTMLDivElement>();

  state: Readonly<GeniePagedQueryState<Data>> = {
    currentCursor: '',
    direction: 'after',
    isLoading: true,
    result: null,
    pageInfo: null,
  };

  static defaultProps = {
    fetchPolicy: 'network-only',
  };

  componentDidMount() {
    this.fetch();
    
    ReduxQueryListener.addCallbackListener(this.onQueryListenerCallback);
  }
  
  componentWillUnmount() {
    ReduxQueryListener.removeCallbackListener(this.onQueryListenerCallback)
  }

  componentDidUpdate(prevProps: any, prevState: GeniePagedQueryState<Data>) {
    const { currentCursor, direction } = this.state;
    const { variables } = this.props;
    if (currentCursor !== prevState.currentCursor || direction !== prevState.direction) {
      this.fetch();
    }
    
    if (prevProps.variables !== variables) {
      this.fetch();
    }
  }

  getPagedVariables(): Vars {
    const { itemsPerPage, variables } = this.props;
    const { currentCursor, direction } = this.state;

    const pagination: Pagination = {};

    if (currentCursor === '') {
      pagination.first = itemsPerPage;
    } else if (direction === 'after') {
      pagination.first = itemsPerPage;
      pagination.after = currentCursor;
    } else if (direction === 'before') {
      pagination.last = itemsPerPage;
      pagination.before = currentCursor;
    }

    const pagedVars: PagedQueryVariables = {
      input: {
        pagination,
      },
    };

    return merge({}, variables, pagedVars);
  }

  getEdges(): Edge[] {
    const { result } = this.state;
    const { edgeSelector } = this.props;
    const edges: Edge[] = get(result, `data.${edgeSelector}`, []);

    return edges;
  }

  fetch = async () => {
    const { query, fetchPolicy, pageInfoSelector } = this.props;
    const variables = this.getPagedVariables();
    this.setState({
      isLoading: true,
    });

    try {
      const result = await appSyncClient.query<Data, Vars>({
        query,
        variables,
        fetchPolicy,
      } as QueryOptions<Vars>);

      const pageInfo: PageInfo = get(result, `data.${pageInfoSelector}`, null);

      this.setState({
        result,
        pageInfo,
      });
    } catch (e) {
      console.warn(e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }
  
  scrollToTop() {
    if (!this.wrapperRef.current) {
      return;
    }

    window.scroll({
      top: this.wrapperRef.current.offsetTop,
      behavior: 'smooth',
    });
  }
  
  onQueryListenerCallback = (event: ListenerEvent) => {
    const { refetchListenerEvents } = this.props;
    if (refetchListenerEvents?.includes(event)) {
      this.fetch();
    }
  }

  onPreviousPage = () => {
    const edges = this.getEdges();
    if (edges.length === 0) {
      return;
    }

    this.setState({
      currentCursor: edges[0].cursor,
      direction: 'before',
    });
    this.scrollToTop();
  }

  onNextPage = () => {
    const edges = this.getEdges();
    if (edges.length === 0) {
      return;
    }

    this.setState({
      currentCursor: edges[edges.length - 1].cursor,
      direction: 'after',
    });
    this.scrollToTop();
  }

  render() {
    const { children } = this.props;
    const { result, pageInfo, isLoading } = this.state;

    return (
      <div ref={this.wrapperRef}>
        {children(result?.data, isLoading, this.fetch)}
        <Pager
          pageInfo={pageInfo}
          onClickPreviousPage={this.onPreviousPage}
          onClickNextPage={this.onNextPage}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default GeniePagedQuery;
