import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import Modal from '../components/widgets/Modal';
import SwitchForm, { FormName } from '../graphql/containers/SwitchForm';

interface FormModalState {
  form?: FormName;
  formParams?: any;
}

class FormModal extends React.PureComponent<RouteComponentProps, FormModalState> {
  state: Readonly<FormModalState> = {
    form: null,
    formParams: {},
  };

  static getDerivedStateFromProps(props: RouteComponentProps, state: FormModalState) {
    const query = queryString.parse(props.history.location.search);

    const formParams = query.formParams ? JSON.parse(query.formParams as string) : {};

    return {
      ...state,
      formParams,
      form: query.form,
    };
  }

  onHide = () => {
    const { history } = this.props;
    const query = queryString.parse(history.location.search);

    this.setState({
      form: null,
    });

    delete query['form'];
    delete query['formParams'];

    this.props.history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(query),
    });
  }

  onRedirectToForm = (formName: FormName, formParams?: any) => {
    const { history } = this.props;
    history.push({
      search: queryString.stringify({
        form: formName,
        formParams: JSON.stringify(formParams),
      }),
    });
  }

  render() {
    const { form, formParams } = this.state;

    return (
      <Modal
        show={!!form}
        onHide={this.onHide}
      >
        <SwitchForm
          form={form}
          formParams={formParams}
          onComplete={this.onHide}
          onCancel={this.onHide}
          onRedirectToForm={this.onRedirectToForm}
        />
      </Modal >
    );
  }
}

export default withRouter(FormModal);
