import * as React from 'react';
import './styles/modal.scss';
import Button, { ButtonProps } from './Button';
import { FiX } from './Icon';

export type ModalAction = {
  id: string;
  label: string;
  variant?: ButtonProps['variant'];
};

interface ModalProps {
  show?: boolean;
  onHide(): void;
  title?: string;
  children: React.ReactNode;
  className?: string;
  width?: 'md';
  actions?: ModalAction[];
  onActionClick?(actionId: string): void;
}

class Modal extends React.PureComponent<ModalProps> {
  static defaultProps = {
    width: 'md',
  };

  renderHeader() {
    const { title, onHide } = this.props;
    return (
      <div>
        <div className="text-right">
          <button className="appearance-none border-0 pt-20 px-20 text-grey" onClick={onHide}>
            <FiX />
          </button>
        </div>
        {title ? (
          <h2 className="text-2xl font-bold px-20 pt-15">{title}...</h2>
        ) : null}
      </div>
    );
  }

  renderActions() {
    const { actions, onActionClick } = this.props;
    if (!actions) {
      return null;
    }

    return (
      <div className="flex items-center border-t p-20">
        {actions.map(action => (
          <Button key={action.id} onClick={() => onActionClick(action.id)}>
            {action.label}
          </Button>
        ))}
      </div>
    );
  }

  render() {
    const { children, className, show, onHide, width } = this.props;

    return (
      <div className={`${show ? 'flex' : 'hidden'} fixed top-0 bottom-0 left-0 right-0 z-100 items-stretch justify-end ${className || ''}`}>
        <div className={`fixed top-0 bottom-0 left-0 right-0 bg-trans-black z-0 ${show ? 'modal__overlay-open' : ''}`} onClick={onHide}></div>
        <div className={`relative z-100 flex items-stretch max-h-full ${show ? 'modal__content-open' : ''}`}>
          <div className={`bg-grey-light border shadow-lg w-screen max-w-${width} mx-auto flex flex-col`}>
            {this.renderHeader()}
            <div className="p-20 overflow-y-auto flex-1 pt-15">
              {children}
            </div>
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
