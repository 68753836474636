import gql from 'graphql-tag';
import * as React from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-apollo';
import { Journey, Kit, Member, Mutation, MutationUpdateRequestArgs, Note, Query, QueryRequestArgs, Request } from '../../../graphql/genie-api-types';
import JourneyConsent from '../../graphql/containers/journeys/JourneyConsent';
import CreateRequestNote from '../../graphql/containers/requests/CreateRequestNote';
import RequestNotes from '../../graphql/containers/requests/RequestNotes';
import FamilyHistory from '../family-history/FamilyHistory';
import NoteForm from '../notes/NoteForm';
import ChecklistCollapsible from '../widgets/ChecklistCollapsible';
import NoteComponent from '../widgets/Note';
import LoadingOverlay from '../widgets/LoadingOverlay';
import MemberDetails from '../member/MemberDetails';
import { isTwoPersonTest } from '../../../utilities/request/request-helpers';
import Alert from '../widgets/Alert';
import Button from '../widgets/Button';
import ZendeskIcon from '../widgets/icons/ZendeskIcon';
import { FiCalendar } from '../widgets/Icon';
import { getAcuitySearchUrl, getZendeskSearchUrl } from '../../../utilities/helpers';
import MemberOrPlaceholder from '../member/MemberOrPlaceholder';
import PrivateContent from '../widgets/PrivateContent';
import { navigate } from '../../store/router/actions';
import { useDispatch } from 'react-redux';
import { PATHS } from '../../store/router/types';

interface PreTestRequestJourney extends Pick<Journey, 'id' | 'hasConsent'> {
  request: PreTestRequest;
  member: Pick<Member, 'id' | 'dateDetailsConfirmedByMember' | 'name'>;
  kits: Pick<Kit, 'id' | 'registrationNumber' | 'recollectionRequestedDate'>[];
}

interface PreTestRequest extends Pick<Request, 'id' | 'product' | 'productVariant' | 'datePaid' | 'dateDetailsConfirmedByMember' | 'onHoldDate'> {
  journeys: PreTestRequestJourney[];
}

interface ApproveRequestProps {
  requestId: Request['id'];
}

const REQUEST_QUERY = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      product
      productVariant
      datePaid
      dateDetailsConfirmedByMember
      onHoldDate
      gcApprovalDate
      notes {
        text
        date
        username
        isImportant
      }
      journeys {
        id
        hasConsent
        member {
          id
          dateDetailsConfirmedByMember
          name
          sex
          nickname
          dateOfBirth
          email
          phoneNumber
          familyHistoryGroup {
            id
            category
            classification
            dateCreated
            dateUpdated
            name
            source
            username
            value
            submissionId
          }
        }
        kits {
          id
          registrationNumber
          recollectionRequestedDate
        }
      }
    }
  }
`;

const APPROVE_REQUEST_MUTATION = gql`
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      request {
        id
        gcApprovalDate
      }
    }
  }
`;

const ApproveRequest = (props: ApproveRequestProps) => {
  const requestQuery = useQuery<Pick<Query, 'request'>, QueryRequestArgs>(REQUEST_QUERY, {
    variables: {
      id: props.requestId,
    },
  });
  const dispatch = useDispatch();
  const [approveRequestMutation, approveRequestMutationResult] = useMutation<Pick<Mutation, 'updateRequest'>, MutationUpdateRequestArgs>(APPROVE_REQUEST_MUTATION);

  const request = requestQuery?.data?.request;
  const journeys = request?.journeys ?? [];

  const submitRequestApproval = React.useCallback(() => {
    approveRequestMutation({
      variables: {
        input: {
          requestId: request.id,
          approvedByGC: true,
        },
      },
    });
    dispatch(navigate.to(PATHS.TASKS_APPROVE_ORDERS))
  }, [request, approveRequestMutation]);

  const undoApproval = React.useCallback(() => {
    approveRequestMutation({
      variables: {
        input: {
          requestId: request.id,
          approvedByGC: false,
        },
      },
    });
  }, [request, approveRequestMutation]);

  if (requestQuery.loading) {
    return <LoadingOverlay />;
  }

  return (
    <div>
      {journeys.map((journey, index) => (
        <React.Fragment key={journey.id}>
          <h2 className="text-xl font-bold mb-20 flex justify-between items-center">
            <span>
              Member {index + 1}/{journeys.length}: <a className="underline cursor-pointer" onClick={(e) => { e.stopPropagation(); e.preventDefault(); dispatch(navigate.toJourney(journey.id)) }}><PrivateContent>{journey?.member?.name}</PrivateContent></a>
            </span>
            <span>
              <a
                href={getZendeskSearchUrl(journey?.member?.email || journey?.member?.name)}
                title="Search Zendesk"
                rel="noopener noreferrer"
                target="_blank"
                className="px-5 inline-block"
              >
                <ZendeskIcon />
              </a>
              <a
                href={getAcuitySearchUrl(journey?.member?.name ?? '')}
                title="Search Acuity"
                rel="noopener noreferrer"
                target="_blank"
                className="px-5 inline-block"
              >
                <FiCalendar />
              </a>
            </span>
          </h2>
          <ChecklistCollapsible
            title="Member details"
            defaultOpen
            checkLabel="Reviewed"
          >
            <div className="flex">
              <div className="w-1/2">
                <MemberOrPlaceholder member={journey.member}>
                  {(member) => (
                    <MemberDetails
                      member={member}
                    />
                  )}
                </MemberOrPlaceholder>
              </div>
              <div className="w-1/2 border-l pl-20 ml-20">
                <h2 className="mb-10 font-bold">Order notes {journeys.length > 1 ? ' (same for both partners)' : ''}</h2>
                <CreateRequestNote
                  requestId={request.id}
                >
                  {(mutation, isLoading) => (
                    <div className="border-b py-10 my-5">
                      <NoteForm
                        isLoading={isLoading}
                        onSubmit={({ text, isImportant }) => mutation(text, isImportant)}
                      />
                    </div>
                  )}
                </CreateRequestNote>
                <RequestNotes requestId={request.id}>
                  {(notes: Note[], isLoading: boolean) => (
                    <div className="relative">
                      {isLoading ? <LoadingOverlay label="Refreshing notes..." /> : null}
                      {notes.map((note => <NoteComponent note={note} key={note.date} />))}
                    </div>
                  )}
                </RequestNotes>
              </div>
            </div>
          </ChecklistCollapsible>
          <ChecklistCollapsible
            title="Family history"
            defaultOpen
            checkLabel="Reviewed"
          >
            <MemberOrPlaceholder member={journey.member}>
              {(member) => (
                <FamilyHistory
                  memberId={member.id}
                  familyHistoryGroup={member.familyHistoryGroup}
                  shouldShowExtra
                />
              )}
            </MemberOrPlaceholder>
          </ChecklistCollapsible>
          <ChecklistCollapsible
            title="Consent"
            defaultOpen
            className="mb-20"
            checkLabel="Reviewed"
          >
            <JourneyConsent
              journeyId={journey.id}
            />
          </ChecklistCollapsible>
        </React.Fragment>
      ))}
      {journeys.length === 1 && isTwoPersonTest(request?.productVariant) ? (
        <Alert
          variant="warning"
          className="py-15 px-15 font-bold mb-20"
        >
          Cannot approve: This order is missing a partner.
        </Alert>
      ) : (
        <div className="flex justify-end items-center">
          {request.gcApprovalDate ? (
            <>
              <div className="text-green-dark font-bold mr-10">Approved {moment(request.gcApprovalDate).fromNow()}</div>
              <Button variant="warning" onClick={undoApproval} disabled={approveRequestMutationResult.loading}>
                Undo approval
              </Button>
            </>
          ) : (
            <Button variant="success" onClick={submitRequestApproval} disabled={approveRequestMutationResult.loading}>
              Approve this order
            </Button>
          )}
        </div>
      )}
      {approveRequestMutationResult.loading && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default ApproveRequest;
