import * as React from 'react';
import * as CryptoJS from 'crypto-js';
import { lowerCase, upperFirst } from 'lodash';
import {
  Journey,
  Member,
  QueryJourneyArgs,
  Request,
  Note,
  Order,
  Doctor,
  Clinic,
  DoctorClinic
} from '../../../../../graphql/genie-api-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PrivateContent from '../../../widgets/PrivateContent';
import JourneyShowContext from '../../../../contexts/JourneyShowContext';
import { navigate } from '../../../../store/router/actions';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import JourneyShowCareTeam from '../../show/JourneyShowCareTeam';
import JourneyShowStage from '../../show/JourneyShowStage';
import DropdownButton from '../../../widgets/DropdownButton';
import JourneyShowKitStage from '../../show/JourneyShowKitStage';
import JourneyShowStates from '../../show/JourneyShowStates';
import JourneyShowKitList from '../../show/JourneyShowKitList';
import JourneyShowFamilyHistoryLink from '../../show/JourneyShowFamilyHistoryLink';
import JourneyShowReportLink from '../../show/JourneyShowReportLink';
import MemberOrPlaceholder from '../../../member/MemberOrPlaceholder';
import { formatAddress, formatMemberName } from '../../../../../utilities/helpers';
import { formatDoctorClinicName } from "../../../../../utilities/doctorClinic";
import { RequestPanelInformation } from '../../show/RequestPanelInformation';

type ActionName = 'edit' | 'onHold' | 'createReferral' | 'createFollowUp' | 'createFlag' | 'updateJourney';

interface QueryResult {
  journey: Pick<Journey, 'id' | 'hasRecollection' | 'addressCity' | 'addressProvince' | 'addressCountryCode'> & {
    member?: Pick<Member, 'id' | 'name' | 'nickname' | 'dateOfBirth' | 'sex' | 'email'>;
    relatedJourneys?: {
      id: Journey['id'];
      member: Pick<Member, 'id' | 'name'>;
    }[];
    request?: Pick<Request, 'pregnancy' | 'id' | 'initiatingMember' | 'productVariant' | 'doctorConnectionType' | 'doctorNotifyOnReport' | 'numGenesTestedRecessive' | 'numGenesTestedXLinked'> & {
      notes?: Pick<Note, 'text' | 'isImportant' | 'date'>[];
      order?: Pick<Order, 'data'>;
      referringDoctorClinic?: Pick<DoctorClinic, 'id'> & {
        doctor: Pick<Doctor, "id" | "title" | "nameFirst" | "nameMiddle" | "nameLast">;
        clinic: Pick<Clinic, "name" | "clinicEmail" | "addressSuburb">;
      };
    };
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      addressCity
      addressProvince
      addressCountryCode
      hasRecollection
      member {
        id
        name
        nickname
        dateOfBirth
        sex
        email
      }
      request {
        id
        productVariant
        doctorConnectionType
        doctorNotifyOnReport
        numGenesTestedRecessive
        numGenesTestedXLinked
        initiatingMember {
          id
        }
        pregnancy {
          dueDate
          days
        }
        notes {
          text
          isImportant
          date
        }
        order {
          data
        }
        referringDoctorClinic {
          doctor {
            id
            title
            nameFirst
            nameMiddle
            nameLast
          }
          clinic {
            name
            clinicEmail
            addressSuburb
          }
        }
      }
      relatedJourneys {
        id
        member {
          id
          name
        }
      }
    }
  }
`;

const JourneyShowHeader = () => {
  const { journeyId } = React.useContext(JourneyShowContext);

  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });

  const dispatch = useDispatch();

  const journey = journeyQuery?.data?.journey;
  const member = journey?.member;
  const request = journey?.request;



  /*
  2022-09-11
  Danny
  Temporary solution to display eugenecare subscription status badge in genie while we wait for real API access
   
  Works as follows:
  1. Appstle (Shopify Subscriptions plugin) sends Zapier an event when a subscription is created, updated or deleted
  2. Zaiper then hashes the email address along with a suffix to create a hash which is used as a filename
  3. Zaiper then creates an image with the hashed name on Cloudinary
  4. Cloudinary URL below has a fallback to a default image (of the subscription being inactive) if the filename does not exist 
  5. Zapier "Zap" for processing the events is here: https://zapier.com/editor/167068641

  A hash is used so you can't easily guess the badge URLs by using email addresses
  `zapierShaSuffix` suffix is sensitive, but not secret and needs to match the "Zap"

  The proper solution, where we query the Appstle API directly requires use of eugene-genie-api-bridge, which is not currently setup for code pipeline deployments
  */

  const EugenecareSubscriptionBadgeURL = (email: string | null) => {
    const zapierShaSuffix = 'B3zp7HsmDAWAst9TXJKENtbg';
    const string = [email?.trim(), zapierShaSuffix].join('');
    const hash = CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
    const filename = 'eugenecare-badges/' + hash + '.png';

    return 'https://res.cloudinary.com/eugene-labs/image/upload/d_eugenecare-inactive.png/' + filename + '?nocache=' + Date.now();
  }


  const onActionClick = (actionName: ActionName) => {
    switch (actionName) {
      case 'edit':
        dispatch(navigate.toForm('MemberUpdate', { memberId: member.id }));
        break;

      case 'onHold':
        dispatch(navigate.toForm('RequestHoldStatusUpdate', { requestId: request.id }))
        break;

      case 'createReferral':
        dispatch(navigate.toForm('ReferralCreate', { journeyId: journey.id }));
        break;

      case 'createFollowUp':
        dispatch(navigate.toForm('FollowUpCreate', { journeyId: journey.id }));
        break;

      case 'createFlag':
        dispatch(navigate.toForm('FlagCreate', { journeyId: journey.id }));
        break;

      case 'updateJourney':
        dispatch(navigate.toForm('JourneyUpdate', { journeyId: journey.id }));
        break;
    }
  }

  const requestPanelInformation = request?.numGenesTestedRecessive ? RequestPanelInformation(request.numGenesTestedRecessive, request.numGenesTestedXLinked) : null;

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-1/2 pr-20">
        <MemberOrPlaceholder member={member}>
          {(member) => (
            <h3 className="text-black font-bold text-2xl"><PrivateContent>{formatMemberName(member, request)}</PrivateContent></h3>
          )}
        </MemberOrPlaceholder>
        {member && <div className="text-grey font-nm text-xs hidden">{member.id ? member.id : null}</div>}
        <div className="mt-20">
          <div className="flex flex-col flex-wrap">
            {request?.pregnancy ?
              <div className="flex text-blue mr-20">
                <span className="text-xl mr-4 -mt-2">🤰</span>
                {request.pregnancy.dueDate ? <span title={'Due on ' + `${moment(request.pregnancy.dueDate).format('Do MMM Y')}`}>{`${Math.floor(request.pregnancy.days / 7)} weeks pregnant`}</span> : 'Due date not entered'}
              </div>
              : null}

            {journey?.hasRecollection ?
              <div className="flex">
                <span className="text-xl mr-4 -mt-2">👩‍🔬</span>
                Lab test re-sent
              </div>
              : null}

            {request?.notes?.filter(note => note.isImportant).map(note => (
              <div className="flex mt-12" key={note.date}>
                <span className="text-xl mr-4 mt-1">📝</span>
                <div className="bg-orange-light px-4 py-3 rounded-sm mb-8">{note.text}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="leading-relaxed mt-12">
          {member ? (
            <div>
              <div><PrivateContent>{member.nickname ? `Prefers '${member.nickname}'` : null}</PrivateContent></div>
              <div>
                {member.dateOfBirth ? `${moment().diff(member.dateOfBirth, 'years')} y/o ` : null}
                {member.sex ? `${upperFirst(lowerCase(member.sex))} (at birth)` : null}
                {member.dateOfBirth ? ', born on ' : null}
                <span className="text-green">
                  {member.dateOfBirth ? moment(member.dateOfBirth).format('D/M/YYYY') : null}
                </span>
              </div>
            </div>
          ) : (
            <div>
              No member is assigned to this journey.
            </div>
          )}
          {journey?.relatedJourneys?.length > 0 && (
            <div className="flex flex-row flex-wrap items-baseline">
              <span className="flex mr-6">Partner of </span>
              {journey?.relatedJourneys.map((journey, index) => (
                <a href={`/journeys/${journey.id}`} key={`userJourney${index}`} className="bg-grey-light px-6 py-3 rounded text-sm leading-tight hover:cursor-pointer hover:text-green">
                  <PrivateContent>{journey?.member?.name ?? 'No member'}</PrivateContent>
                </a>
              ))}
            </div>
          )}
          {journey?.addressCity &&
            <div>
              Location: {formatAddress(journey.addressCity, journey.addressProvince, journey.addressCountryCode)}
            </div>
          }
          {journey?.request?.referringDoctorClinic &&
            <div className="mt-10 flex flex-row flex-wrap items-baseline">
              <span className="flex mr-6">
                Nominated Type: <span className='font-bold ml-3'>{journey?.request?.doctorConnectionType === 'REFERRED_BY_DOCTOR' ? "Referring doctor" : "Non-referring HCP"}</span>
              </span>
              <span className="flex mr-6">
                Share report: <span className='font-bold ml-3'>{request.doctorNotifyOnReport ? "Yes" : "No"}</span>
              </span>
              <span className="flex mr-6">
                Details: <span className='font-bold'>
                  <a href={`/dir/doctors/${journey?.request?.referringDoctorClinic.doctor.id}`} className="bg-grey-light px-6 py-3 rounded text-sm leading-tight hover:cursor-pointer hover:text-green">
                    {formatDoctorClinicName(journey?.request?.referringDoctorClinic)}
                  </a>
                </span>
              </span>
            </div>
          }
          <JourneyShowCareTeam />
        </div>
      </div>
      <div className="w-1/2 pl-20 flex flex-col items-end">
        <DropdownButton<ActionName>
          actions={[
            {
              label: 'Edit',
              name: 'edit',
              variant: 'primary',
            },
            {
              label: 'Change on-hold status',
              name: 'onHold',
              variant: 'primary',
            },
            {
              label: 'Create referral',
              name: 'createReferral',
              variant: 'primary',
            },
            {
              label: 'Create follow up',
              name: 'createFollowUp',
              variant: 'primary',
            },
            {
              label: 'Create flag',
              name: 'createFlag',
              variant: 'primary',
            },
            {
              label: 'Update journey',
              name: 'updateJourney',
              variant: 'primary',
            },
          ]}
          onActionClick={onActionClick}
          className="ml-10 mb-10"
        />
        <JourneyShowStage />
        <JourneyShowKitStage />
        {member ? <img className='mt-5' src={EugenecareSubscriptionBadgeURL(member.email)} alt='Eugenecare Subscription Status' /> : ''}
        <JourneyShowStates />
        <JourneyShowKitList />
        {requestPanelInformation && (
          <div className="text-xs mt-8 text-right">
            <span className="text-grey font-nm">Panel Selected:</span>&nbsp;<span className={`font-md ${requestPanelInformation.className}`}>{requestPanelInformation.text}</span>
          </div>
        )}
        <JourneyShowFamilyHistoryLink />
        <JourneyShowReportLink />
      </div>
    </div>
  );
};

export default JourneyShowHeader;


