import * as React from 'react';
import { Formik, FormikFormProps, FormikValues } from 'formik';
import Button from '../widgets/Button';
import FormControl from '../widgets/form/FormControl';
import ButtonGroup from '../widgets/ButtonGroup';

interface NewSessionFormProps extends FormikFormProps {
  sessionName?: string;
  onSubmit(values: FormikValues): void;
}

const NewSessionForm = (props: NewSessionFormProps) => (
  <Formik
    onSubmit={props.onSubmit}
    initialValues={{
      sessionName: props.sessionName,
    }}
  >
    {({
      handleChange,
      handleSubmit,
      values,
      errors,
      setFieldValue,
    }) => (
        <form onSubmit={handleSubmit} className="mt-20">
          <ButtonGroup>
            <FormControl
              type="text"
              name="sessionName"
              value={values.sessionName}
              onChange={handleChange}
              isInvalid={!!errors.sessionName}
              placeholder="New session name"
              setFieldValue={setFieldValue}
            />
            <Button variant="primary" type="submit">Create</Button>
          </ButtonGroup>
        </form>
      )}
  </Formik>
);

NewSessionForm.defaultProps = {
  sessionName: '',
};

export default NewSessionForm;
