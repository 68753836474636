import gql from 'graphql-tag';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { QueryListRequestsArgs, Request, RequestsConnection } from '../../../graphql/genie-api-types';
import useStatsQuery from '../../../utilities/useStatsQuery';
import Screen from '../../components/widgets/Screen';
import Table, { RowGroup } from '../../components/widgets/Table';
import { navigate } from '../../store/router/actions';

const LIST_ALL_REQUESTS = gql`
  query ListRequests($input: ListRequestsInput!) {
    listRequests(input: $input) {
      edges {
        cursor
        node {
          id
          drNameOnRequestForm
          referringDoctorClinic {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const TasksMatchDoctors = () => {
  const dispatch = useDispatch();
  const requestsStatsQuery = useStatsQuery<RequestsConnection, QueryListRequestsArgs>(LIST_ALL_REQUESTS, {
    variables: {
      input: {
        paid: true,
        referringDoctorId: null,
      }
    }
  }, 'listRequests');

  const rowGroups: RowGroup[] = React.useMemo(() => {
    return requestsStatsQuery?.data?.edges?.map(edge => edge.node)
      .filter(request => !request.referringDoctorClinic && !!request.drNameOnRequestForm)
      .map((request) => ({
        id: `group-${request.id}`,
        rows: [
          {
            id: request.id,
            cells: [
              request.id,
              request.drNameOnRequestForm,
            ],
          },
        ],
      })) ?? [];
  }, [requestsStatsQuery.data]);

  const onSelectRequest = React.useCallback(async (requestId: Request['id']) => {
    dispatch(navigate.toForm('RequestUpdate', { requestId }));
  }, [dispatch]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Match doctors'
      }}
    >
      <Table
        header={['Request Id', 'Dr Name in Member Flow']}
        rowGroups={rowGroups}
        onRefresh={requestsStatsQuery.refetch}
        isLoading={requestsStatsQuery.isLoading}
        onRowSelect={onSelectRequest}
      />
    </Screen>
  );
};

export default TasksMatchDoctors;