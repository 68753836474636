import * as React from 'react';
import { QueryResult } from 'react-apollo';
import { map } from 'lodash';
import TagAutocomplete, { TagAutocompleteProps } from '../../components/widgets/TagAutocomplete';
import GenieQuery from '../base/GenieQuery';
import gql from 'graphql-tag';

interface GeneAutocompleteTagProps {
  onSelectGenes(selectedItems: string[]): void;
  defaultSelectedGenes?: string[];
  autocompleteSize?: TagAutocompleteProps['size'];
}

type GeneSearchResult = {
  genes: {
    genes: string[];
  };
};

const GeneAutocompleteTag = (props: GeneAutocompleteTagProps) => (
  <GenieQuery
    query={gql`
    query Genes($term: String!) {
      genes(term: $term) {
        genes
      }
    }
    `}
    notifyOnNetworkStatusChange
    variables={{
      term: '',
    }}
  >
    {({ loading, data, fetchMore }: QueryResult<GeneSearchResult>) => (
      <TagAutocomplete
        isLoading={loading}
        options={data && data.genes ? data.genes.genes.map((gene: string) => ({
          name: gene,
          id: gene,
        })) : []}
        size={props.autocompleteSize}
        onSearch={(searchQuery: string) => {
          fetchMore({
            variables: {
              term: searchQuery,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
              ...previousResult,
              ...fetchMoreResult,
            }),
          });
        }}
        onChange={items => props.onSelectGenes(map(items, 'id') as string[])}
        defaultSelected={props.defaultSelectedGenes.map((gene: string) => ({
          name: gene,
          id: gene,
        }))}
      />
    )}
  </GenieQuery>
);

GeneAutocompleteTag.defaultProps = {
  defaultSelectedGenes: [],
};

export default GeneAutocompleteTag;
