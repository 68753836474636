import { Request, Journey } from '../../../graphql/genie-api-types';
import { JourneyTableProps } from '../../components/widgets/JourneyTable';
import convertJourneyResultsToJourneyTableData from './convertJourneyResultsToJourneyTableData';

export type JourneyTableData = Pick<JourneyTableProps<Journey>, 'journeysByRequestId' | 'orderedRequestIds'>;

const convertRequestResultsToJourneyTableData = (requests: Request[]): JourneyTableData => {
  const journeys: Journey[] = [];
  requests.forEach(request =>
    request.journeys.forEach(journey => {
      journey.request = request;
      journeys.push(journey);
    }));
    
  return convertJourneyResultsToJourneyTableData(journeys);
};

export default convertRequestResultsToJourneyTableData;
