import * as React from 'react';
import ScreenTitle from './ScreenTitle';

import { FiMinus, FiCheck } from './Icon';

import './styles/task-list-screen-title.scss';
import Button from './Button';

interface TaskListScreenTitleProps {
  title: string;
  onToggle?(): void;
  isSelected: boolean;
}

const TaskListScreenTitle = (props: TaskListScreenTitleProps) => (
  <ScreenTitle
    action={(
      <Button onClick={props.onToggle} isActive className="task-list-screen-title__toggle-button" variant={props.isSelected ? 'success' : 'warning'}>
        {props.isSelected ? <FiCheck size={25} /> : <FiMinus size={25} />}
      </Button>
    )}
    title={props.title}
  />
);

export default TaskListScreenTitle;
