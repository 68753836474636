import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Journey } from '../../../graphql/genie-api-types';
import JourneyShowHeader from '../../components/journey/journey-rows/show/JourneyShowHeader';
import MemberShowContact from '../../components/member/show/MemberShowContact';
import JourneyShowTimeline from '../../components/journey/show/JourneyShowTimeline';
import JourneyShowNotifications from '../../components/journey/show/JourneyShowNotifications';
import Screen from '../../components/widgets/Screen';
import { JourneyShowContextWrapper } from '../../contexts/JourneyShowContext';
import JourneyShowMemberId from '../../components/journey/show/JourneyShowMemberId';
import MemberShowJourneySelector from '../../components/member/show/MemberShowJourneySelector';
import JourneyShowJourneyRows from '../../components/journey/show/JourneyShowJourneyRows';
import JourneyShowRequestId from '../../components/journey/show/JourneyShowRequestId';
import RequestNotes from '../../components/request/RequestNotes';

interface MatchParams {
  journeyId: Journey['id'];
}

type OwnProps = RouteComponentProps<MatchParams>;

const JourneyShow = (props: OwnProps) => {
  const { match } = props;

  return (
    <Screen className="relative">
      <JourneyShowContextWrapper journeyId={match.params.journeyId}>
        <div className="relative">
          <JourneyShowHeader />
          <JourneyShowMemberId>
            {(memberId) => (
              <div className="flex flex-row flex-wrap">
                <div className="w-1/2 pr-20 mt-20 self-end">
                  <MemberShowContact
                    memberId={memberId}
                  />
                </div>
                <div className="w-1/2 mt-20 pl-20">
                  <MemberShowJourneySelector
                    memberId={memberId}
                    selectedJourneyId={match?.params?.journeyId}
                  />
                </div>
              </div>
            )}
          </JourneyShowMemberId>
          <div className="w-full mt-40">
            <JourneyShowJourneyRows />
          </div>
          <div className="w-full flex flex-row flex-nowrap mt-50">
            <div className="w-1/2 pr-20">
              <JourneyShowTimeline />
            </div>
            <div className="w-1/2 pl-20">
              <JourneyShowRequestId>
                {(requestId) => (
                  <RequestNotes requestId={requestId} />
                )}
              </JourneyShowRequestId>

              <JourneyShowNotifications />
            </div>
          </div>
        </div>
      </JourneyShowContextWrapper>
    </Screen>
  );
};

export default JourneyShow;
