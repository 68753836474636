import * as React from 'react';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { OpsEfficiencyStatsProps, WEEK_FORMAT } from '../OpsEfficiency';
import moment from 'moment';

const OpsEfficiencyAppointments = (props: OpsEfficiencyStatsProps) => {
  const { appointments, fromDate, toDate } = props;
  const appointmentsByWeek = React.useMemo(() => {
    const _appointmentsByWeek: { [key: number]: { preTest: number; postTest: number } } = {};

    // Loop from first Monday, by week.
    for (let m = fromDate.clone().isoWeekday(1); m.isBefore(toDate); m.add(1, 'week')) {
      _appointmentsByWeek[parseInt(m.format(WEEK_FORMAT))] = { preTest: 0, postTest: 0 };
    }

    appointments.forEach(appointmentResult => {
      const weekStamp = parseInt(moment(appointmentResult.datetime).format(WEEK_FORMAT));
      if (!_appointmentsByWeek[weekStamp]) {
        return;
      }

      const appointmentName = appointmentResult.type.toLowerCase();
      if (appointmentName.includes('cancer')) {
        return;
      }

      if (appointmentName.includes('before the test')) {
        _appointmentsByWeek[weekStamp].preTest += 1;
      }
      else if (appointmentName.includes('for your results')) {
        _appointmentsByWeek[weekStamp].postTest += 1;
      }
    });

    return _appointmentsByWeek;
  }, [appointments, props.fromDate, props.toDate]);
  const appointmentsChartData = React.useMemo(() => {
    const weekNumbers = Object.keys(appointmentsByWeek) as any as number[];
    return weekNumbers.sort().map((weekStamp: number) => {
      return {
        date: weekStamp,
        formattedDate: moment(weekStamp, WEEK_FORMAT).format('[Week of] Do MMM YYYY'),
        'Pre-test appointments': appointmentsByWeek[weekStamp].preTest,
        'Post-test appointments': appointmentsByWeek[weekStamp].postTest,
      };
    });
  }, [appointmentsByWeek]);

  return (
    <>
      <h2 className="text-xl font-bold mt-20 mb-10">Appointments</h2>
      <AreaChart
        width={800}
        height={400}
        data={appointmentsChartData}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="6 6" />
        <XAxis dataKey="formattedDate" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Pre-test appointments" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="Post-test appointments" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
      </AreaChart>
    </>
  );
};

export default OpsEfficiencyAppointments;
