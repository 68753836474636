import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { debounce } from 'lodash';
import ClientDetails from '../report/client-details';
import Updates from '../report/updates';
import { ApplicationState } from '../../store';
import { CompletedTaskList } from '../../store/report/task-list/types';
import Export from './export/Export';
import { PATHS, Path, ReportMode } from '../../store/router/types';
import Analysis from './analysis';
import TestInformation from './test-information';
import PageNav, { NavItem } from '../../components/widgets/PageNav';
import Route from '../../base/Route';
import Summary from './summary';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import CarrierRates from './carrier-rates';
import { ThunkDispatch } from 'redux-thunk';
import Customisation from './customisation';
import { processReportPath, getReportPathParams } from '../../store/router/actions';
import Spinner from '../../components/widgets/Spinner';
import { replaceReportStateWithStorageState } from '../../store/report/actions';
import { showToast } from '../../store/toast/actions';
import requestReportByRequestId from '../../graphql/requests/requestReportByRequestId';
import { mergeApplicationState } from '../../store/storage/actions';
import { ReportState } from '../../store/report';
import saveReportForRequestId from '../../graphql/requests/saveReportForRequestId';
import { FiUploadCloud } from '../../components/widgets/Icon';
import { saveReportToLocalStorage } from '../../../utilities/report/localStorage';
import FamilyReport from './familyReport';
import RemoteReportHeader from '../../containers/report/header/RemoteReportHeader';
import { Product } from '../../../graphql/genie-api-types';
import AdditionalInformation from './additional-information';
import LetterToDoctor from './letterToDoctor';

const navItems: NavItem[] = [
  {
    path: PATHS.REPORTS_CLIENT_DETAILS,
    label: 'Client details',
    taskListKey: 'clientDetails',
    routeProps: {
      component: ClientDetails,
    },
  },
  {
    path: PATHS.REPORTS_SUMMARY,
    label: 'Summary',
    taskListKey: 'summary',
    routeProps: {
      component: Summary,
    },
  },
  {
    path: PATHS.REPORTS_TEST_INFORMATION,
    label: 'Test information',
    taskListKey: 'testInformation',
    routeProps: {
      component: TestInformation,
    },
  },
  {
    path: PATHS.REPORTS_CARRIER_RATES,
    label: 'Carrier rates',
    taskListKey: 'carrierRates',
    routeProps: {
      component: CarrierRates,
    },
  },
  {
    path: PATHS.REPORTS_ANALYSIS,
    label: 'Analysis',
    taskListKey: 'analysis',
    routeProps: {
      component: Analysis,
    },
  },
  {
    path: PATHS.REPORTS_CUSTOMISATION,
    label: 'Customisation',
    taskListKey: 'customisation',
    routeProps: {
      component: Customisation,
    },
  },
  {
    path: PATHS.REPORTS_EXPORT,
    label: 'Export',
    taskListKey: 'export',
    routeProps: {
      component: Export,
    },
  },
  {
    path: PATHS.REPORTS_LETTER_TO_DOCTOR,
    label: 'Letter to doctor',
    taskListKey: 'letterToDoctor',
    topDivider: false,
    routeProps: {
      component: LetterToDoctor,
    },
  },
  {
    path: PATHS.REPORTS_FAMILY,
    label: 'Family reports',
    taskListKey: 'familyReport',
    topDivider: true,
    routeProps: {
      component: FamilyReport,
    },
  },
  {
    path: PATHS.REPORTS_UPDATES,
    label: 'Updates',
    routeProps: {
      component: Updates,
    },
  }
];

const cancerNavItems: NavItem[] = [
  navItems[0],
  navItems[2],
  {
    path: PATHS.REPORT_ADDITIONAL_INFO,
    label: 'Additional Info',
    taskListKey: 'additionalInformation',
    routeProps: {
      component: AdditionalInformation,
    },
  },
  navItems[6]
]

interface ReportSessionState {
  isLoading: boolean;
  isSaving: boolean;
  isSaved: boolean;
  requestId: string | null;
  mode: ReportMode | null;
  reportLabel: string;
  loadedRequestId: string;
}

interface ReportSessionDispatchProps {
  onReplaceReportStateWithStorageState(sessionName: string): void;
  onInjectReportState(report: ReportState): void;
}

interface ReportSessionProps extends RouteComponentProps {
}

interface ReportSessionStateProps {
  report: ReportState;
  encryptionKey: string;
  completedTasks: CompletedTaskList;
}

interface AllProps extends ReportSessionStateProps, ReportSessionDispatchProps, ReportSessionProps {

}

class ReportSession extends React.PureComponent<AllProps, ReportSessionState> {
  state: Readonly<ReportSessionState> = {
    isLoading: true,
    isSaving: false,
    isSaved: true,
    requestId: null,
    mode: null,
    reportLabel: '',
    loadedRequestId: null,
  };

  static getDerivedStateFromProps(props: AllProps, state: ReportSessionState) {
    const { requestId, mode } = getReportPathParams();
    return {
      ...state,
      requestId,
      mode,
    };
  }

  componentWillUnmount() {
    this.saveReport.flush();
  }

  componentDidMount() {
    this.initReport();
  }

  componentDidUpdate(prevProps: AllProps, prevState: ReportSessionState) {
    const { requestId, mode, isLoading } = this.state;
    const { report } = this.props;

    if (prevState.requestId !== requestId || prevState.mode !== mode) {
      this.initReport();
    } else if (prevProps.report !== report && !isLoading) {
      this.setState({
        isSaved: false,
      }, this.onSaveReport);
    }
  }

  async initReport() {
    const { mode, requestId } = getReportPathParams();

    this.setState({
      isLoading: true,
    });

    try {
      switch (mode) {
        case 'localStorage':
          this.initReportFromStorage();
          break;

        case 'remote':
          await this.initReportFromRemote();
          break;

        default:
          console.warn(`${mode} is not a storage driver`);
      }
    } catch (e) {
      showToast('error', 'Error whilst loading report', e.message);
    }

    this.setState({
      isLoading: false,
      loadedRequestId: requestId,
    });
  }

  initReportFromStorage() {
    const { requestId } = getReportPathParams();
    const { onReplaceReportStateWithStorageState } = this.props;
    onReplaceReportStateWithStorageState(requestId);

    this.setState({
      reportLabel: requestId,
    });
  }

  async initReportFromRemote() {
    const { requestId } = getReportPathParams();
    const { onInjectReportState } = this.props;
    const { data, request } = await requestReportByRequestId(requestId);

    if (data) {
      onInjectReportState({ ...data, request: { request } });
    }

    this.setState({
      reportLabel: 'Cloud report',
    });
  }

  saveReport = debounce(async (requestId, report, encryptionKey, mode) => {
    try {
      switch (mode) {
        case 'localStorage':
          saveReportToLocalStorage(requestId, report, encryptionKey);
          break;

        case 'remote':
          await saveReportForRequestId(requestId, report);
          break;

        default:
          throw new Error(`No storage mode "${mode}"`);
      }
    } catch (e) {
      showToast('error', 'Error saving report', e.message);
    }
  }, 5000, { leading: true });

  onSaveReport = () => {
    const { requestId, mode, loadedRequestId, isLoading } = this.state;
    const { report, encryptionKey } = this.props;

    // Ensure reports don't attempt a save during loads because requestId and report
    // state will be out of sync.
    if (isLoading) {
      return;
    }

    this.setState({ isSaving: true, isSaved: false });

    // Prevent race condition in which a report in progress gets saved to a previous request.
    if (loadedRequestId === requestId) {
      this.saveReport(requestId, report, encryptionKey, mode);
    }

    this.setState({ isSaving: false, isSaved: true });
  }

  renderSaveInformation() {
    const { isSaved } = this.state;

    return (
      <button className="text-sm flex text-purple items-center mr-0 ml-auto focus:outline-none" onClick={this.onSaveReport}>
        {isSaved ? <FiUploadCloud /> : <Spinner color="purple" />}
      </button>
    );
  }

  render() {
    const { completedTasks, encryptionKey } = this.props;
    const { isLoading, reportLabel } = this.state;
    const { requestId, mode } = getReportPathParams();
    const product = this.props.report.request.request?.product;
    const productNavItems = product === Product.Cancer ? cancerNavItems : navItems;

    if (!requestId || !mode) {
      return <Redirect to={{ pathname: PATHS.REPORTS }} />;
    }

    if (mode === 'localStorage' && !encryptionKey) {
      return <Redirect to={{ pathname: PATHS.REPORTS }} />;
    }

    if (isLoading) {
      return <Spinner label="Loading report" />;
    }

    return (
      <div>
        {mode === 'remote' ? <RemoteReportHeader /> : null}
        <Row className="h-full">
          <Col width="1/5">
            <PageNav
              navItems={productNavItems}
              taskList={completedTasks as any}
              pathProcessor={(path: Path) => processReportPath(path, mode as ReportMode, requestId)}
              titleView={(
                <div className="flex items-center">
                  {reportLabel}
                  {this.renderSaveInformation()}
                </div>
              )}
            />
          </Col>
          <Col width="4/5">
            <Switch>
              {productNavItems.map((navItem: NavItem) => (
                <Route path={navItem.path} {...navItem.routeProps} key={navItem.path} />
              ))}
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ report, storage }: ApplicationState) => ({
  report,
  completedTasks: report.taskList.completedTasks,
  encryptionKey: storage.encryptionKey,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, any, any>) => ({
  onReplaceReportStateWithStorageState: (sessionName: string) => dispatch(replaceReportStateWithStorageState(sessionName)),
  onInjectReportState: (report: ReportState) => dispatch(mergeApplicationState({ report })),
});

export default connect<ReportSessionStateProps, ReportSessionDispatchProps, ReportSessionProps>(mapStateToProps, mapDispatchToProps)(ReportSession);
