import * as React from 'react';
import ScreenTitle, { ScreenTitleProps } from './ScreenTitle';
import ErrorBoundary from '../../base/ErrorBoundary';

interface ScreenProps {
  screenTitleProps?: ScreenTitleProps;
  className?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Screen = (props: ScreenProps) => {
  return (
    <div className={`${(props.fullWidth ? 'w-full' : 'max-w-3xl xxl:max-w-5xl')} flex-1 flex flex-col ${props.className ?? ''} print:block`}>
      <ScreenTitle {...props.screenTitleProps} />
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </div>
  );
};

export default Screen;
