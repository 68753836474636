import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, CreateDoctorResult, CreateDoctorInput } from '../../../../graphql/genie-api-types';
import DoctorForm from '../../../components/doctor/DoctorForm';
import { MutationFunction } from 'react-apollo';
import gql from 'graphql-tag';
import { showToast } from '../../../store/toast/actions';
import { pick } from 'lodash';

interface DoctorCreateProps {
  onCreate?(doctor: CreateDoctorResult['doctor']): void;
  onCancel?(): void;
}

const DoctorCreate = (props: DoctorCreateProps) => (
  <GenieMutation
    refetchQueries={() => {
      return ['ListDoctors'];
    }}
    mutation={gql`
      mutation CreateDoctor($input: CreateDoctorInput!) {
        createDoctor(input: $input) {
          doctor {
            id,
            nameFirst
          }
        }
      }
    `}
  >
    {(mutate: MutationFunction<Mutation>, result) => (
      <DoctorForm
        initialValues={{}}
        onCancel={props.onCancel}
        isLoading={result.loading}
        errorMessage={result?.error?.message ?? ''}
        onSubmit={async (doctorFields: CreateDoctorInput) => {
          const mutationResult = await mutate({
            variables: {
              input: {
                ...pick<CreateDoctorInput>(doctorFields, [
                  'id',
                  'gender',
                  'nameFirst',
                  'nameLast',
                  'nameMiddle',
                  'nickname',
                  'personalEmail',
                  'personalMobile',
                  'personalPhone',
                  'practitionerId',
                  'registrationNumber',
                  'specialties',
                  'title',
                ]),
              },
            },
          });

          if (mutationResult && props.onCreate) {
            props.onCreate(mutationResult.data.createDoctor.doctor);
          }

          showToast('success', 'Created Doctor', `${doctorFields.nameFirst}`)
        }}
      />
    )
    }
  </GenieMutation>
);

export default DoctorCreate;
