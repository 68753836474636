import * as React from 'react';
import './styles/session-list.scss';
import Table from '../widgets/Table';
import Badge from '../widgets/Badge';
import { Action } from '../widgets/DropdownButton';

interface SessionListProps {
  sessions: string[];
  onSelectSession(sessionName: string): void;
  onDeleteSession(sessionName: string): void;
}

const sessionActions: Action[] = [
  {
    label: 'Delete',
    variant: 'danger',
    name: 'delete',
  },
];

const SessionList = (props: SessionListProps) => (
  <div className="session-list">
    <Table
      header={['Session name']}
      rowGroups={[{
        id: 'testgroup',
        rows: props.sessions.map(sessionName => ({
          id: sessionName,
          cells: [<Badge key={sessionName}>{sessionName}</Badge>],
          isSelectable: true,
          actions: sessionActions,
        })),
      }]}
      onRowSelect={(sessionName: string) => props.onSelectSession(sessionName)}
      onActionClick={(sessionNames, actionId) => {
        if (actionId === 'delete' && confirm('Are you sure you want to delete this report?')) {
          props.onDeleteSession(sessionNames[0]);
        }
      }}
    />
  </div>
);

export default SessionList;
