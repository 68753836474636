import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, CreateFamilyHistoryGroupItemResult, FamilyHistoryCategory, MutationCreateFamilyHistoryGroupItemArgs, CreateFamilyHistoryGroupItemInput } from '../../../../graphql/genie-api-types';
import { MutationFunction } from 'react-apollo';
import gql from 'graphql-tag';
import FamilyHistoryForm from '../../../components/family-history/FamilyHistoryForm';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface FamilyHistoryCreateProps {
  onCreate?(familyHistoryGroupItem: CreateFamilyHistoryGroupItemResult['familyHistoryGroupItem']): void;
  onCancel?(): void;
  memberId: string;
  familyHistoryCategory: FamilyHistoryCategory;
}

const FamilyHistoryCreate = ({ onCreate, onCancel, memberId, familyHistoryCategory }: FamilyHistoryCreateProps) => (
  <GenieMutation
    mutation={gql`
      mutation CreateFamilyHistoryGroupItem($input: CreateFamilyHistoryGroupItemInput!) {
        createFamilyHistoryGroupItem(input: $input) {
          familyHistoryGroupItem {
            id
          }
        }
      }
    `}
    refetchQueries={() => {
      return ['QueryMember'];
    }}
  >
    {(mutate: MutationFunction<Pick<Mutation, 'createFamilyHistoryGroupItem'>, MutationCreateFamilyHistoryGroupItemArgs>, result) => (
      <FamilyHistoryForm
        initialValues={{ category: familyHistoryCategory }}
        isLoading={result.loading}
        errorMessage={result?.error?.message}
        onCancel={onCancel}
        onSubmit={async (values: CreateFamilyHistoryGroupItemInput) => {
          const mutationResult = await mutate({
            variables: {
              input: {
                memberId,
                ...values,
              },
            },
          });
          if (mutationResult) {
            onCreate(mutationResult.data.createFamilyHistoryGroupItem.familyHistoryGroupItem);
            ReduxQueryListener.triggerEvents('UpdateFamilyHistory');
          }
        }}
      />
    )}
  </GenieMutation>
);

export default FamilyHistoryCreate;
