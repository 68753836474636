import * as React from 'react';
import { Mutation, QueryFamilyHistorySubmissionArgs, MutationReassignFamilyHistorySubmissionArgs, Query } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/widgets/Spinner';
import { appSyncClient } from '../../../../utilities/appSync';
import FamilyHistoryReassignForm from '../../../components/family-history/FamilyHistoryReassignForm';
import { toastr } from 'react-redux-toastr';

interface FamilyHistoryUpdateProps {
  onUpdate?(): void;
  submissionId: string;
}

const FAMILY_HISTORY_SUBMISSION_QUERY = gql`
  query FamilyHistorySubmission($submissionId: ID!) {
    familyHistorySubmission(submissionId: $submissionId) {
      id
      name
      value
    }
  }
`;

const FAMILY_HISTORY_SUBMISSION_REASSIGN_MUTATION = gql`
  mutation ReassignFamilyHistorySubmission($memberId: ID!, $submissionId: ID!) {
    reassignFamilyHistorySubmission(memberId: $memberId, submissionId: $submissionId) {
      id
    }
  }
`;

const FamilyHistoryReassign = ({ onUpdate, submissionId }: FamilyHistoryUpdateProps) => {
  const { loading, data } = useQuery<Pick<Query, 'familyHistorySubmission'>, QueryFamilyHistorySubmissionArgs>(FAMILY_HISTORY_SUBMISSION_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: {
      submissionId,
    },
  });

  const [mutate] = useMutation<Pick<Mutation, 'reassignFamilyHistorySubmission'>, MutationReassignFamilyHistorySubmissionArgs>(FAMILY_HISTORY_SUBMISSION_REASSIGN_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['ListFlags'];
    },
  });

  if (loading) {
    return <Spinner label="Loading family history..." />;
  }

  const name = data?.familyHistorySubmission?.find(submissionItem => submissionItem.name === 'Name')?.value ?? '';

  return (
    <div>
      Submission: {name} - {submissionId}
      <FamilyHistoryReassignForm
        onSubmit={async (values) => {
          if (!values.member) {
            toastr.error('No member was selected', 'A member needs to be selected to complete the reassignment.');
            return;
          }

          await mutate({
            variables: {
              submissionId,
              memberId: values.member.id,
            },
          });
          
          toastr.success(values.member.name, 'Assigned family history');
          
          onUpdate();
        }}
      />
    </div>
  );
}

export default FamilyHistoryReassign;
