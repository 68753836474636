import * as React from 'react';
import ReactTags from 'react-tag-autocomplete';
import Spinner from './Spinner';

export interface TagAutocompleteOption {
  id: string | number;
  name: string;
}

export interface TagAutocompleteProps {
  isLoading: boolean;
  options: TagAutocompleteOption[];
  defaultSelected?: TagAutocompleteOption[];
  onSearch(input: string): void;
  onChange?(items: TagAutocompleteOption[]): void;
  maxSelectionAmount?: number;
  size?: 'small'|'default';
  placeholder?: string;
}

class TagAutocomplete extends React.PureComponent<TagAutocompleteProps> {
  static defaultProps = {
    maxSelectionAmount: 100,
    defaultSelected: [] as TagAutocompleteOption[],
    placeholder: 'Search...',
  };

  onRemove = (index: number) => {
    const { defaultSelected, onChange } = this.props;
    const newSelected = defaultSelected.slice();
    newSelected.splice(index, 1);

    onChange(newSelected);
  }

  onAdd = (tag: TagAutocompleteOption) => {
    const { defaultSelected, onChange, maxSelectionAmount } = this.props;
    const newSelected = [...defaultSelected, tag];

    if (newSelected.length <= maxSelectionAmount) {
      onChange(newSelected);
    }
  }

  render() {
    const { options, defaultSelected, onSearch, isLoading, maxSelectionAmount, size, placeholder } = this.props;
    const isDisabled = defaultSelected?.length >= maxSelectionAmount;

    // Prevent jest from failing when attempting to load react tags component.
    const TagComponent = ReactTags ? ReactTags : () => <div />;
    const className = size === 'small' ? 'react-tags--small' : '';

    return (
      <div className={`relative ${className || ''}`}>
        <TagComponent
          suggestions={options}
          tags={defaultSelected}
          handleInputChange={onSearch}
          handleDelete={this.onRemove}
          handleAddition={this.onAdd}
          inputAttributes={{
            disabled: isDisabled,
          }}
          placeholder={isDisabled ? '' : placeholder}
          autofocus={false}
        />
        {isLoading ? (
          <div className="absolute right-0 top-0 bottom-0 p-10 px20 z-50 flex items-center">
            <Spinner />
          </div>
        ) : null}
      </div>
    );
  }
}

export default TagAutocomplete;
