import * as React from 'react';
import { upperFirst, lowerCase } from 'lodash';
import { Product, ProductVariant } from '../../graphql/genie-api-types';
import { toTitleCase } from '../helpers';
import { getProductStrategy, getProductVariantStrategy, productVariantTable } from './productVariantTable';

export function getProductIcon(product: Product, productVariant: ProductVariant = null) {
  return (
    <div className="flex justify-start items-center" title={`${productVariant ? toTitleCase(productVariant) + ' ' : ''}${product?.toLowerCase()}`}>
      <span className='flex justify-center w-8 mr-4'>
        {getProductVariantStrategy(product, productVariant).iconRenderer()}
      </span>
      {productVariant ? toTitleCase(productVariant) : product ? toTitleCase(product) : ''}
    </div>
  );
}

export function getProductLabel(product: Product, productVariant?: ProductVariant) {
  if (productVariant == null) {
    return getProductStrategy(product).label;
  }
  return getProductVariantStrategy(product, productVariant).label; 
}

export const getVariantLabel = (productVariant: ProductVariant) => {
  return upperFirst(lowerCase(productVariant));
};

export const PRODUCT_LIST = Object.keys(productVariantTable) as Product[];

export const FLAT_PRODUCT_LIST: Array<[Product, ProductVariant]> = [];

for (const [product, productStrategy] of Object.entries(productVariantTable)) {
  if (productStrategy.hasVariant) {
    for (const productVariant of Object.keys(productStrategy.variants)) {
      FLAT_PRODUCT_LIST.push([product as Product, productVariant as ProductVariant])
    }
  } else {
    FLAT_PRODUCT_LIST.push([product as Product, null])
  }
}