import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import Route from '../../../base/Route';
import UpdatesView from './UpdatesView';

const Summary = () => (
  <div>
    <Route path={PATHS.REPORTS_UPDATES} component={UpdatesView} />
  </div>
);

export default Summary;
