import { readFileSync } from 'fs';
import Handlebars from '../../../../report/Handlebars';

const Top = readFileSync(__dirname + '/_base/top.handlebars', 'utf8');
const Bottom = readFileSync(__dirname + '/_base/bottom.handlebars', 'utf8');

const Intro = readFileSync(__dirname + '/_components/intro.handlebars', 'utf8');
const Section = readFileSync(__dirname + '/_components/section.handlebars', 'utf8');
const DiseaseTable = readFileSync(__dirname + '/_components/disease-table.handlebars', 'utf8');
const Divider = readFileSync(__dirname + '/_components/divider.handlebars', 'utf8');

export class emailTemplates {
  static get Intro() {
    return Handlebars.compile(Intro);
  }
  static get Section() {
    return Handlebars.compile(Section);
  }
  
  static get DiseaseTable() {
    return Handlebars.compile(DiseaseTable);
  }
  
  static get Divider() {
    return Handlebars.compile(Divider);
  }
}

export class emailBaseTemplates {
  static get Top() {
    return Handlebars.compile(Top);
  }
  static get Bottom() {
    return Handlebars.compile(Bottom);
  }
}
