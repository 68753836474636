import moment from 'moment';
import { Product } from '../graphql/genie-api-types';
import { fetchFromApiBridge } from './apiBridge';

interface ShopifyOrderResponse {
  orders: {
    id: number;
    created_at: string;
    email: string;
    billing_address: {
      name: string;
    };
    total_price: string;
    line_items: {
      name: string;
      sku: string;
    }[];
  }[];
}

export interface PaidOrder {
  id: number;
  date: string;
  email: string;
  name: string;
  product: Product;
  price: string;
}

const SHOPIFY_PRODUCT_MAPPING:{[key: string]: Product} = {
  'CARRIER-IN': Product.Carrier,
  'CARRIER-CP': Product.Carrier,
  'CARRIER_DONOR': Product.Carrier,
  'CANCER': Product.Cancer,
};

export const getPaidOrders = async (): Promise<PaidOrder[]> => {
  const lastWeek = moment().subtract(1, 'w');
  const createdAtMin = lastWeek.format('YYYY-MM-DD\THH:mm:ssZZ');
  
  const result: ShopifyOrderResponse = await fetchFromApiBridge('shopify', {
    apiPath: `/admin/api/2020-04/orders.json?financial_status=paid&status=any&limit=200&created_at_min=${createdAtMin}`,
  });
  
  return result?.orders?.map(order => ({
    id: order.id,
    email: order.email,
    date: order.created_at,
    name: order.billing_address.name,
    product: SHOPIFY_PRODUCT_MAPPING[order.line_items?.[0].sku ?? ''],
    price: order.total_price,
  })) ?? [];
};
