import * as React from 'react';

import { Switch } from 'react-router';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';
import FamilyHistorySubmission from './submissions/FamilyHistorySubmission';

const Submissions = () => {
  return (
    <Switch>
      <Route path={PATHS.SUBMISSIONS_FAMILY_HISTORY} exact component={FamilyHistorySubmission} />
    </Switch>
  );
};

export default Submissions;
