import * as React from 'react';
import moment from 'moment';
import Screen from '../../components/widgets/Screen';
import { getPaidOrders, PaidOrder } from '../../../utilities/shopify';
import Button from '../../components/widgets/Button';
import { QueryListJourneysArgs, Query, QuerySearchMembersArgs, Request, Journey } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import Table from '../../components/widgets/Table';
import { appSyncClient } from '../../../utilities/appSync';
import Badge from '../../components/widgets/Badge';
import markRequestAsPaid from '../../graphql/requests/request/markRequestAsPaid';
import { FiCheck } from '../../components/widgets/Icon';
import PrivateContent from '../../components/widgets/PrivateContent';
import ProgressBar from '../../components/widgets/ProgressBar';
import GeneticJokes from '../../components/widgets/GeneticJokes';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';

interface OrderRequest extends Pick<Request, 'id'|'datePaid'|'product'|'productVariant'|'pregnancy'> {
  journeys: {
    id: Journey['id'];
  }[];
}

const SEARCH_MEMBER_QUERY = gql`
  query SearchMembers($term: String!) {
    searchMembers(term: $term) {
      id
    }
  }
`;

const SEARCH_JOURNEY_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          request {
            id
            datePaid
            product
            productVariant
            journeys {
              id
            }
          }
        }
      }
    }
  }
`;

interface ShopifyOrderRow extends PaidOrder {
  request?: OrderRequest;
}

const TasksPostPurchase = () => {
  const [shopifyOrders, setShopifyOrders] = React.useState([] as ShopifyOrderRow[]);
  const [isLoadingOrders, setIsLoadingOrders] = React.useState(false);
  
  const { onSelectJourney } = React.useContext(JourneyInfoContext);

  const markOrderPaid = async(requestId: string) => {
    const result = await markRequestAsPaid(requestId, true);
    const request = result.data?.updateRequest?.request;
    if (request?.datePaid) {
      const newShopifyOrders = [...shopifyOrders];
      
      newShopifyOrders.forEach(order => {
        if (request?.id === order.request?.id) {
          order.request.datePaid = request?.datePaid;
        }
      });
      
      setShopifyOrders(newShopifyOrders);
    }
  };
  
  const onOrderSelect = React.useCallback((orderId: string) => {
    const orderIdNumber = parseInt(orderId, 10);
    const order = shopifyOrders.find(order => order.id === orderIdNumber);
    const request = order.request;
    
    if (request) {
      onSelectJourney(request.journeys[0].id);
    }
  }, [shopifyOrders]);

  const getOrdersFromThirdParties = React.useCallback(async (e?: React.FormEvent) => {
    e?.preventDefault();
    setIsLoadingOrders(true);
    try {
      const paidOrders = await getPaidOrders();

      const orderRequests = await Promise.all(paidOrders.map(async (order) => {
        const members = await appSyncClient.query<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>({
          query: SEARCH_MEMBER_QUERY,
          variables: {
            term: order.email,
          },
        });
        
        const memberId = members?.data?.searchMembers?.[0]?.id;
        
        const memberJourneys = memberId ? await appSyncClient.query<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>({
          query: SEARCH_JOURNEY_QUERY,
          variables: {
            input: {
              memberId,
            }
          },
        }) : null;
        
        const correspondingRequest = memberJourneys?.data?.listJourneys?.edges
          ?.map(edge => edge.node)
          ?.reverse() // Use most recent request first
          ?.find(journey => journey?.request?.product === order.product)
          ?.request;
          
        return correspondingRequest as OrderRequest|undefined;
      }));
      
      const shopifyOrderRows = paidOrders.map((order, index) => ({
        ...order,
        request: orderRequests[index],
      }));
      
      setShopifyOrders(shopifyOrderRows);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingOrders(false);
  }, [getPaidOrders]);


  const getStatusColumn = (order: ShopifyOrderRow) => {
    if (!order.request) {
      return <Badge color="orange-light" key="not-found">Not found</Badge>
    }
    
    if (order.request.datePaid) {
      return <Badge color="green" key="synced" className="text-white">Synced <FiCheck /></Badge>
    }
    
    return (
      <Button
        size="sm"
        key="sync"
        variant="success"
        onClick={(e) => {
          e.stopPropagation();
          markOrderPaid(order.request.id);
        }}
      >
        Sync
      </Button>
    );
  }

  return (
    <Screen
      screenTitleProps={{
        title: 'Post purchase workflow',
      }}
    >
      <form className="flex relative items-center justify-between" onSubmit={getOrdersFromThirdParties}>
        <Button type="submit">
          Get Paid Shopify Orders
        </Button>
        <GeneticJokes />
      </form>
      {isLoadingOrders && (
        <ProgressBar progress={0.5} message="Loading shopify orders..." className="my-20" />
      )}
      {shopifyOrders.length > 0 && (
        <>
          <h2 className="my-20 text-lg font-bold">Shopify orders</h2>
          <Table
            header={['Order email', 'Order name', 'Product Code', 'Price', 'Date', 'Status']}
            onRefresh={getOrdersFromThirdParties}
            onRowSelect={onOrderSelect}
            rowGroups={shopifyOrders.map(order => ({
              id: order.id.toString(),
              isSelectable: true,
              rows: [{
                id: order.id.toString(),
                cells: [
                  <PrivateContent key="email">{order.email}</PrivateContent>,
                  <PrivateContent key="name">{order.name}</PrivateContent>,
                  order.product,
                  order.price,
                  moment(order.date).fromNow(),
                  getStatusColumn(order),
                ],
              }],
            }))}
          />
        </>
      )}
    </Screen>
  );
}

export default TasksPostPurchase;
