import * as React from 'react';
import { FaCircleNotch } from './Icon';
import './styles/spinner.scss';

export interface SpinnerProps {
  label?: string;
  className?: string;
  size?: number;
  color?: 'blue'|'white'|'purple';
}

const Spinner = (props: SpinnerProps) => (
  <div className={`flex items-center ${props.className || ''}`}>
    <FaCircleNotch className={`spinner text-${props.color}`} size={props.size} />
    {props.label ? <span className={`ml-5 text-${props.color}`}>{props.label}</span> : ''}
  </div>
);

Spinner.defaultProps = {
  size: 12,
  color: 'purple',
};

export default Spinner;
