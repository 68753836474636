import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Journey, MutationCreateFollowUpArgs, Doctor } from '../../../../graphql/genie-api-types';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import FollowUpForm, { FollowUpFormValues } from '../../../components/follow-up/FollowUpForm';
import { AWS_DATE_FORMAT } from '../../../../utilities/helpers';
import moment from 'moment';

interface ReferralCreateProps {
  onComplete(): void;
  journeyId: Journey['id'];
  doctorId?: Doctor['id'];
}

class ReferralCreate extends React.PureComponent<ReferralCreateProps> {
  onSaveReferral = async ({ doctorId, dateFollowUp, reason }: FollowUpFormValues, mutate: MutationFunction<MutationCreateFollowUpArgs>) => {
    const { onComplete, journeyId } = this.props;

    await mutate({
      variables: {
        input: {
          journeyId,
          doctorId,
          reason,
          dateFollowUp: dateFollowUp ? moment(dateFollowUp).format(AWS_DATE_FORMAT) : null,
        },
      },
    });

    showToast('success', 'Created follow up', 'Successfully created follow up');

    ReduxQueryListener.triggerEvents('All');

    onComplete();
  }

  render() {
    const { doctorId, journeyId } = this.props;
    return (
      <GenieMutation
        mutation={gql`
             mutation createFollowUp($input: CreateFollowUpInput!) {
               createFollowUp(input: $input) {
                followUp {
                   id,
                 }
               }
             }
           `}
      >
        {(mutate, result) => (
          <FollowUpForm
            initialValues={{
              doctorId,
            }}
            journeyId={journeyId}
            onSubmit={(values: FollowUpFormValues) => this.onSaveReferral(values, mutate)}
            isLoading={result.loading}
            errorMessage={result?.error?.message}
          />
        )}
      </GenieMutation>
    );
  }
}

export default ReferralCreate;
