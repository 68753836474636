import * as React from 'react';
import { cloneDeep, set, get } from 'lodash';
import { JSONSchema7 } from 'json-schema';
import formSchema from '../../../utilities/form-schemas/research-db-push.schema.json';
import SchemaTreeForm from '../schema-tree-form/SchemaTreeForm';
import { Extract } from '@eugenelabs/types--research-extract';
import Button from '../widgets/Button';
import { showToast } from '../../store/toast/actions';
import { FiCheck, FiCopy } from '../widgets/Icon';

interface ResearchExtractFormProps {
  values: Partial<Extract>;
  onChange(values: Partial<Extract>): void;
  onChangeValidation(isValid: boolean): void;
}

const helpTexts = {
  'request_id': 'The Invitae RQ Number, not editable. If this is incorrect, edit the original request and reload this form.',
  'date_tested': 'e.g. 2020-05-02',
  'results_date': 'e.g. 2020-05-02',
  'history_disease': 'e.g. Cancer',
  'test_type': '"CARRIER_CP" referrers to couples carrier and "CARRIER_IN" refers to individual carrier',
};

const hiddenFields = ['referring_doctor_id', 'member_id', 'report_id'];
const readOnlyFields = ['request_id'];
const preventArrayAdditionRefs = ['#/definitions/Member'];

const ResearchExtractForm = (props: ResearchExtractFormProps) => {
  const onCopyFirstMemberFieldValueToOtherMembers = (fieldName: string) => () => {
    const members = props.values?.members?.slice(0) ?? [];
    
    const member0Value = get(props.values, fieldName);

    for (let i = 1; i < members.length; i++) {
      members[i] = cloneDeep(members[i]);
      set(members[i], fieldName.replace('members[0].', ''), member0Value);
    }

    props.onChange({
      ...props.values,
      members,
    });
    
    showToast('info', 'Copied across', `Copied ${fieldName}:${member0Value} to other members`);
  };

  const suffixForField = (fieldName: string) => {
    const copyableFieldNames = [
      'client_initiated',
      'test.location',
      'test.relationship_status',
      'test.test_type',
      'referral_source',
      'english_speaking',
    ]
    if (copyableFieldNames.includes(fieldName.replace('members[0].', ''))) {
      const isEqual = props.values?.members?.length > 1 && get(props.values, fieldName) === get(props.values, fieldName.replace('members[0]', 'members[1]'));
      return (
        <Button
          size="sm"
          className="ml-5"
          onClick={onCopyFirstMemberFieldValueToOtherMembers(fieldName)}
          variant={isEqual ? 'success' : 'primary'}
        >
          {isEqual ? <FiCheck className="mb-1" /> : <FiCopy className="mb-1" />} Cop{isEqual ? 'ied' : 'y'} across members
        </Button>
      );
    }

    return null;
  };

  return (
    <div>
      <SchemaTreeForm
        jsonSchema={formSchema as JSONSchema7}
        rootObjectLabel="Report Extract"
        hiddenFields={hiddenFields}
        readOnlyFields={readOnlyFields}
        values={props.values}
        onChange={props.onChange}
        onChangeValidation={props.onChangeValidation}
        fieldHelpTexts={helpTexts}
        preventArrayAdditionRefs={preventArrayAdditionRefs}
        suffixForField={suffixForField}
      />
    </div>
  );
};

export default ResearchExtractForm;
