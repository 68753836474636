import { RequestDiseasesByGeneResult } from '../../../graphql/requests/requestDiseasesByGene';
import { RequestCarrierStatisticsByGeneResult } from '../../../graphql/requests/requestCarrierStatisticsByGene';

export enum ClientDetailsActionTypes {
  CHANGE_FIELDS = '@@report/client-details/CHANGE_FIELDS',
  RECEIVE_DISEASES = '@@report/client-details/RECEIVE_DISEASES',
  RECEIVE_DISEASES_BEGIN = '@@report/client-details/RECEIVE_DISEASES_BEGIN',
  RECEIVE_DISEASES_COMPLETE = '@@report/client-details/RECEIVE_DISEASES_COMPLETE',
  RECEIVE_PSEUDO_DISEASES = '@@report/client-details/RECEIVE_PSEUDO_DISEASES',
  RECEIVE_PSEUDO_DISEASES_BEGIN = '@@report/client-details/RECEIVE_PSEUDO_DISEASES_BEGIN',
  RECEIVE_PSEUDO_DISEASES_COMPLETE = '@@report/client-details/RECEIVE_PSEUDO_DISEASES_COMPLETE',
  RECEIVE_CARRIER_RATES = '@@report/client-details/RECEIVE_CARRIER_RATES',
  SWAP_CLIENTS = '@@report/client-details/SWAP_CLIENTS',
}

export interface ClientDetailsState {
  clientA: ClientFields;
  clientB: ClientFields;
  diseasesByGene: RequestDiseasesByGeneResult;
  carrierRatesByGene: RequestCarrierStatisticsByGeneResult;
  pseudoDiseasesByGene: RequestDiseasesByGeneResult;
  isLoadingDiseases: boolean;
  isPseudoDiseasesLoading: boolean;
}

export interface ClientFields {
  fullName?: string;
  nickname?: string;
  dob?: string;
  sex?: '' | 'Female' | 'Male';
  sampleCollectionDate?: string;
  reportProcessedDate?: string;
  affectedGenes?: string[];
  pseudogenes?: string[];
  ethnicity?: string;
  memberId?: string;
  partnerCanReceiveResults?: boolean | null;
}

export type ClientId = 'A'|'B';
