import * as React from 'react';
import { UpdateCounsellorInput, UpdateCounsellorResult } from '../../../../graphql/genie-api-types';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { showToast } from '../../../store/toast/actions';
import CounsellorForm from '../../../components/counsellor/CounsellorForm';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';

interface CounsellorUpdateProps {
  onUpdate?(counsellor: UpdateCounsellorResult['counsellor']): void;
  onCancel?(): void;
  counsellorId: string;
}

const LIST_COUNSELLORS = gql`
  query {
    listCounsellors {
      id
      name
      nickname
      admin {
        id
        email
      }
    }
  }
`;

const GET_COUNSELLOR = gql`
  query counsellor( $id: String!) {
    counsellor (id:$id) {
      id
      name
      nickname
      admin{
        id
        email
      }
    }
  }
`;

const UPDATE_COUNSELLOR = gql`
  mutation UpdateCounsellor($input: UpdateCounsellorInput!) {
    updateCounsellor(input: $input) {
      counsellor {
        id,
        name,
        nickname,
        admin {
          id
          email
        }
      }
    }
  }
`;


const CounsellorUpdate = (props: CounsellorUpdateProps) => {
  const [updateCounsellor, { loading }] = useMutation(UPDATE_COUNSELLOR, {
    refetchQueries: () => [{
      query: LIST_COUNSELLORS
    }],
  });

  const initialVals = useQuery(GET_COUNSELLOR, {
    variables: {
      id: props.counsellorId
    }
  })

  const onSubmit = async (values: UpdateCounsellorInput) => {
    const res = await updateCounsellor({
      variables: {
        input: {
          id: props.counsellorId,
          name: values.name,
          nickname: values.nickname,
          adminId: values.adminId || null
        }
      }
    });

    if (res.data?.updateCounsellor?.counsellor && props.onUpdate) {
      props.onUpdate(res.data?.updateCounsellor?.counsellor);
      showToast('success', 'Counsellor has been updated', `${res.data?.updateCounsellor?.counsellor.name}`)
    } else {
      showToast('error', 'Cannot update counsellor due to', `${res.errors?.toString}`)
    }
  }

  if (initialVals.loading) {
    return <LoadingOverlay />
  }

  return (
    <CounsellorForm
      initialValues={{
        name: initialVals?.data?.counsellor?.name,
        nickname: initialVals?.data?.counsellor?.nickname || "",
        adminId: initialVals?.data?.counsellor?.admin?.id || ""
      }}
      onCancel={props.onCancel}
      isLoading={loading}
      onSubmit={onSubmit}
    />
  )

};

export default CounsellorUpdate;
