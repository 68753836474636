import { Member, QueryListJourneysArgs, Request, Mutation, UpdateRequestInput, QueryRequestArgs, Journey, Pregnancy, JourneyState, MutationUpdateRequestArgs, Note, CreateRequestNoteInput, MutationCreateRequestNoteArgs } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import gql from 'graphql-tag';
import updateJourneyState from './updateJourneyState';
import { showToast } from '../../../store/toast/actions';
import { DoctorClinicPartial, DOCTOR_CLINIC_FRAGMENT } from '../../../../utilities/doctorClinic';

interface PregnancyPartial extends Pick<Pregnancy, 'dueDate' | 'dueDateConfirmed'> {
  __typename?: string;
}

interface PrimaryRequest extends Pick<Request, 'id' | 'product'> {
  referringDoctorClinic?: DoctorClinicPartial;
  pregnancy?: PregnancyPartial;
  journeys: {
    id: Journey['id'];
    member: {
      id: Member['id'];
    };
  }[];
}

interface RequestResult {
  request: PrimaryRequest;
}

interface DuplicateJourneyRequestJourney extends Pick<Journey, 'id'> {
  member: Pick<Member, 'id'>;
  request?: DuplicateJourney['request'];
}

type RequestNote = Pick<Note, 'date'|'isImportant'|'text'|'username'>;

interface DuplicateJourney extends Pick<Journey, 'id'> {
  request?: {
    id: Request['id'];
    referringDoctorClinic?: DoctorClinicPartial;
    pregnancy?: PregnancyPartial;
    product?: Request['product'];
    journeys?: DuplicateJourneyRequestJourney[];
    notes?: RequestNote[];
  };
}

interface DuplicateListJourneysResult {
  listJourneys: {
    edges: {
      node: DuplicateJourney;
    }[];
  };
}

type DuplicateJourneysByRequestId = { [key: string]: DuplicateJourney[] } ;

export const filterDuplicateJourneys = (primaryRequest: PrimaryRequest, memberJourneys: DuplicateJourney[]) => {
  const duplicateJourneyIdsByRequestId: DuplicateJourneysByRequestId = {};

  const primaryRequestMemberIds = primaryRequest?.journeys?.map(journey => journey.member?.id) ?? [];

  const journeysWithRequestsWithBothMembersAndSameProduct = memberJourneys
    .filter(journey => journey.request.product)
    .filter(journey => journey.request.product === primaryRequest?.product)
    // Contains same members.
    .filter(journey => journey.request.journeys.filter(journey => primaryRequestMemberIds.includes(journey.member.id)).length === primaryRequestMemberIds.length);

  journeysWithRequestsWithBothMembersAndSameProduct.forEach(baseJourney => {
    duplicateJourneyIdsByRequestId[baseJourney.request.id] = baseJourney.request.journeys.map(journey => ({
      ...journey,
      request: baseJourney.request,
    }));
  });

  return duplicateJourneyIdsByRequestId;
};

type ExtractedFields = Pick<UpdateRequestInput, 'referringDoctorClinicId' | 'pregnancy'>;

export const extractMergedRequestFields = (primaryRequest: PrimaryRequest, duplicateJourneysByRequestId: DuplicateJourneysByRequestId): ExtractedFields => {
  const originalReferringDoctorClinicId = primaryRequest.referringDoctorClinic?.id;
  let referringDoctorClinicId = originalReferringDoctorClinicId;

  const originalPregnancy = primaryRequest.pregnancy;
  let pregnancy = originalPregnancy;

  Object.keys(duplicateJourneysByRequestId).forEach(requestId => {
    const request = duplicateJourneysByRequestId[requestId][0].request;
    if (!referringDoctorClinicId && request.referringDoctorClinic?.id) {
      referringDoctorClinicId = request.referringDoctorClinic.id;
    }
    if (!pregnancy && request.pregnancy) {
      pregnancy = request.pregnancy;
    }
  });
  
  const fields: ExtractedFields = {};
  
  if (originalPregnancy !== pregnancy) {
    const { __typename, ...pregnancyInput } = pregnancy;
    fields.pregnancy = pregnancyInput;
  }
  if(originalReferringDoctorClinicId !== referringDoctorClinicId) {
    fields.referringDoctorClinicId = referringDoctorClinicId;
  }
  
  return fields;
};

export const extactRequestNoteInputs = (duplicateJourneysByRequestId: DuplicateJourneysByRequestId): Omit<CreateRequestNoteInput, 'requestId'>[] => {
  const requestNotes: RequestNote[] = [];
  
  Object.entries(duplicateJourneysByRequestId).forEach(([, journeys]) => {
    journeys?.[0]?.request?.notes?.forEach(note => {
      requestNotes.push(note);
    });
  });
  
  const requestNoteInputs: Omit<CreateRequestNoteInput, 'requestId'>[] = requestNotes.map(note => ({
    isImportant: note.isImportant,
    text: `${note.text} -- Originally by ${note.username}, migrated from request in trashed journey.`,
  }));

  return requestNoteInputs;
}

const deduplicateMemberRequests = async (primaryRequestId: Request['id']) => {
  const primaryRequestQuery = await appSyncClient.query<RequestResult, QueryRequestArgs>({
    query: gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          product
          referringDoctorClinic {
            id
          }
          pregnancy {
            dueDate
            dueDateConfirmed
          }
          journeys {
            id
            member {
              id
            }
          }
        }
      }
    `,
    variables: {
      id: primaryRequestId,
    },
  });

  const primaryRequest = primaryRequestQuery.data?.request;
  if (!primaryRequest) {
    return;
  }

  const memberIds = primaryRequest?.journeys?.map(journey => journey.member?.id) ?? [];

  const memberJourneysQueries = await Promise.all(memberIds.map(memberId => appSyncClient.query<DuplicateListJourneysResult, QueryListJourneysArgs>({
    query: gql`
      query ListJourneys($input: ListJourneysInput!) {
        listJourneys(input: $input) {
          edges {
            node {
              id
              state
              request {
                id
                product
                referringDoctorClinic {
                  ...DoctorClinicFields
                }
                pregnancy {
                  dueDate
                  dueDateConfirmed
                }
                journeys {
                  id
                  member {
                    id
                  }
                }
                notes {
                  date
                  isImportant
                  text
                  username
                }
              }
            }
          }
        }
      }
      ${DOCTOR_CLINIC_FRAGMENT}
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        excludeCompleted: true,
        paid: false,
        memberId,
      },
    },
  })));

  let memberJourneys: DuplicateJourney[] = [];

  memberJourneysQueries.forEach(memberJourneyQuery => {
    const thisMemberJourneys = memberJourneyQuery.data?.listJourneys?.edges
      .map(edge => edge.node);
    memberJourneys = [...memberJourneys, ...thisMemberJourneys];
  });

  const duplicateJourneysByRequestId = filterDuplicateJourneys(primaryRequest, memberJourneys);
  
  const mergedFields = extractMergedRequestFields(primaryRequest, duplicateJourneysByRequestId);

  // Update main request with pregnancy and doctor data.
  if (Object.keys(mergedFields).length > 0) {
    appSyncClient.mutate<Pick<Mutation, 'updateRequest'>, MutationUpdateRequestArgs>({
      mutation: gql`
        mutation UpdateRequest($input: UpdateRequestInput!) {
          updateRequest(input: $input) {
            request {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          requestId: primaryRequestId,
          ...mergedFields,
        }
      }
    });
  }
  
  // Move request notes over.
  const requestNoteInputs = extactRequestNoteInputs(duplicateJourneysByRequestId);
  await Promise.all(requestNoteInputs.map(requestNoteInput => {
    return appSyncClient.mutate<Pick<Mutation, 'createRequestNote'>, MutationCreateRequestNoteArgs>({
      mutation: gql`
        mutation CreateRequestNote($input: CreateRequestNoteInput!) {
          createRequestNote(input: $input) {
            request {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          ...requestNoteInput,
          requestId: primaryRequest.id,
        },
      },
    });
  }));
  
  if (requestNoteInputs.length > 0) {
    showToast('info', `Merged complete`, `Migrated ${requestNoteInputs.length} notes from other requests.`);
  }

  // Trash journeys.
  let mergeCount = 0;
  await Promise.all(Object.keys(duplicateJourneysByRequestId).map(async (requestId) => {
    const journeys = duplicateJourneysByRequestId[requestId];
    
    mergeCount += journeys.length;

    await Promise.all(journeys.map(journey => updateJourneyState(journey.id, JourneyState.Trash)));
  }));
  
  if (mergeCount > 0) {
    showToast('info', `Merged complete`, `Merged and trashed ${mergeCount} related journeys in unpaid requests.`);
  }
};

export default deduplicateMemberRequests;