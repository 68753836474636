import * as React from 'react';
import { QueryListRequestsArgs, Query, Request, ListRequestsInput } from '../../../graphql/genie-api-types';
import GeniePagedQuery from '../base/GeniePagedQuery';

interface ListRequestsPagedQueryProps<Data, Vars> {
  query: any;
  itemsPerPage?: number;
  children: (requests: Request[], isLoading: boolean) => React.ReactNode;
  input: ListRequestsInput;
}

class ListRequestsPagedQuery<Data, Vars> extends React.PureComponent<ListRequestsPagedQueryProps<Data, Vars>> {
  static defaultProps = {
    itemsPerPage: 20,
  };

  render() {
    const { children, itemsPerPage, input, query } = this.props;

    return (
      <GeniePagedQuery<Pick<Query, 'listRequests'>, QueryListRequestsArgs>
        query={query}
        itemsPerPage={itemsPerPage}
        pageInfoSelector="listRequests.pageInfo"
        edgeSelector="listRequests.edges"
        variables={{
          input,
        }}
      >
        {(queryResult, isLoading) => {
          const edgeRequests = queryResult?.listRequests?.edges?.map(edge => edge.node) as Request[] ?? [];
          return children(edgeRequests, isLoading);
        }}
      </GeniePagedQuery>
    );
  }
}

export default ListRequestsPagedQuery;
