import * as React from 'react';
import { DocumentNode } from 'graphql';
import { ListKitsInput, Journey, Kit, Member, Request, Pregnancy, IncludeRelatedKitsFlag } from '../../../../graphql/genie-api-types';
import JourneyTable, { JourneyTableProps } from '../../../components/widgets/JourneyTable';
import { Action } from '../../../components/widgets/DropdownButton';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import { Row } from '../../../components/widgets/Table';
import TabsNav from '../../../components/widgets/TabsNav';
import { TabOption } from '../../../components/widgets/Tabs';
import SearchForm from '../../../components/widgets/SearchForm';
import LazyLoader from '../../../components/widgets/LazyLoader';
import usePagedQuery, { PagedQueryResult } from '../../../../utilities/usePagedQuery';
import { FLAT_PRODUCT_LIST } from '../../../../utilities/journeys/products';
import { getProductVariantStrategy } from '../../../../utilities/journeys/productVariantTable';

export interface OpsKitJourney extends Required<Pick<Journey, 'id' | 'state' | 'hasRecollection'>> {
  member: Required<Pick<Member, 'id' | 'name' | 'sex'>> | null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate' | 'dueDateConfirmed' | 'days'>;
    journeys: (Required<Pick<Journey, 'id' | 'state'>> & {
      member: Required<Pick<Member, 'id' | 'name'>>;
    })[];
  };
}

export interface OpsKit<JourneyRow extends OpsKitJourney> extends Pick<Kit, 'id' | 'dateShippedToMember' | 'registrationNumber'> {
  journey: JourneyRow;
}

interface OpsKitsTableProps<KitRow extends OpsKit<OpsKitJourney>> {
  query: DocumentNode;
  queryInput: ListKitsInput;
  header: JourneyTableProps<KitRow['journey']>['header'];
  cellsForJourney(journey: KitRow['journey'], kit: KitRow): Row['cells'];
  onActionClick?(kitIds: Kit['id'][], actionName: string): Promise<void>;
  actionsForJourney?: JourneyTableProps<KitRow['journey']>['actionsForJourney'];
}

type QueryKitsConnectionResult<Kit extends OpsKit<OpsKitJourney>> = {
  edges: {
    cursor: string;
    node: Kit;
  }[];
  totalRows: number;
}

const updateKitAction = 'updateKitAction';
const markAsArrivedAction = 'markAsArrived';

const buildTabInput = (input: ListKitsInput, memberSearchTerm: string, kitSearchTerm: string) => {
  const inputs: Array<[string, ListKitsInput ]> = [
    ['All', {
      ...input,
      ...(memberSearchTerm && {
        memberSearch: memberSearchTerm,
        includeRelatedKits: IncludeRelatedKitsFlag.InScope,
      }),
      ...(kitSearchTerm && {
        registrationNumberSearch: kitSearchTerm,
        includeRelatedKits: IncludeRelatedKitsFlag.InScope,
      }),
    }]
  ];

  for (const [product, variant] of FLAT_PRODUCT_LIST) {
    const strategy = getProductVariantStrategy(product, variant);
    inputs.push([strategy.shortTitle, {
      ...input,
      product: product,
      productVariant: variant,
      isOnRecollectedJourney: false
    }]);
  }

  inputs.push(['Recollections', {
    ...input,
    isOnRecollectedJourney: true,
  }]);

  return inputs;
}

export default function OpsKitsTable<KitRow extends OpsKit<OpsKitJourney>>(props: OpsKitsTableProps<KitRow>) {

  const [currentTab, setTabCurrentTab] = React.useState<string>('All');
  const [memberSearchTerm, setMemberSearchTerm] = React.useState<string>('');
  const [kitSearchTerm, setKitSearchTerm] = React.useState<string>('');
  const hasSearchTerm = !!(memberSearchTerm || kitSearchTerm);

  const tabInput = buildTabInput(props.queryInput, memberSearchTerm, kitSearchTerm);
  const batchSize = 50;
  const tabQuery: Partial<Record<string, PagedQueryResult<QueryKitsConnectionResult<KitRow>>>> = {};
  for (const [tab, input] of tabInput) {
    tabQuery[tab] = usePagedQuery<QueryKitsConnectionResult<KitRow>>(props.query, input, 'listKits', batchSize);
  }
  
  const { isLoading, data, loadMore } = tabQuery[hasSearchTerm ? 'All' : currentTab];

  const dispatch = useDispatch();
  const nextPageRowCount = Math.min(batchSize, data?.totalRows - data?.edges?.length);
  const kits = data?.edges?.map(edge => edge.node) ?? [];

  const kitJourneysByRequestId: { [key: string]: KitRow["journey"][] } = {};
  kits.forEach((kit) => {
    kitJourneysByRequestId[kit.journey.request.id] = kitJourneysByRequestId[kit.journey.request.id] ?? [];
    kitJourneysByRequestId[kit.journey.request.id].push(kit.journey);
  });
  const orderedRequestIds = Object.keys(kitJourneysByRequestId);

  const kitsByJourneyId: { [key: string]: KitRow } = {};
  kits.forEach(kit => {
    kitsByJourneyId[kit.journey.id] = kit;
  });

  const cellsForJourney = (journey: KitRow['journey']) => {
    const kit = kitsByJourneyId[journey.id];
    return props.cellsForJourney(journey, kit);
  };

  const reload = async () => {
    window.scrollTo(0, 0);
    Object.values(tabQuery).forEach(query => query.refetch());
  };

  const onActionClick = async (journeyIds: string[], actionName: string) => {
    const kitIds = data?.edges?.map(edge => edge.node)
      .filter(kit => journeyIds.includes(kit.journey.id))
      .map(kit => kit.id);

    if (props.onActionClick) {
      await props.onActionClick(kitIds, actionName);
    }

    switch (actionName) {
      case updateKitAction: {
        dispatch(navigate.toForm('KitsUpdate', { kitIds }));
        break;
      }
    }
  };

  const onActionComplete = async (rowIds: string[], actionName: string) => {
    if (actionName !== updateKitAction && actionName !== markAsArrivedAction) {
      await reload();
    }
  };

  const actionsForJourney = (journey: KitRow["journey"]) => {
    let actions: Action<string>[] = [];
    if (props.actionsForJourney) {
      actions = [...props.actionsForJourney(journey)];
    }

    actions.push({
      name: updateKitAction,
      label: 'Edit kit',
      variant: 'success',
    });

    return actions;
  };

  const tabs: TabOption<string>[] = tabInput.map(([tabName]) => ({
    label: tabName,
    name: tabName,
    count: tabQuery[tabName].data?.totalRows ?? 0,
  }));

  const searchTab = [{
    label: 'Search Results',
    name: 'Search Results',
    count: tabQuery['All'].data?.totalRows ?? 0,
  }];

  return (
    <LazyLoader
      isLoading={isLoading}
      loadMore={loadMore}
      nextPageRowCount={nextPageRowCount}>
      <div className="flex">
        <SearchForm
          isLoading={isLoading && !!memberSearchTerm}
          onSearchChange={setMemberSearchTerm}
          defaultSearchTerm={memberSearchTerm}
          placeholder="Search by name or email"
          debounceTime={200}
          className="w-1/2 pr-4"
        />
        <SearchForm
          isLoading={isLoading && !!kitSearchTerm}
          onSearchChange={setKitSearchTerm}
          defaultSearchTerm={kitSearchTerm}
          placeholder="Search by kit number"
          debounceTime={200}
          className="w-1/2 pl-4"
        />
      </div>
      <TabsNav
        isLoading={isLoading}
        tabs={hasSearchTerm ? searchTab : tabs}
        onSelectTab={(tabName: string) => setTabCurrentTab(tabName)}
        activeTab={hasSearchTerm ? 'Search' : currentTab}
        isDisabled={hasSearchTerm}
      />
      <div key={currentTab}>
        <JourneyTable<KitRow['journey']>
          journeysByRequestId={kitJourneysByRequestId}
          orderedRequestIds={orderedRequestIds}
          onActionClick={(rowIds, actionName) => onActionClick(rowIds, actionName)}
          onActionComplete={onActionComplete}
          header={props.header}
          cellsForJourney={cellsForJourney}
          onRefresh={reload}
          shouldPersistRefreshButton
          actionsForJourney={actionsForJourney}
          isLoading={isLoading}
          emptyText="All done!"
        />
      </div>
    </LazyLoader>
  );
}
