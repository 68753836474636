import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import { FiChevronRight, FiInbox } from './Icon';
import { isDev } from '../../../utilities/helpers';
import { getSignOutUrl } from '../../../utilities/amplify/auth';
import CognitoFederatedSignOut from '../auth/CognitoFederatedSignOut';

export type SidebarItem = {
  label: string;
  path: string;
  icon?: string;
  emojiIcon?: string;
  badge?: string;
  subItems?: SidebarItem[];
  exact?: boolean;
  unreadCallback?: () => Promise<number>;
};

interface SidebarState {
  isOpen: boolean;
}

interface SidebarProps extends RouteComponentProps {
  footerItems?: SidebarItem[];
  workspaceItems?: SidebarItem[];
}

const getIconComponent = (iconName: string) => {
  switch (iconName) {
    case 'inbox':
      return <FiInbox />;

    default:
      return null;
  }
};

interface SidebarItemProps {
  item: SidebarItem;
  level: number;
  pathName: string;
  onToggleOpen(): void;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { level, item, pathName, onToggleOpen } = props;
  const leftPadding = (level + 1) * 15;

  const [unreadCount, setUnreadCount] = React.useState(0);

  const syncUnreadCount = async () => {
    if (item.unreadCallback) {
      const itemUnreadCount = await item.unreadCallback();
      setUnreadCount(itemUnreadCount);
    }
  };

  React.useEffect(() => {
    syncUnreadCount();
  }, [props.pathName]);

  return (
    <div>
      <NavLink
        to={item.path}
        className={`py-7 mb-5 -mr-15 inline-block text-grey-dark align-middle text-sm font-md san-serif flex items-center pr-15 pl-${leftPadding} lg:py-10`}
        activeStyle={{
          color: '#333',
          background: '#eff0fd',
        }}
        exact={item.exact}
        onClick={onToggleOpen}
      >
        {item.subItems ? <FiChevronRight className="w-4 align-middle text-grey-dark mr-5" /> : <span className="ml-5 align-text-bottom text-grey w-4">•</span>}
        {item.icon ? <span className="w-4 align-text-top text-grey-dark mr-5 mt-1 inline-block">{getIconComponent(item.icon)}</span> : null}
        {item.emojiIcon ? <span className="mr-5">{item.emojiIcon}</span> : null}
        {item.label}
        {item.badge ? (
          <span className="bg-red ml-5 align-middle right-0 top-0 text-white px-5 py-1 rounded-full text-xxs font-md items-center justify-right flex items-center justify-center">
            <span>{item.badge}</span>
          </span>
        ) : null}
        {unreadCount ? (
          <span
            className="font-bold bg-red ml-5 align-middle right-0 top-0 text-white px-5 py-1 rounded-full font-md items-center justify-right flex items-center justify-center"
            style={{ width: '15px', height: '15px', fontSize: '10px' }}
          >
            <span>{unreadCount}</span>
          </span>
        ) : null}
      </NavLink>
      {item.subItems && pathName.indexOf(item.path) > -1 ? (
        <div>
          {item.subItems.map(item => (
            <SidebarItem item={item} level={level + 1} pathName={pathName} onToggleOpen={onToggleOpen} key={item.path} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

class Sidebar extends React.PureComponent<SidebarProps> {
  state: Readonly<SidebarState> = {
    isOpen: false,
  };

  static defaultProps: SidebarProps = {
    footerItems: [],
    workspaceItems: [],
    history: null,
    location: null,
    match: null,
  };

  onToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { footerItems, workspaceItems, location } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={`w-sidebar h-screen bg-grey-light no-scrollbars sticky top-0 overflow-y-auto scrolling-touch lg:bg-transparent lg:w-full lg:h-16 lg:z-50 print:hidden ${
          isOpen ? 'lg:overflow-y-visible' : 'lg:overflow-hidden'
        }`}
      >
        <div className="sidebar group py-10 w-full lg:py-0">
          <div className="content-start w-full">
            <div className="lg:flex lg:flex-row lg:flex-nowrap lg:bg-grey-light lg:w-full lg:pt-12 lg:pb-10">
              <div className={`group hidden lg:flex flex-col flex-wrap w-6 h-6 ml-20 mt-8 mr-6 cursor-pointer relative ${isOpen ? 'pt-8' : ''}`} onClick={this.onToggleOpen}>
                <span className={`w-full h-2px bg-purple mb-5 group-hover:bg-blue ${isOpen ? 'rotate-45 absolute' : ''}`}></span>
                <span className={`w-full bg-purple mb-5 group-hover:bg-blue ${isOpen ? 'h-0' : 'h-2px'}`}></span>
                <span className={`w-full h-2px bg-purple group-hover:bg-blue ${isOpen ? 'rotate-n45 absolute' : ''}`}></span>
              </div>

              <Link to="">
                <img className="w-14 my-5 mx-15" src="/assets/images/genie-logo.png" />
                {isDev() && <span className="font-bold rounded px-5 py-2 text-white bg-purple text-sm ">DEVELOPMENT</span>}
              </Link>
            </div>
            <CognitoFederatedSignOut signOutUrl={getSignOutUrl()} />
            <div className={`${isOpen ? 'lg:w-1/3 lg:bg-grey-light lg:absolute lg:z-50 h-screen lg:overflow-hidden lg:shadow-2xl' : ''} `}>
              <a className="hidden group-hover:inline" href="no-nav.html">
                <i className="w-8 mt-4 -mr-10 align-middle  float-right text-grey " data-feather="chevrons-left">
                  {' '}
                </i>
              </a>

              {/* <div className="mt-20 py-3 px-15 ">
                <i className="w-4 align-text-top text-grey-dark " data-feather="search"> </i> <a className="text-grey-dark align-middle text-sm font-md san-serif">Quick Find</a>
              </div> */}

              <div className="mt-40 mx-15 mb-10 lg:mt-20">
                <p className="text-grey align-middle uppercase tracking-wide font-bold text-xxs">Workspace</p>
              </div>
              {workspaceItems.map(item => (
                <SidebarItem item={item} level={0} pathName={location.pathname} key={item.path} onToggleOpen={this.onToggleOpen} />
              ))}

              <div className="mt-40 mx-15 mb-10 lg:mt-20">
                <p className="text-grey align-middle uppercase tracking-wide font-bold text-xxs">About Genie</p>
              </div>
              {footerItems.map(item => (
                <SidebarItem item={item} level={0} pathName={location.pathname} key={item.path} onToggleOpen={this.onToggleOpen} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
