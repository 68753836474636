import * as React from 'react';
import { flatten } from 'lodash';
import { JourneyState, PageInfo, Pagination, QueryListJourneysArgs } from '../../../../graphql/genie-api-types';
import convertJourneyResultsToJourneyTableData from '../../../graphql/processors/convertJourneyResultsToJourneyTableData';
import JourneyTable, { JourneyRowPartial, JourneyTableProps } from '../../widgets/JourneyTable';
import Pager from '../../widgets/Pager';
import { ORDERED_JOURNEY_STATES } from '../../../../utilities/journeys/states';
import { useLazyQuery } from 'react-apollo';
import { DocumentNode } from 'graphql';
import useToggleState from '../../../../utilities/useToggleState';

interface OwnProps<JourneyRow extends JourneyRowPartial> {
  listJourneysQuery: DocumentNode;
  journeyStates: JourneyState[];
  header: JourneyTableProps<JourneyRow>['header'];
  cellsForJourney: JourneyTableProps<JourneyRow>['cellsForJourney'];
  emptyText?: JourneyTableProps<JourneyRow>['emptyText'];
  actionsForJourney?: JourneyTableProps<JourneyRow>['actionsForJourney'];
  title?: string;
  excludeOnHold?: boolean;
}

export const PAGE_AMOUNT = 50;

interface JourneyQueryResult<JourneyRow extends JourneyRowPartial> {
  listJourneys: {
    edges: {
      cursor: string;
      node: JourneyRow;
    }[];
    pageInfo: PageInfo;
  };
}

function JourneyRows<JourneyRow extends JourneyRowPartial>(props: OwnProps<JourneyRow>) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [excludeOnHold, toggleExcludeOnHold] = useToggleState(true);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [finishingPages, setFinishingPages] = React.useState<(number|null)[]>(ORDERED_JOURNEY_STATES.map(() => null));

  const statePagination = ORDERED_JOURNEY_STATES.map(() => React.useState<Pagination>(null));

  // Hooks require that for each component render, the quantity of hooks
  // do not change, therefore every state needs to have its own query but
  // only some will execute.
  const journeyStateQueries = ORDERED_JOURNEY_STATES.map((state, index) => {
    return useLazyQuery<JourneyQueryResult<JourneyRow>, QueryListJourneysArgs>(props.listJourneysQuery, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        input: {
          state,
          excludeOnHold,
          paid: true,
          pagination: statePagination[index][0] ?? { first: PAGE_AMOUNT },
        },
      },
    });
    
  });

  const { journeyStates, cellsForJourney, emptyText, header, actionsForJourney } = props;

  const journeyQueryIndexes = journeyStates.map((journeyState: JourneyState) => ORDERED_JOURNEY_STATES.indexOf(journeyState));
  const journeyQueriesForRows = journeyStateQueries.filter((query, index) => {
    return journeyQueryIndexes.includes(index) &&
      // Remove queries which didn't have enough pages to make it here. e.g. If a REQUIRE_RESULTS
      // query has 5 pages but a REQUIRE_PRE_TEST_BOOKING has 1 page, at page 2 only REQUIRE_RESULTS
      // journeys will be shown.
      (finishingPages[index] === null || currentPage <= finishingPages[index]);
  });

  const onLoadJourneys = () => journeyQueriesForRows.forEach(([query]) => {
    query();
  });

  React.useEffect(() => {
    onLoadJourneys();
    const loadJourneysPoller = setInterval(onLoadJourneys, 20000);

    return () => {
      clearInterval(loadJourneysPoller);
    };
  }, [journeyStates]);

  const isLoading = !!journeyQueriesForRows.find(([, result]) => result.loading || !result.data);
  const journeyRows = flatten<JourneyRow>(journeyQueriesForRows.map(([, result]) =>
    result.data?.listJourneys?.edges?.map(edge => edge.node).filter(journey => journeyStates.includes(journey.state))).filter(results => results)).reverse();
    
  const tableData = convertJourneyResultsToJourneyTableData<JourneyRow>(journeyRows);

  const scrollToTop = () => {
    if (!wrapperRef.current) {
      return;
    }

    window.scroll({
      top: wrapperRef.current.offsetTop - 80,
      behavior: 'smooth',
    });
  };
  
  const journeyPagination = journeyStateQueries.map(([, result]) =>
    result.data?.listJourneys?.pageInfo);
    
  const onClickNextPage = () => {
    journeyPagination.forEach((pagination, index) => {
      if (!pagination) {
        return;
      }

      if (pagination.hasNextPage) {
        const lastCursor = journeyStateQueries[index][1]?.data?.listJourneys?.edges?.slice(0)?.reverse()?.[0]?.cursor;
        const [, setPagerCursor] = statePagination[index];
        setPagerCursor({ first: PAGE_AMOUNT, after: lastCursor });
      }
      else if (finishingPages[index] === null) {
        const newFinishingPages = [...finishingPages];
        newFinishingPages[index] = currentPage;
        setFinishingPages(newFinishingPages);
      }
    });
    scrollToTop();
    setCurrentPage(currentPage + 1);
  };

  const onClickPreviousPage = () => {
    journeyPagination.forEach((pagination, index) => {
      if (!pagination) {
        return;
      }
  
      if (pagination.hasPreviousPage) {
        const lastCursor = journeyStateQueries[index][1]?.data?.listJourneys?.edges?.[0]?.cursor;
        const [, setPagerCursor] = statePagination[index];
        setPagerCursor({ last: PAGE_AMOUNT, before: lastCursor });
      }
    });
    scrollToTop();
    setCurrentPage(currentPage - 1);
  };
  
  const getCombinedPager = (): Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'> => {
    const pager = {
      hasNextPage: false,
      hasPreviousPage: false,
    }

    journeyPagination.filter(pager => pager).forEach(journeyStatePager => {
      pager.hasNextPage = journeyStatePager.hasNextPage || pager.hasNextPage;
      pager.hasPreviousPage = journeyStatePager.hasPreviousPage || pager.hasPreviousPage;
    });

    return pager;
  }

  return (
    <div ref={wrapperRef}>
      <JourneyTable<JourneyRow>
        {...tableData}
        isLoading={isLoading}
        header={header}
        emptyText={emptyText ?? 'No journeys found.'}
        cellsForJourney={cellsForJourney}
        actionsForJourney={actionsForJourney}
        onRefresh={onLoadJourneys}
        onToggleOnHold={toggleExcludeOnHold}
        onHoldStatus={!excludeOnHold}
      />
      <Pager
        onClickNextPage={onClickNextPage}
        onClickPreviousPage={onClickPreviousPage}
        isLoading={isLoading}
        pageInfo={getCombinedPager()}
      />
    </div>
  );
};

export default JourneyRows;
