import { DocumentNode } from 'graphql';
import { OperationVariables, useQuery } from 'react-apollo';
import { QueryOptions } from 'apollo-client';
import { PageableData } from './runStatsQuery';

// This tracks query invocations. When a fresh query is run, if there are older queries
// of the same type running concurrently, prevent recursive fetching of additional pages
// only for the older query to prevent polluting the cache with duplicate data.
const queryCount: { [type: string]: number } = { };

const useStatsQuery = <TData extends PageableData, TVariables = OperationVariables>(query: DocumentNode, options: Omit<QueryOptions<TVariables>, 'query'>, queryName: string, batchSize = 100) => {
  const onQueryComplete = async (data: { [key: string]: TData }, count: number) => {
    if (count === queryCount[queryName] && data[queryName]?.pageInfo?.hasNextPage) {
      
      const defaultQueryVariables: any = options?.variables ?? {};
      const lastCursor = data?.[queryName]?.edges?.slice(0)?.reverse()?.[0]?.cursor;

      queryHook.fetchMore({
        variables: {
          ...defaultQueryVariables,
          input: {
            ...defaultQueryVariables?.input,
            pagination: {
              first: batchSize,
              after: lastCursor,
            },
          },
        },
        updateQuery: (previousQueryResult, result) => {
          if (count !== queryCount[queryName]) return previousQueryResult;
          const updatedResult = {
            [queryName]: {
              ...previousQueryResult[queryName],
              edges: [
                ...previousQueryResult[queryName]?.edges,
                ...result.fetchMoreResult?.[queryName]?.edges,
              ],
              pageInfo: result.fetchMoreResult?.[queryName]?.pageInfo,
            },
          };
          // Recursively iterate - must be done as side effect in update query because result of fetch more
          // does not contain merged result.
          onQueryComplete(updatedResult, count);
          return updatedResult;
        },
      });
    }
  };

  const initialVariables = {
    ...options.variables,
    input: {
      ...((options.variables as any)?.input ?? {}),
      pagination: {
        first: batchSize,
      },
    },
  };

  const onCompleted = (data: {[key: string]: TData }) => {
    if (data[queryName]?.pageInfo?.hasNextPage) {
      queryCount[queryName] = queryCount[queryName] ?? 0;
      queryCount[queryName]++;
      onQueryComplete(data, queryCount[queryName]);
    }
  }

  const queryHook = useQuery<{ [key: string]: TData }, TVariables>(query, {
    ...options,
    variables: initialVariables,
    notifyOnNetworkStatusChange: true,
    onCompleted: onCompleted,
  });
  
  const fetchStatsQuery = async () => {
    await queryHook.refetch(initialVariables);
  };

  return {
    ...queryHook,
    isLoading: queryHook.loading,
    data: queryHook?.data?.[queryName],
    refetch: fetchStatsQuery,
  };
};

export default useStatsQuery;
