import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import JourneysDuringGroup from '../../graphql/containers/journeys/groups/JourneysDuringGroup';
import Screen from '../../components/widgets/Screen';

const JourneysDuring = () => (
  <Screen>
    <Heading level={1} className="mb-20">Journeys: During</Heading>
    <JourneysDuringGroup />
  </Screen>
);

export default JourneysDuring;
