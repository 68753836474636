import { OperationVariables } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { PageInfo } from '../graphql/genie-api-types';
import { appSyncClient } from './appSync';
import { QueryOptions } from 'apollo-client';

export interface PageableData {
  edges?: {
    cursor?: string;
    node?: any;
  }[];
  pageInfo?: PageInfo;
}

const executeQuery = async <TData = PageableData, TVariables = OperationVariables>(query: DocumentNode, options: Omit<QueryOptions<TVariables>, 'query'>, queryName: string, batchSize: number, currentCursor: string, previousResult: PageableData) => {
  let result = previousResult;

  let queryVariables: any = options?.variables ?? {};
  queryVariables = {
    ...queryVariables,
    input: {
      ...queryVariables?.input,
      pagination: {
        first: batchSize,
        after: currentCursor,
      },
    },
  };
  const queryResponse = await appSyncClient.query<TData, TVariables>({
    query,
    variables: queryVariables,
    errorPolicy: 'ignore',
    fetchPolicy: options.fetchPolicy || 'network-only',
  });
  const queryResponseData = (queryResponse?.data as any)?.[queryName] as any as PageableData;
  const lastCursor = queryResponseData?.edges?.slice(0).reverse()?.[0]?.cursor;
  
  if (queryResponse?.errors) {
    console.error(queryResponse?.errors?.join("\n"));
  }

  result.edges = [
    ...result.edges,
    ...(queryResponseData?.edges ?? [])
  ];
  
  const hasNextPage = queryResponseData?.pageInfo?.hasNextPage;
  if (hasNextPage) {
    if (!lastCursor) {
      console.error('No cursor in query, could\'t iterate stats');
    }
    else {
      // Recursively iterate through pages of query.
      result = await executeQuery<TData, TVariables>(query, options, queryName, batchSize, lastCursor, result);
    }
  }
  
  return result;
}

const runStatsQuery = async<TData = PageableData, TVariables = OperationVariables>(query: DocumentNode, options: Omit<QueryOptions<TVariables>, 'query'> = {}, queryName: string, batchSize: number = 100): Promise<TData> => {
  let result: PageableData = { edges: [], pageInfo: { hasNextPage: false, hasPreviousPage: false } };

  // Run recursive async query.
  result = await executeQuery<TData, TVariables>(query, options, queryName, batchSize, undefined, result);

  return result as any as TData;
};

export default runStatsQuery;
