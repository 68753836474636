import * as React from 'react';
import { FiChevronDown, FiChevronRight } from './Icon';
import Heading, { HeadingProps } from './Heading';

interface CollapsibleProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  headingLevel?: HeadingProps['level'];
  defaultExpandedState?: boolean;
  iconClasses?: string;
  contentClass?: string;
}

interface CollapsibleState {
  isExpanded?: boolean;
}

export class Collapsible extends React.PureComponent<CollapsibleProps> {
  state: Readonly<CollapsibleState> = {
    isExpanded: null,
  };

  static getDerivedStateFromProps(props: CollapsibleProps, state: CollapsibleState) {
    return {
      ...state,
      isExpanded: state.isExpanded === null ? props.defaultExpandedState : state.isExpanded,
    };
  }

  static defaultProps = {
    headingLevel: 2,
    defaultExpandedState: true,
  };

  onToggleExpanded = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  render() {
    const { title, children, className, headingLevel, iconClasses, contentClass } = this.props;
    const { isExpanded } = this.state;

    const IconComponent = isExpanded ? FiChevronDown : FiChevronRight;

    return (
      <div className={className || ''}>
        <button className="flex items-center block w-full focus:outline-none" onClick={this.onToggleExpanded} type="button">
          <div className='print:hidden'>
            <IconComponent className={`w-4 h-4 align-middle text-5xl text-grey mr-10 ${iconClasses ? iconClasses : ''}`} />
          </div>
          <Heading level={headingLevel} className="print:ml-30 print:text-2xl">{title}</Heading>
        </button>

        {isExpanded && (
          <div className={contentClass}>{children}</div>
        )}
      </div>
    );
  }
}
