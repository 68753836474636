import { action } from 'typesafe-actions';
import { CarrierRatesActionTypes } from './types';
import { ClientId } from '../client-details/types';
import { ApplicationState } from '../..';
import { ThunkDispatch } from 'redux-thunk';

export const changeCarrierRateEthnicity = (gene: string, clientId: ClientId, ethnicityId: string) =>
  action(CarrierRatesActionTypes.CHANGE_CARRIER_RATE_ETHNICITY, {
    gene,
    clientId,
    ethnicityId,
  });

export const setCarrierRateDefaultsFromSelectedEthnicities = () =>
  async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ApplicationState) => {
    const { report } = getState();
    const { carrierRates, clientDetails } = report;
    const genes = Object.keys(clientDetails.diseasesByGene);

    const setCarrierRateDefaults = (clientId: ClientId) => {
      genes.forEach((gene) => {
        // No carrier rates for this gene have loaded.
        if (!clientDetails.carrierRatesByGene[gene]) {
          return;
        }
        const { selectedCarrierRateEthnicitiesByGene } = carrierRates;

        // Already has a set value.
        if (selectedCarrierRateEthnicitiesByGene[gene] && selectedCarrierRateEthnicitiesByGene[gene][clientId]) {
          return;
        }

        const clientFields = clientId === 'A' ? clientDetails.clientA : clientDetails.clientB;
        const carrierRatesForGene = clientDetails.carrierRatesByGene[gene];
        const hasCarrierRateForEthnicity = !!carrierRatesForGene.find(rate => rate.ethnicity.id === clientFields.ethnicity);

        // No carrier rates are available for the member's ethnicity.
        if (hasCarrierRateForEthnicity) {
          dispatch(changeCarrierRateEthnicity(gene, clientId, clientFields.ethnicity));
        } else {
          // Set pan ethnic as default if available.
          const panEthnicId = 'pan-ethnic';
          const hasPanEthnicRate = !!carrierRatesForGene.find(rate => rate.ethnicity.id === panEthnicId);
          if (hasPanEthnicRate) {
            dispatch(changeCarrierRateEthnicity(gene, clientId, panEthnicId));
          }
        }
      });
    };

    setCarrierRateDefaults('A');
    setCarrierRateDefaults('B');
  };
