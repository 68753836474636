import * as React from 'react';
import moment from 'moment';
import { JourneyState, Journey, Member, Kit, Request, Pregnancy } from '../../../../graphql/genie-api-types';
import JourneyRows from './JourneyRows';
import gql from 'graphql-tag';

const getSentFormattedDate = (journey: Partial<Journey>) => {
  const activeKitShippingDate = journey.kits?.filter(kit => !kit.recollectionRequestedDate)?.[0]?.dateShippedToLab;
  if (activeKitShippingDate) {
    return <div className="text-xs">{moment(activeKitShippingDate).fromNow()}</div>;
  }
  
  return <div className="text-xs">Not shipped</div>;
}

interface AwaitingResultsJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  kits?: Pick<Kit, 'id'|'dateShippedToLab'|'recollectionRequestedDate'>[];
  request: Required<Pick<Request, 'id'|'product'|'productVariant'|'onHoldDate'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          kits {
            id
            dateShippedToLab
            recollectionRequestedDate
          }
          request {
            id
            product
            productVariant
            onHoldDate
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const AwaitingResultsJourneys = () => (
  <JourneyRows<AwaitingResultsJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.WaitingForLabResults]}
    header={['Samples sent']}
    emptyText="No members samples are waiting for lab results"
    cellsForJourney={journey => [
      getSentFormattedDate(journey),
    ]}
  />
);

export default AwaitingResultsJourneys;
