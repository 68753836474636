import * as React from "react";
import JourneyShowContext from "../../../contexts/JourneyShowContext";
import JourneyNotifications from "../JourneyNotifications";

const MemberShowNotifications = () => {
  const { journeyId } = React.useContext(JourneyShowContext);

  return (
    <div className="mt-20">
      <div className="flex flex-row flex-nowrap justify-between">
        <div className="text-sm text-grey font-nm mb-0 flex mt-8">
          Notifications
        </div>
      </div>

      <div className="py-10 my-5">
        <JourneyNotifications journeyId={journeyId} />
      </div>
    </div>
  );
};

export default MemberShowNotifications;
