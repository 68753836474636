import { Product, JourneyState } from '../../../graphql/genie-api-types';

export enum SearchActionTypes {
  CHANGE_SEARCH_MODE = '@@search/CHANGE_SEARCH_MODE',
  CHANGE_MEMBER_SEARCH = '@@search/CHANGE_MEMBER_SEARCH',
  CHANGE_REQUEST_SEARCH = '@@search/CHANGE_REQUEST_SEARCH',
  CHANGE_JOURNEY_SEARCH = '@@search/CHANGE_JOURNEY_SEARCH',
  CHANGE_DOCTOR_SEARCH = '@@search/CHANGE_DOCTOR_SEARCH',
}

export type SearchMode = 'member' | 'request' | 'journey' | 'doctor';

export type MemberSearchState = {
  searchTerm?: string;
};

export type RequestSearchState = {
  paid?: boolean;
  pregnant?: boolean;
  product?: Product;
};

export type JourneySearchState = {
  state?: JourneyState;
};

export type DoctorSearchState = {
  searchTerm?: string;
};

export type SearchState = {
  searchMode: SearchMode;
  memberSearchState: MemberSearchState;
  requestSearchState: RequestSearchState;
  journeySearchState: JourneySearchState;
  doctorSearchState: DoctorSearchState;
};
