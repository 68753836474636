import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { CreateClinicInput, Query } from '../../../../graphql/genie-api-types';
import ClinicForm from '../../../components/clinic/ClinicForm';
import { QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import DoctorClinicCreate from '../doctor-clinic/DoctorClinicCreate';
import { appSyncClient } from '../../../../utilities/appSync';
import { showToast } from '../../../store/toast/actions';

interface ClinicUpdateProps {
  clinicId: string;
  onComplete(): void;
}

interface ClinicUpdateState {
  step: 'updateClinic' | 'createDoctorClinic';
}

class ClinicUpdate extends React.PureComponent<ClinicUpdateProps> {
  state: Readonly<ClinicUpdateState> = {
    step: 'updateClinic',
  };

  onSwitchToCreateDoctorClinic = () => {
    this.setState({ step: 'createDoctorClinic' });
  }

  onBackToClinicForm = () => {
    appSyncClient.queryManager.reFetchObservableQueries();
    this.setState({ step: 'updateClinic' });
  }

  renderClinicForm({ clinic }: Pick<Query, 'clinic'>) {
      const { onComplete } = this.props;
      const { __typename, doctors, ...clinicValues } = clinic;

      return (
          <GenieMutation
              mutation={gql`
        mutation UpdateClinic($input: UpdateClinicInput!) {
          updateClinic(input: $input) {
              clinic {
                id
                name
                longitude
                latitude
                locationId
                addressStreet
                addressSuburb
                addressPostcode
                addressState
                addressCountry
                clinicEmail
                clinicPhone
                doctors {
                  title
                  nameFirst
                  nameMiddle
                  nameLast
                }
              }
            }
          }
        `}
          >
            {(mutate, result) => (
              <ClinicForm
                initialValues={{
                    ...clinicValues
                }}
                onSubmit={async ({ ...restOfFormValues }: CreateClinicInput) => {
                    await mutate({
                        variables: {
                            input: {
                                id: clinicValues.id,
                                ...restOfFormValues,
                            },
                        },
                    });
                    ReduxQueryListener.triggerEvents('All');
                    showToast('success', 'Request updated', 'Saved');
                    onComplete();
                }}
                isLoading={result.loading}
                errorMessage={result?.error?.message}
                onNewDoctorClinic={this.onSwitchToCreateDoctorClinic}
                attachedDoctors={doctors}
              />
            )}
          </GenieMutation>
      )
  }

  renderForm(clinicResult: Pick<Query, 'clinic'>) {
    const { step } = this.state;
    switch (step) {
      case 'updateClinic':
        return this.renderClinicForm(clinicResult);

      case 'createDoctorClinic':
        return this.renderCreateDoctorClinicForm(clinicResult);
    }
  }

  renderCreateDoctorClinicForm(clinicResult: Pick<Query, 'clinic'>) {
    const { clinic } = clinicResult;
    const clinicOption = {
      id: clinic.id,
      name: clinic.name ?? ''
    };

    return (
      <DoctorClinicCreate
        onCreate={this.onBackToClinicForm}
        onCancel={this.onBackToClinicForm}
        clinic={clinicOption}
      />
    );
  }

  render() {
    return (
      <GenieQuery query={gql`
        query Clinic($id: ID!) {
          clinic(id: $id) {
            id
            name
            addressStreet
            addressSuburb
            addressPostcode
            addressState
            addressCountry
            locationId
            clinicPhone
            clinicEmail
            longitude
            latitude
            doctors {
              id
              nameFirst
              nameMiddle
              nameLast
              title
            }
          }
        }
      `}
        errorPolicy="all"
        variables={{
          id: this.props.clinicId,
        }}
      >
        {({ loading, data }: QueryResult<Pick<Query, 'clinic'>>) => loading ?
          (
            <Spinner label="Loading clinic..." />
          ) : this.renderForm(data)
        }
      </GenieQuery>
    );
  }
}

export default ClinicUpdate;
