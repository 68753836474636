import * as React from 'react';
import { groupBy, startCase } from 'lodash';
import { FamilyHistoryCategory, Member } from '../../../graphql/genie-api-types';
import FamilyHistoryItem from './FamilyHistoryItem';

interface FamilyHistoryListViewProps {
  familyHistoryGroup: Member['familyHistoryGroup'];
}

const FamilyHistoryListView = ({ familyHistoryGroup }: FamilyHistoryListViewProps) => {
  const categoryHistoryItems = groupBy(familyHistoryGroup, 'category');
  
  const categories = [
    FamilyHistoryCategory.Parents,
    FamilyHistoryCategory.Extended,
    FamilyHistoryCategory.Me,
    FamilyHistoryCategory.Siblings,
    FamilyHistoryCategory.Pregnancy,
    FamilyHistoryCategory.Children,
    FamilyHistoryCategory.Niblings,
    'null',
  ];

  return (
    <div className="mb-40">
      {categories.map(categoryName => (
        <div className="mb-20" key={categoryName}>
          <h3 className="text-h3 font-bold">{categoryName === 'null' ? 'Other' : startCase(categoryName.toLowerCase())}</h3>
          {categoryHistoryItems[categoryName]?.map(familyHistoryGroupItem => (
            <FamilyHistoryItem
              key={familyHistoryGroupItem.id}
              familyHistoryGroupItem={familyHistoryGroupItem}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default React.memo(FamilyHistoryListView);