import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Member, Query, QueryMemberArgs } from '../../../../graphql/genie-api-types';
import { FiInfo } from '../../../components/widgets/Icon';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';

type FamilyHistorySubmissionKey = 'Ethnicity' | 'Name';

interface FamilyHistoryExtractProps {
  memberId: Member['id'];
  familyHistoryQuestionKeys: FamilyHistorySubmissionKey[];
}

const QUERY_MEMBER_FAMILY_HISTORY_QUERY = gql`
  query Member($id: ID!) {
    member(id: $id) {
      id
      dateOfBirth
      familyHistoryGroup {
        id
        name
        value
      }
    }
  }
`;

const FamilyHistoryExtract = (props: FamilyHistoryExtractProps) => {
  const { familyHistoryQuestionKeys } = props;
  const familyHistoryQuery = useQuery<Pick<Query, 'member'>, QueryMemberArgs>(QUERY_MEMBER_FAMILY_HISTORY_QUERY, {
    variables: {
      id: props.memberId,
    },
  });

  const mappedValues: { [key in FamilyHistorySubmissionKey]?: string } = {};
  familyHistoryQuery.data?.member?.familyHistoryGroup?.forEach(item => {
    if (item.name && familyHistoryQuestionKeys.includes(item.name as FamilyHistorySubmissionKey)) {
      mappedValues[item.name as FamilyHistorySubmissionKey] = item.value;
    }
    if (item.name === 'Name') {
      mappedValues['Name'] = item.value;
    }
  });

  return (
    <div className="mt-10 relative text-sm border p-10 border flex bg-white">
      <FiInfo className="mr-5" />
      <div className="flex-1">
        <h2 className="font-bold">
          Relevant family history submitted by {mappedValues['Name']}
        </h2>
        {familyHistoryQuery?.loading && <LoadingOverlay />}
        {familyHistoryQuestionKeys.map(questionKey => (
          <div className="mt-5" key={questionKey}>
            <strong>{questionKey}:</strong> {mappedValues[questionKey] ?? 'Non provided'}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FamilyHistoryExtract;
