import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import TestInformationView from './TestInformationView';
import TestInformationEdit from './TestInformationEdit';
import Route from '../../../base/Route';

const TestInformation = () => (
  <div>
    <Route path={PATHS.REPORTS_TEST_INFORMATION} component={TestInformationView} />
    <Route path={PATHS.REPORTS_TEST_INFORMATION_EDIT} component={TestInformationEdit} />
  </div>
);

export default TestInformation;
