import * as React from 'react';
import GeniePagedQuery from '../../base/GeniePagedQuery';
import { Query, QueryListClinicsArgs, Clinic, ListClinicsInput } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';

type ClinicPartial = Pick<Clinic, 'id' | 'name' | 'addressStreet' | 'addressSuburb' | 'addressState' | 'addressPostcode' | 'addressCountry' | 'clinicEmail' | 'clinicPhone' | 'latitude' | 'longitude' | 'locationId'>;

export const CLINIC_LIST_QUERY = gql`
  query ListClinics($input: ListClinicsInput!) {
    listClinics(input: $input) {
      edges {
        cursor
        node {
          id
          name
          addressStreet
          addressSuburb
          addressState
          addressPostcode
          addressCountry
          clinicEmail
          clinicPhone
          locationId
          latitude
          longitude
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

interface ClinicListProps {
  children(clinics: ClinicPartial[], isLoading: boolean, reloadData: () => void): React.ReactNode;
  searchParams: ListClinicsInput;
}

const ClinicList = (props: ClinicListProps) => {
  return (
    <GeniePagedQuery<Pick<Query, 'listClinics'>, QueryListClinicsArgs>
      query={CLINIC_LIST_QUERY}
      variables={{
        input: props.searchParams,
      }}
      notifyOnNetworkStatusChange
      errorPolicy="all"
      itemsPerPage={20}
      pageInfoSelector="listClinics.pageInfo"
      edgeSelector="listClinics.edges"
    >
      {(data, isLoading, reloadData) => props.children(data?.listClinics?.edges?.map(edge => edge.node), isLoading, reloadData)}
    </GeniePagedQuery>
  );
};

export default ClinicList;
