import * as React from 'react';
import { Switch, Redirect } from 'react-router';
import Route from '../base/Route';
import { PATHS } from '../store/router/types';
import CounsellorIndex from './directory/CounsellorIndex';
import DoctorIndex from './directory/DoctorIndex';
import DoctorShow from './directory/DoctorShow';
import ClinicIndex from './directory/ClinicIndex';
import ClinicShow from './directory/ClinicShow';

const Directory = () => {
  return (
    <Switch>
      <Route path={PATHS.DOCTORS} exact component={DoctorIndex} />
      <Route path={PATHS.DOCTOR_SHOW} exact component={DoctorShow} />
      <Route path={PATHS.CLINICS} exact component={ClinicIndex} />
      <Route path={PATHS.CLINIC_SHOW} exact component={ClinicShow} />
      <Route path={PATHS.COUNSELLORS} exact component={CounsellorIndex} />
      <Route>
        <Redirect to={PATHS.DOCTORS} />
      </Route>
    </Switch>
  );
};

export default Directory;
