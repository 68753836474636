import * as React from 'react';
import { Kit, Mutation, Query, QueryKitArgs, MutationKitRecollectionArgs, MutationCreateFlagArgs, FlagCode, FlagType, FlagPriority, MutationCreateRequestNoteArgs } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import Button from '../../../components/widgets/Button';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import Badge from '../../../components/widgets/Badge';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import useGenieQuery from '../../../../utilities/useGenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import { appSyncClient } from '../../../../utilities/appSync';
import Select from 'react-select';

export interface RecollectKitProps {
  kitId: Kit['id'];
  onComplete(): void;
}

const KIT_QUERY = gql`
  query Kit($id: ID!) {
    kit(id: $id) {
      id
      registrationNumber
      journey {
        id
        request {
          id
        }
      }
    }
  }
`;

const RECOLLECT_MUTATION = gql`
mutation KitRecollect($input: KitRecollectionInput!) {
  kitRecollection(input: $input) {
    kit {
      id
      recollectionRequestedDate
      journey {
        id
        hasRecollection
      }
    }
  }
}
`;

const FLAG_MUTATION = gql`
mutation createFlag($input: CreateFlagInput!) {
  createFlag(input: $input) {
    flag {
      id,
    }
  }
}
`;

const REQUEST_NOTE_CREATE_MUTATION = gql`
mutation createRequestNote($input: CreateRequestNoteInput!) {
  createRequestNote(input: $input) {
    request {
      id,
    }
  }
}
`;

const recollectionReasons = [
  'Insufficient DNA',
  'Contamination present',
  'Fails a quality control measure',
  'Tube incorrectly labelled',
  'Tentative recollection',
  'Shipping delay',
  'Other',
];

const RecollectKit = (props: RecollectKitProps) => {
  const [recollectionReason, setRecollectionReason] = React.useState('');
  const [otherReason, setOtherReason] = React.useState('');

  const kitQuery = useGenieQuery<Pick<Query, 'kit'>, QueryKitArgs>(KIT_QUERY, {
    variables: {
      id: props.kitId,
    }
  }, 'kit');

  const [recollectMutate, recollectionMutationResponse] = useMutation<Mutation['kitRecollection'], MutationKitRecollectionArgs>(RECOLLECT_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['Journey'];
    },
  });

  const [flagCreateMutate, flagCreateMutationResponse] = useMutation<Mutation['createFlag'], MutationCreateFlagArgs>(FLAG_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['Journey'];
    },
  });
  
  const [requestNoteCreateMutate] = useMutation<Mutation['createRequestNote'], MutationCreateRequestNoteArgs>(REQUEST_NOTE_CREATE_MUTATION, {
    client: appSyncClient,
  });

  const recollectKit = React.useCallback(async () => {
    if (!recollectionReason) {
      showToast('error', 'No kit recollection reason', 'Please select a reason for recollection');
      return;
    }
    await requestNoteCreateMutate({
      variables: {
        input: {
          text: `Sample has failed. Reason: ${recollectionReason === 'Other' ? `Other ${otherReason}` : recollectionReason}`,
          requestId: kitQuery.data?.kit.journey.request.id,
        },
      }
    });
    await flagCreateMutate({
      variables: {
        input: {
          code: FlagCode.SampleFailed,
          flagType: FlagType.NeedsAttention,
          priority: FlagPriority.High,
          journeyId: kitQuery.data?.kit?.journey?.id,
          linkData: JSON.stringify({ kitId: kitQuery.data?.kit?.id }),
        },
      },
    });
    showToast('success', 'Kit recollection flag created', `Recollection notice will appear in ops blockers.`);

    await recollectMutate({
      variables: {
        input: {
          kitId: props.kitId,
        },
      },
    });
    showToast('success', 'Kit recollected', `Kit ${props.kitId} marked as recollected.`);
    ReduxQueryListener.triggerEvents('All');

    props.onComplete();
  }, [props.kitId, recollectionReason, otherReason, kitQuery.data]);

  if (kitQuery.loading) {
    return <Spinner label="Loading kit..." />
  }

  return (
    <div className="relative">
      <p>Recollecting this kit will flag the member for followup and create a new kit in Member shipping in ops.</p>
      <Badge className="my-10" color="white">{kitQuery.data.kit.registrationNumber ?? 'no-registration-number'}</Badge>
      <div className="flex flex-col items-start">
        <Select
          placeholder="Select a reason..."
          options={recollectionReasons.map(reason => ({
            label: reason,
            value: reason,
          }))}
          className="w-full mb-10"
          onChange={(option) => setRecollectionReason(option.value)}
        />
        {recollectionReason === 'Other' && (
          <textarea
            className="w-full border p-10 mb-10"
            placeholder="Other reason..."
            onChange={(e)  => setOtherReason(e.target.value)}
            value={otherReason}
          />
        )}
        <Button onClick={recollectKit} className="mb-10">
          Recollect kit
        </Button>
        <Button
          onClick={props.onComplete}
          variant="secondary"
        >
          Cancel
        </Button>
      </div>
      {recollectionMutationResponse.loading || flagCreateMutationResponse.loading ? <LoadingOverlay /> : null}
    </div>
  );
}

export default RecollectKit;
