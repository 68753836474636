import * as React from 'react';
import gql from 'graphql-tag';
import { QueryListKitsArgs, KitScope, Kit, KitsConnection } from '../../../../graphql/genie-api-types';
import convertJourneyResultsToJourneyTableData from '../../processors/convertJourneyResultsToJourneyTableData';
import Table from '../../../components/widgets/Table';
import useStatsQuery from '../../../../utilities/useStatsQuery';
import _ from 'lodash';
import { CSVLink } from "react-csv";
import BooleanIcon from '../../../components/widgets/BooleanIcon';
import moment from 'moment';

export const LIST_SHIPPABLES_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        cursor
        node {
          id
          registrationNumber
          recollectionRequestedDate
          memberRegisteredKitOnDate
          journey {
            id
            hasConsent
            hasRecollection
            labOrderNumber
            member {
              id
              name
              dateOfBirth
              sex
            }
            request {
              id
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const variables: QueryListKitsArgs = {
  input: {
    scope: KitScope.ReadyForLabShipping,
  },
};

const wrapperStyle = { fontFamily: 'monospace' };

const ShippingListInvitae = () => {
  const { loading, data, refetch, error } = useStatsQuery<KitsConnection, QueryListKitsArgs>(LIST_SHIPPABLES_QUERY, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables,
  }, 'listKits', 100);

  const kitEdges = data?.edges.map(edge => edge.node) ?? [];
  const journeys = kitEdges.map(kit => kit.journey);
  const { orderedRequestIds, journeysByRequestId } = convertJourneyResultsToJourneyTableData(journeys);

  const kitsByJourneyId: { [key: string]: Kit } = {};

  data?.edges?.map(edge => edge.node)
    .forEach(kit => {
      const journey = kit.journey;
      kitsByJourneyId[journey.id] = kit;
    });

  if (error) {
    return <div>{error}</div>;
  }

  const actualSampleTotal = orderedRequestIds.reduce((totalSample, requestId) => {
    const journeys = journeysByRequestId[requestId];

    return totalSample + journeys.length;
  }, 0)

  const rowGroup = orderedRequestIds.map((requestId) => {
    const journeys = journeysByRequestId[requestId];

    return {
      id: requestId,
      showGroupIndicator: journeys[0]?.request?.journeys?.length > 1,
      rows: journeys.map(journey => {
        return {
          id: journey.id,
          memberName: journey.member?.name,
          // actions: [],
          cells: [
            <span key="registrationNumber" className="tracking-widest">{kitsByJourneyId[journey.id]?.registrationNumber || 'IB_______'}</span>,
            journey?.labOrderNumber,
            <BooleanIcon isChecked={journey?.hasRecollection && kitsByJourneyId[journey.id]?.recollectionRequestedDate == null} key="recollection-status" />,
            <span key="recollection-date">{moment(kitsByJourneyId[journey.id]?.memberRegisteredKitOnDate).format('MM/DD/YYYY')}</span>,
          ],
        }
      }),
    };
  })

  const sortedRowGroup = _.orderBy(rowGroup, item => item.rows[0].memberName, ['asc']);
  const tableHeader = ['IB#', 'RQ#', "Is a recollection?", "Collection date"];
  const csvData = [
    tableHeader,
  ];

  sortedRowGroup.map( rowGroup => {
    const journeys = journeysByRequestId[rowGroup.id];

    journeys.map(journey => {      
      csvData.push([kitsByJourneyId[journey.id]?.registrationNumber || 'IB_______', journey?.labOrderNumber, (journey?.hasRecollection && kitsByJourneyId[journey.id]?.recollectionRequestedDate == null ? 'Yes' : 'No'), moment(kitsByJourneyId[journey.id]?.memberRegisteredKitOnDate).format('MM/DD/YYYY')]);
    })
  })

  csvData.push([ ])
  csvData.push(['Total:', actualSampleTotal.toString() ])

  return (
    <div className='flex flex-col items-end'>
      <div style={wrapperStyle} className="w-full">
        <Table
          header={tableHeader}
          isLoading={loading}
          emptyText={'Nice work, no more kits to ship.'}
          hasBatchSelect={false}
          columnClasses={['w-1/2']}
          onRefresh={refetch}
          rowGroups={sortedRowGroup}
        />
        <div className='flex justify-between w-full bg-grey-light font-bold text-sm text-grey-darkest border-b border-t border-grey-mid px-12 py-12 mt-20'>
          <span>Total no. of samples:</span><span>{actualSampleTotal}</span>
        </div>
      </div>
      <CSVLink 
        className='btn text-center btn--md w-1/3 mt-20 apperance-none text-purple border-purple hover:bg-purple bg-white hover:text-white print:hidden' 
        data={csvData}
        filename={"Invitae-manifest.csv"}
      >Download as CSV</CSVLink>
    </div>
  );
};

export default ShippingListInvitae;
