import * as React from 'react';
import { Collapsible } from '../../components/widgets/Collapsible';
import Table from '../../components/widgets/Table';
import { Journey } from '../../../graphql/genie-api-types';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';
import _ from 'lodash';

export type JourneyRegistrationNumber = {
  journey: Partial<Journey>;
  registrationNumber: string;
}

export type LabShipment = {
  dateLabel: string;
  journeys: JourneyRegistrationNumber[];
};

interface LabShippingHistoryShipmentProps {
  labShipment: LabShipment;
}

type AllProps = LabShippingHistoryShipmentProps;

const wrapperStyle = { fontFamily: 'monospace' };

const LabShippingHistoryShipment = (props: AllProps) => {
  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  const { labShipment } = props;
  const sortedJourneys = _.orderBy(labShipment.journeys, journey => journey.journey?.member?.name, ['asc']);

  return (
    <Collapsible
      title={labShipment.dateLabel}
      headingLevel={3}
      defaultExpandedState={true}
      className="pt-20"
    >
      <div className='block w-full px-20 pt-20 print:hidden'>
        <Table
          header={['Name', 'IB#', 'RQ#']}
          isLoading={false}
          rowGroups={sortedJourneys.map(({ journey, registrationNumber }) => {
            return {
              id: journey.id,
              showGroupIndicator: false,
              rows: [{
                id: journey.id,
                actions: [],
                cells: [
                  journey.member?.name,
                  registrationNumber || 'IB_______',
                  journey.labOrderNumber ?? '-',
                ],
              }],
            }
          })}
          onRowSelect={onSelectJourney}
        />
      </div>
      <div className='block w-full px-20 pt-20 hidden print:block' style={wrapperStyle}>
        <Table
          header={['IB#', 'RQ#']}
          isLoading={false}
          rowGroups={sortedJourneys.map(({ journey, registrationNumber }) => {
            return {
              id: journey.id,
              showGroupIndicator: false,
              rows: [{
                id: journey.id,
                actions: [],
                cells: [
                  registrationNumber || 'IB_______',
                  journey.labOrderNumber ?? '-',
                ],
              }],
            }
          })}
          onRowSelect={onSelectJourney}
        />
        <div className='flex justify-between w-full bg-grey-light font-bold text-sm text-grey-darkest border-b border-t border-grey-mid px-10 py-10 mt-0'>
          <span>Total no. of samples:</span><span>{labShipment?.journeys.length}</span></div>
      </div>
    </Collapsible>
  );
}

export default LabShippingHistoryShipment;
