import { FormInputOption } from '../../../components/widgets/form/FormInput';

export enum AdditionalInformationActionTypes {
  CHANGE_FIELDS = '@@report/additional-information/CHANGE_FIELDS',
}

export interface Macro {
  label: string;
  value: string;
}

export interface FormattedField {
  identifier: string;
  label: string;
  type: string;
  value: string;
  options?: FormInputOption[];
}
export interface AdditionalInformationState {
  content: string;
}
