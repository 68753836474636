import * as React from 'react';

import SessionUnlockForm, { SessionUnlockFormValues } from '../../components/session/SessionUnlockForm';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { Dispatch } from 'redux';
import { changeStorageKey } from '../../store/storage/actions';
import isStorageKeyValid from '../../../utilities/storage/isStorageKeyValid';
import deleteStorageKey from '../../../utilities/storage/deleteStorageKey';
import saveStorageKey from '../../../utilities/storage/saveStorageKey';

interface EncryptedLocalStorageState {
  hasKeyFailed: boolean;
  isUnlocked: boolean;
}

interface EncryptedLocalStorageProps {
}

interface StateProps {
  encryptionKey: string;
}

interface DispatchProps {
  onChangeEncryptionKey(encryptionKey: string): void;
}

interface AllProps extends EncryptedLocalStorageProps, StateProps, DispatchProps {

}

class EncryptedLocalStorage extends React.PureComponent<AllProps, EncryptedLocalStorageState> {
  state: Readonly<EncryptedLocalStorageState> = {
    hasKeyFailed: false,
    isUnlocked: false,
  };

  onUnlockFormSubmit = ({ sessionPassword, shouldResetStorageKey }: SessionUnlockFormValues) => {
    const { onChangeEncryptionKey } = this.props;
    if (shouldResetStorageKey) {
      deleteStorageKey();
    }

    if (!isStorageKeyValid(sessionPassword)) {
      this.setState({
        hasKeyFailed: true,
      });
      return;
    }

    const encryptionKey = saveStorageKey(sessionPassword);
    onChangeEncryptionKey(encryptionKey);
  }

  static getDerivedStateFromProps(props: AllProps, state: EncryptedLocalStorageState) {
    if (props.encryptionKey) {
      return {
        ...state,
        isUnlocked: !!props.encryptionKey,
      };
    }

    return state;
  }

  render() {
    const { isUnlocked, hasKeyFailed } = this.state;
    const { children } = this.props;

    if (isUnlocked) {
      return (
        <div>
          {children}
        </div>
      );
    }
    return (
      <div>
        <p className="leading-normal">To unlock data stored in the session on this computer, please enter the password you previously used or a new password. Note:
            This does <strong>not</strong> need to be the same as your Eugene Google.</p>
        <div className="text-left mt-20 border-t pt-20">
          <SessionUnlockForm
            hasKeyFailed={hasKeyFailed}
            onSubmit={this.onUnlockFormSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  encryptionKey: state.storage.encryptionKey,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeEncryptionKey: (sessionKey: string) => dispatch(changeStorageKey(sessionKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EncryptedLocalStorage);
