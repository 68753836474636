import { Reducer } from 'redux';
import { ExportReportState, ExportReportActionTypes } from './types';

export const initialState: ExportReportState = {
  hasExported: false,
};

const reducer: Reducer<ExportReportState> = (state = initialState, action) => {
  switch (action.type) {
    case ExportReportActionTypes.COMPLETE_EXPORT:
      return {
        ...state,
        hasExported: true,
      };

    default:
      return state;
  }
};

export { reducer as exportReportReducer };
