import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import FamilyReportView from './FamilyReportView';
import Route from '../../../base/Route';

const FamilyReport = () => (
  <div>
    <Route path={PATHS.REPORTS_FAMILY} component={FamilyReportView} />
  </div>
);

export default FamilyReport;
