import gql from 'graphql-tag';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { SALES_LEAD_FRAGMENT } from '../../../../graphql/fragments/leads';
import { Lead, LeadStatus, MutationUpdateLeadArgs, QueryLeadArgs, UpdateLeadResult } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import { pluralise } from '../../../../utilities/helpers';
import LeadsUpdateForm, { LeadUpdateData } from '../../../components/leads/LeadsUpdateForm';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';

interface LeadsCloseProps {
  leadIds: Lead['id'][];
  onComplete(): void;
  showLeadDetails?: boolean;
}

const LEAD_QUERY = gql`
  query Lead($id: ID!) {
    lead(id: $id) {
      id
      ...LeadParts
    }
  }
  ${SALES_LEAD_FRAGMENT}
`;

const LEAD_UPDATE_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      lead {
        ...LeadParts
      }
    }
  }
  ${SALES_LEAD_FRAGMENT}
`;

type LeadPartial = Pick<Lead, 'id' | 'name' | 'product'>;

type LeadResult = { lead: LeadPartial };

const LeadsClose = (props: LeadsCloseProps) => {
  const { leadIds, onComplete, showLeadDetails } = props;
  const [leadsDetails, setLeadsDetails] = React.useState<LeadPartial[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [areLeadsLoading, setAreaLeadsLoading] = React.useState(showLeadDetails);
  const [errorMessage, setErrorMessage] = React.useState('');

  const loadLeads = async () => {
    setAreaLeadsLoading(true);
    const leadResults = await Promise.all(leadIds.map(leadId => appSyncClient.query<LeadResult, QueryLeadArgs>({
      query: LEAD_QUERY,
      variables: {
        id: leadId,
      }
    })));
    setLeadsDetails(leadResults.map(leadResult => leadResult.data.lead));
    setAreaLeadsLoading(false);
  };

  React.useEffect(() => { 
    if (showLeadDetails) {
      loadLeads();
    }
  }, [leadIds, showLeadDetails]);

  const onSubmit = React.useCallback(async (leadSubmissionData: { leads: { [key: string]: LeadUpdateData } }) => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      await Promise.all(leadIds.map(leadId => {
        const leadUpdateInput = leadSubmissionData.leads[leadId];
        return appSyncClient.mutate<UpdateLeadResult, MutationUpdateLeadArgs>({
          mutation: LEAD_UPDATE_MUTATION,
          variables: {
            input: {
              id: leadId,
              status: leadUpdateInput.status,
              ...(leadUpdateInput.status === LeadStatus.ClosedLost ? {
                closedReason: leadUpdateInput.reason === 'other' ? `Other: ${leadUpdateInput.reasonOther}` : leadUpdateInput.reason,
                doNotContact: leadUpdateInput.doNotContact,
              } : {}),
            },
          },
          refetchQueries:['ListLeads']
        });
      }));
      toastr.success('Closed Leads', `Closed ${leadIds.length} ${pluralise('lead', 'leads', leadIds.length)}`);
      onComplete();
    } catch (e) {
      setErrorMessage(e.message);
      return;
    } finally {
      setIsSubmitting(false);
    }
  }, [onComplete, leadIds]);

  return (
    <div>
      {areLeadsLoading ? <LoadingOverlay /> : (
        <LeadsUpdateForm
          leadDetails={leadsDetails}
          leadIds={leadIds}
          showLeadDetails={showLeadDetails}
          isLoading={isSubmitting}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

LeadsClose.defaultProps = {
  showLeadDetails: true,
};

export default LeadsClose;
