import { appSyncClient } from '../../../utilities/appSync';
import { Admin, Query } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { useState, useEffect } from 'react';

const ME_QUERY = gql`
  query Me {
    me {
      cognitoId
      id
      counsellorDetails {
        id
        name
        nickname
        photoURL
      }
      email
    }
  }
`;

let cachedMeResult: Admin = undefined;

export const useMe = () => {
  const [meResult, setMeResult] = useState(cachedMeResult);

  useEffect(() => {
    if (!meResult) {
      requestMe().then(me => setMeResult(me));
    }
  }, []);

  return meResult;
}

const requestMe = async () => {
  if (cachedMeResult) {
    return cachedMeResult;
  }

  const result = await appSyncClient.query<Pick<Query, 'me'>>({
    query: ME_QUERY,
  });
  cachedMeResult = result.data.me;

  return cachedMeResult;
}

export default requestMe;
