import * as React from 'react';
import { FiCheckSquare, FiMinusSquare, FiSquare } from './Icon';

export interface BatchCheckboxProps {
  state: 'checked' | 'partially-checked' | 'unchecked';
  onToggle?(): void;
  className?: string;
}

const BatchCheckbox = (props: BatchCheckboxProps) => {
  const { onToggle, state, className } = props;
  let icon: React.ReactNode;
  let checkClass = 'hover:text-purple';

  switch (state) {
    case 'checked':
      icon = <FiCheckSquare />;
      checkClass += ' text-purple';
      break;

    case 'partially-checked':
      icon = <FiMinusSquare />;
      checkClass += ' text-purple';
      break;

    default:
      icon = <FiSquare />;
      checkClass += ' text-grey';
      break;
  }

  return (
    <button
      className={`table__row-batch-select focus:outline-none ${checkClass} ${className ?? ''}`}
      onClick={(e) => {
        onToggle();
        e.stopPropagation();
        e.preventDefault();
      }}>
      {icon}
    </button>
  );
};

export default BatchCheckbox;
