import * as React from 'react';
import Button from '../../widgets/Button';
import { Journey, JourneyLog, QueryListJourneysArgs } from '../../../../graphql/genie-api-types';
import Spinner from '../../widgets/Spinner';
import StateTimeQuantity from './stats/StateTimeQuantity';
import MonthNumberComparison from './stats/MonthNumberComparison';
import useStatsQuery from '../../../../utilities/useStatsQuery';
import gql from 'graphql-tag';
import { RequestAllJourneysWithHistoryResult } from '../../../graphql/requests/journey/requestAllJourneysWithHistory';

export type QueryResult = {
  journeys: RequestAllJourneysWithHistoryResult;
};

export interface RequestAllJourneysWithHistoryJourney extends Pick<Journey, 'id'> {
  history?: Pick<JourneyLog, 'fromState' | 'toState' | 'date'>[];
}

export interface StatsJourneysConnection {
  edges: {
    node: RequestAllJourneysWithHistoryJourney;
    cursor: string;
  }[];
}

const JourneyStats = () => {
  const query = useStatsQuery<StatsJourneysConnection, QueryListJourneysArgs>(gql`query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          history {
            toState
            fromState
            date
          }
          member {
            id
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }`, {
    variables: {
      input: {
        excludeCompleted: true,
      },
    },
  }, 'listJourneys', 100);
  
  const journeys = query?.data?.edges?.map(edge => edge.node) ?? [];

  return (
    <div>
      <Button disabled={query.isLoading} onClick={query.refetch} className="mb-10">
        {query.isLoading ? <Spinner /> : 'Reload stats'}
      </Button>
      {query.isLoading || query.data ? (
        <div className="flex flex-wrap">
          <div className="w-full rounded shadow mb-20">
            <StateTimeQuantity
              allJourneysWithHistory={journeys}
            />
          </div>
          <div className="rounded shadow text-center w-1/2">
            <MonthNumberComparison
              allJourneysWithHistory={journeys}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default JourneyStats;
