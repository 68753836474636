import * as React from 'react';
import { RouteProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import './styles/page-nav.scss';
import { Path } from '../../store/router/types';

export type NavItemStatus = 'complete' | 'incomplete';

export interface NavItem {
  path: string;
  label: string;
  taskListKey?: string;
  routeProps: RouteProps;
  topDivider?: boolean;
}

interface PageNavProps {
  navItems: NavItem[];
  titleView?: React.ReactNode;
  taskList?: { [key: string]: boolean };
  pathProcessor?(path: Path): string;
}

const PageNav = (props: PageNavProps) => (
  <div className="page-nav">
    <div className="page-nav__title text-blue-dark">
      {props.titleView}
    </div>
    {props.navItems.map((navItem: NavItem, index: number) => {
      const taskStatus = props.taskList && props.taskList[navItem.taskListKey] ? 'complete' : 'incomplete';
      return (
        <NavLink
          to={props.pathProcessor ? props.pathProcessor(navItem.path as Path) : navItem.path}
          className={`page-nav__item page-nav__item-${taskStatus} ${navItem.topDivider ? 'border-t pt-10 mt-10' : ''}`}
          key={navItem.path}
        >
          {index + 1}. {navItem.label}
        </NavLink>
      );
    })}
  </div>
);

export default PageNav;
