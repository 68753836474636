import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { CreateJourneyInput, Journey } from '../../../graphql/genie-api-types';
import * as yup from 'yup';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';

interface JourneyUpdateFormProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: Partial<JourneyUpdateFormValues>;
}

export interface JourneyUpdateFormValues extends CreateJourneyInput {
  id: string;
  addressAddress1: string;
  addressAddress2: string;
  addressCity: string;
  addressCountryCode: string;
  addressProvince: string;
  addressZip: string;
  partnerCanReceiveResults: boolean | null;
}
interface JourneyUpdateFormInput extends FormInputProps {
  name: keyof Journey;
}

const JourneyUpdateForm = (props: JourneyUpdateFormProps) => {
  const { initialValues, onSubmit, isLoading } = props;

  const validationSchema = yup.object().shape({
    addressAddress1: yup.string().nullable(true),
    addressCity: yup.string().nullable(true),
    addressProvince: yup.string().nullable(true),
    addressCountryCode: yup.string().nullable(true),
    addressZip: yup.number().nullable(true),
    partnerCanReceiveResults: yup.boolean().nullable(true),
  });

  const formItems: Pick<JourneyUpdateFormInput, 'label' | 'name' | 'type' | 'as' | 'options'>[] = [
    {
      label: 'Consent to Share Result with Partner',
      name: 'partnerCanReceiveResults',
      as: 'select',
      options: [
        {
          label: 'Missing result consent',
          value: '',
        },
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      label: 'Address Line 1',
      name: 'addressAddress1',
    },
    {
      label: 'Address Line 2',
      name: 'addressAddress2',
    },
    {
      label: 'City/Suburb',
      name: 'addressCity',
    },
    {
      label: 'State/Province',
      name: 'addressProvince',
    },
    {
      label: 'Country Code',
      name: 'addressCountryCode',
    },
    {
      label: 'ZIP/Postal Code',
      name: 'addressZip',
    },
  ];

  return (
    <BaseForm
      isLoading={isLoading}
      initialValues={initialValues}
      submitButtonLabel="Update Journey"
      validationSchema={validationSchema}
      onSubmit={values =>
        onSubmit({
          ...values,
        })
      }
    >
      {({ handleChange, values, setFieldValue, errors }) => (
        <div>
          {formItems.map((formItem, index) => {
            return (
              <FormInput
                {...formItem}
                value={values[formItem.name]}
                onChange={handleChange}
                key={formItem.name}
                autoFocus={index === 0}
                setFieldValue={setFieldValue}
                helpText={errors[formItem.name]}
              />
            );
          })}
        </div>
      )}
    </BaseForm>
  );
};

export default JourneyUpdateForm;
