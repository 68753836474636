import * as React from 'react';
import Heading from '../../../../components/widgets/Heading';
import CreateReportsJourneys from '../../../../components/journey/journey-rows/CreateReportsJourneys';
import ReviewReportsJourneys from '../../../../components/journey/journey-rows/ReviewReportsJourneys';
import ReportCounsellingJourneys from '../../../../components/journey/journey-rows/ReportCounsellingJourneys';

const JourneysPostGroup = () => (
  <div>
    <Heading level={3} className="mb-10">
      Create reports <span className="ml-5 text-sm align-middle">📄 </span>
    </Heading>
    <CreateReportsJourneys />
    <Heading level={3} className="mb-10 mt-30">
      Review reports <span className="ml-5 text-sm align-middle">📄 </span>
    </Heading>
    <ReviewReportsJourneys />
    <Heading level={3} className="mb-10 mt-30">
      Results counselling <span className="ml-5 text-sm align-middle">📞 </span>
    </Heading>
    <ReportCounsellingJourneys />
  </div>
);

export default JourneysPostGroup;
