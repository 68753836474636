import moment from 'moment';
import * as React from 'react';
import Badge, { BadgeProps } from './Badge';

export interface DateBadgeProps extends BadgeProps {
  dateString: string;
  redDays: number;
  orangeDays: number;
}

const currentMoment = moment();
export const getWeeksLabel = (dateString: string) => {
  const momentDate = moment(dateString);
  const ageDays = currentMoment.diff(momentDate, 'days');

  return `${Math.round(ageDays / 7)} weeks ago`;
}

const DateBadge = ({ dateString, redDays, orangeDays, ...props }: DateBadgeProps) => {
  const momentDate = moment(dateString);
  const ageDays = currentMoment.diff(momentDate, 'days');

  let badgeColor: BadgeProps['color'] = undefined;

  if (ageDays >= redDays) {
    badgeColor = 'red-light';
  }
  else if (ageDays >= orangeDays) {
    badgeColor = 'orange-light';
  }
  else {
    badgeColor = 'green-lightest';
  }
  return (
    <Badge {...props} color={badgeColor}>
      {getWeeksLabel(dateString)}
    </Badge>
  )
};

export default DateBadge;
