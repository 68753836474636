import * as React from 'react';
import Heading from '../../components/widgets/Heading';
import JourneyStats from '../../components/journey/journey-rows/JourneyStats';
import Screen from '../../components/widgets/Screen';

const JourneysStats = () => (
  <Screen>
    <Heading level={1} className="mb-20">Journeys: Stats</Heading>
    <JourneyStats />
  </Screen>
);

export default JourneysStats;
