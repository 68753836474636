import gql from 'graphql-tag';
import { ReportState } from '../../app/store/report';
import { QueryListReportsArgs, ReportsConnection } from '../../graphql/genie-api-types';
import { appSyncClient } from '../appSync';
import runStatsQuery from '../runStatsQuery';

const LIST_REPORTS_QUERY = gql`
query ListReports($input: ListReportsInput!) {
  listReports(input: $input) {
    edges {
      cursor
      node {
        id
        username
        data
        members {
          id
          name
        }
        request {
          id
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
`;

const checkReportMismatches = async () => {
  const reportsQuery = await runStatsQuery<ReportsConnection, QueryListReportsArgs>(LIST_REPORTS_QUERY, {
    variables: { input: {} },
  }, 'listReports');
  
  reportsQuery.edges?.map(edge => edge.node).forEach(report => {
    const reportData = JSON.parse(report?.data) as ReportState;
    const memberNames = report.members?.map(member => member.name);
    const reportNames = [reportData?.clientDetails?.clientA?.fullName, reportData?.clientDetails?.clientB?.fullName];
    if (reportData?.request?.request?.id && reportData?.request?.request?.id !== report?.request?.id) {
      console.log(`Mismatched report - request ID: ${report.id}: ${reportNames} <> = ${memberNames}`)
    }
    
    memberNames.forEach(memberName => {
      if (!reportNames.includes(memberName)) {
        console.log(`Mismatched report - memberName ${memberName}: ${report.id}: ${reportNames} <> = ${memberNames}`)
      }
    });
  });
};

export default checkReportMismatches;
