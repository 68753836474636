import * as React from 'react';
import gql from 'graphql-tag';
import { Member, Doctor, Query, QuerySearchDoctorsArgs } from '../../../../graphql/genie-api-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Dispatch } from 'redux';
import { MemberSearchState, DoctorSearchState } from '../../../store/search/types';
import DoctorSearch from '../../../containers/search/DoctorSearch';
import GenieQuery from '../../base/GenieQuery';
import { navigate } from '../../../store/router/actions';

export type DoctorSearchQueryResult = Pick<Member, 'id' | 'name' | 'dateOfBirth'>;

interface DispatchProps {
  onNavigateToDoctor(doctorId: Doctor['id']): void;
}

interface OwnProps {
  emptySearchContent?: React.ReactNode;
}

class DoctorSearchQuery extends React.PureComponent<DoctorSearchState & OwnProps & DispatchProps> {
  renderNoResults() {
    return (
      <div>No results found</div>
    );
  }

  renderSearchResult = (doctor: Doctor) => {
    const { onNavigateToDoctor } = this.props;

    return (
      <button
        key={doctor.id}
        type="button"
        className="px-10 border-b py-15 w-full block flex justify-between items-center focus:outline-none hover:bg-purple-100"
        onClick={() => onNavigateToDoctor(doctor.id)}
      >
        <div className="text-purple font-bold">
          {doctor.nameFirst} {doctor.nameMiddle} {doctor.nameLast}
        </div>
      </button>
    );
  }

  renderSearchResults = (results: Doctor[], isLoading: boolean) => {
    const { searchTerm } = this.props;

    if (!results && !isLoading && searchTerm.length > 0) {
      return this.renderNoResults();
    }

    if (!results || !searchTerm) {
      return <div />;
    }

    return (
      <div className="absolute t-full bg-white w-full z-100 shadow max-h-1/2 overflow-y-scroll">
        {results.map(this.renderSearchResult)}
      </div>
    );
  }

  render() {
    const { searchTerm } = this.props;

    return (
      <div className="max-w-3xl relative">
        <GenieQuery<Pick<Query, 'searchDoctors'>, QuerySearchDoctorsArgs>
          query={gql`
            query SearchDoctors($term: String!) {
              searchDoctors(term: $term) {
                id
                nameFirst
              }
            }
          `}
          notifyOnNetworkStatusChange
          variables={{
            term: searchTerm,
          }}
        >
          {({ data, loading }) => (
            <div>
              <DoctorSearch isLoading={loading} />
              {this.renderSearchResults(data?.searchDoctors ?? [], loading)}
            </div>
          )}
        </GenieQuery>
      </div>
    );
  }
}

const mapStateToProps = ({ search }: ApplicationState) => ({
  ...search.doctorSearchState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onNavigateToDoctor: (doctorId: Doctor['id']) => dispatch(navigate.toDoctor(doctorId)),
});

export default connect<MemberSearchState, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(DoctorSearchQuery);
