import { ReportTemplate } from '..';
import { TwoColSectionVariables } from '../sections/TwoColSection';
import { ClientDetailsVariables } from '../sections/ClientDetails';
import { DiseaseHeaderVariables } from '../sections/DiseaseHeader';
import { SectionVariables } from '../sections/Section';
import { PageTitleVariables } from '../sections/PageTitle';

export const individualCancer: ReportTemplate = {
  label: 'Cancer: Individual',
  name: 'individualCancer',
  medium: 'pdfReport',
  pages: [
    {
      sections: [
        {
          id: 'intro',
          template: 'Intro',
          variables: {
            title: 'Hi {{clientDetails.clientA.nickname}}, your proactive cancer screen results are in and',
            riskLevelColor: '{{#ifEquals summary.reproductiveRisk "Low"}}green{{else}}{{#ifEquals summary.reproductiveRisk "High"}}orange{{else}}pink{{/ifEquals}}{{/ifEquals}}',
            riskLevelPrefix: "we've identified a",
            riskLevel: '{{summary.reproductiveRisk}}',
            riskLevelSuffix: 'inherited cancer risk.',
            optionsImage: false,
          },
        },
        {
          id: 'whoTested',
          template: 'ClientDetails',
          variables: {
            whoTestedTitle: 'Who was tested',
            careTeamTitle: 'Your care team',
            clientAName: '{{clientDetails.clientA.fullName}}',
            clientADOB: '{{formatDate clientDetails.clientA.dob "full"}}',
            clientASex: '{{clientDetails.clientA.sex}}',
            clientASampleDate: 'Sample collected on {{formatDate clientDetails.clientA.sampleCollectionDate "short"}}',
            clientAReportDate: 'Report processed on {{formatDate clientDetails.clientA.reportProcessedDate "short"}}',
            hasSecondClient: false,
            careTeamSubTitle: 'at Eugene',
            careTeam: '{{testInformation.eugeneCareTeam}}',
            referringDoctorTitle: 'Referred by',
            referringDoctorClinic: '{{testInformation.referringDoctorClinic}}',
          } as ClientDetailsVariables,
        },
        {
          id: 'whatWeKnow',
          template: 'Section',
          dependencies: ['summary.whatWeKnow'],
          variables: {
            title: 'What you told us',
            content: '{{ summary.whatWeKnow }}',
          } as SectionVariables,
        },
        {
          id: 'whatWeTested',
          template: 'Section',
          variables: {
            title: 'What we tested',
            content:
              'Eugene’s Proactive cancer risk test used the Invitae Proactive Cancer Screen to help you predict your risk of developing cancer due to an inherited predisposition to cancer. You were tested for variants in a total of {{ testInformation.numberOfGenesTested }} cancer genes.',
          } as SectionVariables,
        },
        {
          id: 'whatWeFound',
          template: 'Section',
          variables: {
            title: 'What we found',
            content: '{{ summary.whatWeFound }}',
          } as SectionVariables,
        },
        {
          id: 'lowRiskWhatThisMeans',
          template: 'Section',
          dependencies: ['!isHighRisk'],
          variables: {
            title: 'What this means',
            content: `Your personal risk of developing cancer due to an inherited cancer predisposition is not high. It’s important to remember that increased-risk cancer genes account for only a small number of cancer diagnoses, even if there’s a family history, and many other factors can increase your chance of developing cancer.

Taking both your test result and your reported family history into account, you are considered to be at population risk for all other cancers, based on what you’ve told us.

People in this category of risk are recommended to:
            `,
          } as SectionVariables,
        },
        {
          id: 'highRiskWhatThisMeans',
          template: 'Section',
          dependencies: ['isHighRisk'],
          variables: {
            title: 'What this means',
            content: `Your personal risk of developing {specific cancer} across your lifetime **is up to {percent%} This means, of all the people who carry this variation, {percent%} of them are expected to develop cancer by the age of 80. However, this also means that {converse %} will not.
Your personal risk of developing certain cancers is increased. For more information, see the following pages.

On a positive note - there are things you can do to manage your risk. We will write to your doctors in order to work together to make an evidence-based risk management plan for you.

See the following pages for more information. Plus, it’s important to remember that not everyone who carries this variant will develop cancer, and there are many things that influence cancer risk, such as lifestyle and the environment.
            `,
          } as SectionVariables,
        },
        {
          id: 'lowRiskImportantNote',
          template: 'Section',
          dependencies: ['!isHighRisk', 'summary.importantNote'],
          variables: {
            title: 'An important note',
            content: '{{ summary.importantNote }}',
          } as SectionVariables,
        },
        {
          id: 'highRiskWhatYouCanDo',
          template: 'TwoColSection',
          dependencies: ['isHighRisk'],
          variables: {
            col1Title: 'General cancer prevention tips',
            col1TickBullets: true,
            col1Width: '6/12',
            col1Content: `
* Be sunsmart: apply sunscreen, cover up and wear a hat when outside
* Drink responsibly
* Avoid smoking
* Eat well and exercise
* Maintain a healthy body weight
* Speak to your GP about up-to-date routine population cancer screening and prevention for your age and gender; which might include cervical smears, breast mammograms, or FOBT and aspirin for bowel cancer


Visit <https://canceraustralia.gov.au/healthy-living> for more information
            `,
            col1TitleOptions: true,
            col2Title: 'Next steps',
            col2TickBullets: true,
            col2Width: '6/12',
            col2Content: `
* Talk to your doctor about referral to {specialists/high risk clinic}
* If there’s anything in this report that you don’t understand, please feel free to reach out to your genetic counsellor.
* Share these results with your doctor; or request a referral to one.

Family history changes over time, so it’s important to discuss any new diagnoses of cancer in your family with your GP or other medical specialist, as this may change your personalised cancer risk.
`,
          } as TwoColSectionVariables,
        },
        {
          id: 'highRiskImportantNote',
          template: 'Section',
          dependencies: ['isHighRisk', 'summary.importantNote'],
          variables: {
            title: 'An important note',
            content: '{{ summary.importantNote }}',
          } as SectionVariables,
        },
        {
          id: 'highRiskQuickNote',
          template: 'Section',
          dependencies: ['isHighRisk', 'summary.quickNote'],
          variables: {
            title: 'Quick note',
            content: '{{ summary.quickNote }}',
          } as SectionVariables,
        },
        {
          id: 'lowRiskNextSteps',
          template: 'Section',
          dependencies: ['!isHighRisk'],
          variables: {
            title: 'General cancer prevention tips',
            tickBullets: true,
            titleOptions: true,
            content: `
* Be sunsmart: apply sunscreen, cover up and wear a hat when outside
* Drink responsibly
* Avoid smoking
* Eat well and exercise
* Maintain a healthy body weight
* Speak to your GP about up-to-date routine population cancer screening and prevention for your age and gender; which might include cervical smears, breast mammograms, or FOBT and aspirin for bowel cancer


Visit <https://canceraustralia.gov.au/healthy-living> for more information
            
Family history changes over time, so it’s important to discuss any new diagnoses of cancer in your family with your GP or other medical specialist, as this may change your personalised cancer risk.
            `,
          } as SectionVariables,
        },
      ],
    },
    {
      repeat: {
        collection: 'orderedDiseases',
        as: 'disease',
        repeaterId: 'disease.id',
      },
      sections: [
        {
          id: 'pageTitle',
          template: 'PageTitle',
          variables: {
            title: 'Section {{lookup sectionNumbersByDiseaseId disease.id}} —',
          } as PageTitleVariables,
        },
        {
          id: 'diseasePageHeader',
          template: 'DiseaseHeader',
          variables: {
            diseaseClass: '{{disease.diseaseClass}}',
            diseaseClassColor: '{{disease.diseaseClassColor}}',
            resultTitle: 'Your result',
            carrierRisk: '{{disease.carrierRisk}}',
            diseaseName: '{{disease.name}}',
            relatedGenesPrefix: 'Related gene — ',
            relatedGenes: '{{{andList disease.genes}}}',
            clientAName: '{{clientDetails.clientA.fullName}}',
            clientACarrierStatus: '{{disease.clientACarrierStatus}}',
            hasSecondClientColumn: false,
            clientBName: '',
            clientBCarrierStatus: '',
          } as DiseaseHeaderVariables,
        },
        {
          id: 'diseasePageOverview',
          template: 'Section',
          variables: {
            title: 'What is {{disease.name}}',
            content: '{{ disease.description }}',
          } as SectionVariables,
        },
        {
          id: 'diseasePageIndividualCarrier',
          template: 'Section',
          variables: {
            title: "So I'm a carrier, what does that mean?",
            content: '{{ disease.carrierInfo }}',
          } as SectionVariables,
        },
        {
          id: 'diseasePageDualCarrierResources',
          template: 'Section',
          variables: {
            title: 'Resources and support groups',
            content: '{{ disease.resources }}',
          } as SectionVariables,
        },
        {
          id: 'diseasePageIndividualCarrierMeaning',
          template: 'Section',
          dependencies: ['!disease.areBothClientsCarriers'],
          variables: {
            title: 'What does that mean for us?',
            content: '{{ disease.individualCarrierInfoForCouple }}',
          } as SectionVariables,
        },
        {
          id: 'diseasePageIndividualCarrierNextStepsWithResources',
          template: 'TwoColSection',
          dependencies: ['disease.resources'],
          variables: {
            col1Title: 'Next steps',
            col1Width: '8/12',
            col1TickBullets: true,
            col1Content: '{{ disease.individualCarrierNextSteps }}',
            col2Title: 'Resources',
            col2Width: '4/12',
            col2Content: '{{ disease.resources }}',
          } as TwoColSectionVariables,
        },
        {
          id: 'diseasePageIndividualCarrierNextStepsWithoutResources',
          template: 'Section',
          dependencies: ['!disease.resources'],
          variables: {
            title: 'Next steps',
            tickBullets: true,
            content: '{{ disease.individualCarrierNextSteps }}',
          } as SectionVariables,
        },
      ],
    },
  ],
};
