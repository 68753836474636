import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Journey, Member, Request, QueryListRequestsArgs, PageInfo, Report, Counsellor } from '../../../../graphql/genie-api-types';
import { getProductLabel } from '../../../../utilities/journeys/products';
import useGenieQuery from '../../../../utilities/useGenieQuery';
import ResearchStatusBadge from '../../../components/research-extract/ResearchStatusBadge';
import Badge from '../../../components/widgets/Badge';
import Button from '../../../components/widgets/Button';
import { FiChevronRight } from '../../../components/widgets/Icon';
import Pager from '../../../components/widgets/Pager';
import PrivateContent from '../../../components/widgets/PrivateContent';
import Screen from '../../../components/widgets/Screen';
import Table, { RowGroup } from '../../../components/widgets/Table';
import TabsNav from '../../../components/widgets/TabsNav';
import CounsellorSelect from '../../../graphql/containers/counsellors/CounsellorSelect';
import { useMe } from '../../../graphql/requests/requestMe';
import { startResearchSession } from '../../../store/research/actions';
import { navigate } from '../../../store/router/actions';

interface RequestResultJourney extends Pick<Journey, 'id'> {
  member: Pick<Member, 'id' | 'name'>;
}

interface RequestResult extends Pick<Request, 'id' | 'product' | 'productVariant' | 'extractSubmitted' | 'researchExtract'> {
  journeys: RequestResultJourney[];
  report?: Pick<Report, 'dateCreated'>;
  primaryCounsellor?: Pick<Counsellor, 'id' | 'name'>;
}

interface RequestQueryResult {
  listRequests: {
    edges: {
      cursor: string;
      node: RequestResult;
    }[];
    pageInfo: PageInfo;
  };
}

type Mode = 'Unsubmitted' | 'Submitted';

const modeTabs: { name: Mode; label: Mode }[] = [
  {
    name: 'Unsubmitted',
    label: 'Unsubmitted',
  },
  {
    name: 'Submitted',
    label: 'Submitted',
  },
];

const ClinicalResearchIndex = () => {
  const [mode, setMode] = React.useState<Mode>('Unsubmitted')
  const [selectedCounsellorId, setSelectedCounsellorId] = React.useState<string>(undefined);
  const me = useMe();

  const dispatch = useDispatch();

  const onChangeCounsellor = React.useCallback((counsellorId: string) => {
    if (!counsellorId) {
      setSelectedCounsellorId(undefined);
    }
    else {
      setSelectedCounsellorId(counsellorId);
    }
  }, [setSelectedCounsellorId]);

  React.useEffect(() => {
    if (me?.counsellorDetails?.id) {
      setSelectedCounsellorId(me.counsellorDetails.id);
    }
  }, [setSelectedCounsellorId, me]);

  const onChangeMode = React.useCallback((mode: Mode) => {
    setMode(mode);
  }, [setMode]);

  const listRequestsVariables: QueryListRequestsArgs = React.useMemo(() => ({
    input: {
      primaryCounsellorId: selectedCounsellorId,
      hasReport: true,
      extractSubmitted: mode === 'Submitted',
    },
  }), [selectedCounsellorId, mode]);

  const requestsQuery = useGenieQuery<RequestQueryResult, QueryListRequestsArgs>(gql`
    query ListRequests($input: ListRequestsInput!) {
      listRequests(input: $input) {
        edges {
          cursor
          node {
            id
            extractSubmitted
            product
            productVariant
            researchExtract {
              id
              dateCreated
              dateSubmitted
            }
            report {
              id
              dateCreated
            }
            primaryCounsellor {
              id
              name
            }
            journeys {
              id
              member {
                id
                name
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
      }
    }
  `, {
    variables: listRequestsVariables,
  }, 'listRequests');

  const onRowSelect = React.useCallback((requestId: string) => {
    dispatch(navigate.toClinicalResearchExtractCreate(requestId));
  }, [dispatch]);

  const rowGroups: RowGroup[] = React.useMemo(() => {
    return requestsQuery?.data?.listRequests?.edges?.map(edge => edge.node).map(request => {
      return {
        id: request.id,
        rows: [
          {
            id: request.id,
            cells: [
              (
                <span key="member-badge-cell">
                  {request?.journeys?.map(journey => (
                    <Badge key={journey.id} className="mr-5"><PrivateContent>{journey.member?.name ?? ''}</PrivateContent></Badge>
                  ))}
                </span>
              ),
              getProductLabel(request.product, request.productVariant),
              request?.primaryCounsellor?.name,
              moment(request.report?.dateCreated).fromNow(),
              <ResearchStatusBadge researchExtract={request.researchExtract} key="status" />,
            ],
          }
        ],
      };
    }) ?? [];
  }, [requestsQuery?.data]);

  const onStartSession = React.useCallback(async () => {
    dispatch(startResearchSession(selectedCounsellorId));
  }, [selectedCounsellorId]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Research',
        action: (
          <div className="flex items-center">
            <span className="pr-10 text-grey-dark">Counsellor</span>
            <CounsellorSelect
              onChange={onChangeCounsellor}
              selectedValue={selectedCounsellorId}
            />
          </div>
        ),
      }}
    >
      <div className="flex justify-between">
        <TabsNav
          tabs={modeTabs}
          activeTab={mode}
          onSelectTab={onChangeMode}
        />
        <div>
          <Button onClick={onStartSession}>
            Start a session <FiChevronRight />
          </Button>
        </div>
      </div>
      <Table
        isLoading={requestsQuery.loading}
        header={[
          'Members',
          'Test',
          'Counsellor',
          'Report date',
          'Status',
        ]}
        rowGroups={rowGroups}
        onRefresh={requestsQuery.onRefresh}
        onRowSelect={onRowSelect}
      />
      <Pager
        pageInfo={requestsQuery.data?.listRequests?.pageInfo}
        isLoading={requestsQuery.loading}
        onClickNextPage={requestsQuery.onNextPage}
        onClickPreviousPage={requestsQuery.onPreviousPage}
      />
    </Screen>
  );
};

export default ClinicalResearchIndex;
