import * as React from 'react';
import Table from '../../widgets/Table';
import { navigate } from '../../../store/router/actions';
import { useDispatch } from 'react-redux';
import DoctorShowContext from '../../../contexts/DoctorShowContext';

const ClinicShowDoctors = () => {
  const { doctor } = React.useContext(DoctorShowContext);
  const dispatch = useDispatch();

  if (!doctor) {
    return null;
  }

  const onGoToClinic = (clinicId: string) => dispatch(navigate.toClinic(clinicId));
  return <Table
    header={['Clinic Name', 'Address']}
    onRowSelect={onGoToClinic}
    rowGroups={doctor.clinics.map(clinic => ({
      id: clinic.id,
      rows: [{
        id: clinic.id,
        cells: [
          clinic.name,
          clinic.addressStreet
        ],
      }]
    })) ?? []}
  />
}

export default ClinicShowDoctors;
