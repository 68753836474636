import * as React from 'react';
import * as tailwindConfig from '../../../../tailwind';
import { FiCheck, FiX } from './Icon';

interface BooleanIconProps {
  isChecked: boolean;
}

const checkColor = tailwindConfig.theme.colors.green;
const xColor = tailwindConfig.theme.colors.red;

const BooleanIcon = (props: BooleanIconProps) => {
  if (props.isChecked) {
    return <FiCheck color={checkColor} />;
  }

  return <FiX color={xColor} />;
};

export default React.memo(BooleanIcon);
