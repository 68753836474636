import * as React from 'react';
import { JourneyState } from '../../../graphql/genie-api-types';
import Screen from '../../components/widgets/Screen';
import ReportsTaskTable from '../../components/report/ReportsTaskTable';

const TasksWriteReports = () => {
  return (
    <Screen
      screenTitleProps={{
        title: 'Write reports',
      }}
    >
      <ReportsTaskTable journeyState={JourneyState.ReadyForReport} />
    </Screen>
  );
};

export default TasksWriteReports;
