import gql from 'graphql-tag';
import * as React from 'react';
import { Counsellor, Journey, JourneyState, Member, Pregnancy, Request } from '../../../../graphql/genie-api-types';
import CounsellorIcon from '../../widgets/CounsellorIcon';
import JourneyRows from './JourneyRows';

interface ReadyToArchiveJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    primaryCounsellor?: Pick<Counsellor, 'id'|'name'|'photoURL'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            onHoldDate
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            initiatingMember {
              id
            }
            primaryCounsellor {
              id
              name
              photoURL
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const ReadyToArchiveJourneys = () => (
  <JourneyRows<ReadyToArchiveJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.ReadyToArchive]}
    header={['GC']}
    emptyText="No reports ready to archive"
    cellsForJourney={journey => [
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor-icon" />,
    ]}
  />
);

export default ReadyToArchiveJourneys;
