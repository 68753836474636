import gql from 'graphql-tag';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Member, QueryListJourneysArgs, Query, Journey } from '../../../graphql/genie-api-types';
import useGenieQuery from '../../../utilities/useGenieQuery';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { PATHS } from '../../store/router/types';

interface MatchParams {
  memberId: Member['id'];
  journeyId?: Journey['id'];
}

type MemberShowLegacyRedirectProps = RouteComponentProps<MatchParams>;

const MEMBER_JOURNEY_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const MemberShowLegacyRedirect = (props: MemberShowLegacyRedirectProps) => {
  const { memberId, journeyId } = props?.match?.params;
  
  const query = useGenieQuery<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>(MEMBER_JOURNEY_QUERY, {
    variables: {
      input: {
        memberId,
      },
    },
    fetchPolicy: 'network-only',
  }, 'listJourneys');
  
  if (journeyId) {
    return (
      <Redirect to={PATHS.JOURNEY_SHOW.replace(':journeyId', journeyId)} />
    );
  }
  
  if (query.loading) {
    return <LoadingOverlay />;
  }
  
  const firstJourneyId = query.data?.listJourneys?.edges?.[0]?.node?.id;
  if (!firstJourneyId) {
    return <div>Member&apos;s journey not found</div>
  }
  
  return (
    <Redirect to={PATHS.JOURNEY_SHOW.replace(':journeyId', firstJourneyId)} />
  );
};
