import { action } from 'typesafe-actions';
import { SummaryActionTypes, SummaryStateFieldNames } from './types';
import { startCase } from 'lodash';
import { Dispatch } from 'redux';
import { ApplicationState } from '../..';
import { getQuickNoteForPseudoDiseases, getWhatWeFound } from '../../../../utilities/analysis/analysisDefaults';
import { showToast } from '../../toast/actions';

export const changeField = (fieldName: SummaryStateFieldNames, fieldValue: string) =>
  action(SummaryActionTypes.CHANGE_FIELD, {
    fieldName,
    fieldValue,
  });

export const resetField = (fieldName: SummaryStateFieldNames) => {
  const confirmed = window.confirm(`Are you sure you would like to reset ${startCase(fieldName)}?`);
  if (confirmed) {
    return action(SummaryActionTypes.RESET_FIELD, fieldName);
  }

  return action('');
};

export const updateDefaultQuickNote = () =>
  async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { report } = getState();
    const { clientDetails } = report;

    try {
      const { pseudoDiseasesByGene } = clientDetails;
      const quickNote = getQuickNoteForPseudoDiseases(pseudoDiseasesByGene, clientDetails.clientA, clientDetails.clientB);

      dispatch(action(SummaryActionTypes.UPDATE_DEFAULT_QUICK_NOTE, quickNote));
    } catch (e) {
      showToast('error', 'Failed to generate default quick note copy', e.message);
    }
  };

export const updateDefaultWhatWeFound = () =>
  async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { report } = getState();
    const { clientDetails } = report;

    try {
      const { diseasesByGene } = clientDetails;
      const whatWeFound = getWhatWeFound(diseasesByGene, clientDetails.clientA, clientDetails.clientB);

      dispatch(action(SummaryActionTypes.UPDATE_DEFAULT_WHAT_WE_FOUND, whatWeFound));
    } catch (e) {
      showToast('error', 'Failed to generate default quick note copy', e.message);
    }
  };
