import * as React from 'react';

import { Switch } from 'react-router';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';
import MemberFamilyHistory from './members/MemberFamilyHistory';
import { MemberShowLegacyRedirect } from './members/MemberShowLegacyRedirect';

const Members = () => {
  return (
    <Switch>
      <Route path={PATHS.MEMBER_FAMILY_HISTORY} component={MemberFamilyHistory} />
      <Route path={PATHS.MEMBER_JOURNEY_LEGACY_REDIRECT} component={MemberShowLegacyRedirect} />
      <Route path={PATHS.MEMBER_SHOW_LEGACY_REDIRECT} component={MemberShowLegacyRedirect} />
    </Switch>
  );
};

export default Members;
