import * as React from 'react';
import { useDispatch } from 'react-redux';
import DoctorShowContext from '../../../contexts/DoctorShowContext';
import { navigate } from '../../../store/router/actions';
import Button from '../../widgets/Button';

const renderFieldOrPlaceholder = (isValid: boolean, fieldLabel: string, fallbackPlaceholderLabel: string, isGreen: boolean, onEditDoctor: () => void) => {
  if (isValid) { return <div className={isGreen ? 'text-green' : ''}>{fieldLabel}</div> }

  return <button className="text-orange text-left focus:outline-none hover:underline" onClick={onEditDoctor}>{fallbackPlaceholderLabel}</button>;
}

const DoctorShowHeader = () => {
  const { doctor, doctorId } = React.useContext(DoctorShowContext);
  const dispatch = useDispatch();
  if (!doctor) {
    return null;
  }

  const onEditDoctor = () => dispatch(navigate.toForm('DoctorUpdate', {
    doctorId: doctorId,
  }));

  return (
    <div>
      <div className="w-full flex justify-between items-start">
        <h3 className="text-black font-bold text-2xl">
          {doctor.title ?
            <span>{doctor.title} </span>
            : null
          }{doctor.nameFirst} {doctor.nameMiddle} {doctor.nameLast} <span className="text-3xl">🩺</span>
        </h3>
        <Button onClick={onEditDoctor} size="sm" className="ml-10">Edit</Button>
      </div>
      <div className="flex flex-wrap">
        <div className="sm:w-full w-1/2 leading-relaxed pr-20 flex flex-col flex-wrap">
          {doctor.nickname ?
            <div className="text-grey-dark">{`Prefers '${doctor.nickname}'`}</div>
            : null
          }
          <div className="mt-12 flex flex-col">
            {renderFieldOrPlaceholder(!!doctor.specialties, `Specialities: ${doctor.specialties}`, 'Add speciality', false, onEditDoctor)}
          </div>
        </div>
        <div className="sm:w-full w-1/2 leading-relaxed mt-12 text-right pl-20 text-xs">
          {doctor.providerNumber ?
            <div className="text-grey">Provider #: {doctor.providerNumber}</div>
            : <button className="text-orange active:outline-none hover:underline" onClick={onEditDoctor}>Add provider number</button>
          }
        </div>
      </div>
    </div>
  );
}

export default DoctorShowHeader;
