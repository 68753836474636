import { SummaryState, SummaryStateFieldNames } from './types';

export const getFieldValue = (fieldName: SummaryStateFieldNames, summaryState: SummaryState): string => {
  if (summaryState.fieldOverrides && summaryState.fieldOverrides[fieldName] !== undefined) {
    return summaryState.fieldOverrides[fieldName];
  }

  return summaryState[fieldName];
};

export const getSummaryStateMergedWithOverrides = (summaryState: SummaryState): { [key in SummaryStateFieldNames]: string } => {
  const reproductiveRisk = getFieldValue('reproductiveRisk', summaryState);
  const whatWeKnow = getFieldValue('whatWeKnow', summaryState);
  const quickNote = getFieldValue('quickNote', summaryState);
  const importantNote = getFieldValue('importantNote', summaryState);
  const whatWeFound = getFieldValue('whatWeFound', summaryState);

  return {
    reproductiveRisk,
    whatWeKnow,
    quickNote,
    importantNote,
    whatWeFound,
  };
};
