import * as React from 'react';
import BaseForm from '../../widgets/BaseForm';
import { Field } from 'formik';
import { RequestCarrierStatisticsByGeneResult } from '../../../graphql/requests/requestCarrierStatisticsByGene';
import { Dictionary } from 'lodash';
import Heading from '../../widgets/Heading';
import FamilyHistoryExtract from '../../../graphql/containers/family-history/FamilyHistoryExtract';

export interface FormValues {
  selectedCarrierRatesByGene: Dictionary<string>;
}

interface CarrierRatesFormProps {
  affectedGenes: string[];
  carrierRatesByGene: RequestCarrierStatisticsByGeneResult;
  selectedCarrierRatesByGene: Dictionary<string>;
  diseaseNamesByGene: Dictionary<string>;
  memberId: string;
  onSubmit(values: FormValues): void;
}

const CarrierRatesForm = (props: CarrierRatesFormProps) => {
  const { affectedGenes, carrierRatesByGene, selectedCarrierRatesByGene, diseaseNamesByGene, memberId, onSubmit } = props;
  return (
    <>
      <BaseForm
        initialValues={{ selectedCarrierRatesByGene }}
        onSubmit={onSubmit}
        submitButtonLabel="Save"
      >
        {({
          values,
        }: { values: FormValues }) => (
          <div>
            {Object.keys(diseaseNamesByGene).sort().map(gene => (
              <div className="mb-10 border bg-white p-20" key={gene}>
                <Heading level={2}>{diseaseNamesByGene[gene]}</Heading>
                <Heading level={3} className="my-20">{affectedGenes.includes(gene) ? 'Carrier Rate' : 'Residual Risk'}</Heading>
                <Field component="div" name="selectedEthnicity">
                  {carrierRatesByGene?.[gene]?.map((carrierRateOption, index) => {
                    const isSelected = values.selectedCarrierRatesByGene[gene] === carrierRateOption.ethnicity.id;
                    const { ethnicity } = carrierRateOption;

                    return (
                      <label
                        className={`block flex w-full py-10 ${isSelected ? 'bg-grey-light' : ''} ${index > 0 ? 'border-t' : ''}`}
                        key={carrierRateOption.ethnicity.id}
                      >
                        <div className="w-2/3">
                          <input
                            type="radio"
                            name={`selectedCarrierRatesByGene[${gene}]`}
                            defaultChecked={isSelected}
                            value={carrierRateOption.ethnicity.id}
                            className="mr-10"
                          />
                          {ethnicity.name}
                        </div>
                        <div className="w-1/3 text-right font-bold">
                          {affectedGenes.includes(gene) ? carrierRateOption.carrierRateDesc : carrierRateOption.residualRiskDesc}
                        </div>
                      </label>
                    );
                  }) ?? 'No carrier rates found in Genie database.'}
                </Field>
              </div>
            ))}

          </div>
        )}
      </BaseForm>
      {memberId && (
        <FamilyHistoryExtract
          familyHistoryQuestionKeys={["Ethnicity"]}
          memberId={memberId}
        />
      )}
    </>
  );
};

export default CarrierRatesForm;
