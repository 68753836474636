import * as React from 'react';
import moment from 'moment';
import GenieMutation from '../../base/GenieMutation';
import { MutationUpdateReferralArgs, Referral, QueryReferralArgs } from '../../../../graphql/genie-api-types';
import { MutationFunction, QueryResult } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReferralForm, { ReferralFormValues } from '../../../components/referral/ReferralForm';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import { DoctorClinicPartial } from '../../../../utilities/doctorClinic';

interface ReferralUpdateProps {
  onComplete(): void;
  referralId: Referral['id'];
}

interface ReferralResult extends Pick<Referral, 'note' | 'dateCreated' | 'dateModified' | 'username'> {
  doctorClinic: DoctorClinicPartial;
}

interface ReferralQueryResult {
  referral: ReferralResult;
}

class ReferralUpdate extends React.PureComponent<ReferralUpdateProps> {
  onSaveReferral = async ({ doctorClinic, ...restOfFormValues }: ReferralFormValues, mutate: MutationFunction<MutationUpdateReferralArgs>) => {
    const { onComplete, referralId } = this.props;

    await mutate({
      variables: {
        input: {
          doctorClinicId: doctorClinic.id,
          id: referralId,
          ...restOfFormValues,
        },
      },
    });

    showToast('success', 'Updated referral', 'Successfully updated referral');
    ReduxQueryListener.triggerEvents('All');

    onComplete();
  }

  render() {
    const { referralId } = this.props;

    return (
      <GenieQuery<any, QueryReferralArgs>
        query={gql`
          query Referral($id: ID!) {
            referral(id: $id) {
              note
              dateCreated
              dateModified
              username
              doctor {
                id
                name
                clinicName
              }
            }
          }
        `}
        errorPolicy="all"
        variables={{
          id: referralId,
        }}
      >
        {({ loading, data }: QueryResult<ReferralQueryResult>) => loading ?
          (
            <Spinner label="Loading referral..." />
          ) : (
            <GenieMutation
              mutation={gql`
                mutation updateReferral($input: UpdateReferralInput!) {
                  updateReferral(input: $input) {
                    referral {
                      id,
                    }
                  }
                }
              `}
            >
              {(mutate, result) => (
                <>
                  <div className="border p-10 bg-white mb-20">
                    Created {moment(data.referral.dateCreated).format('Do MMM, YYYY')} {data.referral.username ? `by ${data.referral.username}` : ''}
                    {data.referral.dateModified ? ` (Modified: ${moment(data.referral.dateModified).format('Do MMM, YYYY')})` : ''}
                  </div>
                  <ReferralForm
                    initialValues={{
                      note: data.referral.note,
                      doctorClinic: data.referral.doctorClinic,
                    }}
                    onSubmit={(values: ReferralFormValues) => this.onSaveReferral(values, mutate)}
                    isLoading={result.loading}
                    errorMessage={result?.error?.message}
                  />
                </>
              )}
            </GenieMutation>
          )}
      </GenieQuery>
    );
  }
}

export default ReferralUpdate;
