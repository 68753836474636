import { sectionTemplates, baseTemplates, cancerTemplates } from '.';
import { ReportState } from '../../../app/store/report';
import { getCompiledTemplateVariablesWithSectionOverrides, ProcessedTemplatePage, getTemplateVariables } from '../templates/getCompiledTemplateVariablesWithSectionOverrides';
import { ReportVariables } from '../templates/extractReportTemplateVariables';
import config from '../../config';
import { TemplateSection, ReportTemplate } from '../templates';
import { FamilyReportVariables } from '../templates/carrier/familyReports';
import juice from 'juice';
import { Product } from '../../../graphql/genie-api-types';

export const renderSectionHtml = (section: TemplateSection, templateVariables: ReportVariables | FamilyReportVariables, template: ReportTemplate) => {
  const sectionTemplate = sectionTemplates[template.medium][section.template];
  return sectionTemplate({
    ...templateVariables,
    ...section.variables,
  });
};

export const renderPageHtml = (page: ProcessedTemplatePage, templateVariables: ReportVariables | FamilyReportVariables, isFirst = false, template: ReportTemplate) => {
  let html = '';

  if (!isFirst) {
    html += '<div class="page-break"></div>';
  }

  page.sections.filter(section => !section.variables._hidden).forEach((section) => {
    html += renderSectionHtml(section, templateVariables, template);
  });

  return html;
};

export const generateHtml = (reportState: ReportState, template: ReportTemplate) => {
  if (reportState.request?.request?.product === Product.Cancer) return generateCancerHtml(reportState);

  let html = '';

  const topTemplate = baseTemplates[template.medium].Top;
  const bottomTemplate = baseTemplates[template.medium].Bottom;
  
  html += topTemplate({ assetHost: config('ASSET_HOST') });

  const compiledPageVariables = getCompiledTemplateVariablesWithSectionOverrides(template.name, reportState);
  const templateVariables = getTemplateVariables(template.name, reportState);

  compiledPageVariables.forEach((page: ProcessedTemplatePage, index: number) => {
    html += renderPageHtml(page, templateVariables, index === 0, template);
  });

  html += bottomTemplate();

  return html;
};

export const generateJuicedHtml = async (reportState: ReportState, template: ReportTemplate) => {
  const reportHtml = generateHtml(reportState, template);
  let reportStyles = await fetch(`/assets/reports/css/report.css`).then(response => response.text());

  // Remove tailwind border hacks which interfere with Outlook.
  reportStyles = reportStyles.replace(/border-width: 0;\n.*border-style: solid;\n.*border-color: currentColor;/, '');

  // Fix sans font issues in outlook.
  reportStyles += 'body, table, td {font-family: Arial, Helvetica, sans-serif !important;}';

  const styledReportHtml = juice(reportHtml, {
    extraCss: reportStyles,
  });

  return styledReportHtml;
};

const generateCancerHtml = (reportState: ReportState) => {
  return cancerTemplates.CancerNAD({
    ...reportState,
    assetHost: config('ASSET_HOST')
  });
}
