import * as React from 'react';
import { Mutation, Query, QueryConsentArgs, MutationReassignConsentArgs } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/widgets/Spinner';
import { appSyncClient } from '../../../../utilities/appSync';
import { toastr } from 'react-redux-toastr';
import ConsentReassignForm from '../../../components/consent/ConsentReassignForm';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import ConsentFormHeader from '../../../components/consent/ConsentFormHeader';

interface ConsentReassignProps {
  onUpdate?(): void;
  consentId: string;
}

const CONSENT_QUERY = gql`
  query Consent($id: ID!) {
    consent(id: $id) {
      id
      dateCreated
      source
      data
      email
      name
      formId
    }
  }
`;

const CONSENT_REASSIGN_MUTATION = gql`
  mutation ReassignConsent($input: ReassignConsentInput!) {
    reassignConsent(input: $input) {
      journey {
        id
      }
    }
  }
`;

const ConsentReassign = ({ onUpdate, consentId }: ConsentReassignProps) => {
  const { loading, data } = useQuery<Pick<Query, 'consent'>, QueryConsentArgs>(CONSENT_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: {
      id: consentId,
    },
  });

  const [mutate, reassignRequest] = useMutation<Pick<Mutation, 'reassignConsent'>, MutationReassignConsentArgs>(CONSENT_REASSIGN_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['ListFlags'];
    },
  });

  if (loading) {
    return <Spinner label="Loading consent..." />;
  }

  const { id, name } = data?.consent ?? {};

  return (
    <div className="relative">
      <ConsentFormHeader
        consent={data?.consent}
      />
      <ConsentReassignForm
        consentId={id}
        onSubmit={async (values) => {
          if (!values.journeyId) {
            toastr.error('No journey was selected', 'A journey needs to be selected to complete the reassignment.');
            return;
          }

          await mutate({
            variables: {
              input: {
                consentId,
                journeyId: values.journeyId
              }
            },
          });
          
          toastr.success(name, `Assigned consent to journey ${values.journeyId}`);
          ReduxQueryListener.triggerEvents('UpdateConsent');
          
          onUpdate();
        }}
      />
      {reassignRequest.loading && <LoadingOverlay label="Reassigning ..." />}
    </div>
  );
}

export default ConsentReassign;
