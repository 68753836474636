import * as React from "react";

import JourneyInfoContext from "../../../contexts/JourneyInfoContext";
import JourneyNotifications from "../JourneyNotifications";

const JourneyInfoNotifications = () => {
  const { journeyId } = React.useContext(JourneyInfoContext);

  return (
    <div className="px-20 mt-30">
      <JourneyNotifications journeyId={journeyId} />
    </div>
  );
};

export default JourneyInfoNotifications;
