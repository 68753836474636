import { ClientDetailsVariableTypes } from './ClientDetails';
import { DiseaseHeaderVariableTypes } from './DiseaseHeader';
import { DiseaseTableVariableTypes } from './DiseaseTable';
import { SectionVariableTypes } from './Section';
import { TwoColSectionVariableTypes } from './TwoColSection';
import { IntroVariableTypes } from './Intro';
import { PageTitleVariableTypes } from './PageTitle';

import { IntroVariableTypes as EmailIntroVariableTypes } from './emails/Intro';

export default {
  variableTypes: {
    Intro: IntroVariableTypes,
    ClientDetails: ClientDetailsVariableTypes,
    DiseaseHeader: DiseaseHeaderVariableTypes,
    DiseaseTable: DiseaseTableVariableTypes,
    Section: SectionVariableTypes,
    TwoColSection: TwoColSectionVariableTypes,
    PageTitle: PageTitleVariableTypes,
    EmailIntro: EmailIntroVariableTypes,
  },
};
