import * as React from 'react';
import { Flag, Journey } from '../../../graphql/genie-api-types';
import moment from 'moment';
import convertJourneyResultsToJourneyTableData from '../../graphql/processors/convertJourneyResultsToJourneyTableData';
import JourneyTable from './JourneyTable';
import { FiClock } from './Icon';
import FlagCodeLabel from '../flag/FlagCodeLabel';
import FlagTimeLabel from '../flag/FlagTimeLabel';
import CounsellorIcon from './CounsellorIcon';
import FlagPriorityLabel from '../flag/FlagPriorityLabel';
import { keyBy } from 'lodash';
import { Action } from './DropdownButton';
import updateFlagSnooze from '../../graphql/requests/flags/updateFlagSnooze';
import FlagTAT from '../flag/FlagTAT';
import { journeySortBy } from '../../graphql/containers/flags/Flags';

interface FlagJourneyTableProps {
  isLoading: boolean;
  flags: Flag[];
  onReload(): Promise<void>;
  sortBy: journeySortBy;
}

const flagActionsForJourney = (): Action[] => [{
  label: 'Snooze',
  name: 'snoozeFlags',
  variant: 'success',
}];

export default class FlagJourneyTable extends React.PureComponent<FlagJourneyTableProps> {
  getFlagsByJourneyId() {
    const { flags } = this.props;
    return keyBy(flags, (flag) => flag?.journey?.id);
  }

  onSnoozeFlag = async (flag: Flag, snoozeUntil: Flag['dateSnoozeUntil']) => {
    const { onReload } = this.props;

    await updateFlagSnooze(flag.id, snoozeUntil);
    await onReload();
  };

  onActionClick = async (journeyIds: string[], actionName: string) => {
    const { onReload } = this.props;
    if (actionName === 'snoozeFlags') {
      const snoozeUntil = moment().add(1, 'day').set('hour', 9).toISOString();
      const flags = journeyIds.map(journeyId => this.getFlagsByJourneyId()[journeyId]);

      await Promise.all(flags.map((flag) => {
        return this.onSnoozeFlag(flag, snoozeUntil);
      }));
    }

    onReload();
  };
  
  render() {
    const { flags, onReload, isLoading, sortBy } = this.props;

    const flagJourneys = flags.filter(flag => flag.journey).map(flag => flag.journey);
    const tableData = convertJourneyResultsToJourneyTableData(flagJourneys, false);
    const flagsByJourneyId = this.getFlagsByJourneyId();

    const isOOOC = flags.filter(flag => flag.flagType == "OUT_OF_OUR_CONTROL").length > 0;
    
    const getFlagDateCreated = (journey: Journey) => {
      const flag = flagsByJourneyId[journey.id];
      return flag?.dateCreated;
    };

    const cellsForJourney = (journey: Partial<Journey>) => [
      <FlagCodeLabel flagCode={flagsByJourneyId?.[journey.id]?.code} flagType={flagsByJourneyId[journey.id]?.flagType} key="flagCode" />,
      <FlagTimeLabel flagCreated={flagsByJourneyId[journey.id]?.dateCreated} key="time" />,
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor" />,
      <FlagPriorityLabel flagPriority={flagsByJourneyId[journey.id]?.priority} key="priority" />,
      isOOOC && <FlagTAT flagJourney={flagsByJourneyId[journey.id]?.journey} key="TAT" />,
    ];

    return (
      <JourneyTable
        {...tableData}
        isLoading={isLoading}
        header={[
          'Reason',
          <FiClock key="time" />,
          'GC',
          'Priority',
          isOOOC && 'TAT'
        ]}
        onRefresh={onReload}
        emptyText="Nothing found here, nice work!"
        cellsForJourney={cellsForJourney}
        getFlagDateCreated={getFlagDateCreated}
        actionsForJourney={flagActionsForJourney}
        onActionClick={this.onActionClick}
        sortBy={sortBy}
      />
    );
  }
}
