import * as React from 'react';
import { colors } from '../../types/tailwind';

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  color?: colors;
  textColor?: colors;
}

const fadedTextStyle: React.CSSProperties = {
  color: 'rgba(0,0,0,0.6)',
};

const Badge = ({ className, color, textColor, ...props }: BadgeProps) => (
  <span className={`inline-block px-10 py-3 rounded text-${textColor} bg-${color} ${className || ''}`} style={color && !color.includes('grey') && !color.includes('purple-100') ? fadedTextStyle : null} {...props} >
    {props.children}
  </span>
);

Badge.defaultProps = {
  textColor: 'black',
  color: 'purple-100',
};

export default Badge;
