import * as React from 'react';
import FormInput, { FormInputProps } from '../widgets/form/FormInput';
import { Member, Sex } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import * as yup from 'yup';

interface MemberFormProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
}

interface MemberFormInput extends FormInputProps {
  name: keyof Member;
}

const formItems: Pick<MemberFormInput, 'label'|'name'|'as'|'options'|'type'|'min'|'max'>[] = [
  {
    label: 'Full name',
    name: 'name',
  },
  {
    label: 'Preferred name',
    name: 'nickname',
  },
  {
    label: 'Sex assigned at birth',
    name: 'sex',
    as: 'select',
    options: [
      {
        value: '',
        label: '-',
      },
      {
        value: Sex.Male,
        label: 'Male',
      },
      {
        value: Sex.Female,
        label: 'Female',
      },
    ],
  },
  {
    label: 'DOB',
    name: 'dateOfBirth',
    type: 'date',
    min: '1940-01-01',
    max: '2010-01-01',
  },
  {
    label: 'Phone number',
    name: 'phoneNumber',
    type: 'tel',
  },
  {
    label: 'Email address',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Enable for Member flow',
    name: 'onlineAccessEnabled',
    type: 'checkbox',
  },
];

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email("Invalid email format").required().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Email format is invalid"),
  onlineAccessEnabled: yup.boolean().oneOf([true], 'Must enable member flow access').required('must enable member flow access'),
})
interface MemberFormState {
  isNicknameEdited: boolean;
}

class MemberForm extends React.PureComponent<MemberFormProps, MemberFormState> {
  state: Readonly<MemberFormState> = {
    isNicknameEdited: false,
  };

  static getDerivedStateFromProps(props: MemberFormProps, state: MemberFormState) {
    if (props.initialValues['nickname']) {
      return {
        ...state,
        isNicknameEdited: true,
      };
    }
    return state;
  }

  onInput(event: React.FormEvent<any>, setFieldValue: (field: string, value: any) => void) {
    const { isNicknameEdited } = this.state;
    if (event.type !== 'input' || isNicknameEdited) {
      return;
    }

    const formInput: HTMLInputElement = event.target as HTMLInputElement;
    if (formInput.name === 'name' && formInput.value.length > 0) {
      const firstName = formInput.value.split(' ')[0];
      setFieldValue('nickname', firstName);
    } else if (formInput.name === 'nickname' && formInput.value.length > 0) {
      this.setState({
        isNicknameEdited: true,
      });
    }
  }

  render() {
    const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = this.props;

    return (
      <BaseForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        submitButtonLabel="Save"
        hasCancel={!!onCancel}
        onCancel={onCancel}
        errorMessage={errorMessage}
        isLoading={isLoading}
        onSubmit={onSubmit}
      >
        {({ handleChange, values, setFieldValue, errors }) => (
          <div>
            {formItems.map((formItem, index) => (
              <FormInput
                {...formItem}
                value={values[formItem.name]}
                onChange={handleChange}
                key={formItem.name}
                autoFocus={index === 0}
                onInput={event => this.onInput(event, setFieldValue)}
                setFieldValue={setFieldValue}
                isInvalid={!!errors[formItem.name]}
                helpText={errors[formItem.name]}
              />
            ))}
          </div>
        )}
      </BaseForm>
    );
  }
}

export default MemberForm;
