import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, Query, MutationUpdateJourneyParamsArgs, QueryJourneyArgs, Journey } from '../../../../graphql/genie-api-types';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import JourneyLabOrderNumberForm from '../../../components/journey/JourneyLabOrderNumberForm';

interface JourneyLabOrderNumberProps {
  onUpdate?(): void;
  onCancel?(): void;
  journeyId: Journey['id'];
}

const JourneyLabOrderNumber = (props: JourneyLabOrderNumberProps) => (
  <GenieQuery<Pick<Query, 'journey'>, QueryJourneyArgs>
    query={gql`
      query Journey($id: ID!) {
          journey(id: $id) {
            id
            labOrderNumber
          }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.journeyId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'journey'>>) => loading ?
      (
        <Spinner label="Loading request..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateJourneyParams($input: UpdateJourneyParamsInput!) {
            updateJourneyParams(input: $input) {
              journey {
                id
                labOrderNumber
              }
            }
          }
          `}
        >
          {(mutate: MutationFunction<Mutation, MutationUpdateJourneyParamsArgs>, result) => {

            return (
              <JourneyLabOrderNumberForm
                initialValues={{
                  ...data.journey,
                }}
                onCancel={props.onCancel}
                isLoading={result.loading}
                errorMessage={result?.error?.message}
                onSubmit={async (requestFields: Pick<Journey, 'labOrderNumber'>) => {
                  const mutationResult = await mutate({
                    variables: {
                      input: {
                        journeyId: props.journeyId,
                        labOrderNumber: requestFields.labOrderNumber,
                      },
                    },
                  });

                  if (mutationResult && props.onUpdate) {
                    props.onUpdate();
                  }
                }}
              />
            );
          }}
        </GenieMutation>
      )}
  </GenieQuery>
);

export default JourneyLabOrderNumber;
