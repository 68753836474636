import * as React from 'react';
import FormGroup from './FormGroup';
import FormControl, { FormControlProps } from './FormControl';
import { groupBy } from 'lodash';
import ReportMacroSelector from '../../report/ReportMacroSelector';

export interface FormInputOption<Value = string> {
  value: Value;
  label: string;
  group?: string;
}

export interface FormInputProps extends FormControlProps {
  name: string;
  label?: string;
  options?: FormInputOption[];
  value?: string;
  helpText?: string;
  autoFocus?: boolean;
  setFieldValue(fieldName: string, fieldValue: any): void;
  halfWidth?: boolean;
  labelClassName?: string;
  clearBottomMargin?: boolean;
  useMacroSelector?: boolean;
}

const getOptionsContent = (options: FormInputOption[]): React.ReactNode[] => {
  if (!options) {
    return null;
  }

  const items: React.ReactNode[] = [];

  const optionsByGroup = groupBy(options, 'group');

  Object.keys(optionsByGroup).sort((a) => a === 'undefined' ? -1000 : 0).forEach(groupName => {
    const optionItems: React.ReactNode[] = [];
    const options = optionsByGroup[groupName];
    options.forEach(option => {
      optionItems.push((
        <option value={option.value} key={option.value}>{option.label}</option>
      ));
    });

    if (groupName !== 'undefined') {
      items.push((
        <optgroup label={groupName} key={groupName}>
          {optionItems}
        </optgroup>
      ))
    }
    else {
      optionItems.forEach(optionItem => items.push(optionItem));
    }
  });

  return items;
}

const FormInput = ({ label, options, clearBottomMargin, helpText, useMacroSelector, value, labelClassName, setFieldValue, halfWidth, ...restOfProps }: FormInputProps) => {

  const onInsertMacro = React.useCallback((macroText: string) => {
    setFieldValue(restOfProps.name, `${value ? `${value} ` : ''}${macroText}`);
  }, [setFieldValue, value]);

  switch (restOfProps.type) {
    case 'checkbox':
      return (
        <div className={`flex-col justify-start items-start ${clearBottomMargin ? '' : 'mb-20'}  ${halfWidth ? 'w-1/2' : 'w-full'}`}>
          <label className={`p-12 py-15 block flex items-center rounded  ${labelClassName ?? ''}`}>
            <input {...restOfProps} checked={!!value} />
            <div className="w-full ml-5">{label}</div>
          </label>
          {helpText && <p className='w-full ml-14 text-grey-dark mt-5 text-sm'>{helpText}</p>}
        </div>
      );

    default:
      return (
        <FormGroup controlId={`formGroup${restOfProps.name}`} label={label} halfWidth={halfWidth} helpText={helpText} clearBottomMargin={clearBottomMargin}>
          <>
            {useMacroSelector && <ReportMacroSelector onSelect={onInsertMacro} />}
            <FormControl
              {...restOfProps as any}
              setFieldValue={setFieldValue}
              value={value ?? ''}
            >
              {getOptionsContent(options)}
            </FormControl>
          </>
        </FormGroup>
      );
  }
};

FormInput.defaultProps = {
  value: '',
  type: 'text',
};

export default FormInput;
