import { escapeRegExp } from 'lodash';
import { Disease } from '../../graphql/genie-api-types';

export type TemplatePayload = {
  disease: Disease,
  nonCarrierName: string,
  carrierName: string,
  carrierRate: string,
  residualRisk: string,
};

export const replaceContentfulTemplateVariables = (templateString: string, templatePayload: TemplatePayload) => {
  const replacementValues: { [key: string]: string } = {
    '{CONDITION.NAME}': templatePayload.disease.name,
    '{CONDITION.GENE}': templatePayload.disease.genes.join(','),
    '{CONDITION.CARRIER_RATE}': templatePayload?.carrierRate ?? '{CONDITION.CARRIER_RATE}',
    '{CONDITION.RESIDUAL_RISK}': templatePayload?.residualRisk ?? '{CONDITION.RESIDUAL_RISK}',
    '{NON_CARRIER.NAME}': templatePayload.nonCarrierName,
    '{CARRIER.NAME}': templatePayload.carrierName,
  };

  let string = templateString || '';

  Object.keys(replacementValues).forEach((replacementPlaceholder: string) => {
    const regexp = new RegExp(escapeRegExp(replacementPlaceholder), 'g');
    string = string.replace(regexp, replacementValues[replacementPlaceholder]);
  });

  return string;
};
