import { appSyncClient } from '../appSync';
import { Query, QuerySearchMembersArgs, QueryListJourneysArgs, Journey, Request, Member } from '../../graphql/genie-api-types';
import pThrottle from 'p-throttle';
import gql from 'graphql-tag';

const SEARCH_MEMBER_QUERY = gql`
  query SearchMembers($term: String!) {
    searchMembers(term: $term) {
      id
    }
  }
`;


interface RequestResult {
  id: Request['id'];
  datePaid: Request['datePaid'];
  product: Request['product'];
  journeys: {
    id: Journey['id'];
    state: Journey['state'];
    member: Pick<Member, 'id'|'email'>;
  }[];
}

interface SearchJourneyResult {
  listJourneys: {
    edges: {
      node: {
        id: Journey['id'];
        request: RequestResult;
      }
    }[];
  }
}

const SEARCH_JOURNEY_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          request {
            id
            datePaid
            product
            journeys {
              id
              state
              member {
                id
                email
              }
            }
          }
        }
      }
    }
  }
`;

interface UnmatchedResult {
  matchType: 'none';
}

interface MatchedResult {
  matchType: 'full'|'partial';
  match: RequestResult;
}

type Result = UnmatchedResult | MatchedResult;

export const findMemberByEmail = async (email: string) => {
  const members = await appSyncClient.query<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>({
    query: SEARCH_MEMBER_QUERY,
    variables: {
      term: email.toLowerCase(),
    },
  });

  return members?.data?.searchMembers?.[0];
}

export const findRequestByEmail = pThrottle(async (email: string, partnerEmail: string = '', onlyPaid: boolean = true): Promise<Result> => {
  let member = await findMemberByEmail(email);
  if (!member && partnerEmail) {
    member = await findMemberByEmail(partnerEmail);
  }
  
  if (!member) {
    return {
      matchType: 'none',
    };
  }
  
  const memberJourneys = await appSyncClient.query<SearchJourneyResult, QueryListJourneysArgs>({
    query: SEARCH_JOURNEY_QUERY,
    variables: {
      input: {
        memberId: member.id,
        paid: onlyPaid,
        excludeCompleted: true,
      },
    },
  });

  // Find most recent paid request.
  const request = memberJourneys?.data?.listJourneys?.edges?.reverse()?.[0]?.node?.request;
  let matchType: Result['matchType'] = 'partial';
  const foundPrimaryEmail = !!request?.journeys.find(journey => journey.member.email?.toLowerCase() === email.toLowerCase());
  
  if (partnerEmail) {
    const foundPartnerEmail = !!request?.journeys.find(journey => journey.member.email?.toLowerCase() === partnerEmail?.toLowerCase());
    
    if (foundPrimaryEmail && foundPartnerEmail) {
      matchType = 'full';
    }
  }
  else if (foundPrimaryEmail) {
    matchType = 'full';
  }
  
  return {
    matchType,
    match: request,
  };
}, 10, 1000);
