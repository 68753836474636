import * as React from 'react';
import moment from 'moment';
import { FamilyHistoryItem, FamilyHistoryClassification, FamilyHistorySource } from '../../../graphql/genie-api-types';
import Button from '../widgets/Button';
import FamilyHistoryClassificationIcon from './FamilyHistoryClassificationIcon';
import { FiEdit2 } from '../widgets/Icon';
import PrivateContent from '../widgets/PrivateContent';

interface FamilyHistoryItemProps {
  familyHistoryGroupItem: Pick<FamilyHistoryItem, 'id' | 'classification' | 'source' | 'name' | 'value' | 'dateCreated' | 'dateUpdated'>;
  alwaysShowTimestamps?: boolean;
  onEditHistoryItem?(itemId: string): void;
}

const FamilyHistoryItem = (props: FamilyHistoryItemProps) => {
  const { onEditHistoryItem, familyHistoryGroupItem, alwaysShowTimestamps } = props;
  const hasChanges = familyHistoryGroupItem.dateCreated !== familyHistoryGroupItem.dateUpdated;

  let textClass = '';
  if (familyHistoryGroupItem.source === FamilyHistorySource.Genie) {
    textClass = 'text-grey-dark';
  }
  if (familyHistoryGroupItem.classification === FamilyHistoryClassification.Alert) {
    textClass = 'text-orange-dark';
  }

  return (
    <div className={`bg-white -mx-8 -mb-8 rounded border-purple-light p-8 flex group mt-10 ${textClass}`}>
      <div className="-mt-3"><FamilyHistoryClassificationIcon classification={familyHistoryGroupItem.classification} /></div>
      <div className="flex-1 text-xs px-8 flex">
        <div className="flex-1">
          <p className="font-bold mb-5">{familyHistoryGroupItem.name}</p>
          <p>
            {familyHistoryGroupItem.name === 'Name' ? (
              <PrivateContent>{familyHistoryGroupItem.value}</PrivateContent>
            ) : familyHistoryGroupItem.value}
            
          </p>
          <div className={`mt-5 text-xxs text-grey-dark ${alwaysShowTimestamps ? '' : 'hidden group-hover:flex'}`}>
            {hasChanges ?
              (
                <span className="flex items-center">
                  <FiEdit2 size={8} className="mr-3" />
                  <span>Edited {moment(familyHistoryGroupItem.dateUpdated).fromNow()}</span>
                </span>
              ) :
              (
                <span> Created {moment(familyHistoryGroupItem.dateCreated).fromNow()}</span>
              )}
          </div>
        </div>
      </div>
      {
        onEditHistoryItem && familyHistoryGroupItem.source !== FamilyHistorySource.MemberFlow && (
          <div>
            <Button onClick={() => onEditHistoryItem(familyHistoryGroupItem.id)} size="sm" variant="link" className="invisible group-hover:visible">
              Edit
            </Button>
          </div>
        )
      }
    </div >
  );
}

export default React.memo(FamilyHistoryItem);
