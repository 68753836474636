import * as React from 'react';
import { Note } from '../../../graphql/genie-api-types';
import FormControl from '../widgets/form/FormControl';
import Button from '../widgets/Button';
import LoadingOverlay from '../widgets/LoadingOverlay';
import ActionLink from '../widgets/ActionLink';
import FormCheck from '../widgets/form/FormCheck';

type FormPayload = {
  text: string;
  isImportant: boolean;
};

interface NoteFormProps {
  onSubmit(values: FormPayload): Promise<void>;
  isLoading: boolean;
}

interface RequestNoteState {
  text: string;
  isImportant: boolean;
  isVisible: boolean;
}

export interface NoteFormValues {
  text: Note['text'];
}

class NoteForm extends React.PureComponent<NoteFormProps, RequestNoteState> {
  state: Readonly<RequestNoteState> = {
    text: '',
    isVisible: false,
    isImportant: false,
  };

  onSubmit = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    const { onSubmit } = this.props;
    const { text, isImportant } = this.state;

    try {
      await onSubmit({ text, isImportant });
      this.setState({ text: '', isImportant: false, isVisible: false });
    } catch (e) {
      // Don't reset text if there was an error.
    }
  }

  onFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const didPressCommandEnter = e.keyCode === 13 && e.metaKey;
    if (!didPressCommandEnter) return true;

    this.onSubmit();
  }

  onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      text: event.target.value,
    });
  }

  onToggleVisibility = () => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  }
  
  onToggleImportant = () => {
    this.setState({
      isImportant: !this.state.isImportant,
    });
  }

  render() {
    const { text, isVisible, isImportant } = this.state;
    const { isLoading } = this.props;

    return (
      <form
        onSubmit={this.onSubmit}
        onKeyDown={this.onFormKeyDown}
      >
        {isVisible ? (
          <div>
            <FormControl
              onChange={this.onChangeText}
              name="text"
              value={text}
              as="textarea"
              placeholder="Add a note"
              autoFocus={true}
            />
            {isLoading ? <LoadingOverlay label="Adding note..." /> : null}
            <div className="flex justify-between mt-10">
              <FormCheck
                type="checkbox"
                label="Important?"
                name="isImportant"
                className="mr-0.2"
                checked={isImportant}
                onChange={this.onToggleImportant}
              />
              <div className="pl-8">
                <Button
                  type="submit"
                  disabled={isLoading || text == ''}
                  variant="primary"
                >
                  {isLoading ? 'Saving ...' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        ) : (
            <div className="flex w-full justify-start">
              <ActionLink
                className="text-xs inline-block font-bold mr-5 text-blue-dark"
                onClick={this.onToggleVisibility}
              >
                + Write note
              </ActionLink>
            </div>
          )}
      </form>
    );
  }
}

export default NoteForm;
