import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { JourneysConnection, JourneyState, Product, QueryListJourneysArgs, ProductVariant, Journey, Pregnancy, Member, JourneyLog } from '../../../graphql/genie-api-types';
import { getProductIcon } from '../../../utilities/journeys/products';
import useStatsQuery from '../../../utilities/useStatsQuery';
import Badge from '../../components/widgets/Badge';
import Table from '../../components/widgets/Table';
import { createReportFromRequest } from '../../store/report/actions';
import { navigate } from '../../store/router/actions';
import { PATHS } from '../../store/router/types';
import PregnancyIcon from '../../components/widgets/PregnancyIcon';
import RecollectionIcon from '../../components/widgets/RecollectionIcon';
import { flatten, sortBy } from 'lodash';
import PrivateContent from '../widgets/PrivateContent';
import DateBadge from '../widgets/DateBadge';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';
import MemberlessPlaceholder from '../member/MemberlessPlaceholder';
import { formatMemberName } from '../../../utilities/helpers';
import { Action } from '../widgets/DropdownButton';

const REPORT_JOURNEYS = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          hasRecollection
          dateReviewed
          completedDate
          lastStateChangeDate
          request {
            id
            datePaid
            product
            productVariant
            initiatingMember {
              id
            }
            pregnancy {
              days
              dueDate
              dueDateConfirmed
            }
            reports {
              id
              dateCreated
            }
            notes {
              date
              text
              createdBy {
                email
              }
            }
          }
          kits {
            id
            dateShippedToLab
            recollectionRequestedDate
          }
          history {
            date
            fromState
            toState
          }
          member {
            id
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

interface RequestRow {
  requestId: string;
  primaryJourneyId: string;
  members: Member[];
  product: Product;
  productVariant: ProductVariant;
  reportCreated: string;
  datePaid: string;
  pregnancy?: Pregnancy;
  dateShippedToLab: string;
  hasRecollection: boolean;
  initiatingMember: Member;
  dateReviewed: string;
  completedDate: string;
  lastStateChangeDate: string;
  history: JourneyLog[];
  dateRevived: string;
  lastNote: string;
  lastNoteCreator: string;
}

export type RowActions = 'viewReport' | 'createReport';

interface AmendedReportsTaskTableProps {
  journeyState: JourneyState;
  tableTitle: string;
}

const AmendedReportsTaskTable = (props: AmendedReportsTaskTableProps) => {
  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  const readyToReviewJourneys = useStatsQuery<JourneysConnection, QueryListJourneysArgs>(REPORT_JOURNEYS, {
    variables: {
      input: {
        state: props.journeyState,
      },
    },
    fetchPolicy: 'network-only',
  }, 'listJourneys');

  const dispatch = useDispatch();

  const requestRowData: RequestRow[] = React.useMemo(() => {
    const journeysByRequestId: { [key: string]: Partial<Journey>[] } = {};

    const journeyNodes = readyToReviewJourneys.data?.edges?.map(edge => edge.node) ?? [];

    journeyNodes.forEach(journeyNode => {
      if (!journeysByRequestId[journeyNode.request.id]) {
        journeysByRequestId[journeyNode.request.id] = [];
      }
      journeysByRequestId[journeyNode.request.id].push(journeyNode);
    });

    return sortBy<RequestRow>(Object.values(journeysByRequestId).map((journeyNodes) => {
      const request = journeyNodes[0].request;
      const hasRecollection = !!journeyNodes?.find(journey => journey.hasRecollection);

      const latestLabShippingDate = sortBy(flatten(journeyNodes.map(journeyNode => journeyNode.kits))
        .filter(kit => !kit.recollectionRequestedDate)
        .map(kit => kit.dateShippedToLab)
        , (labShippingDate => moment(labShippingDate).unix())).reverse()[0];

      const getLatestCompleteToReadyForReportHistory = journeyNodes[0].history.filter(historyItem => historyItem.fromState === JourneyState.Complete && historyItem.toState === JourneyState.ReadyForReport);
      const getLatestReadyToArchiveToReadyForReportHistory = journeyNodes[0].history.filter(historyItem => historyItem.fromState === JourneyState.ReadyToArchive && historyItem.toState === JourneyState.ReadyForReport);
    
      const dateRevived = sortBy([...getLatestCompleteToReadyForReportHistory, ...getLatestReadyToArchiveToReadyForReportHistory].filter(item => item !== undefined), (historyItem => moment(historyItem.date).unix())).reverse()[0];
      const latestNote = sortBy([...request.notes], (note => moment(note.date).unix())).reverse()[0];

      return {
        requestId: request.id,
        primaryJourneyId: journeyNodes[0].id,
        members: journeyNodes?.map(journey => journey.member),
        product: request.product,
        productVariant: request.productVariant,
        reportCreated: request.report?.dateCreated,
        datePaid: request.datePaid,
        pregnancy: request.pregnancy,
        hasRecollection,
        dateShippedToLab: latestLabShippingDate,
        initiatingMember: request.initiatingMember,
        dateReviewed: journeyNodes[0].dateReviewed,
        completedDate: journeyNodes[0].completedDate,
        lastStateChangeDate: journeyNodes[0].lastStateChangeDate,
        history: journeyNodes[0].history,
        dateRevived: dateRevived?.date,
        lastNote: latestNote?.text,
        lastNoteCreator: latestNote?.createdBy?.email,
      };
    }), ((requestRow: RequestRow) => {
      return moment(requestRow.dateRevived).unix();
    })) ?? [];
  }, [readyToReviewJourneys.data]);

  const probablyAmendedRows = React.useMemo(() => {
    return requestRowData.filter(requestRow => requestRow.history.find(historyItem => [JourneyState.Complete, JourneyState.ReadyToArchive].includes(historyItem.fromState)));
  }, [requestRowData]);

  const onActionClick = React.useCallback((rowIds: string[], actionId: RowActions) => {
    const requestId = rowIds[0];
    const requestRow = requestRowData.find(requestRow => requestRow.requestId === requestId);
    const journeyId = requestRow.primaryJourneyId;

    switch (actionId) {
      case 'createReport':
        dispatch(createReportFromRequest(requestId));
        onSelectJourney(journeyId);
        break;

      case 'viewReport':
        dispatch(navigate.toReportPath(PATHS.REPORTS_CLIENT_DETAILS, 'remote', requestId))
        onSelectJourney(journeyId);
        break;
    }
  }, [dispatch, createReportFromRequest, requestRowData]);

  const onRowSelect = React.useCallback((requestId: string) => {
    const requestRow = requestRowData.find(requestRow => requestRow.requestId === requestId);
    const journeyId = requestRow.primaryJourneyId;

    onSelectJourney(journeyId);
  }, [requestRowData, dispatch]);

  const viewReportActions: Action[] = [{
    name: 'viewReport',
    label: 'View report',
    variant: 'success',
  }] 

  const createReportActions: Action[] = [{
    name: 'createReport',
    label: 'Create report',
    variant: 'success',
  }]

  return (
    <div>
       {[probablyAmendedRows].map((requestRows) => (
        <div className="mb-30" key={props.tableTitle}>
          
          <div className='flex p-10 pl-0 font-bold text-sm font-lt text-grey-darkest justify-between items-center'>
            <h2 className="text-lg font-bold mb-10">{props.tableTitle}</h2>
            <div>
              <span className='mr-10'>Total rows:</span><span>{requestRows.length}</span>
            </div>
          </div>
          <Table
            header={['Names', 'Product', 'Time since amended', 'Last Note']}
            alwaysShowActions
            onActionClick={onActionClick}
            onRefresh={readyToReviewJourneys.refetch}
            isLoading={readyToReviewJourneys.isLoading}
            onRowSelect={onRowSelect}
            rowGroups={requestRows.map(requestRow => ({
              id: requestRow.requestId,
              rows: [
                {
                  id: requestRow.requestId,
                  cells: [
                    <div key="names" className="w-64 whitespace-normal">
                      {requestRow.members.map(member => member
                        ? <Badge className="mr-5" key={member.name}><PrivateContent>{formatMemberName(member, requestRow)}</PrivateContent></Badge>
                        : <MemberlessPlaceholder/>
                      )}
                      {requestRow.pregnancy && <PregnancyIcon pregnancy={requestRow.pregnancy} />}
                      {requestRow.hasRecollection && <RecollectionIcon />}
                    </div>,
                    getProductIcon(requestRow.product, requestRow.productVariant),
                    <DateBadge dateString={requestRow.dateRevived} orangeDays={21} redDays={28} key="date" />,
                    <p className="w-64 whitespace-normal" key="lastNote">
                      {requestRow.lastNote ? requestRow.lastNote : "-"}
                      {requestRow.lastNoteCreator && <><br/><span className="text-xs text-grey">{requestRow.lastNoteCreator}</span></>}
                    </p>,
                  ],
                  actions: requestRow.reportCreated ? viewReportActions : createReportActions,
                },
              ],
            }))}
          />
          <div className='flex border-t border-b p-10 border-grey-mid bg-grey-light font-bold text-sm font-lt text-grey-darkest justify-between'><span>Total rows:</span><span>{requestRows.length}</span></div>
        </div>
         ))
        }
    </div>
  );
};

export default AmendedReportsTaskTable;
