import * as React from 'react';
import { PageInfo } from '../../../graphql/genie-api-types';
import { FiChevronLeft, FiChevronRight } from './Icon';
import { StyledIcon } from '@styled-icons/styled-icon';
import Spinner from './Spinner';

interface PagerProps {
  onClickNextPage(): void;
  onClickPreviousPage?(): void;
  pageInfo: PageInfo;
  isLoading?: boolean;
}

const renderPagerButton = (label: string, isEnabled: boolean, callback: () => void, IconBefore?: StyledIcon, IconAfter?: StyledIcon) => (
  <button
    type="button"
    className={`focus:outline-none ${isEnabled ? 'text-purple hover:text-black' : 'cursor-default text-grey'} flex items-center`}
    onClick={isEnabled ? callback : undefined}
  >
    {IconBefore ? <IconBefore /> : null}
    {label}
    {IconAfter ? <IconAfter /> : null}
  </button>
);

class Pager extends React.PureComponent<PagerProps> {
  render() {
    const { pageInfo, onClickPreviousPage, onClickNextPage, isLoading } = this.props;
    if (!pageInfo || !pageInfo.hasPreviousPage && !pageInfo.hasNextPage) {
      return null;
    }

    return (
      <div className="flex justify-end py-10 text-sm">
        {isLoading ? <Spinner label="Loading..." /> : (
          <div className="flex">
            {onClickPreviousPage && (
              <>
                {renderPagerButton('Previous', pageInfo.hasPreviousPage, onClickPreviousPage, FiChevronLeft)}
                <span className="px-10"></span>
              </>
            )}
            {renderPagerButton('Next', pageInfo.hasNextPage, onClickNextPage, null, FiChevronRight)}
          </div>
        )}
      </div>
    );
  }
}

export default Pager;
