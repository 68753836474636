import * as React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { Journey, RequestsConnection, QueryListRequestsArgs, Request, Member, JourneyState, ProductVariant, Kit } from '../../../../graphql/genie-api-types';
import Screen from '../../../components/widgets/Screen';
import useStatsQuery from '../../../../utilities/useStatsQuery';
import Badge from '../../../components/widgets/Badge';
import PrivateContent from '../../../components/widgets/PrivateContent';
import { getProductIcon } from '../../../../utilities/journeys/products';
import Table from '../../../components/widgets/Table';
import PregnancyIcon from '../../../components/widgets/PregnancyIcon';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import { isTwoPersonTest } from '../../../../utilities/request/request-helpers';
import MemberOrPlaceholder from '../../../components/member/MemberOrPlaceholder';
import { formatMemberName } from '../../../../utilities/helpers';
import DateBadge from '../../../components/widgets/DateBadge';
import { flatten, sortBy } from 'lodash';
import { useEffect } from 'react';
interface PreTestRequestJourney extends Pick<Journey, 'id' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission'> {
  request: PreTestRequest;
  kits: Pick<Kit, 'id' | 'dateCreated' | 'dateArrivedAtEugene' | 'recollectionRequestedDate'>[];
  member: Pick<Member, 'id' | 'name'>;
}

interface PreTestRequest extends Pick<Request, 'id' | 'product' | 'productVariant' | 'pregnancy' | 'gcApprovalDate' | 'datePaid' | 'initiatingMember' | 'notes'> {
  journeys: PreTestRequestJourney[];
}


const LIST_REQUESTS_QUERY = gql`
query ListRequests($input: ListRequestsInput!) {
  listRequests(input: $input) {
    edges {
      cursor
      node {
        id
        product
        productVariant
        datePaid
        initiatingMember {
          id
        }
        pregnancy {
          days
          dueDate
          dueDateConfirmed
        }
        gcApprovalDate # Updated from cache once a request is approved.
        notes {
          date
          text
          createdBy {
            email
          }
        }
        journeys {
          id
          state
          hasConsent
          hasRelevantFamilyHistoryGroupSubmission
          kits {
            id
            dateCreated
            dateArrivedAtEugene
          }
          member {
            id
            name
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

const LIST_REQUESTS_INPUT = {
  variables: {
    input: {
      paid: true,
      hasReport: false,
      isGcApproved: false,
    },
  },
};

type Category = 'READY_FOR_APPROVAL' | 'INCOMPLETE' | 'PARTNER_INCOMPLETE';

export const CATEGORY_LABELS: { [key in Category]: string } = {
  ['READY_FOR_APPROVAL']: 'Ready for approval',
  ['INCOMPLETE']: '⏱ Incomplete',
  ['PARTNER_INCOMPLETE']: '💔 Partner incomplete',
};

type GroupedRequests = { [key in Category]: PreTestRequest[] };

const categories: Category[] = ['READY_FOR_APPROVAL', 'PARTNER_INCOMPLETE', 'INCOMPLETE'];

const getCompletionBadge = (completionCount: number, productVariant: ProductVariant) => {
  const totalCount = isTwoPersonTest(productVariant) ? 2 : 1;
  return (
    <Badge color={completionCount >= totalCount ? 'green-light' : (completionCount > 0 ? 'orange-light' : 'red-light')}>
      {completionCount} / {totalCount}
    </Badge>
  );
};

const TasksApproveOrders = () => {
  const preTestQuery = useStatsQuery<RequestsConnection, QueryListRequestsArgs>(LIST_REQUESTS_QUERY, LIST_REQUESTS_INPUT, 'listRequests', 50);
  const dispatch = useDispatch();
  
  useEffect(() => {
    // 👇️ scroll to top on page load, but not while the stat query is loading
    if(!preTestQuery.data?.pageInfo?.hasNextPage) {
      setTimeout(() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) }, 50);
    }
  }, [preTestQuery]);

  const filteredRequests = React.useMemo(() => {
    return preTestQuery.data?.edges?.map(edge => edge.node).filter(request => {
      return request.journeys.find(journey => ![JourneyState.CloseLost, JourneyState.Trash].includes(journey.state))
    });
  }, [preTestQuery]);

  const groupedRequests: GroupedRequests = React.useMemo(() => {
    const groupedRequests: GroupedRequests = {
      'READY_FOR_APPROVAL': [],
      'PARTNER_INCOMPLETE': [],
      'INCOMPLETE': [],
    };

    filteredRequests?.forEach((request) => {
      const completionCount = request.journeys?.filter(journey => journey.hasConsent && journey.hasRelevantFamilyHistoryGroupSubmission).length;
      const isPartnerIncomplete = completionCount === 1 && isTwoPersonTest(request.productVariant);
      const isIncomplete = completionCount === 0;
      const isFullyComplete = !isPartnerIncomplete && !isIncomplete;

      if (isFullyComplete) {
        groupedRequests.READY_FOR_APPROVAL.push(request as PreTestRequest);
      }
      if (isPartnerIncomplete) {
        groupedRequests.PARTNER_INCOMPLETE.push(request as PreTestRequest);
      }
      else if (isIncomplete) {
        groupedRequests.INCOMPLETE.push(request as PreTestRequest);
      }
    });

    return groupedRequests;
  }, [filteredRequests]);

  const onRowSelect = React.useCallback((requestId: string) => {
    dispatch(navigate.toOrderApproval(requestId));
  }, [dispatch]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Approve Orders',
        isLoading: preTestQuery.data?.pageInfo?.hasNextPage,
      }}
    >
      {categories.map((category) => (
        <div className="mt-20 mb-20" key={category}>
          <h2 className="font-bold text-lg mb-10">{CATEGORY_LABELS[category]}</h2>
          <Table
            header={['Members', 'Product', 'FHx', 'Consent', 'Samples Received', 'Note', <div className="w-full" key="status">Status</div>]}
            isLoading={preTestQuery.isLoading}
            onRefresh={preTestQuery.refetch}
            onRowSelect={onRowSelect}
            rowGroups={groupedRequests[category].map(request => {
              const shortestWaitInDays = Number.MAX_SAFE_INTEGER;

              const shouldWarn = shortestWaitInDays !== Number.MAX_SAFE_INTEGER && shortestWaitInDays > 3;
              const recentlyApproved = !!request.gcApprovalDate; // When loading from apollo cache after an approval.
              const latestNote = [...request?.notes].sort((a, b) => b.date.localeCompare(a.date))[0];
              const kitArrivedDate = sortBy(flatten(request?.journeys?.map(journey => journey.kits)).filter(kit => !kit.recollectionRequestedDate), (kit) => {
                return -moment(kit.dateArrivedAtEugene).unix();
              })?.[0]?.dateArrivedAtEugene;

              return (
                {
                  id: request.id,
                  rows: [{
                    id: request.id,
                    cells: [
                      (<div className='w-80 whitespace-normal' key="members">
                        {request.journeys?.map(journey => (
                          <MemberOrPlaceholder member={journey.member}>
                            {(member) => (
                              <Badge className="mr-5" key={journey.id}>
                                <PrivateContent>
                                  {formatMemberName(member, request)}
                                </PrivateContent>
                              </Badge>
                            )}
                          </MemberOrPlaceholder>
                        ))}
                        {request.pregnancy && <PregnancyIcon pregnancy={request.pregnancy} />}
                      </div>),
                      getProductIcon(request.product, request.productVariant),
                      getCompletionBadge(request.journeys.filter(journey => journey.hasRelevantFamilyHistoryGroupSubmission).length, request.productVariant),
                      getCompletionBadge(request.journeys.filter(journey => journey.hasConsent).length, request.productVariant),
                      kitArrivedDate ? <DateBadge orangeDays={21} redDays={28} dateString={kitArrivedDate} key="arrival-date" /> : <Badge>No samples</Badge>,,
                      (<p className="block w-64 whitespace-normal" key="note">{latestNote ? <>{latestNote.text}<br/><span className='text-xs text-grey'>{latestNote.createdBy?.email || ""}</span></> : "-"}</p>),
                      (
                        <div className="w-64 whitespace-normal flex-wrap" key="badges">
                          {!recentlyApproved &&
                            <Badge className="text-sm">
                              Purchased {moment(request.datePaid).fromNow()}
                            </Badge>
                          }
                          {shouldWarn && !recentlyApproved && (
                            <Badge color="orange" textColor="white" className="text-sm inline-flex items-center ml-5">
                              Waiting {shortestWaitInDays && category === 'INCOMPLETE' ? ' for partner ' : ''}{shortestWaitInDays} days
                            </Badge>
                          )}
                          {recentlyApproved && (
                            <Badge color="green" textColor="white" className="text-sm inline-flex items-center ml-5">
                              Approved {moment(request.gcApprovalDate).fromNow()}
                            </Badge>
                          )}
                        </div>
                      )
                    ],
                  }],
                }
              );
            })}
          />
        </div>
      ))}
    </Screen>
  );
};

export default TasksApproveOrders;
