import * as React from 'react';
import Button from './Button';
import CounsellorSearchAutocomplete, { CounsellorPartial } from '../../graphql/containers/counsellors/CounsellorSearchAutocomplete';
import { FiCheckSquare, FiSquare } from './Icon';
import GeneAutocompleteTag from '../../graphql/containers/GeneAutocompleteTag';

export interface FilterOption {
  type: 'text' | 'boolean' | 'counsellorId' | 'gene';
  name: string;
  placeholder: string;
}

type Values = { [key: string]: any };

interface FilterFormProps {
  options: FilterOption[];
  onPress(values: Values): void;
}

const filterInputStyle = {
  height: '30px',
  fontSize: '12px',
};

interface FilterFormOptionProps {
  option: FilterOption;
  setValues(values: Values): void;
  values: Values;
  index: number;
}

const FilterFormOption = (props: FilterFormOptionProps) => {
  const [counsellorValue, setCounsellorValue] = React.useState(null as CounsellorPartial);
  const [geneValue, setGeneValue] = React.useState(null as string);

  const { option, setValues, values, index } = props;
  switch (option.type) {
    case 'text':
      return (
        <input
          key={option.name}
          placeholder={option.placeholder}
          className={`focus:outline-none bg-white px-10 border border-r-0 flex-1 ${index === 0 ? 'rounded-l' : ''}`}
          style={filterInputStyle}
          onChange={(e) => setValues({
            ...values,
            [option.name]: e.target.value,
          })}
        />
      );

    case 'boolean':
      const isSelected = values?.[option.name];
      return (
        <div className="px-5 py-3 border border-r-0 bg-white flex items-center">
          <button
            type="button"
            onClick={() => {
              setValues({
                ...values,
                [option.name]: isSelected ? undefined : true,
              });
            }}
            className={`flex items-center rounded px-5 py-3 focus:outline-none ${isSelected ? 'text-white bg-purple-mid' : 'text-grey'}`}
          >
            {isSelected ? <FiCheckSquare className="mr-5 mt-1" /> : <FiSquare className="mr-5 mt-1" />} {option.placeholder}
          </button>
        </div>
      );

    case 'counsellorId':
      return (
        <div className="flex-1">
          <CounsellorSearchAutocomplete
            onSelect={(selectedCounsellor) => {
              setValues({
                ...values,
                [option.name]: selectedCounsellor?.id,
              });
              setCounsellorValue(selectedCounsellor);
            }}
            defaultValue={counsellorValue}
            autocompleteSize="small"
          />
        </div>
      );

    case 'gene':
      return (
        <div className="flex-1">
          <GeneAutocompleteTag
            onSelectGenes={(genes) => {
              setValues({
                ...values,
                [option.name]: genes?.[0],
              });
              setGeneValue(genes?.[0]);
            }}
            defaultSelectedGenes={geneValue ? [geneValue] : []}
            autocompleteSize="small"
          />
        </div>
      );

    default:
      return (
        <div />
      );
  }
}

const FilterForm = (props: FilterFormProps) => {
  const [values, setValues] = React.useState({} as Values);

  const onFilter = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    props.onPress(values);
    e.preventDefault();
  }, [values, props.onPress]);

  return (
    <form
      className="flex flex-1 bg-grey-light rounded p-2 border items-stretch"
      onSubmit={onFilter}
      style={{ fontSize: '12px' }}
    >
      {props.options.map((option, index) => (
        <FilterFormOption
          option={option}
          setValues={setValues}
          values={values}
          index={index}
          key={option.name}
        />
      ))}
      <Button
        size="sm"
        variant="link"
        className="bg-white rounded-l-none"
        type="submit"
      >
        Filter
      </Button>
    </form>
  );
};

export default FilterForm;
