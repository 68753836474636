import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, CreateJourneyResult } from '../../../../graphql/genie-api-types';
import { MutationFunction } from 'react-apollo';
import JourneyForm, { JourneyFormValues } from '../../../components/journey/JourneyForm';
import gql from 'graphql-tag';

interface JourneyCreateProps {
  onCreate?(journey: CreateJourneyResult['journey']): void;
  onCancel?(): void;
  requestId: string;
}

const JourneyCreate = ({ onCreate, onCancel, requestId }: JourneyCreateProps) => (
  <GenieMutation
    mutation={gql`
      mutation CreateJourney($input: CreateJourneyInput!) {
        createJourney(input: $input) {
          journey {
            id
            member {
              id
              name
            }
            startDate
          }
        }
      }
    `}
  >
    {(mutate: MutationFunction<Mutation>, result) => (
      <JourneyForm
        initialValues={{}}
        isLoading={result.loading}
        errorMessage={result?.error?.message}
        onCancel={onCancel}
        onSubmit={async (values: JourneyFormValues) => {
          const mutationResult = await mutate({
            variables: {
              input: {
                requestId,
                memberId: values?.member?.id ?? null,
              },
            },
          });
          if (mutationResult) {
            onCreate(mutationResult.data.createJourney.journey);
          }
        }}
      />
    )}
  </GenieMutation>
);

export default JourneyCreate;
