import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import {CreateClinicInput} from "../../../../graphql/genie-api-types";
import ClinicForm from "../../../components/clinic/ClinicForm";

export interface ClinicCreateProps {
    onComplete(): void;
}

class ClinicCreate extends React.PureComponent<ClinicCreateProps> {
    onSaveClinic = async ({ ...restOfFormValues }: CreateClinicInput, mutate: MutationFunction) => {
        const { onComplete } = this.props;

        await mutate({
            variables: {
                input: {
                    ...restOfFormValues,
                },
            },
        });

        ReduxQueryListener.triggerEvents('All');
        showToast('success', 'Created clinic', 'Successfully saved clinic');

        onComplete();
    }

    render() {
        return (
            <GenieMutation
                mutation={gql`
          mutation CreateClinic($input: CreateClinicInput!) {
            createClinic(input: $input) {
              clinic {
                id
              }
            }
          }
        `}
            >
                {(mutate, result) => (
                    <ClinicForm
                        initialValues={{}}
                        onSubmit={(values: CreateClinicInput) => this.onSaveClinic(values, mutate)}
                        isLoading={result.loading}
                        isCreate
                        errorMessage={result?.error?.message}
                    />
                )}
            </GenieMutation>
        );
    }
}

export default ClinicCreate;
