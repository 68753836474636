import { Reducer } from 'redux';
import { set, omit } from 'lodash';
import { AnalysisState, AnalysisActionTypes } from './types';

const initialState: AnalysisState = {
  diseaseExpansion: {},
  fieldOverrides: {},
  modifications: {}
};

const reducer: Reducer<AnalysisState> = (state = initialState, action) => {
  switch (action.type) {
    case AnalysisActionTypes.TOGGLE_DISEASE_EXPAND:
      return {
        ...state,
        diseaseExpansion: {
          ...state.diseaseExpansion,
          [action.payload]: !state.diseaseExpansion[action.payload],
        },
      };

    case AnalysisActionTypes.EDIT_DISEASE_FIELD: {
      const { diseaseId, fieldName, value } = action.payload;

      const newOverrides = { ...state.fieldOverrides };
      set(newOverrides, `${diseaseId}.${fieldName}`, value);

      return {
        ...state,
        fieldOverrides: newOverrides,
      };
    }

    case AnalysisActionTypes.RESET_DISEASE_FIELD: {
      const { diseaseId, fieldName } = action.payload;

      const newOverrides = omit(state.fieldOverrides, `${diseaseId}.${fieldName}`);

      return {
        ...state,
        fieldOverrides: newOverrides,
      };
    }
    
    case AnalysisActionTypes.CHANGE_MODIFICATION: {
      const { modification, value, diseaseId } = action.payload;
      const existingModifications = state.modifications?.[diseaseId] ?? {};
      
      return {
        ...state,
        modifications: {
          ...state.modifications,
          [diseaseId]: {
            ...existingModifications,
            [modification]: value,
          },
        },
      };
    }

    default:
      return state;
  }
};

export { reducer as analysisReducer };
