import gql from 'graphql-tag';
import * as React from 'react';

import { Journey, JourneyLog, QueryListRequestsArgs, Request, RequestEdge, ResearchExtract } from '../../../graphql/genie-api-types';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { StatsProps } from './Stats';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { AcuityAppointmentPartial, getAllAcuityAppointments } from '../../../utilities/acuity';
import OpsEfficiencyAppointments from './ops-efficiency/OpsEfficiencyAppointments';
import OpsEfficiencyActiveJourneys from './ops-efficiency/OpsEfficiencyActiveJourneys';
import OpsEfficiencyTable from './ops-efficiency/OpsEfficiencyTable';

interface JourneyPartial extends Pick<Journey, 'id' | 'state'> {
  history?: Pick<JourneyLog, 'fromState' | 'toState' | 'date'>[];
}

interface RequestResult extends Pick<Request, 'id' | 'product'|'gcApprovalDate'> {
  journeys: JourneyPartial[];
  researchExtract?: Pick<ResearchExtract, 'id' | 'dateCreated'>;
}

interface RequestsListResult {
  edges: {
    cursor: RequestEdge['cursor'];
    node: RequestResult;
  }[];
}

export interface OpsEfficiencyStatsProps extends StatsProps {
  requests: RequestResult[];
  appointments: AcuityAppointmentPartial[];
}

export const WEEK_FORMAT = 'YYYYWW';

const OpsEfficiency = (props: StatsProps) => {
  const { fromDate, toDate } = props;
  const [requestResults, setRequestResults] = React.useState<RequestResult[]>([]);
  const [appointmentResults, setAppointmentResults] = React.useState<AcuityAppointmentPartial[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const loadData = React.useCallback(async () => {
    setIsLoading(true);
    const [requestResults, acuityResults] = await Promise.all([
      runStatsQuery<RequestsListResult, QueryListRequestsArgs>(gql`
        query ListRequests($input: ListRequestsInput!) {
          listRequests(input: $input) {
            edges {
              cursor
              node {
                id
                product
                gcApprovalDate
                journeys {
                  id
                  state
                  history {
                    fromState
                    toState
                    date
                  }
                }
                researchExtract {
                  id
                  dateCreated
                }
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `, {
        variables: {
          input: {
            paid: true,
          },
        },
      }, 'listRequests'),
      getAllAcuityAppointments(),
    ]);

    setRequestResults(requestResults.edges.map(edge => edge.node));
    setAppointmentResults(acuityResults);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      <OpsEfficiencyTable
        fromDate={fromDate}
        toDate={toDate}
        requests={requestResults}
      />
      <OpsEfficiencyActiveJourneys
        appointments={appointmentResults}
        fromDate={fromDate}
        toDate={toDate}
        requests={requestResults}
      />
      <OpsEfficiencyAppointments
        appointments={appointmentResults}
        fromDate={fromDate}
        toDate={toDate}
        requests={requestResults}
      />
    </div>
  );
};

export default OpsEfficiency