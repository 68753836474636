import * as React from 'react';
import { copyToClipboard } from '../../../utilities/helpers';
import { FiCopy } from './Icon';

interface CopyTextProps {
  text: string;
}

const CopyText = ({ text }: CopyTextProps) => {
  const onCopy = React.useCallback(() => {
    copyToClipboard(text);
  }, [text]);

  return (
    <div className="relative">
      {text}
      <FiCopy className="absolute top-0 right-0 m-3 hover:text-purple cursor-pointer z-10" onClick={onCopy} />
    </div>
  );
};

export default CopyText;
