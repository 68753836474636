import * as React from 'react';

import { Switch } from 'react-router';
import { PATHS } from '../store/router/types';
import Route from '../base/Route';
import JourneysHome from './clinical/JourneysHome';
import JourneysPre from './clinical/JourneysPre';
import JourneysDuring from './clinical/JourneysDuring';
import JourneysPost from './clinical/JourneysPost';
import JourneysArchived from './clinical/JourneysArchived';
import JourneysStats from './clinical/JourneysStats';
import AppointmentsProjection from './clinical/AppointmentsProjection';
import ClinicalResearchIndex from './clinical/research/ClinicalResearchIndex';
import ClinicalResearchExtractCreate from './clinical/research/ClinicalResearchExtractCreate';

const Journeys = () => {
  return (
    <Switch>
      <Route path={PATHS.CLINICAL} exact component={JourneysHome} />
      <Route path={PATHS.CLINICAL_PRE} exact component={JourneysPre} />
      <Route path={PATHS.CLINICAL_DURING} exact component={JourneysDuring} />
      <Route path={PATHS.CLINICAL_POST} exact component={JourneysPost} />
      <Route path={PATHS.CLINICAL_ARCHIVED} exact component={JourneysArchived} />
      <Route path={PATHS.CLINICAL_STATS} exact component={JourneysStats} />
      <Route path={PATHS.CLINICAL_PROJECTION} exact component={AppointmentsProjection} />
      <Route path={PATHS.CLINICAL_RESEARCH} exact component={ClinicalResearchIndex} />
      <Route path={PATHS.CLINICAL_RESEARCH_EXTRACT_CREATE} exact component={ClinicalResearchExtractCreate} />
    </Switch>
  );
};

export default Journeys;
