import Handlebars from 'handlebars';
import githubMarkdownSchema from 'hast-util-sanitize/lib/github';
import moment from 'moment';
import remark from 'remark';
import html from 'remark-html';

Handlebars.registerHelper('markdown', (markdown: string) =>
  remark()
    .use(html, { sanitize: githubMarkdownSchema })
    .processSync(markdown));

Handlebars.registerHelper('ifEquals', function (arg1: any, arg2: any, options: any) {
  return (arg1 === arg2) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('formatDate', (date: string, formatType: string) => {
  switch (formatType) {
    case 'short':
      return moment(date).format('Do MMM, YYYY');

    case 'full':
      return moment(date).format('Do MMMM, YYYY');

    default:
      return date;
  }
});

Handlebars.registerHelper('andList', (listItems: string[]) => {
  if (!listItems) {
    return '';
  }

  let output = listItems[0];

  for (let i = 1; i < listItems.length; i += 1) {
    const separator = i === listItems.length - 1 ? ' and ' : ', ';
    output += `${separator} ${listItems[i]}`;
  }

  return output;
});

Handlebars.registerHelper('coloredList', (diseaseNames: string[], color: 'orange' | 'dk-green' | 'pink' | 'grey') => {
  const renderDiseaseNameSpan = (diseaseName: string) => {
    return `<span class="${color} md">${diseaseName}</span>`;
  }

  if (!diseaseNames || !diseaseNames.length) {
    return;
  }

  let coloredList = renderDiseaseNameSpan(diseaseNames[0]);

  for (let i = 1; i < diseaseNames.length; i++) {
    let separator = i === diseaseNames.length - 1 ? ' and ' : ', ';
    coloredList += separator + renderDiseaseNameSpan(diseaseNames[i]);
  }

  return coloredList;
});

Handlebars.registerHelper('contains', function(string: string, substring: string, options: any) {
  return string.includes(substring) ? options.fn(this) : options.inverse(this);
});

export default Handlebars;
