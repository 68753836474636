import * as React from 'react';
import { FormInputOption } from './form/FormInput';
import { FiEdit, FiSave, FiX } from './Icon';
import LoadingOverlay from './LoadingOverlay';

interface EditableFieldProps {
  initialValue?: string;
  type: 'text' | 'number';
  as?: 'input' | 'select';
  options?: FormInputOption[];
  onSubmit(value: string): Promise<void>;
  placeholder?: string;
}

const EditableField = (props: EditableFieldProps) => {
  const { initialValue, onSubmit, placeholder } = props;
  const [value, setValue] = React.useState(props.initialValue);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);
  
  const onToggleEditMode = React.useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode, setIsEditMode]);
  
  const onCancel = React.useCallback(() => {
    setValue(initialValue);
    setIsEditMode(false);
  }, [setIsEditMode, setValue, initialValue]);
  
  const onSave = React.useCallback(async () => {
    setIsSaving(true);
    await onSubmit(value);
    setIsSaving(false);
    setIsEditMode(false);
  }, [onSubmit, value]);
  
  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, [setValue]);

  switch (props.as) {

  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="relative"
    >
      {isSaving && <LoadingOverlay />}
      {isEditMode ? (
        <input
          type="text"
          value={value}
          className="w-full p-0 focus:outline-none bg-orange-light"
          style={{ height: 'auto' }}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : (
        <>{value || placeholder}</>
      )}
      {isHovering && !isEditMode && (
        <button type="button" onClick={onToggleEditMode}  className="absolute right-0 top-0 bg-white text-purple">
          <FiEdit />
        </button>
      )}
      {isEditMode && (
        <div className="absolute right-0 top-0 bg-white bottom-0 bg-white z-1">
          <button type="button" className="text-red focus:outline-none" onClick={onCancel}>
            <FiX size={12} />
          </button>
          <button type="button" className="text-purple ml-5 focus:outline-none">
            <FiSave onClick={onSave} />
          </button>
        </div>
      )}
    </div>
  );
};

EditableField.defaultProps = {
  as: 'input',
};

export default EditableField;
