import * as React from 'react';
import DropdownButton, { Action } from '../widgets/DropdownButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ResearchSession } from '../../store/research/types';
import { FiChevronLeft, FiChevronRight } from '../widgets/Icon';
import { navigate } from '../../store/router/actions';
import { ResearchExtract } from '../../../graphql/genie-api-types';
import ResearchStatusBadge from './ResearchStatusBadge';

interface ResearchSessionHeaderProps {
  requestId: string;
  onActionClick(action: ActionNames, actionValue: string): void;
  researchExtract: Pick<ResearchExtract, 'id'|'dateCreated'|'dateSubmitted'>;
}

export type ActionNames = 'Save' | 'Submit';

const saveActions: Action<ActionNames>[] = [
  {
    label: 'Save and Submit',
    name: 'Submit',
    variant: 'primary',
  },
  {
    label: 'Save for later',
    name: 'Save',
    variant: 'success',
  }
];

const ResearchSessionHeader = (props: ResearchSessionHeaderProps) => {
  const dispatch = useDispatch();
  const researchSession = useSelector<ApplicationState, ResearchSession>(state => state.research.session);
  const requestIds = researchSession?.requestIds ?? [];
  const positionInResearchSession = requestIds.indexOf(props.requestId);
  const isRequestInResearchSession = positionInResearchSession > -1;
  const progress = (positionInResearchSession + 1) / requestIds.length;
  
  const progressStyle = React.useMemo(() => {
    return {
      width: `${progress * 100}%`,
    };
  }, [progress]);
  
  const onBack = React.useCallback(() => {
    if (!isRequestInResearchSession || positionInResearchSession === 0) {
      return;
    }

    const previousRequestId = requestIds[positionInResearchSession - 1];

    dispatch(navigate.toClinicalResearchExtractCreate(previousRequestId));
  }, [positionInResearchSession, requestIds ?? [], isRequestInResearchSession]);
  
  const onForward = React.useCallback(() => {
    if (!isRequestInResearchSession || positionInResearchSession === requestIds.length -1) {
      return;
    }
    const nextRequestId = requestIds[positionInResearchSession + 1];
    dispatch(navigate.toClinicalResearchExtractCreate(nextRequestId));
  }, [positionInResearchSession, requestIds ?? [], isRequestInResearchSession]);
  
  return (
    <div className="w-full bg-white border rounded p-10 py-10 mb-10 relative flex items-center">
      <div className="absolute left-0 top-0 bottom-0 bg-purple-lighter" style={progressStyle} />
      {isRequestInResearchSession && (
        <button onClick={onBack} className="relative z-1 pr-10 focus:outline-none">
          <FiChevronLeft />
        </button>
      )}
      <div className="z-1 relative flex justify-between items-center flex-1">
        
        <div>
          {isRequestInResearchSession ? (
            <div>
              <strong>Research extract session: </strong>
              {positionInResearchSession + 1} / {researchSession?.requestIds?.length} extracts completed.
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="flex items-center">
          <ResearchStatusBadge researchExtract={props.researchExtract} />
          <DropdownButton<ActionNames>
            actions={saveActions}
            onActionClick={props.onActionClick}
            className="ml-10"
          />
        </div>
      </div>
      {isRequestInResearchSession && (
        <button onClick={onForward} className="relative z-1 pl-10 focus:outline-none">
          <FiChevronRight />
        </button>
      )}
    </div>
  );
}

export default ResearchSessionHeader;
