import { Journey } from '../../graphql/genie-api-types';
import { Action } from '../../app/components/widgets/DropdownButton';
import { JOURNEY_STATE_ACTIONS } from './states';

export const getActionsForJourney = (journey: Partial<Journey>, getInitialActions: (journey: Partial<Journey>) => Action[]): Action[] => {
  const actions = getInitialActions ? getInitialActions(journey) : [];

  if (JOURNEY_STATE_ACTIONS[journey.state]) {
    const stateActions = JOURNEY_STATE_ACTIONS[journey.state];

    stateActions.forEach(journeyStateAction => {
      actions.push({
        label: journeyStateAction.label,
        name: journeyStateAction.type,
        value: journeyStateAction.value,
        variant: 'success',
      });
    });
  }

  actions.push({
    label: 'Edit request',
    name: '_edit',
    variant: 'success',
    extraProps: { noBatch: true },
  });

  actions.push({
    label: 'Update journey',
    name: '_updateJourney',
    variant: 'success',
  });

  actions.push({
    label: 'Change on-hold status',
    name: '_onHold',
    variant: 'success',
  });

  actions.push({
    label: 'Move to...',
    name: '_move',
    variant: 'success',
  });

  return actions;
};
