import * as React from 'react';
import Tooltip from './Tooltip';

interface GeneticJokesProps {
  className?: string;
}

interface Joke {
  question: string;
  answer: string;
}

const jokes: Joke[] = [
  {
    question: 'Why are tertiary structures selfish?',
    answer: 'Because the amino acids are all wrapped up in themselves.',
  },
  {
    question: 'What did the snooty metacentric say to the telocentric?',
    answer: 'Two arms are better than one.',
  },
  {
    question: 'What has four poles, two plates, a bunch of squiggley stuff and is purple?',
    answer: 'Meiotic grapes.',
  },
  {
    question: 'What do you call a Drosophila who likes to drink?',
    answer: 'A bar fly.',
  },
  {
    question: 'How can you better understand genetics in cold weather?',
    answer: 'Put your codon!',
  },
  {
    question: 'Do you know why the geneticist went to dental school?',
    answer: 'He was looking for an oral high gene.',
  },
  {
    question: 'Why did the polyploid flower always get its own way?',
    answer: 'Because the diploid flower knew it was out numbered.',
  },
  {
    question: 'How many zygotes does it take to screw in a light bulb?',
    answer: '2 n\' 4 poles',
  },
  {
    question: 'What do you call a genetically engineered cow?',
    answer: 'A Mootant',
  },
  {
    question: 'Where in the fridge do you store your genetic experiments?',
    answer: 'In the CRISPR drawer.',
  },
  {
    question: 'What type of food does Rachel\'s mum keep a stockpile of?',
    answer: 'Butter! 🧈',
  },
  {
    question: 'What was the sport that Ben\'s team went undefeated in between 1992 and 1993?',
    answer: 'Cricket! ( Neerim Blue Under 13s) 🏏',
  },
  {
    question: 'What\'s Ellie\'s random super power?',
    answer: 'She can lick her nose. 👃',
  },
  {
    question: 'What\'s Ellie\'s favourite colour?',
    answer: 'Red 🔴',
  },
  {
    question: 'What\'s Ellie\'s favourite food?',
    answer: 'Matzah ball chicken soup soup 🥣',
  },
  {
    question: 'What type of Captain was Zoe in high school?',
    answer: 'Sports ⛹️‍♀️',
  },
  {
    question: 'What is Zoe\'s favourite food?',
    answer: 'Chocolate 🍫',
  },
  {
    question: 'What is Zoe\'s favourite colour?',
    answer: 'Green 🟢',
  },
  {
    question: 'What food would Kunal happily eat out of an Icecream cone?',
    answer: 'Hummus 🍦',
  },
  {
    question: 'What type of "gazing" is Kunal most known for?',
    answer: 'Eye-gazing! 👁',
  },
  {
    question: 'Which month did Eugene start being called Eugene?',
    answer: 'October 2016',
  },
  {
    question: 'Which month did Kate join Eugene?',
    answer: 'March 2018',
  },
  {
    question: 'Which month was Eugene\'s first test sold?',
    answer: 'October 2018',
  },
  {
    question: 'Which month did Emma join Eugene?',
    answer: 'October 2018',
  },
  {
    question: 'Which month did Marty join Eugene?',
    answer: 'February 2019',
  },
  {
    question: 'Which month was Eugene incorporated?',
    answer: 'December 2017',
  },
  {
    question: 'What kind of Skating does Kate Enjoy (and what kind of skating can\'t she do?)',
    answer: 'Rollerskating (not ice-skating 🙅‍♀️⛸)',
  },
  {
    question: 'What is Kate\'s favourite food?',
    answer: 'A good steak with chips and gravy 🥩🍟',
  },
  {
    question: 'What is Kate\'s favourite colour?',
    answer: 'Purple 🟣',
  },
  {
    question: 'What material did Emma\'s parents dress her in when she was a child that scarred her forever?',
    answer: 'Velvet',
  },
  {
    question: 'What is Emma\'s favourite colour?',
    answer: 'Dusty Pink 👚',
  },
  {
    question: 'What is Emma\'s favourite food',
    answer: 'Ice cream 🍦',
  },
  {
    question: 'What is Marty\'s favourite colour?',
    answer: 'Green 🌲',
  },
  {
    question: 'Where was Marty born?',
    answer: 'Austria! 🇦🇹'
  },
  {
    question: 'What\'s the name the startup Marty founded?',
    answer: 'Aircamp ⛺️ (camping don\'t pay the bills yo)',
  },
  {
    question: 'What is Marty\'s favourite food?',
    answer: 'Pizza 🍕 (I\'m aware of the developer cliche 😂)',
  },
  {
    question: 'What is Steph\'s favourite colour?',
    answer: 'Red or Pink - 🔴🌸 (so I guess fuscia?)',
  },
  {
    question: 'What is Steph\'s favourite food?',
    answer: 'Something Asian, bahn mi or dumplings 🥟',
  },
  {
    question: 'What shape is the birthmark on Steph\'s left knee?',
    answer: 'Butterfly! 🦋'
  },
];

const GeneticJokes = (props: GeneticJokesProps) => {
  const joke = jokes[Math.floor(Math.random() * jokes.length)];
  
  return (
    <div className={`cursor-pointer text-purple text-center opacity-75 hover:opacity-100 ${props.className ?? ''}`}>
      <Tooltip label={joke.answer} appearance="light" placement="bottom">
        {joke.question}
      </Tooltip>
    </div>
  );
};

export default React.memo(GeneticJokes);
