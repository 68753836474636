import gql from 'graphql-tag';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import { Lead, LeadStatus, PageInfo, QueryListLeadsArgs } from '../../../graphql/genie-api-types';
import { getProductLabel } from '../../../utilities/journeys/products';
import Badge, { BadgeProps } from '../../components/widgets/Badge';
import GeneticJokes from '../../components/widgets/GeneticJokes';
import PrivateContent from '../../components/widgets/PrivateContent';
import Screen from '../../components/widgets/Screen';
import Table from '../../components/widgets/Table';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber, getZendeskSearchUrl } from '../../../utilities/helpers';
import SalesStats from '../../graphql/containers/ops/SalesStats';
import { Action } from '../../components/widgets/DropdownButton';
import { navigate } from '../../store/router/actions';
import { SALES_LEAD_FRAGMENT } from '../../../graphql/fragments/leads';
import useStatsQuery from '../../../utilities/useStatsQuery';
import TabsNav from '../../components/widgets/TabsNav';
import { TabOption } from '../../components/widgets/Tabs';

interface LeadPartial extends Pick<Lead, 'id' | 'name' | 'email' | 'phone' | 'product' | 'status' | 'doNotContact' | 'dateLastContact' | 'dateCreated' | 'additionalData'> {

}

interface ListLeadsQueryData {
  edges?: {
    cursor: string;
    node: LeadPartial;
  }[];
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>;
}

const LIST_LEADS_QUERY = gql`
  query ListLeads($input: ListLeadsInput!) {
    listLeads(input: $input) {
      edges {
        cursor
        node {
          ...LeadParts
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${SALES_LEAD_FRAGMENT}
`;

type ActionName = 'close' | 'find-in-zendesk';

const queryVars: QueryListLeadsArgs = {
  input: {
    status: LeadStatus.FollowUp,
  },
};

const actions: Action<ActionName>[] = [
  {
    label: 'Find in Zendesk',
    name: 'find-in-zendesk',
    variant: 'primary',
    extraProps: {
      noBatch: true,
    },
  },
  {
    label: 'Close',
    name: 'close',
    variant: 'primary',
  },
];

type TabName = 'all' | 'referrals' | 'direct';

const tabs: TabOption<TabName>[] = [
  {
    name: 'all',
    label: 'All',
  },
  {
    name: 'referrals',
    label: 'Dr Referrals',
  },
  {
    name: 'direct',
    label: 'Direct',
  },
];

const TasksSalesLeads = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = React.useState<TabName>('all');

  const leadsQuery = useStatsQuery<ListLeadsQueryData, QueryListLeadsArgs>(LIST_LEADS_QUERY, {
    variables: queryVars,
  }, 'listLeads', 100);

  const leads = React.useMemo(() => {
    const leadEdges = leadsQuery?.data?.edges?.map(edge => edge.node)
    return sortBy(leadEdges, (lead) => {
      return -moment(lead.dateLastContact).unix();
    }).reverse() as LeadPartial[];
  }, [leadsQuery.data]);

  const onActionClick = React.useCallback(async (leadIds: string[], actionName: ActionName) => {
    switch (actionName) {
      case 'close':
        dispatch(navigate.toForm('LeadsClose', { leadIds }));
        break;

      case 'find-in-zendesk':
        const lead = leads.find(lead => lead.id === leadIds[0]);
        const zendeskUrl = getZendeskSearchUrl(lead?.email || lead?.name);
        window.open(zendeskUrl);
        break;
    }
  }, [dispatch, leads]);

  const rowGroups = React.useMemo(() => {
    return leads.filter(lead => {
      const additionalData = lead.additionalData ? JSON.parse(lead.additionalData) : null;
      const isReferral = additionalData && !!additionalData?.doctors_name; // eslint-disable-line @typescript-eslint/camelcase

      switch (tab) {
        case 'direct':
          return !isReferral;
        case 'referrals':
          return isReferral;
        default:
          return true;
      }

    }).map(lead => {

      let badgeColor: BadgeProps['color'] = 'orange-light';
      if (moment().diff(lead.dateLastContact, 'days') < 2) {
        badgeColor = 'green-lightest';
      }

      const phoneNumber = formatPhoneNumber(lead.phone ?? '');

      const leadData = JSON.parse(lead.additionalData ?? '{}');
      // console.log(leadData);

      return {
        id: `group-${lead.id}`,
        rows: [
          {
            id: lead.id,
            cells: [
              (
                <Badge className="mr-5" key="name"><PrivateContent>{lead.name}</PrivateContent></Badge>
              ),
              getProductLabel(lead.product),
              <PrivateContent key="phone">{phoneNumber}</PrivateContent>,
              leadData?.doctors_name ?? '-',  // eslint-disable-line @typescript-eslint/camelcase
              lead.dateCreated ? moment(lead.dateCreated).fromNow() : '',
              <Badge color={badgeColor} key="last-contact">{lead.dateLastContact ? moment(lead.dateLastContact).fromNow() : 'Not contacted'}</Badge>,
            ],
            actions,
          }
        ],
      };
    });
  }, [leads, tab]);

  const onSelectLead = React.useCallback((leadId: Lead['id']) => {
    dispatch(navigate.toForm('LeadInfoAndClose', { leadId: leadId }));
  }, [dispatch, leads]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Sales leads',
        action: <GeneticJokes />,
      }}
    >
      <div>
        <SalesStats />
        <TabsNav
          tabs={tabs}
          activeTab={tab}
          onSelectTab={(tabName: TabName) => setTab(tabName)}
        />
        <Table
          header={['Name', 'Product', 'Phone', 'Doctor', 'Requested', 'Last contacted']}
          rowGroups={rowGroups}
          onRefresh={leadsQuery.refetch}
          isLoading={leadsQuery.loading}
          onRowSelect={onSelectLead}
          hasBatchSelect
          onActionClick={onActionClick}
        />
      </div>
    </Screen>
  );
}

export default TasksSalesLeads;
