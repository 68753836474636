import * as React from 'react';
import FormInput from '../widgets/form/FormInput';
import { Recommendation } from '../../../graphql/genie-api-types';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { getRecommendationOptionsForAction } from '../../../utilities/recommendations/labels';

export type RecommendationFormValues = Pick<Recommendation, 'action' | 'reason' | 'result'>;

interface RecommendationFormProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  initialValues: RecommendationFormValues;
}

const RecommendationForm = (props: RecommendationFormProps) => {
  const { initialValues, isLoading, onCancel, errorMessage, onSubmit } = props;

  const option = getRecommendationOptionsForAction(props.initialValues.action);

  return (
    <BaseForm
      initialValues={initialValues}
      submitButtonLabel="Save"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
          <div>
            <h2 className="text-lg font-bold mb-10">{option.question}</h2>
            <FormInput
              label=""
              name="result"
              key="result"
              as="select"
              onChange={handleChange}
              setFieldValue={setFieldValue}
              value={values['result']}
              options={option.answers}
            />
            <FormInput
              label="Reason"
              name="reason"
              key="reason"
              as="textarea"
              onChange={handleChange}
              setFieldValue={setFieldValue}
              value={values['reason']}
            />
          </div>
        )}
    </BaseForm>
  );
}

export default RecommendationForm;
