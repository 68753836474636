import { ClientId } from '../client-details/types';

export enum CarrierRatesActionTypes {
  CHANGE_CARRIER_RATE_ETHNICITY = '@@report/carrier-rates/CHANGE_CARRIER_RATE_ETHNICITY',
}

export type RateType = 'carrier_rate' | 'residual_risk';

export type SelectedClientEthnicities = { [key in ClientId]?: string };

export interface CarrierRatesState {
  selectedCarrierRateEthnicitiesByGene: { [key: string]: SelectedClientEthnicities };
}
