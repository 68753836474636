import * as React from 'react';
import moment from 'moment';
import { Kit } from '../../../graphql/genie-api-types';
import Badge from '../widgets/Badge';
import Tooltip from '../widgets/Tooltip';
import { FiRepeat } from '../widgets/Icon';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';

interface KitTagProps {
  kit: Pick<Kit, 'id'|'recollectionRequestedDate'|'registrationNumber'>;
}

const KitTag = (props: KitTagProps) => {
  const { kit } = props;
  const dispatch = useDispatch();

  const onClickKit = React.useCallback(() => {
    dispatch(navigate.toForm('KitsUpdate', { kitIds: [kit.id] }));
  }, [kit]);
  
  const onClickRecollect = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(navigate.toForm('RecollectKit', { kitId: kit.id }));
  }, [kit]);

  return (
    <Tooltip label={kit.recollectionRequestedDate ? `Recollected ${moment(kit.recollectionRequestedDate).format('D MMM Y')}` : ''}>
      <Badge
        key={kit.id}
        className="mr-5 mb-5 group align-middle cursor-pointer hover:bg-purple-300"
        color={kit.recollectionRequestedDate ? 'pink-light' : 'purple-100'}
        onClick={onClickKit}
      >
        {kit.registrationNumber ?? 'not registered'}
        {kit.recollectionRequestedDate ? null : (
          <Tooltip label="Recollect this kit">
            <button
              onClick={onClickRecollect}
              type="button"
            >
              <span className="ml-5 bg-pink-light font-bold rounded px-2 hover:bg-pink hover:text-white"><FiRepeat /></span>
            </button>
          </Tooltip>
        )}
      </Badge>
    </Tooltip>
  );
}

export default KitTag;
