import * as React from "react";
import Table from "../../../widgets/Table";

interface TableProps {
  tableHeader: string[];
  tableColumn: any;
  tableData: any;
}

const ProjectionTable = (props: TableProps) => {
  const { tableHeader, tableColumn, tableData } = props;

  return (
    <Table
      header={tableHeader}
      columnGroups={tableColumn}
      rowGroups={tableData}
      emptyText={"N/A"}
    />
  );
};

export default ProjectionTable;
