import { Reducer } from 'redux';
import { TestInformationState, TestInformationActionTypes } from './types';
import { Product, ProductVariant } from '../../../../graphql/genie-api-types';

export const initialState: TestInformationState = {
  product: Product.Carrier,
  productVariant: ProductVariant.Couple,
  numberOfGenesTested: '267',
  numberOfXLinkedGenesTested: '21',
  referringDoctorClinic: '',
  reviewedBy: 'Prof. Ingrid Winship',
  eugeneCareTeam: 'Zoë Milgrom & Prof. David Amor',
};

const reducer: Reducer<TestInformationState> = (state = initialState, action) => {
  switch (action.type) {
    case TestInformationActionTypes.CHANGE_FIELDS:
      const fields: { fields: TestInformationState } = action.payload;

      return {
        ...state,
        ...fields,
      };
    default:
      return state;
  }
};

export { reducer as testInformationReducer };
