import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { QueryListJourneysArgs, JourneysConnection, Journey } from '../../../graphql/genie-api-types';
import { percentage } from '../../../utilities/helpers';
import useStatsQuery from '../../../utilities/useStatsQuery';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { StatsProps } from './Stats';

const REQUESTS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          kits {
            id
            memberRegisteredKitOnDate
          }
          member {
            id
            dateDetailsConfirmedByMember
            onlineAccessEnabled
            familyHistoryGroup {
              dateCreated
              source
            }
          }
          consents {
            id
            dateCreated
            dateWithdrawn
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const PreTestStats = (props: StatsProps) => {
  const statsQuery = useStatsQuery<JourneysConnection, QueryListJourneysArgs>(REQUESTS_QUERY, {
    variables: {
      input: {
        paid: true,
        excludeOnHold: true,
      }
    }
  }, 'listJourneys', 200);

  const journeyNodes: Journey[] = statsQuery?.data?.edges?.map(edge => edge.node) ?? [];

  const statWhereMemberFlowWasTriggeredByAKit = React.useMemo(() => {
    let loggedInOnKitRegDayCount = 0;
    let consentOnKitRegDayCount = 0;
    let fhxOnKitRegDayCount = 0;
    let bothOnKitRegDayCount = 0;
    let totalCount = 0;
    journeyNodes.forEach(journey => {
      const firstKitReg = journey?.kits?.[0]?.memberRegisteredKitOnDate;

      if (!firstKitReg || !moment(firstKitReg).isBetween(props.fromDate, props.toDate)) {
        return;
      }

      totalCount += 1;

      const kitRegDay = moment(firstKitReg).startOf('day');
      const consentDate = journey.consents?.[0]?.dateCreated;
      const familyHistoryDate = journey?.member?.familyHistoryGroup?.[0]?.dateCreated;
      const loginDate = journey?.member?.dateDetailsConfirmedByMember;

      const consentedOnKitRegDay = consentDate && moment(consentDate).startOf('day').isSame(kitRegDay);
      const fhxOnKitRegDay = familyHistoryDate && moment(familyHistoryDate).startOf('day').isSame(kitRegDay);
      const loginOnKitRegDay = loginDate && moment(loginDate).startOf('day').isSame(kitRegDay);

      if (loginOnKitRegDay) {
        loggedInOnKitRegDayCount += 1;
      }
      else if (consentedOnKitRegDay && fhxOnKitRegDay) {
        bothOnKitRegDayCount += 1;
      }
      else if (consentedOnKitRegDay) {
        consentOnKitRegDayCount += 1;
      }
      else if (fhxOnKitRegDay) {
        fhxOnKitRegDayCount += 1;
      }
    });

    return {
      loggedInOnKitRegDayCount,
      consentOnKitRegDayCount,
      fhxOnKitRegDayCount,
      bothOnKitRegDayCount,
      totalCount
    };
  }, [journeyNodes, props.fromDate, props.toDate]);

  return (
    <div className="relative">
      {statsQuery.isLoading && <LoadingOverlay label="Crunching numbers (takes a few minutes...)" />}
      <table className="w-full">
        <tr>
          <td className="font-bold py-10">
            Members who first accessed member flow on the day their kits arrived
          </td>
          <td className="text-right">
            {statWhereMemberFlowWasTriggeredByAKit.loggedInOnKitRegDayCount} ({percentage(statWhereMemberFlowWasTriggeredByAKit.loggedInOnKitRegDayCount / statWhereMemberFlowWasTriggeredByAKit.totalCount)})
          </td>
        </tr>
        <tr>
          <td className="font-bold py-10">
            Members who had accessed member flow before, <br />who completed a member flow step on the day their kits arrived
          </td>
          <td className="text-right">
            {statWhereMemberFlowWasTriggeredByAKit.bothOnKitRegDayCount + statWhereMemberFlowWasTriggeredByAKit.consentOnKitRegDayCount + statWhereMemberFlowWasTriggeredByAKit.fhxOnKitRegDayCount} ({percentage((statWhereMemberFlowWasTriggeredByAKit.bothOnKitRegDayCount + statWhereMemberFlowWasTriggeredByAKit.consentOnKitRegDayCount + statWhereMemberFlowWasTriggeredByAKit.fhxOnKitRegDayCount) / statWhereMemberFlowWasTriggeredByAKit.totalCount)})
          </td>
        </tr>
        <tr>
          <td className="pl-10 py-10">
            - Completed both consent and family history on the day their kits arrived
          </td>
          <td className="text-right">
            {statWhereMemberFlowWasTriggeredByAKit.bothOnKitRegDayCount} ({percentage(statWhereMemberFlowWasTriggeredByAKit.bothOnKitRegDayCount / statWhereMemberFlowWasTriggeredByAKit.totalCount)})
          </td>
        </tr>
        <tr>
          <td className="pl-10 py-10">
          - Completed just consent on the day their kits arrived
          </td>
          <td className="text-right">
            {statWhereMemberFlowWasTriggeredByAKit.consentOnKitRegDayCount} ({percentage(statWhereMemberFlowWasTriggeredByAKit.consentOnKitRegDayCount / statWhereMemberFlowWasTriggeredByAKit.totalCount)})
          </td>
        </tr>
        <tr>
          <td className="pl-10 py-10">
          - Completed just family history on the day their kits arrived
          </td>
          <td className="text-right">
            {statWhereMemberFlowWasTriggeredByAKit.fhxOnKitRegDayCount} ({percentage(statWhereMemberFlowWasTriggeredByAKit.fhxOnKitRegDayCount / statWhereMemberFlowWasTriggeredByAKit.totalCount)})
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PreTestStats;
