import * as React from 'react';
import { FiChevronDown, FiChevronRight } from '../../widgets/Icon';
import './styles/disease-risk-row.scss';
import { Disease } from '../../../../graphql/genie-api-types';

export interface DiseaseRiskRowProps {
  disease: Disease;
  clientACarrierLabel: string;
  clientBCarrierLabel: string;
  risk: string;
  isExpanded: boolean;
  onClick(): void;
  expansionView?: React.ReactNode;
}

const getExpandedIcon = (isExpanded: boolean) => {
  const IconComponent = isExpanded ? FiChevronDown : FiChevronRight;

  return <IconComponent size={15} />;
};

class DiseaseRiskRow extends React.PureComponent<DiseaseRiskRowProps> {
  onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    // If enter button is pressed.
    if (e.keyCode === 13) {
      this.props.onClick();
    }
  }

  render() {
    const props = this.props;

    return (
      <tbody>
        <tr onClick={props.onClick} onKeyDown={this.onKeyDown} className="disease-risk-row" tabIndex={0}>
          <td className="p-10 border-t-2">{getExpandedIcon(props.isExpanded)} <strong>{props.disease.name}</strong></td>
          <td className="p-10 border-t">{props.risk}</td>
          <td className="p-10 border-t">{props.clientACarrierLabel}</td>
          <td className="p-10 border-t">{props.clientBCarrierLabel}</td>
        </tr>
        {props.isExpanded && props.expansionView ? (
          <tr>
            <td colSpan={4} className="disease-risk-row__expansion-column p-10">
              {props.expansionView}
            </td>
          </tr>
        ) : null}
      </tbody>
    );
  }
}
export default DiseaseRiskRow;
