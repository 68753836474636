import * as React from 'react';


interface LineProps {
  style: 'solid' | 'dotted';
}

interface ExtendingLineProps extends LineProps {
  connectLeft?: string;
  connectRight?: string;
}

interface PedigreeBoxProps {
  children: React.ReactNode;
  topLine?: LineProps;
  leftLine?: LineProps;
  rightLine?: LineProps;
  bottomLine?: ExtendingLineProps;
  className?: string;
  width?: '1/3' | '2/3' | 'full';
  backgroundColorClass?: 'purple-100' | 'orange-light';
}

const borderStyle: React.CSSProperties = {
  position: 'absolute',
  borderColor: '#B0B4F6',
};

const boxPadding = 20;
const borderWidth = '2px';

const PedigreeBox = (props: PedigreeBoxProps) => (
  <div className={`p-${boxPadding} w-${props.width} relative flex flex-col`}>
    <div className={`border border-purple-light rounded bg-${props.backgroundColorClass} p-15 ${props.className || ''} flex-1`}>
      {props.children}
    </div>
    {props.topLine ? (
      <div style={{ ...borderStyle, borderLeftWidth: borderWidth, borderStyle: props.topLine.style, height: `${boxPadding}px`, top: '0px', left: '50%', }} />
    ) : null}
    {props.rightLine ? (
      <div style={{ ...borderStyle, borderTopWidth: borderWidth, borderStyle: props.rightLine.style, top: '50%', width: `${boxPadding}px`, right: 0 }} />
    ) : null}
    {props.leftLine ? (
      <div style={{ ...borderStyle, borderTopWidth: borderWidth, borderStyle: props.leftLine.style, top: '50%', width: `${boxPadding}px`, left: 0, right: `-${boxPadding}px` }} />
    ) : null}
    {props.bottomLine ? (
      <>
        <div style={{ ...borderStyle, borderLeftWidth: borderWidth, borderStyle: props.bottomLine.style, height: `${boxPadding}px`, left: '50%', bottom: '0' }} />
        {props.bottomLine.connectLeft ? (
          <div style={{ ...borderStyle, borderTopWidth: borderWidth, borderStyle: props.bottomLine.style, bottom: 0, left: `calc(${props.bottomLine.connectLeft})`, right: '50%' }} />
        ) : null}
        {props.bottomLine.connectRight ? (
          <div style={{ ...borderStyle, borderTopWidth: borderWidth, borderStyle: props.bottomLine.style, bottom: 0, right: `calc(${props.bottomLine.connectRight} - ${borderWidth})`, left: '50%' }} />
        ) : null}
      </>
    ) : null}
  </div>
);

PedigreeBox.defaultProps = {
  width: '1/3',
  backgroundColorClass: 'purple-100',
};

export default React.memo(PedigreeBox);
