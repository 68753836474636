import * as React from 'react';
import { FlagPriority } from '../../../graphql/genie-api-types';
import { FLAG_PRIORITY_COLORS, FLAG_PRIORITY_LABELS } from '../../../utilities/flags/flagLabels';
import { FiFlag } from '../widgets/Icon';

interface FlagPriorityLabelProps {
  flagPriority: FlagPriority;
}

const FlagPriorityLabel = ({ flagPriority }: FlagPriorityLabelProps) => {
  const color = FLAG_PRIORITY_COLORS[flagPriority];
  const label = FLAG_PRIORITY_LABELS[flagPriority];

  return (
    <div className={`text-${color} text-xs font-bold flex items-center`}>
      <FiFlag /> <span className="ml-5">{label ?? flagPriority}</span>
    </div>
  );
};

export default React.memo(FlagPriorityLabel);