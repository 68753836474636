import * as React from 'react';
import Screen from '../../../components/widgets/Screen';
import ApproveRequest from '../../../components/request/ApproveRequest';
import { RouteComponentProps } from 'react-router';

interface Params {
  requestId: string;
}

interface TasksApproveOrderProps extends RouteComponentProps<Params> {

}

const TasksApproveOrder = (props: TasksApproveOrderProps) => {

  return (
    <Screen
      screenTitleProps={{
        title: 'Approve Order',
      }}
    >
      <ApproveRequest
        requestId={props.match.params.requestId}
      />
    </Screen>
  );
};

export default TasksApproveOrder;
