import { combineReducers } from 'redux';

import { clientDetailsReducer } from './client-details/reducer';
import { ClientDetailsState } from './client-details/types';
import { taskListReducer } from './task-list/reducer';
import { TaskListState } from './task-list/types';
import { AnalysisState } from './analysis/types';
import { analysisReducer } from './analysis/reducer';
import { TestInformationState } from './test-information/types';
import { testInformationReducer } from './test-information/reducer';
import { SummaryState } from './summary/types';
import { summaryReducer } from './summary/reducer';
import { ExportReportState } from './exportReport/types';
import { exportReportReducer } from './exportReport/reducer';
import { carrierRatesReducer } from './carrier-rates/reducer';
import { CarrierRatesState } from './carrier-rates/types';
import { customisationReducer } from './customisation/reducer';
import { CustomisationState } from './customisation/types';
import { ReportRequestState } from './request/types';
import { reportRequestReducer } from './request/reducer';
import { AdditionalInformationState } from './additional-information/types';
import { AdditionalInformationReducer } from './additional-information/reducer';

// The top-level state object.
//
// `connected-react-router` already injects the router state typings for us,
// so we can ignore them here.
export interface ReportState {
  request: ReportRequestState;
  analysis: AnalysisState;
  carrierRates: CarrierRatesState;
  clientDetails: ClientDetailsState;
  taskList: TaskListState;
  testInformation: TestInformationState;
  summary: SummaryState;
  customisation: CustomisationState;
  exportReport: ExportReportState;
  additionalInformation: AdditionalInformationState;
}

const reducers = {
  request: reportRequestReducer,
  analysis: analysisReducer,
  carrierRates: carrierRatesReducer,
  taskList: taskListReducer,
  clientDetails: clientDetailsReducer,
  testInformation: testInformationReducer,
  summary: summaryReducer,
  customisation: customisationReducer,
  exportReport: exportReportReducer,
  additionalInformation: AdditionalInformationReducer,
};

export const defaultReportState = {
  report: combineReducers(reducers)(undefined, { type: '@@init' }),
};

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const reportReducer = combineReducers<ReportState>(reducers);
