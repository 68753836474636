import * as React from 'react';
import { PATHS } from '../store/router/types';
import { Switch } from 'react-router';
import Route from '../base/Route';
import Stats from './stats/Stats';
import StatsInvitae from './stats/StatsInvitae';

const StatDash = () => {
  return (
    <Switch>
      <Route path={PATHS.STATS_INVITAE} component={StatsInvitae} exact />
      <Route path={PATHS.STATS} component={Stats} />
    </Switch>
  );
};

export default StatDash;
