import * as React from 'react';
import Flags from './Flags';
import { FlagType, FlagPriority } from '../../../../graphql/genie-api-types';
import { useMe } from '../../requests/requestMe';

const OwnHighlightedFlags = () => {
  const me = useMe();

  if (!me) {
    return <div className="mt-30">Loading my flags...</div>;
  }

  return (
    <Flags
      flagType={FlagType.NeedsAttention}
      assignedTo={me.id}
      priority={FlagPriority.Urgent}
    />
  );
};

export default OwnHighlightedFlags;
