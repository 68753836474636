import * as React from 'react';
import { widths } from '../../types/tailwind';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width: widths;
}

const Col = ({ className, width, ...props }: Props) => (
  <div className={`mx-15 w-${width} ${className || ''}`} {...props}>
    {props.children}
  </div>
);

export default Col;
