import * as React from 'react';
import { Mutation, Query, QueryConsentArgs, MutationWithdrawConsentArgs } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/widgets/Spinner';
import { appSyncClient } from '../../../../utilities/appSync';
import { toastr } from 'react-redux-toastr';
import WithdrawConsentForm from '../../../components/consent/WithdrawConsentForm';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import ConsentFormHeader from '../../../components/consent/ConsentFormHeader';

interface WithdrawConsentProps {
  onUpdate?(): void;
  consentId: string;
  journeyId: string;
}

const CONSENT_QUERY = gql`
  query Consent($id: ID!) {
    consent(id: $id) {
      id
      dateCreated
      source
      data
      email
      name
      formId
    }
  }
`;

interface ConsentData {
  consent_id?: string;
  legal_name?: string;
  email?: string;
  productType: string;
}

const CONSENT_WITHDRAW_MUTATION = gql`
  mutation WithdrawConsent($input: WithdrawConsentInput!) {
    withdrawConsent(input: $input) {
      consent {
        id
      }
    }
  }
`;

const WithdrawConsent = ({ consentId, journeyId }: WithdrawConsentProps) => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery<Pick<Query, 'consent'>, QueryConsentArgs>(CONSENT_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: {
      id: consentId,
    },
  });

  const [withdrawConsentMutate, withdrawConsentMutationRequest] = useMutation<Pick<Mutation, 'withdrawConsent'>, MutationWithdrawConsentArgs>(CONSENT_WITHDRAW_MUTATION, {
    client: appSyncClient,
  });

  if (loading) {
    return <Spinner label="Loading consent..." />;
  }

  const consentData = JSON.parse(data?.consent?.data ?? '{}') as ConsentData;

  return (
    <div>
      <ConsentFormHeader
        consent={data?.consent}
      />
      <WithdrawConsentForm
        consentId={consentData.consent_id}
        onSubmit={async (values) => {
          const { notes } = values;

          if (!notes) {
            toastr.error('No notes were provided', 'A reason for withdrawing consent must be provided.');
            return;
          }

          await withdrawConsentMutate({
            variables: {
              input: {
                consentId,
                notes,
              }
            },
          });

          toastr.success(`Withdrew consent ${consentData.legal_name ?? ''}`, `Withdrew consent for journey ${journeyId}`);
          ReduxQueryListener.triggerEvents('UpdateConsent');
          dispatch(navigate.toForm('JourneyConsent', { journeyId }));
        }}
      />
      {withdrawConsentMutationRequest.loading && <LoadingOverlay label="Withdrawing consent..." />}
    </div>
  );
}

export default WithdrawConsent;
