import { flatten, sortBy } from 'lodash';
import moment from 'moment';
import { fetchFromApiBridge } from './apiBridge';

interface FormValue {
  id: number;
  fieldID: number;
  value: string;
  name: string;
}

interface Form {
  id: number;
  name: string;
  values: FormValue[];
}

interface AcuityAppointment {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  datetime: string;
  appointmentTypeID: number;
  type: string;
  forms?: Form[];
  calendar: string;
  confirmationPage: string;
}

export interface AppointmentResult {
  id: string;
  type: string;
  name: string;
  email: string;
  date: moment.Moment;
  phone: string;
  counsellor: string;
  dob?: string;
  partnerName?: string;
  partnerDob?: string;
  partnerEmail?: string;
  partnerPhone?: string;
}

const formFieldIds: { [key: number]: keyof Omit<AppointmentResult, 'date'> } = {
  7116620: 'dob',
  7116632: 'partnerName',
  7116634: 'partnerDob',
  7116635: 'partnerEmail',
  8141015: 'partnerPhone',
  8515161: 'dob', // Cancer
  7982642: 'dob', // Carrier donor
  7982643: 'partnerName', // Carrier donor
  7982644: 'partnerDob', // Carrier donor
  7982645: 'partnerEmail', // Carrier donor
  8141037: 'partnerPhone', // Carrier donor 
};

const processAppointment = (result: AcuityAppointment): AppointmentResult => {
  const appointmentResult: AppointmentResult = {
    id: result.id.toString(),
    type: result.type,
    email: result.email,
    phone: result.phone,
    name: `${result.firstName} ${result.lastName}`,
    date: moment(result.datetime),
    counsellor: result.calendar,
  };

  result?.forms?.[0]?.values.forEach((formValue) => {
    if (formFieldIds[formValue.fieldID]) {
      appointmentResult[formFieldIds[formValue.fieldID]] = formValue.value;
    }
  });

  return appointmentResult;
}

export const getAcuityAppointment = async(appointmentId: number): Promise<AppointmentResult> => {
  const result: AcuityAppointment = await fetchFromApiBridge('acuity', {
    apiPath: `/api/v1/appointments/${appointmentId}`,
  });
  
  if (!result) {
    throw new Error('No appointment found');
  }
  
  return processAppointment(result);
}

export const getAcuityPreTestAppointments = async (quantity: number = 50): Promise<AppointmentResult[]> => {
  const appointmentTypes = [
    12293397, // Eugene carrier screening: before the test
    15255133, // Eugene carrier screening: Before the test - individual + donor
    14164522, // Inherited cancer screen: before the test
  ];
  const resultsForAppointmentType: AcuityAppointment[][] = await Promise.all(appointmentTypes.map(appointmentType => 
    fetchFromApiBridge('acuity', {
      apiPath: `/api/v1/appointments?max=${quantity}&appointmentTypeID=${appointmentType}`,
    })
  ));
  
  const results = flatten(resultsForAppointmentType);
  return sortBy(results.map(processAppointment), (item => -item.date.unix()));
};

export type AcuityAppointmentPartial = Pick<AcuityAppointment, 'id'|'datetime'|'type'|'calendar'>;
export const getAllAcuityAppointments = async(): Promise<AcuityAppointmentPartial[]> => {
  const startMonth = moment().subtract(1, 'year').startOf('month');
  const endMonth = moment().endOf('month');
  
  const monthRanges: [string, string][] = [];
  for (let m = startMonth.clone(); m.isBefore(endMonth); m.add(1, 'month')) {
    monthRanges.push([m.format('YYYY-MM-DD'), m.clone().endOf('month').format('YYYY-MM-DD')]);
  }
  
  const resultSets = await Promise.all(monthRanges.map(async(monthRange) => {
    const bridgeResults = await fetchFromApiBridge<AcuityAppointment[]>('acuity', {
      apiPath: `/api/v1/appointments?max=1000&excludeForms=1&minDate=${monthRange[0]}&maxDate=${monthRange[1]}`,
    });
    
    return bridgeResults.map(({ id, datetime, type, calendar }) => ({
      // Restrict result variables to save memory and reduce PII being passed around.
      id, datetime, type, calendar
    }));
  }));
  
  const results = flatten(resultSets);
  return results;
};
