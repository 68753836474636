import * as React from 'react';
import { padStart } from 'lodash';
import { OpsEfficiencyStatsProps } from '../OpsEfficiency';
import moment from 'moment';
import { JourneyState, Product } from '../../../../graphql/genie-api-types';
import { getProductLabel, PRODUCT_LIST } from '../../../../utilities/journeys/products';
import { pluralise } from '../../../../utilities/helpers';

type TaskTime = {
  numberOfOrders: number;
}

type TimeType =
  'Amending Reports' |
  'Approve Reports' |
  'Client-initiated 15min call' |
  'Correspondence' |
  'Download reports' |
  'Fhx review' |
  'Kit orders' |
  'Patient notes' |
  'Post-test call' |
  'Pre-test call' |
  'Recollections' |
  'Report content creation' |
  'Report creation' |
  'Report review' |
  'Report research extraction'
  ;

type RowData = { [key in TimeType]?: TaskTime };
type TimeData = { [key in TimeType]?: string };

type ProductRowData = { [key in Product]?: RowData };
type ProductTimeData = { [key in Product]?: TimeData };

type ProductCounts = { [key in Product]?: number };

const timeToDecimal = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':');

  const decimalValue = parseInt(hours) + (parseInt(minutes || '0') / 60) + (parseInt(seconds || '0') / 60 / 60);
  
  return decimalValue;
}

const decimalToTime = (decimalTime: number): string => {
  const [integer, decimal] = decimalTime.toString().split('.');
  const minutesMultiplier = parseFloat(`0.${decimal ?? '0'}`);
  
  const [minutes, secondsDecimal] = (minutesMultiplier * 60).toString().split('.');
  const secondsMultiplier = parseFloat(`0.${secondsDecimal ?? '0'}`);
  const seconds = Math.round(secondsMultiplier * 60);
  const formattedMinutes = padStart(minutes, 2, '0');
  const formattedSeconds = padStart(seconds.toFixed(0), 2, '0');

  if (decimalTime === 0) {
    return '-:--:--';
  }
  
  return `${integer}:${formattedMinutes}:${formattedSeconds}`;
}

const OpsEfficiencyTable = (props: Omit<OpsEfficiencyStatsProps, 'appointments'>) => {
  const { requests, fromDate, toDate } = props;
  
  const [durationsPerTask, setDurationsPerTask] = React.useState<ProductTimeData>({});

  const preTestCompleteCount = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      return moment(request.gcApprovalDate).isBetween(fromDate, toDate);
    }).forEach(request => {
      counts[request.product] += 1;
    });

    return counts;
  }, [requests, fromDate, toDate]);
  
  const postTestCompleteCount = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      return request?.journeys?.find(journey => {
        return journey?.history?.find(historyItem => {
          return (
            [JourneyState.ReadyToReleaseResults, JourneyState.ReadyToArchive].includes(historyItem.toState) &&
            moment(historyItem.date).isBetween(fromDate, toDate)
          );
        });
      });
    }).forEach(request => {
      counts[request.product] += 1;
    });

    return counts;
  }, [requests, fromDate, toDate]);
  
  const downloadReportsCount = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      return request?.journeys?.find(journey => {
        // Some reports get moved back into ready for report when the lab issues ammendments so 
        // only collect reports that are transitioning for the first time.
        const firstTransitionAwayFromReportHoldingState = journey?.history?.find(historyItem => {
          return historyItem.toState === JourneyState.ReadyForReport;
        });
        if (firstTransitionAwayFromReportHoldingState) {
          return moment(firstTransitionAwayFromReportHoldingState.date).isBetween(fromDate, toDate);
        }
        return false;
      });
    }).forEach(request => {
      counts[request.product] += 1;
    });
    
    return counts;
  }, [requests, fromDate, toDate]);
  
  const reportProcessingCompleteCount = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      return request?.journeys?.find(journey => {
        // Some reports get moved back into ready for report when the lab issues ammendments so 
        // only collect reports that are transitioning for the first time.
        const firstTransitionAwayFromReportHoldingState = journey?.history?.find(historyItem => {
          return historyItem.fromState === JourneyState.ReadyForReport;
        });
        if (firstTransitionAwayFromReportHoldingState) {
          return moment(firstTransitionAwayFromReportHoldingState.date).isBetween(fromDate, toDate);
        }
        return false;
      });
    }).forEach(request => {
      counts[request.product] += 1;
    });

    return counts;
  }, [requests, fromDate, toDate]);
  
  const reportApprovalCompleteCount = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      return request?.journeys?.find(journey => {
        // Some reports get moved back into ready for report when the lab issues ammendments so 
        // only collect reports that are transitioning for the first time.
        const firstTransitionAwayFromReportHoldingState = journey?.history?.find(historyItem => {
          return historyItem.toState === JourneyState.WaitingForResultConsult;
        });
        if (firstTransitionAwayFromReportHoldingState) {
          return moment(firstTransitionAwayFromReportHoldingState.date).isBetween(fromDate, toDate);
        }
        return false;
      });
    }).forEach(request => {
      counts[request.product] += 1;
    });

    return counts;
  }, [requests, fromDate, toDate]);
  
  const researchExtractCounts = React.useMemo(() => {
    const counts: ProductCounts = { [Product.Carrier]: 0, [Product.Cancer]: 0 };
    requests.filter(request => {
      if (request?.researchExtract) {
        return moment(request.researchExtract.dateCreated).isBetween(fromDate, toDate);
      }
      return false;
    }).forEach(request => {
      counts[request.product] += 1;
    });

    return counts;
  }, [requests, fromDate, toDate]);
  
  const rowsPerProduct: ProductRowData = React.useMemo(() => {
      const rowData: ProductRowData = {
        [Product.Carrier]: {},
        [Product.Cancer]: {},
      };
      
      PRODUCT_LIST.forEach(product => {
        rowData[product]['Amending Reports'] = {
          numberOfOrders: reportProcessingCompleteCount[product],
        };
        rowData[product]['Approve Reports'] = {
          numberOfOrders: reportApprovalCompleteCount[product],
        };
        rowData[product]['Client-initiated 15min call'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Correspondence'] = {
          numberOfOrders: product === Product.Carrier ? 100 : 10, // Legacy number for open leads.
        };
        rowData[product]['Download reports'] = {
          numberOfOrders: downloadReportsCount[product],
        };
        rowData[product]['Fhx review'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Kit orders'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Patient notes'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Post-test call'] = {
          numberOfOrders: postTestCompleteCount[product],
        };
        rowData[product]['Pre-test call'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Recollections'] = {
          numberOfOrders: preTestCompleteCount[product],
        };
        rowData[product]['Report content creation'] = {
          numberOfOrders: reportProcessingCompleteCount[product],
        };
        rowData[product]['Report creation'] = {
          numberOfOrders: reportProcessingCompleteCount[product],
        };
        rowData[product]['Report review'] = {
          numberOfOrders: reportApprovalCompleteCount[product],
        };
        // Fuzzy number at the moment due to how retrospectives go but we can't do much about it.
        rowData[product]['Report research extraction'] = {
          numberOfOrders: researchExtractCounts[product],
        };
      });
      
      return rowData;
  }, [preTestCompleteCount, reportProcessingCompleteCount, downloadReportsCount, reportApprovalCompleteCount, researchExtractCounts]);
  
  const setTaskDuration = (product: Product, timeType: TimeType) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setDurationsPerTask({
        ...durationsPerTask,
        [product]: {
          ...durationsPerTask[product],
          [timeType]: e.target.value,
        },
      });
    };
  };

  const getDecimalTimePerOrder = React.useCallback((product: Product, timeType: TimeType) => {
    const durationForTask = durationsPerTask?.[product]?.[timeType] ?? '0:00:00';
    const numberOfOrders = rowsPerProduct?.[product]?.[timeType]?.numberOfOrders ?? 0;
    const decimalDuration = timeToDecimal(durationForTask);

    if (numberOfOrders === 0 || decimalDuration === 0) {
      return 0;
    }

    return decimalDuration / numberOfOrders;
  }, [durationsPerTask, rowsPerProduct]);
  
  const getTotalAverageDecimalTimePerOrder = React.useCallback((product: Product) => {
    const durationsForProduct = durationsPerTask?.[product] ?? {};

    let total = 0;
    Object.keys(durationsForProduct).forEach((timeType: TimeType) => {
      total += getDecimalTimePerOrder(product, timeType);
    });
    
    return total;
  }, [durationsPerTask, rowsPerProduct]);

  return (
    <>
      {PRODUCT_LIST.map(product => (
        <>
        <h2 className="text-xl mb-10 p-5"><span className="font-bold">{getProductLabel(product)}</span> - {fromDate.format('MMMM Do Y')} - {toDate.format('MMMM Do Y')}</h2>
        <div className="p-5 pt-0 pb-10">{preTestCompleteCount[product]} {pluralise('member', 'members', preTestCompleteCount[product])} in pre-test / {postTestCompleteCount[product]} {pluralise('member', 'members', postTestCompleteCount[product])} in post-test</div>
        <div className="mb-20 bg-grey-light p-5 rounded border" key={product}>
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left p-5 py-10 border-b">Task</th>
                <th className="text-left p-5 py-10 border-b">Total duration</th>
                <th className="text-left p-5 py-10 border-b text-right">Number of orders</th>
                <th className="text-left p-5 py-10 border-b text-right">Time per order</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rowsPerProduct[product]).sort().map((taskName: TimeType, index: number) => (
                <tr key={taskName} className={`hover:bg-purple-lighter ${index % 2 ? 'bg-purple-100' : ''}`}>
                  <td className="p-5">{taskName}</td>
                  <td className="p-5">
                    <input
                      type="text"
                      placeholder="00:00"
                      className="px-10"
                      value={durationsPerTask?.[product]?.[taskName] ?? '0:00'}
                      onChange={setTaskDuration(product, taskName)}
                    />
                  </td>
                  <td className="p-5 text-right">{rowsPerProduct[product][taskName].numberOfOrders}</td>
                  <td className="p-5 text-right text-lg" style={{fontFamily: 'monospace'}}>
                    <span className="border p-5 rounded bg-purple-mid text-white">{decimalToTime(getDecimalTimePerOrder(product, taskName))}</span>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3} className="p-20 font-bold text-xl bg-purple text-white rounded-l">
                  Time per order
                </td>
                <td className="p-20 font-bold text-xl bg-purple text-white rounded-r text-right" style={{fontFamily: 'monospace'}}>
                  {decimalToTime(getTotalAverageDecimalTimePerOrder(product))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </>
      ))}

    </>
  );
}

export default OpsEfficiencyTable;