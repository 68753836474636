import * as React from 'react';
import { connect } from 'react-redux';
import { ClientDetailsState } from '../../../store/report/client-details/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import { uniq, flatten } from 'lodash';
import DiseaseRiskRow from '../../../components/report/analysis/DiseaseRiskRow';
import { areBothClientsCarriers } from '../../../../utilities/analysis/analysisDefaults';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { FiInfo, FiRefreshCw } from '../../../components/widgets/Icon';
import { toggleDiseaseExpansion, resetDiseaseField } from '../../../store/report/analysis/actions';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { AnalysisState } from '../../../store/report/analysis/types';
import DiseaseRiskExpansion from '../../../containers/report/analysis/DiseaseRiskExpansion';
import { PATHS } from '../../../store/router/types';
import { getCarrierFieldValue } from '../../../store/report/selectors';
import ActionLink from '../../../components/widgets/ActionLink';
import { updateAffectedDiseaseData } from '../../../store/report/client-details/actions';
import Alert from '../../../components/widgets/Alert';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { navigate } from '../../../store/router/actions';

interface AnalysisViewProps extends TaskListComponentProps {
  clientDetails: ClientDetailsState;
  analysis: AnalysisState;
  onToggleDiseaseExpansions(diseaseId: string): void;
  onFieldClick(gene: string, fieldId: string): void;
  onFieldReset(diseaseId: string, fieldId: string): void;
  onUpdateDiseaseData(): void;
}

class AnalysisView extends React.PureComponent<AnalysisViewProps> {
  renderRefreshButton() {
    return (
      <ActionLink
        onClick={this.props.onUpdateDiseaseData}
        title="Update defaults from contentful"
        className="flex items-center ml-5"
      >
        <FiRefreshCw />
      </ActionLink>
    );
  }

  renderLoading() {
    return (
      <LoadingOverlay label="Loading default content from contentful..." />
    );
  }

  render() {
    const { clientDetails, analysis, onToggleDiseaseExpansions, onFieldClick, onFieldReset, ...restOfProps } = this.props;

    const combinedEffectedGenes = uniq(flatten([clientDetails.clientA.affectedGenes, clientDetails.clientB.affectedGenes]));

    if (!combinedEffectedGenes) {
      return <div>No genes selected</div>;
    }

    return (
      <div>
        <TaskListScreenTitle
          title="Analysis"
          isSelected={restOfProps.isComplete}
          onToggle={restOfProps.onToggleComplete}
        />
        <table key="gene-disease-table" className="w-full">
          <thead>
            <tr>
              <th className="text-left p-10"><div className="flex items-center">Disease {this.renderRefreshButton()}</div></th>
              <th className="text-left p-10">Carrier risk</th>
              <th className="text-left p-10">{clientDetails.clientA.fullName || 'Client A'}</th>
              <th className="text-left p-10">{clientDetails.clientB.fullName || 'Client B'}</th>
            </tr>
          </thead>
          {combinedEffectedGenes.map((gene: string) => {
            const disease = clientDetails.diseasesByGene[gene];
            return (
              <DiseaseRiskRow
                key={disease.id}
                disease={disease}
                clientACarrierLabel={getCarrierFieldValue(gene, 'clientACarrierStatus', analysis, clientDetails)}
                clientBCarrierLabel={getCarrierFieldValue(gene, 'clientBCarrierStatus', analysis, clientDetails)}
                risk={getCarrierFieldValue(gene, 'carrierRisk', analysis, clientDetails)}
                isExpanded={analysis.diseaseExpansion[disease.id]}
                onClick={() => onToggleDiseaseExpansions(disease.id)}
                expansionView={(
                  <DiseaseRiskExpansion
                    gene={gene}
                    areBothClientsCarriers={areBothClientsCarriers(gene, clientDetails.clientA.affectedGenes, clientDetails.clientB.affectedGenes)}
                    onFieldClick={fieldName => onFieldClick(gene, fieldName)}
                    onFieldResetClick={(fieldName => onFieldReset(disease.id, fieldName))}
                  />
                )}
              />
            );
          })
          }
        </table>
        {clientDetails.isLoadingDiseases ? this.renderLoading() : null}
        {combinedEffectedGenes.length === 0 ? <Alert variant="info"><FiInfo size={15} key="no-diseases-message" /> No affected genes</Alert> : null}
      </div>
    );
  }
}

const mapStateToProps = ({ report }: ApplicationState) => ({
  analysis: report.analysis,
  clientDetails: report.clientDetails,
  isComplete: report.taskList.completedTasks.analysis,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onToggleDiseaseExpansions: (diseaseId: string) => dispatch(toggleDiseaseExpansion(diseaseId)),
  onToggleComplete: () => dispatch(toggleTask('analysis')),
  onFieldClick: (gene: string, fieldName: string) => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_ANALYSIS_EDIT_FIELD, `fieldName=${fieldName}&gene=${gene}`)),
  onFieldReset: (diseaseId: string, fieldName: string) => dispatch(resetDiseaseField(diseaseId, fieldName)),
  onUpdateDiseaseData: () => dispatch(updateAffectedDiseaseData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisView);
