import gql from 'graphql-tag';
import { flatten, groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { QueryListJourneysArgs, JourneysConnection, JourneyState, Product } from '../../../graphql/genie-api-types';
import { average, percentage, pluralise } from '../../../utilities/helpers';
import { getProductLabel } from '../../../utilities/journeys/products';
import useStatsQuery from '../../../utilities/useStatsQuery';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import TrafficLightsBar from '../../components/widgets/TrafficLightsBar';
import { StatsProps } from './Stats';

const REQUESTS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          startDate
          history {
            toState
            date
          }
          kits {
            id
            recollectionRequestedDate
            dateShippedToMember
            dateArrivedAtEugene
            dateShippedToLab
          }
          request {
            id
            product
            datePaid
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

interface TurnAroundTimeDetail {
  averageDays: number;
  outlierCounts?: {
    idealDaysDuration?: number;
    numberInIdealZone?: number;
    numberAtLeast1WeekFaster?: number;
    numberAtLeast1WeekSlower?: number;
  };
}

interface RequestTime {
  'Total turn around time': number;
  'Pre-test': number;
  'Blocked at Eugene': number;
  'Lab processing': number;
  'Writing reports': number;
  product: Product;
}

interface RequestTimeDetails {
  'Total turn around time': TurnAroundTimeDetail;
  'Pre-test': TurnAroundTimeDetail;
  'Blocked at Eugene': TurnAroundTimeDetail;
  'Lab processing': TurnAroundTimeDetail;
  'Writing reports': TurnAroundTimeDetail;
  product: Product;
}


type MedianRequestTimesByProduct = { [key in Product]?: Omit<RequestTimeDetails, 'product'> };
type OrderCountByProduct = { [key in Product]: number };

const getOutlierCounts = (turnAroundTimes: number[], thresholdDays: number): TurnAroundTimeDetail['outlierCounts'] => {
  const outlierCounts: TurnAroundTimeDetail['outlierCounts'] = {
    idealDaysDuration: thresholdDays,
    numberInIdealZone: 0,
    numberAtLeast1WeekFaster: 0,
    numberAtLeast1WeekSlower: 0,
  };

  turnAroundTimes.forEach(time => {
    if (time > thresholdDays - 7 && time < thresholdDays) {
      outlierCounts.numberInIdealZone++;
    }
    else if (time < thresholdDays) {
      outlierCounts.numberAtLeast1WeekFaster++;
    }
    else if (time > thresholdDays) {
      outlierCounts.numberAtLeast1WeekSlower++;
    }
  });

  return outlierCounts;
};

const SLAStats = (props: StatsProps) => {
  const statsQuery = useStatsQuery<JourneysConnection, QueryListJourneysArgs>(REQUESTS_QUERY, {
    variables: {
      input: {
        paid: true,
        excludeOnHold: true,
        hasRecollection: false,
      }
    }
  }, 'listJourneys', 200);
  
  const journeysByRequestId = React.useMemo(() => {
    return groupBy(statsQuery?.data?.edges?.map(edge => edge.node) ?? [], (journey => journey.request.id));
  }, [statsQuery?.data]);

  const requestsCompletedInRange = React.useMemo(() => {
    return Object.values(journeysByRequestId).filter((journeys) => {
      const primaryJourney = journeys?.[0];
      const completionItem = primaryJourney.history?.slice(0).reverse().find(historyItem => [JourneyState.Complete, JourneyState.ReadyToArchive, JourneyState.ReadyToReleaseResults].includes(historyItem.toState));
      return (
        completionItem &&
        moment(completionItem.date).isBetween(props.fromDate, props.toDate) &&
        ![JourneyState.CloseLost, JourneyState.Trash].includes(primaryJourney.state)
      );
    }) ?? []
  }, [journeysByRequestId, props.fromDate, props.toDate]);

  const orderTimes: RequestTime[] = React.useMemo(() => {
    return requestsCompletedInRange?.map(journeys => {
      const primaryJourney = journeys?.[0];
      const request = primaryJourney.request;
      const cronologicalHistory = primaryJourney?.history?.slice(0).reverse();

      const paymentDate = moment(request?.datePaid);
      const completionDate = moment(cronologicalHistory.find(historyItem => [JourneyState.Complete, JourneyState.ReadyToArchive].includes(historyItem.toState))?.date);
      const arrivedAtEugeneDate = moment(primaryJourney?.kits[0]?.dateArrivedAtEugene);
      const labShippingDate = moment(primaryJourney?.kits[0]?.dateShippedToLab);
      const reportDownloadedDate = moment(cronologicalHistory?.find(historyItem => [JourneyState.WaitingForPartnerResults, JourneyState.ReadyForReport].includes(historyItem.toState))?.date);

      return {
        'Total turn around time': completionDate.isValid() && arrivedAtEugeneDate.isValid() ? completionDate.diff(arrivedAtEugeneDate, 'days') : 0,
        'Pre-test': labShippingDate.isValid() ? labShippingDate.diff(paymentDate, 'days') : 0,
        'Lab processing': labShippingDate.isValid() && reportDownloadedDate.isValid() ? reportDownloadedDate.diff(labShippingDate, 'days') : 0,
        'Writing reports': completionDate.isValid() && reportDownloadedDate.isValid() ? completionDate.diff(reportDownloadedDate, 'days') : 0,
        'Blocked at Eugene': arrivedAtEugeneDate.isValid() && labShippingDate.isValid() ? labShippingDate.diff(arrivedAtEugeneDate, 'days') : 0,
        product: request.product,
      };
    });
  }, [requestsCompletedInRange]);

  const medianTimes: MedianRequestTimesByProduct = React.useMemo(() => {
    const journeyTimesByProduct = groupBy(orderTimes, 'product');
    const medianTimesByProduct: MedianRequestTimesByProduct = {};

    Object.entries(journeyTimesByProduct).map(([product, times]: [Product, RequestTime[]]) => {
      const tatTimes = times.map(journeyTime => journeyTime['Total turn around time']);
      const labProcessingTimes = times.map(journeyTime => journeyTime['Lab processing']);
      medianTimesByProduct[product] = {
        'Total turn around time': {
          averageDays: average(tatTimes),
          outlierCounts: getOutlierCounts(tatTimes, 4 * 7),
        },
        'Pre-test': {
          averageDays: average(times.map(journeyTime => journeyTime['Pre-test'])),
        },
        'Lab processing': {
          averageDays: average(labProcessingTimes),
          outlierCounts: getOutlierCounts(labProcessingTimes, 3 * 7),
        },
        'Writing reports': {
          averageDays: average(times.map(journeyTime => journeyTime['Writing reports'])),
        },
        'Blocked at Eugene': {
          averageDays: average(times.map(journeyTime => journeyTime['Blocked at Eugene'])),
        },
      }
    });

    return medianTimesByProduct;
  }, [orderTimes]);

  const orderCountByProduct: OrderCountByProduct = React.useMemo(() => {
    const requestsByProduct = groupBy(uniqBy(flatten(requestsCompletedInRange).map(journey => journey.request), 'id'), 'product');
    return {
      [Product.Carrier]: requestsByProduct?.[Product.Carrier]?.length ?? 0,
      [Product.Cancer]: requestsByProduct?.[Product.Cancer]?.length ?? 0,
    };
  }, [requestsCompletedInRange]);

  return (
    <div>
      {statsQuery.isLoading && <LoadingOverlay label="Crunching numbers (takes a few minutes...)" />}
      {[Product.Carrier, Product.Cancer].map(product => (
        <table className="w-full mb-20" key={product}>
          <thead>
            <tr>
              <th className="text-left py-10 text-xl border-b">{getProductLabel(product)}</th>
              <th className="text-left py-10 text-xl border-b" colSpan={2}>{orderCountByProduct[product]} orders completed</th>
            </tr>
          </thead>
          <tbody>
            {medianTimes?.[product] ? Object.entries(medianTimes[product]).map(([medianTimeKey, turnAroundTimeDetail]) => (
              <tr key={medianTimeKey}>
                <td className="w-1/2 py-10">
                  {medianTimeKey}
                </td>
                <td className="py-10">
                  {turnAroundTimeDetail.averageDays?.toFixed(0) || '0'} {pluralise('day', 'days', Math.round(turnAroundTimeDetail.averageDays))} {turnAroundTimeDetail.averageDays > 7 && <span className="text-grey-dark">({(turnAroundTimeDetail.averageDays / 7).toFixed(1)} weeks)</span>}
                </td>
                <td style={{ maxWidth: '200px' }}>
                  {turnAroundTimeDetail.outlierCounts && (
                    <div>
                      <h2 className="font-bold my-5">{turnAroundTimeDetail.outlierCounts.idealDaysDuration / 7} week SLA</h2>
                      <TrafficLightsBar
                        greenCount={turnAroundTimeDetail.outlierCounts.numberAtLeast1WeekFaster}
                        greyCount={turnAroundTimeDetail.outlierCounts.numberInIdealZone}
                        redCount={turnAroundTimeDetail.outlierCounts.numberAtLeast1WeekSlower}
                      />
                      <p className="text-sm mt-5">
                        {percentage(turnAroundTimeDetail.outlierCounts.numberAtLeast1WeekFaster / orderCountByProduct[product])} beat it,{' '}
                        {percentage(turnAroundTimeDetail.outlierCounts.numberInIdealZone / orderCountByProduct[product])} spot on,{' '}
                        {percentage(turnAroundTimeDetail.outlierCounts.numberAtLeast1WeekSlower / orderCountByProduct[product])} slow.
                      </p>
                    </div>
                  )}
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan={3} className="py-10">No completed journeys in this date range.</td>
              </tr>
            )}
            <tr>
              <td colSpan={3} className="border-t py-10 text-xs">
                All numbers are average, journey was completed within date range, excludes recollects
            </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default SLAStats;
