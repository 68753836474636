import * as React from 'react';
import gql from 'graphql-tag';
import { Request, MutationCreateRequestNoteArgs, Mutation } from '../../../../graphql/genie-api-types';
import GenieMutation from '../../base/GenieMutation';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

type AddNoteCallback = (text: string, isImportant: boolean) => Promise<void>;

export interface CreateRequestNoteProps {
  requestId: Request['id'];
  children(addNote: AddNoteCallback, isLoading: boolean): React.ReactElement<any>;
}

const CreateRequestNote = (props: CreateRequestNoteProps) => (
  <GenieMutation
    mutation={gql`
      mutation CreateRequestNote($input: CreateRequestNoteInput!) {
        createRequestNote(input: $input) {
          request {
            id
            notes {
              date
              isImportant
              text
              username
            }
          }
        }
      }
    `}
    awaitRefetchQueries
    refetchQueries={() => {
      return ['Request'];
    }}
  >
    {(mutate: MutationFunction<Pick<Mutation, 'createRequestNote'>, MutationCreateRequestNoteArgs>, result) => {
      const addNoteCallback: AddNoteCallback = async (text, isImportant) => {
        const result = await mutate({
          variables: {
            input: {
              text,
              isImportant,
              requestId: props.requestId,
            },
          },
        });

        if (result) {
          if (result?.data?.createRequestNote?.request?.id) {
            if (isImportant) {
              ReduxQueryListener.triggerEvents('UpdateNotes');
            }
          } else {
            showToast('error', 'Note', 'Note did not save');
          }
        }
      };

      return props.children(addNoteCallback, result.loading);
    }}
  </GenieMutation>
);

export default CreateRequestNote;
