import { fetchFromApiBridge } from '../apiBridge';
import { flatten } from 'lodash';

export interface ShopifyAddress {
  id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  name: string;
  province_code: string;
  country_code: string;
  country_name: string;
}

interface CustomeResponse {
  customers: {
    addresses: ShopifyAddress[];
  }[];
}

const findMemberAddress = async (searchTerm: string):Promise<ShopifyAddress[]> => {
  const result: CustomeResponse = await fetchFromApiBridge('shopify', {
    apiPath: `/admin/api/2020-04/customers/search.json?query=${searchTerm}&fields=addresses`,
  });
  return flatten(result.customers.map(customer => customer.addresses));
};

export default findMemberAddress;