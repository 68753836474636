import * as React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Select from '../../widgets/Select';
import { getProductLabel } from '../../../../utilities/journeys/products';
import { navigate } from '../../../store/router/actions';
import { Journey, Request, JourneyState, Member, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

interface MemberShowJourneySelectorProps {
  memberId: Member['id'];
  selectedJourneyId?: Journey['id'];
}

interface QueryResult {
  member: Pick<Member, 'id'> & {
    journeys?: (Pick<Journey, 'id'|'state'|'startDate'> & {
      request?: Pick<Request, 'id'|'product'|'productVariant'>;
    })[];
  };
}

const QUERY = gql`
  query member($id: ID!) {
    member(id: $id) {
      id
      journeys {
        id
        state
        startDate
        request {
          id
          product
          productVariant
        }
      }
    }
  }
`;
const MemberShowJourneySelector = (props: MemberShowJourneySelectorProps) => {
  const dispatch = useDispatch();
  const query = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: props.memberId,
    },
  });
  const journeys = query?.data?.member?.journeys ?? [];
  
  const onNavigateToMemberJourney = (journeyId: string) => {
    dispatch(navigate.toJourney(journeyId));
  };

  return (
    <div>
      <div className="text-sm text-grey font-nm mb-8">Product:</div>
      <Select
        selectedValue={props.selectedJourneyId}
        onChange={onNavigateToMemberJourney}
        options={journeys.filter(journey => journey.state !== JourneyState.Trash).map(journey => ({
          value: journey.id,
          label: `${getProductLabel(journey.request.product, journey.request.productVariant)} - ${moment(journey.startDate).format('MMM Y')}`,
        }))}
      />
    </div>
  );
};

export default MemberShowJourneySelector;
