import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, DoctorClinic, CreateDoctorResult, Query, UpdateDoctorInput } from '../../../../graphql/genie-api-types';
import DoctorForm from '../../../components/doctor/DoctorForm';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import { pick } from 'lodash';

interface DoctorUpdateProps {
  onUpdate?(doctor: CreateDoctorResult['doctor']): void;
  onCancel?(): void;
  doctorId: DoctorClinic['id'];
}

const DoctorUpdate = (props: DoctorUpdateProps) => (
  <GenieQuery
    query={gql`
      query Doctor($id: ID!) {
        doctor(id: $id) {
          id
          title
          nameFirst
          nameMiddle
          nameLast
          nickname
          personalMobile
          personalPhone
          personalEmail
          specialties
          practitionerId
          gender
          registrationNumber
        }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.doctorId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'doctor'>>) => {
      if (loading) {
        return <Spinner label="Loading doctor..." />;
      }

      const { ...doctorValues } = data.doctor; // eslint-disable-line @typescript-eslint/no-unused-vars

      return (
        <GenieMutation
          mutation={gql`
          mutation UpdateDoctor($input: UpdateDoctorInput!) {
            updateDoctor(input: $input) {
                doctor {
                  id
                }
              }
            }
          `}
        >
          {(mutate: MutationFunction<Mutation>, result) => (
            <DoctorForm
              initialValues={doctorValues}
              onCancel={props.onCancel}
              isLoading={result.loading}
              errorMessage={result?.error?.message}
              onSubmit={async (doctorFields: UpdateDoctorInput) => {
                const mutationResult = await mutate({
                  variables: {
                    input: {
                      ...pick<UpdateDoctorInput>(doctorFields, [
                        'id',
                        'gender',
                        'nameFirst',
                        'nameLast',
                        'nameMiddle',
                        'nickname',
                        'personalEmail',
                        'personalMobile',
                        'personalPhone',
                        'practitionerId',
                        'registrationNumber',
                        'specialties',
                        'title',
                      ]),
                    },
                  },
                });

                ReduxQueryListener.triggerEvents('UpdateDoctor');

                if (mutationResult && props.onUpdate) {
                  props.onUpdate(mutationResult.data.updateDoctor.doctor);
                }
              }}
            />
          )
          }
        </GenieMutation>
      )
    }}
  </GenieQuery>
);

export default DoctorUpdate;
