import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Clinic, Doctor, Maybe, QueryClinicArgs } from '../../graphql/genie-api-types';
import LoadingOverlay from '../components/widgets/LoadingOverlay';

interface ClinicShowContextWrapperProps {
  clinicId: Clinic['id'];
  children: React.ReactNode;
}

export type ClinicShowClinic = Pick<Clinic, 'id' | 'name' | 'addressStreet' | 'addressSuburb' | 'addressPostcode' | 'addressState' | 'addressCountry' | 'clinicPhone' | 'clinicEmail' | 'latitude' | 'longitude' | 'locationId'> & {
  doctors: Maybe<Array<Pick<Doctor, 'id' | 'nameFirst' | 'nameMiddle' | 'nameLast'>>>;
};

export type ClinicShowQueryResult = { clinic: ClinicShowClinic };

export interface ClinicShowContextValue {
  isLoading: boolean;
  errorMessage: string;
  clinic?: ClinicShowClinic;
  clinicId: Clinic['id'];
}

const ClinicShowContext = React.createContext<ClinicShowContextValue>({ isLoading: false, errorMessage: '', clinicId: null });

const MASTER_QUERY = gql`
  query QueryClinic($id: ID!) {
    clinic(id: $id) {
      id
      name
      addressStreet
      addressSuburb
      addressPostcode
      addressState
      addressCountry
      clinicEmail
      clinicPhone
      locationId
      latitude
      longitude
      doctors {
        id
        nameFirst
        nameMiddle
        nameLast
      }
    }
  }
`;

export const ClinicShowContextWrapper = (props: ClinicShowContextWrapperProps) => {
  // Master query is designed to precache all other graphql queries within
  // nested components.
  const clinicQuery = useQuery<ClinicShowQueryResult, QueryClinicArgs>(MASTER_QUERY, {
    variables: {
      id: props.clinicId,
    },
    fetchPolicy: 'network-only',
  });

  const value: ClinicShowContextValue = {
    isLoading: clinicQuery.loading,
    errorMessage: '',
    clinicId: props.clinicId,
    clinic: clinicQuery?.data?.clinic,
  }

  if (clinicQuery.loading) {
    return <LoadingOverlay label="Loading doctor..." />
  }

  return (
    <ClinicShowContext.Provider value={value}>
      {props.children}
    </ClinicShowContext.Provider>
  );
}


export default ClinicShowContext;