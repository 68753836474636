import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { toggleTask } from '../../../store/report/task-list/actions';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { changeFields, resetFields } from '../../../store/report/additional-information/actions';
import { AdditionalInformationState, FormattedField } from '../../../store/report/additional-information/types';
import Select from 'react-select';
import Heading from '../../../components/widgets/Heading';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ActionLink from '../../../components/widgets/ActionLink';
import _ from 'lodash';
import BaseForm from '../../../components/widgets/BaseForm';
import FormInput, { FormInputOption } from '../../../components/widgets/form/FormInput';
import { FormikValues } from 'formik';
import Handlebars from '../../../../utilities/report/Handlebars';
import { ClientDetailsState } from '../../../store/report/client-details/types';
import * as yup from 'yup';
export interface AdditionalInformationComponentProps extends AdditionalInformationState {
  additionalInformationStateFields: AdditionalInformationState;
  onToggleComplete(): void;
  onResetClick(fields: AdditionalInformationState): void;
  onSave(fields: AdditionalInformationState): void;
  isComplete: boolean;
  clientDetails: ClientDetailsState;
}

const macros = [
  {
    label: "Member has reported a VUS in the family",
    value:
      "{{clientDetails.clientA.nickname}} has told us that a close family member has a variant of uncertain significance (VUS) in the [GENE] gene. As discussed with {{clientDetails.clientA.nickname}} prior to testing, while the Invitae laboratory screens [GENE] as part of this test, this test does not report VUS in any of the cancer predisposition genes. We recommend {{clientDetails.clientA.nickname}} be referred to their local clinical genetics service to discuss their close family members' genetic test result and their family history of cancer, so appropriate recommendations can be provided to them.",
  },
  {
    label: "Member at risk of known familial gene variant",
    value:
      "{{clientDetails.clientA.nickname}} has told us that a close family member has a [GENE] variant, and they are therefore at risk of carrying this [GENE] gene variant. As discussed with {{clientDetails.clientA.nickname}} prior to testing, while the Invitae laboratory screens [GENE] as part of this test, it is not a predictive test. Without access to a familial control or confirmatory testing, there are limitations to the test outcome for known familial variants and the lab cannot comment on this variant specifically. {{clientDetails.clientA.nickname}} is aware that government funded predictive testing with a clinical genetics service is available and recommended for the familial variant.",
  },
  {
    label: "Member has a rare history of cancer",
    value:
      "The family history {{clientDetails.clientA.nickname}} has reported is uncommon, and we therefore recommend they are referred to a clinical genetics service through their GP, to review their family history with a genetics specialist. More detail about their [RELATIVE_RELATIONSHIP]'s diagnosis would likely be helpful in establishing if there are any additional cancer risks for {{clientDetails.clientA.nickname}}.",
  },
  {
    label: "Member has family history of cancer",
    value:
      "{{clientDetails.clientA.nickname}} has reported a family history of cancer. We recommend they review their family history with their GP and a genetics service to determine if additional cancer screening is recommended based on confirmed family history.",
  },
  {
    label: "Member has personal and family history of cancer",
    value: "{{clientDetails.clientA.nickname}} has reported a personal and family history of cancer. We recommend they review this history with their GP and consider referral to a clinical genetics service, to determine if additional cancer screening is recommended based on the confirmed history."
  },
  {
    label: "Member has a family history and is at moderate risk",
    value: "{{clientDetails.clientA.nickname}} has reported a family history of cancer. Based on this family history, {{clientDetails.clientA.nickname}} has a moderately increased risk of developing [CANCER_TYPE] cancer in their lifetime. People in this category of risk should access additional screening. We recommend {{clientDetails.clientA.nickname}} speaks to their GP regarding up-to-date screening guidelines for people with this level of risk."
  },
  {
    label: "High risk family history, no testing in the family with NAD result",
    value: "We recommend {{clientDetails.clientA.nickname}} be re-referred to their local genetics service or familial cancer centre so their lifetime risk of [CANCER_TYPE] cancer can be reassessed, taking into account this result. This result suggests the risk of [CANCER_TYPE] cancer may not be as high as it was previously assessed to be."
  },
];

const resetVal: AdditionalInformationState = { content: "" }

const AdditionalInformationView = (props: AdditionalInformationComponentProps) => {
  const [rawContent, setRawContent] = useState('');
  const [dynamicVals, setDynamicVals] = useState([]);

  const schemaShape: any = {};
  dynamicVals.forEach(val => {
    schemaShape[val.identifier] = yup.string().required('Value cannot be empty');
  })
  const dynamicSchema = yup.object().shape(schemaShape);

  const customFields = getDynamicVals(rawContent, dynamicVals);
  const showEditable = _.uniqBy(customFields, 'identifier').length > 1;

  const parsedMacros = macros.map(macro => {
    const macroParser = Handlebars.compile(macro.value)
    return {
      label: macro.label,
      value: macroParser({ ...props })
    };
  })


  const ShortcodeRenderer = (rendererProps: any) => {
    const currentField = _.find(customFields, ['identifier', rendererProps.identifier]);
    return <span className={`inline-block ${!currentField.value ? 'bg-red-light' : 'bg-green-light'} px-4`}>{!currentField.value ? `[insert ${rendererProps.identifier}]` : currentField.value}</span>;
  };

  const onCancel = () => {
    setDynamicVals([]);
    setRawContent('');
  }

  const handleChangeVal = (data: FormikValues) => {
    const newDynamicVals = customFields.map(field => {
      return {
        ...field,
        value: data[field.identifier]
      }
    });
    setDynamicVals(newDynamicVals);
  }

  const onSubmit = (data: FormikValues) => {
    handleChangeVal(data);
    props.onSave({ content: parseShortcode(rawContent, customFields) })

    onCancel();
  }

  return (
    <div>
      <TaskListScreenTitle
        title="Additional Information"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      <Select
        options={parsedMacros}
        placeholder="Select macro..."
        onChange={(option) => {
          setRawContent(option.value)
        }}
        className="mb-20"
        value={null}
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: '#EFF0FD',
            "&:hover": {
              borderColor: '#EFF0FD'
            }
          }),
          indicatorSeparator: () => ({
            display: 'none'
          }),
        }}
        formatOptionLabel={(option) => (
          <>
            <h3 className="font-bold mb-5">{option.label}</h3>
            <p className="text-grey-dark text-sm">{option.value}</p>
          </>
        )}
      />
      {(props.additionalInformationStateFields.content || rawContent) &&
        <>
          <Heading level={3} className="my-20  flex justify-between">Preview
            <ActionLink className="btn text-center btn--sm apperance-none text-red border-red hover:bg-red bg-white hover:text-white base-form__btn ml-10"
              onClick={() => {
                props.onResetClick(resetVal);
                onCancel();
              }}>Reset</ActionLink>
          </Heading>

          <div className='border rounded p-20 mb-20'>
            <ReactMarkdown
              source={rawContent ? rawContent : props.additionalInformationStateFields.content}
              plugins={[
                [require("remark-shortcodes"), { startBlock: "[", endBlock: "]", inlineMode: true }]
              ]}
              renderers={{ shortcode: ShortcodeRenderer }}
            />
          </div>
        </>
      }

      {rawContent &&
        <>
        {showEditable &&
          <Heading level={3} className="mb-20 mt-40">
            <span>Editable fields</span>
          </Heading>
        }

        <BaseForm
          validationSchema={dynamicSchema}
          initialValues={formatInitialValues(customFields)}
          onSubmit={onSubmit}
          hasCancel={true}
          onCancel={onCancel}
          onFormChanged={handleChangeVal}
          submitButtonLabel="Save"
        >
          {({
            handleChange, values, errors, setFieldValue,
          }) => (
            <>
              {_.uniqBy(customFields, 'identifier').map(item => {
                if (item.type === "select") {
                  return <FormInput
                    label={item.label}
                    name={item.identifier}
                    key={item.identifier}
                    type="text"
                    as={item.type}
                    onChange={handleChange}
                    isInvalid={!!errors[item.identifier]}
                    options={item.options}
                    setFieldValue={setFieldValue}
                    value={values[item.identifier] ?? ''} />

                  } else {
                    return <FormInput
                      label={item.label}
                      name={item.identifier}
                      key={item.identifier}
                      type="text"
                      onChange={handleChange}
                      isInvalid={!!errors[item.identifier]}
                      setFieldValue={setFieldValue}
                      value={values[item.identifier] ?? ''}
                      helpText={errors[item.identifier]} />
                  }
                })}
            </>
          )}
        </BaseForm>

        </>
      }

    </div>
  );
};


const getDynamicVals = (rawContent: string, currentDynamicVals: FormattedField[]) => {
  const dissected = rawContent.trim().toString().split("[").join("[###").split(/\[(.*?)\]/);
  const valueKeys = dissected.filter(element => element.includes("###"));
  const finalArray: FormattedField[] = [];

  valueKeys.forEach(item => {
    const itemArr = item.split(" ");
    const itemId = itemArr[0].replace("###", "");
    const getFieldItem = _.find(currentDynamicVals, ['identifier', itemId]);
    const fieldVal = getFieldItem ? getFieldItem.value : "";

    let field: FormattedField = {
      "identifier": itemId,
      "label": capitalizeFirstLetter(itemId),
      "type": "text",
      "value": fieldVal
    }

    if (itemArr[1]) {
      if (itemArr[1].includes("options")) {
        const fieldOptions: FormInputOption[] = [{ label: "Select a value", value: "" }];
        const optionArr = itemArr[1].replace("options=", "").split("\\").join("").split("\"").join("").split('|');
        optionArr.map((optionVal: string) => {
          fieldOptions.push({ label: optionVal, value: optionVal });
        });
        field = { ...field, "type": "select", "options": fieldOptions };
      }
    }

    finalArray.push(field);
  })

  const filteredFinalArray = _.uniqBy(finalArray, "identifier")

  return filteredFinalArray;
}

const parseShortcode = (rawContent: string, dynamicValues: FormattedField[]) => {
  const fromTo = dynamicValues.map((val: FormattedField) => {
    return {
      from: val.identifier, to: val.value
    }
  })

  const dissected = rawContent.trim().toString().split(/\[(.*?)\]/);

  const parsedArr = dissected.map(part => {
    const shortcodeKeyword = part.split(" ");
    const isShortcode = fromTo.filter(e => e.from === shortcodeKeyword[0]);
    return isShortcode.length > 0 ? isShortcode[0].to : part;
  })

  const finalResult = parsedArr.join("");
  return finalResult;
}

const formatInitialValues = (customFields: FormattedField[]) => {
  const result: FormikValues = {};
  customFields.forEach((item: FormattedField) => {
    result[item.identifier] = item.value ?? "";
  })
  return result;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1).split("_").join(" ");
}

const mapStateToProps = ({ report }: ApplicationState) => {
  const additionalInformationStateFields = {
    content: report.additionalInformation.content
  };

  return {
    additionalInformationStateFields,
    isComplete: report.taskList.completedTasks.additionalInformation,
    clientDetails: report.clientDetails,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onSave: (fields: AdditionalInformationState) => dispatch(changeFields(fields)),
  onToggleComplete: () => dispatch(toggleTask('additionalInformation')),
  onResetClick: (fields: AdditionalInformationState) => dispatch(resetFields(fields)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformationView);