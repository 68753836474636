export const PATHS: { [key: string]: string } = {
  WELCOME: '/',
  REPORTS: '/reports',
  REPORT: '/reports/:mode/:requestId',
  REPORTS_CLIENT_DETAILS: '/reports/:mode/:requestId/edit/client-details',
  REPORTS_CLIENT_DETAILS_A: '/reports/:mode/:requestId/edit/client-details/A',
  REPORTS_CLIENT_DETAILS_B: '/reports/:mode/:requestId/edit/client-details/B',
  REPORTS_CARRIER_RATES: '/reports/:mode/:requestId/edit/carrier-rates',
  REPORTS_CARRIER_RATES_EDIT: '/reports/:mode/:requestId/edit/carrier-rates/edit',
  REPORTS_ANALYSIS: '/reports/:mode/:requestId/edit/analysis',
  REPORTS_ANALYSIS_EDIT_FIELD: '/reports/:mode/:requestId/edit/analysis/edit-field/:fieldName',
  REPORTS_TEST_INFORMATION: '/reports/:mode/:requestId/edit/test-information',
  REPORTS_TEST_INFORMATION_EDIT: '/reports/:mode/:requestId/edit/test-information/edit',
  REPORTS_SUMMARY: '/reports/:mode/:requestId/edit/summary',
  REPORTS_SUMMARY_EDIT: '/reports/:mode/:requestId/edit/summary/edit',
  REPORTS_EXPORT: '/reports/:mode/:requestId/edit/export',
  REPORTS_LETTER_TO_DOCTOR: '/reports/:mode/:requestId/edit/letter-to-doctor',
  REPORTS_CUSTOMISATION: '/reports/:mode/:requestId/edit/customisation',
  REPORTS_CUSTOMISATION_EDIT: '/reports/:mode/:requestId/edit/customisation/edit',
  REPORTS_FAMILY: '/reports/:mode/:requestId/edit/family',
  REPORTS_UPDATES: '/reports/:mode/:requestId/updates',
  REPORT_ADDITIONAL_INFO: '/reports/:mode/:requestId/additional-info',
  CONSULT: '/consult',
  CLINICAL: '/clinical',
  CLINICAL_YET_TO_PURCHASE: '/clinical/phase/yet-to-purchase',
  CLINICAL_PRE: '/clinical/phase/pre',
  CLINICAL_DURING: '/clinical/phase/during',
  CLINICAL_POST: '/clinical/phase/post',
  CLINICAL_ARCHIVED: '/clinical/phase/archived',
  CLINICAL_RESEARCH: '/clinical/research',
  CLINICAL_RESEARCH_EXTRACT_CREATE: '/clinical/research/:requestId/extracts/create',
  CLINICAL_RESEARCH_EXTRACT_SHOW: '/clinical/research/:requestId/extracts/:extractId',
  CLINICAL_STATS: '/clinical/stats',
  CLINICAL_PROJECTION: '/clinical/projection',
  PRE_TEST: '/pre-test',
  PRE_TEST_LOGINS: '/pre-test/logins',
  PRE_TEST_INVITES: '/pre-test/invites',
  PRE_TEST_FAMILY_HISTORY: '/pre-test/family-history',
  PRE_TEST_CONSENT: '/pre-test/consent',
  PRE_TEST_KITS: '/pre-test/kits',
  MEMBERS: '/members',
  MEMBER_JOURNEY_LEGACY_REDIRECT: '/members/:memberId/journeys/:journeyId',
  MEMBER_SHOW_LEGACY_REDIRECT: '/members/:memberId',
  JOURNEY_SHOW: '/journeys/:journeyId',
  MEMBER_FAMILY_HISTORY: '/members/:memberId/family-history',
  DIRECTORY: '/dir',
  CLINICS: '/dir/clinics',
  CLINIC_SHOW: '/dir/clinics/:clinicId',
  DOCTORS: '/dir/doctors',
  DOCTOR_SHOW: '/dir/doctors/:doctorId',
  COUNSELLORS: '/dir/counsellors',
  COUNSELLOR_SHOW: '/dir/counsellors/:counsellorId',
  OPS: '/ops',
  OPS_REQUESTS: '/ops/requests',
  OPS_MEMBER_SHIPPING: '/ops/member-shipping',
  OPS_LAB_SHIPPING: '/ops/lab-shipping',
  OPS_AWAITING_SAMPLES: '/ops/awaiting-samples',
  OPS_MANIFEST: '/ops/lab-shipping/manifest',
  OPS_MANIFEST_INVITAE: '/ops/lab-shipping/manifest-invitae',
  OPS_HISTORY: '/ops/ship-history',
  STATS: '/stats',
  STATS_GENERAL: '/stats/general',
  STATS_INVITAE: '/stats/invitae',
  SUBMISSIONS: '/submissions',
  SUBMISSIONS_FAMILY_HISTORY: '/submissions/family-history/:submissionId',
  DEV: '/dev',
  DEV_GRAPHQL: '/dev/graphql',
  DEV_PDF_PRINTER: '/dev/pdf',
  DEV_REPORT_GENERATOR: '/dev/report-generator',
  TASKS: '/tasks',
  TASKS_POST_PURCHASE: '/tasks/post-purchase',
  TASKS_PRE_TEST: '/tasks/pre-test',
  TASKS_DOWNLOAD_REPORTS: '/tasks/download-reports',
  TASKS_WRITE_REPORTS: '/tasks/write-reports',
  TASKS_REVIEW_REPORTS: '/tasks/review-reports',
  TASKS_AMENDED_REPORTS: '/tasks/amended-reports',
  TASKS_SALES: '/tasks/sales',
  TASKS_APPROVE_ORDERS: '/tasks/approve-orders',
  TASKS_APPROVE_ORDERS_ORDER: '/tasks/approve-orders/:requestId',
  TASKS_SALES_LEADS: '/tasks/sales-leads',
  TASKS_MATCH_DOCTORS: '/tasks/match-doctors',
  TASKS_LAB_ORDERS: '/tasks/lab-orders',
  UPDATES: '/updates',
};

type Keys = keyof typeof PATHS;
export type Path = typeof PATHS[Keys];

export type ReportMode = 'remote' | 'localStorage';

export type ReportMatchParams = {
  mode?: ReportMode;
  requestId?: string;
};
