import React from 'react';
import moment from 'moment';
import { Query, QueryListFlagsArgs, FlagType, Mutation, MutationUpdateFlagCompleteArgs, FlagCode, ListFlagsInput } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { showLoadingToast, hideToast } from '../../../store/toast/actions';
import { appSyncClient } from '../../../../utilities/appSync';
import Table from '../../../components/widgets/Table';
import { Collapsible } from '../../../components/widgets/Collapsible';
import Badge from '../../../components/widgets/Badge';
import { Action } from '../../../components/widgets/DropdownButton';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import { FLAG_CODE_LABELS } from '../../../../utilities/flags/flagLabels';
import PrivateContent from '../../../components/widgets/PrivateContent';

const LIST_FLAGS_QUERY = gql`
  query ListFlags($input: ListFlagsInput!) {
    listFlags(input: $input) {
      edges {
        cursor
        node {
          id
          flagType
          code
          priority
          linkData
          assignedTo {
            id
            counsellorDetails {
              id
              name
              nickname
            }
          }
          dateCreated
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const UPDATE_FLAG_COMPLETE_MUTATION = gql`
  mutation UpdateFlagComplete($input: UpdateFlagCompleteInput!) {
    updateFlagComplete(input: $input) {
      flag {
        id
      }
    }
  }
`;

interface BaseLinkData {
  email: string;
  name: string;
}

interface FamilyHistoryLinkData extends BaseLinkData {
  submission_id: string;
}

interface ConsentLinkData extends BaseLinkData {
  consent_id: string;
}

type ActionNames = 'family-history-merge-to-member' | 'consent-assign-to-journey' | 'complete-flag';

const getActions = (code: FlagCode, linkData: FamilyHistoryLinkData | ConsentLinkData): Action<ActionNames>[] => {
  const actions: Action<ActionNames>[] = [];

  switch (code) {
    case FlagCode.UnmatchedFamilyHistory:
      actions.push({
        label: 'Merge into member',
        name: 'family-history-merge-to-member',
        variant: 'primary',
        extraProps: { noBatch: true },
        value: (linkData as FamilyHistoryLinkData).submission_id,
      });
      break;

    case FlagCode.UnmatchedConsent:
      actions.push({
        label: 'Assign to journey',
        name: 'consent-assign-to-journey',
        variant: 'primary',
        extraProps: { noBatch: true },
        value: (linkData as ConsentLinkData).consent_id,
      });
      break;
  }

  actions.push({
    label: 'Remove',
    name: 'complete-flag',
    variant: 'primary',
  });

  return actions;
};

interface AprilFlagsProps {
  queryVariables?: Omit<ListFlagsInput, 'flagType'>;
  title?: string;
}

const AprilFlags = (props: AprilFlagsProps) => {
  const queryVariables = React.useMemo(() => {
    return {
      input: {
        ...props.queryVariables,
        flagType: FlagType.AprilError,
      }
    };
  }, [props.queryVariables]);
  const { loading, data, refetch } = useQuery<Pick<Query, 'listFlags'>, QueryListFlagsArgs>(LIST_FLAGS_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const items = data?.listFlags?.edges?.map(edge => edge.node)
    .map(node => {
      const linkData: FamilyHistoryLinkData | ConsentLinkData = node.linkData ? JSON.parse(node.linkData) : null;

      return {
        id: node.id,
        email: linkData.email ?? '-',
        name: linkData.name ?? '-',
        submitted: node.dateCreated,
        linkData,
        code: node.code,
      }
    }) ?? [];

  const dispatch = useDispatch();

  const [completeFlagsMutation] = useMutation<Pick<Mutation, 'updateFlagComplete'>, MutationUpdateFlagCompleteArgs>(UPDATE_FLAG_COMPLETE_MUTATION, {
    client: appSyncClient,
    refetchQueries: () => {
      return ['ListFlags'];
    },
  });

  const onRowSelect = React.useCallback((flagId: string) => {
    const flag = items.find(item => item.id === flagId);

    switch (flag.code) {
      case FlagCode.UnmatchedFamilyHistory:
        const submissionId = (flag.linkData as FamilyHistoryLinkData).submission_id;
        dispatch(navigate.toFamilyHistorySubmission(submissionId));
        break;

      case FlagCode.UnmatchedConsent:
        const consentId = (flag.linkData as ConsentLinkData).consent_id;
        dispatch(navigate.toForm('ReassignConsent', { consentId }));
        break;
    }
  }, [dispatch, data]);

  const onActionClick = React.useCallback(async (rowIds: string[], actionId: ActionNames, actionValue: string) => {
    switch (actionId) {
      case 'family-history-merge-to-member':
        const submissionId = actionValue;
        dispatch(navigate.toForm('ReassignFamilyHistorySubmission', { submissionId }));
        break;

      case 'consent-assign-to-journey':
        const consentId = actionValue;
        dispatch(navigate.toForm('ReassignConsent', { consentId }));
        break;

      case 'complete-flag':
        const confirm = window.confirm('Are you sure you want to remove these flags?');
        if (confirm) {
          const toastId = 'complete-flag';
          dispatch(showLoadingToast(toastId, 'Removing flags', `Marking ${rowIds.length} flag(s) as complete.`))
          await Promise.all(rowIds.map((flagId) => {
            completeFlagsMutation({
              variables: {
                input: {
                  id: flagId,
                }
              }
            })
          }));
          await refetch(queryVariables);
          dispatch(hideToast(toastId));
        }
    }
  }, [dispatch]);

  if (loading) {
    return null;
  }

  return (
    <Collapsible
      title={props.title ?? 'Umatched April submissions'}
      className="mt-50"
      contentClass="mt-10"
    >
      <Table
        header={['Email', 'Name', 'Type', 'Submitted']}
        isLoading={loading}
        onRefresh={() => refetch(queryVariables)}
        onRowSelect={onRowSelect}
        hasBatchSelect
        onActionClick={onActionClick}
        rowGroups={items.map(item => ({
          id: item.id,
          rows: [
            {
              id: item.id,
              cells: [
                (
                  <Badge key="email">
                    <PrivateContent>{item.email}</PrivateContent>
                  </Badge>
                ),
                <PrivateContent key="name">{item.name}</PrivateContent>,
                (
                  <Badge key="type">
                    {FLAG_CODE_LABELS[item.code]}
                  </Badge>
                ),
                moment(item.submitted).fromNow(),
              ],
              actions: getActions(item.code, item.linkData),
            },
          ],
        }))}
      />
    </Collapsible>
  );
}

export default AprilFlags;
