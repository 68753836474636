import * as React from 'react';
import moment from 'moment';
import { Flag } from '../../../graphql/genie-api-types';
import _ from 'lodash';

interface FlagTATProps {
  flagJourney: Flag['journey'];
}

const FormatResult = (result: string) => {
  return <span className="bg-grey-light py-3 px-5 rounded text-xs">{result}</span>
}

const FlagTAT = ({ flagJourney }: FlagTATProps) => {
  if (!flagJourney || !flagJourney.kits) {
    return FormatResult("N/A")
  }

  const shippedKits = flagJourney?.kits.filter(kit => kit.dateShippedToLab !== null);
  const kitsByDate = _.orderBy(shippedKits, ['dateShippedToLab'], ['desc']);
  const lastKit = kitsByDate[0];
  if (!lastKit) {
    return FormatResult("N/A");
  }

  const TATFastest = moment(lastKit.dateShippedToLab).add(4, 'weeks').format("DD MMM YY");
  const TATSlowest = moment(lastKit.dateShippedToLab).add(6, 'weeks').format("DD MMM YY");

  return FormatResult(TATFastest + " - " + TATSlowest)
};

export default React.memo(FlagTAT);
