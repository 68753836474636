import { coupleCarrier } from './carrier/coupleCarrier';
import { individualCarrier } from './carrier/individualCarrier';
import { individualCancer } from './cancer/individualCancer';
import { AvailableTemplates } from './getCompiledTemplateVariablesWithSectionOverrides';
import { getFamilyReportTemplate } from './carrier/familyReports';

export type Medium = 'pdfReport' | 'email';
export type SectionTemplateName = 'Section' | 'TwoColSection' | 'Intro' | 'DiseaseTable' | 'ClientDetails' | 'DiseaseHeader' | 'PageTitle' | 'Divider';
export type TemplateBaseName = 'Top'|'Bottom';

export type ReportTemplate = {
  name: AvailableTemplates;
  label: string;
  medium: Medium;
  pages: TemplatePage[];
};

export type Repeat = {
  collection: string;
  as: string;
  repeaterId: string;
};

export type TemplatePage = {
  sections: TemplateSection[];
  repeat?: Repeat;
};

export type TemplateSection = {
  template: SectionTemplateName;
  id: string;
  variables?: { [key: string]: any };
  dependencies?: string[];
};

export const templateLabels: {[key in AvailableTemplates]?: string} = {
  coupleCarrier: coupleCarrier.label,
  individualCarrier: individualCarrier.label,
  individualCancer: individualCancer.label,
}

export default {
  coupleCarrier,
  individualCarrier,
  individualCancer,
  familyReportA: getFamilyReportTemplate('clientA'),
  familyReportB: getFamilyReportTemplate('clientB'),
};
