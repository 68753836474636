import * as React from 'react';
import { Switch, RouteProps, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { ApplicationState } from '../store';
import { History } from 'history';
import { ApolloProvider } from 'react-apollo';
import ReduxToastr from 'react-redux-toastr';
import ErrorBoundary from './ErrorBoundary';
import Report from '../screens/Report';
import { appSyncClient } from '../../utilities/appSync';
import AuthenticationWrapper from './AuthenticationWrapper';
import Route from './Route';
import { PATHS } from '../store/router/types';
import BugButton from '../components/widgets/BugButton';
import Sidebar, { SidebarItem } from '../components/widgets/SideBar';
import Clinical from '../screens/Clinical';
import Welcome from '../screens/Welcome';
import JourneyInfo from '../components/journey/info-sidebar/JourneyInfo';
import FormModal from '../screens/FormModal';
import Members from '../screens/Members';
import Doctors from '../screens/Doctors';
import TopToolbar from '../containers/navigation/TopToolbar';
import Ops from '../screens/Ops';
import Submissions from '../screens/Submissions';
import Dev from '../screens/Dev';
import Tasks from '../screens/Tasks';
import { PrivacyContextWrapper } from './PrivacyContext';
import Updates from '../screens/Updates';
import { getUnreadCount } from '../components/updates/UpdatesList';
import Journeys from '../screens/Journeys';
import { JourneyInfoContextWrapper } from '../contexts/JourneyInfoContext';
import Directory from '../screens/Directory';
import StatDash from '../screens/StatsDash';

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

interface RouteShape extends SidebarItem {
  component: RouteProps['component'];
  path: string;
  exact?: boolean;
}

const routes: RouteShape[] = [
  {
    label: 'Today',
    emojiIcon: '🌞',
    component: Welcome,
    path: PATHS.WELCOME,
    exact: true,
  },
  {
    label: 'Clinical',
    emojiIcon: '🩺',
    component: Clinical,
    path: PATHS.CLINICAL,
    subItems: [
      {
        label: 'Post',
        emojiIcon: '🏢',
        path: PATHS.CLINICAL_POST,
      },
      {
        label: 'Archival',
        emojiIcon: '🗂',
        path: PATHS.CLINICAL_ARCHIVED,
      },
      {
        label: 'Research',
        emojiIcon: '🎓',
        path: PATHS.CLINICAL_RESEARCH,
      },
      {
        label: 'Stats',
        emojiIcon: '📈',
        path: PATHS.CLINICAL_STATS,
      },
      {
        label: 'Projection',
        emojiIcon: '🔮',
        path: PATHS.CLINICAL_PROJECTION,
      },
    ],
  },
  {
    label: 'Operations',
    emojiIcon: '📦',
    path: PATHS.OPS,
    component: Ops,
    subItems: [
      {
        label: 'Member shipping',
        emojiIcon: '📬',
        path: PATHS.OPS_MEMBER_SHIPPING,
      },
      {
        label: 'Awaiting samples',
        emojiIcon: '⬅️',
        path: PATHS.OPS_AWAITING_SAMPLES,
      },
      {
        label: 'Lab shipping',
        emojiIcon: '🧪',
        path: PATHS.OPS_LAB_SHIPPING,
      },
      {
        label: 'History',
        emojiIcon: '🕐',
        path: PATHS.OPS_HISTORY,
      },
    ],
  },
  {
    label: 'Reports',
    emojiIcon: '📄',
    component: Report,
    path: PATHS.REPORTS,
  },
  {
    label: 'Directory',
    emojiIcon: '📖',
    component: Directory,
    path: PATHS.DIRECTORY,
    subItems: [
      {
        label: 'Doctors',
        emojiIcon: '🩺',
        path: PATHS.DOCTORS,
      },
      {
        label: 'Clinics',
        emojiIcon: '🩻',
        path: PATHS.CLINICS,
      },
      {
        label: 'Counsellors',
        emojiIcon: '💙',
        path: PATHS.COUNSELLORS,
      },
    ],
  },
  {
    label: 'Tasks',
    emojiIcon: '👩‍💻',
    component: Tasks,
    path: PATHS.TASKS,
    subItems: [
      {
        emojiIcon: '📑',
        label: 'Download reports',
        path: PATHS.TASKS_DOWNLOAD_REPORTS,
      },
      {
        emojiIcon: '✍️',
        label: 'Write reports',
        path: PATHS.TASKS_WRITE_REPORTS,
      },
      {
        emojiIcon: '🧐',
        label: 'Review reports',
        path: PATHS.TASKS_REVIEW_REPORTS,
      },
      {
        emojiIcon: '🛠',
        label: 'Amended reports',
        path: PATHS.TASKS_AMENDED_REPORTS,
      },
      {
        label: 'Approve orders',
        emojiIcon: '✅',
        path: PATHS.TASKS_APPROVE_ORDERS,
      },
      {
        label: 'Lab orders',
        emojiIcon: '🧪',
        path: PATHS.TASKS_LAB_ORDERS,
      },
      {
        label: 'Sales leads',
        emojiIcon: '🚀',
        path: PATHS.TASKS_SALES_LEADS,
      },
      {
        label: 'Match doctors',
        emojiIcon: '🩺',
        path: PATHS.TASKS_MATCH_DOCTORS,
      },
    ],
  },
];

const footerRoutes: RouteShape[] = [
  {
    label: "What's new",
    emojiIcon: '⭐️',
    component: Updates,
    path: PATHS.UPDATES,
    unreadCallback: getUnreadCount,
  },
  {
    label: 'Stats',
    emojiIcon: '📈',
    path: PATHS.STATS,
    component: StatDash,
    subItems: [
      {
        emojiIcon: '📈',
        label: 'General stats',
        path: PATHS.STATS_GENERAL,
      },
      {
        emojiIcon: '👻',
        label: 'Invitae stats',
        path: PATHS.STATS_INVITAE,
      },
    ],
  },
  {
    label: 'Dev',
    emojiIcon: '⚙️',
    component: Dev,
    path: PATHS.DEV,
    subItems: [
      {
        emojiIcon: '👾',
        label: 'GraphQL Editor',
        path: PATHS.DEV_GRAPHQL,
      },
      {
        emojiIcon: '🖨',
        label: 'PDF Printer',
        path: PATHS.DEV_PDF_PRINTER,
      },
      {
        emojiIcon: '🤖',
        label: 'Report Generator',
        path: PATHS.DEV_REPORT_GENERATOR,
      },
    ],
  },
];

const redirects: { [key: string]: string } = {
  '/journeys': '/clinical',
  '/journeys/phase/yet-to-purchase': '/clinical/phase/yet-to-purchase',
  '/journeys/phase/pre': '/clinical/phase/pre',
  '/journeys/phase/during': '/clinical/phase/during',
  '/journeys/phase/post': '/clinical/phase/post',
  '/journeys/phase/archived': '/clinical/phase/archived',
  '/journeys/stats': '/clinical/stats',
}

const Main = (props: MainProps) => {
  const { store, history } = props;

  return (
    <ErrorBoundary>
      <ApolloProvider client={appSyncClient as any}>
        <Provider store={store}>
          <JourneyInfoContextWrapper>
            <PrivacyContextWrapper>
              <AuthenticationWrapper>
                <ConnectedRouter history={history}>
                  <div className="h-full flex flex-col">
                    <ReduxToastr
                      timeOut={2000}
                      newestOnTop={false}
                      preventDuplicates
                      position="top-right"
                      transitionIn="fadeIn"
                      transitionOut="fadeOut"
                      closeOnToastrClick
                    />
                    <div
                      className="flex w-full font-sans items-stretch lg:flex-col lg:flex-wrap">
                      <Sidebar
                        workspaceItems={routes.map(({ icon, label, path, emojiIcon, subItems, exact, unreadCallback }) => ({
                          icon,
                          label,
                          path,
                          emojiIcon,
                          subItems,
                          exact,
                          unreadCallback,
                        }))}
                        footerItems={footerRoutes.map(({ icon, label, path, emojiIcon, subItems, exact, unreadCallback }) => ({
                          icon,
                          label,
                          path,
                          emojiIcon,
                          subItems,
                          exact,
                          unreadCallback,
                        }))}
                      />
                      <div className="content flex-1 px-40 py-50 print:py-20 lg:flex-auto lg:w-full z-0 relative pt-80 flex flex-col">
                        <ErrorBoundary>
                          <TopToolbar />
                        </ErrorBoundary>
                        <Switch>
                          {routes.map(route => (
                            <Route
                              key={route.path}
                              path={route.path}
                              component={route.component}
                              exact={route.exact}
                            />
                          ))}
                          {footerRoutes.map(route => (
                            <Route
                              key={route.path}
                              path={route.path}
                              component={route.component}
                              exact={route.exact}
                            />
                          ))}
                          <Route
                            path={PATHS.MEMBERS}
                            component={Members}
                          />
                          <Route
                            path={PATHS.JOURNEYS}
                            component={Journeys}
                          />
                          <Route
                            path={PATHS.DOCTORS}
                            component={Doctors}
                          />
                          <Route
                            path={PATHS.SUBMISSIONS}
                            component={Submissions}
                          />
                          <Route exact path="/auth">
                            <Redirect to="/" />
                          </Route>
                          {Object.keys(redirects).map(fromPath => (
                            <Redirect from={fromPath} to={redirects[fromPath]} key={fromPath} exact />
                          ))}
                        </Switch>
                      </div>
                      <JourneyInfo />
                    </div>
                    <BugButton />
                    <FormModal />
                  </div>
                </ConnectedRouter>
              </AuthenticationWrapper>
            </PrivacyContextWrapper>
          </JourneyInfoContextWrapper>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default Main;
