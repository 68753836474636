import * as React from 'react';
import { connect } from 'react-redux';
import { concat, has } from 'lodash';
import { FiRefreshCw } from '../../../components/widgets/Icon';
import { ApplicationState } from '../../../store';
import { DiseaseOverrides, AnalysisOverrides } from '../../../store/report/analysis/types';
import './styles/disease-risk-expansion.scss';
import DiseaseRiskExpansionCarrierFields from './DiseaseRiskExpansionCarrierFields';
import { getDiseaseFieldsMergedWithOverrides, getDiseaseIdForGene, getCMSUrlForDisease } from '../../../store/report/selectors';
import DiseaseRiskExpansionItem from '../../../components/report/analysis/DiseaseRiskExpansionItem';
import { updateAffectedDiseaseData } from '../../../store/report/client-details/actions';
import { ThunkDispatch } from 'redux-thunk';
import Button from '../../../components/widgets/Button';
import ButtonGroup from '../../../components/widgets/ButtonGroup';
import { Disease } from '../../../../graphql/genie-api-types';

interface OwnProps {
  gene: string;
  areBothClientsCarriers: boolean;
}

export interface DiseaseRiskExpansionProps extends OwnProps {
  onFieldClick(fieldName: string): void;
  onFieldResetClick(fieldName: string): void;
}

interface StateProps extends DiseaseOverrides {
  fieldOverrides: AnalysisOverrides;
  contentfulUrl: string;
  diseaseId: string;
}

interface DispatchProps {
  onUpdateDiseaseData(): void;
}

const editableFields = [
  'name',
  'description',
  'resources',
  'carrierInfo',
];

const editableIndividualCarrierFields = [
  'individualCarrierInfoForCouple',
  'individualCarrierNextSteps',
];

const editableCoupleCarrierFields = [
  'affectedCoupleInfo',
  'affectedCoupleOptions',
  'affectedCoupleActions',
];

interface AllProps extends StateProps, DiseaseRiskExpansionProps, DispatchProps { }

class DiseaseRiskExpansion extends React.PureComponent<AllProps> {
  render() {
    const { areBothClientsCarriers, onFieldClick, onFieldResetClick, gene, contentfulUrl } = this.props;
    const fields: string[] = areBothClientsCarriers ?
      concat(editableFields, editableCoupleCarrierFields) :
      concat(editableFields, editableIndividualCarrierFields);

    return (
      <div className="disease-risk-expansion pt-10">
        <DiseaseRiskExpansionCarrierFields
          onEditClick={(fieldName: string) => onFieldClick(fieldName)}
          onResetClick={(fieldName: string) => onFieldResetClick(fieldName)}
          gene={gene}
        />
        <div className="disease-risk-expansion__toolbar">
          <h2>Content for {this.props['name']}</h2>
          <ButtonGroup className="disease-risk-expansion__edit-buttons">
            <Button onClick={() => window.open(contentfulUrl)} variant="outline-primary">
              Edit in contentful
            </Button>
            <Button onClick={this.props.onUpdateDiseaseData} variant="outline-primary">
              <FiRefreshCw />
            </Button>
          </ButtonGroup>
        </div>
        {fields.map((fieldName: keyof Disease) => {
          const isOverridden = has(this.props.fieldOverrides, fieldName);
          const fieldValue = this.props[fieldName as keyof DiseaseOverrides];

          return (
            <DiseaseRiskExpansionItem
              key={fieldName}
              diseaseId={this.props.diseaseId}
              onResetClick={() => onFieldResetClick(fieldName)}
              onEditClick={() => onFieldClick(fieldName)}
              isOverridden={isOverridden}
              fieldName={fieldName}
              fieldValue={fieldValue}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = ({ report }: ApplicationState, ownProps: OwnProps): StateProps => {
  const diseaseId = getDiseaseIdForGene(ownProps.gene, report.clientDetails);
  return {
    ...getDiseaseFieldsMergedWithOverrides(ownProps.gene, report.analysis, report.clientDetails, report.carrierRates),
    contentfulUrl: getCMSUrlForDisease(ownProps.gene, report.clientDetails),
    fieldOverrides: report.analysis.fieldOverrides[diseaseId],
    diseaseId,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onUpdateDiseaseData: () => dispatch(updateAffectedDiseaseData()),
});

export default connect<StateProps, DispatchProps, DiseaseRiskExpansionProps>(mapStateToProps, mapDispatchToProps)(DiseaseRiskExpansion);
