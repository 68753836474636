import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { FlagType, FlagPriority, FlagCode } from '../../../graphql/genie-api-types';
import FormInput from '../widgets/form/FormInput';
import { FLAG_TYPE_LABELS, FLAG_PRIORITY_LABELS, FLAG_CODE_LABELS } from '../../../utilities/flags/flagLabels';

export type FlagFormValues = {
  flagType?: FlagType;
  priority?: FlagPriority;
  code?: FlagCode;
}

interface FlagFormProps extends Pick<BaseFormProps, 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: FlagFormValues;
  journeyId: string;
}

const FlagForm = (props: FlagFormProps) => {
  return (
    <BaseForm
      {...props}
      submitButtonLabel="Save"
    >
      {({
        values,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <div>
            <FormInput
              label="Type"
              as="select"
              name="flagType"
              type="text"
              value={values['flagType']}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              options={[
                {
                  value: '',
                  label: '- Select an option -',
                },
                ...Object.keys(FLAG_TYPE_LABELS).map((flagType: FlagType) => ({
                  value: flagType,
                  label: FLAG_TYPE_LABELS[flagType],
                }))
              ]}
            />
            <FormInput
              label="Type"
              as="select"
              name="priority"
              type="text"
              value={values['priority']}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              options={[
                {
                  value: '',
                  label: '- Select an option -',
                },
                ...Object.keys(FLAG_PRIORITY_LABELS).map((flagPriority: FlagPriority) => ({
                  value: flagPriority,
                  label: FLAG_PRIORITY_LABELS[flagPriority],
                }))
              ]}
            />
            <FormInput
              label="Code"
              as="select"
              name="code"
              type="text"
              value={values['code']}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              options={[
                {
                  value: '',
                  label: '- Select an option -',
                },
                ...Object.keys(FLAG_CODE_LABELS).map((flagCode: FlagCode) => ({
                  value: flagCode,
                  label: FLAG_CODE_LABELS[flagCode],
                }))
              ]}
            />
          </div>
        );
      }}
    </BaseForm>
  );
}

export default FlagForm;
