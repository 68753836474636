import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Counsellor, Query } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import Select from '../../../components/widgets/Select';

interface CounsellorSelectProps {
  onChange(counsellorId: Counsellor['id']): void;
  selectedValue: string;
}

const LIST_COUNSELLORS_QUERY = gql`
   query ListCounsellors {
    listCounsellors {
      id
      name
    }
  }
`;

const CounsellorSelect = (props: CounsellorSelectProps) => {
  const counsellorQuery = useQuery<Pick<Query, 'listCounsellors'>, {}>(LIST_COUNSELLORS_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Select
      hasBlankValue
      onChange={props.onChange}
      disabled={counsellorQuery.loading}
      options={counsellorQuery?.data?.listCounsellors?.map(counsellor => ({
        value: counsellor.id,
        label: counsellor.name,
      })) ?? []}
      selectedValue={props.selectedValue}
    />
  );
}

export default CounsellorSelect;
