import * as React from 'react';
import moment from 'moment';
import Table, { Row, TableProps } from './Table';
import Badge from './Badge';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { FiShuffle, FiUserMinus, FiPauseCircle, BSExclamationSquareFill } from './Icon';
import { JourneyStateAction } from '../../../utilities/journeys/states';
import { Action } from './DropdownButton';
import { Journey, Request, ProductVariant } from '../../../graphql/genie-api-types';
import PregnancyIcon from './PregnancyIcon';
import ErrorBoundary from '../../base/ErrorBoundary';
import { getActionsForJourney } from '../../../utilities/journeys/actions';
import { applyActionToJourneys } from '../../store/journeys/actions';
import PrivateContent from './PrivateContent';
import Tooltip from './Tooltip';
import { isTwoPersonTest } from '../../../utilities/request/request-helpers';
import RecollectionIcon from './RecollectionIcon';
import MemberOrPlaceholder from '../member/MemberOrPlaceholder';
import JourneyInfoContext from '../../contexts/JourneyInfoContext';
import { formatMemberName } from '../../../utilities/helpers';
import _ from 'lodash';
import { journeySortBy } from '../../graphql/containers/flags/Flags';
import { useMemo } from 'react';

export type JourneyActionName = JourneyStateAction['type'] | '_move' | '_edit' | '_onHold';

export type JourneyTableOnHoldStatus = true | false | '1/2';

// Minimum required fields for each journey row.
export interface JourneyRowPartial extends Partial<Journey> {
}

export interface JourneyTableProps<JourneyRow extends JourneyRowPartial> extends Pick<TableProps, 'emptyText' | 'isLoading' | 'header' | 'columnGroups' | 'alwaysShowActions' | 'onRefresh' | 'shouldPersistRefreshButton'> {
  journeysByRequestId: { [key: string]: JourneyRow[] };
  orderedRequestIds: Request['id'][];
  actionsForJourney?(journey: JourneyRow): Action[];
  showActionsForJourney?(journey: JourneyRow): boolean;
  cellsForJourney(journey: JourneyRow): Row['cells'];
  onActionClick?(rowIds: string[], actionName: string, actionValue: string): void | Promise<void>;
  onActionComplete?(rowIds: string[], actionName: string, actionValue: string): Promise<void>;
  onHoldStatus?: JourneyTableOnHoldStatus;
  onToggleOnHold?(): void;
  sortBy?: journeySortBy;
  getFlagDateCreated?(journey: JourneyRow): string;
}

type AllProps<JourneyRow extends JourneyRowPartial> = JourneyTableProps<JourneyRow>;

const renderIncompleteRequestIcon = (request: JourneyRowPartial['request']) => {
  if (request?.productVariant === ProductVariant.Couple && request?.journeys?.length < 2) {
    return (
      <span title="Missing partner details" className="inline-block align-middle">
        <FiUserMinus className="w-4 h-4 ml-4 text-grey-dark align-text-bottom" />
      </span>
    );
  }

  return null;
};

const renderOnHoldIcon = (request: JourneyRowPartial['request']) => {
  if (request?.onHoldDate ?? '') {
    return (
      <span title={`On hold since ${moment(request.onHoldDate).format('Do MMM YYYY')}`}>
        <FiPauseCircle className="w-4 h-4 ml-4 mb-1 text-grey-dark text-grey-dark align-text-bottom" />
      </span>
    );
  }

  return null;
};

const renderImportantNotes = (request: JourneyRowPartial['request']) => {
  const notes = request?.notes ?? [];
  if (notes.length > 0) {
    return (
      <Tooltip label={request.notes?.map(note => note.text).join("\n")}>
        <BSExclamationSquareFill className="text-orange ml-5" />
      </Tooltip>
    );
  }

  return null;
};

const renderOnlyThePartnerBadge = (journey: Partial<Journey>) => {
  if (!isTwoPersonTest(journey?.request?.productVariant)) {
    return null;
  }

  if (journey?.request?.journeys?.length > 1) {
    return null;
  }

  if (!journey?.request?.initiatingMember || journey?.member?.id === journey?.request?.initiatingMember?.id) {
    return null;
  }

  return (
    <Badge color="orange" textColor="white" className="font-bold ml-10 text-sm">PARTNER</Badge>
  );
};

type JourneyIdsLoading = { [key: string]: boolean };


function JourneyTable<JourneyRow extends JourneyRowPartial>(props: AllProps<JourneyRow>) {
  const { onSelectJourney } = React.useContext(JourneyInfoContext);
  const [journeyIdsLoading, setJourneyIdsLoading] = React.useState({} as JourneyIdsLoading);
  const dispatch = useDispatch();
  const {
    onActionClick,
    onActionComplete,
    onToggleOnHold,
    onHoldStatus,
    sortBy,
  } = props;

  const markJourneysAsLoading = React.useCallback((journeyIds: Journey['id'][]) => {
    const newJourneyLoadingState = {
      ...journeyIdsLoading,
    };

    journeyIds.forEach(journeyId => {
      newJourneyLoadingState[journeyId] = true;
    });

    setJourneyIdsLoading(newJourneyLoadingState);
  }, [journeyIdsLoading, setJourneyIdsLoading]);

  const markJourneysAsNotLoading = React.useCallback((journeyIds: Journey['id'][]) => {
    const newJourneyLoadingState = {
      ...journeyIdsLoading,
    };

    journeyIds.forEach(journeyId => {
      newJourneyLoadingState[journeyId] = false;
    });

    setJourneyIdsLoading(newJourneyLoadingState);
  }, [journeyIdsLoading, setJourneyIdsLoading]);

  const onRowActionClick = React.useCallback(async (rowIds: string[], actionName: JourneyActionName, actionValue: string) => {
    markJourneysAsLoading(rowIds);

    try {
      if (onActionClick) {
        await onActionClick(rowIds, actionName, actionValue);
      }

      await applyActionToJourneys(actionName, actionValue, rowIds, dispatch);
      if (onActionComplete) {
        await onActionComplete(rowIds, actionName, actionValue);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      markJourneysAsNotLoading(rowIds);
    }
  }, [markJourneysAsNotLoading, markJourneysAsLoading, onActionClick, onActionComplete]);

  const renderShuffleIcons = (journey: JourneyRowPartial, journeys: Partial<JourneyRowPartial>[], request: JourneyRowPartial['request']) => {
    if ((journey?.request?.journeys ?? []).length <= journeys.length) {
      return null;
    }

    const journeyIds = journeys.map(journey => journey.id);
    const missingJourneys = request.journeys.filter(journey => !journeyIds.includes(journey.id));

    if (missingJourneys.length === 0) {
      return null;
    }

    return missingJourneys.map(journey => (
      <button
        title={`${formatMemberName(journey.member, request)} (${startCase(journey.state)})`}
        onClick={(e) => {
          onSelectJourney(journey.id);
          e.stopPropagation();
        }}
        className="mr-5 inline-block align-middle"
        key={journey.id}
      >
        <FiShuffle className="w-4 h-4 ml-4 text-grey-dark align-text-bottom" />
      </button>
    ));
  };

  const renderPeopleHeader = React.useCallback(() => {
    return (
      <div className="flex items-start">
        <span>
          People
        </span>
        {onToggleOnHold ? (
          <button
            type="button"
            className="ml-5 flex items-center focus:outline-none"
            onClick={onToggleOnHold}
          >
            <FiPauseCircle style={!onHoldStatus ? { opacity: 0.5 } : {}} />
          </button>
        ) : null}
      </div>
    );
  }, [onToggleOnHold, onHoldStatus]);

  const { alwaysShowActions, header, isLoading, columnGroups, showActionsForJourney, emptyText, onRefresh, orderedRequestIds, journeysByRequestId, cellsForJourney, getFlagDateCreated, actionsForJourney, shouldPersistRefreshButton } = props;

  const rowGroup =  useMemo(() => {
    const result = orderedRequestIds.map((requestId) => {
      const journeys = journeysByRequestId[requestId];
      return {
        id: requestId,
        showGroupIndicator: journeys?.[0]?.request?.journeys?.length > 1,
        rows: journeys?.map(journey => ({
          id: journey.id,
          memberName: journey.member?.name,
          flagCreated: getFlagDateCreated ? getFlagDateCreated(journey) : "",
          actions: !showActionsForJourney || showActionsForJourney(journey) ? getActionsForJourney(journey, actionsForJourney) : [],
          cells: [
            (
              <span key="member-badge-cell">
                <MemberOrPlaceholder member={journey.member}>
                  {(member) => (
                    <Badge><PrivateContent>{formatMemberName(member, journey.request)}</PrivateContent></Badge>
                  )}
                </MemberOrPlaceholder>
                {journey?.request?.pregnancy?.dueDate ? <PregnancyIcon pregnancy={journey?.request?.pregnancy} /> : null}
                {renderShuffleIcons(journey, journeys, journey.request)}
                {renderIncompleteRequestIcon(journey.request)}
                {renderOnHoldIcon(journey.request)}
                {journey.hasRecollection && <RecollectionIcon />}
                {renderImportantNotes(journey.request)}
                {renderOnlyThePartnerBadge(journey)}
              </span>
            ),
            ...cellsForJourney(journey),
          ],
        })) ?? [],
      };
    })

    return result;
  }, [orderedRequestIds]);

  let sortedRowGroup = null;
  switch(sortBy) {
    case "dateASC":
      sortedRowGroup = _.orderBy([...rowGroup], item => item.rows[0].flagCreated, ['asc']);
      break;
    case "dateDESC":
      sortedRowGroup = _.orderBy([...rowGroup], item => item.rows[0].flagCreated, ['desc']);
      break;
    default:
      // default to sort by initiating member name
      sortedRowGroup = _.orderBy([...rowGroup], item => item.rows[0].memberName, ['asc']);
      break;
  }

  if(!sortedRowGroup) {
    return <p>Loading...</p>;
  }

  return (
    (
      <ErrorBoundary>
        <div>
          <Table
            header={[renderPeopleHeader(), ...header]}
            isLoading={isLoading}
            emptyText={emptyText}
            onRowSelect={onSelectJourney}
            onActionClick={(rowIds, actionName: JourneyActionName, actionValue) => onRowActionClick(rowIds, actionName, actionValue)}
            hasBatchSelect
            disabledRowIds={journeyIdsLoading}
            columnGroups={columnGroups}
            columnClasses={['w-1/2']}
            alwaysShowActions={alwaysShowActions}
            shouldPersistRefreshButton={shouldPersistRefreshButton}
            onRefresh={onRefresh}
            rowGroups={sortedRowGroup}
          />
        </div>
      </ErrorBoundary>
    )
  );
}

export default JourneyTable;
