import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import * as React from 'react';
import { upperFirst, lowerCase, get, cloneDeep, set } from 'lodash';
import { FiAlertTriangle } from '../widgets/Icon';
import ajv, { RequiredParams } from 'ajv';
import Button from '../widgets/Button';

interface SchemaTreeFormProps {
  jsonSchema: JSONSchema7;
  rootObjectLabel: string;
  values: object;
  onChange(values: object): void;
  onChangeValidation(isValid: boolean): void;
  fieldHelpTexts?: {[key: string]: string};
  hiddenFields?: string[];
  readOnlyFields?: string[];
  preventArrayAdditionRefs?: string[];
  suffixForField?(fieldName: string): React.ReactNode|null;
}

const inputClass = 'border-b focus:outline-none focus:border-purple-mid py-3 px-0 font-bold text-purple';
const inputStyle: React.CSSProperties = { height: 'auto' };

const SchemaTreeForm = (props: SchemaTreeFormProps) => {
  const { rootObjectLabel, jsonSchema, values, onChange, onChangeValidation, fieldHelpTexts, hiddenFields, preventArrayAdditionRefs, suffixForField } = props;
  const { definitions } = jsonSchema;
  const [focusedInput, setFocusedInput] = React.useState('');

  const getRowClass = (fieldName: string) =>
    `hover:opacity-1 ${!focusedInput || fieldName === focusedInput ? 'opacity-100' : 'opacity-50'} ${fieldName === focusedInput ? 'bg-grey-light' : ''}`;

  const getObjectByRef = (ref: string) => {
    const dotPath = ref.replace('#/', '').replace('/', '.');

    return get(props.jsonSchema, dotPath);
  };
  
  const validator = React.useMemo(() => {
    const ajvInstance = new ajv({allErrors: true});
    return ajvInstance.compile(jsonSchema);
  }, [jsonSchema]);
  
  const fieldsInError = React.useMemo(() => {
    validator(values);
    return validator.errors?.map(error => {
      const errorPath = error.dataPath.slice(1);
      
      // If nested object fields fail validation, fail the field instead of the entire object.
      if (error.keyword === 'required' && (error?.params as RequiredParams).missingProperty) {
        return `${errorPath ? `${errorPath}.` : ''}${(error?.params as RequiredParams).missingProperty}`;
      }
      
      return errorPath;
    }) ?? [];
  }, [values, validator]);
  
  React.useEffect(() => {
    onChangeValidation(fieldsInError.length === 0);
  }, [fieldsInError, onChangeValidation]);
  
  const onFocus = React.useCallback((fieldName: string) => () => {
    setFocusedInput(fieldName);
  }, [setFocusedInput]);

  const onBlur = React.useCallback((fieldName: string) => () => {
    if (focusedInput === fieldName) {
      setFocusedInput(null);
    }
  }, [setFocusedInput, focusedInput]);

  const onAddToArray = React.useCallback((fieldName) => () => {
    const newValues = cloneDeep(values);
    const currentArrayValue: any[] = get(newValues, fieldName) || [];
    currentArrayValue.push({});
    set(newValues, fieldName, currentArrayValue);
    onChange(newValues);
  }, [onChange, values, fieldsInError]);

  const onRemoveFromArray = React.useCallback((fieldName: string, index: number, allowsArrayAdditions: boolean) => () => {
    if (!allowsArrayAdditions) {
      const confirmed = window.confirm('Once you remove this item you won\'t be able to add a new one to replace it. Continue?');
      if (!confirmed) {
        return;
      }
    }
    const newValues = cloneDeep(values);
    const currentArrayValue: any[] = get(newValues, fieldName) || [];
    currentArrayValue.splice(index, 1);
    set(newValues, fieldName, currentArrayValue);
    onChange(newValues);
  }, [onChange, values, fieldsInError]);

  const onChangeInput = React.useCallback((fieldName: string, schema: JSONSchema7) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const type = schema.type;

    let value: number | string | boolean = e.target.value;
    switch (type) {
      case 'number':
        value = value === '' ? undefined : parseInt(value);
        break;
      case 'boolean':
        value = value === '1';
        break;
      default:
        value = value === '' ? undefined : value;
    }

    const newValues = cloneDeep(values);
    set(newValues, fieldName, value);
    onChange(newValues);
  }, [onChange, values, fieldsInError]);

  const getValue = React.useCallback((fieldName, schema: JSONSchema7) => {
    const type = schema.type;
    let value: number | string | boolean = get(values, fieldName) || '';
    switch (type) {
      case 'number':
        value = (value as any as number).toString();
        break;
      case 'boolean':
        value = value ? '1' : '0';
        break;
    }
    return value;
  }, [values]);

  const renderArrayField = React.useCallback((schema: JSONSchema7, fieldName: string, fieldKey: string): React.ReactNode | null => {
    if (typeof schema === 'boolean') {
      return null;
    }
    const items = schema.items as JSONSchema7;
    const itemLabel = upperFirst(lowerCase(fieldKey));
    const fieldSchema = items.$ref ? getObjectByRef(items.$ref) : items;

    if (typeof items !== 'object') {
      console.error(`${typeof items} not supported in items of array field`);
      return null;
    }

    const fieldValues: any[] = get(values, fieldName) || [];
    const itemCount = fieldValues.length;
    const allowsArrayAdditions = !preventArrayAdditionRefs.includes(items.$ref);

    const fields: React.ReactNode[] = [];
    for (let i = 0; i < itemCount; i++) {
      const label = `${itemLabel}: Item ${i + 1}`;
      const subFieldName = `${fieldName}[${i}]`;
      const labelAction = (
        <Button variant="danger" size="sm" onClick={onRemoveFromArray(fieldName, i, allowsArrayAdditions)} className="ml-10">
          Remove
        </Button>
      );
      const field = renderField(fieldSchema, '', subFieldName, label, labelAction);
      fields.push(field);
    }

    return (
      <div>
        {fields}
        {allowsArrayAdditions && (
          <Button variant="success" size="sm" className="mt-10 ml-20 mb-10" onClick={onAddToArray(fieldName)}>
            + Add {itemLabel}
          </Button>
        )}
      </div>
    );

  }, [focusedInput, values, preventArrayAdditionRefs]);

  const renderInputField = React.useCallback((inputType: 'text' | 'number', schema: JSONSchema7, fieldName: string, key: string) => {
    const { readOnlyFields } = props;
    const isReadOnly = readOnlyFields?.includes(key);
    if (schema?.enum) {
      return (
        <select
          className={inputClass}
          style={inputStyle}
          onFocus={onFocus(fieldName)}
          onBlur={onBlur(fieldName)}
          onChange={onChangeInput(fieldName, schema)}
          name={fieldName}
          data-type={inputType}
          autoComplete="off"
          value={getValue(fieldName, schema)}
        >
          <option value="" />
          {schema.enum.map(option => (
            <option value={option as string} key={option as string}>
              {upperFirst(option as string)}
            </option>
          ))}
        </select>
      );
    }
    return (
      <input
        type={inputType}
        className={inputClass}
        style={inputStyle}
        placeholder="(no value)"
        onFocus={onFocus(fieldName)}
        onBlur={onBlur(fieldName)}
        onChange={onChangeInput(fieldName, schema)}
        name={fieldName}
        data-type={inputType}
        autoComplete="off"
        value={getValue(fieldName, schema)}
        readOnly={isReadOnly}
      />
    );
  }, [focusedInput, values]);

  const renderStringField = React.useCallback((schema: JSONSchema7, fieldName: string, key: string) => {
    return renderInputField('text', schema, fieldName, key)
  }, [focusedInput, values]);

  const renderBooleanField = React.useCallback((schema: JSONSchema7, fieldName: string) => {
    return (
      <select
        className={inputClass}
        style={inputStyle}
        onFocus={onFocus(fieldName)}
        onBlur={onBlur(fieldName)}
        onChange={onChangeInput(fieldName, schema)}
        name={fieldName}
        value={getValue(fieldName, schema)}
        data-type="number"
        autoComplete="off"
      >
        <option value="" />
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>
    );
  }, [focusedInput, values]);

  const renderNumberField = React.useCallback((schema: JSONSchema7, fieldName: string, key: string) => {
    return renderInputField('number', schema, fieldName, key);
  }, [focusedInput, values]);

  const renderField = React.useCallback((schema: JSONSchema7Definition, key = '', path = '', labelOverride = '', labelAction: React.ReactNode = null): React.ReactNode => {
    if (typeof schema === 'boolean') {
      return null;
    }
    const { properties, type, $ref } = schema;

    const fieldName = [path, key].filter(item => item.length > 0).join('.');

    const subFields = Object.entries(properties ?? {}).map(([subFieldKey, subFieldSchema]) => {
      return renderField(subFieldSchema, subFieldKey, fieldName);
    });
    
    if ($ref) {
      return renderField(getObjectByRef($ref), key, path); 
    }

    const label = labelOverride || upperFirst(lowerCase(key));

    let field;
    let childFields;

    switch (type) {
      case 'string':
        field = renderStringField(schema, fieldName, key);
        break;
      case 'boolean':
        field = renderBooleanField(schema, fieldName);
        break;
      case 'number':
        field = renderNumberField(schema, fieldName, key);
        break;
      case 'array':
        childFields = renderArrayField(schema, fieldName, key);
        break;
    };
    
    const inError = fieldsInError.includes(fieldName);
    const helpText = fieldHelpTexts?.[key];
    const isHidden = hiddenFields.includes(key);

    return (
      <div className={`pl-20 rounded py-10 ${inError ? 'text-orange-dark' : ''} ${isHidden ? 'hidden' : ''}`} key={fieldName}>
        <div className={`flex hover:bg-grey-light py-5 items-center ${getRowClass(fieldName)}`}>
          <div className="flex items-center mr-10 cursor-default" title={fieldName}>
            <span className={`mt-1 ml-5 ${childFields || subFields.length > 0 ? 'font-bold' : ''}`}>{label} <span className="text-grey-dark text-xs hidden">{fieldName}</span></span>
            {labelAction}
          </div>
          <div>
            {field}
          </div>
          {suffixForField && suffixForField(fieldName)}
          {fieldsInError.includes(fieldName) && <FiAlertTriangle className="mx-10" />}
        </div>
        {childFields}
        {subFields}
        {helpText && <div className="text-sm text-grey-dark py-5">{helpText}</div>}
      </div>
    );
  }, [definitions, rootObjectLabel, focusedInput, values, fieldsInError, fieldHelpTexts, hiddenFields, suffixForField]);
  
  return (
    <form>
      {renderField(props.jsonSchema, '', '', rootObjectLabel)}
    </form>
  );
}

export default SchemaTreeForm;
