import { Reducer } from 'redux';
import { ReportRequestState } from './types';

const initialState: ReportRequestState = {
  request: null,
};

const reducer: Reducer<ReportRequestState> = (state = initialState): ReportRequestState => {
  return state;
};

export { reducer as reportRequestReducer };
