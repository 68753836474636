import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { JourneyState } from '../../../graphql/genie-api-types';
import FormJourneyStatesRadios from '../widgets/form/FormJourneyStatesRadios';

interface JourneyMoveFormProps extends Pick<BaseFormProps, 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: Partial<JourneyMoveFormValues>;
}

export interface JourneyMoveFormValues {
  journeyState: JourneyState;
}

const JourneyMoveForm = (props: JourneyMoveFormProps) => (
  <BaseForm
    {...props}
    submitButtonLabel="Move journey"
  >
    {({
      values,
      setFieldValue,
    }) => (
        <FormJourneyStatesRadios
          onSelect={journeyState => setFieldValue('journeyState', journeyState)}
          selectedJourneyState={values.journeyState}
        />
      )
    }
  </BaseForm>
);

export default JourneyMoveForm;
