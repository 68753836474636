import * as React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { curry } from 'lodash';
import RouteButton from '../../widgets/RouteButton';
import PregnancyIcon from '../../widgets/PregnancyIcon';
import { FiMessageSquare, FiMail, FiPhoneCall, FiLink, FiExternalLink } from '../../widgets/Icon';
import { copyToClipboard, formatAddress, formatDateOfBirth, formatSex, getZendeskSearchUrl, formatMemberName, url } from '../../../../utilities/helpers';
import ZendeskIcon from '../../widgets/icons/ZendeskIcon';
import PrivateContent from '../../widgets/PrivateContent';
import DropdownButton from '../../widgets/DropdownButton';
import { navigate } from '../../../store/router/actions';
import MemberOrPlaceholder from '../../member/MemberOrPlaceholder';
import { PATHS } from '../../../store/router/types';
import JourneyInfoContext from '../../../contexts/JourneyInfoContext';

const onCopyValue = (value: string, e: MouseEvent) => {
  e.preventDefault();
  copyToClipboard(value);
}

type JourneyInfoActions = 'edit-request' | 'edit-member' | 'edit-journey';

const JourneyInfoHeader = () => {
  const { journey } = React.useContext(JourneyInfoContext);

  const dispatch = useDispatch();

  const { member, request } = journey;
  const isPregnant = !!request?.pregnancy?.dueDate;

  const onClickAction = React.useCallback((actionName: JourneyInfoActions) => {
    switch (actionName) {
      case 'edit-member':
        dispatch(navigate.toForm('MemberUpdate', { memberId: member.id }));
        break;
      case 'edit-request':
        dispatch(navigate.toForm('RequestUpdate', { requestId: request.id }));
        break;
      case 'edit-journey':
        dispatch(navigate.toForm('JourneyUpdate', { journeyId: journey.id }));
        break;
    }
  }, [dispatch, member, request]);

  return (
    <div className="sticky top-0 bg-white flex items-center px-20 pt-20 pb-10 z-10">
      <div className="w-full group">
        <h3 className="text-black font-nm text-lg">
          <div className="flex justify-between flex-1">
            <RouteButton isUnstyled path={`/journeys/${journey.id}`} className="hover:underline text-left">
              <MemberOrPlaceholder member={member}>
                {(member) => (
                  <PrivateContent>{formatMemberName(member, request)}<br /><span className="text-sm text-grey-dark whitespace-no-wrap">{formatDateOfBirth(member.dateOfBirth)} {formatSex(member.sex)} (at birth)</span></PrivateContent>
                )}
              </MemberOrPlaceholder>
              {isPregnant ? <PregnancyIcon pregnancy={request.pregnancy} /> : null}
            </RouteButton>
            <DropdownButton<JourneyInfoActions>
              actions={[
                {
                  label: 'Edit request',
                  name: 'edit-request',
                  variant: 'primary',
                },
                {
                  label: 'Edit member',
                  name: 'edit-member',
                  variant: 'primary',
                },
                {
                  label: 'Edit journey',
                  name: 'edit-journey',
                  variant: 'primary',
                }
              ]}
              onActionClick={onClickAction}
              className="invisible group-hover:visible"
            />
          </div>
        </h3>
        <p className="text-grey-dark font-nm align-middle text-sm mt-2">Journey started on {moment(journey.startDate).format('Do MMMM YYYY')}</p>
        {journey?.addressCity &&
          <p className="text-grey-dark font-nm align-middle text-sm mt-2">
            Location: {formatAddress(journey.addressCity, journey.addressProvince, journey.addressCountryCode)}
          </p>
        }
        {member && (
          <div className="mt-15 flex">
            <a href={`sms:${member.phoneNumber}`} onClick={curry(onCopyValue)(member.phoneNumber)}><FiMessageSquare className="w-6 h-6 align-text-bottom mr-10 text-grey" /></a>
            <a href={`mailto:${member.email}`} onClick={curry(onCopyValue)(member.email)}><FiMail className="w-6 h-6 align-text-bottom mr-10 text-green-dark" /></a>
            <a href={`tel:${member.phoneNumber}`} onClick={curry(onCopyValue)(member.phoneNumber)}><FiPhoneCall className="w-6 h-6 align-text-bottom mr-10 text-green-dark" /></a>
            <a href={getZendeskSearchUrl(member.email || member.name)} target="_blank" rel="noopener noreferrer"><ZendeskIcon className="w-6 h-6 align-text-bottom mr-10 text-green-dark" /></a>
            <a href={PATHS.JOURNEY_SHOW.replace(':journeyId', journey.id)} onClick={curry(onCopyValue)(url(PATHS.JOURNEY_SHOW.replace(':journeyId', journey.id)))}><FiLink className="w-6 h-6 align-text-bottom text-purple hover:text-blue" /></a>
            <a href={PATHS.JOURNEY_SHOW.replace(':journeyId', journey.id)} target="_blank" rel="noopener noreferrer"><FiExternalLink className="w-6 h-6 ml-4 align-text-bottom text-purple hover:text-blue" /></a>
          </div>
        )}
      </div>
    </div>
  );
}

export default JourneyInfoHeader;
