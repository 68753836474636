import { Reducer } from 'redux';
import { SummaryState, SummaryActionTypes } from './types';

export const initialState: SummaryState = {
  reproductiveRisk: '',
  whatWeKnow: '',
  whatWeFound: '',
  quickNote: '',
  importantNote: '',
  fieldOverrides: {},
  isLoadingPseudoDiseases: false,
};

const reducer: Reducer<SummaryState> = (state = initialState, action) => {
  switch (action.type) {
    case SummaryActionTypes.CHANGE_FIELD:
      const { fieldName, fieldValue }: { fieldName: keyof SummaryState; fieldValue: string } = action.payload;

      return {
        ...state,
        fieldOverrides: {
          ...state.fieldOverrides,
          [fieldName]: fieldValue,
        },
      };

    case SummaryActionTypes.RESET_FIELD:
      const resetFieldName: keyof SummaryState = action.payload;
      // eslint-disable-next-line
      const { [resetFieldName]: removedField, ...fieldOverrides } = state.fieldOverrides;

      return {
        ...state,
        fieldOverrides,
      };

    case SummaryActionTypes.UPDATE_DEFAULT_QUICK_NOTE:
      return {
        ...state,
        quickNote: action.payload,
      };

    case SummaryActionTypes.UPDATE_DEFAULT_WHAT_WE_FOUND:
      return {
        ...state,
        whatWeFound: action.payload,
      };

    case SummaryActionTypes.RECEIVE_PSEUDO_DISEASES_BEGIN:
      return {
        ...state,
        isLoadingPseudoDiseases: true,
      };

    case SummaryActionTypes.RECEIVE_PSEUDO_DISEASES_COMPLETE:
      return {
        ...state,
        isLoadingPseudoDiseases: false,
      };

    default:
      return state;
  }
};

export { reducer as summaryReducer };
