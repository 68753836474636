import React from 'react';
import { connect } from 'react-redux';
import { MemberSearchState } from '../../store/search/types';
import { changeMemberSearch } from '../../store/search/actions';
import { ApplicationState } from '../../store';
import SearchForm from '../../components/widgets/SearchForm';
import { Dispatch } from 'redux';

interface OwnProps {
  isLoading?: boolean;
}

interface DispatchProps {
  onChangeSearchTerm(searchTerm: string): void;
}

interface AllProps extends OwnProps, DispatchProps, MemberSearchState { }

const MemberSearch = (props: AllProps) => (
  <div className="max-w-3xl">
    <SearchForm
      onSearchChange={props.onChangeSearchTerm}
      isLoading={props.isLoading}
      defaultSearchTerm={props.searchTerm || ''}
      placeholder="Search by name, dob (yyyy-mm-dd), rq# ..."
      autoFocus
    />
  </div>
);

const mapStateToProps = ({ search }: ApplicationState) => ({
  ...search.memberSearchState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeSearchTerm: (searchTerm: string) => dispatch(changeMemberSearch({ searchTerm })),
});

export default connect<MemberSearchState, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(MemberSearch);
