import * as React from 'react';
import { uniqBy } from 'lodash';
import gql from 'graphql-tag';
import { Kit, Journey, Member, Request, Pregnancy, Counsellor, KitScope, QueryListKitsArgs } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import JourneyTable from '../../../components/widgets/JourneyTable';
import { Collapsible } from '../../../components/widgets/Collapsible';
import { JourneyStateAction } from '../../../../utilities/journeys/states';
import { Action } from '../../../components/widgets/DropdownButton';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import BooleanIcon from '../../../components/widgets/BooleanIcon';
import { getProductIcon } from '../../../../utilities/journeys/products';
import FailedSamples from './FailedSamples';

const JOURNEY_FRAGMENT = gql`
  fragment NeedsAttentionKitsJourneyParts on Journey {
    id
    state
    hasConsent
    hasRelevantFamilyHistoryGroupSubmission
    hasRecollection
    labOrderNumber
    member {
      id
      name
      sex
      onlineAccessEnabled
      dateDetailsConfirmedByMember
    }
    request {
      id
      gcApprovalDate
      onHoldDate
      product
      productVariant
      pregnancy {
        dueDate
        dueDateConfirmed
        days
      }
      journeys {
        id
        state
        member {
          id
          name
        }
      }
      primaryCounsellor {
        id
        name
        nickname
        photoURL
      }
      notes(importantOnly: true) {
        text
      }
    }
  }
`;

const LIST_KITS_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        node {
          id
          journey {
            ...NeedsAttentionKitsJourneyParts
          }
        }
      }
    }
  }
  ${JOURNEY_FRAGMENT}
`;

interface KitResultRequest extends Required<Pick<Request, 'id' | 'gcApprovalDate' | 'onHoldDate' | 'product' | 'productVariant'>> {
  pregnancy: Pick<Pregnancy, 'dueDate' | 'dueDateConfirmed' | 'days'>;
  primaryCounsellor: Pick<Counsellor, 'id' | 'name' | 'nickname' | 'photoURL'>;
  journeys: (Required<Pick<Journey, 'id' | 'state'>> & {
    member: Required<Pick<Member, 'id' | 'name'>>;
  })[];
}

interface KitResultJourney extends Pick<Journey, 'id' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission' | 'labOrderNumber' | 'state'> {
  member: Required<Pick<Member, 'id' | 'name' | 'sex' | 'onlineAccessEnabled' | 'dateDetailsConfirmedByMember'>>;
  request: KitResultRequest;
}

interface KitResult extends Pick<Kit, 'id'> {
  journey: KitResultJourney;
}

interface ListKitsResponse {
  listKits: {
    edges: {
      node: KitResult;
    }[];
  };
}

const scopes = [
  KitScope.RequireLabOrder,
  KitScope.RequireConsent,
  KitScope.RequireApproval,
];

const getActionsForJourney = (journey: KitResultJourney): Action[] => {
  const journeyStateActions: JourneyStateAction[] = [];

  if (!journey.labOrderNumber) {
    journeyStateActions.push({
      label: 'Add lab order number',
      type: 'addLabOrderNumber',
      noBatch: true,
    });
  }

  if (!journey.hasConsent) {
    journeyStateActions.push({
      label: 'Consent received',
      type: 'markAsConsented',
    });
  }

  if (!journey.request.gcApprovalDate) {
    journeyStateActions.push({
      label: 'GC Approved',
      type: 'markAsVerified',
      noBatch: true,
    });
  }

  return journeyStateActions.map(journeyStateAction => ({
    label: journeyStateAction.label,
    name: journeyStateAction.type,
    variant: 'success',
    extraProps: journeyStateAction.noBatch ? {
      noBatch: true,
    } : {},
  }));
};

interface NeedsAttentionKitsProps {
  collapsed?: boolean;
  includeFailedSamples?: boolean;
}

const NeedsAttentionKits = (props: NeedsAttentionKitsProps) => {
  const [isLoadingKits, setIsLoadingKits] = React.useState(true);
  const [kits, setKits] = React.useState<KitResult[]>([]);

  const loadKits = async () => {
    setIsLoadingKits(true);
    const kitResponses = await Promise.all(scopes.map(scope =>
      appSyncClient.query<ListKitsResponse, QueryListKitsArgs>({
        query: LIST_KITS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            scope,
          },
        },
      })));

    const kitSets = kitResponses.map(kit => kit.data?.listKits?.edges.map(edge => edge.node) ?? []);
    // eslint-disable-next-line
    const kitResults: KitResult[] = uniqBy([].concat.apply([], kitSets) as KitResult[], 'id');

    setKits(kitResults);
    setIsLoadingKits(false);
  };

  const journeysByRequestId = React.useMemo(() => {
    const result: { [key: string]: KitResultJourney[] } = {};

    kits.forEach(kit => {
      const journey = kit.journey;
      if (!result[journey.request.id]) {
        result[journey.request.id] = [];
      }
      result[journey.request.id].push(journey);
    });

    return result;
  }, [kits]);

  const orderedRequestIds = React.useMemo(() => {
    return Object.keys(journeysByRequestId);
  }, [journeysByRequestId]);

  React.useEffect(() => {
    loadKits();
    ReduxQueryListener.addCallbackListener(loadKits);

    return () => {
      ReduxQueryListener.removeCallbackListener(loadKits);
    };
  }, []);

  return (
    <Collapsible
      title="Ops blockers 🛑"
      className="mt-50"
      defaultExpandedState={!props.collapsed}
    >
      <JourneyTable<KitResultJourney>
        journeysByRequestId={journeysByRequestId}
        orderedRequestIds={orderedRequestIds}
        onActionComplete={loadKits}
        columnGroups={[
          {
            colSpan: 2,
          },
          {
            colSpan: 3,
            content: 'Ops',
          },
          {
            colSpan: 2,
            content: 'Clinical',
          },
        ]}
        header={[
          'Login',
          'FHx',
          'Consent',
          'GC Approval',
          'Invitae',
          'Product',
        ]}
        emptyText="No ops blockers"
        cellsForJourney={(journey: KitResultJourney) => [
          journey?.member?.onlineAccessEnabled ? <BooleanIcon key="login" isChecked={!!journey.member?.dateDetailsConfirmedByMember} /> : <span key="na" className="text-xxs">N/A</span>,
          <BooleanIcon key="FHx" isChecked={!!journey.hasRelevantFamilyHistoryGroupSubmission} />,
          <BooleanIcon key="consent" isChecked={!!journey.hasConsent} />,
          <BooleanIcon key="approval" isChecked={!!journey.request.gcApprovalDate} />,
          <BooleanIcon key="invitae" isChecked={!!journey.labOrderNumber} />,
          getProductIcon(journey.request.product, journey.request.productVariant),
        ]}
        onRefresh={loadKits}
        actionsForJourney={(journey: KitResultJourney) => getActionsForJourney(journey)}
        isLoading={isLoadingKits}
      />
      {props.includeFailedSamples && (
        <div className="mt-30">
          <h2 className="font-bold text-xl">Failed samples for followup</h2>
          <FailedSamples />
        </div>
      )}
    </Collapsible>
  );
};

export default NeedsAttentionKits;
