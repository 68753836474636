import { DiseaseByGeneResult } from '../../../graphql/requests/requestDiseasesByGene';

export enum AnalysisActionTypes {
  TOGGLE_DISEASE_EXPAND = '@@report/analysis/TOGGLE_DISEASE_EXPAND',
  EDIT_DISEASE_FIELD = '@@report/analysis/EDIT_DISEASE_FIELD',
  RESET_DISEASE_FIELD = '@@report/analysis/RESET_DISEASE_FIELD',
  RECEIVE_DISEASES = '@@report/analysis/RECEIVE_DISEASES',
  CHANGE_MODIFICATION = '@@report/analysis/CHANGE_MODIFICATION',
}

export type CarrierRisk = 'High Risk'|'Low Risk'|'Increased Risk'|'Result to note';
export type DiseaseExpansion = { [key: string]: boolean };
export type DiseaseFieldOverrides = { [key: string]: AnalysisOverrides };
export type Modifications = {
  [key: string]: {
    addPathogenicMutationsToCarrierInfo?: boolean;
    addPathogenicMutationsToNextSteps?: boolean;
  };
};

export interface AnalysisState {
  diseaseExpansion: DiseaseExpansion;
  fieldOverrides: DiseaseFieldOverrides;
  modifications: Modifications;
}

export type DiseaseOverrides = Omit<Partial<DiseaseByGeneResult>, 'cms'>;

export interface CarrierRiskOverrides {
  carrierRisk?: CarrierRisk;
  clientACarrierStatus?: string;
  clientBCarrierStatus?: string;
}

export interface AnalysisOverrides extends DiseaseOverrides, CarrierRiskOverrides {
}
