import * as React from 'react';
import { Journey, JourneyState, Member, Pregnancy, Request } from '../../../../graphql/genie-api-types';
import JourneyRows from './JourneyRows';
import { Row } from '../../widgets/Table';
import gql from 'graphql-tag';

interface ArchivedResultsJourneyJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            onHoldDate
            initiatingMember {
              id
            }
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const ArchivedJourneys = () => (
  <JourneyRows<ArchivedResultsJourneyJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.Complete]}
    header={[]}
    emptyText="No journeys archived"
    cellsForJourney={(): Row['cells'] => []}
  />
);

export default ArchivedJourneys;
