import * as React from 'react';
import { connect } from 'react-redux';

import NewSessionForm from '../../components/session/NewSessionForm';
import SessionList from '../../components/session/SessionList';
import Alert from '../../components/widgets/Alert';
import { ApplicationState } from '../../store';
import { createReportSession, getReportSessions, deleteReportSession } from '../../../utilities/report/localStorage';

interface SessionSelectorOwnProps {
  onSelectSession: (sessionName: string) => void;
  defaultState: any;
}

interface SessionSelectorStateProps {
  encryptionKey: string;
}

interface SessionSelectorProps extends SessionSelectorOwnProps, SessionSelectorStateProps {

}

interface SessionSelectorState {
  sessionNames: string[];
}

class SessionSelector extends React.PureComponent<SessionSelectorProps, SessionSelectorState> {
  state: Readonly<SessionSelectorState> = {
    sessionNames: [],
  };

  componentDidMount() {
    const { encryptionKey } = this.props;
    const sessionNames = getReportSessions(encryptionKey);

    this.setState({
      sessionNames,
    });
  }

  onSelectSession = (sessionName: string) => {
    const { onSelectSession } = this.props;
    onSelectSession(sessionName);
  }

  onCreateSession = (sessionName: string) => {
    const { encryptionKey } = this.props;

    createReportSession(sessionName, encryptionKey);
    const sessionNames = getReportSessions(encryptionKey);

    this.setState({
      sessionNames,
    });
  }

  onDeleteSession = (sessionName: string) => {
    const { encryptionKey } = this.props;

    deleteReportSession(sessionName, encryptionKey);
    const sessionNames = getReportSessions(encryptionKey);

    this.setState({
      sessionNames,
    });
  }

  render() {
    const { sessionNames } = this.state;

    return (
      <div>
        {sessionNames ? (
          <SessionList
            sessions={sessionNames}
            onSelectSession={this.onSelectSession}
            onDeleteSession={this.onDeleteSession}
          />
        ) : (
            <Alert variant="warning">No sessions for the given password were found.</Alert>
          )}
        <NewSessionForm
          onSubmit={({ sessionName }) => this.onCreateSession(sessionName)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  encryptionKey: state.storage.encryptionKey,
});

export default connect<SessionSelectorStateProps, {}, SessionSelectorOwnProps>(mapStateToProps)(SessionSelector);
