import * as React from 'react';
import { PATHS } from '../../../store/router/types';
import AnalysisView from './AnalysisView';
import AnalysisEdit from './AnalysisEdit';
import Route from '../../../base/Route';

const Analysis = () => (
  <div>
    <Route path={PATHS.ANALYSIS} component={AnalysisView} />
    <Route path={PATHS.REPORTS_ANALYSIS_EDIT_FIELD} component={AnalysisEdit} />
  </div>
);

export default Analysis;
