import * as React from 'react';
import moment from 'moment';
import { StatsProps } from './Stats';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { ReportsConnection, QueryListReportsArgs, Report } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';

interface ReportResult extends Pick<Report, 'id' | 'dateCreated'> {
  isHighRisk: boolean;
}

const ReportStats = (props: StatsProps) => {
  const [allReports, setAllReports] = React.useState<ReportResult[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { fromDate, toDate } = props;

  const getAllReports = async () => {
    const [allReportResults, highRiskReportResults] = await Promise.all([
      runStatsQuery<ReportsConnection, QueryListReportsArgs>(gql`
      query ListReports($input: ListReportsInput!) {
        listReports(input: $input) {
          edges {
            cursor
            node {
              id
              dateCreated
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
      `, {
        variables: {
          input: {},
        },
      }, 'listReports'),
      runStatsQuery<ReportsConnection, QueryListReportsArgs>(gql`
        query ListReports($input: ListReportsInput!) {
          listReports(input: $input) {
            edges {
              cursor
              node {
                id
                dateCreated
              }
            }
            pageInfo {
              hasNextPage
            }
          }
        }
        `, {
        variables: {
          input: {
            isHighRisk: true,
          },
        },
      }, 'listReports'),
    ]);
    const highRiskReportIds = highRiskReportResults?.edges?.map(edge => edge.node.id) ?? [];
    const reports = allReportResults.edges.map(edge => ({
      ...edge.node,
      isHighRisk: highRiskReportIds.includes(edge.node.id),
    }));
    setAllReports(reports);
    setIsLoading(false);
  };

  React.useEffect(() => { getAllReports(); }, []);

  const [reportCount, highRiskReportCount] = React.useMemo(() => {
    let reportCount = 0;
    let highRiskReportCount = 0;
    
    allReports.filter(report => {
      const reportDate = moment(report.dateCreated).startOf('day');
      return reportDate.isSameOrAfter(fromDate) && reportDate.isSameOrBefore(toDate);
    }).forEach(report => {
      reportCount += 1;
      if (report.isHighRisk) {
        highRiskReportCount += 1;
      }
    });
    
    return [reportCount, highRiskReportCount];
  }, [fromDate, toDate, allReports]);

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
          <table className="w-full">
            <tbody>
              <tr>
                <td>Total reports created</td>
                <td className="text-right font-bold py-10">{reportCount}</td>
              </tr>
              <tr>
                <td>High risk reports created</td>
                <td className="text-right font-bold py-10">{highRiskReportCount}</td>
              </tr>
            </tbody>
          </table>
        )}
    </>
  );
};

export default ReportStats;