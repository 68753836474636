import { appSyncClient } from '../../../../utilities/appSync';
import { Mutation, MutationUpdateFlagSnoozeArgs, Flag } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';

type UpdateFlagSnoozeResult = {
  updateFlagSnooze: {
    flag: Flag['id'];
  };
}

const updateFlagSnooze = async (flagId: Flag['id'], dateSnoozeUntil: string): Promise<ApolloQueryResult<UpdateFlagSnoozeResult>> =>
  appSyncClient.mutate<Pick<Mutation, 'updateFlagSnooze'>, MutationUpdateFlagSnoozeArgs>({
    mutation: gql`
      mutation UpdateFlagSnooze($input: UpdateFlagSnoozeInput!) {
        updateFlagSnooze(input: $input) {
          flag {
            id
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: flagId,
        dateSnoozeUntil,
      },
    },
  });

export default updateFlagSnooze;
