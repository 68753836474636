import * as React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

interface BarChartProps {
  chartData: any;
}

const ProjectionBarChart = (props: BarChartProps) => {
  const { chartData } = props;

  return (
    <BarChart
      width={800}
      height={600}
      data={chartData}
      margin={{
        top: 20,
        right: 50,
        left: 0,
        bottom: 50,
      }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis unit=" hours" />
      <Tooltip />
      <Legend
        wrapperStyle={{ display: "flex", gap: "1rem" }}
        layout="horizontal"
        height={80}
        verticalAlign="top"
        align="left"
      />
      <Bar
        dataKey="cancerpre"
        stackId="cancer"
        fill="#136c57"
        unit=" hours"
        name="Cancer - Pretest"
      />
      <Bar
        dataKey="cancerfhx"
        stackId="cancer"
        fill="#93e7d1"
        unit=" hours"
        name="Cancer - FHx"
      />
      <Bar
        dataKey="cancerpost"
        stackId="cancer"
        fill="#26cfa2"
        unit=" hours"
        name="Cancer - Posttest"
      />

      <Bar
        dataKey="carrierpre"
        stackId="carrier"
        fill="#6269ee"
        unit=" hours"
        name="Carrier - Pretest"
      />
      <Bar
        dataKey="carrierfhx"
        stackId="carrier"
        fill="#c0c3f8"
        unit=" hours"
        name="Carrier - FHx"
      />
      <Bar
        dataKey="carrierlow"
        stackId="carrier"
        fill="#4e54be"
        unit=" hours"
        name="Carrier - Low risk"
      />
      <Bar
        dataKey="carrierinc"
        stackId="carrier"
        fill="#3b3f8f"
        unit=" hours"
        name="Carrier - Increased risk"
      />
      <Bar
        dataKey="carrierhigh"
        stackId="carrier"
        fill="#272a5f"
        unit=" hours"
        name="Carrier - High risk"
      />
    </BarChart>
  );
};

export default ProjectionBarChart;
