import * as React from 'react';
import TagAutocomplete, { TagAutocompleteProps } from './TagAutocomplete';

export interface SearchAutocompleteOption {
  id: string | number;
  name: string;
}

export interface SearchAutocompleteProps extends TagAutocompleteProps {
  onSelect(option: SearchAutocompleteOption): void;
  size?: TagAutocompleteProps['size'];
}

const SearchAutocomplete = (props: SearchAutocompleteProps) => {
  return (
    <TagAutocomplete
      {...props}
      maxSelectionAmount={1}
      onChange={(items) => {
        if (items.length > 0) {
          props.onSelect(items[0]);
        } else {
          props.onSelect(null);
        }
      }}
    />
  );
};

export default SearchAutocomplete;
