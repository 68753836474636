import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { Journey, Member, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { navigate } from '../../../store/router/actions';

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      member {
        id
        hasFamilyHistoryGroup
      }
    }
  }
`;

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    member: Pick<Member, 'id' | 'hasFamilyHistoryGroup'>;
  };
}

const JourneyShowFamilyHistoryLink = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const dispatch = useDispatch();
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const journey = journeyQuery?.data?.journey;
  
  
  if (!journey || !journey.member) {
    return null;
  }

  const { member } = journey;
  const onNavigateToFamilyHistory = () => dispatch(navigate.toMemberFamilyHistory(member.id));
  const hasFamilyHistoryGroup = member.hasFamilyHistoryGroup;

  return (
    <div className="text-xs text-grey font-nm mt-8 text-right">
      <button onClick={onNavigateToFamilyHistory} className={hasFamilyHistoryGroup ? 'text-green' : 'text-orange'}>{hasFamilyHistoryGroup ? 'Family history' : 'No family history'}</button>
    </div>
  );
};

export default JourneyShowFamilyHistoryLink;
