import * as React from 'react';

interface CounsellorIconProps {
  photoURL?: string;
  name?: string;
  className?: string;
}

const CounsellorIcon = ({ photoURL, name, className }: CounsellorIconProps) => {
  if (photoURL) {
    return <img src={photoURL} alt="" className={`rounded-full w-5 h-5 ${className || ''}`} />;
  }

  if (name) {
    return (
      <span
        className={`rounded-full w-5 h-5 border text-xxs text-center inline-block leading-loose font-bold ${className || ''}`}
      >
        {name.split(' ').map(name => name[0].toUpperCase()).join('')}
      </span>
    );
  }
  
  return <span className={className} />;
};

export default React.memo(CounsellorIcon);
