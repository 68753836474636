import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Mutation, Query, Request, CreateRequestResult } from '../../../../graphql/genie-api-types';
import RequestPaidForm from '../../../components/request/RequestPaidForm';
import { MutationFunction, QueryResult } from 'react-apollo';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import { convertLocalToUTC, convertUTCToLocal } from '../../../../utilities/helpers';

interface RequestPaidProps {
  onUpdate?(request: CreateRequestResult['request']): void;
  onCancel?(): void;
  requestId: Request['id'];
}

const RequestPaid = (props: RequestPaidProps) => (
  <GenieQuery
    query={gql`
      query Request($id: ID!) {
          request(id: $id) {
            id
            datePaid
          }
      }
    `}
    errorPolicy="all"
    variables={{
      id: props.requestId,
    }}
  >
    {({ loading, data }: QueryResult<Pick<Query, 'request'>>) => loading ?
      (
        <Spinner label="Loading request..." />
      ) : (
        <GenieMutation
          mutation={gql`
          mutation UpdateRequest($input: UpdateRequestInput!) {
            updateRequest(input: $input) {
              request {
                id
                datePaid
              }
            }
          }
          `}
        >
          {(mutate: MutationFunction<Mutation>, result) => (
            <RequestPaidForm
              initialValues={{
                ...data.request,
                datePaid: convertUTCToLocal(data.request.datePaid),
              }}
              onCancel={props.onCancel}
              isLoading={result.loading}
              errorMessage={result?.error?.message}
              onSubmit={async (requestFields: Pick<Request, 'datePaid'>) => {
                const mutationResult = await mutate({
                  variables: {
                    input: {
                      requestId: props.requestId,
                      datePaid: convertLocalToUTC(requestFields.datePaid),
                    },
                  },
                });

                if (mutationResult && props.onUpdate) {
                  props.onUpdate(mutationResult.data.updateRequest.request);
                }
              }}
            />
          )
          }
        </GenieMutation>
      )}
  </GenieQuery>
);

export default RequestPaid;
