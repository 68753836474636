import * as React from 'react';
import { copyToClipboard } from '../../../utilities/helpers';
import DiseaseSearchAutocomplete from '../../graphql/containers/DiseaseSearchAutocomplete';
import { SearchAutocompleteOption } from '../widgets/SearchAutocomplete';

export interface DiseaseOption extends SearchAutocompleteOption {
  genes?: string[];
}

const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
  const value = (e.target as HTMLInputElement).value;
  copyToClipboard(value);
};

const DiseaseDetailsSearch = () => {
  const [selectedDisease, setSelectedDisease] = React.useState<DiseaseOption>(null);

  return (
    <div>
      <DiseaseSearchAutocomplete
        onSelect={setSelectedDisease}
      />
      {selectedDisease && (
        <div className="p-10 border bg-white font-bold">
          <div className="flex w-full items-center">
            <span>Disease Id:</span> <input type="text" value={selectedDisease?.id} readOnly className="flex-1 pl-5" onClick={onInputClick} />
          </div>
          <div className="flex w-full items-center">
            <span>Name:</span> <input type="text" value={selectedDisease?.name} readOnly className="flex-1 pl-5" onClick={onInputClick} />
          </div>
          <div className="flex w-full items-center">
            <span>Genes:</span> <input type="text" value={selectedDisease?.genes} readOnly className="flex-1 pl-5" onClick={onInputClick} />
          </div>
        </div>
      )}
    </div>
  )
};

export default DiseaseDetailsSearch;
