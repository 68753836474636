import * as React from 'react';

interface ChecklistItemProps {
  children: React.ReactNode;
}

const ChecklistItem = (props: ChecklistItemProps) => (
  <div className="flex my-10 items-center">
    <div className="w-6 h-6 bg-grey-light border border-grey-dark mr-10 rounded hidden print:block"></div>
    <div>
      {props.children}
    </div>
  </div>
);

export default ChecklistItem;
