import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { CreateJourneyInput } from '../../../graphql/genie-api-types';
import FormGroup from '../widgets/form/FormGroup';
import MemberSearchAutocomplete from '../../graphql/containers/members/MemberSearchAutocomplete';
import { SearchAutocompleteOption } from '../widgets/SearchAutocomplete';
import MultiModelForm from '../../screens/MultiModelForm';
import { AnyModelResult } from '../../graphql/containers/SwitchForm';

interface JourneyFormProps extends Pick<BaseFormProps, 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: Partial<JourneyFormValues>;
}

export interface JourneyFormValues extends CreateJourneyInput {
  member: SearchAutocompleteOption;
}

class JourneyForm extends React.PureComponent<JourneyFormProps> {
  render() {
    const { initialValues, onSubmit, ...props } = this.props;
    return (
      <MultiModelForm<'member'>
        modelForms={{
          member: 'MemberCreate',
        }}
        initialModelValues={{
          member: initialValues.member as AnyModelResult,
        }}
      >
        {(onSwitchToModelForm, onChangeModelValue, modelValues) => (
          <BaseForm
            {...props}
            initialValues={{}}
            submitButtonLabel="Add Journey"
            onSubmit={(values) => onSubmit({
              ...values,
              ...modelValues,
            })}
          >
            {() => (
                <div>
                  <FormGroup
                    controlId="member"
                    label="Member (optional)"
                    labelAction={{
                      label: '+ New',
                      onClick: () => onSwitchToModelForm('member'),
                    }}
                  >
                    <MemberSearchAutocomplete
                      onSelect={member => onChangeModelValue('member', member)}
                      defaultValue={modelValues.member}
                    />
                  </FormGroup>
                </div>
              )
            }
          </BaseForm>
        )}
      </MultiModelForm>
    );
  }
}

export default JourneyForm;
