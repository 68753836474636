import * as React from 'react';
import moment from 'moment';
import LoomVideo from '../widgets/LoomVideo';

interface Update {
  timestamp: number;
  title: string;
  updateArticle: React.ReactNode;
}

const updates: Update[] = [
  {
    timestamp: 1623044356000,
    title: 'Ops screen changes',
    updateArticle: (
      <>
        <LoomVideo
          videoId="ad06bbc018b642b8a42e2dad30615830"
        />
      </>
    ),
  },
  {
    timestamp: 1622605725000,
    title: 'Memberless journeys',
    updateArticle: (
      <>
        <LoomVideo
          videoId="8e95d38fd7754d779a633f72c130b141"
        />
      </>
    ),
  },
  {
    timestamp: 1621486515000,
    title: 'Pre-test self assessment question removal',
    updateArticle: (
      <>
        <LoomVideo
          videoId="a199dda04094490e9a87e4d514b865e8"
        />
      </>
    ),
  },
  {
    timestamp: 1620880308000,
    title: 'Immediate recollect workflow',
    updateArticle: (
      <>
        <LoomVideo
          videoId="ab55adb3b58a4fafa362d2da777c0d82"
        />
      </>
    ),
  },
  {
    timestamp: 1619155008000,
    title: 'Individual report tweaks',
    updateArticle: (
      <>
        <LoomVideo
          videoId="7570b437d3444707900009f33e9bccb1"
        />
      </>
    ),
  },
  {
    timestamp: 1619144952000,
    title: 'Report writing helpers',
    updateArticle: (
      <>
        <LoomVideo
          videoId="e363f595b4db4c5f9584c7739d3c5e1c"
        />
      </>
    ),
  },
  {
    timestamp: 1616995746000,
    title: 'Reports tasks in Genie',
    updateArticle: (
      <>
        <LoomVideo
          videoId="aa8d97b948534f5fbf139e27b20c7130"
        />
      </>
    ),
  },
  {
    timestamp: 1616049799000,
    title: 'Lab orders in Genie',
    updateArticle: (
      <>
        <LoomVideo
          videoId="8353b381e2484f368c9c80967daab15a"
        />
      </>
    ),
  },
  {
    timestamp: 1616043277000,
    title: 'Linking Dr names from Member flow to Genie',
    updateArticle: (
      <>
        <LoomVideo
          videoId="fc180b177bd647e9819923a5e3e4f705"
        />
      </>
    ),
  },
  {
    timestamp: 1614578212000,
    title: 'Enabling logins for existing members',
    updateArticle: (
      <>
        <LoomVideo
          videoId="c46e0203a2434798bb09a8ceeba85726"
        />
      </>
    ),
  },
  {
    timestamp: 1614571326000,
    title: 'Approving orders',
    updateArticle: (
      <>
        <LoomVideo
          videoId="1c852bdcbd4847e5b64571ee4d3cff38"
        />
      </>
    ),
  },
  {
    timestamp: 1614222516000,
    title: 'Sales leads',
    updateArticle: (
      <>
        <LoomVideo
          videoId="69a45842952f4116b2a8bf24c8ee3a0e"
        />
      </>
    ),
  },
  {
    timestamp: 1611288332000,
    title: 'Pregnancy data automatically loads from family history',
    updateArticle: (
      <>
        <LoomVideo
          videoId="1b6af9902f0b41129507f63aa5e3009b"
        />
      </>
    ),
  },
  {
    timestamp: 1610603272000,
    title: 'Date of Birth member search',
    updateArticle: (
      <>
        <LoomVideo
          videoId="dd558d5f2514416bbc183941a1e61798"
        />
      </>
    ),
  },
  {
    timestamp: 1610590858000,
    title: 'Recollecting kits',
    updateArticle: (
      <>
        <LoomVideo
          videoId="b533ac4e85964d3688e787f793197147"
        />
      </>
    ),
  },
  {
    timestamp: 1610588446000,
    title: 'Getting efficiency stats',
    updateArticle: (
      <>
        <LoomVideo
          videoId="c0af3f176c6c4ccbb4b6731fa06d2e7d"
        />
      </>
    ),
  }
];

const UPDATE_READ_COUNT_KEY = 'genie/lastUpdateReadCount';

export const getUnreadCount = async () => {
  const lastUpdateReadCount = parseInt(localStorage.getItem(UPDATE_READ_COUNT_KEY) ?? '0');
  const newUpdates = updates.filter(update => update.timestamp > lastUpdateReadCount);

  return newUpdates.length;
};

const UpdatesList = () => {
  React.useEffect(() => {
    localStorage.setItem(UPDATE_READ_COUNT_KEY, new Date().getTime().toString());
  }, []);

  return (
    <div>
      {updates.map(update => (
        <div className="border-b py-20" key={`${update.title}-${update.timestamp}`}>
          <div className="text-grey-dark mb-5">{moment(update.timestamp).format('Do MMM YYYY')}</div>
          <h2 className="text-2xl font-bold mb-20">{update.title}</h2>
          <div className="mt-10">
            {update.updateArticle}
          </div>
        </div>
      ))}
    </div>
  );
}

export default UpdatesList;
