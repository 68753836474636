import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormInput from '../widgets/form/FormInput';

interface WithdrawConsentFormProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  consentId: string;
}

const WithdrawConsentForm = (props: WithdrawConsentFormProps) => {
  const { isLoading, onCancel, errorMessage, onSubmit } = props;

  return (
    <BaseForm
      initialValues={{}}
      submitButtonLabel="Withdraw"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={(values) => onSubmit({
        ...values,
      })}
    >
      {({
        handleChange,
        values,
        setFieldValue,
      }) => (
        <div>
          <FormInput
            label="Notes (required)"
            name="notes"
            key="notes"
            as="textarea"
            onChange={handleChange}
            setFieldValue={setFieldValue}
            value={values['notes']}
          />
        </div>
      )}
    </BaseForm>
  );
}

export default WithdrawConsentForm;
