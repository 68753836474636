import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Journey, Member, QueryJourneyArgs } from '../../../../graphql/genie-api-types';
import JourneyShowContext from '../../../contexts/JourneyShowContext';

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      member {
        id
      }
    }
  }
`;

interface QueryResult {
  journey: Pick<Journey, 'id'> & {
    member?: Pick<Member, 'id'>;
  };
}

interface JourneyShowMemberIdProps {
  children(memberId: Member['id']): React.ReactNode;
}

const JourneyShowMemberId = (props: JourneyShowMemberIdProps) => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });
  const member = journeyQuery?.data?.journey?.member;
  
  if (!member) {
    return null;
  }
  
  return <>{props.children(member.id)}</>
};

export default JourneyShowMemberId;
