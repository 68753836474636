import * as React from 'react';
import Alert from '../components/widgets/Alert';
import { sentryClient } from '../../utilities/sentry';
import { isDev } from '../../utilities/helpers';
import { FaBug } from '../components/widgets/Icon';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error;
}

const bugUrl = 'https://www.notion.so/eugenelabs/d7afce910259494fb79533937f1564d9?v=5b6153c58bd54f1aae2306268eafb0d9';

// Adapted from https://reactjs.org/docs/error-boundaries.html.
export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  state: Readonly<ErrorBoundaryState> = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error) {
    if (!isDev()) {
      sentryClient.captureException(error);
    }
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      if (isDev()) {
        console.error(error);
      }
      return (
        <div className="mt-10">
          <Alert variant="danger" className="relative">
          <a href={bugUrl} className="underline absolute top-0 right-0 p-10 pr-10" target="_blank" rel="noopener noreferrer"><FaBug /></a>
            <h2 className="text-md font-bold my-10 mb-12 mx-15">Looks like we hit a bug - please screenshot this and add it to the <a href={bugUrl} className="underline" target="_blank" rel="noopener noreferrer">notion bug board</a>.</h2>
            <code className="bg-white text-grey-dark block p-15 leading-normal">
              {error.stack}
            </code>
          </Alert>
        </div>
      );
    }

    return this.props.children;
  }
}
