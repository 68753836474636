import * as React from 'react';
import gql from 'graphql-tag';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { JourneyState, Query, Journey, Sex } from '../../../graphql/genie-api-types';
import JourneyTable, { JourneyTableProps } from '../../components/widgets/JourneyTable';
import Screen from '../../components/widgets/Screen';
import GeneticJokes from '../../components/widgets/GeneticJokes';
import Button from '../../components/widgets/Button';
import { FiCheckSquare, FiCopy, FiSquare } from '../../components/widgets/Icon';
import { copyToClipboard, getGoogleDriveFolderName, getGoogleDriveSearchLink, getGoogleDriveFolderNameInvitaeReportManager } from '../../../utilities/helpers';
import PrivateContent from '../../components/widgets/PrivateContent';
import { getProductIcon } from '../../../utilities/journeys/products';

interface TasksDownloadReportsProps {

}

type JourneyTablePayload = Pick<JourneyTableProps<Journey>, 'journeysByRequestId' | 'orderedRequestIds'>;

const query = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          request {
            id
            product
            productVariant
            initiatingMember {
              id
            }
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            journeys {
              id
              labOrderNumber
              state
              member {
                id
                name
                dateOfBirth
                sex
              }
              kits {
                id
                recollectionRequestedDate
                dateShippedToLab
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const showActionsForJourney = (journey: Journey) =>
  journey.state === JourneyState.WaitingForLabResults;

const cellsForJourney = (journey: Journey) => [
  getProductIcon(journey.request.product, journey.request.productVariant),
  journey.labOrderNumber,
  <Button
    key="folder-name"
    size="sm"
    variant="link"
    className="flex items-center hover:bg-purple hover:text-white"
    onClick={(e) => {
      e.stopPropagation();
      const folderName = getGoogleDriveFolderName(journey.request.journeys);
      copyToClipboard(folderName);
      window.open(getGoogleDriveSearchLink(folderName));
    }}
  >
    <span><PrivateContent>{getGoogleDriveFolderName(journey.request.journeys).slice(0, 5)}</PrivateContent>...</span> <FiCopy className="ml-5" />
  </Button>,
  <Button
  key="folder-name"
  size="sm"
  variant="link"
  className="flex items-center hover:bg-purple hover:text-white"
  onClick={(e) => {
    e.stopPropagation();
    const folderName = getGoogleDriveFolderNameInvitaeReportManager(journey.request);
    copyToClipboard(folderName);
    window.open(getGoogleDriveSearchLink(folderName));
  }}
>
  <span><PrivateContent>{getGoogleDriveFolderNameInvitaeReportManager(journey.request).slice(0, 5)}</PrivateContent>...</span> <FiCopy className="ml-5" />
</Button>
];

const TasksDownloadReports = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showOnlyShippedToLabKits, setShowOnlyShippedToLabKits] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [journeyTablePayload, setJourneyTablePayload] = React.useState({
    journeysByRequestId: {},
    orderedRequestIds: [],
  } as JourneyTablePayload);

  const loadJourneys = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const journeyStats = await runStatsQuery<Query['listJourneys']>(query, {
        variables: {
          input: {
            state: JourneyState.WaitingForLabResults,
          }
        }
      }, 'listJourneys');

      const journeysByRequestId: { [key: string]: Journey[] } = {};
      journeyStats.edges.map((journeyEdge) => {
        journeysByRequestId[journeyEdge.node.request.id] = journeyEdge.node.request.journeys.map(journey => ({
          ...journey,
          request: journeyEdge.node.request,
        })).sort(journey => (journey?.member?.sex ?? Sex.Female) === Sex.Female ? -1 : 1)
      });

      setJourneyTablePayload({
        journeysByRequestId,
        orderedRequestIds: Object.keys(journeysByRequestId),
      });
    } catch (e) {

    }

    setIsLoading(false);
  }, [isLoading, setIsLoading]);

  const onActionComplete = React.useCallback(async (rowIds: string[], actionName: string, actionValue: string) => {
    if (actionName === 'moveToState' && [JourneyState.ReadyForReport, JourneyState.WaitingForPartnerResults].includes(actionValue as JourneyState)) {
      const journeysByRequestId = { ...journeyTablePayload.journeysByRequestId };
      rowIds.forEach(rowId => {
        Object.entries(journeysByRequestId).map(([, journeys]) => {
          const journey = journeys.find(journey => journey.id === rowId);
          if (journey) {
            journey.state = actionValue as JourneyState;
          }
        });
      });
      setJourneyTablePayload({
        ...journeyTablePayload,
        journeysByRequestId,
      });
    }
  }, [journeyTablePayload, setJourneyTablePayload]);
  
  const onToggleShippedToLab = React.useCallback(() => {
    setShowOnlyShippedToLabKits(!showOnlyShippedToLabKits);
  }, [setShowOnlyShippedToLabKits, showOnlyShippedToLabKits]);

  React.useEffect(() => {
    loadJourneys();
  }, []);

  const filteredJourneyTablePayload = React.useMemo(() => {
    const filteredPayload: JourneyTablePayload = {
      journeysByRequestId: {},
      orderedRequestIds: [],
    };

    Object.keys(journeyTablePayload.journeysByRequestId).forEach(requestId => {
      const journeys = journeyTablePayload.journeysByRequestId[requestId];

      const passesKitsCheck = showOnlyShippedToLabKits ? journeys.find(journey => !!journey.kits?.find(kit => !kit.recollectionRequestedDate && !!kit.dateShippedToLab)) : true;
      const passesSearchCheck = searchText === '' ? true : journeys.find(journey => journey.member && journey.member.name.toLowerCase().includes(searchText.toLowerCase()));

      if (passesKitsCheck && passesSearchCheck) {
        filteredPayload.orderedRequestIds.push(requestId);
        filteredPayload.journeysByRequestId[requestId] = journeys;
      }
    });

    return filteredPayload;
  }, [searchText, journeyTablePayload, showOnlyShippedToLabKits]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Download reports',
        action: (
          <button className="flex items-center font-bold text-purple focus:outline-none" onClick={onToggleShippedToLab}>
            <span className="mr-5 mb-2">{showOnlyShippedToLabKits ? <FiCheckSquare /> : <FiSquare />}</span> <span>Only shipped to lab</span> 
          </button>
        ),
      }}
    >
      <input
        type="text"
        placeholder="Search member names..."
        className="border p-10 rounded"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <JourneyTable
        isLoading={isLoading}
        {...filteredJourneyTablePayload}
        cellsForJourney={cellsForJourney}
        header={['Product', 'RQ#', "Google Drive (old)", "Google Drive (new)"]}
        showActionsForJourney={showActionsForJourney}
        onActionComplete={onActionComplete}
        alwaysShowActions
        onRefresh={loadJourneys}
      />
      <GeneticJokes className="mt-auto mb-0 pt-40" />
    </Screen>
  );
};

export default TasksDownloadReports;
