import * as React from 'react';
import moment from 'moment';
import { StatsProps } from './Stats';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { RequestsConnection, QueryListRequestsArgs, Request, JourneyState } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

interface FunnelStats {
  requestsWithoutReferral: {
    total: number;
  };
  requestsWithReferral: {
    total: number;
  };
}

const colors = ['#6269EE', '#FFB258', '#7F7F9E', '#FD5A5A'];

const SalesStats = (props: StatsProps) => {
  const [allRequests, setAllRequests] = React.useState<Pick<Request, 'id' | 'datePaid' | 'referringDoctorClinic' | 'pregnancy'>[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { fromDate, toDate } = props;

  const getAllRequests = async () => {
    const results = await runStatsQuery<RequestsConnection, QueryListRequestsArgs>(gql`
    query ListRequests($input: ListRequestsInput!) {
      listRequests(input: $input) {
        edges {
          cursor
          node {
            id
            datePaid
            referringDoctorClinic {
              id
            }
            pregnancy {
              days
            }
            journeys {
              id
              state
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
    `, {
      variables: {
        input: {},
      },
    }, 'listRequests');
    const requests = results.edges.map(edge => edge.node).filter(request =>
      request.journeys?.find(journey =>
        journey.state !== JourneyState.Trash)
    );
    setAllRequests(requests);
    setIsLoading(false);
  };

  React.useEffect(() => { getAllRequests(); }, []);

  const paidRequests = React.useMemo(() => {
    return allRequests.filter(request => {
      if (!request.datePaid) {
        return false;
      }
      const reportDate = moment(request.datePaid).startOf('day');
      return reportDate.isSameOrAfter(fromDate) && reportDate.isSameOrBefore(toDate);
    });
  }, [allRequests, fromDate, toDate]);

  const funnelStats: FunnelStats = React.useMemo(() => {
    const output: FunnelStats = {
      requestsWithoutReferral: {
        total: 0,
      },
      requestsWithReferral: {
        total: 0,
      },
    };

    paidRequests.forEach(request => {
      const countOutput = request.referringDoctorClinic ? output.requestsWithReferral : output.requestsWithoutReferral;
      countOutput.total += 1;
    });

    return output;
  }, [fromDate, toDate, paidRequests]);

  const paidReferringDoctorGraphData = React.useMemo(() => {
    const weekFormat = 'YYYY.ww';

    const weekData: { [key: string]: { referred: number; notReferred: number } } = {};

    const startWeek = moment(fromDate).clone().startOf('week');
    const endWeek = moment(toDate).clone().add(1, 'week').startOf('week');
    const weeks = endWeek.diff(startWeek, 'weeks');

    for (let i = 0; i < weeks; i++) {
      const date = startWeek.clone().add(i, 'weeks');
      const weekString = date.format(weekFormat);
      weekData[weekString] = {
        referred: 0,
        notReferred: 0,
      };
    }

    paidRequests.forEach(paidRequest => {
      const datePaidKey = moment(paidRequest.datePaid).format(weekFormat);
      if (!weekData[datePaidKey]) {
        return;
      }
      if (paidRequest.referringDoctorClinic) {
        weekData[datePaidKey].referred += 1;
      }
      else {
        weekData[datePaidKey].notReferred += 1;
      }
    });

    return {
      dataKeys: ['Referrals', 'Direct'],
      items: Object.keys(weekData).sort().map((weekString) => {
        const weekMoment = moment(weekString, weekFormat);
        return {
          week: weekMoment.format('MMM DD'),
          'Referrals': weekData[weekString].referred,
          'Direct': weekData[weekString].notReferred,
        };
      }),
    };
  }, [paidRequests]);
  
  const paidPregnancyGraphData = React.useMemo(() => {
    const weekFormat = 'YYYY.ww';

    const weekData: { [key: string]: { pregnant: number; notPregnant: number } } = {};

    const startWeek = moment(fromDate).clone().startOf('week');
    const endWeek = moment(toDate).clone().add(1, 'week').startOf('week');
    const weeks = endWeek.diff(startWeek, 'weeks');

    for (let i = 0; i < weeks; i++) {
      const date = startWeek.clone().add(i, 'weeks');
      const weekString = date.format(weekFormat);
      weekData[weekString] = {
        pregnant: 0,
        notPregnant: 0,
      };
    }

    paidRequests.forEach(paidRequest => {
      const datePaidKey = moment(paidRequest.datePaid).format(weekFormat);
      if (!weekData[datePaidKey]) {
        return;
      }
      if (paidRequest.pregnancy) {
        weekData[datePaidKey].pregnant += 1;
      }
      else {
        weekData[datePaidKey].notPregnant += 1;
      }
    });

    return {
      dataKeys: ['Pregnant', 'Not Pregnant'],
      items: Object.keys(weekData).sort().map((weekString) => {
        const weekMoment = moment(weekString, weekFormat);
        return {
          week: weekMoment.format('MMM DD'),
          'Pregnant': weekData[weekString].pregnant,
          'Not Pregnant': weekData[weekString].notPregnant,
        };
      }),
    };
  }, [paidRequests]);

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
          <>
            <table className="w-full">
              <tbody>
                <tr>
                  <td>Purchases</td>
                  <td className="text-right font-bold py-10">{paidRequests.length}</td>
                </tr>
                <tr>
                  <td className="border-t pt-10">Purchases <strong>with</strong> referring doctor</td>
                  <td className="text-right font-bold py-10 border-t">{funnelStats.requestsWithReferral.total}</td>
                </tr>
                <tr>
                  <td className="border-t pt-10">Purchases <strong>without</strong> referring doctor referral</td>
                  <td className="text-right font-bold py-10 border-t">{funnelStats.requestsWithoutReferral.total}</td>
                </tr>
              </tbody>
            </table>
            <AreaChart
              width={1000}
              height={500}
              data={paidReferringDoctorGraphData.items}
              margin={{
                top: 100, right: 30, left: 0, bottom: 0,
              }}
            >
              {paidReferringDoctorGraphData.dataKeys.map((dataKey, index) => (
                <Area type="monotone" dataKey={dataKey} fill={colors[index]} stroke={colors[index]} key={dataKey} stackId="1" />
              ))}
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <XAxis dataKey="week" />
              <Tooltip />
            </AreaChart>
            <AreaChart
              width={1000}
              height={500}
              data={paidPregnancyGraphData.items}
              margin={{
                top: 100, right: 30, left: 0, bottom: 0,
              }}
            >
              {paidPregnancyGraphData.dataKeys.map((dataKey, index) => (
                <Area type="monotone" dataKey={dataKey} fill={colors[index]} stroke={colors[index]} key={dataKey} stackId="1" />
              ))}
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis />
              <XAxis dataKey="week" />
              <Tooltip />
            </AreaChart>
          </>
        )}
    </>
  );
}

export default SalesStats;