import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Path, ReportMode, ReportMatchParams, PATHS } from './types';
import { matchPath } from 'react-router';
import { FormName } from '../../graphql/containers/SwitchForm';

export const getReportPathParams = (): ReportMatchParams => {
  const match = matchPath<ReportMatchParams>(window.location.pathname, {
    path: PATHS.REPORT,
    exact: false,
    strict: false,
  });

  const params: { [key: string]: string } = {};

  if (match && match.params) {
    Object.keys(match.params).forEach((key: string) => {
      params[key] = decodeURI(match.params[key as keyof ReportMatchParams] as string);
    });
  }

  return params;
};

export const processReportPath = (path: Path, mode: ReportMode, requestId: string) =>
  path.replace(':mode', mode).replace(':requestId', requestId);

export const navigate = {
  to: (path: Path) =>
    push(path),
  toJourney: (journeyId: string) =>
    push(PATHS.JOURNEY_SHOW.replace(':journeyId', journeyId)),
  toMemberFamilyHistory: (memberId: string) =>
    push(PATHS.MEMBER_FAMILY_HISTORY.replace(':memberId', memberId)),
  toDoctor: (doctorId: string) =>
    push(PATHS.DOCTOR_SHOW.replace(':doctorId', doctorId)),
  toClinic: (clinicId: string) =>
    push(PATHS.CLINIC_SHOW.replace(':clinicId', clinicId)),
  toReportPath: (path: Path, mode: ReportMode, id: string) =>
    push(processReportPath(path, mode, id)),
  toCurrentReportPath: (path: Path, queryString?: string) => {
    const { mode, requestId } = getReportPathParams();
    return push({
      pathname: processReportPath(path, mode, requestId),
      search: queryString,
    });
  },
  toFamilyHistorySubmission: (submissionId: string) =>
    push(PATHS.SUBMISSIONS_FAMILY_HISTORY.replace(':submissionId', submissionId)),
  toClinicalResearchExtractCreate: (requestId: string) =>
    push(PATHS.CLINICAL_RESEARCH_EXTRACT_CREATE.replace(':requestId', requestId)),
  toOrderApproval: (requestId: string) =>
    push(PATHS.TASKS_APPROVE_ORDERS_ORDER.replace(':requestId', requestId)),
  toForm: (formName: FormName, formParams: object = {}) =>
    push({
      search: queryString.stringify({
        form: formName,
        formParams: JSON.stringify(formParams),
      }),
    }),
  toMemberLegacy: (memberId: string) =>
    push(PATHS.MEMBER_SHOW_LEGACY_REDIRECT.replace(':memberId', memberId)),
};
