import * as React from 'react';
import { curry } from 'lodash';
import { FiMail, FiPhoneCall, FiMessageCircle } from '../../widgets/Icon';
import DripIcon from '../../widgets/icons/DripIcon';
import { copyToClipboard, getZendeskSearchUrl } from '../../../../utilities/helpers';
import ZendeskIcon from '../../widgets/icons/ZendeskIcon';
import PrivateContent from '../../widgets/PrivateContent';
import { Member, QueryMemberArgs } from '../../../../graphql/genie-api-types';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

type ContactType = 'message' | 'email' | 'call';

interface MemberShowContactProps {
  memberId: Member['id'];
}

interface QueryResult {
  member: Pick<Member, 'id' | 'nickname' | 'name' | 'email' | 'phoneNumber'>;
}

const QUERY = gql`
  query member($id: ID!) {
    member(id: $id) {
      id
      nickname
      name
      email
      phoneNumber
    }
  }
`;

const MemberShowContact = (props: MemberShowContactProps) => {
  const memberQuery = useQuery<QueryResult, QueryMemberArgs>(QUERY, {
    variables: {
      id: props.memberId,
    },
  });

  const member = memberQuery?.data?.member;

  const onShowContact = (member: QueryResult['member'], type: ContactType, e: MouseEvent) => {
    e.preventDefault();
    const contactValues: { [key in ContactType]?: string } = {
      message: member.phoneNumber,
      email: member.email,
      call: member.phoneNumber,
    };

    copyToClipboard(contactValues[type]);
  }

  if (!member) {
    return null;
  }

  return (
    <div className="bg-grey-light rounded flex flex-row flex-nowrap justify-between">
      <div className="flex pl-12 py-11 w-auto text-grey">
        Contact <PrivateContent>{member.nickname ? member.nickname : null}</PrivateContent>
      </div>
      <div className="flex justify-end pr-12 pt-10 w-auto">
        {member.email && (
          <a target="_blank" href={`https://www.getdrip.com/1233591/subscribers?subscriber_email=${member.email}`} rel="noopener noreferrer">
            <DripIcon />
          </a>
        )}
        {member.email && (
          <a href={`mailto:${member.email}`} onClick={curry(onShowContact)(member)('email')}>
            <FiMail className="w-5 h-5 align-text-bottom ml-12 text-grey hover:text-green" />
          </a>
        )}
        {member.phoneNumber && (
          <>
            <a href={`tel:${member.phoneNumber}`} onClick={curry(onShowContact)(member)('call')} rel="noopener noreferrer">
              <FiPhoneCall className="w-5 h-5 align-text-bottom ml-12 text-grey hover:text-green" />
            </a>
            <a href={`sms:${member.phoneNumber}`} onClick={curry(onShowContact)(member)('message')} rel="noopener noreferrer">
              <FiMessageCircle className="w-5 h-5 align-text-bottom ml-12 text-grey hover:text-green" />
            </a>
          </>
        )}
        <a
          target="_blank"
          href={getZendeskSearchUrl(member.email || member.name)}
          rel="noopener noreferrer"
          className="text-grey hover:text-green ml-12"
        >
          <ZendeskIcon />
        </a>
      </div>
    </div>
  );
}

export default MemberShowContact;
