import { FlagType, FlagPriority, FlagCode, JourneyState } from '../../graphql/genie-api-types';
import { FiAlertTriangle, FiInfo, FiMinusCircle, FiAlertOctagon, FiBox, FiArrowLeft, FiClock, FiEdit, FiUsers, FiMessageSquare, FiX } from '../../app/components/widgets/Icon';
import { colors } from '../../app/types/tailwind';
import { JOURNEY_STATE_ICONS } from '../journeys/states';
import { StyledIcon } from '@styled-icons/styled-icon';

export const FLAG_TYPE_LABELS: { [key in FlagType]: string } = {
  APRIL_ERROR: 'April error',
  AWAITING_INPUT: 'Awaiting input',
  FOLLOW_UP: 'Follow up',
  NEEDS_ATTENTION: 'Needs attention',
  OUT_OF_OUR_CONTROL: 'Out of our control',
};

export const FLAG_PRIORITY_LABELS: { [key in FlagPriority]: string } = {
  HIGH: 'High',
  LOW: 'Low',
  MEDIUM: 'Medium',
  URGENT: 'Urgent',
};

export type FlagCodeType = {
  [key in FlagCode]: string;
};

export const FLAG_CODE_LABELS: FlagCodeType = {
  LATE_RESULTS_1: "Late results",
  LATE_RESULTS_2: "Extra late results",
  NOT_RETURNED_TO_EUGENE: "Not returned to Eugene",
  NOT_SENT_TO_MEMBER: "Not sent to member",
  NOT_SHIPPED_TO_LAB: "Not shipped to lab",
  READY_FOR_REPORT: "Ready for report",
  READY_FOR_REPORT_REVIEW: "Ready for report review",
  READY_TO_RELEASE_RESULTS: "Ready to release results",
  REQUIRE_DETAILS: "Require details",
  REQUIRE_PRETEST_BOOKING: "Require pretest booking",
  REQUIRE_RESULT_BOOKING: "Require result booking",
  UNMATCHED_FAMILY_HISTORY: "Family history",
  UNMATCHED_CONSENT: "Consent",
  FEEDBACK_RECEIVED: "Received feedback",
  SAMPLE_FAILED: "Sample failed",
};

export const FLAG_CODE_ICONS: { [key in FlagCode]: StyledIcon } = {
  'LATE_RESULTS_1': FiClock,
  'LATE_RESULTS_2': FiClock,
  'NOT_RETURNED_TO_EUGENE': FiArrowLeft,
  'NOT_SENT_TO_MEMBER': FiBox,
  'NOT_SHIPPED_TO_LAB': FiBox,
  'READY_FOR_REPORT': JOURNEY_STATE_ICONS[JourneyState.ReadyForReport],
  'READY_FOR_REPORT_REVIEW': JOURNEY_STATE_ICONS[JourneyState.ReadyForReportReview],
  'READY_TO_RELEASE_RESULTS': JOURNEY_STATE_ICONS[JourneyState.ReadyToReleaseResults],
  'REQUIRE_DETAILS': JOURNEY_STATE_ICONS[JourneyState.RequireDetails],
  'REQUIRE_PRETEST_BOOKING': JOURNEY_STATE_ICONS[JourneyState.RequirePretestBooking],
  'REQUIRE_RESULT_BOOKING': JOURNEY_STATE_ICONS[JourneyState.RequireResultBooking],
  'UNMATCHED_FAMILY_HISTORY': FiUsers,
  'UNMATCHED_CONSENT': FiEdit,
  'FEEDBACK_RECEIVED': FiMessageSquare,
  'SAMPLE_FAILED': FiX,
};

export const FLAG_PRIORITY_ICONS: { [key in FlagPriority]: StyledIcon } = {
  HIGH: FiAlertTriangle,
  LOW: FiInfo,
  MEDIUM: FiMinusCircle,
  URGENT: FiAlertOctagon,
};

export const FLAG_PRIORITY_COLORS: { [key in FlagPriority]: colors } = {
  HIGH: 'red',
  LOW: 'purple',
  MEDIUM: 'orange',
  URGENT: 'red',
};