import { OperationVariables, QueryHookOptions, QueryResult, useQuery } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { PageInfo } from '../graphql/genie-api-types';
import { appSyncClient } from './appSync';

export interface GenieQueryResult<TData, TVariables> extends QueryResult<TData, TVariables> {
  onNextPage(): void;
  onPreviousPage(): void;
  onRefresh(): void;
}

interface PageableData {
  edges: {
    cursor: string;
  }[];
  pageInfo: PageInfo;
}

interface GenieQuerySettings {
  useInfinitePager?: boolean;
}

const useGenieQuery = <TData = PageableData, TVariables = OperationVariables>(query: DocumentNode, options: QueryHookOptions<TData, TVariables> = {}, queryName: string, perPage?: number, settings?: GenieQuerySettings): GenieQueryResult<TData, TVariables> => {
  const genieOptions: QueryHookOptions<TData, TVariables> = {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    ...options,
  }
  
  if (perPage) {
    let queryVariables: any = options?.variables ?? {};
    queryVariables = {
      ...queryVariables,
      input: {
        ...queryVariables?.input,
        pagination: {
          first: perPage,
        },
      },
    }
    genieOptions.variables = queryVariables;
  }
  
  const queryResponse = useQuery<TData, TVariables>(query, genieOptions);
  const queryResponseData = (queryResponse?.data as any)?.[queryName] as any as PageableData;
  
  const onRefresh = () => {
    queryResponse.refetch(genieOptions.variables);
  }

  const onNextPage = () => {
    const lastCursor = queryResponseData?.edges?.slice(0).reverse()?.[0].cursor;

    queryResponse.fetchMore({
      variables: {
        ...options.variables,
        input: {
          ...(options.variables as any)?.input,
          pagination: {
            ...(options.variables as any)?.input?.pagination,
            after: lastCursor,
            first: perPage,
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (settings?.useInfinitePager) {
          const nextResult = (fetchMoreResult as any)[queryName] as PageableData;
          nextResult.edges = [
            ...prev[queryName].edges,
            ...nextResult.edges,
          ]
          return {
            [queryName]: nextResult,
          };
        }
        return fetchMoreResult;
      },
    });
  };

  const onPreviousPage = () => {
    const firstCursor = queryResponseData?.edges?.[0].cursor;

    queryResponse.fetchMore({
      variables: {
        ...options.variables,
        input: {
          ...(options.variables as any)?.input,
          pagination: {
            ...(options.variables as any)?.input?.pagination,
            before: firstCursor,
            last: perPage,
          }
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
  }

  return {
    ...queryResponse,
    onNextPage,
    onPreviousPage,
    onRefresh,
  };
};

export default useGenieQuery;
