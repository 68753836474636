import gql from 'graphql-tag';
import * as React from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { JourneyState, ProductVariant, Query, QueryListJourneysArgs, QuerySearchMembersArgs } from '../../../../graphql/genie-api-types';
import { ORDERED_JOURNEY_STATES } from '../../../../utilities/journeys/states';
import Badge, { BadgeProps } from '../../../components/widgets/Badge';
import { FiCheck } from '../../../components/widgets/Icon';
import Spinner from '../../../components/widgets/Spinner';

interface MemberAcuitySyncBadgeProps {
  primaryEmail: string;
  partnerEmail: string;
}

export const SEARCH_MEMBER_QUERY = gql`
  query SearchMembers($term: String!) {
    searchMembers(term: $term) {
      id
    }
  }
`;

const SEARCH_JOURNEY_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          request {
            id
            datePaid
            product
            primaryCounsellor {
              id
            }
            journeys {
              id
              state
              member {
                id
                email
              }
            }
          }
        }
      }
    }
  }
`;

type SyncStatusType = 'loading' | 'synced' | 'not-found' | 'not-synced';
type SyncStatus = {
  type: SyncStatusType;
  message?: string;
}

const MemberAcuitySyncBadge = (props: MemberAcuitySyncBadgeProps) => {
  const { primaryEmail, partnerEmail } = props;
  const primaryMemberQuery = useQuery<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>(SEARCH_MEMBER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      term: primaryEmail,
    },
  });

  const [loadPartnerMember, partnerMemberQuery] = useLazyQuery<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>(SEARCH_MEMBER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadPrimaryMemberJourneys, primaryMemberJourneysQuery] = useLazyQuery<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>(SEARCH_JOURNEY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadPartnerMemberJourneys, partnerMemberJourneysQuery] = useLazyQuery<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>(SEARCH_JOURNEY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const primaryMember = React.useMemo(() => {
    return primaryMemberQuery.data?.searchMembers?.[0];
  }, [primaryMemberQuery.data]);

  const partnerMember = React.useMemo(() => {
    return partnerMemberQuery.data?.searchMembers?.[0];
  }, [partnerMemberQuery.data]);

  React.useEffect(() => {
    if (primaryMemberQuery.loading || !primaryMemberQuery.called) {
      return;
    }
    
    if (!partnerEmail && !primaryMember) {
      return;
    }

    if (!primaryMember) {
      loadPartnerMember({
        variables: {
          term: partnerEmail,
        },
      });
    }
    else {
      loadPrimaryMemberJourneys({
        variables: {
          input: {
            memberId: primaryMember.id,
            paid: true,
          },
        },
      });
    }
  }, [primaryMember, primaryMemberQuery.loading, primaryMemberQuery.called, partnerEmail]);

  React.useEffect(() => {
    if (partnerMemberQuery.loading || !partnerMemberQuery.called) {
      return;
    }

    if (partnerMember) {
      loadPartnerMemberJourneys({
        variables: {
          input: {
            memberId: partnerMember.id,
            paid: true,
          },
        },
      });
    }
  }, [partnerMember, partnerMemberQuery.loading, , partnerMemberQuery.called]);

  const syncStatus: SyncStatus = React.useMemo(() => {
    if (
      primaryMemberQuery.loading ||
      partnerMemberQuery.loading ||
      primaryMemberJourneysQuery.loading ||
      partnerMemberJourneysQuery.loading
    ) {
      return { type: 'loading' };
    }

    if (!primaryMember && !partnerMember) {
      return {
        type: 'not-found',
        message: 'No primary member and no partner member',
      };
    }

    const memberJourneys = primaryMemberJourneysQuery.data?.listJourneys ?? partnerMemberJourneysQuery.data?.listJourneys;

    // Find most recent paid request.
    const request = memberJourneys?.edges?.reverse()?.[0]?.node?.request;
    
    if (!request) {
      return {
        type: 'not-synced',
        message: 'No paid request',
      };
    }
    
    if (!request.primaryCounsellor) {
      return {
        type: 'not-synced',
        message: 'No primary counsellor',
      };
    }
    
    const foundPrimaryEmail = !!request.journeys.find(journey => journey.member.email?.toLowerCase() === primaryEmail.toLowerCase());
    const journeyStates = request.journeys?.map(journey => journey.state);
    const hasJourneyStateBeforePreTest = !!journeyStates.find(state => ORDERED_JOURNEY_STATES.indexOf(state) <= ORDERED_JOURNEY_STATES.indexOf(JourneyState.RequirePretestBooking));
    
    if (hasJourneyStateBeforePreTest) {
      return {
        type: 'not-synced',
        message: 'Journey state hasn\'t been advanced.',
      };
    }
    
    if (request.productVariant === ProductVariant.Individual) {
      // Single member products can skip partner checks.
      return {
        type: 'synced',
        message: 'Single member product',
      };
    }
    if (partnerEmail) {
      const foundPartnerEmail = !!request.journeys?.find(journey => journey.member.email?.toLowerCase() === partnerEmail?.toLowerCase());

      if (foundPrimaryEmail && foundPartnerEmail) {
        return {
          type: 'synced',
          message: 'Found both primary member and partner member emails',
        };
      }
    }
    else if (foundPrimaryEmail) {
      return {
        type: 'synced',
        message: 'Found primary member email',
      };
    }

    return {
      type: 'not-synced',
      message: 'Reached end of matcher and couldn\'t find synced records',
    };
  }, [primaryMemberQuery, partnerMemberQuery, primaryMemberJourneysQuery, partnerMemberJourneysQuery, primaryMember, partnerMember, primaryEmail, partnerEmail]);

  let label = 'Not found';
  let labelColor: BadgeProps['color'] = 'orange-light';

  switch (syncStatus.type) {
    case 'loading':
      label = 'Searching...';
      labelColor = 'grey-light';
      break;
    case 'not-synced':
      label = 'Not synced';
      labelColor = 'green-lightest';
      break;
    case 'synced':
      label = 'Synced';
      labelColor = 'green';
      break;
  }

  return (
    <Badge
      color={labelColor}
      textColor={syncStatus.type === 'synced' ? 'white' : undefined}
      className="inline-flex items-center"
      title={syncStatus.message}
    >
      {syncStatus.type === 'loading' && <Spinner size={10} className="mr-5" />}
      <span className="mr-3">
        {label}
      </span>
      {syncStatus.type === 'synced' ? <FiCheck /> : null}
    </Badge>
  );
};

export default MemberAcuitySyncBadge;
