import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import Screen from '../../components/widgets/Screen';
import Table from '../../components/widgets/Table';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import useStatsQuery from '../../../utilities/useStatsQuery';
import { Journey, JourneyState, QueryListJourneysArgs } from '../../../graphql/genie-api-types';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';
import _ from 'lodash';
import { isTwoPersonTest } from '../../../utilities/request/request-helpers';
import { CSVLink } from 'react-csv';

interface StatsJourneysConnection {
  edges: {
    node: Journey;
    cursor: string;
  }[];
}

const JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          startDate
          hasConsent
          labOrderNumber
          request {
            product
            productVariant
            journeys {
              id
              member {
                id
                name
              }
            }
          }
          member {
            id
            dateDetailsConfirmedByMember
            name
            sex
            nickname
            dateOfBirth
            email
            phoneNumber
            hasFamilyHistoryGroup
          }
          kits {
            id
            dateCreated
            dateArrivedAtEugene
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const StatsInvitae = () => {
  const journeysQuery = useStatsQuery<StatsJourneysConnection, QueryListJourneysArgs>(
    JOURNEYS_QUERY,
    {
      variables: {
        input: {
          paid: true,
        },
      },
    },
    'listJourneys',
    100
  );

  if (journeysQuery.loading) {
    return <LoadingOverlay label={"Loading " + journeysQuery?.data?.edges?.length.toString() + " journeys..."} />;
  }

  const dispatch = useDispatch();

  const journeys: Journey[] = journeysQuery?.data?.edges?.map(edge => edge.node) ?? [];
  const sortedJourneys = _.orderBy([...journeys.filter(journey => !journey.labOrderNumber )], (journey) => journey.startDate,["desc"]);

  const csvData = [
    ['Paid Date', 'Name', 'Product', 'Current State', 'Partner Invite', 'FHx', 'Consent', 'Sample received', 'Email address', 'Phone number'],
  ];

  return (
    <Screen
      screenTitleProps={{
        title: 'Invitae Stats',
      }}
    >
    <p className='text-lg mb-10'>A list of paid journeys that are currently missing a lab order number</p>
    <CSVLink 
        className='btn text-center btn--md w-1/3 mb-20 apperance-none text-purple border-purple hover:bg-purple bg-white hover:text-white print:hidden' 
        data={csvData}
        filename={"Eugene-invitae-stat-" + moment(new Date()).format("YYYY-MM-DD") + ".csv"}
      >Download as CSV</CSVLink>
    <Table
      header={['Paid Date', 'Name', 'Product', 'Current State', 'Partner Invite', 'FHx', 'Consent', 'Sample received', 'Email address', 'Phone number']}
      rowGroups={[{
        id: 'testgroup',
        rows: sortedJourneys.map(journey => {
          const partnerInvited = (journey.request?.journeys?.filter(journey => !!journey.member && journey.state !== JourneyState.Trash)).length == 1 ? "No" : "Yes";
          const latestKit = _.orderBy([...journey.kits], (kit) => kit.dateCreated,["desc"])[0];
          
          const cellData = [
            moment(journey.startDate).format('Do MMM YY'),
            journey.member?.name,
            journey.request?.productVariant !== null ? journey.request?.product + ' ' + journey.request?.productVariant : journey.request?.product,
            journey.state,
            isTwoPersonTest(journey.request?.productVariant) ? partnerInvited : "-",
            journey.member?.hasFamilyHistoryGroup ? "Yes" : "No",
            journey.hasConsent ? "Yes" : "No",
            !!latestKit?.dateArrivedAtEugene ? "Yes" : "No",
            journey.member?.email,
            journey.member?.phoneNumber
          ];
          
          csvData.push([...cellData]);

          return ({
          id: journey.id,
          cells: cellData,
          isSelectable: true,
          actions: [],
        })}),
      }]}
      onRowSelect={(id: string) => dispatch(navigate.toJourney(id))}
    />
    </Screen>
  );
};

export default StatsInvitae;
