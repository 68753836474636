import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import TaskListScreenTitle from '../../../components/widgets/TaskListScreenTitle';
import { TaskListComponentProps } from '../../../store/report/task-list/types';
import { SummaryStateFieldNames } from '../../../store/report/summary/types';
import { ApplicationState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleTask } from '../../../store/report/task-list/actions';
import { connect } from 'react-redux';
import { PATHS } from '../../../store/router/types';
import EditArea from '../../../components/widgets/EditArea';
import { FormInputProps } from '../../../components/widgets/form/FormInput';
import { getSummaryStateMergedWithOverrides } from '../../../store/report/summary/selectors';
import { resetField } from '../../../store/report/summary/actions';
import Heading from '../../../components/widgets/Heading';
import ActionLink from '../../../components/widgets/ActionLink';
import { navigate } from '../../../store/router/actions';

interface SummaryViewProps extends TaskListComponentProps {
  onFieldClick(fieldName: SummaryStateFieldNames): void;
  onResetClick(fieldName: SummaryStateFieldNames): void;
  summaryStateFields: SummaryStateFields;
  overriddenFieldNames: SummaryStateFieldNames[];
}

type SummaryStateFields = { [key in SummaryStateFieldNames]: string };

interface SummaryFormInputProps extends Pick<FormInputProps, 'label'|'name'|'as'|'options'> {
  name: SummaryStateFieldNames;
}

const formItems: SummaryFormInputProps[] = [
  {
    label: 'Reproductive risk',
    name: 'reproductiveRisk',
    as: 'select',
    options: [
      {
        value: '',
        label: '-',
      },
      {
        value: 'High',
        label: 'High',
      },
      {
        value: 'Low',
        label: 'Low',
      },
      {
        value: 'Increased',
        label: 'Increased',
      },
    ],
  },
  {
    label: 'What we know',
    name: 'whatWeKnow',
    as: 'textarea',
  },
  {
    label: 'What we found',
    name: 'whatWeFound',
    as: 'textarea',
  },
  {
    label: 'Important note',
    name: 'importantNote',
    as: 'textarea',
  },
  {
    label: 'Quick Note',
    name: 'quickNote',
    as: 'textarea',
  },
];

const SummaryView = (props: SummaryViewProps) => {
  return (
    <div>
      <TaskListScreenTitle
        title="Summary"
        isSelected={props.isComplete}
        onToggle={props.onToggleComplete}
      />
      {formItems.map((formItem) => {
        const isOverridden = props.overriddenFieldNames.includes(formItem.name);

        return (
          <div className="mb-20" key={formItem.name}>
            <div className="flex justify-between mb-2">
              <Heading level={3} className="mb-10">{formItem.label}</Heading>
              {isOverridden ? <ActionLink className="text-sm text-blue" onClick={() => props.onResetClick(formItem.name)}>Reset</ActionLink> : ''}
            </div>
            <EditArea
              onClick={() => props.onFieldClick(formItem.name)}
              isOverridden={isOverridden}
            >
              <ReactMarkdown source={props.summaryStateFields[formItem.name]} />
            </EditArea>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ report }: ApplicationState) => {
  const summaryStateFields = getSummaryStateMergedWithOverrides(report.summary);

  return {
    summaryStateFields,
    overriddenFieldNames: report.summary.fieldOverrides ? Object.keys(report.summary.fieldOverrides) : [],
    isComplete: report.taskList.completedTasks.summary,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  onFieldClick: (fieldName: string) => dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_SUMMARY_EDIT, `fieldName=${fieldName}`)),
  onToggleComplete: () => dispatch(toggleTask('summary')),
  onResetClick: (fieldName: SummaryStateFieldNames) => dispatch(resetField(fieldName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
