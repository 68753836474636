import * as React from 'react';
import GraphqlIDE from './dev/GraphqlIDE';
import { PATHS } from '../store/router/types';
import { Switch } from 'react-router';
import Route from '../base/Route';
import PDFPrinter from './dev/PDFPrinter';
import ReportGenerator from './dev/ReportGenerator';

const Dev = () => {
  return (
    <Switch>
      <Route path={PATHS.DEV_PDF_PRINTER} component={PDFPrinter} exact />
      <Route path={PATHS.DEV_REPORT_GENERATOR} component={ReportGenerator} exact />
      <Route path={PATHS.DEV} component={GraphqlIDE} />
    </Switch>
  )
}

export default Dev;
