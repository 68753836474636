import * as React from 'react';
import Heading from '../../../../components/widgets/Heading';
import AwaitingResultsJourneys from '../../../../components/journey/journey-rows/AwaitingResultsJourneys';

const JourneysDuringGroup = () => (
  <div>
    <Heading level={3} className="mb-10">
      Awaiting results <span className="ml-5 text-sm align-middle">👩🏽‍🔬 </span>
    </Heading>
    <AwaitingResultsJourneys />
  </div>
);

export default JourneysDuringGroup;
