export enum ClientDetailsActionTypes {
  COMPLETE_TASK = '@@report/task-list/COMPLETE_TASK',
  UNCOMPLETE_TASK = '@@report/task-list/UNCOMPLETE_TASK',
  TOGGLE_TASK = '@@report/task-list/TOGGLE_TASK',
}

export interface CompletedTaskList {
  clientDetails: boolean;
  carrierRates: boolean;
  analysis: boolean;
  testInformation: boolean;
  summary: boolean;
  customisation: boolean;
  export: boolean;
  familyReport: boolean;
  additionalInformation: boolean;
}

export interface TaskListState {
  completedTasks: CompletedTaskList;
}

export type TaskTypes = 'clientDetails' | 'export' | 'carrierRates' | 'analysis' | 'testInformation' | 'customisation' | 'summary' | 'familyReport' | 'additionalInformation';

export interface TaskListComponentProps {
  onToggleComplete(): void;
  isComplete: boolean;
}
