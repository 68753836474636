import * as React from 'react';
import gql from 'graphql-tag';
import { Member, QueryListJourneysArgs, Request, Query, QuerySearchMembersArgs, QueryRequestArgs } from '../../../../graphql/genie-api-types';
import Spinner from '../../../components/widgets/Spinner';
import { getProductLabel } from '../../../../utilities/journeys/products';
import { formatDateOfBirth } from '../../../../utilities/helpers';
import { FiChevronRight, FiArrowLeft } from '../../../components/widgets/Icon';
import { useLazyQuery } from 'react-apollo';
import Badge from '../../../components/widgets/Badge';
import { DocumentNode } from 'graphql';
import PrivateContent from '../../../components/widgets/PrivateContent';

interface RequestSearchFieldProps {
  showUnpaid?: boolean;
  onSelectRequest(request: Request): void;
  requestQuery: DocumentNode;
  defaultRequest: Request;
}

const MEMBER_SEARCH_QUERY = gql`
query SearchMembers($term: String!) {
  searchMembers(term: $term) {
    id
    name
    dateOfBirth
  }
}
`;

const LIST_JOURNEYS_QUERY = gql`
query ListJourneys($input: ListJourneysInput!) {
  listJourneys(input: $input) {
    edges {
      node {
        id
        state
        request {
          id
          product
          productVariant
          journeys {
            id
            member {
              id
              name
              dateOfBirth
              sex
            }
          }
        }
      }
    }
  }
}
`;

type MemberSearchResult = Pick<Member, 'id' | 'name' | 'dateOfBirth'>;

interface RequestItemProps {
  request: Request;
  onClick(request: Request): void;
}

const RequestItem = (props: RequestItemProps) => {
  const { request, onClick } = props;
  if (!request) {
    return <div>Request not loaded</div>;
  }
  return (
    <button key={request.id} className="p-10 border-b text-left w-full block hover:bg-purple-100" onClick={() => onClick(request)}>
      <div className="font-bold mb-10">{getProductLabel(request.product, request.productVariant)}</div>
      {request.journeys?.map(journey => (
        <Badge className="mr-5" key={journey.id}>
          <PrivateContent>{journey.member.name}</PrivateContent>
        </Badge>
      ))}
    </button>
  );
}

const RequestSearchField = (props: RequestSearchFieldProps) => {
  const { onSelectRequest, defaultRequest } = props;
  const [memberResult, setMemberResult] = React.useState<MemberSearchResult>(null);
  const [selectedRequestId, setSelectedRequestId] = React.useState<string>(null);
  const [memberSearchTerm, setMemberSearchTerm] = React.useState('');
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const [searchMembers, memberQuery] = useLazyQuery<Pick<Query, 'searchMembers'>, QuerySearchMembersArgs>(MEMBER_SEARCH_QUERY);
  const [listJourneys, listJourneysQuery] = useLazyQuery<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>(LIST_JOURNEYS_QUERY);
  const [fetchRequest, fetchRequestQuery] = useLazyQuery<Pick<Query, 'request'>, QueryRequestArgs>(props.requestQuery);
  
  React.useEffect(() => {
    if (!selectedRequestId && defaultRequest) {
      const member = defaultRequest?.journeys?.find(journey => journey.member);
      if (member) {
        setSelectedRequestId(defaultRequest.id);
        setMemberResult(member);
      }
    }
  }, [defaultRequest]);

  React.useEffect(() => {
    onSelectRequest(fetchRequestQuery.data?.request);
  }, [fetchRequestQuery.data]);

  React.useEffect(() => {
    searchMembers({
      variables: {
        term: memberSearchTerm,
      }
    });
    if (memberSearchTerm.length > 0) {
      setIsDropdownOpen(true);
    }
  }, [memberSearchTerm]);
  
  React.useEffect(() => {
    if (selectedRequestId) {
      fetchRequest({ 
        variables: {
          id: selectedRequestId,
        },
      });
    }
  }, [selectedRequestId]);
  
  React.useEffect(() => {
    if (memberResult) {
      listJourneys({
        variables: {
          input: {
            memberId: memberResult.id,
          },
        },
      });
    }
  }, [memberResult]);
  
  if (selectedRequestId && !isDropdownOpen) {
    return (
      <div className="bg-white border">
        {fetchRequestQuery.loading ? <Spinner className="m-20" label="Loading request..." /> : (
        <div className="bg-white border">
          <RequestItem request={fetchRequestQuery.data?.request} onClick={() => { setIsDropdownOpen(true); }} />
        </div>
      )}
      </div>
    );
  }

  return (
    <div>
      <div className="relative">
        <div className="relative shadow">
          {memberResult ? (
            <div className="p-10 py-15 border w-full font-bold bg-white flex items-center">
              <button onClick={() => setMemberResult(null)} className="pr-12">
                <FiArrowLeft />
              </button>
              <PrivateContent>{memberResult.name}</PrivateContent>
            </div>
          ) : (
            <input
              type="text"
              onChange={(e) => setMemberSearchTerm(e.target.value)}
              value={memberSearchTerm}
              placeholder="Find a request..."
              className="p-10 border w-full focus:outline-none font-bold"
              disabled={!!memberResult}
            />
          )}
          {memberQuery.loading && <Spinner className="absolute right-0 top-0 p-15" />}
        </div>
        {isDropdownOpen && (
          <div className="absolute bg-white z-100 w-full shadow overflow-y-auto" style={{maxHeight: '300px'}}>
           {memberSearchTerm.length > 0 && !memberResult && (
              <div>
                {(memberQuery?.data?.searchMembers ?? []).length === 0 && (
                  <div className="p-10 text-grey-dark">No members found for &quot;{memberSearchTerm}&quot;...</div>
                )}
                {memberQuery?.data?.searchMembers?.map(result => (
                  <button
                    className="p-10 w-full flex items-center justify-between focus:outline-none border-b hover:bg-purple-100"
                    type="button"
                    onClick={() => setMemberResult(result)}
                    key={result.id}
                  >
                    <PrivateContent><span>{result.name} {result.dateOfBirth && <span>({formatDateOfBirth(result.dateOfBirth)})</span>}</span></PrivateContent>
                    <FiChevronRight />
                  </button>
                ))}
              </div>
            )}
            {memberResult && (
              <div>
                {listJourneysQuery.loading && <Spinner className="m-10" />}
                {listJourneysQuery.data?.listJourneys?.edges?.map(edge => edge?.node?.request)?.filter(request => !!request).map(request => (
                  <RequestItem key={request.id} request={request} onClick={(selectedRequest) => { setSelectedRequestId(selectedRequest.id); setIsDropdownOpen(false); }} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestSearchField;
