import { AmplifyAuth } from './amplify/auth';

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import 'cross-fetch/polyfill';
import pThrottle from 'p-throttle';
import config from './config';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.log('GraphQL Error: ' + message));

  if (networkError) console.log('Network Error: ' + networkError.message);
});

const httpLink = createHttpLink({
  uri: config('APP_SYNC_API_URL'),
  fetch: pThrottle(
    (uri: string, config) => {
      return fetch(uri, config);
    },
    10, // Max. concurrent Requests
    1000 // Within a 1 second window.
  ),
});

const authLink = setContext(async (req, { headers }) => {
  const amplifySession = await AmplifyAuth.currentSession();
  const token = await amplifySession.getIdToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

export const appSyncClient = new ApolloClient({
         link: authLink.concat(errorLink).concat(httpLink),
         cache: new InMemoryCache(),
       });
