import React, { PureComponent } from 'react';
import { FiChevronDown, FiChevronUp } from './Icon';

interface MarkdownHelperState {
  isExpanded: boolean;
}

class MarkdownHelper extends PureComponent<{}, MarkdownHelperState> {
  state: Readonly<MarkdownHelperState> = {
    isExpanded: false,
  };

  onToggleExpand = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  render() {
    const { isExpanded } = this.state;

    return (
      <div>
        <button onClick={this.onToggleExpand} type="button" className="focus:outline-none pb-10 flex items-center">
          Markdown help {isExpanded ? <FiChevronUp className="ml-10" /> : <FiChevronDown className="ml-10" /> }
        </button>
        <div className="text-xs mb-10" style={{ display: isExpanded ? 'block' : 'none' }}>
          <strong className="mb-5 mr-5 inline-block">*bold*</strong>
          <em className="mb-5 mr-5 inline-block">_italics_</em>
          <span className="mb-5 mr-5 inline-block disease-label-low-risk">[Disease Name](#disease-label-low-risk)</span>
          <span className="mb-5 mr-5 inline-block disease-label-high-risk">[Disease Name](#disease-label-high-risk)</span>
          <span className="mb-5 mr-5 inline-block disease-label-increased-risk">[Disease Name](#disease-label-increased-risk)</span>
          <span className="disease-label-result-to-note inline-block">[Disease Name](#disease-label-result-to-note)</span>
        </div>
      </div>
    );
  }
}

export default MarkdownHelper;
