import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import findMemberAddress, { ShopifyAddress } from '../../../utilities/shopify/findMemberAddress';
import { FiSearch } from '../widgets/Icon';
import Spinner from '../widgets/Spinner';

interface ShopifyMemberSearchProps {
  defaultSearchTerm: string;
}

const addressBoxStyle: React.CSSProperties = {
  maxHeight: '200px',
  overflowY: 'scroll',
};

const ShopifyMemberSearch = (props: ShopifyMemberSearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isLoadingAddresses, setIsLoadingAddresses] = React.useState(false);
  const [addresses, setAddresses] = React.useState<ShopifyAddress[]>([]);

  React.useEffect(() => {
    setSearchTerm(props.defaultSearchTerm)
  }, [props.defaultSearchTerm, setSearchTerm]);

  const onSearchTermChange = useDebouncedCallback(async (term: string) => {
    setIsLoadingAddresses(true);
    const shopifyResults = await findMemberAddress(term);
    setIsLoadingAddresses(false);
    setAddresses(shopifyResults);
  }, 300);
  
  const onSearchInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, [setSearchTerm]);

  React.useEffect(() => {
    onSearchTermChange.callback(searchTerm);
  }, [searchTerm, onSearchTermChange]);

  return (
    <div className="bg-white rounded mt-20">
      <div className="relative">
        <input
          type="text"
          placeholder="Search shopify..."
          className="p-5 pl-40 w-full border-b"
          defaultValue={searchTerm}
          onChange={onSearchInputChange}
        />
        <FiSearch className="absolute top-0 left-0 m-15" />
      </div>
      <div style={addressBoxStyle}>
        {isLoadingAddresses && <Spinner className="p-10" label="Loading addresses..." />}
        {addresses.map(address => (
          <div className="border-b pb-10 mt-10 p-10" key={address.id}>
            <div>{address.first_name} {address.last_name}</div>
            <div>{address.address1}</div>
            <div>{address.address2}</div>
            <div>{address.city} {address.province} {address.zip}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopifyMemberSearch;
