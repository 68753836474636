import * as React from 'react';

const ActionLink = ({ onClick, ...props }: React.HTMLAttributes<HTMLAnchorElement>) => (
  <a {...props} href="#" onClick={(e) => {
    onClick(e);
    e.preventDefault();
  }}
  >
    {props.children}
  </a>
);

export default ActionLink;
