import * as React from 'react';
import GeniePagedQuery from '../../base/GeniePagedQuery';
import { Query, QueryListDoctorsArgs, Doctor, ListDoctorsInput } from '../../../../graphql/genie-api-types';
import gql from 'graphql-tag';

type DoctorPartial = Pick<Doctor, 'id' | 'nameFirst' | 'nameMiddle' | 'nameLast' | 'nickname' | 'specialties' | 'title' | 'clinics'>;

export const DOCTOR_LIST_QUERY = gql`
  query ListDoctors($input: ListDoctorsInput!) {
    listDoctors(input: $input) {
      edges {
        cursor
        node {
          id
          nameFirst
          nameMiddle
          nameLast
          nickname
          specialties
          title
          clinics {
            id
            name
            addressCountry
            addressPostcode
            addressState
            addressState
            addressSuburb
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

interface DoctorListProps {
  children(doctors: DoctorPartial[], isLoading: boolean, reloadData: () => void): React.ReactNode;
  searchParams: ListDoctorsInput;
}

const DoctorList = (props: DoctorListProps) => {
  return (
    <GeniePagedQuery<Pick<Query, 'listDoctors'>, QueryListDoctorsArgs>
      query={DOCTOR_LIST_QUERY}
      variables={{
        input: props.searchParams,
      }}
      notifyOnNetworkStatusChange
      errorPolicy="all"
      itemsPerPage={20}
      pageInfoSelector="listDoctors.pageInfo"
      edgeSelector="listDoctors.edges"
    >
      {(data, isLoading, reloadData) => props.children(data?.listDoctors?.edges?.map(edge => edge.node), isLoading, reloadData)}
    </GeniePagedQuery>
  );
};

export default DoctorList;
