import * as React from 'react';
import { JourneyState } from '../../../graphql/genie-api-types';
import AmendedReportsTaskTable from '../../components/report/AmendedReportsTaskTable';
import Screen from '../../components/widgets/Screen';

const TasksAmendedReports = () => {
  return (
    <Screen
      screenTitleProps={{
        title: 'Amended reports',
      }}
    >
      <AmendedReportsTaskTable journeyState={JourneyState.ReadyForReport} tableTitle="Ready for report" />
      <AmendedReportsTaskTable journeyState={JourneyState.ReadyForReportReview} tableTitle="Ready for report review" />
    </Screen>
  );
};

export default TasksAmendedReports;
