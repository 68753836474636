import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { useDispatch } from 'react-redux';
import { Journey, Kit, Member, QueryJourneyArgs, Request } from '../../../../graphql/genie-api-types';
import { formatPartnerConsent } from '../../../../utilities/helpers';
import { labOrderNumberUrl } from '../../../../utilities/journeys/selectors';
import JourneyShowContext from '../../../contexts/JourneyShowContext';
import { navigate } from '../../../store/router/actions';
import { FiChevronRight } from '../../widgets/Icon';

interface QueryResult {
  journey: Pick<Journey, 'id' | 'hasConsent' | 'labOrderNumber' | 'partnerCanReceiveResults'> & {
    kits: Pick<Kit, 'id' | 'dateArrivedAtEugene' | 'dateShippedToMember' | 'recollectionRequestedDate' | 'dateShippedToLab'>[];
    request: Pick<Request, 'id' | 'gcApprovalDate' | 'datePaid' | 'productVariant'>;
    member: Pick<Member, 'id' | 'onlineAccessEnabled'>;
  };
}

const QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      hasConsent
      labOrderNumber
      partnerCanReceiveResults
      kits {
        id
        dateArrivedAtEugene
        dateShippedToMember
        recollectionRequestedDate
        dateShippedToLab
      }
      member {
        id
        onlineAccessEnabled
      }
      request {
        id
        gcApprovalDate
        datePaid
        productVariant
      }
    }
  }
`;

const JourneyShowStates = () => {
  const { journeyId } = React.useContext(JourneyShowContext);
  const journeyQuery = useQuery<QueryResult, QueryJourneyArgs>(QUERY, {
    variables: {
      id: journeyId,
    },
  });

  const journey = journeyQuery?.data?.journey;
  const dispatch = useDispatch();

  if (!journey) {
    return null;
  }

  const renderStateButton = (isEnabled: boolean, label: string, action: () => {}, tooltip = '') => {
    return (
      <button
        className={`${isEnabled ? 'text-green' : 'text-orange'} hover:underline focus:outline-none`}
        onClick={action}
        title={tooltip}
      >
        {label}
      </button>
    );
  }

  const renderUserStateDateButton = (dateString: string | boolean, successLabel: string, failLabel: string, action: () => {}) => {
    const isEnabled = !!dateString;
    const label = dateString ? successLabel : failLabel;
    const tooltip = dateString && typeof dateString === 'string' ? moment(dateString).format('Do MMM YYYY') : '';

    return renderStateButton(isEnabled, label, action, tooltip);
  }

  const request = journey?.request;
  const member = journey?.member;
  const datePaid = request?.datePaid;
  const hasConsent = journey?.hasConsent;
  const approvalDate = request?.gcApprovalDate;
  const hasOnlineAccess = journey?.member?.onlineAccessEnabled;

  const onNavigateToRequestPaidForm = () => dispatch(navigate.toForm('RequestPaid', {
    requestId: request?.id,
  }));

  const onNavigateToOnlineAccessForm = () => dispatch(navigate.toForm('MemberOnlineAccess', {
    memberId: member?.id,
  }));

  const onNavigateToJourneyConsentForm = () => dispatch(navigate.toForm('JourneyConsent', {
    journeyId,
  }));

  const onNavigateToJourneyUpdateForm = () => dispatch(navigate.toForm('JourneyUpdate', {
    journeyId,
  }));

  const onNavigateToRequestApprovalForm = () => dispatch(navigate.toForm('RequestApproval', {
    requestId: request?.id,
  }));

  const onNavigateToLabOrderNumber = () => dispatch(navigate.toForm('JourneyLabOrderNumber', {
    journeyId,
  }));

  const showPartnerConsent = ["DONOR", "COUPLE"].includes(request?.productVariant);

  return (
    <>
      <div className="text-grey text-xs mt-30 text-right">
        {renderUserStateDateButton(datePaid, 'Purchased', 'Not Purchased', onNavigateToRequestPaidForm)}{' / '}
        {renderUserStateDateButton(hasOnlineAccess, 'Has login', 'No login', onNavigateToOnlineAccessForm)}{' / '}
        {showPartnerConsent && 
          <button
            className={`${formatPartnerConsent(journey.partnerCanReceiveResults, 'text-green', 'text-red', 'text-orange')} hover:underline focus:outline-none`}
            onClick={onNavigateToJourneyUpdateForm}
          >
            {formatPartnerConsent(journey.partnerCanReceiveResults, 'Can share result', 'Can\'t share result', 'Missing result consent')}
          </button>}
        {showPartnerConsent && ' / '} 
        {renderUserStateDateButton(hasConsent, 'Consented', 'No Consent', onNavigateToJourneyConsentForm)}{' / '}
        {renderUserStateDateButton(approvalDate, 'Approved', 'Awaiting Approval', onNavigateToRequestApprovalForm)}
      </div>
      <div className="text-xs text-grey font-nm mt-15 text-right">
        Lab Order #: {renderStateButton(!!journey.labOrderNumber, journey.labOrderNumber || 'Not yet added', onNavigateToLabOrderNumber)}
        {journey.labOrderNumber && (
          <a target="_blank" href={labOrderNumberUrl(journey)} rel="noopener noreferrer" className="text-purple ml-5 bg-grey-light p-3 px-5 text-xs border rounded hover:text-white hover:bg-purple">
            Invitae <FiChevronRight />
          </a>
        )}
      </div>
    </>
  );
};

export default JourneyShowStates;
