import * as React from 'react';
import moment from 'moment';
import { StatsProps } from './Stats';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { RequestsConnection, QueryListRequestsArgs, Request, JourneyState, Product } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { PRODUCT_LIST } from '../../../utilities/journeys/products';

interface ChartDataItem {
  week: string;
  referred: number;
  notReferred: number;
}

interface ChartData {
  items: { [key: string]: ChartDataItem };
};

interface FunnelStats {
  requestsWithoutReferral: {
    total: number;
    converted: number;
  };
  requestsWithReferral: {
    total: number;
    converted: number;
  };
  chartData: ChartData;
}

type FunnelStatsByProduct = { [key in Product | 'all']?: FunnelStats };

interface RequestStatsItemProps {
  funnelStats: FunnelStats;
  label: string;
}

const weekFormat = 'YYYY.ww';
const colors = ['#6269EE', '#FFB258', '#7F7F9E', '#FD5A5A'];

const RequestStatsItem = (props: RequestStatsItemProps) => {
  const { funnelStats } = props;

  const dataKeys = ['Referrals', 'Direct'];
  const items = Object.keys(funnelStats.chartData.items).sort().map((weekString) => {
    const weekMoment = moment(weekString, weekFormat);
    return {
      week: weekMoment.format('MMM DD'),
      'Referrals': funnelStats.chartData.items?.[weekString]?.referred ?? 0,
      'Direct': funnelStats.chartData.items?.[weekString]?.notReferred ?? 0,
    };
  });

  return (
    <tbody>
      <tr>
        <td colSpan={2}>
          <AreaChart
            width={1000}
            height={500}
            data={items}
            margin={{
              top: 100, right: 30, left: 0, bottom: 0,
            }}
          >
            {dataKeys.map((dataKey, index) => (
              <Area type="monotone" dataKey={dataKey} fill={colors[index]} stroke={colors[index]} key={dataKey} stackId="1" />
            ))}
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            <XAxis dataKey="week" />
            <Tooltip />
          </AreaChart>
        </td>
      </tr>
      <tr>
        <td><h2 className="text-lg font-bold">{props.label}</h2></td>
        <td className="text-right font-bold py-10 text-lg">{funnelStats.requestsWithReferral.total + funnelStats.requestsWithoutReferral.total}</td>
      </tr>
      <tr>
        <td className="border-t pt-10">Requests <strong>with</strong> referring doctor sale / lead</td>
        <td className="text-right font-bold py-10 border-t">{funnelStats.requestsWithReferral.converted} / {funnelStats.requestsWithReferral.total} ({Math.round(((funnelStats.requestsWithReferral.converted / funnelStats.requestsWithReferral.total) || 0) * 100)}%)</td>
      </tr>
      <tr>
        <td className="border-t pt-10 pb-40">Requests <strong>without</strong> referring doctor sale / lead</td>
        <td className="text-right font-bold py-10 border-t pb-40">{funnelStats.requestsWithoutReferral.converted} / {funnelStats.requestsWithoutReferral.total} ({Math.round(((funnelStats.requestsWithoutReferral.converted / funnelStats.requestsWithoutReferral.total) || 0) * 100)}%)</td>
      </tr>
    </tbody>
  );
};

const RequestStats = (props: StatsProps) => {
  const [allRequests, setAllRequests] = React.useState<Pick<Request, 'id' | 'dateCreated' | 'datePaid' | 'referringDoctorClinic' | 'pregnancy' | 'product' | 'productVariant'>[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { fromDate, toDate } = props;

  const getAllRequests = async () => {
    const results = await runStatsQuery<RequestsConnection, QueryListRequestsArgs>(gql`
    query ListRequests($input: ListRequestsInput!) {
      listRequests(input: $input) {
        edges {
          cursor
          node {
            id
            datePaid
            dateCreated
            product
            productVariant
            referringDoctorClinic {
              id
            }
            pregnancy {
              days
            }
            journeys {
              id
              state
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
    `, {
      variables: {
        input: {},
      },
    }, 'listRequests');
    const requests = results.edges.map(edge => edge.node).filter(request =>
      request.journeys?.find(journey =>
        journey.state !== JourneyState.Trash)
    );
    setAllRequests(requests);
    setIsLoading(false);
  };

  React.useEffect(() => { getAllRequests(); }, []);

  const dateRangeRequests = React.useMemo(() => {
    return allRequests.filter(request => {
      const reportDate = moment(request.dateCreated).startOf('day');
      return reportDate.isSameOrAfter(fromDate) && reportDate.isSameOrBefore(toDate);
    });
  }, [allRequests, fromDate, toDate]);

  const funnelStats: FunnelStatsByProduct = React.useMemo(() => {
    const startWeek = moment(fromDate).clone().startOf('week');
    const endWeek = moment(toDate).clone().add(1, 'week').startOf('week');
    const weeks = endWeek.diff(startWeek, 'weeks');

    const funnelStatsByProduct: FunnelStatsByProduct = {};
    PRODUCT_LIST.forEach((productCode: Product) => {
      funnelStatsByProduct[productCode] = {
        requestsWithoutReferral: {
          total: 0,
          converted: 0,
        },
        requestsWithReferral: {
          total: 0,
          converted: 0,
        },
        chartData: {
          items: {},
        },
      };
      for (let i = 0; i < weeks; i++) {
        const date = startWeek.clone().add(i, 'weeks');
        const weekString = date.format(weekFormat);
        funnelStatsByProduct[productCode].chartData.items[weekString] = {
          week: weekString,
          referred: 0,
          notReferred: 0,
        };
      }
    });

    const allStats: FunnelStats = {
      requestsWithoutReferral: {
        total: 0,
        converted: 0,
      },
      requestsWithReferral: {
        total: 0,
        converted: 0,
      },
      chartData: {
        items: {},
      },
    };

    for (let i = 0; i < weeks; i++) {
      const date = startWeek.clone().add(i, 'weeks');
      const weekString = date.format(weekFormat);
      allStats.chartData.items[weekString] = {
        week: weekString,
        referred: 0,
        notReferred: 0,
      };
    }

    dateRangeRequests.forEach(request => {
      const dateCreatedKey = moment(request.dateCreated).format(weekFormat);
      const countOutput = request.referringDoctorClinic ? allStats.requestsWithReferral : allStats.requestsWithoutReferral;
      countOutput.total += 1;
      allStats.chartData.items[dateCreatedKey][request.referringDoctorClinic ? 'referred' : 'notReferred'] += 1;
      
      if (request.datePaid) {
        countOutput.converted +=1;
      }

      const productStats = funnelStatsByProduct?.[request.product];
      if (productStats) {
        const productCountOutput = request.referringDoctorClinic ? productStats.requestsWithReferral : productStats.requestsWithoutReferral;
        productCountOutput.total += 1;
        if (request.datePaid) {
          productCountOutput.converted += 1;
        }

        funnelStatsByProduct[request.product].chartData.items[dateCreatedKey][request.referringDoctorClinic ? 'referred' : 'notReferred'] += 1;
      }
    });

    funnelStatsByProduct['all'] = allStats;

    return funnelStatsByProduct;
  }, [fromDate, toDate, dateRangeRequests]);

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
          <>
            <table className="w-full">
              <RequestStatsItem funnelStats={funnelStats.all} label="All Requests" />
              {PRODUCT_LIST.map((productCode: Product) =>
              (
                <>
                  <RequestStatsItem funnelStats={funnelStats[productCode]} label={productCode} key={productCode} />
                </>
              )
              )}
            </table>
          </>
        )}
    </>
  );
}

export default RequestStats;