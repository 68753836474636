import * as React from 'react';
import { FiArrowLeft, FiArrowRight, FiSearch, FiLink, FiTrash, FiEye, FiEyeOff } from '../../components/widgets/Icon';
import { connect, useDispatch } from 'react-redux';
import { PATHS } from '../../store/router/types';
import RouteButton from '../../components/widgets/RouteButton';
import { ApplicationState } from '../../store';
import { withRouter, RouteComponentProps, matchPath, NavLink } from 'react-router-dom';
import { NavigationState } from '../../store/navigation/types';
import GenieQuery from '../../graphql/base/GenieQuery';
import gql from 'graphql-tag';
import { Query, JourneyState, Journey, QueryJourneyArgs } from '../../../graphql/genie-api-types';
import { getProductLabel } from '../../../utilities/journeys/products';
import Tooltip from '../../components/widgets/Tooltip';
import { formatSex, formatDateOfBirth, copyToClipboard } from '../../../utilities/helpers';
import { navigate } from '../../store/router/actions';
import PrivacyContext, { PrivacyContextValue } from '../../base/PrivacyContext';
import PrivateContent from '../../components/widgets/PrivateContent';
import Badge from '../../components/widgets/Badge';

interface StateProps extends NavigationState {
  canGoBack: boolean;
  canGoForward: boolean;
}

interface TopToolbarProps {

}

type AllProps = StateProps & TopToolbarProps & RouteComponentProps;

interface TopToolbarButtonProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick(): void;
}

type JourneyMatch = {
  id: string;
};

const TopToolbarButton: React.FC<TopToolbarButtonProps> = (props: TopToolbarButtonProps) => (
  <button
    className={`py-3 px-4 focus:outline-none hover:bg-grey-light flex items-center rounded ${props.isDisabled ? 'text-grey' : ''}`}
    onClick={props.isDisabled ? null : props.onClick}
  >
    {props.children}
  </button>
);

// interface DoctorShowNavProps {
//   doctorId: Doctor['id'];
// }

// const DoctorShowNav: React.FC<DoctorShowNavProps> = () => {
//   return (
//     <RouteButton
//       isUnstyled
//       path={PATHS.DOCTORS}
//       className="relative z-10 focus:outline-none ml-2 inline-block"
//     >
//       Doctors
//     </RouteButton>
//   );
// }

interface JourneyShowNavProps {
  journeyId: Journey['id'];
}

const JourneyShowNav: React.FC<JourneyShowNavProps> = (props: JourneyShowNavProps) => (
  <GenieQuery<Pick<Query, 'journey'>, QueryJourneyArgs>
    query={gql`
      query Journey($id: ID!) {
        journey(id: $id) {
          id
          member {
            id
            name
            dateOfBirth
            sex
          }
        }
      }
   `}
    variables={{
      id: props.journeyId,
    }}
  >
    {
      (result) => {
        if (result.loading) {
          return <div>Loading member ...</div>;
        }
        const member = result?.data?.journey?.member;
        if (!member) {
          return <Badge color="orange-light">Memberless journey</Badge>
        }
        return (
          <Tooltip
            label={`${formatDateOfBirth(member?.dateOfBirth)} ${formatSex(member?.sex)}`}
            placement="bottom"
            appearance="light"
          >
            <PrivateContent>{member?.name}</PrivateContent>
          </Tooltip>
        );
      }
    }
  </GenieQuery>
);

interface JourneyShowRequestNavProps {
  journeyId: Journey['id'];
}

const JourneyShowRequestNav: React.FC<JourneyShowRequestNavProps> = (props: JourneyShowRequestNavProps) => {
  const [showTrashed, setShowTrashed] = React.useState(false);

  const dispatch = useDispatch();
  const onToggleTrashed = React.useCallback(() => setShowTrashed(!showTrashed), [setShowTrashed, showTrashed]);

  return (
    <GenieQuery<Pick<Query, 'journey'>, QueryJourneyArgs>
      query={gql`
      query Journey($id: ID!) {
        journey(id: $id) {
          id
          member {
            id
            journeys {
              id
              state
              request {
                id
                product
                productVariant
              }
              relatedJourneys {
                id
                member {
                  id
                  name
                  dateOfBirth
                  sex
                }
              }
            }
          }
        }
      }
   `}
      variables={{
        id: props.journeyId,
      }}
    >
      {
        (result) => {
          if (result.loading) {
            return <div>Loading requests ...</div>;
          }
          const journeys = result?.data?.journey?.member?.journeys ?? [];
          const trashCount = journeys?.filter(journey => journey?.state === JourneyState.Trash)?.length;
          const shownJourneys = journeys?.filter(journey => showTrashed ? true : journey?.state !== JourneyState.Trash);
          return (
            <div className="text-grey-dark text-right">
              {shownJourneys?.map(journey => {
                const request = journey.request;
                const relatedJourneys = journey.relatedJourneys ?? [];
                return (
                  <NavLink
                    exact={true}
                    key={request.id}
                    activeClassName={journey.state === JourneyState.Trash ? 'border-red' : 'border-purple'}
                    to={PATHS.JOURNEY_SHOW.replace(':journeyId', journey.id)}
                    className={`pb-2 border-b-2 border-transparent inline-block ml-10 ${journey.state === JourneyState.Trash ? 'text-red' : 'text-purple'}`}
                  >
                    {getProductLabel(request.product, request.productVariant)}
                    {relatedJourneys.length ? (
                      <span className="ml-2 inline-block">
                        with&nbsp;
                        <strong>{relatedJourneys.map(relatedJourney => (
                          <Tooltip
                            key={relatedJourney?.member?.id ?? 'memberless'}
                            label={`${formatDateOfBirth(relatedJourney?.member?.dateOfBirth)} ${formatSex(relatedJourney?.member?.sex)}`}
                            placement="bottom"
                            appearance="light"
                          >
                            <span className="font-bold hover:text-black" onClick={(e) => { e.stopPropagation(); e.preventDefault(); dispatch(navigate.toJourney(relatedJourney.id)); }}>
                              <PrivateContent>{relatedJourney?.member?.name ?? 'Memberless journey'}</PrivateContent>
                            </span>
                          </Tooltip>
                        ))}</strong>
                      </span>
                    ) : null}
                  </NavLink>
                );
              })}
              {trashCount > 0 && (
                <button
                  className={`ml-10 border border-transparent rounded inline-flex focus:outline-none items-center py-2 px-5  ${showTrashed ? 'bg-red text-white' : 'text-red'} text-xxs hover:border-red`}
                  type="button"
                  onClick={onToggleTrashed}
                >
                  <FiTrash className="mr-2 -mt-1" /> {trashCount}x
                </button>
              )}
            </div>
          );
        }
      }
    </GenieQuery>
  );
}

interface NavExtractor {
  path: string;
  keys?: string[];
  Component: React.FC;
}

const FamilyHistoryLabel = () =>
  <>Family history</>;

const SearchLabel = () => (
  <>
    <FiSearch className="mr-3" size={12} /> <span>Search</span>
  </>
);

const ShippingToolbar: React.FC<{}> = () => (
  <RouteButton
    isUnstyled
    path={PATHS.LAB_SHIPPING}
    className="relative z-10 focus:outline-none ml-2 inline-block"
  >
    Lab Shipping Journeys
  </RouteButton>
);

const navExtractors: NavExtractor[] = [
  {
    path: PATHS.WELCOME,
    Component: SearchLabel,
  },
  {
    path: PATHS.JOURNEY_SHOW,
    keys: ['journeyId'],
    Component: JourneyShowNav,
  },
  {
    path: PATHS.MEMBER_FAMILY_HISTORY,
    keys: ['memberId'],
    Component: FamilyHistoryLabel,
  },
  {
    path: PATHS.LAB_SHIPPING,
    Component: ShippingToolbar,
  },
  // {
  //   path: PATHS.DOCTOR_SHOW,
  //   keys: ['doctorShow'],
  //   Component: DoctorShowNav,
  // },
];

const metaExtractors: NavExtractor[] = [
  {
    path: PATHS.JOURNEY_SHOW,
    keys: ['journeyId'],
    Component: JourneyShowRequestNav,
  },
];

const attachMatch = (location: Location, navExtractor: NavExtractor) => {
  const { path } = navExtractor;
  const match = matchPath(location.pathname, {
    path,
  });

  return { match, navExtractor };
}

const TopToolbar: React.FC<AllProps> = (props: AllProps) => {
  const { history } = props;
  const [memberId, setMemberId] = React.useState('');
    
  const privacyContext = React.useContext<PrivacyContextValue>(PrivacyContext);

  const journeyMatch = matchPath(props.location.pathname, {
    path: PATHS.JOURNEY_SHOW,
  });

  if (journeyMatch) {
    const thisMemberId = (journeyMatch.params as JourneyMatch).id;

    if (thisMemberId !== memberId) {
      setMemberId(thisMemberId);
    }
  }

  const onCopyUrl = React.useCallback(() => {
    copyToClipboard(window.location.href);
  }, [window.location.href]);

  const navItems: React.ReactNode[] = navExtractors.map(navExtractor => attachMatch(location, navExtractor)).filter(({ match }) => match).map(({ match, navExtractor }) => {
    const { Component, keys } = navExtractor;
    const componentProps: { [key: string]: string } = {};
    keys?.forEach(key => {
      componentProps[key] = (match.params as any)[key];
    });

    return (
      <TopToolbarButton
        onClick={() => history.location.pathname === match.url ? history.replace(match.url) : history.push(match.url)}
        key={match.url}
      >
        <Component {...componentProps} />
      </TopToolbarButton>
    );
  });

  const metaItems = metaExtractors.map(navExtractor => attachMatch(location, navExtractor))
    .filter(({ match }) => match)
    .map(({ match, navExtractor }) => {
      const { Component, keys } = navExtractor;
      const componentProps: { [key: string]: string } = {};
      keys?.forEach(key => {
        componentProps[key] = (match.params as any)[key];
      });
      return (
        <Component {...componentProps} key={match.url} />
      );
    });

  return (
    <div className="lg:mt-50 fixed top-0 right-0 lg:left-0 left-sidebar bg-white z-50 py-20 px-40 text-xs print:hidden">
      <div className="max-w-3xl xxl:max-w-5xl flex items-center">
        <div className="py-10 -ml-4 mr-10 flex">
          <TopToolbarButton onClick={props.history.goBack} isDisabled={!props.canGoBack}>
            <FiArrowLeft />
          </TopToolbarButton>
          <TopToolbarButton onClick={props.history.goForward} isDisabled={!props.canGoForward}>
            <FiArrowRight />
          </TopToolbarButton>
        </div>
        {navItems.map((item, index) => (
          <React.Fragment key={`breadcrumb${index}`}>
            {item}
            {index < navItems.length - 1 ? <div className="mx-5 text-grey text-xxs"> / </div> : null}
          </React.Fragment>
        ))}
        <div className="flex pl-4 ml-auto mr-0 items-start">
          <div className="py-3 relative">
            {metaItems}
          </div>
          <button
            className={`py-3 relative ml-10 hover:text-white hover:bg-red rounded-full -mr-3 p-3 flex items-center justify-center focus:outline-none ${privacyContext.isPrivateModeActive ? 'px-10 bg-red text-white font-bold' : 'text-grey-dark'}`}
            onClick={privacyContext.onPrivateModeToggle}
            title="Private mode"
          >
            {privacyContext.isPrivateModeActive ? <><FiEyeOff className="mr-5" /> Private mode</> : <FiEye />}
          </button>
          <button
            className="py-3 relative ml-10 text-grey-dark hover:text-black hover:bg-grey-lightest rounded-full -mr-3 p-3 flex items-center justify-center focus:outline-none"
            onClick={onCopyUrl}
          >
            <FiLink />
          </button>
        </div>
      </div>
    </div >
  );
}


const mapStateToProps = (state: ApplicationState) => ({
  canGoBack: state.navigation.historyIndex > -1,
  canGoForward: state.navigation.history.length && state.navigation.historyIndex < state.navigation.history.length - 1,
  ...state.navigation,
});

export default connect(mapStateToProps)(withRouter(TopToolbar));