import * as React from 'react';
import { AreaChart, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Area, BarChart, Bar } from 'recharts';
import moment, { Moment } from 'moment';
import runStatsQuery from '../../../utilities/runStatsQuery';
import { FamilyHistorySource, Journey, JourneyState, Kit, KitsConnection, QueryListKitsArgs } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LoadingOverlay from '../../components/widgets/LoadingOverlay';
import { median, percentage } from '../../../utilities/helpers';
import { isTwoPersonTest } from '../../../utilities/request/request-helpers';
import { TooltipFormatter } from './RecollectionStats';
import { StatsProps } from './Stats';

const formatMonthString = (moment: moment.Moment) =>
  `${moment.year()}.${(moment.month() + 1).toString().padStart(2, '0')}`

interface ChartData {
  dataKeys: string[];
  items: { [key: string]: number | string }[];
  tooltipFormatter?: TooltipFormatter;
}

const reasonsJourneyIsBlocked = (journey: Journey, arrivedAtEugene: Moment) => {
  const request = journey?.request;
  const member = journey?.member;

  const memberLoginDate = member.dateDetailsConfirmedByMember;
  const isMemberLoginBlocked = member.onlineAccessEnabled && (!memberLoginDate || moment(memberLoginDate).isAfter(arrivedAtEugene));

  const familyHistorySubmissionDate = journey?.member?.familyHistoryGroup?.find(familyHistoryGroupItem => familyHistoryGroupItem.source === FamilyHistorySource.April)?.dateCreated;
  const isFhxBlocked = !familyHistorySubmissionDate || moment(familyHistorySubmissionDate).isAfter(arrivedAtEugene);

  const consentDate = journey?.consents?.filter(consent => !consent.dateWithdrawn)?.[0]?.dateCreated;
  const isConsentBlocked = !consentDate || moment(consentDate).isAfter(arrivedAtEugene);

  const approvalDate = request && request.gcApprovalDate;
  const isApprovalBlocked = !isFhxBlocked && !isConsentBlocked && approvalDate && moment(approvalDate).isAfter(arrivedAtEugene);

  return {
    isFhxBlocked,
    isConsentBlocked,
    isApprovalBlocked,
    isMemberLoginBlocked,
  };
};

const KitsStats = (props: StatsProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [allKits, setAllKits] = React.useState<Kit[]>([]);
  const { fromDate, toDate } = props;

  const getAllKits = async () => {
    const results = await runStatsQuery<KitsConnection, QueryListKitsArgs>(gql`
      query ListKits($input: ListKitsInput!) {
        listKits(input: $input) {
          edges {
            cursor
            node {
              id
              dateCreated
              dateShippedToMember
              dateShippedToLab
              dateArrivedAtEugene
              recollectionRequestedDate
              memberRegisteredKitOnDate
              journey {
                id
                history {
                  fromState
                  date
                }
                consents {
                  id
                  dateCreated
                  dateWithdrawn
                }
                request {
                  id
                  datePaid
                  productVariant
                  gcApprovalDate
                }
                member {
                  id
                  dateDetailsConfirmedByMember
                  onlineAccessEnabled
                  familyHistoryGroup {
                    dateCreated
                    source
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
      `, {
      variables: {
        input: {},
      },
    }, 'listKits');
    const kits = results.edges.map(edge => edge.node);
    setAllKits(kits);
    setIsLoading(false);
  };

  React.useEffect(() => { getAllKits(); }, []);

  const opsBlockerStats = React.useMemo(() => {
    const kitsArrivedAtEugeneBetweenDates = allKits.filter(kit => {
      if (!kit.dateArrivedAtEugene) {
        return false;
      }

      return moment(kit.dateArrivedAtEugene).isBetween(fromDate, toDate);
    });

    const journeysByRequest: { [key: string]: Journey[] } = {};
    allKits.forEach(kit => {
      const requestId = kit?.journey?.request?.id;
      if (!requestId) {
        return;
      }
      if (!journeysByRequest[requestId]) {
        journeysByRequest[requestId] = [kit.journey];
      }
      else {
        journeysByRequest[requestId].push(kit.journey);
      }
    });

    let totalCount = 0;
    let kitsBlocked = 0;
    let fhxOpsBlockers = 0;
    let consentOpsBlockers = 0;
    let partnerCompleteOpsBlockers = 0;
    let partnerLoginOpsBlockers = 0;
    let clinicalOpsBlockers = 0;
    let memberLoginBlockers = 0;

    kitsArrivedAtEugeneBetweenDates.forEach(kit => {
      const journey = kit?.journey;
      const request = journey?.request;
      let kitBlocked = false;

      if (!journey || !request || !journey?.member) {
        return;
      }
      const arrivedAtEugene = moment(kit.dateArrivedAtEugene);

      const { isFhxBlocked, isConsentBlocked, isApprovalBlocked, isMemberLoginBlocked } = reasonsJourneyIsBlocked(journey, arrivedAtEugene);

      totalCount += 1;

      if (isFhxBlocked) {
        fhxOpsBlockers += 1;
        kitBlocked = true;
      }

      if (isConsentBlocked) {
        consentOpsBlockers += 1;
        kitBlocked = true;
      }

      if (isApprovalBlocked) {
        clinicalOpsBlockers += 1;
        kitBlocked = true;
      }

      if (isMemberLoginBlocked) {
        memberLoginBlockers += 1;
        kitBlocked = true;
      }

      if (isTwoPersonTest(request.productVariant)) {
        const requestJourneys = journeysByRequest[request.id];
        if (requestJourneys.length < 2) {
          partnerLoginOpsBlockers += 1;
          kitBlocked = true;
        }
        else {
          const partnerJourney = requestJourneys?.find(requestJourney => requestJourney.id !== journey.id);
          if (!partnerJourney || !partnerJourney.member) {
            partnerLoginOpsBlockers += 1;
          }
          else {
            const reasonsPartnerJourneyIsBlocked = reasonsJourneyIsBlocked(requestJourneys?.find(requestJourney => requestJourney.id !== journey.id), arrivedAtEugene);
            if (reasonsPartnerJourneyIsBlocked.isMemberLoginBlocked) {
              kitBlocked = true;
              partnerLoginOpsBlockers += 1;
            } else if (reasonsPartnerJourneyIsBlocked.isFhxBlocked || reasonsPartnerJourneyIsBlocked.isApprovalBlocked || reasonsPartnerJourneyIsBlocked.isConsentBlocked) {
              partnerCompleteOpsBlockers += 1;
              kitBlocked = true;
            }
          }
        }
      }
      
      if (kitBlocked) {
        kitsBlocked += 1;
      }

    });

    return {
      fhxOpsBlockers,
      consentOpsBlockers,
      partnerLoginOpsBlockers,
      partnerCompleteOpsBlockers,
      clinicalOpsBlockers,
      memberLoginBlockers,
      totalCount,
      kitsBlocked,
    };
  }, [fromDate, toDate, allKits]);

  const kitsAndRecollectsDataByMonth = React.useMemo(() => {
    const startMonth = moment(fromDate).clone().startOf('month');
    const endMonth = moment(toDate).clone().add(1, 'month').startOf('month');

    const months = endMonth.diff(startMonth, 'months');

    const monthData: { [key: string]: { totalShipped: number; totalRecollected: number; totalMemberShipped: number; totalLabShipped: number } } = {}

    for (let i = 0; i < months; i++) {
      const date = startMonth.clone().add(i, 'months');
      const monthString = formatMonthString(date);
      monthData[monthString] = { totalShipped: 0, totalRecollected: 0, totalMemberShipped: 0, totalLabShipped: 0 };
    }

    allKits.filter(kit => kit.dateShippedToMember).forEach(kit => {
      const shippedToMemberDate = moment(kit.dateShippedToMember);
      const recollectedDate = kit.recollectionRequestedDate ? moment(kit.recollectionRequestedDate) : null;

      const memberShippedDateString = formatMonthString(shippedToMemberDate);
      if (monthData[memberShippedDateString]) {
        monthData[memberShippedDateString].totalShipped++;
        monthData[memberShippedDateString].totalMemberShipped++;

        if (recollectedDate) {
          monthData[memberShippedDateString].totalRecollected++;
        }
        const shippedToLabDate = moment(kit.dateShippedToLab);
        const labShippedDateString = formatMonthString(shippedToLabDate);
        if (monthData[labShippedDateString]) {
          monthData[labShippedDateString].totalLabShipped++;
        }
      }
    });

    return Object.keys(monthData).sort().map((monthString) => {
      return {
        month: moment(monthString, 'YYYY-MM').format('MMM'),
        'Kits shipped to members': monthData[monthString].totalMemberShipped,
        'Kits shipped to lab': monthData[monthString].totalLabShipped,
        '# of kits recollected': monthData[monthString].totalRecollected,
        'Kit recollection rate': Math.round(((monthData[monthString].totalRecollected / monthData[monthString].totalShipped) || 0) * 100),
      };
    });
  }, [fromDate, toDate, allKits]);

  const medianLeadTimeDataByMonth = React.useMemo(() => {
    const startMonth = moment(fromDate).clone().startOf('month');
    const endMonth = moment(toDate).clone().add(1, 'month').startOf('month');

    const months = endMonth.diff(startMonth, 'months');

    const monthData: { [key: string]: { memberShippingDays: number[]; withCustomerDays: number[]; labShippingDays: number[]; waitingForResultsDays: number[]; receivingResultsDays: number[] } } = {}

    for (let i = 0; i < months; i++) {
      const date = startMonth.clone().add(i, 'months');
      const monthString = formatMonthString(date);
      monthData[monthString] = { memberShippingDays: [], withCustomerDays: [], labShippingDays: [], waitingForResultsDays: [], receivingResultsDays: [] };
    }

    allKits.filter(kit => kit.dateShippedToMember)
      .filter(kit => kit.recollectionRequestedDate)
      .filter(kit => kit.journey?.request?.datePaid)
      .forEach(kit => {
        const datePaidDate = moment(kit.journey?.request?.datePaid);
        const startDateString = formatMonthString(datePaidDate);

        if (!monthData[startDateString]) {
          return;
        }

        const shippedToMemberDate = moment(kit.dateShippedToMember);
        const arrivedAtEugeneDate = kit.dateArrivedAtEugene ? moment(kit.dateArrivedAtEugene) : null;
        const shippedToLabDate = kit.dateShippedToLab ? moment(kit.dateShippedToLab) : null;
        const reportsReceivedLog = kit.journey?.history?.find(historyItem => historyItem.fromState === JourneyState.WaitingForLabResults);
        const reportsReleasedLog = kit.journey?.history?.find(historyItem => historyItem.fromState === JourneyState.ReadyToReleaseResults || historyItem.fromState === JourneyState.RequireResultBooking);

        monthData[startDateString].memberShippingDays.push(shippedToMemberDate.diff(kit.dateCreated, 'days'));

        if (arrivedAtEugeneDate) {
          monthData[startDateString].withCustomerDays.push(arrivedAtEugeneDate.diff(kit.dateShippedToMember, 'days'));
        }

        if (shippedToLabDate) {
          monthData[startDateString].labShippingDays.push(shippedToLabDate.diff(kit.dateArrivedAtEugene, 'days'));
        }

        if (shippedToLabDate && reportsReceivedLog) {
          const reportsReceivedDate = moment(reportsReceivedLog.date);
          monthData[startDateString].waitingForResultsDays.push(reportsReceivedDate.diff(kit.dateShippedToLab, 'days'));

          if (reportsReleasedLog) {
            const reportsReleasedDate = moment(reportsReleasedLog.date);
            monthData[startDateString].receivingResultsDays.push(reportsReleasedDate.diff(reportsReceivedDate, 'days'));
          }
        }
      });

    return Object.keys(monthData).sort().map((monthString) => {
      return {
        month: moment(monthString, 'YYYY-MM').format('MMM'),
        'Member shipping days': median(monthData[monthString].memberShippingDays),
        'With customer days': median(monthData[monthString].withCustomerDays),
        'Lab shipping days': median(monthData[monthString].memberShippingDays),
        'Waiting for results days': median(monthData[monthString].waitingForResultsDays),
        'Receiving results days': median(monthData[monthString].receivingResultsDays),
      };
    });
  }, [fromDate, toDate, allKits]);

  const colors = ['#6269EE', '#FFB258', '#7F7F9E', '#FD5A5A'];

  const data: ChartData = React.useMemo(() => {
    return {
      dataKeys: ['Kit recollection rate'],
      items: kitsAndRecollectsDataByMonth,
      // tooltipFormatter: (value: any, props: { payload: any; }) => {
      //   const { payload } = props;
      //   return `${value}% (${payload['# of kits recollected']} kits recollected)`;
      // },
    };
  }, [kitsAndRecollectsDataByMonth]);

  const kitCountData: ChartData = React.useMemo(() => {
    return {
      dataKeys: ['Kits shipped to members', 'Kits shipped to lab'],
      items: kitsAndRecollectsDataByMonth,
      tooltipFormatter: (value: any) => {
        return value;
      },
    };
  }, [kitsAndRecollectsDataByMonth]);

  const leadTimeData: ChartData = React.useMemo(() => {
    return {
      dataKeys: ['Member shipping days', 'With customer days', 'Lab shipping days', 'Waiting for results days', 'Receiving results days'],
      items: medianLeadTimeDataByMonth,
      tooltipFormatter: (value: any) => {
        return value;
      },
    };
  }, [medianLeadTimeDataByMonth]);

  const kitShippedCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.dateShippedToMember)
      .filter(kit => {
        const shipDate = moment(kit.dateShippedToMember).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const kitShippedToLabCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.dateShippedToLab)
      .filter(kit => {
        const shipDate = moment(kit.dateShippedToLab).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);
  
  const kitsArrivedAtEugeneCount = React.useMemo(() => {
    return allKits
      .filter(kit => !!kit.dateArrivedAtEugene)
      .filter(kit => {
        const shipDate = moment(kit.dateArrivedAtEugene).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);
  
  const kitsRegisteredByMemberCount = React.useMemo(() => {
    return allKits
      .filter(kit => !!kit.memberRegisteredKitOnDate && !!kit.dateArrivedAtEugene)
      .filter(kit => {
        const shipDate = moment(kit.dateArrivedAtEugene).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const kitRecollectCount = React.useMemo(() => {
    return allKits
      .filter(kit => kit.recollectionRequestedDate)
      .filter(kit => {
        const recollectDate = moment(kit.recollectionRequestedDate).startOf('day');
        return recollectDate.isSameOrAfter(fromDate) && recollectDate.isSameOrBefore(toDate);
      }).length;
  }, [fromDate, toDate, allKits]);

  const averageDaysBetween = React.useCallback((aTimeField: keyof Pick<Kit, 'dateShippedToLab' | 'dateCreated' | 'dateShippedToMember' | 'dateArrivedAtEugene'>, bTimeField: keyof Pick<Kit, 'dateShippedToLab' | 'dateCreated' | 'dateShippedToMember' | 'dateArrivedAtEugene'>) => {
    const filteredKits = allKits.filter(kit => kit[aTimeField])
      .filter(kit => {
        const shipDate = moment(kit[aTimeField]).startOf('day');
        return shipDate.isSameOrAfter(fromDate) && shipDate.isSameOrBefore(toDate);
      });
    const hoursAverage = filteredKits.map(kit => {
      return moment(kit[aTimeField]).diff(moment(kit[bTimeField]), 'h');
    }).reduce((a, b) => a + b, 0) / filteredKits.length;

    return `${(hoursAverage / 24).toFixed(1)} days`;
  }, [fromDate, toDate, allKits]);

  const textStats = React.useMemo(() => {
    const rows = [
      ['Kits shipped to member', kitShippedCount],
      ['Kits shipped to lab', kitShippedToLabCount],
      ['Kit recollections performed', kitRecollectCount],
      ['Arrived kits registered by members', `${kitsRegisteredByMemberCount} (${percentage(kitsRegisteredByMemberCount / kitsArrivedAtEugeneCount)})`],
      ['Member shipping lead-time', averageDaysBetween('dateShippedToMember', 'dateCreated')],
      ['Lab shipping lead-time', averageDaysBetween('dateShippedToLab', 'dateArrivedAtEugene')],
      ['Full kit lifecycle time', averageDaysBetween('dateShippedToLab', 'dateCreated')],
      ['Kits blocked', `${opsBlockerStats.kitsBlocked} (${percentage(opsBlockerStats.kitsBlocked / opsBlockerStats.totalCount)} of all kits)`],
      ['Kits blocked by Family History', `${opsBlockerStats.fhxOpsBlockers} (${percentage(opsBlockerStats.fhxOpsBlockers / opsBlockerStats.kitsBlocked)} of blockers)`],
      ['Kits blocked by Consent', `${opsBlockerStats.consentOpsBlockers} (${percentage(opsBlockerStats.consentOpsBlockers / opsBlockerStats.kitsBlocked)} of blockers)`],
      ['Kits blocked by Clinical Approval', `${opsBlockerStats.clinicalOpsBlockers} (${percentage(opsBlockerStats.clinicalOpsBlockers / opsBlockerStats.kitsBlocked)} of blockers)`],
      ['Kits blocked by partner not logging in', `${opsBlockerStats.partnerLoginOpsBlockers} (${percentage(opsBlockerStats.partnerLoginOpsBlockers / opsBlockerStats.kitsBlocked)} of blockers)`],
      ['Kits blocked by partner not completing in time', `${opsBlockerStats.partnerCompleteOpsBlockers} (${percentage(opsBlockerStats.partnerCompleteOpsBlockers / opsBlockerStats.kitsBlocked)} of blockers)`],
    ]
    return (
      <table className="w-full">
        <tbody>
          {rows.map((row, index) => (
            <tr key={`row-${index}`}>
              <td>{row[0]}</td>
              <td className="text-right font-bold py-10">{row[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [kitShippedCount, kitShippedToLabCount, kitRecollectCount, opsBlockerStats]);

  return (
    <div>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {textStats}
          <h2 className="text-lg my-10 font-bold text-center border-t pt-20 mt-20">Recollection rates (% monthly)</h2>
          <AreaChart width={730} height={500} data={data.items}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis interval={0} allowDecimals={false} />
            <Legend />
            <Tooltip formatter={data.tooltipFormatter} />
            {data.dataKeys.map((dataKey, index) => (
              <Area type="monotone" dataKey={dataKey} fill={colors[index]} stroke={colors[index]} key={dataKey} />
            ))}
          </AreaChart>
          <h2 className="text-lg mt-20 mb-10 font-bold text-center border-t pt-20 mt-20">Kit output</h2>
          <BarChart width={730} height={500} data={kitCountData.items}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis interval={0} allowDecimals={false} />
            <Legend />
            <Tooltip formatter={kitCountData.tooltipFormatter} />
            {kitCountData.dataKeys.map((dataKey, index) => (
              <Bar dataKey={dataKey} stackId="a" fill={colors[index]} stroke={colors[index]} key={dataKey} />
            ))}
          </BarChart>
          <h2 className="text-lg mt-20 mb-10 font-bold text-center border-t pt-20 mt-20">Lead times (median)</h2>
          <AreaChart width={730} height={500} data={leadTimeData.items}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis interval={0} allowDecimals={false} />
            <Legend />
            <Tooltip formatter={leadTimeData.tooltipFormatter} />
            {leadTimeData.dataKeys.map((dataKey, index) => (
              <Area dataKey={dataKey} stackId="a" fill={colors[index]} stroke={colors[index]} key={dataKey} />
            ))}
          </AreaChart>
        </>
      )}
    </div>
  );
}

export default KitsStats;