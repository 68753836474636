import * as React from 'react';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { uniq } from 'lodash';
import { JourneyState } from '../../../../graphql/genie-api-types';
import { OpsEfficiencyStatsProps, WEEK_FORMAT } from '../OpsEfficiency';
import moment from 'moment';

const PRE_TEST_STATES: JourneyState[] = [
  JourneyState.RequirePretestBooking,
  JourneyState.WaitingForPretestConsult,
];
const POST_TEST_STATES: JourneyState[] = [
  JourneyState.ReadyForReport,
  JourneyState.ReadyForReportReview,
  JourneyState.RequireResultBooking,
  JourneyState.WaitingForResultConsult,
  JourneyState.ReadyToReleaseResults,
];

const OpsEfficiencyActiveJourneys = (props: OpsEfficiencyStatsProps) => {
  const { requests, fromDate, toDate } = props;

  const activeJourneysByWeek = React.useMemo(() => {
    const _journeysByWeek: { [key: number]: { preTest: number; postTest: number } } = {};

    // Loop from first Monday, by week.
    for (let m = fromDate.clone().isoWeekday(1); m.isBefore(toDate); m.add(1, 'week')) {
      _journeysByWeek[parseInt(m.format(WEEK_FORMAT))] = { preTest: 0, postTest: 0 };
    }

    requests.forEach(requestResult => {
      requestResult?.journeys?.forEach(journeyResult => {
        const history = (journeyResult?.history ?? []).slice(0).reverse();
        const preTestWeekTimestamps: number[] = [];
        const postTestWeekTimestamps: number[] = [];
        for (let i = 0; i < history.length; i++) {
          const historyItem = history[i];
          // Don't report on resurrected journeys.
          if ([JourneyState.Complete, JourneyState.ReadyToArchive, JourneyState.Trash].includes(historyItem.fromState)) {
            break;
          }
          // Skip null from state due to duplication issues in earlier genie.
          if (historyItem.fromState === null) {
            continue;
          }
          const historyItemDate = moment(historyItem.date);
          // If day is Saturday or Sunday, advance to next business week.
          if (historyItemDate.isoWeekday() >= 6) {
            historyItemDate.add(1, 'week');
            historyItemDate.isoWeekday(1);
          }
  
          if (historyItem.fromState !== null && historyItem.toState !== JourneyState.Trash && PRE_TEST_STATES.includes(historyItem.fromState) || PRE_TEST_STATES.includes(historyItem.toState)) {
            preTestWeekTimestamps.push(parseInt(historyItemDate.format(WEEK_FORMAT)));
          }
          if (POST_TEST_STATES.includes(historyItem.fromState) || POST_TEST_STATES.includes(historyItem.toState)) {
            postTestWeekTimestamps.push(parseInt(historyItemDate.format(WEEK_FORMAT)));
          }
        }

        const preTestTimePeriod = uniq(preTestWeekTimestamps).sort();
        if (preTestTimePeriod.length === 1 && _journeysByWeek[preTestTimePeriod[0]]) {
          _journeysByWeek[preTestTimePeriod[0]].preTest += 1;
        }
        else if (preTestTimePeriod.length > 1) {
          // Fill in the time periods between the first and last state change.
          const endPeriodDate = moment(preTestTimePeriod[preTestTimePeriod.length - 1], WEEK_FORMAT);
          for (let m = moment(preTestTimePeriod[0], WEEK_FORMAT); m.isBefore(endPeriodDate); m.add(1, 'week')) {
            const weekStamp = parseInt(m.format(WEEK_FORMAT));
            if (_journeysByWeek[weekStamp]) {
              _journeysByWeek[weekStamp].preTest += 1;
            }
          }
        }

        const postTestTimePeriod = uniq(postTestWeekTimestamps).sort();

        if (postTestTimePeriod.length === 1 && _journeysByWeek[postTestTimePeriod[0]]) {
          _journeysByWeek[postTestTimePeriod[0]].postTest += 1;
        } else if (postTestTimePeriod.length > 1) {
          // Fill in the time periods between the first and last state change.
          const endPeriodDate = moment(postTestTimePeriod[postTestTimePeriod.length - 1], WEEK_FORMAT);
          for (let m = moment(postTestTimePeriod[0], WEEK_FORMAT); m.isBefore(endPeriodDate); m.add(1, 'week')) {
            const weekStamp = parseInt(m.format(WEEK_FORMAT));
            if (_journeysByWeek[weekStamp]) {
              _journeysByWeek[weekStamp].postTest += 1;
            }
          }
        }
      });
    });

    return _journeysByWeek;
  }, [requests, props.fromDate, props.toDate]);


  const openMembersChartData = React.useMemo(() => {
    const weekNumbers = Object.keys(activeJourneysByWeek) as any as number[];
    return weekNumbers.sort().map((weekStamp: number) => {
      return {
        date: weekStamp,
        formattedDate: moment(weekStamp, WEEK_FORMAT).format('[Week of] Do MMM YYYY'),
        'Open pre-test members': activeJourneysByWeek[weekStamp].preTest,
        'Open post-test members': activeJourneysByWeek[weekStamp].postTest,
      };
    });
  }, [activeJourneysByWeek]);

  return (
    <>
      <h2 className="text-xl font-bold mb-10">Active journeys</h2>
      <AreaChart
        width={800}
        height={400}
        data={openMembersChartData}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="6 6" />
        <XAxis dataKey="formattedDate" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Open pre-test members" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="Open post-test members" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
      </AreaChart>
    </>
  );
};

export default OpsEfficiencyActiveJourneys;
