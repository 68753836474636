import * as React from 'react';
import moment from 'moment';
import { KitScope, Mutation, MutationUpdateKitArgs, ListKitsInput, Kit, FlagCode, Journey, Member, Pregnancy, Request } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import { getProductIcon } from '../../../utilities/journeys/products';
import { useMutation } from 'react-apollo';
import { appSyncClient } from '../../../utilities/appSync';
import RouteButton from '../../components/widgets/RouteButton';
import { PATHS } from '../../store/router/types';
import BooleanIcon from '../../components/widgets/BooleanIcon';
import Screen from '../../components/widgets/Screen';
import NeedsAttentionKits from '../../graphql/containers/kits/NeedsAttentionKits';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';
import OpsKitsTable from '../../graphql/containers/ops/OpsKitsTable';
import AprilFlags from '../../graphql/containers/flags/AprilFlags';

interface OpsLabShippingJourney extends Required<Pick<Journey, 'id' | 'state' | 'hasRecollection' | 'hasConsent' | 'hasRelevantFamilyHistoryGroupSubmission' | 'labOrderNumber'>> {
  member: Required<Pick<Member, 'id'|'name'|'sex'|'dateDetailsConfirmedByMember'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'gcApprovalDate' | 'initiatingMember'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'>>;
    })[];
  };
}

interface OpsLabShippingKit extends Pick<Kit, 'id'|'dateArrivedAtEugene'|'registrationNumber'> {
  journey: OpsLabShippingJourney;
}

const OPS_MEMBER_SHIPPING_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        cursor
        node {
          id
          dateArrivedAtEugene
          registrationNumber
          journey {
            id
            state
            labOrderNumber
            hasConsent
            hasRelevantFamilyHistoryGroupSubmission
            hasRecollection
            member {
              id
              name
              sex
              email
              dateDetailsConfirmedByMember
            }
            consents {
              id
              dateCreated
              dateWithdrawn
            }
            request {
              id
              onHoldDate
              gcApprovalDate
              product
              productVariant
              initiatingMember {
                id
              }
              pregnancy {
                dueDate
                dueDateConfirmed
                days
              }
              notes(importantOnly: true) {
                text
              }
              journeys {
                id
                state
                member {
                  id
                  name
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalRows
    }
  }
`;

const OPS_MEMBER_SHIPPING_SHIPPED_MUTATION = gql`
  mutation UpdateKit($input: UpdateKitInput!) {
    updateKit(input: $input) {
      kit {
        id
      }
    }
  }
`;

const queryInput: ListKitsInput = {
  scope: KitScope.ReadyForLabShipping,
};

const markAsShippedAction = 'markAsShipped';
const assignKitNumberAction = 'assignKit';

const OpsLabShipping = () => {
  const dispatch = useDispatch();

  const [mutate] = useMutation<Pick<Mutation, 'updateKit'>, MutationUpdateKitArgs>(OPS_MEMBER_SHIPPING_SHIPPED_MUTATION, {
    client: appSyncClient,
  });

  const onActionClick = React.useCallback(async (kitIds: string[], actionName: string) => {
    switch (actionName) {
      case assignKitNumberAction: {
        dispatch(navigate.toForm('KitsAssignNumber', { kitIds }));
      }
        break;
      case markAsShippedAction: {
        await Promise.all(kitIds.map(kitId => mutate({
          variables: {
            input: {
              kitId,
              shippedToLab: true,
            },
          },
        })));
      }
        break;
    }
  }, [mutate]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Lab shipping',
        action: (
          <div>
            <RouteButton
              className='mr-10'
              path={PATHS.OPS_MANIFEST}
            >
              View Manifest
            </RouteButton>
            <RouteButton
              path={PATHS.OPS_MANIFEST_INVITAE}
            >
              View Manifest for Invitae
            </RouteButton>
          </div>
        )
      }}
    >
      <OpsKitsTable<OpsLabShippingKit>
        query={OPS_MEMBER_SHIPPING_QUERY}
        queryInput={queryInput}
        header={[
          'Arrived',
          'Product',
          'Login',
          'FHx',
          'Consent',
          'GC',
          'Invitae',
        ]}
        cellsForJourney={(journey, kit) => [
          kit.dateArrivedAtEugene ? moment(kit.dateArrivedAtEugene).fromNow() : '',
          getProductIcon(journey.request.product, journey.request.productVariant),
          <BooleanIcon key="login" isChecked={!!journey.member.dateDetailsConfirmedByMember} />,
          <BooleanIcon key="fhx" isChecked={!!journey.hasRelevantFamilyHistoryGroupSubmission} />,
          <BooleanIcon key="consent" isChecked={journey.hasConsent} />,
          <BooleanIcon key="approval" isChecked={!!journey.request.gcApprovalDate} />,
          <BooleanIcon key="invitae" isChecked={!!journey.labOrderNumber} />,
        ]}
        actionsForJourney={() => [
          {
            label: 'Shipped',
            name: markAsShippedAction,
            variant: 'success',
          },
          {
            label: 'Assign kit number',
            name: assignKitNumberAction,
            variant: 'success',
          },
        ]}
        onActionClick={(kitIds, actionName) => onActionClick(kitIds, actionName)}
      />
      <div className="mt-20">
        <NeedsAttentionKits />
        <AprilFlags queryVariables={{ code: FlagCode.UnmatchedConsent }} title="Unmatched consent ✍️" />
      </div>
    </Screen>
  );
};

export default OpsLabShipping;
