import * as React from 'react';
import { FiClock, FiX, FiSearch, FiCheck } from '../../widgets/Icon';
import Badge from '../../widgets/Badge';
import { JourneyState } from '../../../../graphql/genie-api-types';
import CounsellorIcon from '../../widgets/CounsellorIcon';
import JourneyRows from './JourneyRows';
import ShippedKitsCheck from '../columns/ShippedKitsCheck';
import LabOrderNumberCheck from '../columns/LabOrderNumberCheck';
import { getAcuitySearchUrl } from '../../../../utilities/helpers';
import gql from 'graphql-tag';
import { JourneyRowPartial } from '../../widgets/JourneyTable';

const getDateBadge = (journey: JourneyRowPartial, request: JourneyRowPartial['request']) => {
  const initiatingMember = request?.initiatingMember || journey?.member;

  const acuityLink = getAcuitySearchUrl(initiatingMember?.name ?? '');

  return <a href={acuityLink} target="blank"><Badge color="green-lightest" className="text-xs hover:text-green-dark flex items-center"><FiSearch className="mr-5 mb-1" /> <span>Search Acuity</span></Badge></a>;
};

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          labOrderNumber
          kits {
            id
            memberRegisteredKitOnDate
            recollectionRequestedDate
            registrationNumber
            dateShippedToMember
          }
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            onHoldDate
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            primaryCounsellor {
              id
              name
              photoURL
            }
            datePaid
            initiatingMember {
              id
              name
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const PreTestCounsellingJourneys = () => (
  <JourneyRows
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.RequireDetails, JourneyState.RequirePretestBooking, JourneyState.WaitingForPretestConsult]}
    header={['Purchase', 'Shipped', 'Invitae', 'GC', <div title="Booking Time" key="booking-time"><FiClock /></div>, '']}
    emptyText="No members awaiting pre-test counselling"
    cellsForJourney={journey => [
      journey?.request?.datePaid ? <span className="text-xs text-green" key="paid-icon"><FiCheck /></span> : <FiX color="grey" key="unpaid-icon" />,
      <ShippedKitsCheck journey={journey} key="ship-kits-icon" />,
      <LabOrderNumberCheck journey={journey} key="lab-order-icon" />,
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor-icon" />,
      getDateBadge(journey, journey.request),
    ]}
  />
);

export default PreTestCounsellingJourneys;
