import * as React from 'react';
import './styles/loading-overlay.scss';
import Spinner, { SpinnerProps } from './Spinner';

const LoadingOverlay = (props: SpinnerProps) => (
  <div className="loading-overlay z-50">
    <Spinner {...props} />
  </div>
);

export default LoadingOverlay;
