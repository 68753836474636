import * as React from 'react';
import GenieQuery from '../base/GenieQuery';
import gql from 'graphql-tag';
import { Journey, QueryListJourneysArgs } from '../../../graphql/genie-api-types';

type MemberJourneyResult = Pick<Journey, 'id' | 'startDate'>;

type ListJourneysResult = {
  listJourneys: {
    edges: {
      node: MemberJourneyResult;
    }[];
  };
};

export interface MemberJourneysProps {
  memberId: string;
  children(journeys: MemberJourneyResult[], isLoading: boolean): React.ReactElement<any>;
}

const MemberJourneys = (props: MemberJourneysProps) => (
  <GenieQuery<ListJourneysResult, QueryListJourneysArgs>
    query={gql`
      query ListJourneys($input: ListJourneysInput!) {
        listJourneys(input: $input) {
          edges {
            node {
              id
              startDate
              state
            }
          }
        }
      }
    `}
    notifyOnNetworkStatusChange
    errorPolicy="all"
    variables={{
      input: {
        memberId: props.memberId,
      },
    }}
  >
    {(response) => {
      const journeys: MemberJourneyResult[] = response?.data?.listJourneys?.edges?.map(edge => edge.node) ?? [];
      return props.children(journeys, response.loading);
    }}
  </GenieQuery>
);

export default MemberJourneys;
