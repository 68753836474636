import * as React from 'react';
import moment from 'moment';
import { mean, max, min } from 'lodash';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Line, Bar,
} from 'recharts';
import { JourneyState } from '../../../../../graphql/genie-api-types';
import Select from '../../../widgets/Select';
import { JOURNEY_STATE_LABELS } from '../../../../../utilities/journeys/states';
import { FiArrowRight } from '../../../widgets/Icon';
import { RequestAllJourneysWithHistoryJourney } from '../JourneyStats';

const journeyStateOptions = Object.keys(JOURNEY_STATE_LABELS).map((journeyState: JourneyState) => ({
  label: JOURNEY_STATE_LABELS[journeyState],
  value: journeyState
}));

interface StateTimeQuantityProps {
  allJourneysWithHistory: RequestAllJourneysWithHistoryJourney[];
}

const StateTimeQuantity = (props: StateTimeQuantityProps) => {
  const [fromState, setFromState] = React.useState<JourneyState>(JourneyState.RequirePretestBooking);
  const [toState, setToState] = React.useState<JourneyState>(JourneyState.ReadyToReleaseResults);
  const { allJourneysWithHistory } = props;
  
  const data = React.useMemo(() => {
    const sortableDurationMonthFormat = 'YYYYMM';

    const durations: { [key: string]: number[] } = {};
    allJourneysWithHistory.forEach(journey => {
      const fromLog = journey.history.slice(0).reverse().find(historyItem => historyItem.toState === fromState);
      const toLog = journey.history.slice(0).reverse().find(historyItem => historyItem.toState === toState);

      if (fromLog && toLog) {
        const fromDate = moment(fromLog.date);
        const toDate = moment(toLog.date)
        const timeBetweenStates = toDate.diff(fromDate, 'd');
        const monthYear = toDate.format(sortableDurationMonthFormat);

        durations[monthYear] = durations[monthYear] || [];
        durations[monthYear].push(timeBetweenStates);
      }
    });

    const data = Object.keys(durations).sort().map(monthYear => ({
      month: moment(monthYear, sortableDurationMonthFormat).format('MMM YYYY'),
      days: Math.round(mean(durations[monthYear])),
      quantity: durations[monthYear].length,
      max: max(durations[monthYear]),
      min: min(durations[monthYear]),
    }));

    return data;
  }, [fromState, toState, allJourneysWithHistory]);

  return (
    <div>
      <div className="flex mb-20 items-center">
        <Select<JourneyState>
          options={journeyStateOptions}
          onChange={setFromState}
          selectedValue={fromState}
        />
        <FiArrowRight size={30} className="mx-20" />
        <Select<JourneyState>
          options={journeyStateOptions}
          onChange={setToState}
          selectedValue={toState}
        />
      </div>
      <ComposedChart width={900} height={450} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" label="" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation='right' unit="d" />
        <Tooltip />
        <Line type="monotone" dataKey="quantity" stroke="#ff7300" yAxisId="left" />
        <Bar dataKey="days" barSize={20} fill="#413ea0" yAxisId="right" />
      </ComposedChart>
    </div>
  );
};

export default StateTimeQuantity;
