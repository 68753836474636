import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { MutationUpdateRecommendationArgs, Recommendation, QueryRecommendationArgs, RecommendationResult } from '../../../../graphql/genie-api-types';
import { MutationFunction, QueryResult } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import RecommendationForm, { RecommendationFormValues } from '../../../components/recommendation/RecommendationForm';
import GenieQuery from '../../base/GenieQuery';
import Spinner from '../../../components/widgets/Spinner';
import ReduxQueryListener from '../../../store/ReduxQueryListener';

interface RecommendationUpdateProps {
  onComplete(): void;
  recommendationId: Recommendation['id'];
  answer: RecommendationResult;
}

interface RecommendationQueryResult {
  recommendation: Pick<Recommendation, 'id' | 'action' | 'reason' | 'result'>;
}

class RecommendationUpdate extends React.PureComponent<RecommendationUpdateProps> {
  onSaveRecommendation = async ({ reason, result }: RecommendationFormValues, mutate: MutationFunction<MutationUpdateRecommendationArgs>) => {
    const { onComplete, recommendationId } = this.props;

    await mutate({
      variables: {
        input: {
          id: recommendationId,
          reason,
          result,
        },
      },
    });

    showToast('success', 'Updated recommendation', 'Successfully updated recommendation');
    ReduxQueryListener.triggerEvents('All');

    onComplete();
  }

  render() {
    const { recommendationId, answer } = this.props;

    return (
      <GenieQuery<any, QueryRecommendationArgs>
        query={gql`
          query Recommendation($id: ID!) {
            recommendation(id: $id) {
              id
              action
              reason
              result
            }
          }
        `}
        errorPolicy="all"
        variables={{
          id: recommendationId,
        }}
      >
        {({ loading, data }: QueryResult<RecommendationQueryResult>) => loading ?
          (
            <Spinner label="Loading recommendation..." />
          ) : (
            <GenieMutation
              mutation={gql`
                mutation updateRecommendation($input: UpdateRecommendationInput!) {
                  updateRecommendation(input: $input) {
                    recommendation {
                      id,
                    }
                  }
                }
              `}
            >
              {(mutate, result) => (
                <>
                  <RecommendationForm
                    initialValues={{
                      ...data.recommendation,
                      result: answer,
                    }}
                    onSubmit={(values: RecommendationFormValues) => this.onSaveRecommendation(values, mutate)}
                    isLoading={result.loading}
                    errorMessage={result?.error?.message}
                  />
                </>
              )}
            </GenieMutation>
          )}
      </GenieQuery>
    );
  }
}

export default RecommendationUpdate;
