import * as React from 'react';
import Screen from '../../components/widgets/Screen';
import { getPaidOrders } from '../../../utilities/shopify';
import Button from '../../components/widgets/Button';
import Table from '../../components/widgets/Table';
import Badge from '../../components/widgets/Badge';
import { FiCalendar } from '../../components/widgets/Icon';
import { useDispatch } from 'react-redux';
import { getAcuityPreTestAppointments } from '../../../utilities/acuity';
import ProgressBar from '../../components/widgets/ProgressBar';
import { navigate } from '../../store/router/actions';
import PrivateContent from '../../components/widgets/PrivateContent';
import MemberAcuitySyncBadge from '../../graphql/containers/members/MemberAcuitySyncBadge';
import GeneticJokes from '../../components/widgets/GeneticJokes';
import Select from '../../components/widgets/Select';

interface AcuityOrderRow {
  id: string;
  date: string;
  names: string[];
  primaryEmail: string;
  partnerEmail?: string;
  type: string;
}

const TasksPreTest = () => {
  const [acuityAppointments, setAcuityAppointments] = React.useState([] as AcuityOrderRow[]);
  const [isLoadingAppointments, setIsLoadingAppointments] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [appointmentAmount, setAppointmentAmount] = React.useState('50');

  const dispatch = useDispatch();

  const onRowSelect = React.useCallback((appointmentId: string) => {
    dispatch(navigate.toForm('AcuityPreTestMatch', { acuityAppointmentId: appointmentId }));
  }, [acuityAppointments]);

  const getAppointmentsFromThirdParties = React.useCallback(async (e?: React.FormEvent) => {
    e?.preventDefault();
    setIsLoadingAppointments(true);
    setLoadingMessage('Loading appointments from acuity...');
    setLoadingProgress(0.1);
    try {
      const appointments = await getAcuityPreTestAppointments(parseInt(appointmentAmount));
      setLoadingProgress(0.4);

      const appointmentCount = appointments.length;
      const progressPerAppointment = 0.6 / appointmentCount;
      let currentLoadingProgress = 0.4;

      const resultsAppointments = appointments.filter(appointment => !appointment.type.toLowerCase().includes('results'));

      const acuitAppointmentWithRequest: AcuityOrderRow[] = await Promise.all(resultsAppointments.map(async (appointment) => {
        const names = [appointment.name, appointment.partnerName].filter((name) => name);

        currentLoadingProgress += progressPerAppointment;
        setLoadingProgress(currentLoadingProgress);

        return {
          id: appointment.id,
          date: appointment.date.calendar(null, { sameElse : 'DD/MM/YYYY' }),
          names,
          primaryEmail: appointment.email,
          partnerEmail: appointment.partnerEmail,
          type: appointment.type,
        };
      }));

      setAcuityAppointments(acuitAppointmentWithRequest);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingAppointments(false);
  }, [getPaidOrders, setLoadingProgress, setLoadingMessage, appointmentAmount]);

  return (
    <Screen
      screenTitleProps={{
        title: 'Acuity pre-test',
      }}
    >
      {(
        <form className="flex relative justify-between items-center" onSubmit={getAppointmentsFromThirdParties}>
          <div className="flex items-center">
            <div>
              <Select options={[{ label: 'Last 50', value: '50' }, { label: 'Last 100', value: '100' }, { label: 'Last 200', value: '200' }]} onChange={setAppointmentAmount} selectedValue={appointmentAmount} />
            </div>
            <Button type="submit" disabled={isLoadingAppointments}>
              Get Acuity appointments
            </Button>
          </div>
          {(isLoadingAppointments || acuityAppointments.length > 0) && (
            <GeneticJokes />
          )}
        </form>
      )}
      {isLoadingAppointments && (
        <ProgressBar progress={loadingProgress} message={loadingMessage} className="my-20" />
      )}
      {acuityAppointments.length > 0 && (
        <>
          <Table
            header={['Names', 'Appointment', 'Status']}
            onRefresh={getAppointmentsFromThirdParties}
            onRowSelect={onRowSelect}
            rowGroups={acuityAppointments.map(appointment => ({
              id: appointment.id,
              rows: [
                {
                  id: appointment.id,
                  cells: [
                    appointment.names.map((name, index) => (<Badge key={`name-${index}`} className="mr-5"><PrivateContent>{name}</PrivateContent></Badge>)),
                    <Badge key="date" color="green-lightest"><FiCalendar className="-mt-3" /> {appointment.date}</Badge>,
                    appointment.type,
                    <MemberAcuitySyncBadge
                      primaryEmail={appointment.primaryEmail}
                      partnerEmail={appointment.partnerEmail}
                      key="sync-badge"
                    />,
                  ],
                }
              ],
            }))}
          />
        </>
      )}
    </Screen>
  );
}

export default TasksPreTest;
