import { FieldType } from '../../FieldType';

export type SectionVariables = {
  title: string;
  content: string;
  tickBullets?: boolean;
};

export const SectionVariableTypes: {[key in keyof SectionVariables]: FieldType} = {
  title: 'text',
  content: 'text',
  tickBullets: 'boolean',
};
