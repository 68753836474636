import * as React from 'react';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import Button from '../../components/widgets/Button';
import { generatePDF } from '../../../utilities/report/html/generator';

const PDFPrinter = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [htmlString, setHtmlString] = React.useState('');
  const [isNoHeader, setIsNoHeader] = React.useState(false);
  
  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHtmlString(e.target.value);
  }

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoHeader(e.target.checked);
  } 

  const onSubmit = async () => {
    setIsLoading(true);

    await generatePDF(htmlString, 'eugene-pdf', isNoHeader ? { top: "10mm" } : undefined);
    
    setIsLoading(false);
  }

  return (
    <div>
      <ScreenTitle
        title="Manually print a PDF"
      />
      <textarea
        onChange={onChangeTextArea}
        value={htmlString}
        className="border border-grey-dark p-10 w-full"
        placeholder="Paste the HTML here..."
        style={{ height: '300px' }}
      />
      <label className="block mt-8 mb-20">
        <span className="mr-8">Don’t add header</span>
        <input
          type="checkbox"
          defaultChecked={isNoHeader}
          onChange={onChangeCheckbox}
        />
      </label>
      
      <Button
        onClick={onSubmit}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Download PDF'}
      </Button>
    </div>
  );
}

export default PDFPrinter;
