import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Clinic } from '../../../graphql/genie-api-types';
import ClinicShowHeader from '../../components/clinic/show/ClinicShowHeader';
import ClinicShowContact from '../../components/clinic/show/ClinicShowContact';
import Screen from '../../components/widgets/Screen';
import { ClinicShowContextWrapper } from '../../contexts/ClinicShowContext';
import ClinicShowDoctors from '../../components/clinic/show/ClinicShowDoctors';

interface MatchParams {
  clinicId: Clinic['id'];
}

type OwnProps = RouteComponentProps<MatchParams>;

const ClinicShow = (props: OwnProps) => {
  const { clinicId } = props.match.params;
  return (
    <Screen className="relative">
      <ClinicShowContextWrapper clinicId={clinicId}>
        <div className="relative">
          <div className="mb-40">
            <ClinicShowHeader />
            <ClinicShowContact />
          </div>
          <div className="mb-40">
            <ClinicShowDoctors />
          </div>
        </div>
      </ClinicShowContextWrapper>
    </Screen>
  );
}

export default ClinicShow;
