import * as React from 'react';
import { Mutation, QueryJourneyArgs, Journey, Member, Request, Consent, MutationCreateConsentArgs, ConsentSource } from '../../../../graphql/genie-api-types';
import { useQuery, useMutation } from 'react-apollo';
import Spinner from '../../../components/widgets/Spinner';
import gql from 'graphql-tag';
import Button from '../../../components/widgets/Button';
import Table from '../../../components/widgets/Table';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import { getProductLabel } from '../../../../utilities/journeys/products';
import { appSyncClient } from '../../../../utilities/appSync';
import { Action } from '../../../components/widgets/DropdownButton';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../store/router/actions';
import Badge from '../../../components/widgets/Badge';
import { formatDateFull, formatPartnerConsent } from '../../../../utilities/helpers';
import moment from 'moment';
import PrivateContent from '../../../components/widgets/PrivateContent';

interface JourneyConsentProps {
  onUpdate?(): void;
  onCancel?(): void;
  journeyId: Journey['id'];
}

interface JourneyPartial extends Pick<Journey, 'id' | 'partnerCanReceiveResults'> {
  member: Pick<Member, 'id' | 'name'>;
  request: Pick<Request, 'id' | 'product' | 'productVariant'>;
  consents: Pick<Consent, 'id' | 'createdBy' | 'dateCreated' | 'dateWithdrawn' | 'source' | 'withdrawnBy' | 'formId' | 'name' | 'email' | 'withdrawalNotes'>[];
}

type JourneyResult = { journey: JourneyPartial };

const JOURNEY_CONSENT_QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      hasConsent # Refresh apollo graphql cache for journey info sidebar and pretest screens.
      partnerCanReceiveResults
      member {
        id
        name
      }
      request {
        id
        product
        productVariant
      }
      consents {
        id
        createdBy {
          counsellorDetails {
            name
          }
        }
        dateCreated
        dateWithdrawn
        source
        email
        formId
        name
        withdrawalNotes
        withdrawnBy {
          email
          counsellorDetails {
            name
          }
        }
      }
    }
  }
`;

const CONSENT_CREATE_MUTATION = gql`
  mutation CreateConsent($input: CreateConsentInput!) {
    createConsent(input: $input) {
      consent {
        id
      }
    }
  }
`;

const withdrawActions: Action[] = [
  {
    label: 'Withdraw',
    name: 'withdraw',
    variant: 'danger',
    extraProps: {
      noBatch: true,
    },
  },
  {
    label: 'Reassign',
    name: 'reassign',
    variant: 'primary',
    extraProps: {
      noBatch: true,
    },
  }
];

const JourneyConsent = (props: JourneyConsentProps) => {
  const queryVariables = {
    id: props.journeyId,
  };

  const dispatch = useDispatch();

  const { loading, data, refetch } = useQuery<JourneyResult, QueryJourneyArgs>(JOURNEY_CONSENT_QUERY, {
    client: appSyncClient,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const [createConsentMutate, createConsentMutationRequest] = useMutation<Pick<Mutation, 'createConsent'>, MutationCreateConsentArgs>(CONSENT_CREATE_MUTATION, {
    client: appSyncClient,
  });

  const onReload = React.useCallback(() => {
    refetch(queryVariables);
  }, [refetch, queryVariables]);

  const onCreateManualConsent = React.useCallback(async () => {
    await createConsentMutate({
      variables: {
        input: {
          journeyId: props.journeyId,
        },
      },
    });
    onReload();
  }, [createConsentMutate, props.journeyId]);

  const onActionClick = React.useCallback(async (consentIds: string[], actionName: string) => {
    for (let i = 0; i < consentIds.length; i++) {
      switch (actionName) {
        case 'withdraw':
          dispatch(navigate.toForm('WithdrawConsent', { consentId: consentIds[i], journeyId: props.journeyId }));
          break;
        case 'reassign':
          dispatch(navigate.toForm('ReassignConsent', { consentId: consentIds[i] }));
          break;
      }
    }
  }, [dispatch, navigate, props.journeyId]);

  if (loading) {
    return <Spinner label="Loading journey..." />;
  }

  const journey = data?.journey;
  if (!journey) {
    return <div />
  }

  const productLabel = getProductLabel(journey.request.product, journey.request.productVariant);
  const memberName = journey.member?.name;
  const consents = journey.consents ?? [];
  const noConsent = !consents.find(consent => !consent.dateWithdrawn);

  const partnerCanReceiveResults = journey.partnerCanReceiveResults;

  return (
    <div className="relative">
      <div className="bg-white p-10 border">
        <h2 className="text-md mb-10"> Consent for <strong>{productLabel}</strong> by <strong><PrivateContent>{memberName}</PrivateContent></strong></h2>
        <Table
          header={['Date', 'Recorded name', 'Status']}
          emptyText="No consent received yet."
          alwaysShowActions
          onRefresh={onReload}
          onActionClick={onActionClick}
          rowGroups={consents.map(consent => (
            {
              id: consent.id,
              rows: [{
                id: consent.id,
                renderExpansion: function renderExpansion() {
                  return (
                    <div>
                      <div className="my-5"><strong>Source:</strong> {consent.source}</div>
                      {consent.source === ConsentSource.Web && (
                        <>
                          <div className="my-5"><strong>Form version:</strong> {consent.formId}</div>
                          <div className="my-5"><strong>Name:</strong> <PrivateContent>{consent.name}</PrivateContent></div>
                          <div className="my-5"><strong>Email:</strong> <PrivateContent>{consent.email}</PrivateContent></div>
                          <div className="my-5"><strong>Date:</strong> {moment(consent.dateCreated).format('llll')}</div>
                        </>
                      )}
                      {consent.withdrawalNotes && (
                        <div className="my-5"><strong className="block mb-2">Withdrawal notes:</strong> {consent.withdrawalNotes}</div>
                      )}
                      {consent.withdrawnBy && (
                        <div className="my-5"><strong>Withdrawn by:</strong> {consent.withdrawnBy?.counsellorDetails?.name ?? consent.withdrawnBy?.email}</div>
                      )}
                    </div>
                  )
                },
                cells: [
                  formatDateFull(consent.dateCreated),
                  <PrivateContent key="name">{consent.name}</PrivateContent>,
                  consent.dateWithdrawn ? <Badge color="red-light" key="status">Withdrawn</Badge> : <Badge color="green-light" key="status">Received</Badge>,
                ],
                actions: consent.dateWithdrawn ? [] : withdrawActions,
              }],
            }))}
        />
        {noConsent && (
          <Button
            onClick={onCreateManualConsent}
            className="mt-20"
          >
            Mark as consent received (HelloSign)
          </Button>
        )}
      </div>
      {createConsentMutationRequest.loading && (
        <LoadingOverlay label="Adding consent..." />
      )}
      {["COUPLE", "DONOR"].includes(journey.request?.productVariant) &&
        <div className={`bg-white p-10 border mt-20`}>
          <h2 className="text-md mb-10"> Consent to share result with partner?</h2>
          <p className={`${formatPartnerConsent(partnerCanReceiveResults, 'text-green', 'text-red', 'text-orange')}`}>{formatPartnerConsent(partnerCanReceiveResults)}</p>
        </div>
      }
    </div>
  );
}

export default JourneyConsent;
