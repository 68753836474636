import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Doctor, DoctorStats, QueryDoctorArgs } from '../../graphql/genie-api-types';
import LoadingOverlay from '../components/widgets/LoadingOverlay';

interface DoctorShowContextWrapperProps {
  doctorId: Doctor['id'];
  children: React.ReactNode;
}

export type DoctorShowDoctor = Pick<Doctor, 'id' | 'title' | 'nameFirst' | 'nameMiddle' | 'nameLast' | 'nickname' | 'personalPhone' | 'personalMobile' | 'personalEmail' | 'gender' | 'practitionerId' | 'providerNumber' | 'specialties' | 'clinics'> & {
  stats: Pick<DoctorStats, 'nLifetimeConversions'>[];
};

export type DoctorShowQueryResult = { doctor: DoctorShowDoctor };

export interface DoctorShowContextValue {
  isLoading: boolean;
  errorMessage: string;
  doctor?: DoctorShowDoctor;
  // referralCount: number;
  doctorId: Doctor['id'];
}

const DoctorShowContext = React.createContext<DoctorShowContextValue>({ isLoading: false, errorMessage: '', doctorId: null });

const MASTER_QUERY = gql`
  query QueryDoctor($id: ID!) {
    doctor(id: $id) {
      id
      title
      nameFirst
      nameMiddle
      nameLast
      nickname
      personalPhone
      personalMobile
      personalEmail
      gender
      practitionerId
      providerNumber
      specialties
      clinics {
        id
        name
        addressStreet
        addressSuburb
        addressPostcode
        addressState
        addressCountry
        locationId
        latitude
        longitude
        clinicEmail
        clinicPhone
      }
    }
  }
`;

export const DoctorShowContextWrapper = (props: DoctorShowContextWrapperProps) => {
  // Master query is designed to precache all other graphql queries within
  // nested components.
  const doctorQuery = useQuery<DoctorShowQueryResult, QueryDoctorArgs>(MASTER_QUERY, {
    variables: {
      id: props.doctorId,
    },
    fetchPolicy: 'network-only',
  });

  // const doctorStat = doctorQuery?.data?.doctor?.stats ?? [];
  const value: DoctorShowContextValue = {
    isLoading: doctorQuery.loading,
    errorMessage: '',
    doctorId: props.doctorId,
    // referralCount: doctorStat.length > 0 ? doctorStat[0].nLifetimeConversions : 0,
    doctor: doctorQuery?.data?.doctor,
  }

  if (doctorQuery.loading) {
    return <LoadingOverlay label="Loading doctor..." />
  }

  return (
    <DoctorShowContext.Provider value={value}>
      {props.children}
    </DoctorShowContext.Provider>
  );
}


export default DoctorShowContext;