import * as React from 'react';
import { hot } from 'react-hot-loader';
import { cloneDeep } from 'lodash';

import Main from './base/Main';
import '../../scss/app.scss';
import configureStore from './configureStore';
import ReduxQueryListener from './store/ReduxQueryListener';
import { history } from './store';

const initialState = (window as any).initialReduxState;

declare const module: any;  

const store = configureStore(initialState);

// Hot reload restore Redux cache from window object in dev environments.
if (module.hot) {
  // save current state before module being reloaded on dev environments. Clone deep
  // to trigger modules to reload.
  module.hot.dispose(() => (window as any).initialReduxState = cloneDeep(store.getState()));
}

ReduxQueryListener.initWithStore(store);

const App = () =>
  (<Main store={store} history={history} />);

export default hot(module)(App);
