import * as React from 'react';
import { FaPen } from './Icon';
import './styles/edit-area.scss';

interface EditAreaProps {
  onClick(): void;
  isOverridden?: boolean;
  children: React.ReactNode;
  padding?: number;
}

const EditArea = (props: EditAreaProps) => (
  <button
    className={`edit-area ${props.isOverridden ? 'edit-area--overridden' : ''}`}
    style={{ padding: `${props.padding}px` }}
    onClick={props.onClick}
    type="button"
  >
    <FaPen size={10} className="edit-area__icon" />
    {props.children}
  </button>
);

EditArea.defaultProps = {
  isOverridden: true,
  padding: 20,
};

export default EditArea;
