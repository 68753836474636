import * as React from 'react';
import GenieQuery from '../../base/GenieQuery';
import gql from 'graphql-tag';
import { Member, Query, QueryListJourneysArgs, Request, JourneyState } from '../../../../graphql/genie-api-types';

interface RelatedMember extends Pick<Member, 'id'> {
  request: Pick<Request, 'id' | 'product' | 'productVariant'>;
}

export interface MemberConnectedMembersQueryProps {
  memberId: string;
  children(members: RelatedMember[], isLoading: boolean): React.ReactElement<any>;
}

const MemberConnectedMembersQuery = ({ memberId, children }: MemberConnectedMembersQueryProps) => (
  <GenieQuery<Pick<Query, 'listJourneys'>, QueryListJourneysArgs>
    query={gql`
      query ListJourneys($input: ListJourneysInput!) {
        listJourneys(input: $input) {
          edges {
            node {
              id
              request {
                id
                product
                productVariant
                journeys {
                  state
                  member {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `}
    notifyOnNetworkStatusChange
    errorPolicy="all"
    variables={{
      input: {
        memberId,
      },
    }}
  >
    {(response) => {
      const memberJourneys = response.data?.listJourneys?.edges?.map(journeyEdge => journeyEdge.node) || [];
      const requests = memberJourneys
        .filter(journey => journey.state !== JourneyState.Trash)
        .filter(journey => journey.request)
        .filter(journey => journey.request.journeys?.length > 0)
        .map(journey => journey.request);

      const relatedMembers: RelatedMember[] = [];

      requests.forEach(request => {
        request.journeys.forEach(journey => {
          if (journey.member && journey.member.id !== memberId) {
            const { id } = journey.member;
            relatedMembers.push({
              id,
              request: {
                id: request.id,
                product: request.product,
              },
            });
          }
        });
      });

      return children(relatedMembers, response.loading);
    }}
  </GenieQuery>
);

export default MemberConnectedMembersQuery;
