import { useQuery } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { PageableData } from './runStatsQuery';

export type PagedQueryResult<TData extends PageableData> = {
  data: TData;
  isLoading: boolean;
  refetch: () => Promise<void>;
  loadMore: () => void;
}

const usePagedQuery = <TData extends PageableData>(query: DocumentNode, queryInput: object, queryName: string, batchSize = 50) => {

  const variables = {
    input: {
      ...queryInput,
      pagination: {
        first: batchSize,
      },
    }
  };

  const queryResult = useQuery<{[key: string]: TData}>(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const { data, refetch, loading, fetchMore } = queryResult;

  const loadMore = () => {
    const lastCursor = data[queryName]?.edges?.slice(-1)[0]?.cursor;
    fetchMore({
      variables: {
        input: {
          ...queryInput,
          pagination: {
            first: batchSize,
            after: lastCursor
          },
        }
      },
      updateQuery: (previousQueryResult, result) => {
        const updatedResult = {
          [queryName]: {
            ...previousQueryResult[queryName],
            edges: [
              ...previousQueryResult[queryName]?.edges,
              ...result.fetchMoreResult[queryName]?.edges,
            ],
            pageInfo: result.fetchMoreResult?.[queryName]?.pageInfo,
          },
        };
        return updatedResult;
      }
    });
  }

  return {
    data: data?.[queryName],
    isLoading: loading,
    refetch: async () => {
      await refetch();
    },
    loadMore,
  };
}

export default usePagedQuery;
