import * as React from 'react';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import FormGroup from '../widgets/form/FormGroup';
import JourneySearchField from '../../graphql/containers/journeys/JourneySearchField';

interface ConsentReassignFormProps extends Pick<BaseFormProps, 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
  consentId: string;
}

const ConsentReassignForm = (props: ConsentReassignFormProps) => {
  const { isLoading, onCancel, errorMessage, onSubmit } = props;

  return (
    <BaseForm
      initialValues={{}}
      submitButtonLabel="Reassign"
      hasCancel={!!onCancel}
      onCancel={onCancel}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={(values) => onSubmit({
        ...values,
      })}
    >
      {({
          setFieldValue,
        }) => (
        <div>
          <FormGroup
            controlId="journey"
            label="Find the journey to assign this consent to..."
          >
            <JourneySearchField
              onSelectJourney={(journeyId) => setFieldValue('journeyId', journeyId)}
            />
          </FormGroup>
        </div>
      )}
    </BaseForm>
  );
}

export default ConsentReassignForm;
