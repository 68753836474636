import * as React from 'react';
import { startCase } from 'lodash';
import { FamilyHistoryCategory } from '../../../graphql/genie-api-types';
import ActionLink from '../widgets/ActionLink';

interface FamilyHistoryCategoryBoxProps {
  memberId: string;
  category?: FamilyHistoryCategory;
  label?: string;
  onAddHistoryItem?(memberId: string, category: string): void;
  children: React.ReactNode;
}

const FamilyHistoryCategoryBox = ({ memberId, category, children, label, onAddHistoryItem }: FamilyHistoryCategoryBoxProps) => (
  <>
    <div className="flex justify-between">
      <h3 className="text-h3 font-bold">{label || startCase(category.toLowerCase())}</h3>
      {onAddHistoryItem && (
        <ActionLink
          className="text-xs inline-block font-bold ml-5 text-blue-dark"
          onClick={() => onAddHistoryItem(memberId, category)}
        >
          + Add
        </ActionLink>
      )}
    </div>
    {children}
  </>
);

export default React.memo(FamilyHistoryCategoryBox);
