import { action } from 'typesafe-actions';
import { startCase } from 'lodash';
import { AnalysisActionTypes, Modifications } from './types';

export const toggleDiseaseExpansion = (diseaseId: string) =>
  action(AnalysisActionTypes.TOGGLE_DISEASE_EXPAND, diseaseId);

export const editDiseaseField = (diseaseId: string, fieldName: string, value: string) =>
  action(AnalysisActionTypes.EDIT_DISEASE_FIELD, { diseaseId, fieldName, value });

export const resetDiseaseField = (diseaseId: string, fieldName: string, autoConfirm = false) => {
  const confirmed = autoConfirm || window.confirm(`Are you sure you would like to reset ${startCase(fieldName)}?`);
  if (confirmed) {
    return action(AnalysisActionTypes.RESET_DISEASE_FIELD, { diseaseId, fieldName });
  }

  return action('');
};

export const changeModification = (diseaseId: string, modification: keyof Modifications, value: any) =>
  action(AnalysisActionTypes.CHANGE_MODIFICATION, { diseaseId, modification, value});
