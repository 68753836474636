import gql from 'graphql-tag';

export const SALES_LEAD_FRAGMENT = gql`
  fragment LeadParts on Lead {
    id
    name
    phone
    email
    product
    status
    doNotContact
    dateLastContact
    dateCreated
    additionalData
  } 
`;