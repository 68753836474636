import gql from 'graphql-tag';
import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { FamilyHistoryItem, Journey, Member, Mutation, MutationSetResearchExtractSubmittedArgs, MutationUpsertResearchExtractArgs, Note, QueryRequestArgs, Report, Request, ResearchExtract } from '../../../../graphql/genie-api-types';
import { appSyncClient } from '../../../../utilities/appSync';
import FamilyHistory from '../../../components/family-history/FamilyHistory';
import ReportIframe from '../../../components/report/export/ReportIframe';
import { Collapsible } from '../../../components/widgets/Collapsible';
import LoadingOverlay from '../../../components/widgets/LoadingOverlay';
import Screen from '../../../components/widgets/Screen';
import Spinner from '../../../components/widgets/Spinner';
import NoteComponent from '../../../components/widgets/Note';
import Tabs from '../../../components/widgets/Tabs';
import { ReportState } from '../../../store/report';
import { PATHS } from '../../../store/router/types';
import { FiLink } from '../../../components/widgets/Icon';
import ResearchExtractForm from '../../../components/research-extract/ResearchExtractForm';
import ShopifyMemberSearch from '../../../components/research-extract/ShopifyMemberSearch';
import DiseaseDetailsSearch from '../../../components/research-extract/DiseaseDetailsSearch';
import generatedExtractFromRequest from '../../../../utilities/extract/generateExtractFromRequest';
import { Extract } from '@eugenelabs/types--research-extract';
import { useDispatch } from 'react-redux';
import { hideToast, showLoadingToast, showToast } from '../../../store/toast/actions';
import { fetchFromApiBridge } from '../../../../utilities/apiBridge';
import ResearchSessionHeader, { ActionNames } from '../../../components/research-extract/ResearchSessionHeader';
import GeneticJokes from '../../../components/widgets/GeneticJokes';
import { copyToClipboard } from '../../../../utilities/helpers';

interface MatchParams {
  requestId: string;
}

const REQUEST_EXTRACT_QUERY = gql`
  query Request($id: ID!) {
    request(id: $id) {
      id
      dateCreated
      extractSubmitted
      researchExtract {
        id
        data
        dateCreated
        dateUpdated
        dateSubmitted
      }
      initiatingMember {
        id
        email
      }
      report {
        id
        data
      }
      pregnancy {
        days
        dueDate
      }
      notes {
        date 
        text
        username
        isImportant
      }
      journeys {
        id
        member {
          id
          dateOfBirth
          name
          familyHistoryGroup {
            id
            category
            classification
            dateCreated
            dateUpdated
            name
            source
            value
          }
        }
      }
    }
  }
`;

interface MemberPartial extends Pick<Member, 'id' | 'dateOfBirth' | 'name'> {
  familyHistoryGroup: Pick<FamilyHistoryItem, 'id' | 'category' | 'classification' | 'dateCreated' | 'dateUpdated' | 'name' | 'source' | 'value'>[];
}

interface JourneyPartial extends Pick<Journey, 'id'> {
  member: MemberPartial;
}

interface RequestPartial extends Pick<Request, 'id' | 'dateCreated' | 'extractSubmitted'> {
  report: Pick<Report, 'id' | 'data'>;
  journeys: JourneyPartial[];
  notes: Pick<Note, 'date' | 'text' | 'username' | 'isImportant'>[];
  initiatingMember: Pick<Member, 'id' | 'email'>;
  researchExtract?: Pick<ResearchExtract, 'id' | 'data' | 'dateCreated' | 'dateUpdated'>;
}

interface QueryResult {
  request: RequestPartial;
}

const SAVE_EXTRACT_MUTATION = gql`
  mutation UpsertResearchExtract($input: UpsertResearchExtractInput!) {
    upsertResearchExtract(input: $input) {
      researchExtract {
        id
        dateSubmitted
        dateCreated
        data
      }
    }
  }
`;

const SET_RESEARCH_EXTRACT_SUBMITTED_MUTATION = gql`
  mutation SetResearchExtractSubmitted($input: SetResearchExtractSubmittedInput!) {
    setResearchExtractSubmitted(input: $input) {
      researchExtract {
        id
        dateSubmitted
        dateCreated
        data
      }
    }
  }
`;

const columnWrapperStyle: React.CSSProperties = {
  height: 'calc(100vh - 220px)',
};

const ClinicalResearchExtractCreate = (props: RouteComponentProps<MatchParams>) => {
  const [formValues, setFormValues] = React.useState<Partial<Extract>>({});
  const [isLoadingGeneratedRequestExtract, setIsLoadingGeneratedRequestExtract] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const { requestId } = props.match.params;
  const requestQuery = useQuery<QueryResult, QueryRequestArgs>(REQUEST_EXTRACT_QUERY, {
    client: appSyncClient,
    fetchPolicy: 'network-only',
    variables: {
      id: requestId,
    },
  });

  const dispatch = useDispatch();

  const [extractSaveMutate, extractSaveMutationResult] = useMutation<Pick<Mutation, 'upsertResearchExtract'>, MutationUpsertResearchExtractArgs>(SAVE_EXTRACT_MUTATION, {
    client: appSyncClient,
  });
  
  const [extractSetSubmittedMutate, extractSetSubmittedMutateResult] = useMutation<Pick<Mutation, 'setResearchExtractSubmitted'>, MutationSetResearchExtractSubmittedArgs>(SET_RESEARCH_EXTRACT_SUBMITTED_MUTATION, {
    client: appSyncClient,
  });

  const onSaveActionClick = React.useCallback(async (action: ActionNames) => {
    const savingToastId = 'researchSaving';
    dispatch(showLoadingToast(savingToastId, 'Saving extract...', 'Saving your extract to Genie'));
  
    await extractSaveMutate({
      variables: {
        input: {
          requestId,
          data: JSON.stringify(formValues),
        },
      }
    });
    
    dispatch(hideToast(savingToastId));
    if (!extractSaveMutationResult.error) {
      showToast('success', 'Saved research extract', 'Research extract saved in Genie.');
    }
    else {
      showToast('error', 'Error saving research extract to genie', 'The json values have been copied to clipboard. Paste it in a message to tech chat.');
    }
    
    if (action === 'Submit') {
      const shouldProceed = isValid || window.confirm('This form has validation errors, are you sure you want to submit it?');
      if (!shouldProceed) {
        return;
      }

      const loadingToastId = 'researchSubmitting';
      dispatch(showLoadingToast(loadingToastId, 'Submitting extract...', 'Submitting extract to research database.'));
      try {
        await fetchFromApiBridge('research-db', { report: formValues });
        await extractSetSubmittedMutate({
          variables: {
            input: {
              requestId,
            },
          },
        });
        await requestQuery.refetch({
          id: requestId,
        });
        showToast('success', 'Submission successfuly', 'Data was submitted successfully to research database');
      } catch (e) {
        copyToClipboard(JSON.stringify({ report: formValues }));
        showToast('error', 'Submission to research database failed.', 'Your extract was saved in Genie but could not be sent to the research database. Check to make sure you\'ve filled in all fields and try again.');
      }
      finally {
        dispatch(hideToast(loadingToastId));
      }
    }
  }, [formValues, requestId, extractSaveMutate, dispatch, isValid]);
  
  const onChangeValidation = React.useCallback((isValid: boolean) => {
    setIsValid(isValid);
  }, [setIsValid]);

  const onChangeForm = React.useCallback((values: Partial<Extract>) => {
    setFormValues(values);
  }, [setFormValues]);

  const onLoadGeneratedExtract = React.useCallback(async (requestId: string) => {
    setIsLoadingGeneratedRequestExtract(true);
    try {
      const generatedExtractValues = await generatedExtractFromRequest(requestId);
      setFormValues(generatedExtractValues);
    } catch (e) {
      console.error(e);
    }
    setIsLoadingGeneratedRequestExtract(false);
  }, [setIsLoadingGeneratedRequestExtract, setFormValues, formValues]);

  React.useEffect(() => {
    if (!requestQuery.data) {
      return;
    }

    if (requestQuery.data.request?.researchExtract?.data) {
      setFormValues(JSON.parse(requestQuery.data.request.researchExtract.data));
    }
    else {
      onLoadGeneratedExtract(requestQuery.data.request.id);
    }
  }, [requestQuery.data]);

  const reportState: ReportState | null = React.useMemo(() => {
    if (requestQuery.data?.request?.report?.data) {
      return JSON.parse(requestQuery.data?.request?.report?.data);
    }
    return null;
  }, [requestQuery.data?.request]);

  const members = React.useMemo(() => {
    return requestQuery?.data?.request?.journeys?.map(journey => journey.member) ?? [];
  }, [requestQuery?.data?.request]);

  const notes = React.useMemo(() => {
    return requestQuery?.data?.request?.notes ?? [];
  }, [requestQuery?.data?.request]);

  const journeyShowUrl = React.useMemo(() => {
    const journeyId = requestQuery?.data?.request?.journeys?.[0]?.id;
    return PATHS.JOURNEY_SHOW.replace(':journeyId', journeyId);
  }, [requestQuery?.data?.request]);

  const defaultShopifySearchTerm = React.useMemo(() => {
    return requestQuery?.data?.request?.initiatingMember?.email;
  }, [requestQuery?.data?.request]);
7
  return (
    <Screen
      screenTitleProps={{
        title: 'Create extract',
        action: requestQuery.loading ? <Spinner /> : (
          <GeneticJokes />
        ),
      }}
      fullWidth
    >
      <ResearchSessionHeader
        onActionClick={onSaveActionClick}
        requestId={requestId}
        researchExtract={requestQuery?.data?.request?.researchExtract}
      />
      {extractSaveMutationResult.loading || extractSetSubmittedMutateResult.loading && <LoadingOverlay />}
      {extractSaveMutationResult.error && <div className="text-red">{extractSaveMutationResult.error.message}</div>}
      {requestQuery.loading || isLoadingGeneratedRequestExtract ? <LoadingOverlay /> : (
        <div className="flex" style={columnWrapperStyle}>
          <div className="w-1/2 h-full overflow-y-auto">
            <ResearchExtractForm
              values={formValues}
              onChange={onChangeForm}
              onChangeValidation={onChangeValidation}
            />
          </div>
          <div className="w-1/2 border bg-grey-lightest p-15 rounded h-full overflow-y-auto">
            <div className="flex justify-between">
              <h2 className="font-bold text-lg mb-20 text-grey-darkest">Quick reference</h2>
              <a href={journeyShowUrl} target="_blank" rel="noopener noreferrer" className="text-purple font-bold"><FiLink /> Full profile</a>
            </div>
            {reportState && (
              <Collapsible
                title="Report"
                headingLevel={3}
              >
                <ReportIframe
                  reportState={reportState}
                  className="w-full mt-10 bg-white"
                  style={{ minHeight: '400px' }}
                />
              </Collapsible>
            )}
            <Collapsible
              title="Family history"
              defaultExpandedState={false}
              className="mt-20"
              headingLevel={3}
            >
              <Tabs
                tabs={members.map((member) => ({
                  name: member.id,
                  label: member.name,
                }))}
                defaultTab={members?.[0]?.id}
              >
                {(memberId) => (
                  <FamilyHistory
                    familyHistoryGroup={members.find(member => member.id === memberId).familyHistoryGroup}
                    memberId={memberId}
                    shouldShowExtra
                    shouldShowAsStacked
                  />
                )}
              </Tabs>
            </Collapsible>
            {notes.length > 0 && (
              <Collapsible
                title="Request notes"
                defaultExpandedState={false}
                className="mt-20"
                headingLevel={3}
              >
                {notes.map(note => (
                  <NoteComponent note={note} key={note.date} />
                ))}
              </Collapsible>
            )}
            <Collapsible
              title="Shopify member search"
              defaultExpandedState={false}
              className="mt-20"
              headingLevel={3}
            >
              <ShopifyMemberSearch
                defaultSearchTerm={defaultShopifySearchTerm}
              />
            </Collapsible>
            <Collapsible
              title="Disease search"
              defaultExpandedState={false}
              className="mt-20"
              headingLevel={3}
            >
              <DiseaseDetailsSearch />
            </Collapsible>
          </div>
        </div>
      )}
    </Screen>
  );
}

export default ClinicalResearchExtractCreate;
