import * as React from 'react';
import { Consent, Product } from '../../../graphql/genie-api-types';
import { formatDateFull } from '../../../utilities/helpers';
import { getProductLabel } from '../../../utilities/journeys/products';

interface ConsentFormHeaderProps {
  consent: Pick<Consent, 'name' | 'email' | 'dateCreated' | 'formId' | 'data' | 'source'>;
}

interface ConsentData {
  productType: string;
}


const ConsentFormHeader = (props: ConsentFormHeaderProps) => {
  const { name, email, dateCreated, data, formId, source } = props.consent;
  const consentData = JSON.parse(data ?? '{}') as ConsentData;
  return (
    <>
      <div className="mb-5">
        Name: {source === 'WEB' ? <><strong>{name}</strong> ({email})</> : <strong>PDF/Genie Submission</strong>}
      </div>
      <div className="my-5">
        Time: <strong>{formatDateFull(dateCreated, true)}</strong>
      </div>
      <div className="my-5 mb-20">
        Form: <strong>{consentData.productType ? getProductLabel(consentData.productType as Product) : 'No product'}</strong> {formId}
      </div>
    </>
  );
};

export default ConsentFormHeader;
