import * as React from 'react';
import { concat, uniqBy } from 'lodash';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { FollowUp, QueryJourneyArgs } from '../../../graphql/genie-api-types';
import { QueryResult } from 'react-apollo';
import FormInput from '../widgets/form/FormInput';
import GenieQuery from '../../graphql/base/GenieQuery';
import gql from 'graphql-tag';
import Spinner from '../widgets/Spinner';
import ErrorBoundary from '../../base/ErrorBoundary';
import { DoctorClinicPartial, DOCTOR_CLINIC_FRAGMENT, formatDoctorClinicName } from '../../../utilities/doctorClinic';

export type FollowUpFormValues = {
  doctorId?: FollowUp['doctorClinic']['id'];
  dateFollowUp?: FollowUp['dateFollowUp'];
  reason?: FollowUp['reason'];
}

interface FollowUpFormProps extends Pick<BaseFormProps, 'onSubmit' | 'isLoading' | 'errorMessage' | 'onCancel'> {
  initialValues: FollowUpFormValues;
  journeyId: string;
}

interface JourneyResult {
  id: string;
  request: {
    referringDoctorClinic: DoctorClinicPartial;
  };
  referrals: {
    doctorClinic: DoctorClinicPartial;
  }[];
}

export interface JourneyQueryResult {
  journey: JourneyResult;
}

export const FOLLOW_UP_FORM_DOCTOR_QUERY = gql`
  query Journey($id: ID!) {
    journey(id: $id) {
      id
      request {
        referringDoctorClinic {
          ...DoctorClinicFields
        }
      }
      referrals {
        doctorClinic {
          ...DoctorClinicFields
        }
      }
    }
  }
  ${DOCTOR_CLINIC_FRAGMENT}
`;

const FollowUpForm = (props: FollowUpFormProps) => {
  return (
    <GenieQuery<JourneyQueryResult, QueryJourneyArgs>
      query={FOLLOW_UP_FORM_DOCTOR_QUERY}
      errorPolicy="all"
      variables={{
        id: props.journeyId,
      }}
    >
      {(result: QueryResult<JourneyQueryResult>) => result.loading ?
        (
          <Spinner label="Loading available doctors..." />
        ) : (
          <ErrorBoundary>
            <BaseForm
              {...props}
              submitButtonLabel="Save"
            >
              {({
                values,
                handleChange,
                setFieldValue,
              }) => {
                const { data } = result;
                const referralDoctorClinics = data?.journey?.referrals.map(referral => referral.doctorClinic);
                const allDoctors = uniqBy(concat(referralDoctorClinics, data?.journey?.request?.referringDoctorClinic ?? null).filter(doctor => doctor), 'id');
                return (
                  <div>
                    <FormInput
                      label='Doctor Clinic (from referrals and referring doctor)'
                      as="select"
                      name='doctorClinicId'
                      type="text"
                      value={values['doctorClinicId']}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      options={[
                        {
                          value: '',
                          label: 'No doctor clinic'
                        },
                        ...allDoctors.map(doctorClinic => ({
                          value: doctorClinic.id,
                          label: formatDoctorClinicName(doctorClinic),
                        }))
                      ]}
                    />
                    <FormInput
                      label="Date to follow up"
                      name="dateFollowUp"
                      type="date"
                      value={values['dateFollowUp']}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <FormInput
                      label="Reason for follow up"
                      name="reason"
                      as="textarea"
                      value={values['reason']}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                );
              }}
            </BaseForm>
          </ErrorBoundary>
        )}
    </GenieQuery>
  );
}

export default FollowUpForm;
