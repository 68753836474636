import * as React from 'react';
import UpdatesList from '../../components/updates/UpdatesList';
import Screen from '../../components/widgets/Screen';

const UpdatesIndex = () => (
  <Screen
    screenTitleProps={{
      title: 'What\'s new with Genie',
    }}
  >
    <UpdatesList /> 
  </Screen>
);

export default UpdatesIndex;