import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Doctor } from '../../../graphql/genie-api-types';
import DoctorRequestJourneys from '../../components/doctor/show/DoctorShowJourneys';
import DoctorShowHeader from '../../components/doctor/show/DoctorShowHeader';
import DoctorShowContact from '../../components/doctor/show/DoctorShowContact';
import Screen from '../../components/widgets/Screen';
import { DoctorShowContextWrapper } from '../../contexts/DoctorShowContext';
import DoctorShowClinics from '../../components/doctor/show/DoctorShowClinics';

interface MatchParams {
  doctorId: Doctor['id'];
}

type OwnProps = RouteComponentProps<MatchParams>;

const DoctorShow = (props: OwnProps) => {
  const { doctorId } = props.match.params;
  return (
    <Screen className="relative">
      <DoctorShowContextWrapper
        doctorId={doctorId}
      >
        <div className="relative">
          <div className="mb-40">
            <DoctorShowHeader />
            <DoctorShowContact />
          </div>
          <div className="mb-40">
            <DoctorShowClinics />
          </div>
          <DoctorRequestJourneys referringDoctorId={doctorId} />
        </div>
      </DoctorShowContextWrapper>
    </Screen>
  );
}

export default DoctorShow;
