import { ReportState } from '../../../app/store/report';
import { sectionTemplates } from '../html';
import { cloneDeep, has, get } from 'lodash';
import { extractReportTemplateVariables, ReportVariables } from './extractReportTemplateVariables';
import templates, { TemplatePage, ReportTemplate } from '.';
import { getFamilyReportTemplateVariables, FamilyReportVariables } from './carrier/familyReports';
import Handlebars from '../Handlebars';
import { CustomisationState } from '../../../app/store/report/customisation/types';

export type AvailableTemplates = 'coupleCarrier' | 'individualCarrier' | 'familyReportA' | 'familyReportB' | 'individualCancer';

export interface ProcessedTemplatePage extends TemplatePage {
  id: string;
}

const getTemplate = (templateName: AvailableTemplates) => {
  return templates[templateName];
};

export const getTemplateVariables = (templateName: AvailableTemplates, reportState: ReportState) => {
  switch (templateName) {
    case 'coupleCarrier':
      return extractReportTemplateVariables(reportState);

      case 'individualCarrier':
      return extractReportTemplateVariables(reportState);
      
    case 'familyReportA':
      return getFamilyReportTemplateVariables(reportState, 'clientA');

    case 'familyReportB':
      return getFamilyReportTemplateVariables(reportState, 'clientB');
      
    default:
      return extractReportTemplateVariables(reportState);
  }
}

const getTemplateVariablesForPage = (page: TemplatePage, pageIndex: number, templateVariables: ReportVariables|FamilyReportVariables, customisation: CustomisationState, template: ReportTemplate) => {
  const processedPage = cloneDeep(page) as ProcessedTemplatePage;

  if (page.repeat) {
    const key = get(templateVariables, page.repeat.repeaterId);

    processedPage.id = `p-${pageIndex}-r-${key}`;
  } else {
    processedPage.id = `p-${pageIndex}`;
  }

  processedPage.sections = processedPage.sections
    .filter(section => sectionTemplates[template.medium][section.template])
    .filter((section) => {
      let shouldShow = true;

      if (section.dependencies) {
        section.dependencies.forEach((dependency) => {
          if (!shouldShow) {
            return;
          }

          const negate = dependency[0] === '!';
          const objectPath = dependency.replace('!', '');
          const hasValue = has(templateVariables, objectPath) && !!get(templateVariables, objectPath);

          shouldShow = negate ? !hasValue : hasValue;
        });
      }

      return shouldShow;
    })
    .map((section) => {
      if (section.variables) {
        Object.keys(section.variables).forEach((variableName) => {
          const variableContent = section.variables[variableName];

          if (typeof variableContent === 'string') {
            const miniTemplate = Handlebars.compile(variableContent, { noEscape: true });
            section.variables[variableName] = miniTemplate(templateVariables);
          } else {
            section.variables[variableName] = variableContent;
          }
        });
      }

      const overrides = customisation.sectionVariableOverridesByTemplate?.[template.name]?.[processedPage.id]?.[section.id] ?? {};
      section.variables = {
        ...section.variables,
        ...overrides,
      };

      return section;
    });

  return processedPage;
};

export const getCompiledTemplateVariablesWithSectionOverrides = (templateName: AvailableTemplates, reportState: ReportState) => {
  const templateVariables = getTemplateVariables(templateName, reportState);
  const template = getTemplate(templateName);

  const pages: ProcessedTemplatePage[] = [];

  template?.pages.forEach((page, pageIndex) => {
    if (!page.repeat) {
      pages.push(getTemplateVariablesForPage(page, pageIndex, templateVariables, reportState.customisation, template));
    } else {
      const items = get(templateVariables, page.repeat.collection);
      items.forEach((item: any) => {
        pages.push(getTemplateVariablesForPage(page, pageIndex, {
          ...templateVariables,
          [page.repeat.as]: item,
        }, reportState.customisation, template));
      });
    }
  });

  return pages;
};
