import * as React from 'react';
import Spinner from './Spinner';
import { TabOption } from './Tabs';

interface TabsNavProps {
  tabs: TabOption<string>[];
  activeTab: string;
  onSelectTab(tabName: string): void;
  isLoading?: boolean;
  className?: string;
  isDisabled?: boolean;
}

const wrapperStyle: React.CSSProperties = {
  borderBottom: '2px solid rgba(15,0,100,0.2)',
  color: 'rgba(15,0,100,0.8)',
}

const activeStyle: React.CSSProperties = {
  borderColor: '#0F0064',
};

const TabsNav = (props: TabsNavProps) => {
  const { activeTab, onSelectTab, tabs, isLoading, isDisabled, className } = props;
  return (
    <div className={`relative mt-20 ${isDisabled ? 'opacity-50' : ''}`}>
      <ul className={`list-reset flex w-full text-base justify-start align-start ${className}`} style={wrapperStyle}>
        {tabs.map(tab => (
          <li className="mr-2" key={tab.name}>
            <button
              className={`ml-5 mr-15 font-bold block no-underline py-5 cursor-pointer focus:outline-none ${tab.name === activeTab ? 'border-b-2 border-blue -mb-2' : ''}`}
              style={tab.name === activeTab ? activeStyle : undefined}
              onClick={() => onSelectTab(tab.name)}
              disabled={isDisabled}
            >
              {tab.label} <span className={isLoading ? 'opacity-50': ''}>{tab.count !== undefined ? `(${tab.count})` : ''}</span>
            </button>
          </li>
        ))}
      </ul>
      {isLoading && <Spinner className="absolute right-0 top-0 mt-5" color="purple" />}
    </div>
  );
};

export default TabsNav;
