import * as React from 'react';
import * as pc from './projection/Calculations';
import * as pf from './projection/DataFormatter';
import Tabs from '../../widgets/Tabs';
import ProjectionBarChart from './projection/BarChart';
import ProjectionTable from './projection/ProjectionTable';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AppointmentPredictionResult, Product, QueryAppointmentPredictionArgs } from '../../../../graphql/genie-api-types';
import LoadingOverlay from '../../widgets/LoadingOverlay';
import SettingsForm, { FormPayload } from './projection/SettingsForm';
import { useState } from 'react';

// Tabs settings 
type TabModes = 'Bar Chart' | 'Table';

const projectionTabs: { name: TabModes; label: TabModes }[] = [
  {
    name: 'Bar Chart',
    label: 'Bar Chart',
  },
  {
    name: 'Table',
    label: 'Table',
  },
];

export const LIST_BOOKINGS_QUERY = gql`
  query ($product: Product!, $preTestTurnAroundTime: Int!, $postTestTurnAroundTime: Int!) {
    appointmentPrediction(product:$product, preTestTurnAroundTime: $preTestTurnAroundTime, postTestTurnAroundTime: $postTestTurnAroundTime) {
      preTestAppointments {
        datePredicted,
        total
      },
      postTestAppointments {
        datePredicted,
        total
      }
    }
  }
`;
interface QueryResult {
  appointmentPrediction: AppointmentPredictionResult;
}

const AppointmentProjection = () => {
  const [projectionState, setProjectionState] = useState({
    displayAdvForm: false,
    avgPreTat: 7,
    avgPostTat: 21,
    cancerPretestApptTime: 30,
    cancerPosttestApptTime: 30,
    cancerFhxreviewApptTime: 15,
    carrierPretestApptTime: 30,
    carrierFhxreviewApptTime: 15,
    carrierLowriskApptTime: 15,
    carrierIncreasedriskApptTime: 30,
    carrierHighriskApptTime: 60,
    cancerPretestRatio: 100,
    cancerPosttestRatio: 100,
    cancerFhxreviewRatio: 0,
    carrierPretestRatio: 2,
    carrierFhxreviewRatio: 10,
    carrierLowriskRatio: 90,
    carrierIncreasedriskRatio: 5,
    carrierHighriskRatio: 5,
    howManyWeeksToProject: 3,
  })

  const carrierQuery = useQuery<QueryResult, QueryAppointmentPredictionArgs>(LIST_BOOKINGS_QUERY, {
    variables: {
      product: Product.Carrier,
      preTestTurnAroundTime: projectionState.avgPreTat,
      postTestTurnAroundTime: projectionState.avgPostTat
    }
  });

  const cancerQuery = useQuery<QueryResult, QueryAppointmentPredictionArgs>(LIST_BOOKINGS_QUERY, {
    variables: {
      product: Product.Cancer,
      preTestTurnAroundTime: projectionState.avgPreTat,
      postTestTurnAroundTime: projectionState.avgPostTat
    }
  });
  const isLoading = carrierQuery.loading || cancerQuery.loading;
  if (isLoading) {
    return <LoadingOverlay />
  }

  const carrierData = carrierQuery.data?.appointmentPrediction;
  const cancerData = cancerQuery.data?.appointmentPrediction;

  // calculate how many minutes of appointments are required per week
  const cancerPretestWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPreTat, cancerData.preTestAppointments, projectionState.cancerPretestRatio, projectionState.cancerPretestApptTime);
  const cancerFhxWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPreTat, cancerData.preTestAppointments, projectionState.cancerFhxreviewRatio, projectionState.cancerFhxreviewApptTime);
  const cancerPosttestWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPostTat, cancerData.postTestAppointments, projectionState.cancerPosttestRatio, projectionState.cancerPosttestApptTime);
  const carrierPretestWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPreTat, carrierData.preTestAppointments, projectionState.carrierPretestRatio, projectionState.carrierPretestApptTime);
  const carrierFhxWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPreTat, carrierData.preTestAppointments, projectionState.carrierFhxreviewRatio, projectionState.carrierFhxreviewApptTime);
  const carrierLowriskWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPostTat, carrierData.postTestAppointments, projectionState.carrierLowriskRatio, projectionState.carrierLowriskApptTime);
  const carrierIncreasedriskWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPostTat, carrierData.postTestAppointments, projectionState.carrierIncreasedriskRatio, projectionState.carrierIncreasedriskApptTime);
  const carrierHighriskWeeklyMinutes = pc.getTestWeeklyTotal(projectionState.avgPostTat, carrierData.postTestAppointments, projectionState.carrierHighriskRatio, projectionState.carrierHighriskApptTime);

  // format the data for the bar chart
  const barChartData = pf.barChartDataFormatter(projectionState.howManyWeeksToProject, cancerPretestWeeklyMinutes, cancerFhxWeeklyMinutes, cancerPosttestWeeklyMinutes, carrierPretestWeeklyMinutes, carrierFhxWeeklyMinutes, carrierLowriskWeeklyMinutes, carrierIncreasedriskWeeklyMinutes, carrierHighriskWeeklyMinutes);

  const tableHeaders = pf.tableHeaderFormatter(projectionState.howManyWeeksToProject);

  const tableData = [
    {
      id: 'projectionTable',
      showGroupIndicator: false,
      isSelectable: false,
      rows: pf.tableDataFormatter(projectionState.howManyWeeksToProject, cancerPretestWeeklyMinutes, cancerFhxWeeklyMinutes, cancerPosttestWeeklyMinutes, carrierPretestWeeklyMinutes, carrierFhxWeeklyMinutes, carrierLowriskWeeklyMinutes, carrierIncreasedriskWeeklyMinutes, carrierHighriskWeeklyMinutes),
    },
  ];

  const renderProjectionTab = (tabName: TabModes) => {
    switch (tabName) {
      case 'Bar Chart':
        return <ProjectionBarChart chartData={barChartData} />;
      case 'Table':
        return <ProjectionTable tableHeader={tableHeaders} tableColumn={[]} tableData={tableData} />
    }
  };

  const onFormSubmit = (values: FormPayload) => {
    setProjectionState({ ...values })
  }

  return (
    <div className='overflow-x-auto'>
      <SettingsForm
        isLoading={isLoading}
        initialValues={projectionState}
        onSubmit={async (e: FormPayload) => { onFormSubmit(e); }}
      />

      <Tabs<TabModes>
        tabs={projectionTabs}
        defaultTab={'Bar Chart'}
        className="mb-15"
      >
        {tabName => renderProjectionTab(tabName)}
      </Tabs>
    </div>
  );
};

export default AppointmentProjection;
