/*
  This file compensates for a lack of tree shaking with parceljs and typescript. Could be made
  redundant in future versions of either.
*/

import { Bug as FaBug } from '@styled-icons/fa-solid/Bug';
import { ParachuteBox as FaParachuteBox } from '@styled-icons/fa-solid/ParachuteBox';
import { PlaneDeparture as FaPlaneDeparture } from '@styled-icons/fa-solid/PlaneDeparture';
import { Pen as FaPen } from '@styled-icons/fa-solid/Pen';
import { CircleNotch as FaCircleNotch } from '@styled-icons/fa-solid/CircleNotch';
import { ArrowRight as FaArrowRight } from '@styled-icons/fa-solid/ArrowRight';
import { Info as FiInfo } from '@styled-icons/feather/Info';
import { CheckCircle as FiCheck } from '@styled-icons/fa-solid/CheckCircle';
import { Edit2 as FiEdit2 } from '@styled-icons/feather/Edit2';
import { Flag as FiFlag } from '@styled-icons/feather/Flag';
import { ArrowRight as FiArrowRight } from '@styled-icons/feather/ArrowRight';
import { RefreshCcw as FiRefreshCcw } from '@styled-icons/feather/RefreshCcw';
import { Edit3 as FiEdit3 } from '@styled-icons/feather/Edit3';
import { Times as FiX } from '@styled-icons/fa-solid/Times';
import { Repeat as FiRepeat } from '@styled-icons/feather/Repeat';
import { AlertTriangle as FiAlertTriangle } from '@styled-icons/feather/AlertTriangle';
import {ExclamationSquareFill as BSExclamationSquareFill } from '@styled-icons/bootstrap/ExclamationSquareFill';
import { ChevronDown as FiChevronDown } from '@styled-icons/feather/ChevronDown';
import { ChevronRight as FiChevronRight } from '@styled-icons/feather/ChevronRight';
import { CheckSquare as FiCheckSquare } from '@styled-icons/feather/CheckSquare';
import { Square as FiSquare } from '@styled-icons/feather/Square';
import { Clock as FiClock } from '@styled-icons/feather/Clock';
import { Edit as FiEdit } from '@styled-icons/feather/Edit';
import { Calendar as FiCalendar } from '@styled-icons/feather/Calendar';
import { Inbox as FiInbox } from '@styled-icons/feather/Inbox';
import { FileText as FiFileText } from '@styled-icons/feather/FileText';
import { Eye as FiEye } from '@styled-icons/feather/Eye';
import { EyeOff as FiEyeOff } from '@styled-icons/feather/EyeOff';
import { Send as FiSend } from '@styled-icons/feather/Send';
import { Archive as FiArchive } from '@styled-icons/feather/Archive';
import { ThumbsUp as FiThumbsUp } from '@styled-icons/feather/ThumbsUp';
import { Shuffle as FiShuffle } from '@styled-icons/feather/Shuffle';
import { UserMinus as FiUserMinus } from '@styled-icons/feather/UserMinus';
import { ChevronUp as FiChevronUp } from '@styled-icons/feather/ChevronUp';
import { ChevronLeft as FiChevronLeft } from '@styled-icons/feather/ChevronLeft';
import { Search as FiSearch } from '@styled-icons/feather/Search';
import { RefreshCw as FiRefreshCw } from '@styled-icons/feather/RefreshCw';
import { Mail as FiMail } from '@styled-icons/feather/Mail';
import { PhoneCall as FiPhoneCall } from '@styled-icons/feather/PhoneCall';
import { Activity as FiActivity } from '@styled-icons/feather/Activity';
import { MessageSquare as FiMessageSquare } from '@styled-icons/feather/MessageSquare';
import { ArrowLeft as FiArrowLeft } from '@styled-icons/feather/ArrowLeft';
import { ArrowDown as FiArrowDown } from '@styled-icons/feather/ArrowDown';
import { ArrowUp as FiArrowUp } from '@styled-icons/feather/ArrowUp';
import { MessageCircle as FiMessageCircle } from '@styled-icons/feather/MessageCircle';
import { PackageIcon as FiPackage } from '@styled-icons/feather/PackageIcon';
import { Link as FiLink } from '@styled-icons/feather/Link';
import { ExternalLink as FiExternalLink } from '@styled-icons/feather/ExternalLink';
import { Plus as FiPlus } from '@styled-icons/feather/Plus';
import { Printer as FiPrinter } from '@styled-icons/feather/Printer';
import { UploadCloud as FiUploadCloud } from '@styled-icons/feather/UploadCloud';
import { Copy as FiCopy } from '@styled-icons/feather/Copy';
import { MinusCircle as FiMinusCircle } from '@styled-icons/feather/MinusCircle';
import { Minus as FiMinus } from '@styled-icons/feather/Minus';
import { AlertOctagon as FiAlertOctagon } from '@styled-icons/feather/AlertOctagon';
import { Box as FiBox } from '@styled-icons/feather/Box';
import { Users as FiUsers } from '@styled-icons/feather/Users';
import { User as FiUser } from '@styled-icons/feather/User';
import { Trash as FiTrash } from '@styled-icons/feather/Trash';
import { PauseCircle as FiPauseCircle } from '@styled-icons/feather/PauseCircle';
import { Star as FiStar } from '@styled-icons/feather/Star';
import { Save as FiSave } from '@styled-icons/feather/Save';
import { MinusSquare as FiMinusSquare } from '@styled-icons/feather/MinusSquare';

import { PlusSquare as FiPlusSquare } from '@styled-icons/feather/PlusSquare';

export {
  FiStar,
  FiMinus,
  FiUser,
  FiUsers,
  FiAlertOctagon,
  FiBox,
  FaBug,
  FaParachuteBox,
  FaPlaneDeparture,
  FaPen,
  FaCircleNotch,
  FaArrowRight,
  FiInfo,
  FiCheck,
  FiFlag,
  FiArrowRight,
  FiEdit,
  FiEdit2,
  FiEdit3,
  FiRefreshCcw,
  FiX,
  FiRepeat,
  FiAlertTriangle,
  FiChevronDown,
  FiChevronRight,
  FiCheckSquare,
  FiSquare,
  FiClock,
  FiCalendar,
  FiInbox,
  FiFileText,
  FiEye,
  FiSend,
  FiSave,
  FiArchive,
  FiThumbsUp,
  FiShuffle,
  FiUserMinus,
  FiChevronUp,
  FiChevronLeft,
  FiSearch,
  FiRefreshCw,
  FiMail,
  FiPhoneCall,
  FiActivity,
  FiMessageSquare,
  FiArrowLeft,
  FiArrowDown,
  FiArrowUp,
  FiMessageCircle,
  FiPackage,
  FiLink,
  FiExternalLink,
  FiPlus,
  FiPrinter,
  FiUploadCloud,
  FiCopy,
  FiMinusCircle,
  FiTrash,
  FiPauseCircle,
  FiEyeOff,
  FiPlusSquare,
  BSExclamationSquareFill,
  FiMinusSquare,
};
