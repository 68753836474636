import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Member } from '../../../graphql/genie-api-types';
import Spinner from '../../components/widgets/Spinner';
import { useDispatch } from 'react-redux';
import { navigate } from '../../store/router/actions';
import FamilyHistory from '../../components/family-history/FamilyHistory';
import Button from '../../components/widgets/Button';
import MemberFamilyHistoryQuery from '../../graphql/containers/members/MemberFamilyHistoryQuery';
import MemberConnectedMembersQuery from '../../graphql/containers/members/MemberConnectedMembersQuery';
import { getProductLabel } from '../../../utilities/journeys/products';
import { formatDateOfBirth, formatSex } from '../../../utilities/helpers';
import Screen from '../../components/widgets/Screen';
import moment from 'moment';
import { useState } from 'react';
import RouteButton from '../../components/widgets/RouteButton';
import { PATHS } from '../../store/router/types';

interface MatchParams {
  memberId: Member['id'];
}

type OwnProps = RouteComponentProps<MatchParams>;

interface MemberFamilyHistoryItemProps {
  memberId: string;
  shouldShowExtra: boolean;
}

export const MemberFamilyHistoryItem = (props: MemberFamilyHistoryItemProps) => {
  const [dateFilter, setDateFilter] = useState('');
  const { memberId, shouldShowExtra } = props;
  const dispatch = useDispatch();

  const onAddHistoryItem = React.useCallback((memberId: string, category: string) =>
    dispatch(navigate.toForm('FamilyHistoryCreate', {
      memberId,
      category,
    })), [dispatch]);

  const onEditHistoryItem = React.useCallback((familyHistoryGroupItemId: string) =>
    dispatch(navigate.toForm('FamilyHistoryUpdate', {
      familyHistoryGroupItemId,
    })), [dispatch]);

  return (
    <div>
      <MemberFamilyHistoryQuery
        memberId={memberId}
      >
        {(member) => {
          const dateFilterVals = member?.familyHistoryGroup?.map(item => moment(item.dateCreated).format("DD MMM YY"));
          const uniqueDateFilterVals = [...Array.from(new Set(dateFilterVals))];

          let renderFilteredHistory;
          if (dateFilter !== '') {
            renderFilteredHistory = member?.familyHistoryGroup?.filter(item => moment(item.dateCreated).format("DD MMM YY") === dateFilter);
          } else {
            renderFilteredHistory = member?.familyHistoryGroup;
          }
          return (
            <>
              <h3 className="text-black font-bold text-2xl mb-10 justify-between flex w-full items-end">
                <span className="flex items-center">
                  <RouteButton path={PATHS.JOURNEY_SHOW.replace(":journeyId", member?.journeys[0].id)} isUnstyled={true} className="underline cursor-pointer font-bold">{member?.name}</RouteButton>
                </span>
                <span className="text-grey-dark font-normal ml-10 text-lg">
                  {formatDateOfBirth(member?.dateOfBirth)}{' '}
                  {formatSex(member?.sex)}
                </span>
              </h3>
              {uniqueDateFilterVals &&
                <div className="mb-10">
                  <span className="mr-5">Submission date:</span>
                  {uniqueDateFilterVals?.map(element => {
                    return <Button variant="link" size="sm" key={element.split(" ").join("-")} className="mr-4" onClick={() => setDateFilter(element)}>{element}</Button>
                  })}
                  <Button variant="link" size="sm" key="all" className="mr-4" onClick={() => setDateFilter("")}>Show All</Button>
                </div>
              }
              {member && (
                <FamilyHistory
                  familyHistoryGroup={renderFilteredHistory}
                  memberId={member.id}
                  onAddHistoryItem={onAddHistoryItem}
                  onEditHistoryItem={onEditHistoryItem}
                  shouldShowExtra={shouldShowExtra}
                />
              )}
            </>
          );
        }}
      </MemberFamilyHistoryQuery>
    </div>
  );
};

const MemberFamilyHistory = (props: OwnProps) => {
  const [shouldShowExtra, setShouldShowExtra] = React.useState(false);

  const onToggleShowExtra = React.useCallback(() => {
    setShouldShowExtra(!shouldShowExtra);
  }, [shouldShowExtra, setShouldShowExtra]);

  const { memberId } = props.match.params;

  return (
    <Screen className="relative">
      <div className="mb-30 flex justify-between items-center font-bold">
        <div className="flex">
          <span className="mr-15 font-normal text-grey">Key:</span>
          <span className="mr-15">They&apos;ve written this</span>
          <span className="mr-15 text-grey-dark">We&apos;ve written this</span>
          <span className="mr-15 text-orange-dark">This is important</span>
        </div>
        <div>
          <Button variant="link" onClick={onToggleShowExtra}>
            {shouldShowExtra ? 'Hide' : 'Show'} extras
          </Button>
        </div>
      </div>
      <div className="relative">
        <MemberFamilyHistoryItem memberId={memberId} shouldShowExtra={shouldShowExtra} />
        <MemberConnectedMembersQuery memberId={memberId}>
          {(connectedMembers, isLoading) => {
            if (isLoading) {
              return <Spinner label="Loading connected family histories" />;
            }

            return (
              <>
                {connectedMembers.map(connectedMember => (
                  <React.Fragment key={connectedMember.id}>
                    <h3 className="text-grey-dark mb-5">Connected via {getProductLabel(connectedMember.request.product, connectedMember.request.productVariant)}</h3>
                    <MemberFamilyHistoryItem memberId={connectedMember.id} shouldShowExtra={shouldShowExtra} />
                  </React.Fragment>
                ))}
              </>
            )
          }}
        </MemberConnectedMembersQuery>
      </div>
    </Screen>
  );
};

export default MemberFamilyHistory;
