import * as React from 'react';
import GenieMutation from '../../base/GenieMutation';
import { Journey, MutationCreateFlagArgs } from '../../../../graphql/genie-api-types';
import { MutationFunction } from 'react-apollo';
import { showToast } from '../../../store/toast/actions';
import gql from 'graphql-tag';
import ReduxQueryListener from '../../../store/ReduxQueryListener';
import FlagForm, { FlagFormValues } from '../../../components/flag/FlagForm';

interface FlagCreateProps {
  onComplete(): void;
  journeyId: Journey['id'];
}

const mutationQuery = gql`
  mutation createFlag($input: CreateFlagInput!) {
    createFlag(input: $input) {
      flag {
        id,
      }
    }
  }
`;

const FlagCreate = (props: FlagCreateProps) => {
  const { journeyId, onComplete } = props;
  
  const onSubmit = React.useCallback(async (values: FlagFormValues, mutate: MutationFunction<MutationCreateFlagArgs>) => {
    const { flagType, priority, code } = values;

    await mutate({
      variables: {
        input: {
          journeyId,
          flagType,
          priority,
          code,
        },
      },
    });

    showToast('success', 'Created flag', 'Successfully created flag');

    ReduxQueryListener.triggerEvents('UpdateFlag');

    onComplete();
  }, [onComplete, journeyId]);

  return (
    <GenieMutation
      mutation={mutationQuery}
    >
      {(mutate, result) => (
        <FlagForm
          initialValues={{}}
          journeyId={journeyId}
          onSubmit={(values) => onSubmit(values, mutate)}
          isLoading={result.loading}
          errorMessage={result?.error?.message}
        />
      )}
    </GenieMutation>
  );
}

export default FlagCreate;
