interface RequestPanelInformation {
  text: string;
  className: string;
}

export const RequestPanelInformation = (numGenesTestedRecessive: number | null, numGenesTestedXLinked: number | null): RequestPanelInformation | null => {
  if (numGenesTestedRecessive === null) {
    return null;
  }

  let text = 'Expanded';
  let className = '';

  if (numGenesTestedRecessive > 500) {
    text = 'Comprehensive'
    className = 'text-purple';
  }

  const totalGenes = numGenesTestedRecessive + numGenesTestedXLinked;

  text += ` (${totalGenes} genes)`;

  return {
    text,
    className
  };
}