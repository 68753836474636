import { JourneyStateAction } from '../../../utilities/journeys/states';
import { AppDispatch } from '..';
import requestJourneyRequestId from '../../graphql/requests/journey/requestJourneyRequestId';
import updateJourneyState from '../../graphql/requests/journey/updateJourneyState';
import { JourneyState, Journey } from '../../../graphql/genie-api-types';
import { createReportFromRequest, viewRequestSession, createMemberReportEmail } from '../report/actions';
import { navigate } from '../router/actions';

export type JourneyActionType = JourneyStateAction['type'] | '_onHold' | '_edit' | '_move' | '_updateJourney';

const actionsRequiringRequestId: JourneyActionType[] = [
  '_edit',
  '_onHold',
  'createReport',
  'viewReport',
  'createMemberReportEmail',
  'markAsVerified',
];

const batchFormActions: JourneyActionType[] = [
  '_move',
];

export const applyActionToJourneys = async (actionType: JourneyActionType, actionValue: JourneyStateAction['value'], journeyIds: Journey['id'][], dispatch: AppDispatch) => {
  const doesRequireRequestId = actionsRequiringRequestId.includes(actionType);
  const isBatchFormAction = batchFormActions.includes(actionType);

  if (isBatchFormAction) {
    switch (actionType) {
      case '_move':
        dispatch(navigate.toForm('JourneysMove', { journeyIds: journeyIds }));
        break;

      default:
        break;
    }
    return;
  }

  return Promise.all(
    journeyIds.map(async journeyId => {
      const requestId = doesRequireRequestId ? await requestJourneyRequestId(journeyId) : null;
      switch (actionType) {
        case '_edit':
          dispatch(navigate.toForm('RequestUpdate', { requestId }));
          break;

        case '_onHold':
          dispatch(navigate.toForm('RequestHoldStatusUpdate', { requestId }));
          break;

        case 'moveToState':
          await updateJourneyState(journeyId, actionValue as JourneyState);
          break;

        case 'createReport':
          dispatch(createReportFromRequest(requestId));
          break;

        case 'viewReport':
          dispatch(viewRequestSession(requestId));
          break;

        case 'createMemberReportEmail':
          dispatch(createMemberReportEmail(requestId));
          break;

        case 'markAsVerified':
          dispatch(navigate.toForm('RequestApproval', { requestId }));
          break;

        case 'markAsConsented':
          dispatch(navigate.toForm('JourneyConsent', { journeyId }));
          break;

        case 'addLabOrderNumber':
          dispatch(navigate.toForm('JourneyLabOrderNumber', { journeyId }));
          break;

        case '_updateJourney':
          dispatch(navigate.toForm('JourneyUpdate', { journeyId }));
          break;

        default:
          return;
      }
    })
  );
};
