import * as React from 'react';
import moment from 'moment';
import { Kit, Journey, Member, PageInfo, KitsConnection, QueryListKitsArgs, KitScope } from '../../../graphql/genie-api-types';
import gql from 'graphql-tag';
import LabShippingHistoryShipment, { LabShipment } from '../../containers/shipments/LabShippingHistoryShipment';
import Spinner from '../../components/widgets/Spinner';
import Screen from '../../components/widgets/Screen';
import Button from '../../components/widgets/Button';
import { FiPrinter } from '../../components/widgets/Icon';
import ScreenTitle from '../../components/widgets/ScreenTitle';
import StatsForm from '../../components/stats/StatsForm';
import useStatsQuery from '../../../utilities/useStatsQuery';
import { Filters } from '../stats/Stats';

export const LAB_SHIPPING_HISTORY_QUERY = gql`
  query ListKits($input: ListKitsInput!) {
    listKits(input: $input) {
      edges {
        node {
          id
          dateShippedToLab
          registrationNumber
          journey {
            id
            startDate
            labOrderNumber
            member {
              id
              name
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export interface ListKitsJourneyResult extends Pick<Journey, 'id' | 'labOrderNumber' | 'addressProvince'> {
  member: Pick<Member, 'id'|'name'>;
};

export interface ListKitsKitResult extends Pick<Kit, 'id' | 'dateShippedToLab' | 'registrationNumber'> {
  journey: ListKitsJourneyResult;
}

export interface ListKitsResult {
  listKits: {
    edges: {
      node: ListKitsKitResult;
      cursor: string;
    }[];
    pageInfo: PageInfo;
  };
}

const LabShippingHistory = () => {
  const [filters, setFilters] = React.useState<Filters>({
    fromDate: moment().subtract(356, 'd').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const kitEdges = useStatsQuery<KitsConnection, QueryListKitsArgs>(LAB_SHIPPING_HISTORY_QUERY, {
    variables: {
      input: {
        scope: KitScope.ShippedToLab
      },
    },
  }, 'listKits');

  if (kitEdges.error) {
    return <div>{kitEdges.error.message}</div>
  }

  if (kitEdges.loading) {
    return <Spinner className="mt-20" label={`Fetching all shipment history...(${kitEdges.data?.edges?.length || 0} shipments found)`} />
  }

  const edges = kitEdges.data?.edges;

  const labShipmentsByDate: { [key: number]: LabShipment } = {};
  edges.forEach(kitEdge => {
    const date = moment(kitEdge.node.dateShippedToLab);
    const dateNumber = parseInt(date.format('YYYYMMDD'));
    const dateLabel = date.format('YYYY-MM-DD');

    if (!labShipmentsByDate[dateNumber]) {
      labShipmentsByDate[dateNumber] = {
        dateLabel,
        journeys: []
      };
    }
    labShipmentsByDate[dateNumber].journeys.push({
      journey: kitEdge.node.journey,
      registrationNumber: kitEdge.node.registrationNumber
    });
  });

  const labShipments = Object.keys(labShipmentsByDate).reverse().map(key => labShipmentsByDate[key as any as number]);

  const filteredShipments = labShipments.filter(labShipment => {
    return moment(labShipment.dateLabel).isSameOrAfter(filters.fromDate) && moment(labShipment.dateLabel).isSameOrBefore(filters.toDate)
  })

  return (
    <Screen>
      <div className='flex justify-between items-center px-0 pt--14 items-center print:hidden'>
        <ScreenTitle title="Shipping History" />
        <div>
          <Button
            variant='link'
            size='sm'
            onClick={window.print}
            className='mb-20'> <FiPrinter /> Print Manifest</Button>
        </div>
      </div>

      <div className='print:hidden'>
        <StatsForm
          onChangeFilters={setFilters}
          defaultFilters={filters}
        />
      </div>

      {
        filteredShipments.length === 0 ? <p className="mt-20">No shipments were sent on these dates. Please try again.</p> :
          filteredShipments.map((labShipment, i) => (
            <div key={labShipment.dateLabel} className={`${i != 0 ? 'page-break' : ''}`}>
              <LabShippingHistoryShipment
                labShipment={labShipment}
                key={labShipment.dateLabel}
              />
            </div>
          ))
      }
    </Screen>
  );
};

export default LabShippingHistory;