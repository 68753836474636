import * as React from 'react';
import { MessageMethod } from '../../../graphql/genie-api-types';
import LoadingOverlay from '../widgets/LoadingOverlay';
import ActionLink from '../widgets/ActionLink';
import BaseForm from '../widgets/BaseForm';
import FormInput, { FormInputOption } from '../widgets/form/FormInput';
import { FiX } from '../widgets/Icon';

export type NotificationFormPayload = {
  content: string;
  method: MessageMethod;
  destination: string;
};

interface NotificationFormProps {
  onSubmit(values: NotificationFormPayload): Promise<void>;
  isLoading: boolean;
}

const methodOptions: FormInputOption<MessageMethod>[] = [
  {
    value: MessageMethod.Call,
    label: 'Call',
  },
  {
    value: MessageMethod.Email,
    label: 'Email',
  },
  {
    value: MessageMethod.Sms,
    label: 'SMS',
  },
];

const NotificationForm = (props: NotificationFormProps) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const onToggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  
  const onSubmit = async(values: NotificationFormPayload) => {
    await props.onSubmit(values);
    setIsVisible(false);
  };

  const { isLoading } = props;

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {
        isVisible ? (
          <div className="pt-20">
            <button className="float-right appearance-none border-0 text-grey -mt-30"
                onClick={onToggleVisibility}
              >
                <FiX />
            </button>
            <BaseForm
              initialValues={{
                method: MessageMethod.Call,
                content: '',
                destination: ''
              }}
              onSubmit={onSubmit}
              submitButtonLabel="Add followup"
            >
              {({
                handleChange, values, setFieldValue,
              }) => (
                <>
                  <FormInput
                    type="text"
                    label="How did you contact them?"
                    name="method"
                    as="select"
                    options={methodOptions}
                    value={values?.method}
                    onChange={handleChange}
                    setFieldValue={setFieldValue} />
                  <FormInput
                    type="text"
                    label="Where did you send it to?"
                    name="destination"
                    value={values?.destination ?? ''}
                    onChange={handleChange}
                    setFieldValue={setFieldValue} />
                  <FormInput
                    label="Notes"
                    name="content"
                    type="text"
                    as="textarea"
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    value={values?.content ?? ''} />
                </>
              )}
            </BaseForm>
            
          </div>
        ) : (
            <div className="flex w-full justify-start">
              <ActionLink
                className="text-xs inline-block font-bold mr-5 text-blue-dark"
                onClick={onToggleVisibility}
              >
                + Add followup
                  </ActionLink>
            </div>
          )
      }
    </>
  );
};

export default NotificationForm;