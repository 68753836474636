import * as React from 'react';
import queryString, { InputParams } from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router';
import Button, { ButtonProps } from './Button';

export interface RouteButtonProps extends ButtonProps, RouteComponentProps {
  path?: string;
  query?: InputParams;
  isUnstyled?: boolean;
}

const RouteButton: React.FC<RouteButtonProps> = ({ path, query, onClick, history, staticContext, location, match, isUnstyled, ...restOfProps }: RouteButtonProps) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (path) {
      history.push(path);
    } else if (query) {
      const search = queryString.stringify(query);
      history.push({
        search,
      });
    } else if (onClick) {
      onClick(e);
    }
  };

  if (isUnstyled) {
    return (
      <button
        onClick={onButtonClick}
        className={`focus:outline-none ${restOfProps.className}`}
        style={restOfProps.style}
      >
        {restOfProps.children}
      </button>
    );
  }

  return (
    <Button
      {...restOfProps}
      onClick={onButtonClick}
    />
  );
};

RouteButton.defaultProps = {
  variant: 'primary',
  size: 'md',
  type: 'button',
  textAlign: 'center',
};

export default withRouter(RouteButton);
