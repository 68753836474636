import gql from 'graphql-tag';
import * as React from 'react';
import { Counsellor, Journey, JourneyState, Member, Pregnancy, Report, Request } from '../../../../graphql/genie-api-types';
import CounsellorIcon from '../../widgets/CounsellorIcon';
import JourneyRows from './JourneyRows';

interface ReviewReportsJourney extends Required<Pick<Journey, 'id'|'state'|'hasRecollection'|'lastStateChangeDate'>>{
  member: Required<Pick<Member, 'id'|'name'|'sex'>>|null;
  request: Required<Pick<Request, 'id' | 'product' | 'productVariant' | 'onHoldDate' | 'initiatingMember' | 'riskStatus'>> & {
    pregnancy?: Pick<Pregnancy, 'dueDate'|'dueDateConfirmed'|'days'>;
    primaryCounsellor?: Pick<Counsellor, 'id'|'name'|'photoURL'>;
    report?: Pick<Report, 'id'>;
    journeys: (Required<Pick<Journey, 'id'|'state'>> & {
      member: Required<Pick<Member, 'id'|'name'|'sex'>>;
    })[];
  };
}

export const LIST_JOURNEYS_QUERY = gql`
  query ListJourneys($input: ListJourneysInput!) {
    listJourneys(input: $input) {
      edges {
        cursor
        node {
          id
          state
          hasRecollection
          lastStateChangeDate
          member {
            id
            name
            sex
          }
          request {
            id
            product
            productVariant
            onHoldDate
            initiatingMember {
              id
            }
            pregnancy {
              dueDate
              dueDateConfirmed
              days
            }
            primaryCounsellor {
              id
              name
              photoURL
            }
            report {
              id
            }
            journeys {
              id
              state
              member {
                id
                name
                sex
              }
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const ReviewReportsJourneys = () => (
  <JourneyRows<ReviewReportsJourney>
    listJourneysQuery={LIST_JOURNEYS_QUERY}
    journeyStates={[JourneyState.ReadyForReportReview]}
    header={['GC']}
    emptyText="No reports awaiting review"
    cellsForJourney={journey => [
      <CounsellorIcon {...(journey?.request?.primaryCounsellor ?? {})} key="counsellor-icon" />,
    ]}
    actionsForJourney={journey => journey.request.report ? [
      {
        label: 'View report',
        name: 'viewReport',
        variant: 'success',
        noBatch: true,
      },
    ] : []}
  />
);

export default ReviewReportsJourneys;
