const { extendEugeneTailwindConfig } = require('@eugenelabs/front-end--tailwind');
module.exports = extendEugeneTailwindConfig({
  theme: {
    maxWidth: {
      'md': '28rem',
      '3xl': '48rem',
      '5xl': '64rem',
    },
    zIndex: {
      '0': '0',
      '10': '10',
      '50': '50',
      '100': '100',
    },
    inset: {
      'sidebar': '240px',
    },
    screens: {
      print: {'raw': 'print'},
    },
  },
  variants: {
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColors: ['responsive', 'hover', 'focus', 'group-hover'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderCollapse: [],
    borderColors: ['responsive', 'hover', 'focus'],
    borderRadius: ['responsive'],
    borderStyle: ['responsive'],
    borderWidths: ['responsive'],
    cursor: ['responsive'],
    display: ['responsive', 'group-hover'],
    flexbox: ['responsive'],
    float: ['responsive'],
    fonts: ['responsive'],
    fontWeights: ['responsive', 'hover', 'focus'],
    height: ['responsive'],
    inset: ['responsive'],
    leading: ['responsive'],
    lists: ['responsive'],
    margin: ['responsive', 'last'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    negativeMargin: ['responsive'],
    objectFit: false,
    objectPosition: false,
    opacity: ['responsive'],
    outline: ['focus'],
    overflow: ['responsive'],
    padding: ['responsive'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    resize: ['responsive'],
    shadows: ['responsive', 'hover', 'focus'],
    svgFill: [],
    svgStroke: [],
    tableLayout: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    textAlign: ['responsive'],
    textColors: ['responsive', 'hover', 'focus', 'group-hover'],
    textSizes: ['responsive'],
    textStyle: ['responsive', 'hover', 'focus', 'group-hover'],
    tracking: ['responsive'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive', 'group-hover'],
    whitespace: ['responsive'],
    width: ['responsive'],
    zIndex: ['responsive'],
  },
  corePlugins: {},
  plugins: [
    function({ addUtilities }) {
      const newUtilities = {
        '.rotate-45': {
          transform: 'rotate(45deg)',
        },
        '.rotate-n45': {
          transform: 'rotate(-45deg)',
        },
      }
      addUtilities(newUtilities)
    }
  ],
});

// see https://github.com/eugenelabs/front-end--tailwind/blob/master/tailwind.config.js

// colors = {
//   'transparent': 'transparent',

//   'black': '#1D1D26',
//   'grey-darkest': '#56566B',
//   'grey-dark': '#7F7F9E',
//   'grey': '#AAAAB9',
//   'grey-light': '#FAFAFF',
//   'grey-mid': '#ccc',
//   'grey-lightest': '#F7F7F7', 
//   'white': '#ffffff',

//   'input-border': '#ced4da',

//   'red': '#FD5A5A',
//   'red-light': '#FEACAC',

//   'orange': '#FFB258',
//   'orange-light': '#FFF0DE',
//   'orange-dark': '#d47300',

//   'yellow': '#ffc107',

//   'green-dark': '#1EA380',
//   'green': '#26CFA2',
//   'green-light': '#92E7D0',
//   'green-lightest': '#E3F0ED',

//   'blue-darkest': '#00003D',
//   'blue-dark': '#0000D2',
//   'blue': '#031AFE',

//   'purple': '#6269EE',
//   'purple-mid': '#8187f1',
//   'purple-light': '#B0B4F6',
//   'purple-lighter': '#EFF0FD',
//   'purple-lightest': '#e0e1fc',
//   'purple-100': '#F7F8FF',

//   'pink': '#D667CD',
//   'pink-light': '#FAEFFA',

//   'brown-light': '#EDE0D4',
//   'trans-black': 'rgba(0,0,0,0.5)',
//   'trans-white': 'rgba(255,255,255,0.9)',

//   'error': 'rgba(153, 0, 0, 0.6)',

//   'inherit': 'inherit',
// };


