import * as React from 'react';
import { MessageMethod, Notification } from '../../../graphql/genie-api-types';
import { FiPhoneCall, FiMail, FiMessageSquare } from '../widgets/Icon';
import moment from 'moment';

interface NotificationRowProps {
  notification: Notification;
  className?: string;
}

const verbs: { [key in MessageMethod]: string } = {
  [MessageMethod.Call]: 'Called',
  [MessageMethod.Email]: 'Emailed',
  [MessageMethod.Sms]: 'SMS\'d',
}

const icons: { [key in MessageMethod]: React.ReactNode } = {
  [MessageMethod.Call]: <FiPhoneCall />,
  [MessageMethod.Email]: <FiMail />,
  [MessageMethod.Sms]: <FiMessageSquare />,
}

const NotificationRow = (props: NotificationRowProps) => {
  const { notification } = props;
  return (
    <div className={`flex ${props.className ?? ''}`}>
      <div className="pr-10">
        {icons[notification.method]}
      </div>
      <div className="flex-1">
        {verbs[notification.method]} {moment(notification.dateSent).fromNow()}
        {props.notification.subject && (
          <div className="text-grey-dark mt-5">
            {props.notification.subject}
          </div>
        )}
        {props.notification.content && (
          <div className="text-grey-dark mt-5">
            {props.notification.content}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationRow;

