import * as React from 'react';
import { curry } from 'lodash';
import { navigate } from '../../../store/router/actions';
import { FiMail, FiPhoneCall } from '../../widgets/Icon';
import { copyToClipboard } from '../../../../utilities/helpers';
import ClinicShowContext from '../../../contexts/ClinicShowContext';
import { useDispatch } from 'react-redux';

const onShowContact = (value: string, e: MouseEvent) => {
  e.preventDefault();
  copyToClipboard(value);
}

const ClinicShowContact = () => {
  const { clinic, clinicId } = React.useContext(ClinicShowContext);
  const dispatch = useDispatch();
  if (!clinic) {
    return null;
  }

  const onEditClinic = () => dispatch(navigate.toForm('ClinicUpdate', {
    clinicId: clinicId,
  }));

  return (
    <div className="flex">
      <div className="w-1/2 leading-relaxed mt-12 pr-20 mt-40">
        <div className="bg-grey-light px-14 pt-8 pb-10 rounded">
          <div className="text-grey">Contact {clinic.name}</div>
          <span className="flex flex-row flex-nowrap py-3">
            <FiMail className="w-5 h-5 align-text-bottom mr-12 mt-2 text-grey" />
            {clinic.clinicEmail ?
              <button className="active:outline-none hover:underline" onClick={curry(onShowContact)(clinic.clinicEmail)}>
                {clinic.clinicEmail}
              </button>
              : <button className="text-orange active:outline-none hover:underline" onClick={onEditClinic}>Add clinic email</button>
            }
          </span>
          <span className="flex flex-row flex-nowrap py-3">
            <FiPhoneCall className="w-5 h-5 align-text-bottom mr-12 mt-2 text-grey" />
            {clinic.clinicPhone ?
              <button className="active:outline-none hover:underline" onClick={curry(onShowContact)(clinic.clinicPhone)}>
                {clinic.clinicPhone}
              </button>
              : <button className="text-orange active:outline-none hover:underline" onClick={onEditClinic}>Add clinic phone number</button>
            }
          </span>
        </div>
      </div>
    </div>
  );
}

export default ClinicShowContact;
