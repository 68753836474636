import * as React from 'react';
import FormInput from '../widgets/form/FormInput';
import BaseForm, { BaseFormProps } from '../widgets/BaseForm';
import { trimLabOrderNumber } from '../../../utilities/helpers';

interface JourneyLabOrderNumberFormProps extends Pick<BaseFormProps, 'initialValues' | 'onSubmit' | 'onCancel' | 'isLoading' | 'errorMessage'> {
}
const JourneyLabOrderNumberForm = ({ initialValues, onSubmit, onCancel, isLoading, errorMessage }: JourneyLabOrderNumberFormProps) => (
  <BaseForm
    initialValues={initialValues}
    submitButtonLabel="Save"
    hasCancel={!!onCancel}
    onCancel={onCancel}
    errorMessage={errorMessage}
    isLoading={isLoading}
    onSubmit={onSubmit}
  >
    {({
      values,
      setFieldValue,
    }) => {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.name, trimLabOrderNumber(e.target.value));
      }
      return (
        <div>
          <FormInput
            name="labOrderNumber"
            type="text"
            label="Lab order number"
            setFieldValue={setFieldValue}
            value={values['labOrderNumber']}
            onChange={onChange}
          />
        </div>
      )
    }}
  </BaseForm>
);

export default JourneyLabOrderNumberForm;
