import { action } from 'typesafe-actions';
import { union } from 'lodash';
import { ClientDetailsActionTypes, ClientFields, ClientId } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { navigate } from '../../router/actions';
import { PATHS } from '../../router/types';
import requestDiseasesByGene, { RequestDiseasesByGeneResult } from '../../../graphql/requests/requestDiseasesByGene';
import { ApplicationState } from '../..';
import { updateDefaultQuickNote, updateDefaultWhatWeFound } from '../summary/actions';
import requestCarrierStatisticsByGene, { RequestCarrierStatisticsByGeneResult } from '../../../graphql/requests/requestCarrierStatisticsByGene';
import { setCarrierRateDefaultsFromSelectedEthnicities } from '../carrier-rates/actions';
import { showToast, showLoadingToast, hideToast } from '../../toast/actions';

export const changeFields = (client: ClientId, fields: Omit<ClientFields, 'memberId'>) => async (dispatch: ThunkDispatch<{}, {}, any>) => {
  dispatch(action(ClientDetailsActionTypes.CHANGE_FIELDS, { client, fields }));
  dispatch(navigate.toCurrentReportPath(PATHS.REPORTS_CLIENT_DETAILS));
  dispatch(updateAffectedDiseaseData());
  dispatch(updatePseudoDiseaseData());
  showToast('success', 'Success', 'Updated client details');
};

export const receieveDiseaseData = (diseasesByGene: RequestDiseasesByGeneResult) =>
  action(ClientDetailsActionTypes.RECEIVE_DISEASES, diseasesByGene);

export const receievePseudoDiseaseData = (diseasesByGene: RequestDiseasesByGeneResult) =>
  action(ClientDetailsActionTypes.RECEIVE_PSEUDO_DISEASES, diseasesByGene);

export const receieveCarrierRatesData = (carrierRateData: RequestCarrierStatisticsByGeneResult) =>
  action(ClientDetailsActionTypes.RECEIVE_CARRIER_RATES, carrierRateData);

export const updateAffectedDiseaseData = () =>
  async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ApplicationState) => {
    dispatch(action(ClientDetailsActionTypes.RECEIVE_DISEASES_BEGIN));

    const { report } = getState();
    const { clientDetails } = report;

    const genes = union(
      clientDetails.clientA.affectedGenes,
      clientDetails.clientB.affectedGenes,
    );

    try {
      const loaderId = 'updateAffectedDiseaseDataLoader';
      dispatch(showLoadingToast(loaderId, 'Downloading...', 'Diseases and carrier statistics'));

      const diseasesByGene = await requestDiseasesByGene(genes);
      dispatch(receieveDiseaseData(diseasesByGene));
      dispatch(updateDefaultWhatWeFound());

      const carrierRateData = await requestCarrierStatisticsByGene(genes);
      dispatch(receieveCarrierRatesData(carrierRateData));
      dispatch(hideToast(loaderId));
    } catch (e) {
      showToast('error', 'Disease data download failed', e.message);
    }

    dispatch(setCarrierRateDefaultsFromSelectedEthnicities());
    dispatch(action(ClientDetailsActionTypes.RECEIVE_DISEASES_COMPLETE));
  };

export const updatePseudoDiseaseData = () =>
  async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => ApplicationState) => {
    dispatch(action(ClientDetailsActionTypes.RECEIVE_DISEASES_BEGIN));

    const { report } = getState();
    const { clientDetails } = report;

    const genes = union(
      clientDetails.clientA.pseudogenes,
      clientDetails.clientB.pseudogenes,
    );

    try {
      const loaderId = 'updatePseudoDiseaseDataLoader';
      dispatch(showLoadingToast(loaderId, 'Downloading...', 'Pseudo diseases'));

      const diseasesByGene = await requestDiseasesByGene(genes);
      dispatch(receievePseudoDiseaseData(diseasesByGene));
      dispatch(updateDefaultQuickNote());

      const carrierRateData = await requestCarrierStatisticsByGene(genes);
      dispatch(receieveCarrierRatesData(carrierRateData));

      dispatch(hideToast(loaderId));
    } catch (e) {
      showToast('error', 'Pseudo disease data download failed', e.message);
    }

    dispatch(action(ClientDetailsActionTypes.RECEIVE_DISEASES_COMPLETE));
  };

export const swapClients = () =>
  action(ClientDetailsActionTypes.SWAP_CLIENTS);
