import * as React from 'react';
import { PATHS } from '../store/router/types';
import { Switch } from 'react-router';
import Route from '../base/Route';
import UpdatesIndex from './updates/UpdatesIndex';

const Updates = () => {
  return (
    <Switch>
      <Route path={PATHS.UPDATES} component={UpdatesIndex} exact />
    </Switch>
  )
}

export default Updates;
